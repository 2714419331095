import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { WorkflowApiKeySchema } from "../../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Modal from "../../modals/Modal";
import Input from "../../inputs/Input";
import Button from "../../buttons/Button";
import { useEffect } from "react";
import {
  selectSelectedWorkflowApiKey,
  selectWorkflowsApiKeysActionLoading,
  selectShowEditorModal,
  handleHideEditorModal,
  getWorkflowsApiKeysAsync,
  updateWorkflowApiKeyAsync,
  createWorkflowApiKeyAsync,
} from "../../../store/workflows/apiKeysSlice";

const WorkflowApiKeyEditorModal = () => {
  const isOpen = useAppSelector(selectShowEditorModal);
  const loading = useAppSelector(selectWorkflowsApiKeysActionLoading);
  const apiKey = useAppSelector(selectSelectedWorkflowApiKey);
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(WorkflowApiKeySchema),
  });

  useEffect(() => {
    if (isOpen && apiKey) {
      setValue("keyName", apiKey.keyName);
    }
  }, [isOpen, apiKey, setValue]);

  const onSubmit = (data: any) => {
    if (apiKey) {
      dispatch(
        updateWorkflowApiKeyAsync({
          id: apiKey.id,
          keyName: data.keyName,
        })
      ).then((action) => {
        if (action.type === "workflows/api-keys/update/fulfilled") {
          reset();
          dispatch(handleHideEditorModal());
          dispatch(getWorkflowsApiKeysAsync());
        }
      });
    } else {
      dispatch(createWorkflowApiKeyAsync(data.keyName)).then((action) => {
        if (action.type === "workflows/api-keys/create/fulfilled") {
          reset();
          dispatch(handleHideEditorModal());
          dispatch(getWorkflowsApiKeysAsync());
        }
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleHideEditorModal());
        reset();
      }}
      contentClassName="max-w-lg"
      //   containerClassName="!items-start"
      title={apiKey ? "Update api key" : "Create new api key"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col w-full gap-5">
          <Input
            label="Api key label"
            inputProps={{ ...register("keyName") }}
            error={errors.keyName?.message}
            placeholder=""
          />
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            loading={loading}
            type="primary"
            buttonProps={{
              disabled: loading,
              type: "submit",
            }}
            buttonClassName="w-[100px]"
          >
            {apiKey ? "Update" : "Create"}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default WorkflowApiKeyEditorModal;
