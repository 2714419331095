import classNames from "classnames";
import { copyToClipboard } from "../../utils/copy";
import { ReactComponent as CopyIcon } from "./../../assets/icons/copy.svg";
import Button from "../buttons/Button";
import Text from "./Text";

const CopyableKey = ({
  text,
  label,
  loading,
  className,
}: {
  text?: string;
  label?: string;
  loading?: boolean;
  className?: string;
}) => {
  return (
    <div
      className={classNames(
        "relative pr-7 h-7 flex items-center px-2 py-1 gap-2 bg-primary-100/30 text-primary-500 hover:bg-primary-100/40 dark:bg-primary-300/10 dark:hover:bg-primary-200/10 transition-colors duration-200 rounded-md text-xs font-base cursor-pointer whitespace-nowrap",
        {
          "animate-pulse": loading,
          "min-w-[150px]": loading && text === "",
        },
        className
      )}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        text && copyToClipboard(text);
      }}
    >
      <div className="overflow-hidden text-ellipsis">{label || text}</div>
      <CopyIcon className="w-4 h-4 absolute right-2" />
    </div>
  );
};

const CopyableInput = ({
  title,
  value,
  loading,
  className,
}: {
  title?: string;
  value?: string;
  loading?: boolean;
  className?: string;
}) => {
  const handleTextClick = () => {
    value && copyToClipboard(value);
  };
  return (
    <div
      className={classNames(
        "space-x-2 lg:flex-1 flex items-end w-full",
        className
      )}
    >
      <div className={classNames("flex-1", { "animate-pulse": loading })}>
        <div className={classNames("flex flex-col w-full")}>
          <Text className="text-sm font-medium">{title}</Text>
          <div className="flex gap-2">
            <input
              disabled
              value={value}
              className="py-2 transition placeholder-gray-600 leading-6 outline-none bg-transparent ring-none w-full px-4 cursor-text resize-none outline-0 border dark:border-dark-2 text-slate-500 dark:text-slate-400 relative dark:bg-faint duration-150  items-center text-sm rounded-lg h-9"
            />
            <Button
              buttonProps={{ onClick: handleTextClick, disabled: loading }}
              type="light"
            >
              <div className="flex items-center gap-1">
                <CopyIcon className="w-4 h-4" />
                Copy
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CopyableInput };
export default CopyableKey;
