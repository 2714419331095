import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import { isAxiosError } from "axios";
import toast from "react-hot-toast";
import { ToastClasses } from "../../components/modals/alerts";
import {
  createS3EventApi,
  deleteS3EventApi,
  getS3ArnsApi,
  getS3EventsApi,
  updateS3EventApi,
} from "../../apis/s3API";
import {
  EventDestinationNames,
  S3Event,
  S3EventsState,
} from "../../types/s3-events";
import { changeS3ActiveStatus } from "./s3PublicSlice";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";

const initialState: S3EventsState = {
  events: [],
  loading: false,
  arns: [],
  arnsLoading: false,
  actionLoading: false,
  showEditor: false,
  selectedEvent: null,
};

export const getS3EventsAsync = createAsyncThunk<
  { events: S3Event[] },
  undefined,
  IThunkRejectValue
>("s3-events", async (_, { rejectWithValue, fulfillWithValue, dispatch }) => {
  try {
    const response = await getS3EventsApi();

    const { Result, NodeStatus } = response.data;
    const events = Result || [];

    dispatch(changeS3ActiveStatus(NodeStatus === 2));

    return fulfillWithValue({ events });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const deleteS3EventAsync = createAsyncThunk<
  boolean,
  { event: string; identifier: string },
  { rejectValue: { message: string } }
>("s3-events/delete", async ({ event, identifier }, { rejectWithValue }) => {
  try {
    await deleteS3EventApi(event, identifier);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createS3EventAsync = createAsyncThunk<
  boolean,
  { event: EventDestinationNames; identifier: string; data: any },
  IThunkRejectValue
>(
  "s3-events/create",
  async ({ event, identifier, data }, { rejectWithValue }) => {
    try {
      await createS3EventApi(event, identifier, data);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const updateS3EventAsync = createAsyncThunk<
  boolean,
  { id: number; data: any },
  IThunkRejectValue
>("s3-events/update", async ({ id, data }, { rejectWithValue }) => {
  try {
    await updateS3EventApi(id, data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getS3ArnsAsync = createAsyncThunk<
  { arns: string[] },
  undefined,
  IThunkRejectValue
>(
  "s3-events/arns",
  async (_, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getS3ArnsApi();

      const { Result, NodeStatus } = response.data;
      const arns = Result.arns || [];

      dispatch(changeS3ActiveStatus(NodeStatus === 2));

      return fulfillWithValue({ arns });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    handleShowEditor: (state, action: { payload: { event?: S3Event } }) => {
      state.showEditor = true;
      if (typeof action.payload.event !== "undefined") {
        state.selectedEvent = action.payload.event;
      }
    },
    handleHideEditor: (state) => {
      state.showEditor = false;
      state.selectedEvent = null;
    },
    handleChangeEventActionLoading: (state, action: PayloadAction<boolean>) => {
      state.actionLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getS3EventsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getS3EventsAsync.fulfilled, (state, action) => {
        const { events } = action.payload;

        state.events = events;
        state.loading = false;
      })
      .addCase(getS3EventsAsync.rejected, (state, { payload }) => {
        state.loading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createS3EventAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(createS3EventAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(createS3EventAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateS3EventAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(updateS3EventAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(updateS3EventAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteS3EventAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(deleteS3EventAsync.fulfilled, (state) => {
        // state.actionLoading = false;
      })
      .addCase(deleteS3EventAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getS3ArnsAsync.pending, (state) => {
        state.arnsLoading = true;
      })
      .addCase(getS3ArnsAsync.fulfilled, (state, action) => {
        const { arns } = action.payload;
        state.arns = arns;
        state.arnsLoading = false;
      })
      .addCase(getS3ArnsAsync.rejected, (state, { payload }) => {
        state.arnsLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectS3Events = (state: RootState) => state.s3Events.events;
export const selectLoading = (state: RootState) => state.s3Events.loading;
export const selectActionLoading = (state: RootState) =>
  state.s3Events.actionLoading;

export const selectShowEditor = (state: RootState) => state.s3Events.showEditor;

export const selectSelectedS3Event = (state: RootState) =>
  state.s3Events.selectedEvent;

export const selectS3Arns = (state: RootState) => state.s3Events.arns;
export const selectS3ArnsLoading = (state: RootState) =>
  state.s3Events.arnsLoading;

export const {
  handleShowEditor,
  handleHideEditor,
  handleChangeEventActionLoading,
} = eventsSlice.actions;
export default eventsSlice.reducer;
