import { useState } from "react";
import {
  getLocalStorage,
  setLocalStorage,
} from "./../../../utils/localStorage";
import Button from "../../buttons/Button";
import Text from "../../general/Text";
import { ReactComponent as CloseIcon } from "./../../../assets/icons/close.svg";

const MarketApiBanner = () => {
  const localStorageName = "market-api-banner";
  const showWeb3Banner = getLocalStorage<boolean>(localStorageName, true);
  const [show, setShow] = useState<boolean>(showWeb3Banner);
  const closeBanner = () => {
    setLocalStorage<boolean>(localStorageName, false);
    setShow(false);
  };

  if (!show) return null;
  return (
    <div className="relative w-full bg-gray-50 dark:bg-dark-3 border border-slate-300 dark:border-dark-2 p-6 rounded-lg flex flex-col">
      <CloseIcon
        onClick={closeBanner}
        className="absolute right-3 top-3 text-slate-500 dark:text-slate-300 w-5 hover:scale-110 duration-300 cursor-pointer"
      />
      <div className="flex items-center w-full justify-between">
        <Text className="text-sm">Get started with Market API</Text>
      </div>
      <div className="flex items-center gap-2 mt-5 flex-wrap">
        <a
          href="https://developers.coinranking.com/api/documentation/coins/coin-supply-history"
          target="_blank"
          rel="noreferrer"
        >
          <Button>Coin</Button>
        </a>
        <a
          href="https://developers.coinranking.com/api/documentation/blockchains"
          target="_blank"
          rel="noreferrer"
        >
          <Button>Blockchains</Button>
        </a>
        <a
          href="https://developers.coinranking.com/api/documentation/exchanges"
          target="_blank"
          rel="noreferrer"
        >
          <Button>Exchanges</Button>
        </a>
        <a
          href="https://developers.coinranking.com/api/documentation/markets"
          target="_blank"
          rel="noreferrer"
        >
          <Button>Markets</Button>
        </a>
        <a
          href="https://developers.coinranking.com/api/documentation/reference-currencies"
          target="_blank"
          rel="noreferrer"
        >
          <Button>Reference currencies</Button>
        </a>
        <a
          href="https://developers.coinranking.com/api/documentation/stats"
          target="_blank"
          rel="noreferrer"
        >
          <Button>Coin Stats</Button>
        </a>
        <a
          href="https://developers.coinranking.com/api/documentation/search"
          target="_blank"
          rel="noreferrer"
        >
          <Button>Search</Button>
        </a>
        <a
          href="https://developers.coinranking.com/api/documentation/metadata"
          target="_blank"
          rel="noreferrer"
        >
          <Button>Metadata</Button>
        </a>
        <a
          href="https://developers.coinranking.com/api/documentation/realtime"
          target="_blank"
          rel="noreferrer"
        >
          <Button>Real-time</Button>
        </a>
      </div>
    </div>
  );
};

export default MarketApiBanner;
