import NotData from "../../layouts/NotData";
import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useState } from "react";
import Button from "../../buttons/Button";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";

import Dropdown from "../../general/Dropdown";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import QuestionModal, { DeleteModal } from "../../modals/QuestionModal";
import Text from "../../general/Text";
import { useSearch } from "../../../providers/SearchProvider";
import Highlighter from "react-highlight-words";
import {
  deleteDBSDatabasesAsync,
  getDBSDatabasesAsync,
  handleDBSDatabaseEditor,
  selectDBSDatabases,
  selectDBSDatabasesActionLoading,
  selectDBSDatabasesLoading,
  selectDatabaseService,
  selectSelectedDBSDatabase,
} from "../../../store/database/serviceSlice";
import { DBSDatabase } from "../../../types/database";
import DBSDatabaseCreateModal from "./DBSDatabaseCreateModal";
import { generateServicePermissions } from "../../../pages/databases/ServicePage";
import { useNavigate } from "react-router-dom";
import { DatabaseServiceGeneralInformationUrl } from "../../../utils/urls";

const DatabasesTab = () => {
  const service = useAppSelector(selectDatabaseService);

  const databases = useAppSelector(selectDBSDatabases);
  const databasesLoading = useAppSelector(selectDBSDatabasesLoading);
  const database = useAppSelector(selectSelectedDBSDatabase);
  const [filteredDatabases, setFilteredDatabases] = useState<DBSDatabase[]>([]);
  const actionLoading = useAppSelector(selectDBSDatabasesActionLoading);

  const dispatch = useAppDispatch();

  const [deleteDatabase, setDeleteDatabase] = useState<string | null>(null);
  const { value: searchValue } = useSearch();
  const navigate = useNavigate();

  useEffect(() => {
    if (service) {
      const permissions = generateServicePermissions().databases;
      if (!permissions.includes(service.engine)) {
        navigate(DatabaseServiceGeneralInformationUrl(service.id));
      } else {
        dispatch(
          getDBSDatabasesAsync({
            engine: service.engine,
            clusterId: service.id,
          })
        );
      }
    }
  }, [dispatch, navigate, service]);

  useEffect(() => {
    const lookedUpFiles = databases.filter((database) =>
      database.name?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredDatabases(lookedUpFiles);
  }, [dispatch, searchValue, databases]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Database</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="">
        <div className="flex items-center justify-between">
          <div className="items-center justify-between flex flex-1 transition duration-150">
            <Text className="font-medium text-lg"></Text>
          </div>
          <div className="flex items-center gap-2">
            <Button
              size="medium"
              type="light"
              buttonProps={{
                onClick: () => {
                  if (service)
                    dispatch(
                      getDBSDatabasesAsync({
                        engine: service.engine,
                        clusterId: service.id,
                      })
                    );
                },
                disabled: !service,
              }}
              buttonClassName="group"
              tooltip="Refresh"
            >
              <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>

            <Button
              type="primary"
              buttonProps={{
                onClick: () => dispatch(handleDBSDatabaseEditor(true)),
              }}
              buttonClassName="group"
            >
              Create a database
              <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>
          </div>
        </div>

        <div className="mt-5 w-full">
          <Table loading={databasesLoading}>
            <TableHead>
              <TableRow>
                <TableTH lable="Name" />
                <TableTH lable="" />
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredDatabases.map((database, i) => (
                <TableRow key={i}>
                  <TableTD className="cursor-pointer">
                    <div className="flex items-center flex-row justify-between space-x-3">
                      <Highlighter
                        highlightClassName="bg-primary-100 dark:bg-primary-300 rounded-sm"
                        className="whitespace-nowrap max-w-[240px] text-ellipsis overflow-hidden text-sm font-medium"
                        searchWords={[searchValue]}
                        autoEscape={true}
                        textToHighlight={database.name}
                      />
                    </div>
                  </TableTD>
                  <TableTD className="w-32 gap-1">
                    <div className="h-full w-full inline-flex items-center justify-center gap-1">
                      <div className="w-8 flex justify-center items-center">
                        <Dropdown
                          positionClassName="!w-48"
                          menu={[
                            {
                              key: "end",
                              label: (
                                <div className="flex items-center gap-1">
                                  <ArchiveIcon className="w-4" />
                                  Delete
                                </div>
                              ),
                              danger: true,
                              onClick: (_, close) => {
                                close();
                                setDeleteDatabase(database.id);
                              },
                              disabled: database.default,
                            },
                          ]}
                          type="simple"
                        >
                          <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                            <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                          </div>
                        </Dropdown>
                      </div>
                      <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" />
                    </div>
                  </TableTD>
                </TableRow>
              ))}

              {databases.length === 0 && (
                <TableRow withoutHoverStyle={true}>
                  <TableTD colSpan={10} className="!border-0">
                    <NotData text="You have not created any database yet" />
                  </TableTD>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <DBSDatabaseCreateModal />
        <DeleteModal
          title="Delete a Database"
          isOpen={deleteDatabase !== null}
          onClose={() => setDeleteDatabase(null)}
          description="To confirm, type 'DELETE' in the box below"
          confirmButtonText="Confirm"
          confirmButtonType="danger"
          confirmButtonClassName="w-full"
          onConfirm={() => {
            deleteDatabase &&
              service &&
              dispatch(
                deleteDBSDatabasesAsync({
                  clusterId: service.id,
                  engine: service.engine,
                  databaseId: deleteDatabase,
                })
              ).then((action) => {
                if (action.type === "service/databases/delete/fulfilled") {
                  dispatch(
                    getDBSDatabasesAsync({
                      engine: service.engine,
                      clusterId: service.id,
                    })
                  );
                  setDeleteDatabase(null);
                }
              });
          }}
          loading={actionLoading}
          confirmString={database?.name}
        />
      </div>
    </>
  );
};

export default DatabasesTab;
