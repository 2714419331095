import NotData from "../../layouts/NotData";
import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useMemo, useState } from "react";
import Button from "../../buttons/Button";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right.svg";

import { useNavigate } from "react-router-dom";
import {
  DatabaseBackupsDuplicateUrl,
  DatabaseServiceCreateUrl,
  DatabaseServiceGeneralInformationUrl,
} from "../../../utils/urls";
import Dropdown from "../../general/Dropdown";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import Text, { HighlighterText } from "../../general/Text";
import { useSearch } from "../../../providers/SearchProvider";
import {
  getDatabaseServicesAsync,
  getDBSAvailabilityAsync,
  selectDBSAvailability,
  selectDBSAvailabilityLoading,
  updateDBSAsync,
} from "../../../store/database/servicesSlice";
import {
  convertTimeStringToUTC,
  convertUTCToLocalTime,
  expireDate,
  formatTimestamp,
  getGmtOffsetHours,
} from "../../../utils/date";
import {
  getDBSBackupsAsync,
  getDatabaseServiceAsync,
  selectDBSBackups,
  selectDBSBackupsLoading,
  selectDatabaseService,
} from "../../../store/database/serviceSlice";
import { IDBSBackup } from "../../../types/database";
import { generateServicePermissions } from "../../../pages/databases/ServicePage";
import Card from "../../general/Card";
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ReactComponent as VerifyIcon } from "./../../../assets/icons/verify.svg";
import { ReactComponent as ErrorIcon } from "./../../../assets/icons/error-warning.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";

const DBSBackupsTab = () => {
  const [filteredBackups, setFilteredBackups] = useState<IDBSBackup[]>([]);
  const [showHandleBackupTimeButton, setShowHandleBackupTimeButton] =
    useState<boolean>(false);

  const service = useAppSelector(selectDatabaseService);
  const backups = useAppSelector(selectDBSBackups);
  const backupsLoading = useAppSelector(selectDBSBackupsLoading);

  const availability = useAppSelector(selectDBSAvailability);
  const availabilityLoading = useAppSelector(selectDBSAvailabilityLoading);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { value: searchValue } = useSearch();

  const [backupTime, setBackupTime] = useState(service?.backupTime || "");
  const handleChangeBackupTime = (event: any) => {
    setBackupTime(event.target.value);
    setShowHandleBackupTimeButton(true);
  };
  const handleActionBackupTime = (isCancel: boolean) => {
    if (service) {
      dispatch(
        updateDBSAsync({
          id: service.id,
          engine: service.engine,
          data: {
            backupTime: isCancel ? service?.backupTime : backupTime,
          },
        })
      ).then((action) => {
        if (action.type === "services/update/fulfilled") {
          dispatch(getDatabaseServicesAsync());
          dispatch(getDatabaseServiceAsync({ id: service.id }));
          setShowHandleBackupTimeButton(false);
          setBackupTime(service.maintenanceTime);
        }
      });
    }
  };

  useEffect(() => {
    if (service) {
      const permissions = generateServicePermissions().backups;
      if (!permissions.includes(service.engine)) {
        navigate(DatabaseServiceGeneralInformationUrl(service.id));
      } else {
        dispatch(
          getDBSBackupsAsync({ engine: service.engine, id: service.id })
        );
      }
    }
  }, [dispatch, navigate, service]);

  useEffect(() => {
    const lookedUpFiles = backups.filter((backup) =>
      backup.description?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredBackups(lookedUpFiles);
  }, [dispatch, searchValue, backups]);

  const retentionDays = useMemo(() => {
    return service?.backups?.retentionDays;
  }, []);

  const formatTimestamp = (timestamp: any, format: any) => {
    const dateTime = moment(timestamp).format(format);
    return { datetime: dateTime };
  };

  const formatWithRetention = (timestamp: any, retention: number) => {
    const dateWithRetention = moment(timestamp)
      .add(retention, "days")
      .format("MMM DD hh:mm:ss A");
    return { datetime: dateWithRetention };
  };

  const handleDuplicateBackup = (backup: any) => {
    service &&
      navigate(DatabaseBackupsDuplicateUrl(service.id), {
        state: { selectedBackup: backup, selectedValue: 3 },
      });
  };

  const handleDuplicateNewest = (backups: any) => {
    if (service && service.capabilities.backupTime.update === "enabled") {
      navigate(DatabaseBackupsDuplicateUrl(service.id), {
        state: { selectedValue: 1 },
      });
    } else if (service) {
      navigate(DatabaseBackupsDuplicateUrl(service.id), {
        state: { selectedBackupTime: backups, selectedValue: 3 },
      });
    }
  };

  const availabilityData = useMemo(() => {
    return availability?.find(
      (a) => a.engine === service?.engine && a.plan === service.plan
    );
  }, [service, availability]);

  useEffect(() => {
    if (availability === undefined || availability.length === 0) {
      dispatch(getDBSAvailabilityAsync());
    }
  }, [availability, dispatch]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Database</title>
        <meta name="description" content="" />
      </Helmet>

      {service && (
        <>
          <div className="flex flex-col gap-3">
            <Text className="font-medium text-lg ">Backups</Text>

            <div className="items-center justify-between flex flex-1 transition duration-150">
              <Text className="text-sm ">
                Below you will find the list of your backups. You can restore or
                fork any of them.
              </Text>
            </div>
            <div className="items-center justify-between flex flex-1 transition duration-150">
              <Text className="text-sm ">
                These rolling backups are kept for your service to remain
                operational.
              </Text>
            </div>
            <div className="mt-5">
              <Card title="Database backups">
                <ul className="mt-2 ">
                  <li className="p-4 ">
                    <div className="flex justify-between flex-col md:flex-row">
                      <div className="mb-4 md:mb-0 w-full ">
                        <Text type="title" className="text-sm ">
                          Retention period
                        </Text>
                        <Text className="text-sm flex items-center gap-1">
                          {availabilityData?.backups?.retentionDays &&
                          availabilityData.backups.retentionDays > 0
                            ? `${availabilityData.backups.retentionDays} days`
                            : "a few seconds"}
                        </Text>
                      </div>
                      <div className="mb-4 md:mb-0 w-full">
                        <Text type="title" className="text-sm mb-2">
                          Backup time (UTC)
                        </Text>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <div className="flex flex-row">
                            {" "}
                            <TextField
                              type="time"
                              defaultValue={service.backupTime}
                              inputProps={{ step: 1 }}
                              onChange={handleChangeBackupTime}
                              InputProps={{
                                className: "dark:bg-slate-400 ",
                              }}
                              disabled={!service.capabilities.backupTime.update}
                            />
                            {showHandleBackupTimeButton && (
                              <div className="flex flex-col mt-1 ml-2">
                                <div
                                  onClick={() => handleActionBackupTime(false)}
                                >
                                  <Text>
                                    <VerifyIcon className="w-6 h-6   " />
                                  </Text>
                                </div>
                                <div
                                  onClick={() => handleActionBackupTime(true)}
                                >
                                  <Text>
                                    <ErrorIcon className="w-6 h-6  " />
                                  </Text>
                                </div>
                              </div>
                            )}
                          </div>
                        </LocalizationProvider>
                        <Text className="text-xs mt-1">
                          Local time (GMT {getGmtOffsetHours()}
                          ):
                          {service &&
                            convertTimeStringToUTC(
                              service?.backupTime
                            ).toLocaleTimeString()}
                        </Text>
                      </div>
                    </div>
                  </li>
                </ul>
              </Card>
            </div>
            <div className="flex justify-end">
              {service && backups && (
                <div className="flex items-center gap-2">
                  <Button
                    size="medium"
                    type="light"
                    buttonProps={{
                      onClick: () => {
                        if (service)
                          dispatch(
                            getDBSBackupsAsync({
                              engine: service.engine,
                              id: service.id,
                            })
                          );
                      },
                      disabled: !service,
                    }}
                    buttonClassName="group"
                    tooltip="Refresh"
                  >
                    <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
                  </Button>
                  <Button
                    type="primary"
                    buttonProps={{
                      onClick: () =>
                        handleDuplicateNewest(
                          service.capabilities.backupTime.update
                            ? backups
                            : null
                        ),
                      //  disabled: backupsLoading,
                    }}
                    buttonClassName="group"
                  >
                    Duplicate (Fork)
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="mt-5 w-full">
            <Table loading={backupsLoading}>
              <TableHead>
                <TableRow>
                  <TableTH lable="Name" />
                  <TableTH lable="Location" />
                  <TableTH lable="Creation date" />
                  <TableTH lable="Expiry date" />
                  <TableTH lable="Status" />
                  <TableTH lable="" />
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredBackups.map((backup, i) => (
                  <TableRow key={i}>
                    <TableTD className="cursor-pointer">
                      <div className="flex items-center flex-row justify-between space-x-3">
                        <HighlighterText
                          searchValue={searchValue}
                          textToHighlight={backup.description}
                        />
                      </div>
                    </TableTD>

                    <TableTD className="cursor-pointer">
                      <Text className="text-sm">
                        {backup.regions.map((r) => (
                          <div className="mr-2">{r.name}</div>
                        ))}
                      </Text>
                    </TableTD>

                    <TableTD className="cursor-pointer">
                      <Text className="text-sm">
                        {
                          formatTimestamp(backup.createdAt, "MMM DD hh:mm:ss A")
                            .datetime
                        }
                      </Text>
                    </TableTD>
                    <TableTD className="cursor-pointer">
                      <Text className="text-sm">
                        {
                          formatWithRetention(
                            backup.createdAt,
                            (availabilityData &&
                              availabilityData.backups.retentionDays) ||
                              0
                          ).datetime
                        }
                      </Text>
                    </TableTD>

                    <TableTD className="cursor-pointer">
                      <Text
                        className="text-xs"
                        type={
                          backup.status === "READY"
                            ? "success-alert"
                            : "warning-alert"
                        }
                      >
                        {backup.status}
                      </Text>
                    </TableTD>

                    <TableTD className="w-32 gap-1">
                      <div className="h-full w-full inline-flex items-center justify-center gap-1">
                        <div className="w-8 flex justify-center items-center ">
                          <Dropdown
                            positionClassName="!w-48"
                            menu={[
                              {
                                key: "1",
                                label: (
                                  <div className="flex items-center gap-1">
                                    Duplicate (Fork)
                                  </div>
                                ),
                                onClick: (_, close) => {
                                  close();
                                  handleDuplicateBackup(backup);
                                },
                                disabled: backupsLoading,
                              },
                            ]}
                            type="simple"
                          >
                            <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                              <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                            </div>
                          </Dropdown>
                        </div>
                        {/* <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" /> */}
                      </div>
                    </TableTD>
                  </TableRow>
                ))}

                {backups.length === 0 && !backupsLoading && (
                  <TableRow withoutHoverStyle={true}>
                    <TableTD colSpan={10} className="!border-0">
                      <NotData text="No queris have been run" />
                    </TableTD>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </>
      )}
    </>
  );
};

export default DBSBackupsTab;
