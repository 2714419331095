import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../types";
import { S3PublicStateType } from "../../types/s3";

const initialState: S3PublicStateType = {
  activeStatus: true, //is IPFS enabled or not?
};

const s3PublicSlice = createSlice({
  name: "s3-public",
  initialState: initialState,
  reducers: {
    changeS3ActiveStatus(state, action: PayloadAction<boolean>) {
      state.activeStatus = action.payload;
    },
  },
});

export const selectS3Status = (state: RootState) => state.s3Public.activeStatus;

export const { changeS3ActiveStatus } = s3PublicSlice.actions;
export default s3PublicSlice.reducer;
