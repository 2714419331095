import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  handleShowEditor,
  selectS3TiersLoading,
} from "../../../store/s3/tiersSlice";
import Button from "../../buttons/Button";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import Dropdown from "../../general/Dropdown";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil-square.svg";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import NotData, { S3NotActiveTab } from "../../layouts/NotData";
import { useSearch } from "../../../providers/SearchProvider";
import Highlighter from "react-highlight-words";
import { S3Tier } from "../../../types/s3-tiers";
import { getS3TiersAsync, selectS3Tiers } from "../../../store/s3/tiersSlice";
import { S3TierAddUrl } from "../../../utils/urls";
import { useNavigate } from "react-router-dom";
import UpdateCredentialsModal from "./UpdateCredentialsModal";
import Text from "../../general/Text";
import classNames from "classnames";
import { selectS3Status } from "../../../store/s3/s3PublicSlice";

const S3TiersTab = () => {
  const s3Status = useAppSelector(selectS3Status);
  const [filteredTiers, setFilteredTiers] = useState<S3Tier[]>([]);
  const tiers = useAppSelector(selectS3Tiers);
  const loading = useAppSelector(selectS3TiersLoading);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { value: searchValue } = useSearch();

  useEffect(() => {
    // if (tiers.length === 0) {
    dispatch(getS3TiersAsync());
    // }
  }, [dispatch]);

  useEffect(() => {
    const lookedUp = tiers.filter((t) =>
      t[t.type]?.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredTiers(lookedUp);
  }, [dispatch, searchValue, tiers]);

  if (!s3Status) {
    return <S3NotActiveTab />;
  }
  return (
    <>
      <div className="flex transition-all duration-500 items-center justify-between gap-3">
        <Text className="whitespace-nowrap font-medium">Tiers</Text>
        <div className="flex items-center justify-end gap-1 md:gap-2 ">
          <Button
            size="medium"
            type="light"
            buttonProps={{
              onClick: () => dispatch(getS3TiersAsync()),
            }}
            buttonClassName="group"
            tooltip="Refresh"
          >
            <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
          <Button
            type="primary"
            buttonProps={{ onClick: () => navigate(S3TierAddUrl) }}
            buttonClassName="group"
          >
            Create Tier
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>
      <div className="mt-5 w-full ">
        <Table loading={loading}>
          <TableHead>
            <TableRow>
              <TableTH lable="Tire Name" />
              <TableTH lable="Status" />
              <TableTH lable="Type" />
              <TableTH lable="Endpoint" />
              <TableTH lable="Bucket" />
              <TableTH lable="Prefix" />
              <TableTH lable="Region" />
              <TableTH lable="Usage" />
              <TableTH lable="Objects" />
              <TableTH lable="Versions" />
              <TableTH lable="" />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTiers.map((tier, i) => (
              <TableRow key={i}>
                <TableTD>
                  <div className="flex items-center flex-row justify-between space-x-4">
                    <Highlighter
                      highlightClassName="bg-primary-100 dark:bg-primary-300 rounded-sm"
                      className="whitespace-nowrap max-w-[110px] md:max-w-none text-ellipsis overflow-hidden text-sm font-medium"
                      searchWords={[searchValue]}
                      autoEscape={true}
                      textToHighlight={tier[tier.type].name}
                    />
                  </div>
                </TableTD>

                <TableTD>
                  <Text
                    type="title"
                    className={classNames("text-xs md:text-sm", {
                      "!text-red-500": !tier.status,
                    })}
                  >
                    {tier.status ? "Active" : "Inactive"}
                  </Text>
                </TableTD>
                <TableTD>
                  {tier.type && (
                    <img
                      src={`https://cdn.djuno.cloud/tiers/${tier.type}.svg`}
                      alt={tier.type}
                      className="w-8 h-8 rounded-full"
                    />
                  )}
                </TableTD>
                <TableTD>
                  <Text className="text-xs md:text-sm">
                    {tier[tier.type].endpoint.substring(0, 15) + "..."}
                  </Text>
                </TableTD>
                <TableTD>
                  <Text className="text-xs md:text-sm">
                    {tier[tier.type].bucket}
                  </Text>
                </TableTD>
                <TableTD>
                  <Text className="text-xs md:text-sm">
                    {tier[tier.type].prefix}
                  </Text>
                </TableTD>
                <TableTD>
                  {/* TODO */}
                  <Text className="text-xs md:text-sm">{""}</Text>
                </TableTD>
                <TableTD>
                  <Text className="text-xs md:text-sm">
                    {tier[tier.type].usage}
                  </Text>
                </TableTD>
                <TableTD>
                  <Text className="text-xs md:text-sm">
                    {tier[tier.type].objects}
                  </Text>
                </TableTD>
                <TableTD>
                  <Text className="text-xs md:text-sm">
                    {tier[tier.type].versions}
                  </Text>
                </TableTD>
                <TableTD className="w-32 gap-1">
                  <div className="h-full w-full inline-flex items-center justify-end gap-1">
                    <div className="w-8 flex justify-end items-center">
                      <Dropdown
                        positionClassName="!w-40"
                        menu={[
                          {
                            key: "1",
                            label: (
                              <div className="flex items-center gap-1">
                                <EditIcon className="w-4" />
                                Edit
                              </div>
                            ),
                            onClick: (_, close) => {
                              close();
                              dispatch(handleShowEditor({ tier }));
                            },
                          },
                          {
                            type: "divider",
                          },
                        ]}
                        type="simple"
                      >
                        <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                          <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                        </div>
                      </Dropdown>
                    </div>
                  </div>
                </TableTD>
              </TableRow>
            ))}

            {tiers.length === 0 && (
              <TableRow withoutHoverStyle={true}>
                <TableTD colSpan={11} className="!border-0">
                  <NotData text="You have not created any Tier yet" />
                </TableTD>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <UpdateCredentialsModal />
    </>
  );
};

export default S3TiersTab;
