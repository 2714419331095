import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as LogoTextIcon } from "./../../assets/icons/logo-text.svg";
// import { ReactComponent as HomeIcon } from "./../../assets/icons/home.svg";
// import { ReactComponent as CubeTransparentIcon } from "./../../assets/icons/cube-transparent.svg";
// import { ReactComponent as CubeIcon } from "./../../assets/icons/cube.svg";
import { ReactComponent as SettingIcon } from "./../../assets/icons/setting.svg";
import { ReactComponent as CreditCardIcon } from "./../../assets/icons/credit-card.svg";
import { ReactComponent as BookIcon } from "./../../assets/icons/book-open.svg";
import { ReactComponent as LifebouyIcon } from "./../../assets/icons/lifebouy.svg";
// import { ReactComponent as Square3StackIcon } from "./../../assets/icons/square-3stack.svg";
// import { ReactComponent as GlobeAltIcon } from "./../../assets/icons/globe-alt.svg";
// import { ReactComponent as PhotoIcon } from "./../../assets/icons/photo.svg";
// import { ReactComponent as BellAlertIcon } from "./../../assets/icons/bell-alert.svg";
// import { ReactComponent as LockClosedIcon } from "./../../assets/icons/lock-closed.svg";
// import { ReactComponent as ChainIcon } from "./../../assets/icons/chain.svg";
import classNames from "classnames";
import {
  BlockApiUrl,
  BlockEventNotificationsUrl,
  DatabaseServicesUrl,
  RpcEndpointsUrl,
  HomeUrl,
  InstancesUrl,
  IpfsFilesUrl,
  MarketApiUrl,
  NftApiUrl,
  S3BucketsUrl,
  SettingsBillingUrl,
  SettingsProfileUrl,
  VMsUrl,
  WalletApiUrl,
  Web3AuthEndpointsUrl,
  WebAppsUrl,
  WorkflowsUrl,
} from "../../utils/urls";
import Text from "../general/Text";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getServiceTypesAsync,
  selectServiceTypes,
  selectServiceTypesLoading,
} from "../../store/publicSlice";
import Loading from "../general/Loading";

export type SidebarProps = {
  segments: string[];
  show?: boolean;
};

export type SidebarItemProps = {
  id: string | number;
  serviceTypeId?: number;
  label?:
    | string
    | React.ReactNode
    | (({ isActive }: { isActive?: boolean }) => React.ReactNode);
  link?: string;
  onClick?: () => void;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  activeString: string | undefined;
  segmentIndex: number;
  disabled?: boolean;
  testId?: string;
};

const homeItem: SidebarItemProps = {
  id: "home",
  serviceTypeId: 0,
  label: "Home",
  link: HomeUrl,
  activeString: undefined,
  segmentIndex: 0,
  disabled: true,
  testId: "1th-sidebar",
};

const mainMenu: SidebarItemProps[] = [
  {
    id: "endpoints",
    serviceTypeId: 1,
    link: RpcEndpointsUrl,
    activeString: "rpc-endpoints",
    segmentIndex: 0,
    testId: "2th-sidebar",
  },
  {
    id: "web-apps",
    serviceTypeId: 2,
    link: WebAppsUrl,
    activeString: "web-apps",
    segmentIndex: 0,
    testId: "3th-sidebar",
  },
  {
    id: "workflow-studio",
    serviceTypeId: 3,
    link: WorkflowsUrl,
    activeString: "workflow-studio",
    segmentIndex: 0,
    testId: "4th-sidebar",
  },
  {
    id: "dapp-studio",
    serviceTypeId: 4,
    link: "",
    activeString: "app-studio",
    segmentIndex: 0,
    testId: "5th-sidebar",
  },
  {
    id: "blockchain-studio",
    serviceTypeId: 5,
    link: "",
    activeString: "blockchain-studio",
    segmentIndex: 0,
    testId: "6th-sidebar",
  },
  {
    id: "web3-auth",
    serviceTypeId: 6,
    link: Web3AuthEndpointsUrl,
    activeString: "web3-auth",
    segmentIndex: 0,
    testId: "7th-sidebar",
  },
  {
    id: "wallet-api",
    serviceTypeId: 7,
    link: WalletApiUrl,
    activeString: "wallet-api",
    segmentIndex: 0,
    testId: "8th-sidebar",
  },
  {
    id: "nft-api",
    serviceTypeId: 8,
    link: NftApiUrl,
    activeString: "nft-api",
    segmentIndex: 0,
    testId: "9th-sidebar",
  },
  {
    id: "market-api",
    serviceTypeId: 9,
    link: MarketApiUrl,
    activeString: "market-api",
    segmentIndex: 0,
    testId: "10th-sidebar",
  },
  {
    id: "block-api",
    serviceTypeId: 10,
    link: BlockApiUrl,
    activeString: "block-api",
    segmentIndex: 0,
    testId: "11th-sidebar",
  },
  {
    id: "block-events",
    serviceTypeId: 11,
    link: BlockEventNotificationsUrl,
    activeString: "block-events",
    segmentIndex: 0,
    testId: "12th-sidebar",
  },
  {
    id: "ipfs",
    serviceTypeId: 12,
    link: IpfsFilesUrl,
    activeString: "ipfs",
    segmentIndex: 0,
    testId: "13th-sidebar",
  },
  {
    id: "s3",
    serviceTypeId: 13,
    link: S3BucketsUrl,
    activeString: "s3",
    segmentIndex: 0,
    testId: "14th-sidebar",
  },
  {
    id: "database",
    serviceTypeId: 14,
    link: DatabaseServicesUrl,
    activeString: "database",
    segmentIndex: 0,
    testId: "15th-sidebar",
  },
  {
    id: "instance",
    serviceTypeId: 15,
    link: InstancesUrl,
    activeString: "instances",
    segmentIndex: 0,
    testId: "16th-sidebar",
  },
];

const subMenu: SidebarItemProps[] = [
  {
    id: "settings",
    label: "Settings",
    link: SettingsProfileUrl,
    icon: SettingIcon,
    activeString: "settings",
    segmentIndex: 0,
    testId: "17th-sidebar",
  },
  {
    id: "billing",
    label: "Usage & Billing",
    link: SettingsBillingUrl,
    icon: CreditCardIcon,
    activeString: "billing",
    segmentIndex: 0,
    testId: "18th-sidebar",
  },
  {
    id: "documentation",
    label: "Documentation",
    onClick: () => {
      window.open("https://docs.djuno.cloud");
    },
    icon: BookIcon,
    activeString: "documentation",
    segmentIndex: 0,
    testId: "19th-sidebar",
  },
  {
    id: "support",
    label: "Support",
    link: "/support",
    icon: LifebouyIcon,
    activeString: "support",
    segmentIndex: 0,
    testId: "20th-sidebar",
  },
];

const Sidebar = ({ segments, show }: SidebarProps) => {
  const serviceTypes = useAppSelector(selectServiceTypes);
  const serviceTypesLoading = useAppSelector(selectServiceTypesLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getServiceTypesAsync());
  }, [dispatch]);

  const sidebarItems = useMemo(() => {
    const _serviceTypes = [...serviceTypes];
    const serviceTypeItems = _serviceTypes
      .filter((st) => st.IsMenuItem)
      .filter((st) => st.IsActive)
      .sort((a, b) => a.OrdrIndx - b.OrdrIndx);

    let menuItems: SidebarItemProps[] = [];
    serviceTypeItems.forEach((serviceTypeItem) => {
      const data = mainMenu.find((i) => i.serviceTypeId === serviceTypeItem.Id);
      if (!data) return;
      menuItems.push({
        ...data,
        label: () => (
          <div className="flex items-center justify-between w-full gap-1">
            {serviceTypeItem.ServiceName}
            {serviceTypeItem.Tag && (
              <Text className="text-xs" type={"success-alert"}>
                {serviceTypeItem.Tag}
              </Text>
            )}
          </div>
        ),
        disabled: !serviceTypeItem.Enabled,
      });
    });
    return [...menuItems]; //homeItem,
  }, [serviceTypes]);

  const activeItem = sidebarItems.find(
    (item) => segments[item.segmentIndex] === item.activeString
  );
  const [pointerPosition, setPointerPosition] = useState(() => {
    if (!activeItem) return activeItem;
    return sidebarItems.indexOf(activeItem) * 36;
  });

  const [hover, setHover] = useState<string | number | undefined>();

  useEffect(() => {
    const item = sidebarItems.find((item) => item.id === hover) || activeItem;
    if (item) {
      setPointerPosition(sidebarItems.indexOf(item) * 36);
    }
  }, [activeItem, hover, sidebarItems]);

  const handleMouseEnter = (id: string | number) => {
    setHover(id);
  };

  const handleMouseLeave = () => {
    setHover(undefined);
  };

  return (
    <div
      className={classNames(
        "w-[300px] bg-white dark:bg-dark-3 md:border-r dark:border-dark-3 h-full fixed border-r-2 border-slate-200 transition-all duration-500 ease-in-out z-40",
        {
          "-left-[300px] lg:left-0": !show,
          "!left-0": show,
        }
      )}
    >
      <div className="flex w-full px-8 py-4 justify-between h-16 items-center">
        <div className="flex gap-2">
          <Link to={HomeUrl}>
            <LogoTextIcon className="text-black/60 dark:text-gray-300 h-9" />
          </Link>
        </div>
      </div>
      <div className="flex flex-col flex-grow justify-between overflow-y-auto w-full transition-height h-[calc(100vh-5rem)] pb-3">
        <div className="my-2 w-full relative">
          {activeItem && (
            <span
              className="w-1 h-9 block bg-primary-400 absolute transition-all top-0 rounded-r-md"
              style={{ top: pointerPosition }}
            />
          )}

          <div className="flex flex-col">
            {!serviceTypesLoading &&
              sidebarItems.map((menu, index) => (
                <SidebarMenuItem
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  key={index}
                  {...menu}
                  isActive={segments[menu.segmentIndex] === menu.activeString}
                  dataTestId={menu.testId}
                />
              ))}
            {serviceTypesLoading && (
              <Loading borderSize={2} className="!min-h-[150px]" />
            )}
          </div>
        </div>
        <div className="px-6 space-y-4 mb-5 mt-2">
          <div className="w-full h-[1px] bg-slate-200 dark:bg-slate-700 rounded-sm" />
          <div className="my-2 flex flex-col space-y-1">
            {subMenu.map((menu, index) => (
              <SidebarSubMenuItem
                key={index}
                {...menu}
                isActive={segments[menu.segmentIndex] === menu.activeString}
                dataTestId={menu.testId}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const SidebarMenuItem = (
  props: SidebarItemProps & {
    isActive?: boolean;
    onMouseEnter?: (label: string | number) => void;
    onMouseLeave?: () => void;
    dataTestId?: string;
  }
) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (!props.disabled) {
      if (props.link) {
        navigate(props.link);
      } else if (props.onClick) {
        props.onClick();
      }
    }
  };

  const renderLabel = () => {
    if (typeof props.label === "function") {
      return props.label({ isActive: props.isActive });
    } else {
      return (
        <div className="text-sm whitespace-nowrap w-full">{props.label}</div>
      );
    }
  };

  return (
    <div
      onClick={handleClick}
      onMouseLeave={props.onMouseLeave}
      onMouseEnter={() =>
        props.onMouseEnter ? props.onMouseEnter(props.id) : {}
      }
      className={classNames(
        "py-2 px-8 select-none flex transition duration-150 items-center gap-4 text-base font-medium group/sidebar-nav",
        {
          "hover:bg-primary-50 text-slate-400 hover:text-primary-500 dark:hover:bg-primary-400/10 dark:hover:text-primary-400":
            !props.isActive,
          "bg-primary-50 text-primary-500 dark:bg-primary-400/10 dark:text-primary-400 !font-semibold":
            props.isActive,
          "cursor-pointer": !props.disabled,
          "cursor-not-allowed": props.disabled,
        }
      )}
      test-cy={props.dataTestId}
    >
      {props.icon && (
        <props.icon
          className={classNames("w-6 h-6", {
            "group-hover/sidebar-nav:text-primary-400": !props.isActive,
            "text-primary-400": props.isActive,
          })}
        />
      )}
      <div className="text-sm whitespace-nowrap w-full">{renderLabel()}</div>
    </div>
  );
};

const SidebarSubMenuItem = (
  props: SidebarItemProps & { isActive?: boolean; dataTestId?: string }
) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (!props.disabled) {
      if (props.link) {
        navigate(props.link);
      } else if (props.onClick) {
        props.onClick();
      }
    }
  };

  const renderLabel = () => {
    if (typeof props.label === "function") {
      return props.label({ isActive: props.isActive });
    } else {
      return (
        <div className="text-sm whitespace-nowrap w-full">{props.label}</div>
      );
    }
  };

  return (
    <div
      onClick={handleClick}
      className={classNames(
        "h-8 px-2 rounded-md select-none flex transition duration-150 space-x-2 items-center text-sm",
        {
          "hover:bg-primary-50 text-slate-400 hover:text-slate-800 dark:hover:bg-primary-400/10 dark:hover:text-slate-100 ":
            !props.isActive,
          "bg-white dark:bg-primary-400/10 dark:text-primary-400  shadow-sm drop-shadow-md ring-1 ring-gray-200 dark:ring-0":
            props.isActive,
          "cursor-pointer": !props.disabled,
          "cursor-not-allowed": props.disabled,
        }
      )}
      test-cy={props.dataTestId}
    >
      {props.icon && (
        <props.icon
          className={classNames("w-5 h-5", {
            "dark:text-slate-400 hover:dark:text-slate-100": !props.isActive,
            "text-primary-300": props.isActive,
          })}
        />
      )}
      <div className="text-sm font-medium whitespace-nowrap">
        {renderLabel()}
      </div>
    </div>
  );
};
export default Sidebar;
