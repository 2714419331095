import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createS3EventAsync,
  getS3EventsAsync,
  selectActionLoading,
  selectLoading,
  selectSelectedS3Event,
} from "../../../../store/s3/eventsSlice";
import Button from "../../../buttons/Button";
import { S3MySqlEventSchema } from "../../../../utils/validations";
import { useEffect, useState } from "react";
import Switcher from "../../../inputs/Switcher";
import Input from "../../../inputs/Input";
import RadioGrouper, { RadioGroupeItem } from "../../../inputs/RadioGrouper";
import { InfoTooltip } from "../../../general/Tooltip";
import Textarea from "../../../inputs/Textarea";
import Text from "../../../general/Text";
import { useNavigate } from "react-router-dom";
import { S3EventsUrl } from "../../../../utils/urls";
import useGetS3Events from "../../../../hooks/useGetS3Events";

const formatOptions: RadioGroupeItem[] = [
  { label: "Namespace", value: "namespace" },
  { label: "Access", value: "access" },
];

const MySqlForm = () => {
  const event = useAppSelector(selectSelectedS3Event);
  const loading = useAppSelector(selectActionLoading);
  const eventsLoading = useAppSelector(selectLoading);

  const [useDndString, setUseDnsString] = useState<boolean>(false);
  const [selectedFormat, setSelectedFormat] = useState<RadioGroupeItem>(
    formatOptions[0]
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getS3Events = useGetS3Events();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(S3MySqlEventSchema(useDndString)),
  });

  const dbNameInputValue = watch("db_name");
  const hostInputValue = watch("host");
  const portInputValue = watch("port");
  const userInputValue = watch("user");
  const passwordInputValue = watch("password");
  const dsn_stringInputValue = watch("dsn_string");

  useEffect(() => {
    function parseConnectionString(
      connectionString: string
    ): Record<string, string> {
      const pattern = /([^:]+)?:([^@]+)?@tcp\(([^:]+)?:([^)]*)?\)\/([^/]+)?/;
      const match = connectionString.match(pattern);
      if (match) {
        const [, user, pass, host, port, dbname] = match;
        return {
          user: user || "",
          pass: pass || "",
          host: host || "",
          port: port || "",
          dbname: dbname || "",
        };
      } else {
        // Return default values if the pattern doesn't match
        return {
          user: "",
          pass: "",
          host: "",
          port: "",
          dbname: "",
        };
      }
    }

    if (dsn_stringInputValue) {
      const parsedDnsString = parseConnectionString(dsn_stringInputValue);
      // console.log("parsedDnsString:", parsedDnsString);
      setValue("db_name", parsedDnsString.dbname);
      setValue("user", parsedDnsString.user);
      setValue("password", parsedDnsString.pass);
      setValue("host", parsedDnsString.host);
      setValue("port", parsedDnsString.port);
    }
  }, [dsn_stringInputValue, setValue]);

  useEffect(() => {
    setValue(
      "dsn_string",
      `${userInputValue}:${passwordInputValue}@tcp(${hostInputValue}:${portInputValue})/${dbNameInputValue}`
    );
  }, [
    dbNameInputValue,
    hostInputValue,
    passwordInputValue,
    portInputValue,
    setValue,
    userInputValue,
  ]);

  useEffect(() => {
    if (event) {
    } else {
      setValue("dsn_string", ":@tcp(:)/");
      setSelectedFormat(formatOptions[0]);
    }
  }, [event, setValue]);

  const onSubmit = (data: any) => {
    let keyValues = [
      {
        key: "dsn_string",
        value: data.dsn_string,
      },
      {
        key: "table",
        value: data.table,
      },
      {
        key: "format",
        value: selectedFormat.value,
      },
      {
        key: "queue_dir",
        value: data.queue_dir,
      },
      {
        key: "comment",
        value: data.comment,
      },
    ];

    if (data.queue_limit !== null) {
      keyValues = [
        ...keyValues,
        {
          key: "queue_limit",
          value: data.queue_limit.toString(),
        },
      ];
    }
    const formData = {
      key_values: keyValues,
    };
    // if (event) {
    //   dispatch(
    //     updateS3EventAsync({
    //       id: event.Id,
    //       data: {
    //         label: data.label,
    //       },
    //     })
    //   ).then((action) => {
    //     if (action.type === "s3-access-keys/update/fulfilled") {
    //       // handleClearComponent();
    //       reset();
    //       dispatch(handleHideEditor());
    //       dispatch(getS3EventsAsync());
    //     }
    //   });
    // } else {
    dispatch(
      createS3EventAsync({
        event: "mysql",
        identifier: data.identifier,
        data: formData,
      })
    ).then((action) => {
      if (action.type === "s3-events/create/fulfilled") {
        getS3Events(() => {
          reset();
          navigate(S3EventsUrl);
        });
      }
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col w-full gap-5">
        <Input
          label="Identifier"
          tooltip="Unique descriptive string for this destination"
          placeholder="Enter Destination Identifier"
          inputProps={{ ...register("identifier") }}
          error={errors.identifier?.message}
          required
        />

        <div className="flex items-center justify-between w-full">
          <Text type="title" className="text-sm !font-medium">
            Enter DNS String
          </Text>
          <Switcher on={useDndString} onToggle={setUseDnsString} />
        </div>
        {!useDndString && (
          <div className="flex flex-col bg-gray-50 dark:bg-dark-1 dark:border-dark-2 border p-3 rounded-lg gap-3">
            <Input
              label="Host"
              placeholder="Enter Host"
              inputProps={{ ...register("host") }}
              error={errors.host?.message}
              required
            />
            <Input
              label="DB Name"
              placeholder="Enter DB Name"
              inputProps={{ ...register("db_name") }}
              error={errors.db_name?.message}
              required
            />
            <Input
              label="Port"
              placeholder="Enter Port"
              inputProps={{ ...register("port") }}
              error={errors.port?.message}
              required
            />
            <Input
              label="User"
              placeholder="Enter User"
              inputProps={{ ...register("user") }}
              error={errors.user?.message}
              required
            />
            <Input
              label="Password"
              placeholder="Enter Password"
              inputProps={{ ...register("password") }}
              error={errors.password?.message}
              required
            />
          </div>
        )}

        <Input
          label="Connection String"
          inputProps={{
            ...register("dsn_string"),
            disabled: !useDndString,
          }}
          error={errors.dsn_string?.message}
          required={useDndString}
        />

        <Input
          label="Table"
          tooltip="DB table name to store/update events, table is auto-created"
          placeholder="Enter Table Name"
          inputProps={{
            ...register("table"),
          }}
          error={errors.table?.message}
        />

        <div className="flex gap-5 justify-between">
          <div className="flex items-center gap-1">
            <div className="text-sm font-medium text-slate-800 dark:text-slate-50">
              Format
            </div>
            <InfoTooltip content="'namespace' reflects current bucket/object list and 'access' reflects a journal of object operations, defaults to 'namespace'" />
          </div>
          <RadioGrouper
            items={formatOptions}
            selected={selectedFormat}
            setSelected={setSelectedFormat}
          />
        </div>

        <Input
          label="Queue Directory"
          tooltip="Staging directory for undelivered messages e.g. '/home/events'"
          placeholder="ِEnter Queue Directory"
          inputProps={{ ...register("queue_dir") }}
          error={errors.queue_dir?.message}
        />

        <Input
          label="Queue Limit"
          tooltip="maximum limit for undelivered messages, defaults to '10000'"
          placeholder="ِEnter Queue Limit"
          inputProps={{ ...register("queue_limit"), type: "number" }}
          error={errors.queue_limit?.message}
        />

        <Textarea
          label="Comment"
          placeholder="Enter custom notes if any"
          textareaProps={{ ...register("comment") }}
          error={errors.comment?.message}
        />
      </div>
      <div className="mt-4 flex justify-end">
        <Button
          loading={loading || eventsLoading}
          type="primary"
          buttonProps={{
            disabled: loading || eventsLoading,
            type: "submit",
          }}
          buttonClassName="w-[100px]"
        >
          {event ? "Update" : "Create"}
        </Button>
      </div>
    </form>
  );
};
export default MySqlForm;
