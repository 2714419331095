import { useAppSelector } from "../../../../hooks";
import {
  selectDriveUsed,
  selectDriveUsedLoading,
} from "../../../../store/s3/overviewSlice";
import GraphWidget from "./freams/GraphWidget";
import { S3WidgetType } from "../../../../types/s3";

const chartType: S3WidgetType = "driveUsed";
const chartCode: number = 74;

const DriveUsedWidget = () => {
  const data = useAppSelector(selectDriveUsed);
  const loading = useAppSelector(selectDriveUsedLoading);

  return (
    <GraphWidget
      title="Drive Used Capacity"
      chartCode={chartCode}
      chartType={chartType}
      data={data}
      dataLoading={loading}
    />
  );
};

export default DriveUsedWidget;
