import Button from "../../buttons/Button";
import {
  createInstanceAutomatedBackupAsync,
  deleteInstanceBackupWorkflowProcessAsync,
  getInstanceSnapshotAsync,
  handleInstanceShowModal,
  selectInstance,
  selectInstanceLoading,
  selectInstanceSnapshops,
  getInstanceEnableBackupAsync,
  selectInstanceBackup,
  deleteInstanceBackupSnapshotAsync,
  selectInstanceSnapshopLoading,
  selectInstanceBackupActionLoading,
  selectInstanceBackupLoading,
} from "../../../store/instances/instanceSlice";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { InstancesCreateUrl, InstancesUrl } from "../../../utils/urls";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import InstancesBackupModal from "../dashboard/InstancesBackupModal";
import Text, { HighlighterText } from "../../general/Text";
import QuestionModal, { DeleteModal } from "../../modals/QuestionModal";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import Dropdown from "../../general/Dropdown";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import NotData from "../../layouts/NotData";
import { useSearch } from "../../../providers/SearchProvider";
import { getInstancesAsync } from "../../../store/instances/instancesSlice";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { InstanceSnapshot } from "../../../types/instance";
import Alert from "../../general/Alert";
import { ReactComponent as ErrorIcon } from "./../../../assets/icons/logs/error.svg";

const InstanceBackupsTab = () => {
  const { id } = useParams();
  const [showEnableBackupsModal, setShowEnableBackupsModal] =
    useState<boolean>(false);

  const [deleteBackup, setDeleteBackup] = useState<InstanceSnapshot | null>(
    null
  );
  const [imageId, setImageId] = useState<string>("");

  const instance = useAppSelector(selectInstance);
  const loading = useAppSelector(selectInstanceLoading);

  const snapshots = useAppSelector(selectInstanceSnapshops);
  const snapshotsLoading = useAppSelector(selectInstanceSnapshopLoading);

  const backup = useAppSelector(selectInstanceBackup);
  const backupLoading = useAppSelector(selectInstanceBackupLoading);
  const backupActionLoading = useAppSelector(selectInstanceBackupActionLoading);

  const [enableBackups, setEnableBackups] = useState<boolean>(
    !backup ? false : true
  );

  const [filteredSnapshots, setFilteredSnapshots] = useState<
    InstanceSnapshot[]
  >([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // console.log("snapshots", snapshots);
  // console.log("filteredSnapshots", filteredSnapshots);

  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (!instance) {
      dispatch(getInstancesAsync({ withoutLoading: false }));
    }
  }, [dispatch, instance]);

  useEffect(() => {
    if (instance) {
      dispatch(
        getInstanceEnableBackupAsync({
          instanceId: instance.id,
          regionName: instance.region,
        })
      ).then((action: any) => {
        if (
          action.type === "instances/enable/backup/fulfilled" &&
          action.payload &&
          action.payload.enableBackup.id
        ) {
          setImageId(action.payload.enableBackup.id);
          setEnableBackups(true);

          if (instance) {
            dispatch(
              getInstanceSnapshotAsync({ instanceId: instance.id })
            ).then((action: any) => {
              if (
                action.type === "instances/snapshot/fulfilled" &&
                action.payload
              ) {
                setFilteredSnapshots(action.payload.snapshots);
              }
            });
          }
        } else {
          setEnableBackups(false);
        }
      });
    }
  }, [dispatch, instance, imageId]);

  const handleDisableBackups = useCallback(() => {
    if (backup && imageId && instance) {
      dispatch(
        deleteInstanceBackupWorkflowProcessAsync({
          regionName: instance.region,
          backupWorkflowId: imageId,
        })
      ).then((action) => {
        if (action.type === "instance/backup/delete/fulfilled") {
          dispatch(
            getInstanceEnableBackupAsync({
              instanceId: instance.id,
              regionName: instance.region,
            })
          );
          setEnableBackups(false);
          setImageId("");
        }
      });
    }
  }, [backup, imageId, instance, dispatch]);

  useEffect(() => {
    if (instance) {
      dispatch(getInstanceSnapshotAsync({ instanceId: instance.id }));
    }
  }, [dispatch, instance]);

  useEffect(() => {
    if (snapshots) {
      const filtered = snapshots.filter((snapshot) =>
        snapshot.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredSnapshots(filtered);
    }
  }, [searchValue, snapshots]);

  return (
    <div>
      {instance && (
        <div className="mt-2 flex flex-col gap-4 w-full">
          <Text className="text-sm">
            Backups are automatic copies of youy servers disk.For every server
            there are seven slots fo backups. If all slots are full and an
            additional one is created, then the oldest backup will be deleted.
            We recommend that you power off your server before creating a backup
            to ensure data consistency on the disks. Enabling Backups for your
            server will cost 20% of your server plan per month
          </Text>
          <div className="flex items-center gap-3">
            <div>
              <Button
                size="small"
                buttonProps={{
                  onClick: () => {
                    dispatch(handleInstanceShowModal(instance));
                  },
                }}
              >
                Run Manual Backups
              </Button>
            </div>
            <>
              {!enableBackups ? (
                <div>
                  <Button
                    size="small"
                    buttonProps={{
                      onClick: () => {
                        setShowEnableBackupsModal(true);
                      },
                    }}
                  >
                    Enable Automated Backups
                  </Button>
                </div>
              ) : (
                <div>
                  <Button
                    size="small"
                    loading={backupActionLoading}
                    buttonProps={{
                      onClick: handleDisableBackups,
                    }}
                  >
                    Disable Automated Backups
                  </Button>
                </div>
              )}
            </>
          </div>

          {filteredSnapshots.length > 0 && (
            <div className="mt-5 w-full">
              <Table loading={snapshotsLoading || backupLoading}>
                <TableHead>
                  <TableRow>
                    <TableTH lable="Name" />
                    <TableTH lable="ID" />
                    <TableTH lable="Region" />
                    <TableTH lable="Size" />
                    <TableTH lable="Creation date" />
                    <TableTH lable="Status" />
                    <TableTH lable="" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredSnapshots.map((snapshot, i) => (
                    <TableRow key={i}>
                      <TableTD className="cursor-pointer">
                        <div className="flex flex-col gap-1">
                          <HighlighterText
                            searchValue={searchValue}
                            textToHighlight={snapshot.name}
                          />
                        </div>
                      </TableTD>
                      <TableTD className="cursor-pointer">
                        <Text className="text-xs md:text-sm whitespace-nowrap">
                          {snapshot.id}
                        </Text>
                      </TableTD>

                      <TableTD className="cursor-pointer">
                        <Text className="text-xs md:text-sm whitespace-nowrap">
                          {snapshot.region}
                        </Text>
                      </TableTD>

                      <TableTD className="cursor-pointer">
                        <Text className="text-xs md:text-sm whitespace-nowrap">
                          {snapshot.size.toFixed(2)} GiB
                        </Text>
                      </TableTD>

                      <TableTD className="cursor-pointer">
                        <Text className="text-xs md:text-sm whitespace-nowrap">
                          {snapshot.creationDate}
                        </Text>
                      </TableTD>

                      <TableTD className="cursor-pointer">
                        <Text
                          className="text-xs whitespace-nowrap"
                          type={
                            snapshot.status === "active"
                              ? "success-alert"
                              : "warning-alert"
                          }
                        >
                          {snapshot.status}
                        </Text>
                      </TableTD>

                      <TableTD className="w-32 gap-1">
                        <div className="h-full w-full inline-flex items-center justify-center gap-1">
                          <div className="w-8 flex justify-center items-center">
                            <Dropdown
                              positionClassName="!w-48"
                              menu={[
                                {
                                  key: "1",
                                  label: (
                                    <div className="flex items-center gap-1">
                                      {/* <DetailsIcon className="w-4" /> */}
                                      <PlusIcon className="w-4" />
                                      Create instance
                                    </div>
                                  ),
                                  onClick: () => navigate(InstancesCreateUrl),
                                  disabled: loading,
                                },

                                {
                                  type: "divider",
                                },
                                {
                                  key: "end",
                                  label: (
                                    <div className="flex items-center gap-1">
                                      <ArchiveIcon className="w-4" />
                                      Delete
                                    </div>
                                  ),
                                  danger: true,
                                  onClick: (_, close) => {
                                    close();
                                    setDeleteBackup(snapshot);
                                  },
                                  disabled:
                                    loading || snapshot.status !== "active",
                                },
                              ]}
                              type="simple"
                            >
                              <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                                <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                              </div>
                            </Dropdown>
                          </div>
                          {/* <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" /> */}
                        </div>
                      </TableTD>
                    </TableRow>
                  ))}

                  {filteredSnapshots.length === 0 && (
                    <TableRow withoutHoverStyle={true}>
                      <TableTD colSpan={9} className="!border-0">
                        <NotData text="You have not created any backup yet" />
                      </TableTD>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          )}
        </div>
      )}

      <InstancesBackupModal />

      <QuestionModal
        title="Enable Backups"
        description={
          <div className="flex flex-col">
            <Text className="mb-5">
              Enabling backups will be recurringly billed,for 20 % per month of
              your current plan.Our terms and conditions apply.
            </Text>
            <Alert
              type="warning"
              className="text-xs mb-3"
              icon={<ErrorIcon className="w-5 mr-1 aspect-square" />}
            >
              Volumes are not included in backups
            </Alert>
          </div>
        }
        isOpen={showEnableBackupsModal}
        onClose={() => {
          setShowEnableBackupsModal(false);
          setShowEnableBackupsModal(false);
        }}
        onConfirm={() => {
          setEnableBackups(true); //aslo show table
          setShowEnableBackupsModal(false);
          setShowEnableBackupsModal(false);
          if (instance) {
            dispatch(
              createInstanceAutomatedBackupAsync({
                regionName: instance?.region,
                data: {
                  instanceId: instance.id,
                  name: instance.name,
                  cron: "24 1 * * *",
                },
              })
            );
            dispatch(
              getInstanceEnableBackupAsync({
                instanceId: instance.id,
                regionName: instance.region,
              })
            ).then((action: any) => {
              if (
                action.type === "instances/enable/backup/fulfilled" &&
                action.payload
              ) {
                setImageId(action.payload.enableBackup.id);
              }
            });
            dispatch(
              getInstanceSnapshotAsync({ instanceId: instance.id })
            ).then((action: any) => {
              if (
                action.type === "instances/snapshot/fulfilled" &&
                action.payload
              ) {
                setFilteredSnapshots(action.payload.snapshots);
              }
            });
          }
        }}
        confirmButtonType="primary"
        confirmButtonText="Enable & Buy now"
        confirmButtonClassName="confirm-button-fit-content"
      />

      <DeleteModal
        title="Delete a Manual Backup"
        description={
          <div className="flex flex-col">
            <Text className="mb-5">
              Permanently delete
              {deleteBackup?.name} {deleteBackup?.creationDate}
            </Text>
            <Alert
              type="warning"
              className="text-xs mb-3"
              icon={<ErrorIcon className="w-5 mr-1 aspect-square" />}
            >
              All of the data on this instance backup will be lost.
            </Alert>
          </div>
        }
        isOpen={deleteBackup !== null}
        onClose={() => setDeleteBackup(null)}
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        onConfirm={() => {
          deleteBackup &&
            instance &&
            dispatch(
              deleteInstanceBackupSnapshotAsync({
                instanceId: instance.id,
                imageId: deleteBackup.id,
              })
            ).then((action) => {
              if (action.type === "instance/snapshot/delete/fulfilled") {
                getInstanceSnapshotAsync({ instanceId: instance.id });
                dispatch(getInstancesAsync({ withoutLoading: false }));
                setDeleteBackup(null);
              }
              setDeleteBackup(null);
            });
        }}
        loading={snapshotsLoading}
        confirmString={deleteBackup?.name}
      />
    </div>
  );
};

export default InstanceBackupsTab;
