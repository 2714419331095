import html2canvas from "html2canvas";

export const capitalizeFirstLetter = (
  inputString: string | undefined
): string => {
  if (typeof inputString === "undefined") return "";
  if (inputString.length === 0) {
    return inputString;
  }

  const firstLetter = inputString.charAt(0).toUpperCase();
  const restOfString = inputString.slice(1);

  return firstLetter + restOfString;
};

export const captureAndDownloadImage = (elementId: string) => {
  const element = document.getElementById(elementId);
  if (element) {
    html2canvas(element)
      .then((canvas) => {
        const dataURL = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = dataURL;
        link.download = "image.png";
        link.click();
      })
      .catch((error) => {
        console.error("Error capturing image:", error);
      });
  }
};

// Function to remove ANSI escape sequences from a string
export const removeANSIEscapeSequences = (str: string): string => {
  // eslint-disable-next-line no-control-regex
  return str.replace(/\u001B\[[0-9;]*m/g, "");
};

export const generateRandomName = (prefix = "") => {
  const s = [
    "charming",
    "young",
    "left",
    "short",
    "scarce",
    "quixotic",
    "halting",
    "silly",
    "laughable",
    "severe",
    "talented",
    "wrathful",
    "striped",
    "narrow",
    "quick",
    "dramatic",
    "overjoyed",
    "distinct",
    "impolite",
    "acid",
    "practical",
    "fretful",
    "savory",
    "cloudy",
    "fuzzy",
    "fresh",
    "informal",
    "yummy",
    "familiar",
    "breezy",
    "nine",
    "steady",
    "rich",
    "curved",
    "momentous",
    "calm",
    "nimble",
    "healthy",
    "wandering",
    "eatable",
    "nappy",
    "grubby",
    "tedious",
    "pumped",
    "cute",
    "splendid",
    "natural",
    "gainful",
    "curly",
    "decorous",
    "clear",
    "placid",
    "ambitious",
    "sincere",
    "majestic",
    "cautious",
    "fragile",
    "fast",
    "telling",
    "marked",
    "adorable",
    "shocking",
    "hulking",
    "lucky",
    "gleaming",
    "public",
    "tan",
  ];
  const r = [
    "peebles",
    "mayor",
    "queloz",
    "ashkin",
    "mourou",
    "strickland",
    "weiss",
    "barish",
    "thorne",
    "thouless",
    "haldane",
    "kosterlitz",
    "kajita",
    "mcdonald",
    "akasaki",
    "amano",
    "nakamura",
    "englert",
    "higgs",
    "haroche",
    "wineland",
    "perlmutter",
    "schmidt",
    "riess",
    "geim",
    "novoselov",
    "kao",
    "boyle",
    "smith",
    "nambu",
    "kobayashi",
    "maskawa",
    "fert",
    "grunberg",
    "mather",
    "smoot",
    "glauber",
    "hall",
    "hansch",
    "gross",
    "politzer",
    "wilczek",
    "abrikosov",
    "ginzburg",
    "leggett",
    "davis-jr",
    "koshiba",
    "giacconi",
    "cornell",
    "ketterle",
    "wieman",
    "alferov",
    "kroemer",
    "kilby",
    "t-hooft",
    "veltman",
    "laughlin",
    "störmer",
    "tsui",
    "chu",
    "cohen-tannoudji",
    "phillips",
    "lee",
    "osheroff",
    "richardson",
    "perl",
    "reines",
    "brockhouse",
    "shull",
    "hulse",
    "taylor-jr",
    "charpak",
    "de-gennes",
    "friedman",
    "kendall",
    "taylor",
    "ramsey",
    "dehmelt",
    "paul",
    "lederman",
    "schwartz",
    "steinberger",
    "bednorz",
    "muller",
    "ruska",
    "binnig",
    "rohrer",
    "von-klitzing",
    "rubbia",
    "van-der-meer",
    "chandrasekhar",
    "fowler",
    "wilson",
    "bloembergen",
    "schawlow",
    "siegbahn",
    "cronin",
    "fitch",
    "glashow",
    "salam",
    "weinberg",
    "kapitsa",
    "penzias",
    "wilson",
    "anderson",
    "mott",
    "van-vleck",
    "richter",
    "ting",
    "bohr",
    "mottelson",
    "rainwater",
    "ryle",
    "klaba",
    "hewish",
    "esaki",
    "giaever",
    "josephson",
    "bardeen",
    "cooper",
    "schrieffer",
    "gabor",
    "alfven",
    "neel",
    "gell-mann",
    "alvarez",
    "bethe",
    "kastler",
    "tomonaga",
    "schwinger",
    "feynman",
    "townes",
    "basov",
    "prokhorov",
    "wigner",
    "goeppert-mayer",
    "jensen",
    "landau",
    "hofstadter",
    "mossbauer",
    "glaser",
    "segre",
    "chamberlain",
    "cherenkov",
    "tamm",
    "frank",
    "yang",
    "lee",
    "shockley",
    "bardeen",
    "brattain",
    "lamb",
    "kusch",
    "born",
    "bothe",
    "zernike",
    "bloch",
    "purcell",
    "cockcroft",
    "walton",
    "powell",
    "yukawa",
    "blackett",
    "appleton",
    "bridgman",
    "pauli",
    "rabi",
    "stern",
    "lawrence",
    "fermi",
    "davisson",
    "thomson",
    "hess",
    "anderson",
    "chadwick",
    "schrodinger",
    "dirac",
    "heisenberg",
    "raman",
    "de-broglie",
    "richardson",
    "compton",
    "wilson",
    "perrin",
    "franck",
    "hertz",
    "siegbahn",
    "millikan",
    "bohr",
    "einstein",
    "guillaume",
    "stark",
    "planck",
    "barkla",
    "bragg",
    "von-laue",
    "kamerlingh-onnes",
    "dalen",
    "wien",
    "van-der-waals",
    "marconi",
    "braun",
    "lippmann",
    "michelson",
    "thomson",
    "lenard",
    "rayleigh",
    "becquerel",
    "curie",
    "lorentz",
    "zeeman",
    "rontgen",
    "alexander",
    "blagg",
    "burbidge",
    "burnell",
    "cannon",
    "connes",
    "cook",
    "couper",
    "crisp",
    "crooker",
    "faber",
    "feynman",
    "gay",
    "gaze",
    "hansen",
    "haynes",
    "kaltenegger",
    "klumpke",
    "leavitt",
    "leland",
    "natarajan",
    "porco",
    "rubin",
    "sitterly",
    "tarter",
    "tinsley",
    "ayrton",
    "borg",
    "cartwright",
    "chessell",
    "daubechies",
    "estrin",
    "faddeeva",
    "goldwasser",
    "boyd",
    "camero",
    "grosz",
    "koss",
    "kra",
    "hamilton",
    "hardcastle",
    "hirschberg",
    "holberton",
    "hopper",
    "kahn",
    "keldysh",
    "kwiatkowska",
    "lehr",
    "lemone",
    "liskov",
    "millington",
    "narlikar",
    "northcutt",
    "peter",
    "jones",
    "vaughan",
    "wrinch",
    "wing",
    "mirzakhani",
    "uhlenbeck",
  ];

  const rs = Math.floor(Math.random() * s.length);
  const rr = Math.floor(Math.random() * r.length);

  return `${prefix}-${s[rs]}-${r[rr]}`;
};

export function round(value: number, precision: number) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export const spliter = (data: string, spliter: string) => {
  return data
    .split(spliter)
    .map((n) => n.trim())
    .filter(Boolean);
};

export const getCountryFlag = (countryCode: string) => {
  return `${process.env.REACT_APP_FLAG_URL}/${countryCode}.svg`;
};
