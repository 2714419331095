import NotData from "./../../../components/layouts/NotData";
import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "./../../../hooks";
import {
  getAllRpcAsync,
  selectActionLoading,
  selectAllRpc,
  selectLoading,
  deleteRpcAsync,
  selectselectedRpc,
} from "./../../../store/rpc/rpcsSlice";
import { useEffect, useState } from "react";
import Button from "./../../../components/buttons/Button";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as DetailsIcon } from "./../../../assets/icons/bars-3-bottom-left.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import { useNavigate } from "react-router-dom";
import {
  RpcEndpointCreateUrl,
  RpcEndpontGetStartedUrl,
} from "./../../../utils/urls";
import Dropdown from "./../../../components/general/Dropdown";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "./../../../components/general/Table";
import QuestionModal, {
  DeleteModal,
} from "./../../../components/modals/QuestionModal";
import Text from "./../../../components/general/Text";
import { Rpc } from "./../../../types/rpc";
import { useSearch } from "./../../../providers/SearchProvider";
import Highlighter from "react-highlight-words";

const RpcEndpointsTab = () => {
  const [deleteRpcId, setDeleteRpcId] = useState<number | null>(null);
  const allRpc = useAppSelector(selectAllRpc);
  const [filteredRpc, setFilteredRpc] = useState<Rpc[]>([]);
  const loading = useAppSelector(selectLoading);
  const actionLoading = useAppSelector(selectActionLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    dispatch(getAllRpcAsync());
  }, [dispatch]);

  useEffect(() => {
    const lookedUpFiles = allRpc.filter((rpc) =>
      rpc.RpcName.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredRpc(lookedUpFiles);
  }, [dispatch, searchValue, allRpc]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | RPC Endpoints</title>
        <meta
          name="description"
          content="RPC-endpoints, or Remote Procedure Call endpoints, are a fundamental aspect of distributed computing systems, enabling communication between different components or services across a network"
        />
      </Helmet>
      <div className="">
        <div className="flex items-center justify-between">
          <div className="items-center justify-between flex flex-1 transition duration-150">
            {/* <Text className="font-medium text-lg">Endpoints</Text> */}
          </div>
          <div className="flex items-center gap-2">
            <Button
              size="medium"
              type="light"
              buttonProps={{
                onClick: () => dispatch(getAllRpcAsync()),
              }}
              buttonClassName="group"
              tooltip="Refresh"
            >
              <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>
            <Button
              type="primary"
              buttonProps={{
                onClick: () => navigate(RpcEndpointCreateUrl),
              }}
              buttonClassName="group"
            >
              Create Endpoint
              <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>
          </div>
        </div>

        <div className="mt-5 w-full">
          <Table loading={loading}>
            <TableHead>
              <TableRow>
                <TableTH lable="Name" />
                <TableTH lable="Status" />
                <TableTH lable="Network" />
                <TableTH lable="" />
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRpc.map((rpc, i) => (
                <TableRow
                  key={i}
                  onClick={() => navigate(RpcEndpontGetStartedUrl(rpc.Id))}
                >
                  <TableTD className="cursor-pointer">
                    <div className="flex items-center flex-row justify-between space-x-3">
                      <div className="w-6 h-6 rounded-full ring-0 flex items-center text-center justify-center">
                        <img
                          src={rpc.Icon}
                          alt={rpc.RpcName}
                          className="w-5 h-5"
                        />
                      </div>
                      <Highlighter
                        highlightClassName="bg-primary-100 dark:bg-primary-300 rounded-sm"
                        className="whitespace-nowrap max-w-[110px] md:max-w-none text-ellipsis overflow-hidden text-sm font-medium"
                        searchWords={[searchValue]}
                        autoEscape={true}
                        textToHighlight={rpc.RpcName}
                      />
                    </div>
                  </TableTD>
                  <TableTD className="cursor-pointer">
                    <Text
                      className="text-xs md:text-sm"
                      type={
                        rpc.RPCNodeStatus === "RUNNING"
                          ? "success-alert"
                          : rpc.RPCNodeStatus === "INPROGRESS"
                          ? "primary-alert"
                          : "warning-alert"
                      }
                    >
                      {rpc.RPCNodeStatus}
                    </Text>
                  </TableTD>

                  <TableTD className="cursor-pointer">
                    <Text className="text-xs md:text-sm">
                      {rpc.NetworkType}
                    </Text>
                  </TableTD>

                  <TableTD className="w-32 gap-1">
                    <div className="h-full w-full inline-flex items-center justify-center gap-1">
                      <div className="w-8 flex justify-center items-center">
                        <Dropdown
                          positionClassName="!w-40"
                          menu={[
                            {
                              key: "1",
                              label: (
                                <div className="flex items-center gap-1">
                                  <DetailsIcon className="w-4" />
                                  Get details
                                </div>
                              ),
                              onClick: () =>
                                navigate(RpcEndpontGetStartedUrl(rpc.Id)),
                              disabled: loading,
                            },
                            {
                              type: "divider",
                            },
                            {
                              key: "end",
                              label: (
                                <div className="flex items-center gap-1">
                                  <ArchiveIcon className="w-4" />
                                  Delete
                                </div>
                              ),
                              danger: true,
                              onClick: (_, close) => {
                                close();
                                setDeleteRpcId(rpc.Id);
                              },
                              disabled:
                                loading || rpc.RPCNodeStatus !== "RUNNING",
                            },
                          ]}
                          type="simple"
                        >
                          <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                            <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                          </div>
                        </Dropdown>
                      </div>
                      <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" />
                    </div>
                  </TableTD>
                </TableRow>
              ))}

              {allRpc.length === 0 && (
                <TableRow withoutHoverStyle={true}>
                  <TableTD colSpan={4} className="!border-0">
                    <NotData text="You have not created any endpoint yet" />
                  </TableTD>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <DeleteModal
          title="Delete RPC Endpoint"
          isOpen={deleteRpcId !== null}
          onClose={() => setDeleteRpcId(null)}
          description="To confirm, type 'DELETE' in the box below"
          confirmButtonText="Confirm"
          confirmButtonType="danger"
          confirmButtonClassName="w-full"
          onConfirm={() => {
            deleteRpcId &&
              dispatch(deleteRpcAsync({ id: deleteRpcId })).then((action) => {
                if (action.type === "rpc/delete/fulfilled") {
                  dispatch(getAllRpcAsync());
                } else {
                }
                setDeleteRpcId(null);
              });
          }}
          loading={actionLoading}
          confirmString={allRpc.find((rpc) => rpc.Id === deleteRpcId)?.RpcName}
        />
      </div>
    </>
  );
};
export default RpcEndpointsTab;
