import { useNavigate } from "react-router-dom";
import Button from "../../components/buttons/Button";
// import { ReactComponent as BellAlertIcon } from "./../../assets/icons/bell-alert.svg";
import { ReactComponent as CloseIcon } from "./../../assets/icons/close.svg";
import { ReactComponent as ArrowRightIcon } from "./../../assets/icons/arrow-right.svg";
import { ReactComponent as HashtagIcon } from "./../..//assets/icons/hashtag.svg";
import { DatabaseServicesUrl } from "../../utils/urls";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Control, Controller, useForm, useController } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatabaseServiceCreateSchema } from "../../utils/validations";
import {
  createDBSAsync,
  getDBSAvailabilityAsync,
  getDBSCapabilitiesAsync,
  getDBSCatalogAsync,
  selectDBSAvailability,
  selectDBSAvailabilityLoading,
  selectDBSCapabilities,
  selectDBSCapabilitiesLoading,
  selectDBSCatalog,
  selectDBSCatalogLoading,
  selectDatabaseServicesActionLoading,
} from "../../store/database/servicesSlice";
import {
  DatabaseService,
  DBSAddon,
  DBSAvailability,
  DBSCapabilities,
  DBSCreateApiData,
  DBSEngine,
  DBSGroupAvailability,
  DBSNodeType,
} from "../../types/database";
import { RadioGroup } from "@headlessui/react";
import Text from "../../components/general/Text";
import { AnimatePresence, motion } from "framer-motion";
import classNames from "classnames";
import { Select2 } from "../../components/inputs/Select";
import { binarySize, humanizeSize, biteSize } from "../../utils/file";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../components/general/Table";
import { capitalizeFirstLetter, generateRandomName } from "../../utils";
import Slider from "rc-slider";
import Input from "../../components/inputs/Input";
import { LoadingSpin } from "../../components/general/Loading";
import { InfoTooltip } from "../../components/general/Tooltip";
import Card from "../../components/general/Card";
import { getDBserviveData } from "./../../components/databases/services/DatabaseServicesTab";
import RadioGrouper, {
  RadioGroupeItem,
} from "../../components/inputs/RadioGrouper";
import { regionsData } from "../../components/instances/create/utils";

export const dbsPeriodItems: Array<RadioGroupeItem<"hour" | "month">> = [
  { label: "Hour", value: "hour" },
  { label: "Mounth", value: "month" },
];

const ServiceCreatePage = () => {
  const navigate = useNavigate();

  const availability = useAppSelector(selectDBSAvailability);
  const availabilityLoading = useAppSelector(selectDBSAvailabilityLoading);

  const capabilities = useAppSelector(selectDBSCapabilities);
  const capabilitiesLoading = useAppSelector(selectDBSCapabilitiesLoading);

  const actionLoading = useAppSelector(selectDatabaseServicesActionLoading);

  const catalog = useAppSelector(selectDBSCatalog);
  const catalogLoading = useAppSelector(selectDBSCatalogLoading);

  const [planAvailability, setPlanAvailability] = useState<
    Array<DBSAvailability>
  >([]);

  // selected planGroup
  const [selectedPlanGroup, setSelectedPlanGroup] = useState<
    DBSGroupAvailability | undefined
  >(undefined);

  const [serviceName, setServiceName] = useState("");

  // all regions having in the planGroup availabilities
  const [regions, setRegions] = useState<Array<string>>([]);

  // node types
  const [nodeTypes, setNodeTypes] = useState<Array<DBSNodeType>>([]);
  const [selectedDBSNodeType, setSelectedDBSNodeType] = useState<
    DBSNodeType | undefined
  >();

  // addons
  const [addons, setAddons] = useState<Array<DBSAddon>>([]);
  const [period, setPeriod] = useState<"month" | "hour">("hour");

  const dispatch = useAppDispatch();

  const {
    setValue,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(DatabaseServiceCreateSchema()),
    reValidateMode: "onChange",
  });

  // watch form values
  const selectedEngineName = watch("SelectedEngine");
  const selectedEngineVersion = watch("SelectedEngineVersion");
  const selectedPlan = watch("SelectedPlan");
  const selectedRegion = watch("SelectedRegion");
  const selectedNodeType = watch("SelectedNodeType");
  const numberOfNodes = watch("NumberOfNodes");
  const additionalStorage = watch("AdditionalStorage");

  //get first data
  useEffect(() => {
    dispatch(getDBSAvailabilityAsync());
    dispatch(getDBSCapabilitiesAsync());
    dispatch(getDBSCatalogAsync());
  }, [dispatch, reset]);

  // initial base form values
  useEffect(() => {
    if (capabilities) {
      setValue("SelectedEngine", capabilities.engines[0].name);
      setValue("SelectedEngineVersion", capabilities.engines[0].defaultVersion);
    }
  }, [capabilities, setValue]);

  // all operational engines
  const engines = useMemo(() => {
    if (capabilities && capabilities.engines) {
      return capabilities.engines.filter((e) => e.category === "operational");
    }
    return [];
  }, [capabilities]);

  // full data of selected engine
  const selectedEngine = useMemo(() => {
    if (capabilities && capabilities.engines && selectedEngineName) {
      return capabilities.engines.find((e) => e.name === selectedEngineName);
    }
  }, [capabilities, selectedEngineName]);

  // process plansAvailability after changing -> selected engine, selected version
  useEffect(() => {
    if (selectedEngine && selectedEngineVersion) {
      const plansAvailability = availability?.filter(
        (a) =>
          a.lifecycle.status === "STABLE" &&
          a.engine === selectedEngine.name &&
          a.network === "public" &&
          a.version === selectedEngineVersion
      );
      setPlanAvailability(plansAvailability || []);
      const serviceName = generateRandomName(selectedEngine.name);
      setServiceName(serviceName);
    }
  }, [availability, selectedEngine, selectedEngineVersion]);

  // make plan groups from planAvailability
  const planGruops: Array<DBSGroupAvailability> = useMemo(() => {
    if (capabilities) {
      const planGroups = groupAvailabilityByPlan(
        planAvailability,
        capabilities
      );
      setValue("SelectedPlan", planGroups[0]?.plan);
      setSelectedPlanGroup(planGroups[0]);
      return planGroups;
    } else {
      return [];
    }
  }, [capabilities, planAvailability, setValue]);

  // create main plan code
  const planCode = useMemo(() => {
    return `databases.${selectedEngineName}-${selectedPlan}-${selectedNodeType}.${period}.consumption`;
  }, [period, selectedEngineName, selectedNodeType, selectedPlan]);

  // find main addon
  const planAddon = useMemo(() => {
    return addons.find((a) => a.planCode === planCode);
  }, [addons, planCode]);

  // create additional storage plan code
  const additionnalStoragePlanCode = useMemo(() => {
    return `databases.${selectedEngineName}-${selectedPlan}-additionnal-storage-gb.${period}.consumption`;
  }, [period, selectedEngineName, selectedPlan]);

  // find additional-storage addon
  const additionalStoragePlanAddon = useMemo(() => {
    return addons.find((a) => a.planCode === additionnalStoragePlanCode);
  }, [addons, additionnalStoragePlanCode]);

  // set selectedPlanGroup state when selected plan is changing
  useEffect(() => {
    if (selectedPlan && planGruops) {
      const planGroup = planGruops.find((pg) => pg.plan === selectedPlan);
      setSelectedPlanGroup(planGroup);
    }
  }, [planGruops, selectedPlan]);

  // make regions and selected region from selectedPlanGroup
  useEffect(() => {
    if (selectedPlanGroup) {
      const regions = extractRegionsFromAvailabilities(
        selectedPlanGroup.availability
      );
      setRegions(regions);
      const prevSelectedRegion = selectedRegion;
      if (prevSelectedRegion && regions.includes(selectedRegion)) {
        setValue("SelectedRegion", prevSelectedRegion);
      } else {
        setValue("SelectedRegion", regions[0]);
      }
      setValue("NumberOfNodes", selectedPlanGroup.minNodeNumber);
    }
  }, [selectedPlanGroup, selectedRegion, setValue]);

  // make nodes by selected planGroup and selected region
  useEffect(() => {
    if (selectedPlanGroup && capabilities) {
      const availability = filterAvailabilitiesByRegion(
        selectedPlanGroup.availability,
        selectedRegion
      );
      const nodeTypes = getNodeTypesFromAvailability(
        availability,
        capabilities
      );
      if (nodeTypes && nodeTypes.length > 0) {
        setNodeTypes(nodeTypes);

        const prevNodeType = selectedNodeType;
        const nodeType = nodeTypes.find(
          (nt) => nt.flavor.name === prevNodeType
        );
        if (nodeType) {
          setValue("SelectedNodeType", prevNodeType);
          setSelectedDBSNodeType(nodeType);
        } else {
          setValue("SelectedNodeType", nodeTypes[0].flavor.name);
          setSelectedDBSNodeType(nodeTypes[0]);
        }
      }
    }
  }, [
    capabilities,
    selectedNodeType,
    selectedPlanGroup,
    selectedRegion,
    setValue,
  ]);

  //filter addons by selected engine and period
  useEffect(() => {
    if (catalog) {
      const planCode = `databases.${selectedEngineName}`;
      const filteredAddons = catalog.addons
        .filter((addon) => addon.planCode.includes(planCode))
        .filter((addon) => addon.planCode.includes(period));
      setAddons(filteredAddons);
    }
  }, [catalog, period, selectedEngineName]);

  // select node type
  const handleSelectNodeType = useCallback(
    (nodeType: DBSNodeType) => {
      const prevNodeType = selectedNodeType;
      if (prevNodeType !== nodeType.flavor.name) {
        setValue("SelectedNodeType", nodeType.flavor.name);
        setSelectedDBSNodeType(nodeType);
      }
    },
    [selectedNodeType, setValue]
  );

  const handleSubmitForm = (data: any) => {
    // console.log(data);
    const engine = data.SelectedEngine;
    const createData: DBSCreateApiData = {
      description: serviceName,
      nodesPattern: {
        flavor: data.SelectedNodeType,
        number: data.NumberOfNodes,
        region: data.SelectedRegion,
      },
      plan: data.SelectedPlan,
      version: data.SelectedEngineVersion,
    };

    if (data.AdditionalStorage > 0) {
      createData.disk = { size: data.AdditionalStorage };
    }

    dispatch(createDBSAsync({ engine, data: createData })).then((action) => {
      if (action.type === "services/create/fulfilled") {
        navigate(DatabaseServicesUrl);
      }
    });
  };

  return (
    <>
      <div className="flex items-center justify-between h-16 px-6 sticky top-0 z-20 bg-white dark:bg-dark-1 border-b dark:border-dark-2">
        <div className="items-center justify-between flex flex-1 transition duration-150">
          <div className="font-medium mr-2 text-standard text-md dark:text-slate-100 flex items-center gap-1">
            Create a database service
          </div>
        </div>
        <div className="">
          <Button
            type="light"
            size="small"
            buttonProps={{
              onClick: () => navigate(DatabaseServicesUrl),
            }}
            buttonClassName="group"
          >
            <CloseIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>

      {(availabilityLoading || capabilitiesLoading) && (
        <div className="h-full w-full flex items-center justify-center min-h-[calc(100vh-128px)]">
          <LoadingSpin borderSize={2} />
        </div>
      )}
      {!availabilityLoading && !capabilitiesLoading && (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="mt-10 w-full px-6 flex flex-col lg:flex-row pb-24 gap-8 relative">
            <div className="w-full lg:w-2/3 flex flex-col gap-10">
              <div>
                <Text className="text-lg font-medium">
                  Select your database type
                </Text>
                <Text className="text-sm mt-1">
                  Select a database type to suit your needs
                </Text>
                <div className="mt-3">
                  <DatabaseServiceTypesInput
                    engines={engines}
                    control={control}
                    errorMessage={errors.SelectedEngine?.message}
                  />

                  <div className="w-1/2">
                    <Text className="text-sm mt-1">Select Version</Text>
                    <Controller
                      name="SelectedEngineVersion"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Select2
                          label=""
                          className="mt-2"
                          options={
                            selectedEngine
                              ? [
                                  ...selectedEngine.versions.map((v) => ({
                                    label: v,
                                    value: v,
                                  })),
                                ]
                              : []
                          }
                          value={value}
                          onChange={onChange}
                          emptyString="Select a version"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="">
                <Text className="text-base font-medium">
                  Select a service plan
                </Text>
                <div className="mt-5">
                  <DatabaseServicePlansInput
                    control={control}
                    plans={planGruops}
                    addons={addons}
                    period={period}
                    errorMessage={errors.SelectedPlan?.message}
                  />
                </div>
              </div>

              <div className="">
                <Text className="text-lg font-medium">Select a region</Text>
                <div className="mt-5">
                  <DatabaseServiceRegionsInput
                    control={control}
                    regions={regions}
                    errorMessage={errors.SelectedPlan?.message}
                  />
                </div>
              </div>

              <div className="">
                <Text className="text-lg font-medium">Node type</Text>
                <Text className="text-sm mt-1">Select the node template</Text>
                <div className="mt-5">
                  <Table containerClassName="!min-h-min">
                    <TableHead>
                      <TableRow>
                        <TableTH lable="Type" />
                        <TableTH lable="vCores" />
                        <TableTH lable="Memory" />
                        <TableTH lable="Usable Storage" />
                        <TableTH lable={`Cost/${period}/node (estimated)`} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {nodeTypes.map((nodeType, index) => {
                        const {
                          flavor: {
                            specifications: { memory },
                            name: flavorName,
                          },
                          availability: {
                            specifications: { storage },
                          },
                        } = nodeType;

                        let Memory;
                        let minStorage;
                        let maxStorage;

                        if (memory) {
                          const memoryBinary = binarySize(
                            memory.value,
                            memory.unit.slice()[0],
                            1000
                          );
                          Memory = humanizeSize(memoryBinary, {
                            binaryBaseValue: 1000,
                          });
                        }

                        if (storage) {
                          const { minimum, maximum } = storage;
                          const minStorageBinary = binarySize(
                            minimum.value,
                            minimum.unit.slice()[0],
                            1000
                          );
                          minStorage = humanizeSize(minStorageBinary, {
                            binaryBaseValue: 1000,
                          });

                          const maxStorageBinary = binarySize(
                            maximum.value,
                            maximum.unit.slice()[0],
                            1000
                          );
                          maxStorage = humanizeSize(maxStorageBinary, {
                            binaryBaseValue: 1000,
                          });
                        }

                        const filteredAddons = addons.filter((addon) =>
                          addon.planCode.includes(
                            selectedPlan + "-" + flavorName
                          )
                        );

                        return (
                          <TableRow
                            key={index}
                            selected={nodeType.flavor.name === selectedNodeType}
                            className="cursor-pointer"
                            onClick={() => handleSelectNodeType(nodeType)}
                          >
                            <TableTD className="w-36">
                              <Text className="text-xs md:text-sm whitespace-nowrap">
                                {capitalizeFirstLetter(nodeType.flavor.name)}
                              </Text>
                            </TableTD>
                            <TableTD>
                              <Text className="text-xs md:text-sm">
                                {nodeType.flavor.specifications.core}
                              </Text>
                            </TableTD>
                            <TableTD>
                              <Text className="text-xs md:text-sm">
                                {Memory?.number && Memory?.join("B")}
                              </Text>
                            </TableTD>
                            <TableTD>
                              <Text className="text-xs md:text-sm whitespace-nowrap">
                                {minStorage && maxStorage && (
                                  <>
                                    {minStorage.number !== maxStorage.number ? (
                                      <>{`From ${minStorage.join(
                                        "B"
                                      )} to ${maxStorage.join("B")}`}</>
                                    ) : (
                                      <>{minStorage.join("B")}</>
                                    )}
                                  </>
                                )}
                              </Text>
                            </TableTD>
                            <TableTD>
                              <div className="flex items-center">
                                <Text className="text-xs font-medium">
                                  €
                                  {filteredAddons.length > 0
                                    ? getDBSPriceAmout(
                                        filteredAddons[0].pricings[0].price
                                      )
                                    : "0"}{" "}
                                  ex. VAT
                                </Text>
                                <Text className="text-[0.6rem]">
                                  (€
                                  {filteredAddons.length > 0
                                    ? getDBSPriceAmout(
                                        filteredAddons[0].pricings[0].price +
                                          filteredAddons[0].pricings[0].tax
                                      )
                                    : "0"}{" "}
                                  incl. VAT)
                                </Text>
                              </div>
                            </TableTD>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              </div>

              <div className="">
                <Text className="text-lg font-medium">Cluster sizing</Text>
                <Text className="text-base mt-2">Number of nodes</Text>
                {selectedPlanGroup && (
                  <NumberOfNodesInput
                    plan={selectedPlanGroup}
                    control={control}
                    errorMessage={errors.NumberOfNodes?.message}
                  />
                )}

                {selectedDBSNodeType && (
                  <AdditionalStorageInput
                    control={control}
                    nodeType={selectedDBSNodeType}
                    period={period}
                    nodeCount={numberOfNodes}
                    addon={additionalStoragePlanAddon}
                    errorMessage={errors.AdditionalStorage?.message}
                  />
                )}
              </div>
            </div>
            <div className="w-full lg:w-1/3">
              <Card title="Your order" className="sticky top-20">
                <div className="flex flex-col gap-3">
                  <div className="">
                    <Text className="text-sm font-semibold inline">Name:</Text>
                    <Text className="text-sm ml-1 break-words inline">
                      {serviceName}
                    </Text>
                    <span className="inline-block">
                      <InfoTooltip content="The name of your cluster is automatically generated. You can change it later." />
                    </span>
                  </div>

                  <div className="">
                    <Text className="text-sm font-semibold inline">
                      Service:
                    </Text>
                    <Text className="text-sm ml-1 break-words inline">
                      {capitalizeFirstLetter(selectedEngineName)}
                    </Text>
                    <div className="flex items-center gap-1">
                      <HashtagIcon className="ml-2 w-5 aspect-square text-slate-600 dark:text-slate-400" />
                      <Text className="text-sm">{`Version ${selectedEngineVersion}`}</Text>
                    </div>
                  </div>

                  <div className="">
                    <Text className="text-sm font-semibold inline">
                      Solution:
                    </Text>
                    <Text className="text-sm ml-1 break-words inline">
                      {capitalizeFirstLetter(selectedPlan)}
                    </Text>
                  </div>

                  <div className="">
                    <Text className="text-sm font-semibold inline">
                      Datacenter:
                    </Text>
                    <Text className="text-sm ml-1 break-words inline">
                      {selectedRegion}
                    </Text>
                  </div>

                  <div className="">
                    <Text className="text-sm font-semibold inline">
                      Node template:
                    </Text>
                    <Text className="text-sm ml-1 break-words inline">
                      {capitalizeFirstLetter(selectedNodeType)}
                    </Text>
                  </div>

                  <div className="">
                    <Text className="text-sm font-semibold inline">
                      Cluster:
                    </Text>
                    <div className="flex flex-col ml-2">
                      <Text className="text-sm ml-1 break-words inline">
                        {`${numberOfNodes} nodes`}
                      </Text>
                      <Text className="text-sm ml-1 break-words inline">
                        {selectedDBSNodeType &&
                          getClusterTotalStorage(
                            selectedDBSNodeType,
                            additionalStorage
                          )}
                      </Text>
                    </div>
                  </div>

                  <div className="my-3">
                    <RadioGrouper
                      items={dbsPeriodItems}
                      selected={dbsPeriodItems.find((i) => i.value === period)}
                      setSelected={(item) => setPeriod(item.value)}
                    />
                  </div>

                  <div className="">
                    <Text className="text-sm font-semibold inline">Price:</Text>
                    <div className="flex flex-col ml-2">
                      <div className="flex items-center flex-wrap">
                        <Text className="text-xs font-medium whitespace-nowrap">
                          €
                          {planAddon
                            ? getDBSPriceAmout(
                                (planAddon.pricings[0].price +
                                  +(additionalStoragePlanAddon
                                    ? additionalStoragePlanAddon.pricings[0]
                                        .price * Number(additionalStorage || 0)
                                    : 0)) *
                                  numberOfNodes
                              )
                            : "0"}{" "}
                          ex. VAT
                        </Text>
                        <Text className="text-[0.6rem]  whitespace-nowrap">
                          (€
                          {planAddon
                            ? getDBSPriceAmout(
                                (planAddon.pricings[0].price +
                                  planAddon.pricings[0].tax +
                                  (additionalStoragePlanAddon
                                    ? (additionalStoragePlanAddon.pricings[0]
                                        .price +
                                        additionalStoragePlanAddon.pricings[0]
                                          .tax) *
                                      Number(additionalStorage || 0)
                                    : 0)) *
                                  numberOfNodes
                              )
                            : "0"}{" "}
                          incl. VAT)
                        </Text>
                        <Text className="text-xs font-medium  whitespace-nowrap">
                          /{period}
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <div className="fixed bottom-0 right-0 left-0 flex items-center w-full h-16 border-t bg-white dark:bg-dark-1 dark:border-dark-2 px-6 md:pl-72">
            <div className="flex justify-end w-full">
              <Button
                type="primary"
                buttonProps={
                  {
                    // disabled: !isValid,
                    // onClick: () => setStep("SelectDeployingSourceStep"),
                  }
                }
                buttonClassName="group  w-[110px]"
                loading={actionLoading}
              >
                Order
                <ArrowRightIcon className="w-4 h-4 group-hover:scale-110 group-hover:translate-x-1 transition-all duration-300" />
              </Button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export const DatabaseServiceTypesInput: React.FC<{
  engines: DBSEngine[];
  control: Control<any>;
  errorMessage: string | undefined;
}> = ({ engines, control, errorMessage }) => {
  const {
    field: { onChange: onChangeVersion },
  } = useController({ name: "SelectedEngineVersion", control });

  return (
    <Controller
      name="SelectedEngine"
      control={control}
      render={({ field: { value, onChange } }) => (
        <RadioGroup
          value={value || null}
          onChange={(v) => {
            onChange(v);
            const selectedEngine = engines.find((e) => e.name === v);
            if (selectedEngine) onChangeVersion(selectedEngine.defaultVersion);
          }}
        >
          <div className="flex flex-col w-full">
            <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-5">
              {engines.map((engine, i) => (
                <RadioGroup.Option key={i} value={engine.name}>
                  {({ checked }) => {
                    const serviceData = getDBserviveData(engine.name);
                    return (
                      <div
                        className={classNames(
                          "h-full col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300 cursor-pointer",
                          {
                            "border-primary-400 dark:border-primary-400":
                              checked,
                          }
                        )}
                      >
                        <div className="flex items-center justify-between">
                          <Text className="text-sm font-medium">
                            {serviceData.title}
                          </Text>
                          <div className="w-16 aspect-square flex justify-center items-center">
                            {serviceData.Icon}
                          </div>
                        </div>
                        <Text className="text-xs mt-1" type="subtext">
                          {engine.description}
                        </Text>
                      </div>
                    );
                  }}
                </RadioGroup.Option>
              ))}
            </div>
            <AnimatePresence>
              {typeof errorMessage === "string" && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                >
                  <p className="mt-2 text-xs text-red-600 dark:text-red-500">
                    {errorMessage}
                  </p>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </RadioGroup>
      )}
    />
  );
};

export const DatabaseServicePlansInput: React.FC<{
  plans: DBSGroupAvailability[];
  control: Control<any>;
  addons: Array<DBSAddon>;
  period: string;
  errorMessage: string | undefined;
  disabledIndex?: number;
  selectedService?: DatabaseService | null;
}> = ({
  plans,
  control,
  addons,
  period,
  errorMessage,
  disabledIndex,
  selectedService,
}) => {
  return (
    <Controller
      name="SelectedPlan"
      control={control}
      render={({ field: { value, onChange } }) => (
        <RadioGroup
          value={value || null}
          onChange={(v) => {
            onChange(v);
          }}
        >
          <div className="flex flex-col w-full">
            <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-5">
              {plans.map((plan, i) => (
                <RadioGroup.Option
                  key={i}
                  value={plan.plan}
                  disabled={disabledIndex ? i < disabledIndex : false}
                >
                  {({ checked, disabled }) => {
                    let minDisk;
                    let maxDisk;

                    if (plan.minDiskSize && plan.minDiskSizeUnit) {
                      const minDiskBinary = binarySize(
                        plan.minDiskSize,
                        plan.minDiskSizeUnit.slice()[0],
                        1000
                      );
                      minDisk = humanizeSize(minDiskBinary, {
                        binaryBaseValue: 1000,
                      });
                    }

                    if (plan.maxDiskSize && plan.maxDiskSizeUnit) {
                      const maxDiskBinary = binarySize(
                        plan.maxDiskSize,
                        plan.maxDiskSizeUnit.slice()[0],
                        1000
                      );
                      maxDisk = humanizeSize(maxDiskBinary, {
                        binaryBaseValue: 1000,
                      });
                    }

                    let minMemory;
                    let maxMemory;
                    if (plan.minMemory && plan.minMemoryUnit) {
                      const minMemoryBinary = binarySize(
                        plan.minMemory,
                        plan.minMemoryUnit.slice()[0],
                        1000
                      );
                      minMemory = humanizeSize(minMemoryBinary, {
                        binaryBaseValue: 1000,
                      });
                    }

                    if (plan.maxMemory && plan.maxMemoryUnit) {
                      const maxMemoryBinary = binarySize(
                        plan.maxMemory,
                        plan.maxMemoryUnit.slice()[0],
                        1000
                      );
                      maxMemory = humanizeSize(maxMemoryBinary, {
                        binaryBaseValue: 1000,
                      });
                    }

                    const filteredAddons = addons.filter((addon) =>
                      addon.planCode.includes(
                        plan.plan +
                          "-" +
                          plan.availability[0].specifications.flavor
                      )
                    );
                    const exVAT =
                      filteredAddons.length > 0
                        ? getDBSPriceAmout(
                            filteredAddons[0].pricings[0].price *
                              (plan.minCore || 1)
                          )
                        : "0";
                    const inclVAT =
                      filteredAddons.length > 0
                        ? getDBSPriceAmout(
                            (filteredAddons[0].pricings[0].price +
                              filteredAddons[0].pricings[0].tax) *
                              (plan.minCore || 1)
                          )
                        : "0";

                    return (
                      <div
                        className={classNames(
                          "col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300 cursor-pointer h-full",
                          {
                            "border-primary-400 dark:border-primary-400":
                              checked,
                            "!cursor-not-allowed": disabled,
                          }
                        )}
                      >
                        <div className="flex items-center justify-between mb-2">
                          <Text className="text-sm">
                            {capitalizeFirstLetter(plan.plan)}
                          </Text>
                          {selectedService &&
                            selectedService.plan === plan.plan && (
                              <Text
                                className="text-xs ml-2"
                                type="success-alert"
                              >
                                Current solution
                              </Text>
                            )}
                        </div>
                        <div className="flex flex-col gap-1 border-t border-t-white/20 pt-2">
                          {minMemory && maxMemory && (
                            <Text className="text-xs mt-1" type="subtext">
                              {plan.minMemory === plan.maxMemory
                                ? `${minMemory.join("B")} RAM`
                                : `From ${minMemory.join(
                                    "B"
                                  )} to ${maxMemory.join("B")} RAM`}
                            </Text>
                          )}
                          {typeof plan.minCore !== "undefined" &&
                            typeof plan.maxCore !== "undefined" && (
                              <>
                                {plan.maxCore !== 0 && (
                                  <Text className="text-xs mt-1" type="subtext">
                                    {plan.minCore === plan.maxCore
                                      ? `${plan.minDiskSize} vCores`
                                      : `From ${plan.minCore} to ${plan.maxCore} vCores`}
                                  </Text>
                                )}
                              </>
                            )}
                          {minDisk && maxDisk && (
                            <Text className="text-xs mt-1" type="subtext">
                              {plan.minDiskSize === plan.maxDiskSize
                                ? `${minDisk.join("B")} Storage`
                                : `From ${minDisk.join("B")} to ${maxDisk.join(
                                    "B"
                                  )} Storage`}
                            </Text>
                          )}

                          <Text className="text-xs mt-1" type="subtext">
                            {plan.minNodeNumber === plan.maxNodeNumber
                              ? `${plan.minNodeNumber} nodes`
                              : `${plan.minNodeNumber} nodes included, up to ${plan.maxNodeNumber} nodes`}
                          </Text>
                          {plan.backups.length > 0 && (
                            <Text className="text-xs mt-1" type="subtext">
                              Manual and automatic backups
                            </Text>
                          )}
                        </div>
                        <div className="flex items-center border-t mt-2 pt-2 gap-0.5">
                          {Number(exVAT) > 0 && (
                            <Text className="text-xs">From </Text>
                          )}
                          <Text className="text-xs font-medium">
                            €{exVAT} ex. VAT
                          </Text>
                          <Text className="text-[0.6rem]">
                            (€
                            {inclVAT} incl. VAT)
                          </Text>
                          <Text className="text-xs font-medium">/{period}</Text>
                        </div>
                      </div>
                    );
                  }}
                </RadioGroup.Option>
              ))}
            </div>
            <AnimatePresence>
              {typeof errorMessage === "string" && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                >
                  <p className="mt-2 text-xs text-red-600 dark:text-red-500">
                    {errorMessage}
                  </p>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </RadioGroup>
      )}
    />
  );
};

export const DatabaseServiceRegionsInput: React.FC<{
  regions: string[];
  control: Control<any>;
  errorMessage: string | undefined;
  selectedService?: DatabaseService | null;
}> = ({ regions, control, errorMessage, selectedService }) => {
  return (
    <Controller
      name="SelectedRegion"
      control={control}
      render={({ field: { value, onChange } }) => (
        <RadioGroup value={value || null} onChange={onChange}>
          <div className="flex flex-col w-full">
            <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-5">
              {regions.map((region, i) => {
                const regionData = regionsData.find(
                  (rd) => rd.datacenterLocation === region
                );
                return (
                  <RadioGroup.Option key={i} value={region}>
                    {({ checked }) => (
                      <div
                        className={classNames(
                          "col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300 cursor-pointer",
                          {
                            "border-primary-400 dark:border-primary-400":
                              checked,
                          }
                        )}
                      >
                        <div className="flex items-center justify-between mb-2">
                          <Text className="text-sm">
                            {regionData ? regionData.title : region}
                          </Text>
                          <div className="flex items-center gap-2">
                            {selectedService &&
                              selectedService.nodes[0].region === region && (
                                <Text
                                  className="text-xs ml-2"
                                  type="success-alert"
                                >
                                  Current solution
                                </Text>
                              )}
                            {regionData && regionData.dataImage && (
                              <span
                                style={{
                                  backgroundImage: `url(${regionData.dataImage})`,
                                  backgroundSize: "contain",
                                  backgroundPosition: "50%",
                                }}
                                className="w-4 h-4 inline-block align-middle bg-no-repeat"
                              />
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col border-t border-slate-200 dark:border-gray-400/40 mt-2 pt-2 gap-0.5">
                          <Text type="subtext" className="text-xs">
                            {region.toUpperCase()}
                          </Text>
                        </div>
                      </div>
                    )}
                  </RadioGroup.Option>
                );
              })}
            </div>
            <AnimatePresence>
              {typeof errorMessage === "string" && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                >
                  <p className="mt-2 text-xs text-red-600 dark:text-red-500">
                    {errorMessage}
                  </p>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </RadioGroup>
      )}
    />
  );
};

export const NumberOfNodesInput: React.FC<{
  plan: DBSGroupAvailability;
  control: Control<any>;
  errorMessage: string | undefined;
}> = ({ plan, control, errorMessage }) => {
  const { minNodeNumber, maxNodeNumber } = plan;

  if (minNodeNumber === maxNodeNumber) {
    return (
      <Text className="text-sm mt-1">
        {`Your ${capitalizeFirstLetter(plan.plan)} solution includes
            ${plan.minNodeNumber} nodes.`}
      </Text>
    );
  }
  return (
    <div>
      <Controller
        name="NumberOfNodes"
        control={control}
        render={({ field: { value, onChange: nodeChanger } }) => {
          return (
            <div className="flex flex-col gap-2">
              <Text className="text-xs">
                Select the number of nodes in the cluster
              </Text>
              <Input
                className="!w-40"
                error={errorMessage}
                inputProps={{
                  value,
                  onChange: (e: any) => {
                    const v = Number(e.target.value);

                    if (v < maxNodeNumber) {
                      nodeChanger(v);
                    } else if (v >= maxNodeNumber) {
                      nodeChanger(maxNodeNumber);
                    }

                    if (v === 0) {
                      nodeChanger("");
                    } else if (v < minNodeNumber) {
                      nodeChanger(minNodeNumber);
                    }
                  },
                  type: "number",
                }}
              />
            </div>
          );
        }}
      />
    </div>
  );
};

export const AdditionalStorageInput: React.FC<{
  nodeType: DBSNodeType;
  control: Control<any>;
  period: string;
  nodeCount: number;
  addon?: DBSAddon;
  errorMessage?: string;
}> = ({ nodeType, control, period, addon, nodeCount, errorMessage }) => {
  const {
    availability: {
      flavor,
      specifications: { storage },
    },
  } = nodeType;

  const additionalStorageData = useMemo(() => {
    let additionalBinary;
    let stepValue;
    if (storage) {
      const { minimum, maximum, step } = storage;
      const minStorageBinary = binarySize(
        minimum.value,
        minimum.unit.slice()[0],
        1000
      );
      const maxStorageBinary = binarySize(
        maximum.value,
        maximum.unit.slice()[0],
        1000
      );
      additionalBinary = maxStorageBinary - minStorageBinary;
      stepValue = step ? step.value : 0;
    } else {
      additionalBinary = 0;
      stepValue = 0;
    }

    return {
      storage: humanizeSize(additionalBinary, {
        fractionDigits: 2,
        binaryBaseValue: 1000,
      }),
      binary: additionalBinary,
      step: stepValue,
    };
  }, [storage]);

  if (additionalStorageData.binary === 0) {
    return null;
  }

  return (
    <div>
      <Text className="text-base mt-5">Additional storage</Text>
      {storage && (
        <Text className="text-sm mt-1 mb-2">
          {`The ${capitalizeFirstLetter(flavor)} node model includes ${
            storage.minimum.value
          } ${
            storage.minimum.unit
          } storage, to which you can add up to ${additionalStorageData.storage.join(
            "B"
          )} of additional storage in increments of ${
            additionalStorageData.step
          } GB.`}
        </Text>
      )}
      <Controller
        name="AdditionalStorage"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <div className="flex items-center w-full gap-8">
              <div className="flex flex-1 flex-col">
                <div className="flex flex-col">
                  <div className="flex items-center justify-between">
                    <Text className="text-sm">None</Text>
                    <Text className="text-sm">
                      {additionalStorageData.storage.join("B")}
                    </Text>
                  </div>
                  <div className="mx-2">
                    <Slider
                      className=""
                      step={10}
                      min={0}
                      max={biteSize(additionalStorageData.binary, "G", 1000)}
                      onChange={(value) =>
                        onChange(Array.isArray(value) ? value[0] : value)
                      }
                      value={value}
                    />
                  </div>
                </div>
                <div className="flex justify-center">
                  <Text className="text-sm">
                    {value
                      ? humanizeSize(binarySize(value, "G", 1000), {
                          fractionDigits: 2,
                          binaryBaseValue: 1000,
                        }).join("B")
                      : "0"}
                  </Text>
                </div>
              </div>
              <div className="flex items-center w-1/6 flex-wrap">
                <Text className="text-xs font-medium whitespace-nowrap">
                  €
                  {addon
                    ? getDBSPriceAmout(
                        addon.pricings[0].price *
                          (Number(value || 0) * nodeCount)
                      )
                    : "0"}{" "}
                  ex. VAT
                </Text>
                <Text className="text-[0.6rem]  whitespace-nowrap">
                  (€
                  {addon
                    ? getDBSPriceAmout(
                        (addon.pricings[0].price + addon.pricings[0].tax) *
                          (Number(value || 0) * nodeCount)
                      )
                    : "0"}{" "}
                  incl. VAT)
                </Text>
                <Text className="text-xs font-medium  whitespace-nowrap">
                  /{period}
                </Text>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

export function groupAvailabilityByPlan(
  availability: Array<DBSAvailability>,
  capabilities: DBSCapabilities
): Array<DBSGroupAvailability> {
  const planGroups: { [key: string]: DBSGroupAvailability } = {};

  availability.forEach((item) => {
    const {
      plan,
      flavor: flavorName,
      minNodeNumber,
      maxNodeNumber,
      backups,
      network,
    } = item;

    let minCore: number | undefined;
    let maxCore: number | undefined;
    let minDiskSize: number | undefined;
    let maxDiskSize: number | undefined;
    let minDiskSizeUnit: string | undefined;
    let maxDiskSizeUnit: string | undefined;
    let minMemory: number | undefined;
    let maxMemory: number | undefined;
    let minMemoryUnit: string | undefined;
    let maxMemoryUnit: string | undefined;

    // Find the corresponding flavor object
    const flavor = capabilities.flavors.find(
      (flavor) => flavor.name === flavorName
    );

    if (flavor) {
      minCore = flavor.core;
      maxCore = flavor.core;

      // Check if storage information exists in specifications
      if (flavor.specifications.storage) {
        minDiskSize = flavor.specifications.storage.value;
        maxDiskSize = flavor.specifications.storage.value;
        minDiskSizeUnit = flavor.specifications.storage.unit;
        maxDiskSizeUnit = flavor.specifications.storage.unit;
      }

      // Check if memory information exists in specifications
      if (flavor.specifications.memory) {
        minMemory = flavor.specifications.memory.value;
        maxMemory = flavor.specifications.memory.value;
        minMemoryUnit = flavor.specifications.memory.unit;
        maxMemoryUnit = flavor.specifications.memory.unit;
      }
    }

    if (!planGroups[plan]) {
      planGroups[plan] = {
        plan,
        availability: [],
        minNodeNumber,
        maxNodeNumber,
        minCore,
        maxCore,
        minDiskSize,
        maxDiskSize,
        minDiskSizeUnit,
        maxDiskSizeUnit,
        minMemory,
        maxMemory,
        minMemoryUnit,
        maxMemoryUnit,
        backups: [],
        networks: [],
      };
    } else {
      if (minCore !== undefined && maxCore !== undefined) {
        planGroups[plan].minCore = Math.min(planGroups[plan].minCore!, minCore);
        planGroups[plan].maxCore = Math.max(planGroups[plan].maxCore!, maxCore);
      }
      if (minDiskSize !== undefined && maxDiskSize !== undefined) {
        planGroups[plan].minDiskSize = Math.min(
          planGroups[plan].minDiskSize!,
          minDiskSize
        );
        planGroups[plan].maxDiskSize = Math.max(
          planGroups[plan].maxDiskSize!,
          maxDiskSize
        );
        planGroups[plan].minDiskSizeUnit = minDiskSizeUnit!;
        planGroups[plan].maxDiskSizeUnit = maxDiskSizeUnit!;
      }
      if (minMemory !== undefined && maxMemory !== undefined) {
        planGroups[plan].minMemory = Math.min(
          planGroups[plan].minMemory!,
          minMemory
        );
        planGroups[plan].maxMemory = Math.max(
          planGroups[plan].maxMemory!,
          maxMemory
        );
        planGroups[plan].minMemoryUnit = minMemoryUnit!;
        planGroups[plan].maxMemoryUnit = maxMemoryUnit!;
      }
      planGroups[plan].minNodeNumber = Math.min(
        planGroups[plan].minNodeNumber,
        minNodeNumber
      );
      planGroups[plan].maxNodeNumber = Math.max(
        planGroups[plan].maxNodeNumber,
        maxNodeNumber
      );
    }

    if (backups.available && backups.available === true) {
      planGroups[plan].backups.push(item.backup);
    }

    if (network) {
      planGroups[plan].networks.push(network);
    }

    planGroups[plan].availability.push(item);
  });

  // Ensure backups array contains only unique values
  Object.values(planGroups).forEach((group) => {
    group.backups = Array.from(new Set(group.backups));
    group.networks = Array.from(new Set(group.networks));
  });

  // Order the plan groups based on the order defined in the plans array
  const orderedPlanGroups: Array<DBSGroupAvailability> = capabilities.plans
    .map((plan) => planGroups[plan.name])
    .filter((group) => group !== undefined);

  return orderedPlanGroups;
}

export function extractRegionsFromAvailabilities(
  availabilities: DBSAvailability[]
): string[] {
  const regionsSet: Set<string> = new Set();
  availabilities.forEach((availability) => regionsSet.add(availability.region));
  return Array.from(regionsSet);
}

export function filterAvailabilitiesByRegion(
  availabilities: DBSAvailability[],
  region: string
): DBSAvailability[] {
  return availabilities.filter(
    (availability) => availability.region === region
  );
}

export function getNodeTypesFromAvailability(
  availabilities: DBSAvailability[],
  capabilities: DBSCapabilities
): DBSNodeType[] {
  const nodeTypes: DBSNodeType[] = [];

  availabilities.forEach((availability) => {
    const flavorName = availability.flavor;
    const flavor = capabilities.flavors.find(
      (flavor) => flavor.name === flavorName
    );

    if (flavor) {
      const nodeType: DBSNodeType = {
        flavor: flavor,
        availability: availability,
      };
      nodeTypes.push(nodeType);
    }
  });

  return nodeTypes;
}

export function getClusterTotalStorage(
  nodeType: DBSNodeType,
  additionalStorage: number | null | undefined
) {
  const {
    availability: {
      specifications: { storage },
    },
  } = nodeType;

  if (storage) {
    const { minimum } = storage;

    const clusterStorage = humanizeSize(
      binarySize(minimum.value, minimum.unit.slice()[0], 1000),
      { binaryBaseValue: 1000 }
    );
    if (additionalStorage) {
      const totalStorage =
        Number(clusterStorage.number) + Number(additionalStorage);
      return `${humanizeSize(binarySize(totalStorage, "G", 1000), {
        fractionDigits: 2,
        binaryBaseValue: 1000,
      }).join("B")} (${clusterStorage.join("B")})`;
    }
    return clusterStorage.join("B");
  }
  return "";
}

export const getDBSPriceAmout = (price: number, toFixed: number = 3) => {
  return (price / 10 ** 8).toFixed(toFixed);
};
export default ServiceCreatePage;
