import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Button from "../../buttons/Button";

import { ReactComponent as UploadIcon } from "./../../../assets/icons/clouds/upload.svg";
import { ReactComponent as FailedIcon } from "./../../../assets/icons/clouds/failed.svg";
import { ReactComponent as SuccessIcon } from "./../../../assets/icons/clouds/success.svg";
import { ReactComponent as TagIcon } from "./../../../assets/icons/tag.svg";
import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right-circle.svg";
import { ReactComponent as CopyIcon } from "./../../../assets/icons/copy.svg";

import {
  getWebAppEventsAsync,
  selectWebApp,
  selectWebAppEvents,
  selectWebAppEventsLoading,
  selectWebAppLoading,
} from "../../../store/web-app/webAppSlice";
import Text, { A } from "../../general/Text";
import NotData from "../../layouts/NotData";
import Loading from "../../general/Loading";
import { formatTimestamp } from "../../../utils/date";
import { Link } from "react-router-dom";
import { WebAppDeployUrl, WebAppPlanUrl } from "../../../utils/urls";
import { copyToClipboard } from "../../../utils/copy";
import Tooltip from "../../general/Tooltip";

const WebAppOverviewTab = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);

  const events = useAppSelector(selectWebAppEvents);
  const eventsLoading = useAppSelector(selectWebAppEventsLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (webApp) {
      // dispatch(getWebAppEventsAsync(webApp.Id.toString()));
    }
  }, [dispatch, webApp]);

  return (
    <>
      <div className="flex flex-col gap-3 mb-5">
        <div className="flex items-center gap-2">
          <Text className="text-xl font-medium mr-2">{webApp?.Name}</Text>
          <Text type="neutral-alert" className="!text-sm">
            Image
          </Text>
          <Text type="primary-alert" className="!text-sm">
            Free
          </Text>
          <Link
            className="cursor-pointer text-sky-500 hover:text-sky-600"
            to={WebAppPlanUrl(webApp?.Id.toString())}
          >
            <Text
              type="transparent"
              className="text-sm font-light flex items-center gap-0.5"
            >
              Upgrade your instance
              <ArrowRightIcon className="w-4 aspect-square" />
            </Text>
          </Link>
        </div>
        <div className="flex items-center gap-2">
          <A
            href={webApp?.URL || undefined}
            target="_blank"
            className="text-sm"
          >
            {webApp?.URL}
          </A>
          <Tooltip content="Copy to clipboard" place="right">
            <CopyIcon
              className="w-4 cursor-pointer text-sky-500 hover:text-sky-600"
              onClick={() => webApp && copyToClipboard(webApp.URL || "")}
            />
          </Tooltip>
        </div>
      </div>
      <ul className="mt-2 border dark:border-dark-2 rounded-lg bg-white dark:bg-dark-3">
        {eventsLoading && <Loading borderSize={2} className="min-h-[300px]" />}
        {!eventsLoading && events.length === 0 && <NotData />}
        {!eventsLoading &&
          events.length > 0 &&
          events.map((event, index) => (
            <li
              key={index}
              className="p-4 border-b last:border-b-0 dark:border-dark-2"
            >
              <div className="flex justify-between items-center">
                <div className="flex items-center flex-1 gap-4">
                  <span>
                    {event.type === "deploy" && (
                      <UploadIcon className="w-5 aspect-square text-secondary-400 dark:text-secondary-300" />
                    )}
                    {event.type === "failed" && (
                      <FailedIcon className="w-5 aspect-square text-secondary-400 dark:text-secondary-300" />
                    )}
                    {event.type === "live" && (
                      <SuccessIcon className="w-5 aspect-square text-secondary-400 dark:text-secondary-300" />
                    )}
                  </span>
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-3">
                      <Text className="text-sm flex items-center gap-1">
                        <Link
                          className="cursor-pointer text-sky-500 hover:text-sky-600"
                          to={WebAppDeployUrl(
                            webApp?.Id.toString(),
                            event.deployId
                          )}
                        >
                          Deploy
                        </Link>
                        {event.type === "deploy" && <>started</>}
                        {event.type === "failed" && <>failed</>}
                        {event.type === "live" && <>live</>} for{" "}
                        <span
                          title={`sha256:${event.commitId}`}
                          className="font-mono"
                        >
                          {event.commitId.substring(0, 7)}
                        </span>
                      </Text>
                      <Text className="text-xs flex items-center gap-1 font-mono">
                        <TagIcon className="w-4" />
                        {event.branchName}
                      </Text>
                    </div>
                    {event.message && (
                      <div className="flex items-center gap-3">
                        <Text className="text-sm flex items-center gap-1 font-light">
                          {event.message}
                          {". "}
                          {event.type === "failed" && (
                            <>
                              Check your{" "}
                              <Link
                                className="cursor-pointer text-sky-500 hover:text-sky-600"
                                to={WebAppDeployUrl(
                                  webApp?.Id.toString(),
                                  event.deployId
                                )}
                              >
                                deploy logs
                              </Link>{" "}
                              for more information.
                            </>
                          )}
                        </Text>
                      </div>
                    )}
                    <Text type="subtext" className="text-xs">
                      {
                        formatTimestamp(
                          event.createdAt,
                          "MMMM DD, YYYY [at] hh:mm A"
                        ).datetime
                      }
                    </Text>
                  </div>
                </div>
                <div className="flex items-center">
                  {event.type === "failed" && (
                    <Button buttonProps={{ disabled: true }}>Rollback</Button>
                  )}
                </div>
              </div>
            </li>
          ))}
      </ul>
    </>
  );
};

export default WebAppOverviewTab;
