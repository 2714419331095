import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  selectMarketApiActionLoading,
  selectSelectedMarketApiApiKey,
  selectShowMarketApiEditorModal,
  handleHideMarketApiEditorModal,
  createMarketApiApiKeyAsync,
  updateMarketApiApiKeyAsync,
  getMarketApiApiKeysAysnc,
} from "../../../store/market-api/apiKeysSlice";
import { MarketApiApiKeySchema } from "../../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Modal from "../../modals/Modal";
import Input from "../../inputs/Input";
import Button from "../../buttons/Button";
import { useEffect } from "react";

const ApiKeyEditorModal = () => {
  const isOpen = useAppSelector(selectShowMarketApiEditorModal);
  const loading = useAppSelector(selectMarketApiActionLoading);
  const apiKey = useAppSelector(selectSelectedMarketApiApiKey);
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(MarketApiApiKeySchema),
  });

  useEffect(() => {
    if (isOpen && apiKey) {
      setValue("label", apiKey.Label);
    }
  }, [isOpen, apiKey, setValue]);

  const onSubmit = (data: any) => {
    if (apiKey) {
      dispatch(
        updateMarketApiApiKeyAsync({
          id: apiKey.Id,
          formData: {
            label: data.label,
          },
        })
      ).then((action) => {
        if (action.type === "market-api/api-keys/update/fulfilled") {
          reset();
          dispatch(handleHideMarketApiEditorModal());
          dispatch(getMarketApiApiKeysAysnc());
        }
      });
    } else {
      dispatch(
        createMarketApiApiKeyAsync({
          label: data.label,
        })
      ).then((action) => {
        if (action.type === "market-api/api-keys/create/fulfilled") {
          reset();
          dispatch(handleHideMarketApiEditorModal());
          dispatch(getMarketApiApiKeysAysnc());
        }
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleHideMarketApiEditorModal());
        reset();
      }}
      contentClassName="max-w-lg"
      //   containerClassName="!items-start"
      title={apiKey ? "Update api key" : "Create new api key"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col w-full gap-5">
          <Input
            label="Api key label"
            inputProps={{ ...register("label") }}
            error={errors.label?.message}
            placeholder=""
          />
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            loading={loading}
            type="primary"
            buttonProps={{
              disabled: loading,
              type: "submit",
            }}
            buttonClassName="w-[100px]"
          >
            {apiKey ? "Update" : "Create"}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ApiKeyEditorModal;
