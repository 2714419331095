import { Listbox, Transition } from "@headlessui/react";
import React, { FocusEvent, Fragment, useMemo } from "react";
import { ReactComponent as CheckIcon } from "./../../assets/icons/check.svg";
import { ReactComponent as ChevronUpDownIcon } from "./../../assets/icons/chevron-up-down.svg";
import { ReactComponent as ClearIcon } from "./../../assets/icons/close.svg";
import { motion, AnimatePresence } from "framer-motion";
import classNames from "classnames";
import { InfoTooltip } from "./../general/Tooltip";
import { LoadingSpin } from "./../general/Loading";
import { useFloating, shift, flip } from "@floating-ui/react-dom";
import Text from "./../general/Text";
import NotData from "../layouts/NotData";

type SelectProps<T> = {
  options: SelectOption<T>[];
  selected?: SelectOption<T>;
  setSelected?: (option: SelectOption<T> | undefined) => void;
  name?: string;
  clearable?: boolean;
  error?: string;
  label?: string;
  required?: boolean;
  hint?: string;
  tooltip?: string;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  emptyString?: string;
};

export type SelectOption<T = string | number, ET = any> = {
  label: string | React.ReactNode;
  value: T;
  extraElement?: React.ReactNode;
  extraData?: ET;
  disabled?: boolean;
};

const Select = <T extends string | number | object>(
  selectProps: SelectProps<T>
) => {
  const { refs, floatingStyles } = useFloating({
    placement: "bottom-end",
    middleware: [flip(), shift()],
  });

  const handleClear = (e: React.MouseEvent<HTMLOrSVGElement, MouseEvent>) => {
    e.stopPropagation();
    selectProps.setSelected && selectProps.setSelected(undefined);
  };
  return (
    <div
      className={classNames("flex flex-col", {
        [`${selectProps.className}`]: selectProps.className,
      })}
    >
      <div
        className={classNames("flex items-center", {
          "justify-between": selectProps.label,
          "justify-end": !selectProps.label,
        })}
      >
        <label
          // htmlFor={id}
          className={classNames(
            "flex items-center gap-1 text-sm text-slate-800 dark:text-slate-50 whitespace-nowrap",
            {
              "text-red-700 dark:text-red-500": selectProps.error,
              "mb-1": selectProps.label,
            }
          )}
        >
          {selectProps.label}
          {selectProps.required && <span className="text-red-500">*</span>}
          {selectProps.tooltip && <InfoTooltip content={selectProps.tooltip} />}
        </label>
        {selectProps.hint && (
          <span className="text-xs mb-1 text-slate-500">
            {selectProps.hint}
          </span>
        )}
      </div>
      <Listbox
        value={selectProps.selected || null}
        onChange={selectProps.setSelected}
        disabled={selectProps.disabled}
        by="value"
        name={selectProps.name}
      >
        <div className="relative">
          <Listbox.Button
            ref={refs.setReference}
            className={classNames(
              "border-2 min-h-[40px] relative text-sm text-left rounded-lg w-full p-2.5 bg-secondary-100 disabled:bg-secondary-300  disabled:text-secondary-400 dark:bg-dark-2 disabled:dark:bg-secondary-500 outline-none disabled:cursor-not-allowed",
              {
                " border-red-500 text-red-900 placeholder-red-700   focus:border-red-500 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500":
                  selectProps.error,
                "    dark:border-dark-2 dark:focus:border-slate-600 dark:text-slate-50   border-secondary-100 focus:bg-secondary-50  focus:border-secondary-200":
                  !selectProps.error,
              }
            )}
          >
            <span
              className={classNames(
                "block truncate text-slate-800 dark:text-slate-200",
                {
                  "!text-slate-300 dark:!text-secondary-600":
                    selectProps.loading,
                }
              )}
            >
              {selectProps.selected ? (
                selectProps.selected.label
              ) : (
                <Text className="text-sm" type="subtext">
                  {selectProps.emptyString
                    ? selectProps.emptyString
                    : selectProps.label
                    ? `Select a ${selectProps.label.toLowerCase()}`
                    : ""}
                </Text>
              )}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 gap-1">
              {selectProps.clearable && selectProps.selected && (
                <ClearIcon
                  onClick={handleClear}
                  className="w-4 cursor-pointer hover:scale-110 duration-300 text-slate-400 hover:text-slate-800"
                />
              )}

              <ChevronUpDownIcon
                className="h-4 w-4 text-gray-400"
                aria-hidden="true"
              />
            </span>
            {selectProps.loading && (
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <LoadingSpin size={18} borderSize={2} />
              </span>
            )}
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              ref={refs.setFloating}
              style={floatingStyles}
              className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-zinc-700 p-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
            >
              {selectProps.options.map((option, optionIdx) => (
                <Listbox.Option
                  key={optionIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-5 pr-4 rounded-md ${
                      active
                        ? "bg-primary-50 text-primary-600 dark:bg-dark-2 dark:text-primary-300"
                        : "text-gray-900 dark:text-slate-300"
                    }`
                  }
                  value={option}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {option.label}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-1 text-primary-600 dark:text-primary-300">
                          <CheckIcon className="h-3 w-3" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
              {selectProps.options.length === 0 && (
                <NotData iconClassName="!w-8" textClassName="!font-normal" />
              )}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      <AnimatePresence>
        {selectProps.error && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
          >
            <p className="mt-2 text-xs text-red-600 dark:text-red-500">
              {selectProps.error}
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

type SelectProps2<T, ET = any> = {
  className?: string;
  buttonClassName?: string;
  optionsClassName?: string;
  label?: string;
  error?: string;
  required?: boolean;
  tooltip?: string;
  hint?: string;
  loading?: boolean;
  emptyString?: string;
  clearable?: boolean;
  disabled?: boolean;
  value?: T;
  defaultValue?: T;
  onChange?: (value: T | undefined) => void;
  onBlur?: (e: FocusEvent) => void;
  options: SelectOption<T, ET>[];
};

const Select2 = <T extends string | number, ET = any>({
  className,
  buttonClassName,
  optionsClassName,
  label,
  error,
  required,
  tooltip,
  hint,
  loading,
  emptyString,
  clearable,
  disabled,
  value,
  defaultValue,
  onChange,
  onBlur,
  options,
}: SelectProps2<T, ET>) => {
  const { refs, floatingStyles } = useFloating({
    placement: "bottom-start",
    middleware: [flip(), shift()],
  });

  const handleClear = (e: React.MouseEvent<HTMLOrSVGElement, MouseEvent>) => {
    e.stopPropagation();
    onChange && onChange(undefined);
  };

  const selectedOption = useMemo(() => {
    return options.find((o) => o.value === value);
  }, [options, value]);

  return (
    <div className={classNames("flex flex-col", className)}>
      <div
        className={classNames("flex items-center", {
          "justify-between": label,
          "justify-end": !label,
        })}
      >
        <label
          // htmlFor={id}
          className={classNames(
            "flex items-center gap-1 text-sm text-slate-800 dark:text-slate-50 whitespace-nowrap",
            {
              "text-red-700 dark:text-red-500": error,
              "mb-1": label,
            }
          )}
        >
          {label}
          {required && <span className="text-red-500">*</span>}
          {tooltip && <InfoTooltip content={tooltip} />}
        </label>
        {hint && <span className="text-xs mb-1 text-slate-500">{hint}</span>}
      </div>
      <Listbox
        defaultValue={defaultValue || null}
        value={value || null}
        onChange={onChange}
        disabled={disabled}
      >
        <div className="relative">
          <Listbox.Button
            ref={refs.setReference}
            className={classNames(
              "border-2 min-h-9 relative text-sm text-left rounded-lg w-full p-2.5 bg-secondary-100 disabled:bg-secondary-300 disabled:text-secondary-400 dark:bg-dark-2 disabled:dark:bg-secondary-500 outline-none disabled:cursor-not-allowed",
              {
                [buttonClassName || ""]: buttonClassName,
                "border-red-500 text-red-900 placeholder-red-700 focus:border-red-500 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500":
                  error,
                "dark:border-dark-2 dark:focus:border-slate-600 dark:text-slate-50 border-secondary-100 focus:bg-secondary-50  focus:border-secondary-200":
                  !error,
              }
            )}
          >
            <span
              className={classNames(
                "block truncate text-slate-800 dark:text-slate-200",
                {
                  "!text-slate-300 dark:!text-secondary-600": loading,
                }
              )}
            >
              {value ? (
                selectedOption?.label
              ) : (
                <Text className="text-sm" type="subtext">
                  {emptyString
                    ? emptyString
                    : label
                    ? `Select a ${label.toLowerCase()}`
                    : ""}
                </Text>
              )}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 gap-1">
              {clearable && value && (
                <ClearIcon
                  onClick={handleClear}
                  className="w-4 cursor-pointer hover:scale-110 duration-300 text-slate-400 hover:text-slate-800"
                />
              )}

              <ChevronUpDownIcon
                className="h-4 w-4 text-gray-400"
                aria-hidden="true"
              />
            </span>
            {loading && (
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <LoadingSpin size={18} borderSize={2} />
              </span>
            )}
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              ref={refs.setFloating}
              style={floatingStyles}
              className={classNames(
                "absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-zinc-700 p-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm",
                { [optionsClassName || ""]: optionsClassName }
              )}
            >
              {options.map((option, optionIdx) => (
                <Listbox.Option
                  disabled={option.disabled}
                  key={optionIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-5 pr-4 rounded-md ${
                      active
                        ? "bg-primary-50 text-primary-600 dark:bg-dark-2 dark:text-primary-300"
                        : "text-gray-900 dark:text-slate-300"
                    }`
                  }
                  value={option.value}
                >
                  {({ selected, disabled }) => (
                    <div
                      className={classNames({
                        "cursor-not-allowed opacity-50": disabled,
                      })}
                    >
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {option.label}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-1 text-primary-600 dark:text-primary-300">
                          <CheckIcon className="h-3 w-3" aria-hidden="true" />
                        </span>
                      ) : null}
                    </div>
                  )}
                </Listbox.Option>
              ))}
              {options.length === 0 && (
                <NotData iconClassName="!w-8" textClassName="!font-normal" />
              )}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      <AnimatePresence>
        {error && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
          >
            <p className="mt-2 text-xs text-red-600 dark:text-red-500">
              {error}
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

type MultiSelectProps<T> = {
  options: Array<SelectOption<T>>;
  value?: Array<SelectOption<T>>;
  onChange?: (values: Array<SelectOption<T>>) => void;
  clearable?: boolean;
  error?: string;
  label?: string | React.ReactNode;
  required?: boolean;
  hint?: string;
  tooltip?: string;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  emptyString?: string;
};

const MultiSelect = <T extends string | number>(
  selectProps: MultiSelectProps<T>
) => {
  const { refs, floatingStyles } = useFloating({
    placement: "bottom-end",
    middleware: [flip(), shift()],
  });

  const handleClear = (e: React.MouseEvent<HTMLOrSVGElement, MouseEvent>) => {
    e.stopPropagation();
    selectProps.onChange && selectProps.onChange([]);
  };

  return (
    <div
      className={classNames("flex flex-col", {
        [`${selectProps.className}`]: selectProps.className,
      })}
    >
      <div
        className={classNames("flex items-center", {
          "justify-between": selectProps.label,
          "justify-end": !selectProps.label,
        })}
      >
        <label
          // htmlFor={id}
          className={classNames(
            "flex items-center gap-1 text-sm font-medium text-slate-800 dark:text-slate-50 whitespace-nowrap",
            {
              "text-red-700 dark:text-red-500": selectProps.error,
              "mb-1": selectProps.label,
            }
          )}
        >
          {selectProps.label}
          {selectProps.required && <span className="text-red-500">*</span>}
          {selectProps.tooltip && <InfoTooltip content={selectProps.tooltip} />}
        </label>
        {selectProps.hint && (
          <span className="text-xs mb-1 text-slate-500">
            {selectProps.hint}
          </span>
        )}
      </div>
      <Listbox
        by="value"
        value={selectProps.value || null}
        onChange={selectProps.onChange}
        disabled={selectProps.disabled}
        multiple
      >
        <div className="relative">
          <Listbox.Button
            ref={refs.setReference}
            className={classNames(
              "border-2 min-h-[40px] relative text-sm text-left rounded-lg w-full p-2.5 bg-secondary-100 disabled:bg-secondary-300  disabled:text-secondary-400 dark:bg-dark-2 disabled:dark:bg-secondary-500 outline-none disabled:cursor-not-allowed",
              {
                " border-red-500 text-red-900 placeholder-red-700   focus:border-red-500 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500":
                  selectProps.error,
                "    dark:border-dark-2 dark:focus:border-slate-600 dark:text-slate-50   border-secondary-100 focus:bg-secondary-50  focus:border-secondary-200":
                  !selectProps.error,
              }
            )}
          >
            <span
              className={classNames(
                "block truncate text-slate-800 dark:text-slate-200",
                {
                  "!text-slate-300 dark:!text-secondary-600":
                    selectProps.loading,
                }
              )}
            >
              {selectProps.value && selectProps.value.length > 0 ? (
                selectProps.value.map((o) => o.label).join(" , ")
              ) : (
                <Text className="text-sm" type="subtext">
                  {selectProps.emptyString ? selectProps.emptyString : ""}
                </Text>
              )}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 gap-1">
              {selectProps.clearable &&
                selectProps.value &&
                selectProps.value.length > 0 && (
                  <ClearIcon
                    onClick={handleClear}
                    className="w-4 cursor-pointer hover:scale-110 duration-300 text-slate-400 hover:text-slate-800"
                  />
                )}
              <ChevronUpDownIcon
                className="h-4 w-4 text-gray-400"
                aria-hidden="true"
              />
            </span>
            {selectProps.loading && (
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <LoadingSpin size={18} borderSize={2} />
              </span>
            )}
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              ref={refs.setFloating}
              style={floatingStyles}
              className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-zinc-700 p-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
            >
              {selectProps.options.map((option, optionIdx) => (
                <Listbox.Option
                  key={optionIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-5 pr-4 rounded-md ${
                      active
                        ? "bg-primary-50 text-primary-600 dark:bg-dark-2 dark:text-primary-300"
                        : "text-gray-900 dark:text-slate-300"
                    }`
                  }
                  value={option}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {option.label}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-1 text-primary-600 dark:text-primary-300">
                          <CheckIcon className="h-3 w-3" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      <AnimatePresence>
        {selectProps.error && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
          >
            <p className="mt-2 text-xs text-red-600 dark:text-red-500">
              {selectProps.error}
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export { MultiSelect, Select2 };
export default Select;
