import { useState, useEffect } from "react";

import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Button from "../../../buttons/Button";
import Input from "../../../inputs/Input";
import Modal from "../../../modals/Modal";

const SaveWorkflowDialog = ({ show, dialogProps, onCancel, onConfirm }) => {
  //   const portalElement = document.getElementById("portal");

  const [workflowName, setWorkflowName] = useState("");
  const [isReadyToSave, setIsReadyToSave] = useState(false);

  useEffect(() => {
    if (workflowName) setIsReadyToSave(true);
    else setIsReadyToSave(false);
  }, [workflowName]);

  return (
    <Modal
      isOpen={show}
      onClose={onCancel}
      contentClassName="max-w-lg"
      title={dialogProps.title}
    >
      <div className="flex flex-col gap-5 mt-7">
        <Input
          inputProps={{
            id: "workflow-name",
            onChange: (e) => setWorkflowName(e.target.value),
            value: workflowName,
            type: "text",
          }}
          placeholder="My New Workflow"
        />

        <div className="flex items-center gap-2 justify-end">
          <Button type="light" buttonProps={{ onClick: onCancel }}>
            {dialogProps.cancelButtonName}
          </Button>
          <Button
            type="primary"
            buttonProps={{
              onClick: () => onConfirm(workflowName),
              disabled: !isReadyToSave,
            }}
          >
            {dialogProps.confirmButtonName}
          </Button>
        </div>
      </div>
    </Modal>
  );

  //   return createPortal(component, portalElement);
};

export default SaveWorkflowDialog;
