import { Helmet } from "react-helmet";
// import { Outlet } from "react-router-dom";
import Text from "../../components/general/Text";
// import { TabOptions } from "../../types/tabs";
// import Tabs from "../../components/general/Tabs";
// import { ReactComponent as ListBulletIcon } from "./../../assets/icons/list-bullet.svg";
// import { ReactComponent as CubeIcon } from "./../../assets/icons/cube-transparent.svg";
import DatabaseServicesTab from "../../components/databases/services/DatabaseServicesTab";
// import DatabasesPlansTab from "../../components/databases/plans/DatabasesPlansTab";
// import {
//   DatabaseServicesPlansUrl,
//   DatabaseServicesUrl,
// } from "../../utils/urls";

// export const DatabaseServicesPageMenus: TabOptions = [
//   {
//     label: (
//       <div className="flex items-center gap-1">
//         <CubeIcon className="w-3" />
//         Services
//       </div>
//     ),
//     url: DatabaseServicesUrl,
//     element: <DatabaseServicesTab />,
//   },
//   {
//     label: (
//       <div className="flex items-center gap-1">
//         <ListBulletIcon className="w-3" />
//         Plans
//       </div>
//     ),
//     url: DatabaseServicesPlansUrl,
//     element: <DatabasesPlansTab />,
//   },
// ];

const DatabaseServicesPage = () => {
  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Database Services</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="flex items-center justify-between h-20">
        <div className="px-6 items-center justify-between flex flex-1 transition duration-150">
          <Text className="font-medium text-lg">Database Services</Text>
        </div>
        {/* <div className="mr-6 w-96"></div> */}
      </div>
      <div className="mx-6">
        {/* <Tabs
          useUrl
          options={DatabaseServicesPageMenus.map((o) => ({
            label: o.label,
            url: o.url,
          }))}
        /> */}
        <div className="py-6">
          {/* <Outlet /> */}
          <DatabaseServicesTab />
        </div>
      </div>
    </>
  );
};

export default DatabaseServicesPage;
