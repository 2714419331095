import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import Modal from "../../../../modals/Modal";
import Button from "../../../../buttons/Button";
import {
  selectBucketDetails,
  setBucketEncryptionAsync,
  selectEncryptionLoading,
  selectBucketDetailsLoading,
  selectShowEncryptionEditor,
  handleHideEncryptionModal,
  selectBucketEncryption,
  getBucketEncryptionAsync,
} from "../../../../../store/s3/buckets/bucketSlice";
import Select, { SelectOption } from "../../../../inputs/Select";
import { EncryptionTypes, S3BucketKms } from "../../../../../types/s3-bucket";
import { useEffect, useState } from "react";
import {
  getBucketsKmsKeysAsync,
  handleShowKmsEditor,
  selectKmsKeys,
  selectKmsLoading,
} from "../../../../../store/s3/buckets/bucketsSlice";
import { ReactComponent as AddIcon } from "./../../../../../assets/icons/plus.svg";
import S3KmsEditorModal from "./S3KmsEditorModal";

export const encryptionOptions: SelectOption<EncryptionTypes>[] = [
  { label: "Disabled", value: "Disabled" },
  { label: "SSE-S3", value: "SSE-S3" },
  { label: "SSE-KMS", value: "SSE-KMS" },
];

const S3EncryptionEditorModal = () => {
  const isOpen = useAppSelector(selectShowEncryptionEditor);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const bucketDetailsLoading = useAppSelector(selectBucketDetailsLoading);
  const bucketEncryption = useAppSelector(selectBucketEncryption);
  const encryptionLoading = useAppSelector(selectEncryptionLoading);

  const kmsKeys = useAppSelector(selectKmsKeys);
  const kmsKeysLoading = useAppSelector(selectKmsLoading);

  const [selectedEnc, setSelectedEnc] =
    useState<SelectOption<EncryptionTypes>>();
  const [selectedKmsKey, setSelectedKmsKey] =
    useState<SelectOption<S3BucketKms>>();

  const dispatch = useAppDispatch();

  const handleClearComponent = () => {
    // reset();
  };

  //specify selections value
  useEffect(() => {
    if (bucketEncryption) {
      //TODO
      // setSelectedEnc()
      // setSelectedKmsKey()
    } else {
      setSelectedEnc(encryptionOptions[0]);
    }
  }, [bucketEncryption]);

  useEffect(() => {
    if (selectedEnc?.value === "SSE-KMS") {
      dispatch(getBucketsKmsKeysAsync());
    }
  }, [dispatch, selectedEnc?.value]);

  const onSubmit = (data: any) => {
    if (bucketDetails && selectedEnc) {
      dispatch(
        setBucketEncryptionAsync({
          name: bucketDetails.name,
          data: {
            isDisabled: selectedEnc.value === "Disabled",
            encType: selectedEnc.value.toLowerCase(),
            kmsKeyID: selectedKmsKey ? selectedKmsKey.value.id : null, //TODO
          },
        })
      ).then((action) => {
        if (action.type === "bucket/encryption/set/fulfilled") {
          handleClearComponent();
          dispatch(handleHideEncryptionModal());
          dispatch(getBucketEncryptionAsync({ name: bucketDetails.name }));
        }
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleHideEncryptionModal());
        handleClearComponent();
      }}
      contentClassName="max-w-lg"
      containerClassName="!items-start"
      title={"Enable Bucket Encryption"}
    >
      <div className="mt-5 flex flex-col w-full gap-5">
        <Select<EncryptionTypes>
          label="Encryption Type"
          options={encryptionOptions}
          selected={selectedEnc}
          setSelected={(o) => {
            setSelectedEnc(o);
          }}
        />
        {selectedEnc?.value === "SSE-KMS" && (
          <div className="flex flex-col gap-1">
            <Select<S3BucketKms>
              label="KMS Key ID"
              options={[
                ...kmsKeys.map(
                  (k) =>
                    ({
                      label: k.id,
                      value: k,
                    } as unknown as SelectOption<S3BucketKms>)
                ),
              ]}
              selected={selectedKmsKey}
              setSelected={setSelectedKmsKey}
              loading={kmsKeysLoading}
            />
            <div className="flex justify-end">
              <Button
                tooltip="Create New KMS key"
                type="icon"
                startIcon={<AddIcon className="w-5" />}
                buttonProps={{
                  onClick: () => dispatch(handleShowKmsEditor()),
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div className="mt-4 flex justify-end gap-2">
        <Button
          buttonProps={{
            onClick: (e) => {
              e.preventDefault();
              dispatch(handleHideEncryptionModal());
            },
          }}
          type="light"
          buttonClassName="w-[70px]"
        >
          Cancel
        </Button>
        <Button
          loading={encryptionLoading || bucketDetailsLoading}
          type="primary"
          buttonProps={{
            disabled: encryptionLoading || bucketDetailsLoading,
            onClick: onSubmit,
          }}
          buttonClassName="w-[70px]"
        >
          Set
        </Button>
      </div>
      <S3KmsEditorModal />
    </Modal>
  );
};

export default S3EncryptionEditorModal;
