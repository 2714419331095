import { useEffect, useState } from "react";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../../general/Table";
import { ReactComponent as MoreIcon } from "./../../../../assets/icons/more.svg";
import { ReactComponent as ArchiveIcon } from "./../../../../assets/icons/archive-box.svg";
import { ReactComponent as PlusIcon } from "./../../../../assets/icons/plus.svg";
import { ReactComponent as EditIcon } from "./../../../../assets/icons/pencil-square.svg";
import {
  selectBucketDetails,
  selectBucketDetailsLoading,
} from "../../../../store/s3/buckets/bucketSlice";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import NotData, { S3NotActiveTab } from "../../../layouts/NotData";
import QuestionModal, { DeleteModal } from "../../../modals/QuestionModal";
import Dropdown from "../../../general/Dropdown";
import Button from "../../../buttons/Button";
import {
  deleteBucketReplicationAsync,
  getBucketReplicationsAsync,
  handleShowReplicationEditor,
  selectBucketLReplicationsLoading,
  selectBucketReplicationActionLoading,
  selectReplications,
} from "../../../../store/s3/buckets/setting/replicationsSlice";
import S3ReplicationEditorModal from "../modals/buckets/S3ReplicationEditorModal";
import Text from "../../../general/Text";
import { selectS3Status } from "../../../../store/s3/s3PublicSlice";

const S3BucketReplicationsTab = () => {
  const s3Status = useAppSelector(selectS3Status);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const bucketDetailsLoading = useAppSelector(selectBucketDetailsLoading);

  const replications = useAppSelector(selectReplications);
  const loading = useAppSelector(selectBucketLReplicationsLoading);
  const actionLoading = useAppSelector(selectBucketReplicationActionLoading);

  const [deleteReplication, setDeleteReplication] = useState<string | null>(
    null
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (bucketDetails && !bucketDetailsLoading) {
      dispatch(getBucketReplicationsAsync({ name: bucketDetails.name }));
    }
  }, [bucketDetails, bucketDetailsLoading, dispatch]);

  const handleDelete = () => {
    if (deleteReplication && bucketDetails)
      dispatch(
        deleteBucketReplicationAsync({
          bucketName: bucketDetails.name,
          id: deleteReplication,
        })
      ).then((action) => {
        if (action.type === "bucket/lifecycle/delete/fulfilled") {
          dispatch(getBucketReplicationsAsync({ name: bucketDetails.name }));
          setDeleteReplication(null);
        }
      });
  };

  if (!s3Status) {
    return <S3NotActiveTab />;
  }
  return (
    <>
      <div className="flex transition-all duration-500 items-center justify-between gap-3">
        <Text className="whitespace-nowrap font-medium">Replications</Text>
        <div className="flex items-center justify-end gap-1 md:gap-2 ">
          <Button
            type="primary"
            buttonProps={{
              onClick: () => dispatch(handleShowReplicationEditor({})),
            }}
          >
            Add Replication
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>
      <div className="mt-5 w-full">
        <Table loading={loading}>
          <TableHead>
            <TableRow>
              <TableTH lable="Priority" />
              <TableTH lable="Destination" />
              <TableTH lable="Prefix" />
              <TableTH lable="Tags" />
              <TableTH lable="Status" />
              <TableTH lable="" />
            </TableRow>
          </TableHead>
          <TableBody>
            {replications?.map((replication, i) => (
              <TableRow key={i}>
                <TableTD>
                  <Text className="text-xs md:text-sm font-semibold whitespace-nowrap">
                    {replication.priority}
                  </Text>
                </TableTD>
                <TableTD>
                  <Text className="text-xs md:text-sm whitespace-nowrap">
                    {replication.destination.bucket.slice(0, 30) + "..."}
                  </Text>
                </TableTD>
                <TableTD>
                  <Text className="text-xs md:text-sm whitespace-nowrap">
                    {replication.prefix}
                  </Text>
                </TableTD>
                <TableTD>
                  <Text className="text-xs md:text-sm whitespace-nowrap">
                    {replication.tags ? "Yes" : "No"}
                  </Text>
                </TableTD>
                <TableTD>
                  <Text className="text-xs md:text-sm whitespace-nowrap">
                    {replication.status}
                  </Text>
                </TableTD>
                <TableTD className="w-32 gap-1">
                  <div className="h-full w-full inline-flex items-center justify-end gap-1">
                    <div className="w-8 flex justify-center items-center">
                      <Dropdown
                        positionClassName="!w-40"
                        menu={[
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <EditIcon className="w-4" />
                                Edit
                              </div>
                            ),
                            onClick: () =>
                              dispatch(
                                handleShowReplicationEditor({ replication })
                              ),
                          },
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: () => setDeleteReplication(replication.id),
                          },
                        ]}
                        type="simple"
                      >
                        <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                          <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                        </div>
                      </Dropdown>
                    </div>
                  </div>
                </TableTD>
              </TableRow>
            ))}

            {replications?.length === 0 && (
              <TableRow withoutHoverStyle={true}>
                <TableTD colSpan={7} className="!border-0">
                  <NotData text="You have not added any replication yet" />
                </TableTD>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <S3ReplicationEditorModal />
      <DeleteModal
        isOpen={deleteReplication !== null}
        onClose={() => setDeleteReplication(null)}
        onConfirm={handleDelete}
        loading={actionLoading || bucketDetailsLoading}
        title="Delete Replication"
        // description={`Are you sure you want to delete the ${deleteReplication} rule?`}
        confirmButtonType="danger"
        // confirmString={
        //   replications.find((r) => r.id === deleteReplication)
        //     ?.id
        // }
      />
    </>
  );
};

export default S3BucketReplicationsTab;
