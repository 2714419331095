import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getEnvironmentsAsync,
  selectEnvironmentSelectIsOpen,
  selectEnvironments,
  selectEnvironmentsLoading,
  closeEnvSelectionModal,
  selectEnvironmentSelectIsClosable,
  openEnvCreateModal,
  openEnvSelectionModal,
} from "../../store/environments/environmentsSlice";
import Modal from "../modals/Modal";
import Loading from "../general/Loading";
import classNames from "classnames";
import {
  handleSelectEnv,
  selectOnStageEnv,
  selectUser,
} from "../../store/auth/authSlice";
import { Environment } from "../../types/environment";
import { ReactComponent as PlusIcon } from "./../../assets/icons/plus.svg";
import { ReactComponent as ArrowDownIcon } from "./../../assets/icons/arrow-down.svg";
import EnvironmentCreateModal from "./EnvironmentCreateModal";
import Button from "../buttons/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  DatabaseServicesUrl,
  InstancesUrl,
  InstancesVolumesUrl,
} from "../../utils/urls";
import { handleResetInstanceVolumes } from "../../store/instances/instancesVolumesSlice";

const EnvironmentSelectModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectEnvironmentSelectIsOpen);
  const closable = useAppSelector(selectEnvironmentSelectIsClosable);
  const environments = useAppSelector(selectEnvironments);
  const activeEnv = useAppSelector(selectOnStageEnv);
  const loading = useAppSelector(selectEnvironmentsLoading);

  const { volumeId, instanceId, databaseId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const getRouteInfo = useCallback(() => {
    const isInstanceVolumeRoute =
      location.pathname.startsWith("/instances/volumes");
    const isInstanceRoute = location.pathname.startsWith("/instances");
    const isDatabaseRoute = location.pathname.startsWith("/database");

    // console.log("location", location, volumeId, instanceId, databaseId);

    return {
      volumeId,
      instanceId,
      databaseId,
      isInstanceVolumeRoute,
      isInstanceRoute,
      isDatabaseRoute,
      navigate,
    };
  }, [location, volumeId, instanceId, databaseId, navigate]);

  useEffect(() => {
    if (environments.length === 0 && isOpen) {
      dispatch(getEnvironmentsAsync());
    }
  }, [dispatch, environments.length, isOpen]);

  const handleChangeEnv = (env: Environment) => {
    const {
      volumeId,
      isInstanceVolumeRoute,
      isInstanceRoute,
      isDatabaseRoute,
      navigate,
    } = getRouteInfo();
    dispatch(handleSelectEnv({ env }));
    dispatch(closeEnvSelectionModal());
    if (volumeId && isInstanceVolumeRoute) {
      navigate(InstancesVolumesUrl);
      dispatch(handleResetInstanceVolumes());
    } else if (isInstanceRoute) {
      navigate(InstancesUrl);
    } else if (isDatabaseRoute) {
      navigate(DatabaseServicesUrl);
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      closable={closable}
      onClose={() => dispatch(closeEnvSelectionModal())}
      contentClassName="max-w-lg"
      //   containerClassName="!items-start"
      title="Select active environment"
    >
      {!loading && (
        <div className="grid grid-cols-1 md:grid-cols-2 mt-5 gap-x-1 gap-y-2">
          {environments.map((env, envIdx) => (
            <div
              onClick={() => handleChangeEnv(env)}
              key={envIdx}
              className={classNames(
                "flex gap-2 items-center w-full dark:border-dark-2  px-3 py-2.5 dark:text-slate-200 text-sm cursor-pointer rounded-lg",
                {
                  "bg-slate-100 dark:bg-black/20": env.Id === activeEnv?.Id,
                  "hover:bg-slate-50 hover:dark:bg-black/20":
                    env.Id !== activeEnv?.Id,
                }
              )}
            >
              <div>
                <div
                  className={classNames(
                    "w-[16px] h-[16px] border rounded-full",
                    {
                      "dark:bg-dark-3  dark:border-white/20":
                        env.Id !== activeEnv?.Id,
                      "bg-primary-300  border-0 ": env.Id === activeEnv?.Id,
                    }
                  )}
                />
              </div>
              <p className=" text-ellipsis whitespace-nowrap overflow-hidden">
                {env.Name}
              </p>
            </div>
          ))}
          <div
            onClick={() => dispatch(openEnvCreateModal())}
            className="group flex gap-2 items-center w-full border dark:border-white/20  px-3 py-2.5 dark:text-slate-200 text-sm cursor-pointer rounded-lg"
          >
            <div>
              <PlusIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-transform duration-500" />
            </div>
            <p className=" text-ellipsis whitespace-nowrap overflow-hidden">
              Create new
            </p>
          </div>
        </div>
      )}

      {loading && <Loading style={{ minHeight: 150 }} />}
      <EnvironmentCreateModal />
    </Modal>
  );
};

const EnvironmentButton = () => {
  const dispatch = useAppDispatch();

  const authUser = useAppSelector(selectUser);
  const selectedEnv = useAppSelector(selectOnStageEnv);

  return (
    <Button
      buttonClassName="w-full whitespace-nowrap"
      contentClassName=" !justify-start"
      type="light"
      buttonProps={{
        onClick: () =>
          authUser?.IsVerified ? dispatch(openEnvSelectionModal()) : {},
      }}
    >
      <div className="w-full flex items-center justify-between">
        <span className="w-14 md:w-auto overflow-hidden text-ellipsis">
          {selectedEnv?.Name}
        </span>
        {authUser?.IsVerified && <ArrowDownIcon className="w-4 h-4" />}
      </div>
    </Button>
  );
};

export { EnvironmentButton };
export default EnvironmentSelectModal;
