import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  deleteS3AccessKeyAsync,
  getS3AccessKeysAsync,
  handleShowEditor,
  selectActionLoading,
  selectS3AccessKeys,
  selectLoading,
  deleteMultipleS3AccessKeyAsync,
} from "../../../store/s3/accessKeysSlice";
import Button from "../../buttons/Button";
import S3AccessKeyEditorModal from "./S3AccessKeyEditorModal";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import Dropdown from "../../general/Dropdown";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil-square.svg";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import NotData, { S3NotActiveTab } from "../../layouts/NotData";
import QuestionModal, { DeleteModal } from "../../modals/QuestionModal";
import { selectOnStageEnv } from "../../../store/auth/authSlice";
import S3AccessKeyShowModal from "./S3AccessKeyShowModal";
import CopyableKey from "../../general/CopyableKey";
import { S3AccessKey } from "../../../types/s3-access-key";
import { useSearch } from "../../../providers/SearchProvider";
import Highlighter from "react-highlight-words";
import { formatTimestamp } from "../../../utils/date";
import Text from "../../general/Text";
import { selectS3Status } from "../../../store/s3/s3PublicSlice";

const S3AccessKeysTab = () => {
  const s3Status = useAppSelector(selectS3Status);
  const env = useAppSelector(selectOnStageEnv);
  const accessKeys = useAppSelector(selectS3AccessKeys);
  const [filteredAccessKeys, setFilteredAccessKeys] = useState<S3AccessKey[]>(
    []
  );
  const [checkedAccessKeys, setCheckedAccessKeys] = useState<S3AccessKey[]>([]);

  const loading = useAppSelector(selectLoading);
  const actionLoading = useAppSelector(selectActionLoading);

  const [deleteAccessKey, setDeleteAccessKey] = useState<string | null>(null);
  const [deleteChecked, setDeleteChecked] = useState(false);

  const dispatch = useAppDispatch();
  const { value: searchValue } = useSearch();

  useEffect(() => {
    // if (accessKeys.length === 0) {
    dispatch(getS3AccessKeysAsync());
    // }
  }, [dispatch]);

  useEffect(() => {
    const lookedUpBuckets = accessKeys.filter((ak) =>
      ak.accessKey?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredAccessKeys(lookedUpBuckets);
  }, [dispatch, searchValue, accessKeys]);

  const handleDelete = () => {
    deleteAccessKey &&
      dispatch(deleteS3AccessKeyAsync({ accessKey: deleteAccessKey })).then(
        (action) => {
          if (action.type === "s3-access-keys/delete/fulfilled") {
            dispatch(getS3AccessKeysAsync());
          } else {
          }
          setDeleteAccessKey(null);
        }
      );
  };

  const handleChangeChecked = (accessKey: S3AccessKey) => {
    const isSelected =
      checkedAccessKeys.find((ak) => ak.accessKey === accessKey.accessKey) ||
      false;
    const filteredAccessKeys = checkedAccessKeys.filter(
      (ak) => ak.accessKey !== accessKey.accessKey
    );
    if (isSelected) {
      setCheckedAccessKeys([...filteredAccessKeys]);
    } else {
      setCheckedAccessKeys([...filteredAccessKeys, accessKey]);
    }
  };

  const handleDeleteSelectedItems = () => {
    dispatch(
      deleteMultipleS3AccessKeyAsync({ accessKeys: checkedAccessKeys })
    ).then((action) => {
      if (action.type === "s3-access-keys/delete-multiple/fulfilled") {
        dispatch(getS3AccessKeysAsync());
        setCheckedAccessKeys([]);
        setDeleteChecked(false);
      } else {
      }
    });
  };

  if (!s3Status) {
    return <S3NotActiveTab />;
  }

  return (
    <>
      <div className="flex transition-all duration-500 items-center justify-between gap-3">
        <Text className="whitespace-nowrap font-medium">Access Keys</Text>
        <div className="flex items-center justify-end gap-1 md:gap-2 ">
          <Button
            type="danger"
            buttonProps={{
              onClick: () => setDeleteChecked(true),
              disabled: checkedAccessKeys.length === 0,
            }}
            buttonClassName="!px-2"
            tooltip="Delete Selected"
            startIcon={<ArchiveIcon className="w-5" />}
          />
          <Button
            type="primary"
            buttonProps={{ onClick: () => dispatch(handleShowEditor({})) }}
            buttonClassName="group"
          >
            Create New
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>
      <div className="mt-5 w-full">
        <div className="flex items-center text-sm gap-2 flex-1 mb-3">
          <Text>API:</Text>
          <CopyableKey text={env?.StorageApiRoute} />
        </div>
        <Table loading={loading}>
          <TableHead>
            <TableRow>
              <TableTH lable="Access Key" />
              <TableTH lable="Expiry" />
              <TableTH lable="Status" />
              <TableTH lable="Name" />
              <TableTH lable="Description" />
              <TableTH lable="" />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAccessKeys.map((accessKey, i) => (
              <TableRow key={i}>
                <TableTD>
                  <div className="flex items-center flex-row justify-between space-x-4">
                    <input
                      id="default-checkbox"
                      type="checkbox"
                      checked={
                        !!checkedAccessKeys.find(
                          (ak) => ak.accessKey === accessKey.accessKey
                        )
                      }
                      onChange={() => {}}
                      className="block w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 rounded ring-0 dark:bg-gray-700 dark:border-gray-600"
                      onClick={() => handleChangeChecked(accessKey)}
                    />
                    <Highlighter
                      highlightClassName="bg-primary-100 dark:bg-primary-300 rounded-sm"
                      className="whitespace-nowrap max-w-[110px] md:max-w-none text-ellipsis overflow-hidden text-sm font-medium"
                      searchWords={[searchValue]}
                      autoEscape={true}
                      textToHighlight={accessKey.accessKey}
                    />
                  </div>
                </TableTD>
                <TableTD>
                  <Text className="text-xs md:text-sm">
                    {accessKey.expiration &&
                      formatTimestamp(
                        accessKey.expiration,
                        "YYYY/M/D HH:mm:ss [UTC]"
                      ).datetime}
                  </Text>
                </TableTD>
                <TableTD>
                  <Text className="text-xs md:text-sm">
                    {accessKey.accountStatus === "on" ? "Enabled" : "Disabled"}
                  </Text>
                </TableTD>
                <TableTD>
                  <Text className="text-xs md:text-sm">{accessKey.name}</Text>
                </TableTD>
                <TableTD>
                  <Text className="text-xs md:text-sm">
                    {accessKey.description}
                  </Text>
                </TableTD>
                <TableTD className="w-32 gap-1">
                  <div className="h-full w-full inline-flex items-center justify-end gap-1">
                    <div className="w-8 flex justify-center items-center">
                      <Dropdown
                        positionClassName="!w-40"
                        menu={[
                          {
                            key: "1",
                            label: (
                              <div className="flex items-center gap-1">
                                <EditIcon className="w-4" />
                                Edit
                              </div>
                            ),
                            onClick: (_, close) => {
                              close();
                              dispatch(
                                handleShowEditor({
                                  accessKey: accessKey.accessKey,
                                })
                              );
                            },
                          },
                          {
                            type: "divider",
                          },
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: (_, close) => {
                              close();
                              setDeleteAccessKey(accessKey.accessKey);
                            },
                          },
                        ]}
                        type="simple"
                      >
                        <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                          <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                        </div>
                      </Dropdown>
                    </div>
                  </div>
                </TableTD>
              </TableRow>
            ))}

            {accessKeys.length === 0 && (
              <TableRow withoutHoverStyle={true}>
                <TableTD colSpan={6} className="!border-0">
                  <NotData text="You have not created any Access Key yet" />
                </TableTD>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <S3AccessKeyEditorModal />
      <S3AccessKeyShowModal />
      <DeleteModal
        title="Delete Access Key"
        isOpen={deleteAccessKey !== null}
        onClose={() => setDeleteAccessKey(null)}
        onConfirm={handleDelete}
        loading={actionLoading}
        confirmString={
          accessKeys.find(
            (accessKey) => accessKey.accessKey === deleteAccessKey
          )?.name
        }
        confirmButtonType="danger"
      />
      <QuestionModal
        isOpen={deleteChecked}
        onClose={() => setDeleteChecked(false)}
        title="Delete Access Keys"
        description={`Are you sure you want to delete the selected ${checkedAccessKeys.length} Access Keys?`}
        onConfirm={handleDeleteSelectedItems}
        confirmButtonType="danger"
        loading={actionLoading}
      />
    </>
  );
};

export default S3AccessKeysTab;
