import { Disclosure } from "@headlessui/react";
import { ReactComponent as ChevronDownIcon } from "./../../assets/icons/chevron-down.svg";
import classNames from "classnames";
import { PropsWithChildren } from "react";
import Loading from "./Loading";

type AccordionProps = {
  items?: Array<AccordionItem>;
  panelClassNames?: string;
  loading?: boolean;
};

export interface AccordionItem {
  label: string | React.ReactNode;
  panel?: string | React.ReactNode;
}

const Accordion = (props: PropsWithChildren<AccordionProps>) => {
  const { items, panelClassNames, loading } = props;
  return (
    <div className="w-full bg-gray-50 dark:bg-black/10 border dark:border-dark-2 rounded-lg overflow-hidden ">
      {items?.length === 0 && loading && (
        <Loading borderSize={2} style={{ minHeight: 100 }} />
      )}
      {items?.map((item, i) => (
        <Disclosure key={i}>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between items-center text-slate-900  bg-white hover:bg-gray-50 dark:text-slate-100 dark:bg-dark-3 dark:hover:bg-black/10 px-2 py-3 md:px-4 md:py-4 text-left text-sm font-medium  focus:outline-none focus-visible:ring-0">
                {item.label}
                {item.panel && (
                  <ChevronDownIcon
                    className={classNames(
                      "h-4 w-4 text-slate-500 dark:text-slate-300 transform transition-transform duration-300",
                      {
                        "rotate-180": open,
                      }
                    )}
                  />
                )}
              </Disclosure.Button>
              {item.panel && (
                <Disclosure.Panel
                  className={classNames(panelClassNames, {
                    "p-3 border-y dark:border-dark-2 dark:text-slate-200":
                      panelClassNames === undefined,
                  })}
                >
                  {item.panel}
                </Disclosure.Panel>
              )}
            </>
          )}
        </Disclosure>
      ))}
    </div>
  );
};
export default Accordion;
