import { Edge, Node } from "reactflow";
import {
  INodeData,
  INodeOptionsValue,
  INodeParams,
  INodeParamsType,
  INodeResponse,
  IVariableBody,
  IWorkflowResponse,
} from "../../../../types/workflows";
import { useCallback, useEffect, useRef, useState } from "react";
import Button from "../../../buttons/Button";
import { ReactComponent as PlusIcon } from "./../../../../assets/icons/pencil.svg";
import { ReactComponent as MinusIcon } from "./../../../../assets/icons/minus.svg";
import { ReactComponent as CheckIcon } from "./../../../../assets/icons/check.svg";
import { ReactComponent as ChevronDownIcon } from "./../../../../assets/icons/chevron-down.svg";
import { Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  getNodeAsync,
  handleClearWorkflow,
  selectWorkflowNode,
  selectWorkflowNodeLoading,
} from "../../../../store/workflows/workflowSlice";
import * as Yup from "yup";
import lodash from "lodash";
import {
  getAvailableNodeIdsForVariable,
  handleCredentialParams,
  numberOrExpressionRegex,
} from "../../../../utils/wfHelper";
import Text from "../../../general/Text";
import { IconBtnWrapper } from "../../../general/Wrappers";
import Input from "../../../inputs/Input";
import InputParameters from "../inputs/InputParameters";

// material-ui
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  ClickAwayListener,
  Divider,
  Paper,
  Popper,
} from "@mui/material";
import CredentialInput from "../inputs/CredentialInput";
import OutputResponses from "../output/OutputResponses";
import VariableSelector from "./VariableSelector";
import EditVariableDialog from "../dialog/EditVariableDialog";
import { ReactComponent as DeleteIcon } from "./../../../../assets/icons/archive-box.svg";
import Tooltip from "../../../general/Tooltip";
import Loading from "../../../general/Loading";

interface IEditNodes {
  nodes: Node[];
  edges: Edge[];
  node: Node<INodeData> | null;
  workflow: IWorkflowResponse | null;
  onNodeLabelUpdate: (nodeLabel: string) => void;
  onNodeValuesUpdate: (nodeFlowData: INodeData) => void;
  onDeleteNode: (nodeId: string) => void;
}

const EditNodes: React.FC<IEditNodes> = ({
  nodes,
  edges,
  node,
  workflow,
  onNodeLabelUpdate,
  onNodeValuesUpdate,
  onDeleteNode,
}) => {
  const workflowNode = useAppSelector(selectWorkflowNode);
  const nodeDetailsLoading = useAppSelector(selectWorkflowNodeLoading);

  const dispatch = useAppDispatch();

  const [nodeFlowData, setNodeFlowData] = useState<INodeData | null>(null);
  const [nodeLabel, setNodeLabel] = useState<string>("");
  const [expanded, setExpanded] = useState<INodeParamsType | false>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [nodeDetails, setNodeDetails] = useState<INodeResponse | null>(null);
  const [nodeParams, setNodeParams] = useState<INodeParams[]>([]);
  const [nodeParamsType, setNodeParamsType] = useState<INodeParamsType[]>([]);
  const [nodeParamsInitialValues, setNodeParamsInitialValues] = useState<
    Record<string, any>
  >({});
  const [nodeParamsValidation, setNodeParamsValidation] = useState<
    Record<string, Yup.Schema>
  >({});
  const [isVariableSelectorOpen, setVariableSelectorOpen] =
    useState<boolean>(false);
  const [variableBody, setVariableBody] = useState<IVariableBody | undefined>();
  const [availableNodesForVariable, setAvailableNodesForVariable] = useState<
    Node[] | null
  >(null);
  const [isEditVariableDialogOpen, setEditVariableDialog] =
    useState<boolean>(false);
  const [editVariableDialogProps, setEditVariableDialogProps] = useState<any>(
    {}
  );

  const anchorRef = useRef<HTMLDivElement>(null);
  const s = useRef<HTMLDivElement>(null);

  const scrollTop = () => {
    const curr = s.current;
    if (curr) {
      curr.scrollTop = 0;
    }
  };

  const handleClear = () => {
    setNodeFlowData(null);
    setNodeLabel("");
    setExpanded(false);
    setNodeDetails(null);
    setNodeParams([]);
    setNodeParamsType([]);
    setNodeParamsInitialValues({});
    setNodeParamsValidation({});
    setVariableSelectorOpen(false);
    setVariableBody(undefined);
    setAvailableNodesForVariable(null);
    setEditVariableDialog(false);
    setEditVariableDialogProps({});
    dispatch(handleClearWorkflow());
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    setVariableSelectorOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    if (open) setVariableSelectorOpen(false);
  };

  const handleAccordionChange =
    (paramsType: INodeParamsType) => (event: any, isExpanded: boolean) => {
      setExpanded(isExpanded ? paramsType : false);
      scrollTop();
    };

  const handleNodeLabelChange = (event: any) => {
    setNodeLabel(event.target.value);
  };

  const saveNodeLabel = () => {
    onNodeLabelUpdate(nodeLabel);
  };

  const onEditVariableDialogOpen = useCallback(
    (input: any, values: Record<string, any>, arrayItemBody?: any) => {
      if (node) {
        const variableNodesIds = getAvailableNodeIdsForVariable(
          nodes,
          edges,
          node.id
        );

        const nodesForVariable = [];
        for (let i = 0; i < variableNodesIds.length; i += 1) {
          const nodeId = variableNodesIds[i];
          const node = nodes.find((nd) => nd.id === nodeId);
          nodesForVariable.push(node);
        }

        const dialogProps = {
          input,
          values,
          arrayItemBody,
          availableNodesForVariable: nodesForVariable,
          cancelButtonName: "Cancel",
          confirmButtonName: "Save",
        };

        setEditVariableDialogProps(dialogProps);
        setEditVariableDialog(true);
      }
    },
    [edges, node, nodes]
  );

  const setVariableSelectorState = useCallback(
    (variableSelectorState: boolean, body?: IVariableBody) => {
      if (node) {
        setVariableSelectorOpen(variableSelectorState);
        if (body) {
          setVariableBody(body);
          const variableNodesIds = getAvailableNodeIdsForVariable(
            nodes,
            edges,
            node.id
          );

          const nodesForVariable: Node[] = [];
          for (let i = 0; i < variableNodesIds.length; i += 1) {
            const nodeId = variableNodesIds[i];
            const node = nodes.find((nd) => nd.id === nodeId);
            if (node) nodesForVariable.push(node);
          }
          setAvailableNodesForVariable(nodesForVariable);
        }
      }
    },
    [edges, node, nodes]
  );

  const paramsChanged = useCallback(
    (formParams: INodeParams[], paramsType: INodeParamsType) => {
      if (nodeDetails) {
        // Because formParams options can be changed due to show hide options,
        // To avoid that, replace with original details options

        const credentialMethodParam = formParams.find(
          (param) => param.name === "credentialMethod"
        );
        const credentialMethodParamIndex = formParams.findIndex(
          (param) => param.name === "credentialMethod"
        );

        if (credentialMethodParam !== undefined) {
          const _params =
            paramsType !== "outputResponses"
              ? nodeDetails[paramsType]
              : undefined;
          const originalParam = _params?.find(
            (param) => param.name === "credentialMethod"
          );
          if (originalParam !== undefined) {
            formParams[credentialMethodParamIndex]["options"] =
              originalParam.options;
          }
        }

        const updateNodeDetails: INodeResponse = {
          ...nodeDetails,
          [paramsType]: formParams,
        };
        setNodeDetails(updateNodeDetails);
      }
    },
    [nodeDetails]
  );

  const valueChanged = useCallback(
    (formValues: Record<string, any>, paramsType: INodeParamsType) => {
      if (nodeFlowData) {
        const updateNodeFlowData: INodeData = {
          ...nodeFlowData,
          [paramsType]: formValues,
        };
        // If input parameters change, notify output has to be retest
        if (nodeFlowData.outputResponses) {
          const outputResponsesFlowData = nodeFlowData.outputResponses;
          outputResponsesFlowData.submit = null;
          outputResponsesFlowData.needRetest = true;
          updateNodeFlowData.outputResponses = outputResponsesFlowData;
        }

        setNodeFlowData(updateNodeFlowData);
        onNodeValuesUpdate(updateNodeFlowData);
      }
    },
    [nodeFlowData, onNodeValuesUpdate]
  );

  const onVariableSelected = useCallback(
    (returnVariablePath: string) => {
      if (variableBody && nodeFlowData) {
        const path = variableBody.path;
        const paramsType = variableBody.paramsType;
        const newInput = `${variableBody.textBeforeCursorPosition}{{${returnVariablePath}}}${variableBody.textAfterCursorPosition}`;
        const clonedNodeFlowData = lodash.cloneDeep(nodeFlowData);

        lodash.set(clonedNodeFlowData, path, newInput);
        const _params = clonedNodeFlowData[paramsType];
        if (_params) valueChanged(_params, paramsType);
      }
    },
    [nodeFlowData, valueChanged, variableBody]
  );

  const onSubmit = useCallback(
    (formValues: Record<string, any>, paramsType: INodeParamsType) => {
      if (nodeFlowData) {
        const updateNodeFlowData: INodeData = {
          ...nodeFlowData,
          [paramsType]: formValues,
        };
        setNodeFlowData(updateNodeFlowData);
        onNodeValuesUpdate(updateNodeFlowData);

        const index = nodeParamsType.indexOf(paramsType);
        if (index >= 0 && index !== nodeParamsType.length - 1) {
          setExpanded(nodeParamsType[index + 1]);
          scrollTop();
        }
      }
    },
    [nodeFlowData, nodeParamsType, onNodeValuesUpdate]
  );

  const showHideParameters = useCallback(
    (
      input: INodeParams,
      displayType: "show" | "hide",
      index: number,
      toBeDeleteParams: INodeParams[]
    ) => {
      const displayOptions = input[displayType];

      if (displayOptions) {
        Object.keys(displayOptions).forEach((path) => {
          const comparisonValue = displayOptions[path];
          if (path.includes("$index")) {
            path = path.replace("$index", index.toString());
          }
          const groundValue = lodash.get(nodeFlowData, path, "");

          if (Array.isArray(comparisonValue)) {
            if (
              displayType === "show" &&
              !comparisonValue.includes(groundValue)
            ) {
              toBeDeleteParams.push(input);
            }
            if (
              displayType === "hide" &&
              comparisonValue.includes(groundValue)
            ) {
              toBeDeleteParams.push(input);
            }
          } else if (typeof comparisonValue === "string") {
            if (
              displayType === "show" &&
              !(
                comparisonValue === groundValue ||
                new RegExp(comparisonValue).test(groundValue)
              )
            ) {
              toBeDeleteParams.push(input);
            }
            if (
              displayType === "hide" &&
              (comparisonValue === groundValue ||
                new RegExp(comparisonValue).test(groundValue))
            ) {
              toBeDeleteParams.push(input);
            }
          }
        });
      }
    },
    [nodeFlowData]
  );

  const displayParameters = useCallback(
    (
      params: INodeParams[],
      paramsType: INodeParamsType,
      arrayIndex: number
    ) => {
      const toBeDeleteParams: INodeParams[] = [];
      let _params: INodeParams[] = [];

      for (let i = 0; i < params.length; i += 1) {
        let input = params[i];

        if (input.type === "array") {
          const arrayInitialValue = lodash.get(
            nodeFlowData,
            `${paramsType}.${input.name}`,
            []
          );
          let inputArray: INodeParams[][] = [];
          for (let j = arrayIndex; j < arrayInitialValue.length; j += 1) {
            inputArray.push(
              displayParameters(input.array || [], paramsType, j)
            );
          }
          input = { ...input, arrayParams: inputArray };
        }
        if (input.show) {
          showHideParameters(input, "show", arrayIndex, toBeDeleteParams);
        }
        if (input.hide) {
          showHideParameters(input, "hide", arrayIndex, toBeDeleteParams);
        }
        _params.push(input);
      }
      let returnParams = _params;
      for (let i = 0; i < toBeDeleteParams.length; i += 1) {
        returnParams = returnParams.filter(
          (prm) => JSON.stringify(prm) !== JSON.stringify(toBeDeleteParams[i])
        );
      }
      return returnParams;
    },
    [nodeFlowData, showHideParameters]
  );

  const showHideOptions = useCallback(
    (
      displayType: "show" | "hide",
      index: number,
      options: INodeOptionsValue[] | undefined
    ) => {
      let returnOptions = options;
      const toBeDeleteOptions: INodeOptionsValue[] = [];

      if (returnOptions) {
        for (let i = 0; i < returnOptions.length; i += 1) {
          const option = returnOptions[i];
          const displayOptions = option[displayType];
          if (displayOptions) {
            Object.keys(displayOptions).forEach((path) => {
              const comparisonValue = displayOptions[path];

              if (path.includes("$index")) {
                path = path.replace("$index", index.toString());
              }
              const groundValue = lodash.get(nodeFlowData, path, "");

              if (Array.isArray(comparisonValue)) {
                if (
                  displayType === "show" &&
                  !comparisonValue.includes(groundValue)
                ) {
                  toBeDeleteOptions.push(option);
                }
                if (
                  displayType === "hide" &&
                  comparisonValue.includes(groundValue)
                ) {
                  toBeDeleteOptions.push(option);
                }
              } else if (typeof comparisonValue === "string") {
                if (
                  displayType === "show" &&
                  !(
                    comparisonValue === groundValue ||
                    new RegExp(comparisonValue).test(groundValue)
                  )
                ) {
                  toBeDeleteOptions.push(option);
                }
                if (
                  displayType === "hide" &&
                  (comparisonValue === groundValue ||
                    new RegExp(comparisonValue).test(groundValue))
                ) {
                  toBeDeleteOptions.push(option);
                }
              }
            });
          }
        }

        for (let i = 0; i < toBeDeleteOptions.length; i += 1) {
          returnOptions = returnOptions.filter(
            (opt) =>
              JSON.stringify(opt) !== JSON.stringify(toBeDeleteOptions[i])
          );
        }
      }
      return returnOptions;
    },
    [nodeFlowData]
  );

  const displayOptions = useCallback(
    (
      params: INodeParams[],
      paramsType: INodeParamsType,
      arrayIndex: number
    ) => {
      let clonedParams = params;

      for (let i = 0; i < clonedParams.length; i += 1) {
        const input = clonedParams[i];

        if (input.type === "array") {
          const arrayInitialValue = lodash.get(
            nodeFlowData,
            `${paramsType}.${input.name}`,
            []
          );
          const inputArray = [];
          for (let j = arrayIndex; j < arrayInitialValue.length; j += 1) {
            if (input.arrayParams) {
              inputArray.push(
                displayOptions(input.arrayParams[j] || [], paramsType, j)
              );
            }
          }
          input.arrayParams = inputArray;
        }

        if (input.type === "options") {
          input.options = showHideOptions("show", arrayIndex, input.options);
          input.options = showHideOptions("hide", arrayIndex, input.options);
        }
      }

      return clonedParams;
    },
    [nodeFlowData, showHideOptions]
  );

  const setYupValidation = useCallback(
    (params: INodeParams[]) => {
      const validationSchema: Record<string, Yup.Schema> = {};
      for (let i = 0; i < params.length; i += 1) {
        const input = params[i];
        let inputOptional = input.optional;

        if (typeof input.optional === "object" && input.optional !== null) {
          const keys = Object.keys(input.optional);
          inputOptional = true;
          for (let j = 0; j < keys.length; j += 1) {
            const path = keys[j];
            const comparisonValue = input.optional[path];
            const groundValue = lodash.get(nodeFlowData, path, "");

            if (Array.isArray(comparisonValue)) {
              inputOptional =
                inputOptional && comparisonValue.includes(groundValue);
            } else if (typeof comparisonValue === "string") {
              inputOptional =
                inputOptional &&
                (comparisonValue === groundValue ||
                  new RegExp(comparisonValue).test(groundValue));
            }
          }
        }

        if (
          (input.type === "string" ||
            input.type === "password" ||
            input.type === "date" ||
            input.type === "code" ||
            input.type === "json" ||
            input.type === "file" ||
            input.type === "options" ||
            input.type === "asyncOptions") &&
          !inputOptional
        ) {
          validationSchema[input.name] = Yup.string().required(
            `${input.label} is required. Type: ${input.type}`
          );
        } else if (input.type === "number" && !inputOptional) {
          validationSchema[input.name] = Yup.string()
            .required(`${input.label} is required. Type: ${input.type}`)
            .matches(
              numberOrExpressionRegex,
              `${input.label} must be numbers or a variable expression.`
            );
        } else if (input.type === "array" && !inputOptional) {
          /*
            ************
            * Limitation on different object shape within array: https://github.com/jquense/yup/issues/757
            ************
            const innerValidationSchema = setYupValidation(input.arrayParams);
            validationSchema[input.name] = Yup.array(Yup.object(innerValidationSchema)).required(`Must have ${input.label}`).min(1, `Minimum of 1 ${input.label}`);
            */
        }
      }
      return validationSchema;
    },
    [nodeFlowData]
  );

  const initializeFormValuesAndParams = useCallback(
    (paramsType: INodeParamsType) => {
      if (nodeFlowData && nodeDetails) {
        const initialValues: Record<string, any> = {};

        const params =
          paramsType !== "outputResponses" ? nodeDetails[paramsType] || [] : [];

        const reorganizedParams = displayParameters(params, paramsType, 0);

        let nodeParams = displayOptions(
          lodash.cloneDeep(reorganizedParams),
          paramsType,
          0
        );

        nodeParams = handleCredentialParams(
          nodeParams,
          paramsType,
          reorganizedParams,
          nodeFlowData
        );

        for (let i = 0; i < nodeParams.length; i += 1) {
          const input = nodeParams[i];

          // Load from nodeFlowData values
          const _nodeParams = nodeFlowData[paramsType];
          if (_nodeParams && input.name in _nodeParams) {
            initialValues[input.name] = _nodeParams[input.name];

            // Check if option value is still available from the list of options
            if (input.type === "options") {
              const optionVal = input.options?.find(
                (option) => option.name === initialValues[input.name]
              );
              if (!optionVal) delete initialValues[input.name];
            }
          } else {
            // Load from nodeParams default values
            initialValues[input.name] = input.default || "";

            /**
                 * Special case for array, always initialize the item if default is not set
                 * Disabling for now
                if (input.type === 'array' && !input.default) {
                    const newObj = {}
                    for (let j = 0; j < input.array.length; j += 1) {
                        newObj[input.array[j].name] = input.array[j].default || ''
                    }
                    initialValues[input.name] = [newObj]
                }
                */
          }
        }

        initialValues.submit = null;

        setNodeParamsInitialValues(initialValues);
        setNodeParamsValidation(setYupValidation(nodeParams));
        setNodeParams(nodeParams);
      }
    },
    [
      displayOptions,
      displayParameters,
      nodeDetails,
      nodeFlowData,
      setYupValidation,
    ]
  );

  // Handle Accordian
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false && anchorRef.current) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;

    if (open === false) {
      handleClear();
    }
  }, [open]);

  // Get Node Details from API
  useEffect(() => {
    if (workflowNode) {
      setNodeDetails(workflowNode);
      const nodeParamsType: INodeParamsType[] = [];

      if (workflowNode.actions) nodeParamsType.push("actions");
      if (workflowNode.networks) nodeParamsType.push("networks");
      if (workflowNode.credentials) nodeParamsType.push("credentials");
      if (workflowNode.inputParameters) nodeParamsType.push("inputParameters");
      nodeParamsType.push("outputResponses");

      setNodeParamsType(nodeParamsType);

      if (nodeParamsType.length) {
        setExpanded(nodeParamsType[0]);
        scrollTop();
      }
    }
  }, [workflowNode]);

  // Initialization
  useEffect(() => {
    if (node) {
      setOpen(true);
      setNodeLabel(node.data.label);
      setNodeFlowData(node.data);
      dispatch(getNodeAsync({ name: node.data.name }));
    } else {
      setOpen(false);
    }
  }, [dispatch, node]);

  // Initialize Parameters Initial Values & Validation
  useEffect(() => {
    if (nodeDetails && nodeFlowData && expanded) {
      initializeFormValuesAndParams(expanded);
    }
  }, [nodeDetails, nodeFlowData, expanded, initializeFormValuesAndParams]);

  return (
    <div className="relative z-[1050] top-5 left-7 inline-flex" ref={anchorRef}>
      <div className="text-slate-700" onClick={handleToggle}>
        <Button buttonClassName="!px-1.5 !bg-purple-600">
          {open ? (
            <MinusIcon className="w-5 aspect-square text-white" />
          ) : (
            <PlusIcon className="w-5 aspect-square text-white p-0.5" />
          )}
        </Button>
      </div>

      <Popper
        placement="bottom-start"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        sx={{ zIndex: 1000, minWidth: 400, maxWidth: 400 }}
      >
        <Transition
          show={open}
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Paper
            elevation={3}
            className="bg-white dark:bg-dark-3 border-2 border-white dark:border-dark-2 !rounded-2xl"
          >
            <ClickAwayListener onClickAway={handleClose}>
              <div>
                <Box sx={{ p: 2 }}>
                  <div className="flex justify-between w-full">
                    <Text className="text-sm font-semibold">Edit Nodes</Text>

                    {node && (
                      <Tooltip content="Delete node" className="">
                        <DeleteIcon
                          onClick={() => (node ? onDeleteNode(node.id) : null)}
                          className="w-5 aspect-square text-red-500 hover:text-red-600 cursor-pointer"
                        />
                      </Tooltip>
                    )}
                  </div>
                </Box>
                <div
                  className="scroll max-h-[calc(100vh-250px)] overflow-y-auto"
                  ref={s}
                >
                  {!node && !nodeDetailsLoading && (
                    <Text className="text-sm pb-4 px-4" type="subtext">
                      No data
                    </Text>
                  )}

                  {nodeFlowData && nodeFlowData.label && (
                    <Box
                      sx={{
                        pl: 4,
                        pr: 4,
                        pt: 2,
                        pb: 2,
                        // textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Text className="text-xs font-medium">Node Label</Text>
                      <div className="flex text-center items-center gap-2">
                        <div className="!flex-1">
                          <Input
                            id={nodeFlowData.name}
                            inputProps={{
                              value: nodeLabel,
                              onChange: handleNodeLabelChange,
                            }}
                          />
                        </div>
                        <IconBtnWrapper
                          title="Validate and Save"
                          onClick={saveNodeLabel}
                        >
                          <CheckIcon className="w-4 h-4 text-slate-800 dark:text-slate-200" />
                        </IconBtnWrapper>
                      </div>
                    </Box>
                  )}

                  {nodeDetailsLoading && (
                    <Loading borderSize={2} className="!h-[150px]" />
                  )}

                  {!nodeDetailsLoading && node && (
                    <>
                      {/* actions */}
                      {nodeParamsType.includes("actions") && (
                        <Box sx={{ p: 2 }}>
                          <Accordion
                            expanded={expanded === "actions"}
                            onChange={handleAccordionChange("actions")}
                            sx={{ boxShadow: "none" }}
                            className="bg-white dark:bg-dark-3"
                          >
                            <AccordionSummary
                              expandIcon={
                                <ChevronDownIcon className="w-[15px] h-[15px] text-slate-700 dark:text-slate-300" />
                              }
                              aria-controls="actions-content"
                              id="actions-header"
                            >
                              <div className="flex items-center justify-between">
                                <Text className="text-sm font-semibold">
                                  Actions
                                </Text>
                                {nodeFlowData &&
                                  nodeFlowData.actions &&
                                  nodeFlowData.actions.submit && (
                                    <div className="w-[18px] h-[18px] rounded-full bg-success flex justify-center items-center ml-1">
                                      <CheckIcon className="p-1 text-white" />
                                    </div>
                                  )}
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <InputParameters
                                key={node?.id} // to reload whenever node changed
                                params={nodeParams}
                                paramsType="actions"
                                initialValues={nodeParamsInitialValues}
                                nodeParamsValidation={nodeParamsValidation}
                                nodeFlowData={nodeFlowData}
                                setVariableSelectorState={
                                  setVariableSelectorState
                                }
                                onEditVariableDialogOpen={
                                  onEditVariableDialogOpen
                                }
                                valueChanged={valueChanged}
                                onSubmit={onSubmit}
                              />
                            </AccordionDetails>
                          </Accordion>
                          <Divider />
                        </Box>
                      )}
                      {/* networks */}
                      {nodeParamsType.includes("networks") && (
                        <Box sx={{ p: 2 }}>
                          <Accordion
                            expanded={expanded === "networks"}
                            onChange={handleAccordionChange("networks")}
                            sx={{ boxShadow: "none" }}
                            className="bg-white dark:bg-dark-3"
                          >
                            <AccordionSummary
                              expandIcon={
                                <ChevronDownIcon className="w-[15px] h-[15px] text-slate-700 dark:text-slate-300" />
                              }
                              aria-controls="networks-content"
                              id="networks-header"
                            >
                              <div className="flex items-center justify-between">
                                <Text className="text-sm font-semibold">
                                  Networks
                                </Text>
                                {nodeFlowData &&
                                  nodeFlowData.networks &&
                                  nodeFlowData.networks.submit && (
                                    <div className="w-[18px] h-[18px] rounded-full bg-success flex justify-center items-center ml-1">
                                      <CheckIcon className="p-1 text-white" />
                                    </div>
                                  )}
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <InputParameters
                                key={node?.id} // to reload whenever node changed
                                params={nodeParams}
                                paramsType="networks"
                                initialValues={nodeParamsInitialValues}
                                nodeParamsValidation={nodeParamsValidation}
                                nodeFlowData={nodeFlowData}
                                setVariableSelectorState={
                                  setVariableSelectorState
                                }
                                onEditVariableDialogOpen={
                                  onEditVariableDialogOpen
                                }
                                valueChanged={valueChanged}
                                onSubmit={onSubmit}
                              />
                            </AccordionDetails>
                          </Accordion>
                          <Divider />
                        </Box>
                      )}
                      {/* credentials */}
                      {nodeParamsType.includes("credentials") && (
                        <Box sx={{ p: 2 }}>
                          <Accordion
                            expanded={expanded === "credentials"}
                            onChange={handleAccordionChange("credentials")}
                            sx={{ boxShadow: "none" }}
                            className="bg-white dark:bg-dark-3"
                          >
                            <AccordionSummary
                              expandIcon={
                                <ChevronDownIcon className="w-[15px] h-[15px] text-slate-700 dark:text-slate-300" />
                              }
                              aria-controls="credentials-content"
                              id="credentials-header"
                            >
                              <div className="flex items-center justify-between">
                                <Text className="text-sm font-semibold">
                                  Credentials
                                </Text>
                                {nodeFlowData &&
                                  nodeFlowData.credentials &&
                                  nodeFlowData.credentials.submit && (
                                    <div className="w-[18px] h-[18px] rounded-full bg-success flex justify-center items-center ml-1">
                                      <CheckIcon className="p-1 text-white" />
                                    </div>
                                  )}
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <CredentialInput
                                key={node?.id} // to reload whenever node changed
                                initialParams={nodeParams}
                                paramsType="credentials"
                                initialValues={nodeParamsInitialValues}
                                initialValidation={nodeParamsValidation}
                                valueChanged={valueChanged}
                                paramsChanged={paramsChanged}
                                onSubmit={onSubmit}
                              />
                            </AccordionDetails>
                          </Accordion>
                          <Divider />
                        </Box>
                      )}
                      {/* inputParameters */}
                      {nodeParamsType.includes("inputParameters") && (
                        <Box sx={{ p: 2 }}>
                          <Accordion
                            expanded={expanded === "inputParameters"}
                            onChange={handleAccordionChange("inputParameters")}
                            sx={{ boxShadow: "none" }}
                            className="bg-white dark:bg-dark-3"
                          >
                            <AccordionSummary
                              expandIcon={
                                <ChevronDownIcon className="w-[15px] h-[15px] text-slate-700 dark:text-slate-300" />
                              }
                              aria-controls="inputParameters-content"
                              id="inputParameters-header"
                            >
                              <div className="flex items-center justify-between">
                                <Text className="text-sm font-semibold">
                                  Input Parameters
                                </Text>
                                {nodeFlowData &&
                                  nodeFlowData.inputParameters &&
                                  nodeFlowData.inputParameters.submit && (
                                    <div className="w-[18px] h-[18px] rounded-full bg-success flex justify-center items-center ml-1">
                                      <CheckIcon className="p-1 text-white" />
                                    </div>
                                  )}
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <InputParameters
                                key={node?.id} // to reload whenever node changed
                                params={nodeParams}
                                paramsType="inputParameters"
                                initialValues={nodeParamsInitialValues}
                                nodeParamsValidation={nodeParamsValidation}
                                nodeFlowData={nodeFlowData}
                                setVariableSelectorState={
                                  setVariableSelectorState
                                }
                                onEditVariableDialogOpen={
                                  onEditVariableDialogOpen
                                }
                                valueChanged={valueChanged}
                                onSubmit={onSubmit}
                              />
                            </AccordionDetails>
                          </Accordion>
                          <Divider />
                        </Box>
                      )}
                      {/* outputResponses */}
                      {nodeDetails && nodeFlowData && (
                        <Box sx={{ p: 2 }}>
                          <Accordion
                            expanded={expanded === "outputResponses"}
                            onChange={handleAccordionChange("outputResponses")}
                            sx={{ boxShadow: "none" }}
                            className="bg-white dark:bg-dark-3"
                          >
                            <AccordionSummary
                              expandIcon={
                                <ChevronDownIcon className="w-[15px] h-[15px] text-slate-700 dark:text-slate-300" />
                              }
                              aria-controls="outputResponses-content"
                              id="outputResponses-header"
                            >
                              <div className="flex items-center justify-between">
                                <Text className="text-sm font-semibold">
                                  Output Responses
                                </Text>
                                {nodeFlowData &&
                                  nodeFlowData.outputResponses &&
                                  nodeFlowData.outputResponses.submit && (
                                    <div className="w-[18px] h-[18px] rounded-full bg-success flex justify-center items-center ml-1">
                                      <CheckIcon className="p-1 text-white" />
                                    </div>
                                  )}
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <OutputResponses
                                key={node?.id} // to reload whenever node changed
                                nodeId={node?.id}
                                nodeParamsType={nodeParamsType}
                                nodeFlowData={nodeFlowData}
                                nodes={nodes}
                                edges={edges}
                                workflow={workflow}
                                onSubmit={onSubmit}
                              />
                            </AccordionDetails>
                          </Accordion>
                          <Divider />
                        </Box>
                      )}
                    </>
                  )}
                </div>
                <VariableSelector
                  key={JSON.stringify(availableNodesForVariable)}
                  nodes={availableNodesForVariable}
                  isVariableSelectorOpen={isVariableSelectorOpen}
                  anchorEl={anchorRef.current}
                  onVariableSelected={(returnVariablePath) =>
                    onVariableSelected(returnVariablePath)
                  }
                  handleClose={() => setVariableSelectorOpen(false)}
                />
                <EditVariableDialog
                  key={JSON.stringify(editVariableDialogProps)}
                  show={isEditVariableDialogOpen}
                  dialogProps={editVariableDialogProps}
                  onCancel={() => setEditVariableDialog(false)}
                  onConfirm={(updateValues) => {
                    if (expanded) {
                      valueChanged(updateValues, expanded);
                      setEditVariableDialog(false);
                    }
                  }}
                />
              </div>
            </ClickAwayListener>
          </Paper>
        </Transition>
      </Popper>
    </div>
  );
};

export default EditNodes;
