import { Control, Controller, useController } from "react-hook-form";
import { InstanceVolumeTypeGroup, OvhRegion } from "../../../types/instance";
import { useMemo } from "react";
import { Box, Slider } from "@mui/material";
import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import Text from "../../general/Text";
import { AnimatePresence, motion } from "framer-motion";
import { DBSAddon } from "../../../types/database";
import Input from "../../inputs/Input";
import { capitalizeFirstLetter } from "../../../utils";
import { getDBSPriceAmout } from "../../../pages/databases/ServiceCreatePage";
import { getVolumePlanTypeFromPlanCode } from "./utile";
import { humanizeSize } from "../../../utils/file";
import Button from "../../buttons/Button";
import { regionsData } from "../create/utils";

export const InstanceVolumeRegionInput: React.FC<{
  regions: OvhRegion[];
  control: Control<any>;
  errorMessage: string | undefined;
}> = ({ regions, control, errorMessage }) => {
  const activeRegions = useMemo(() => {
    return regions
      .filter((r) => r.services.find((s) => s.name === "instance"))
      .filter((r) => r.status === "UP");
  }, [regions]);

  return (
    <Controller
      name="selectedRegion"
      control={control}
      render={({ field: { value, onChange } }) => {
        return (
          <Box sx={{ width: "100%", typography: "body1" }}>
            {/* <Tabs
                  value={tabValue}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                      "&.Mui-disabled": { opacity: 0.3 },
                    },
                  }}
                >
                  {models.map((model, index) => (
                    <MuiTabItem label={model.title} {...a11yProps(index)} />
                  ))}
                </Tabs> */}
            <RadioGroup value={value || null} onChange={onChange}>
              <div className="flex flex-col w-full mt-3">
                <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-5">
                  {activeRegions.map((region, i) => (
                    <RadioGroup.Option key={i} value={region.name}>
                      {({ checked, disabled }) => {
                        const regionData = regionsData.find(
                          (rd) =>
                            rd.datacenterLocation === region.datacenterLocation
                        );
                        return (
                          <div
                            className={classNames(
                              "col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300 cursor-pointer h-full",
                              {
                                "border-primary-400 dark:border-primary-400":
                                  checked,
                                "!cursor-not-allowed": disabled,
                              }
                            )}
                          >
                            <div className="flex items-center justify-between mb-2">
                              <Text className="text-sm">
                                {regionData ? regionData.title : region.name}
                              </Text>
                              {regionData && regionData.dataImage && (
                                <span
                                  style={{
                                    backgroundImage: `url(${regionData.dataImage})`,
                                    backgroundSize: "contain",
                                    backgroundPosition: "50%",
                                  }}
                                  className="w-4 h-4 inline-block align-middle bg-no-repeat"
                                />
                              )}
                            </div>
                            <div className="flex flex-col border-t border-slate-200 dark:border-gray-400/40 mt-2 pt-2 gap-0.5">
                              <Text type="subtext" className="text-xs">
                                {region.name.toUpperCase()}
                              </Text>
                            </div>
                          </div>
                        );
                      }}
                    </RadioGroup.Option>
                  ))}

                  {/* {unavalibleRegions.length > 0 && (
                      <>
                        {" "}
                        <div className="col-span-full my-1 flex items-center gap-0.5">
                          <Switcher
                            on={showUnavalibles}
                            onToggle={setShowUnavalibles}
                          />
                          <Text className="text-xs">
                            Show unavailable regions
                          </Text>
                        </div>
                        {showUnavalibles && (
                          <>
                            {unavalibleRegions.map((region, i) => (
                              <RadioGroup.Option
                                key={i}
                                value={region.name}
                                disabled
                              >
                                {({ checked, disabled }) => {
                                  return (
                                    <div
                                      className={classNames(
                                        "col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300 cursor-pointer h-full",
                                        {
                                          "border-primary-400 dark:border-primary-400":
                                            checked,
                                          "!cursor-not-allowed opacity-60":
                                            disabled,
                                        }
                                      )}
                                    >
                                      <div className="flex items-center justify-between mb-2">
                                        <Text className="text-sm">
                                          {region.name.toUpperCase()}
                                        </Text>
                                      </div>
                                    </div>
                                  );
                                }}
                              </RadioGroup.Option>
                            ))}
                            <div className="col-span-full flex items-center justify-between">
                              <Text className="text-sm">
                                These regions are available for other models.
                              </Text>
                              <A
                                className="text-sm"
                                onClick={handleEditModelSelection}
                              >
                                Change model
                              </A>
                            </div>
                          </>
                        )}
                      </>
                    )} */}
                </div>

                <AnimatePresence>
                  {typeof errorMessage === "string" && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                    >
                      <p className="mt-2 text-xs text-red-600 dark:text-red-500">
                        {errorMessage}
                      </p>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </RadioGroup>
          </Box>
        );
      }}
    />
  );
};

export const InstanceVolumeTypeInput: React.FC<{
  types: InstanceVolumeTypeGroup[];
  addons: DBSAddon[];
  control: Control<any>;
  errorMessage: string | undefined;
}> = ({ types, addons, control, errorMessage }) => {
  return (
    <Controller
      name="selectedType"
      control={control}
      render={({ field: { value, onChange } }) => {
        return (
          <Box sx={{ width: "100%", typography: "body1" }}>
            <RadioGroup value={value || null} onChange={onChange}>
              <div className="flex flex-col w-full mt-3">
                <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-5">
                  {types.map((type, i) => {
                    const addon = addons.find(
                      (a) =>
                        a.planCode ===
                        `volume.${getVolumePlanTypeFromPlanCode(
                          type.plans[0].code
                        )}.consumption`
                    );

                    const volumData = addon
                      ? addon.blobs?.technical?.volume
                      : null;

                    const exVAT = addon
                      ? getDBSPriceAmout(addon.pricings[0].price, 6)
                      : null;
                    return (
                      <RadioGroup.Option
                        key={type.title + i}
                        value={type.plans[0].code}
                      >
                        {({ checked, disabled }) => {
                          return (
                            <div
                              className={classNames(
                                "col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300 cursor-pointer h-full",
                                {
                                  "border-primary-400 dark:border-primary-400":
                                    checked,
                                  "!cursor-not-allowed": disabled,
                                }
                              )}
                            >
                              <div className="flex items-center justify-between mb-2">
                                <Text className="text-sm">
                                  {capitalizeFirstLetter(type.title)}
                                </Text>
                              </div>
                              <div className="flex flex-col border-t mt-2 pt-2 gap-0.5">
                                {volumData && (
                                  <div className="flex items-center ">
                                    {volumData?.iops.guaranteed && (
                                      <Text className="text-xs font-medium">
                                        {volumData.iops.level} guaranteed IOPS
                                      </Text>
                                    )}
                                    {!volumData.iops.guaranteed && (
                                      <Text className="text-xs font-medium">
                                        {`Up to ${
                                          volumData.iops.max ||
                                          volumData.iops.level
                                        } IOPS`}
                                      </Text>
                                    )}
                                    {volumData.capacity.max && (
                                      <Text className="text-xs font-medium">
                                        ,{" "}
                                        {humanizeSize(
                                          volumData.capacity.max * 1000000000,
                                          { binaryBaseValue: 1000 }
                                        ).join("B")}{" "}
                                        max
                                      </Text>
                                    )}
                                  </div>
                                )}

                                {exVAT && (
                                  <div key={i} className="flex items-center ">
                                    <Text className="text-xs font-medium">
                                      €{exVAT} ex. VAT
                                    </Text>
                                    <Text className="text-xs font-medium">
                                      / hour
                                    </Text>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        }}
                      </RadioGroup.Option>
                    );
                  })}
                </div>
                <AnimatePresence>
                  {typeof errorMessage === "string" && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                    >
                      <p className="mt-2 text-xs text-red-600 dark:text-red-500">
                        {errorMessage}
                      </p>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </RadioGroup>
          </Box>
        );
      }}
    />
  );
};

export const InstanceVolumeCapacityInput: React.FC<{
  control: Control<any>;
  selectedAddon?: DBSAddon;
  errorMessage?: string;
  handleGoNexStep?: () => void;
  estimatedPrice: number;
}> = ({
  selectedAddon,
  control,
  errorMessage,
  handleGoNexStep,
  estimatedPrice,
}) => {
  const technicalData = selectedAddon ? selectedAddon.blobs?.technical : null;

  const {
    field: { value: capacity, onChange: onChangeCapacity },
  } = useController({ name: "capacity", control });

  //calculate maximum capacity value
  const minCapacityValue = 10;

  //calculate maximum capacity value
  const maxCapacityValue = useMemo(() => {
    if (
      technicalData &&
      technicalData.volume &&
      technicalData.volume.capacity
    ) {
      return technicalData.volume.capacity.max;
    } else {
      return minCapacityValue;
    }
  }, [technicalData]);

  //calculate bandwidth value
  const maxBandwidthValue = useMemo(() => {
    if (technicalData && technicalData.bandwidth) {
      return technicalData.bandwidth.max * 1000;
    } else {
      return null;
    }
  }, [technicalData]);

  //calculate bandwidth value
  const bandwidthValue = useMemo(() => {
    if (
      technicalData &&
      technicalData.bandwidth &&
      technicalData.bandwidth.level
    ) {
      let _value = technicalData.bandwidth.level * capacity;
      if (maxBandwidthValue && _value > maxBandwidthValue) {
        return maxBandwidthValue;
      }
      return _value;
    } else {
      return null;
    }
  }, [technicalData, capacity, maxBandwidthValue]);

  //calculate maximum iops value
  const maxIopsValue = useMemo(() => {
    if (technicalData && technicalData.volume) {
      return technicalData.volume.iops.max;
    } else {
      return null;
    }
  }, [technicalData]);

  //calculate iops value
  const iopsValue = useMemo(() => {
    if (
      technicalData &&
      technicalData.volume &&
      technicalData.volume.iops.max
    ) {
      let _value = technicalData.volume.iops.level * capacity;
      if (maxIopsValue && _value > maxIopsValue) {
        return maxIopsValue;
      } else {
        return _value;
      }
    }
    return null;
  }, [technicalData, capacity, maxIopsValue]);

  //handle value changing
  const handleChangeCapacityValue = (value: number) => {
    let _value = value;
    // if (_value < minCapacityValue) {
    //   _value = minCapacityValue;
    // }

    // if (_value > maxCapacityValue) {
    //   _value = maxCapacityValue;
    // }

    onChangeCapacity(_value);
  };

  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }}>
        {technicalData &&
          technicalData.volume &&
          technicalData.volume.capacity.max > 1 && (
            <div className="flex flex-col gap-1 mb-9">
              <div className="w-full md:w-1/2">
                <Input
                  inputProps={{
                    type: "number",
                    min: minCapacityValue,
                    max: maxCapacityValue,
                    onChange: (e: any) =>
                      handleChangeCapacityValue(Number(e.target.value)),
                    value: capacity,
                  }}
                  label="Value"
                  hint={`max: ${maxCapacityValue}`}
                />
              </div>

              <div className="flex flex-col">
                {bandwidthValue !== null && (
                  <Text className="text-sm">{`Bandwidth: ${bandwidthValue} MB/s`}</Text>
                )}
                {iopsValue !== null && (
                  <Text className="text-sm">{`IOPS: ${iopsValue} IOPS`}</Text>
                )}
                <Text className="text-sm">{`Estimated amount: €${getDBSPriceAmout(
                  estimatedPrice
                )} ex. VAT/month`}</Text>
              </div>

              <Text className="text-xs">
                The maximum size depends on your available quota.
              </Text>
              <AnimatePresence>
                {typeof errorMessage === "string" && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                  >
                    <p className="mt-2 text-xs text-red-600 dark:text-red-500">
                      {errorMessage}
                    </p>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          )}
      </Box>
      {handleGoNexStep && (
        <Button
          buttonProps={{
            disabled:
              capacity > maxCapacityValue || capacity < minCapacityValue,
            onClick: (e: any) => {
              e.preventDefault();
              handleGoNexStep();
            },
            id: "3th-next-button",
          }}
        >
          Next
        </Button>
      )}
    </>
  );
};

export const InstanceVolumeNameInput: React.FC<{
  control: Control<any>;
  errorMessage?: string;
  handleGoNexStep?: () => void;
}> = ({ control, errorMessage, handleGoNexStep }) => {
  const {
    field: { value, onChange },
  } = useController({ name: "volumeName", control });

  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <div className="flex flex-col gap-1 mb-9">
          <Input
            label="Volume name"
            inputProps={{
              value: value,
              onChange: onChange,
            }}
            error={errorMessage}
          />
        </div>
      </Box>

      {handleGoNexStep && (
        <Button
          buttonProps={{
            disabled:
              errorMessage !== undefined || value === undefined || value === "",
            onClick: (e: any) => {
              e.preventDefault();
              handleGoNexStep();
            },
            id: "4th-next-button",
          }}
        >
          Next
        </Button>
      )}
    </>
  );
};
