import Button from "../../buttons/Button";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useMemo, useState } from "react";
import {
  getDBSCertificateAsync,
  getDBSUsersAsync,
  getDatabaseServiceAsync,
  selectDatabaseService,
  selectDatabaseServiceLoading,
  selectDatabaseServiceUsers,
  selectDatabaseServiceUsersLoading,
} from "../../../store/database/serviceSlice";
import Text from "../../general/Text";
import Card from "../../general/Card";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import {
  DatabaseAuthorisedIPsUrl,
  DatabaseBackupsDuplicateUrl,
  DatabaseBackupsUrl,
  DatabaseServicesUrl,
  DatabaseUpgradeNodeUrl,
  DatabaseUpgradePlanUrl,
  DatabaseUpgradeStorageUrl,
  DatabaseUsersUrl,
} from "../../../utils/urls";
import { Select2 } from "../../inputs/Select";
import DatabaseRenameModal from "./../services/DatabaseRenameModal";
import {
  deleteDBSAsync,
  getDBSAvailabilityAsync,
  getDBSCapabilitiesAsync,
  getDatabaseServicesAsync,
  handleShowDatabaseRenameModal,
  selectDBSAvailability,
  selectDBSAvailabilityLoading,
  selectDBSCapabilities,
  selectDBSCapabilitiesLoading,
  selectDatabaseServicesActionLoading,
  updateDBSAsync,
} from "../../../store/database/servicesSlice";
import { DatabaseService } from "../../../types/database";
import { convertTimeStringToUTC, getGmtOffsetHours } from "../../../utils/date";
import QuestionModal from "../../modals/QuestionModal";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextField } from "@mui/material";
import Loading from "../../general/Loading";
import { NotExist } from "../../layouts/NotData";
import { ReactComponent as VerifyIcon } from "./../../../assets/icons/verify.svg";
import { ReactComponent as ErrorIcon } from "./../../../assets/icons/error-warning.svg";
import { binarySize, humanizeSize } from "../../../utils/file";
import { CopyableInput } from "../../general/CopyableKey";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Alert from "../../general/Alert";
import DBSCertificate from "./DBSCertificate";

const GeneralInformationTab = () => {
  const service = useAppSelector(selectDatabaseService);
  const loading = useAppSelector(selectDatabaseServiceLoading);
  const actionLoading = useAppSelector(selectDatabaseServicesActionLoading);
  const users = useAppSelector(selectDatabaseServiceUsers);
  const usersLoading = useAppSelector(selectDatabaseServiceUsersLoading);

  const availability = useAppSelector(selectDBSAvailability);
  const availabilityLoading = useAppSelector(selectDBSAvailabilityLoading);

  const capabilities = useAppSelector(selectDBSCapabilities);
  const capabilitiesLoading = useAppSelector(selectDBSCapabilitiesLoading);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [deleteService, setDeleteService] = useState<DatabaseService | null>(
    null
  );
  const [endpointService, setEndpointService] = useState<string | undefined>();
  const [maintenanceTime, setMaintenanceTime] = useState(
    service?.maintenanceTime || ""
  );
  const [showHandleMainTenanceButton, setShowHandleMainTenanceButton] =
    useState<boolean>(false);
  const [backupTime, setBackupTime] = useState(service?.backupTime || "");
  const [showHandleBackupTimeButton, setShowHandleBackupTimeButton] =
    useState<boolean>(false);

  const selectedEndpoint = useMemo(() => {
    return service?.endpoints.find((e) => e.component === endpointService);
  }, [endpointService, service]);

  const flavorData = useMemo(() => {
    return capabilities?.flavors?.find((f) => f.name === service?.flavor);
  }, [service, capabilities]);

  const availabilityData = useMemo(() => {
    return availability?.find((a) => a.flavor === service?.flavor);
  }, [service, availability]);

  useEffect(() => {
    if (availability === undefined || availability.length === 0) {
      dispatch(getDBSAvailabilityAsync());
    }
  }, [availability, dispatch]);

  useEffect(() => {
    if (capabilities === undefined) {
      dispatch(getDBSCapabilitiesAsync());
    }
  }, [capabilities, dispatch]);

  const handleChangeMaintenanceTime = (event: any) => {
    setMaintenanceTime(event.target.value);
    setShowHandleMainTenanceButton(true);
  };
  const handleChangeBackupTime = (event: any) => {
    setBackupTime(event.target.value);
    setShowHandleBackupTimeButton(true);
  };

  const handleActionMaintenanceTime = (isCancel: boolean) => {
    if (service) {
      dispatch(
        updateDBSAsync({
          id: service.id,
          engine: service.engine,
          data: {
            maintenanceTime: isCancel
              ? service?.maintenanceTime
              : maintenanceTime,
          },
        })
      ).then((action) => {
        if (action.type === "services/update/fulfilled") {
          dispatch(getDatabaseServicesAsync());
          dispatch(getDatabaseServiceAsync({ id: service.id }));
          setShowHandleMainTenanceButton(false);
          setMaintenanceTime(service.maintenanceTime);
        }
      });
    }
  };

  const handleActionBackupTime = (isCancel: boolean) => {
    if (service) {
      dispatch(
        updateDBSAsync({
          id: service.id,
          engine: service.engine,
          data: {
            backupTime: isCancel ? service?.backupTime : backupTime,
          },
        })
      ).then((action) => {
        if (action.type === "services/update/fulfilled") {
          dispatch(getDatabaseServicesAsync());
          dispatch(getDatabaseServiceAsync({ id: service.id }));
          setShowHandleBackupTimeButton(false);
          setBackupTime(service.maintenanceTime);
        }
      });
    }
  };

  useEffect(() => {
    if (service) {
      if (service.endpoints.length > 0) {
        setEndpointService(service.endpoints[0].component);
      }
      if (service.engine !== "m3aggregator") {
        dispatch(getDBSUsersAsync({ id: service.id, engine: service.engine }));
      }
    }
  }, [dispatch, service]);

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const [isVisibleIPWarning, setIsVisibleIPWarning] = useState(true);
  const [isVisibleUserWarning, setIsVisibleUserWarning] = useState(true);
  const [isVisibleStatusWarning, setIsVisibleStatusWarning] = useState(true);

  return (
    <>
      {!loading &&
        isVisibleIPWarning &&
        service &&
        service.ipRestrictions.length === 0 && (
          <div className="">
            <Alert
              type="warning"
              className="text-xs mb-3"
              showCloseButton={true}
            >
              Your cluster is not fully configured: you must authorize IP
              addresses in order to use it.
            </Alert>
          </div>
        )}

      {!usersLoading &&
        !loading &&
        isVisibleUserWarning &&
        users.length === 0 && (
          <div className="mt-1">
            <Alert
              type="warning"
              className="text-xs mb-3"
              showCloseButton={true}
            >
              Your cluster is not fully configured: you must create user in
              order to use it.
            </Alert>
          </div>
        )}

      {!loading &&
        isVisibleStatusWarning &&
        service &&
        service.status !== "READY" && (
          <div className="mt-1">
            <Alert
              type="warning"
              className="text-xs mb-3"
              showCloseButton={true}
            >
              Your cluster is not fully configured.
              {/* You can carry out operations on your cluster once the deployment process is complete. */}
            </Alert>
          </div>
        )}
      {service && !loading && (
        <>
          <div>
            <Card title="Cluster overview">
              <div className="mt-2 grid gap-4 md:grid-cols-2">
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Text type="title" className="text-sm">
                        Name
                      </Text>
                      <Text className="text-sm">
                        {service?.description}{" "}
                        <Button
                          type="default"
                          buttonProps={{
                            onClick: () =>
                              dispatch(handleShowDatabaseRenameModal(service)),
                          }}
                        >
                          Edit
                        </Button>
                      </Text>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Text type="title" className="text-sm">
                        Cluster ID
                      </Text>
                      <Text className="text-sm">{service?.id}</Text>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Text type="title" className="text-sm">
                        Status
                      </Text>
                      <Text
                        className="text-sm  inline-block"
                        type={
                          service.status === "CREATING"
                            ? "warning-alert"
                            : service.status === "READY"
                            ? "success-alert"
                            : "primary-alert"
                        }
                      >
                        {service.status}
                      </Text>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Text type="title" className="text-sm">
                        Service
                      </Text>
                      <Text className="text-sm">{service?.engine}</Text>
                      <Text className="text-sm"> {service?.version}</Text>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Text type="title" className="text-sm">
                        Service plan
                      </Text>
                      <Text className="text-sm">{service?.plan}</Text>
                      <Text className="text-sm flex items-center gap-1 mt-1">
                        <Link
                          className={classNames(
                            "text-sm cursor-pointer text-sky-500 hover:text-sky-600",
                            {
                              "!cursor-not-allowed": service.status !== "READY",
                            }
                          )}
                          to={
                            service.status !== "READY"
                              ? "#"
                              : DatabaseUpgradePlanUrl(service.id)
                          }
                        >
                          Upgrade your plan
                        </Link>
                      </Text>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Text type="title" className="text-sm">
                        Nodes
                      </Text>
                      <div className="flex ">
                        {service.nodes.map((node) => (
                          <div
                            className={classNames(
                              "w-3 aspect-square rounded-full mr-1",
                              {
                                "bg-success": node.status === "READY",
                                "bg-secondary-200 dark:bg-secondary-500":
                                  node.status !== "READY",
                              }
                            )}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Text type="title" className="text-sm">
                        Node template
                      </Text>
                      {availabilityData && flavorData && (
                        <Text className="text-sm">
                          {flavorData.core > 0
                            ? `${flavorData.core} CPU /`
                            : ""}
                          {flavorData.memory > 0
                            ? `${flavorData.memory} GB RAM /`
                            : ""}
                          {availabilityData.specifications.storage?.minimum
                            .value ===
                          availabilityData.specifications.storage?.maximum.value
                            ? `${
                                availabilityData.specifications.storage?.minimum
                                  ? humanizeSize(
                                      binarySize(
                                        availabilityData.specifications.storage
                                          ?.minimum.value,
                                        availabilityData.specifications.storage?.minimum.unit.slice()[0],
                                        1000
                                      ),
                                      {
                                        binaryBaseValue: 1000,
                                      }
                                    ).join("B")
                                  : ""
                              } storage`
                            : `  From ${
                                availabilityData.specifications.storage?.minimum
                                  ? humanizeSize(
                                      binarySize(
                                        availabilityData.specifications.storage
                                          ?.minimum.value,
                                        availabilityData.specifications.storage?.minimum.unit.slice()[0],
                                        1000
                                      ),
                                      {
                                        binaryBaseValue: 1000,
                                      }
                                    ).join("B")
                                  : ""
                              } ${
                                availabilityData.specifications.storage?.maximum
                                  ? humanizeSize(
                                      binarySize(
                                        availabilityData.specifications.storage
                                          ?.maximum.value,
                                        availabilityData.specifications.storage?.maximum.unit.slice()[0],
                                        1000
                                      ),
                                      {
                                        binaryBaseValue: 1000,
                                      }
                                    ).join("B")
                                  : ""
                              } storage`}
                        </Text>
                      )}
                      {flavorData && flavorData?.core > 0 && (
                        <Text className="text-sm flex items-center gap-1 mt-1">
                          <Link
                            className={classNames(
                              "text-sm cursor-pointer text-sky-500 hover:text-sky-600",
                              {
                                "!cursor-not-allowed":
                                  service.status !== "READY",
                              }
                            )}
                            to={
                              service.status !== "READY"
                                ? "#"
                                : DatabaseUpgradeNodeUrl(service.id)
                            }
                          >
                            Upgrade your node template
                          </Link>
                        </Text>
                      )}
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Text type="title" className="text-sm">
                        Storage
                      </Text>
                      <Text className="text-sm">
                        {service.storage.size.value} {service.storage.size.unit}{" "}
                      </Text>
                      <Text className="text-sm">First generation</Text>
                      <Text className="text-sm">remote drive</Text>

                      {flavorData && flavorData?.core > 0 && (
                        <Text className="text-sm flex items-center gap-1 mt-1">
                          <Link
                            className={classNames(
                              "text-sm cursor-pointer text-sky-500 hover:text-sky-600",
                              {
                                "!cursor-not-allowed":
                                  service.status !== "READY",
                              }
                            )}
                            to={
                              service.status !== "READY"
                                ? "#"
                                : DatabaseUpgradeStorageUrl(service.id)
                            }
                          >
                            Edit aditional storage
                          </Link>
                        </Text>
                      )}
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Text type="title" className="text-sm">
                        Datacenter
                      </Text>
                      <Text className="text-sm">{service.nodes[0].region}</Text>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          {service.endpoints.length > 0 && (
            <div className="mt-5">
              <Card title="Login information">
                <div className="mt-2 grid gap-4 md:grid-cols-2">
                  <div className="p-2">
                    <div className="flex justify-between flex-col md:flex-row">
                      <div className="mb-4 md:mb-0 w-full ">
                        <Text type="title" className="text-base ">
                          Service
                        </Text>
                        <Select2
                          label=""
                          value={endpointService}
                          onChange={(v) => setEndpointService(v)}
                          options={
                            service?.endpoints.map((s) => ({
                              label: s.component,
                              value: s.component,
                            })) || []
                          }
                          className="w-60"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="flex justify-between flex-col md:flex-row">
                      <div className="mb-4 md:mb-0 w-full">
                        <CopyableInput
                          title="URI"
                          value={selectedEndpoint?.uri}
                          loading={loading}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="flex justify-between flex-col md:flex-row">
                      <div className="mb-4 md:mb-0 w-full">
                        <Text type="title" className="text-sm">
                          Host
                        </Text>
                        <Text className="text-sm">
                          {selectedEndpoint?.domain}
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="flex justify-between flex-col md:flex-row">
                      <div className="mb-4 md:mb-0 w-full">
                        <Text type="title" className="text-sm">
                          Protocol
                        </Text>
                        <Text className="text-sm">
                          {
                            service?.endpoints.find(
                              (e: any) => e.component === endpointService
                            )?.scheme
                          }
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="flex justify-between flex-col md:flex-row">
                      <div className="mb-4 md:mb-0 w-full">
                        <Text type="title" className="text-sm">
                          SSL mode
                        </Text>
                        <Text className="text-sm">Mandatory</Text>
                      </div>
                    </div>
                  </div>

                  {selectedEndpoint?.port !== null && (
                    <div className="p-2">
                      <div className="flex justify-between flex-col md:flex-row">
                        <div className="mb-4 md:mb-0 w-full">
                          <Text type="title" className="text-sm">
                            Port
                          </Text>
                          <Text className="text-sm">
                            {selectedEndpoint?.port}
                          </Text>
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedEndpoint && (
                    <div className="p-2">
                      <div className="flex justify-between flex-col md:flex-row">
                        <div className="mb-4 md:mb-0 w-full">
                          <Text type="title" className="text-sm">
                            Path
                          </Text>
                          <Text className="text-sm">
                            {selectedEndpoint?.path
                              ? selectedEndpoint?.path
                              : "-"}
                          </Text>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="p-2">
                    <div className="flex justify-between flex-col md:flex-row">
                      <div className="mb-4 md:mb-0 w-full">
                        <Text type="title" className="text-sm">
                          Users
                        </Text>
                        <Text
                          className="text-sm inline-block"
                          type="success-alert"
                        >
                          {users ? `${users.length} user` : "No user set"}
                        </Text>
                        <Text className="text-sm flex items-center gap-1">
                          <Link
                            className={classNames(
                              "text-sm cursor-pointer text-sky-500 hover:text-sky-600",
                              {
                                "!cursor-not-allowed":
                                  service.status !== "READY",
                              }
                            )}
                            to={
                              service.status !== "READY"
                                ? "#"
                                : DatabaseUsersUrl(service.id)
                            }
                          >
                            Manage users
                          </Link>
                        </Text>
                      </div>
                    </div>
                  </div>

                  <DBSCertificate
                    title="CA certificate"
                    service={service}
                    validEngines={["mysql", "cassandra", "postgresql", "kafka"]}
                  />
                </div>
              </Card>
            </div>
          )}

          {/* {service.engine === "mysql" && (
            <div className="mt-5">
              <Card title="Service Integration">
                <div className="mt-2 grid gap-4 md:grid-cols-2">
                  <div className="p-2">
                    <div className="flex justify-between flex-col md:flex-row">
                      <div className="mb-4 md:mb-0 w-full">
                        <Text
                          className="text-sm  inline-block"
                          type="warning-alert"
                        >
                          none
                        
                        </Text>

                        <Text className="text-sm flex items-center gap-1">
                          <Link
                            className="text-sm  cursor-pointer text-sky-500 hover:text-sky-600"
                            to={DatabaseServicesUrl}
                          >
                            Manage integration
                          </Link>
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          )} */}

          <div className="mt-5">
            <Card title="Configuration">
              <div className="mt-2 grid gap-4 md:grid-cols-2">
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Text type="title" className="text-sm mb-2">
                        Maintenance time (UTC)
                      </Text>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div className="flex flex-row">
                          {" "}
                          <TextField
                            type="time"
                            defaultValue={service.maintenanceTime}
                            inputProps={{ step: 1 }}
                            onChange={handleChangeMaintenanceTime}
                            InputProps={{
                              className: "dark:bg-slate-400 ",
                            }}
                          />
                          {showHandleMainTenanceButton && (
                            <div className="flex flex-col mt-1 ml-2">
                              <div
                                onClick={() =>
                                  handleActionMaintenanceTime(false)
                                }
                              >
                                <Text>
                                  <VerifyIcon
                                    className="w-6 h-6"
                                    id="verify-icon"
                                  />
                                </Text>
                              </div>
                              <div
                                onClick={() =>
                                  handleActionMaintenanceTime(true)
                                }
                              >
                                <Text>
                                  <ErrorIcon className="w-6 h-6  " />
                                </Text>
                              </div>
                            </div>
                          )}
                        </div>
                      </LocalizationProvider>
                      <Text className="text-xs mt-1">
                        Local time (GMT {getGmtOffsetHours()}
                        ):
                        {service &&
                          convertTimeStringToUTC(
                            service?.maintenanceTime
                          ).toLocaleTimeString()}
                      </Text>
                    </div>
                  </div>
                </div>
                {service.capabilities.backups && (
                  <div className="p-2">
                    <div className="flex justify-between flex-col md:flex-row">
                      <div className="mb-4 md:mb-0 w-full">
                        <Text type="title" className="text-sm mb-2">
                          Backup time (UTC)
                        </Text>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <div className="flex flex-row">
                            {" "}
                            <TextField
                              type="time"
                              defaultValue={service.backupTime}
                              inputProps={{ step: 1 }}
                              onChange={handleChangeBackupTime}
                              InputProps={{
                                className: "dark:bg-slate-400 ",
                              }}
                            />
                            {showHandleBackupTimeButton && (
                              <div className="flex flex-col mt-1 ml-2">
                                <div
                                  onClick={() => handleActionBackupTime(false)}
                                >
                                  <Text>
                                    <VerifyIcon className="w-6 h-6   " />
                                  </Text>
                                </div>
                                <div
                                  onClick={() => handleActionBackupTime(true)}
                                >
                                  <Text>
                                    <ErrorIcon className="w-6 h-6  " />
                                  </Text>
                                </div>
                              </div>
                            )}
                          </div>
                        </LocalizationProvider>
                        <Text className="text-xs mt-1">
                          Local time (GMT {getGmtOffsetHours()}
                          ):
                          {service &&
                            convertTimeStringToUTC(
                              service?.backupTime
                            ).toLocaleTimeString()}
                        </Text>
                        <Text className="text-sm flex items-center gap-1">
                          <Link
                            className={classNames(
                              "text-sm cursor-pointer text-sky-500 hover:text-sky-600",
                              {
                                "!cursor-not-allowed":
                                  service.status !== "READY",
                              }
                            )}
                            to={
                              service.status !== "READY"
                                ? "#"
                                : DatabaseBackupsUrl(service.id)
                            }
                          >
                            Manage backups
                          </Link>
                        </Text>
                        <Text className="text-sm flex items-center gap-1">
                          <Link
                            className={classNames(
                              "text-sm cursor-pointer text-sky-500 hover:text-sky-600",
                              {
                                "!cursor-not-allowed":
                                  service.status !== "READY",
                              }
                            )}
                            to={
                              service.status !== "READY"
                                ? "#"
                                : DatabaseBackupsDuplicateUrl(service.id)
                            }
                          >
                            Duplicate(Fork)
                          </Link>
                        </Text>
                      </div>
                    </div>
                  </div>
                )}
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Text type="title" className="text-sm">
                        Network used
                      </Text>

                      <Text className="text-sm flex items-center gap-1">
                        {service?.networkType} network
                      </Text>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  {service.engine !== "m3aggregator" && (
                    <div className="flex justify-between flex-col md:flex-row">
                      <div className="mb-4 md:mb-0 w-full">
                        <Text type="title" className="text-sm">
                          Authorised IP
                        </Text>
                        {service.ipRestrictions.length > 0 ? (
                          service.ipRestrictions.map((p) => (
                            <div key={p.ip}>
                              <Text
                                className="text-sm inline-block mb-2"
                                type="success-alert"
                              >
                                {p.ip}
                              </Text>
                            </div>
                          ))
                        ) : (
                          <Text
                            className="text-sm inline-block mb-2"
                            type="warning-alert"
                          >
                            None
                          </Text>
                        )}

                        <Text className="text-sm flex items-center gap-1">
                          <Link
                            className={classNames(
                              "text-sm cursor-pointer text-sky-500 hover:text-sky-600",
                              {
                                "!cursor-not-allowed":
                                  service.status !== "READY",
                              }
                            )}
                            to={
                              service.status !== "READY"
                                ? "#"
                                : DatabaseAuthorisedIPsUrl(service.id)
                            }
                          >
                            Manage IP addresses
                          </Link>
                        </Text>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Card>
          </div>

          <div className="mt-5">
            <Text>
              All product names, logos, and brands are property of their
              respective owners. All company, product and service names used in
              this website are for identification purposes only. Use of these
              names, logos, and brands does not imply endorsement.
            </Text>
          </div>
          <DatabaseRenameModal />
          <QuestionModal
            isOpen={deleteService !== null}
            onClose={() => setDeleteService(null)}
            onConfirm={() => {
              deleteService &&
                dispatch(
                  deleteDBSAsync({
                    clusterId: deleteService.id,
                    engine: deleteService.engine,
                  })
                ).then((action) => {
                  if (action.type === "services/delete/fulfilled") {
                    dispatch(getDatabaseServicesAsync());
                    setDeleteService(null);
                    navigate(DatabaseServicesUrl);
                  }
                });
            }}
            loading={actionLoading}
            confirmButtonType="danger"
          />
        </>
      )}

      {/* empty service */}
      {service === null && !loading && (
        <div className="h-full flex items-center justify-center px-4">
          <div className="flex flex-col items-center">
            <div className="text-center space-y-1">
              <NotExist
                url={() => navigate(DatabaseServicesUrl)}
                text="The cluster does not exist"
              />
            </div>
          </div>
        </div>
      )}

      {loading && <Loading borderSize={2} className="!min-h-[300px]" />}
    </>
  );
};

export default GeneralInformationTab;
