import Button from "../../../../components/buttons/Button";
import { DeleteModal } from "../../../../components/modals/QuestionModal";
import { ReactComponent as PlusIcon } from "./../../../../assets/icons/plus.svg";
import { ReactComponent as ArchiveIcon } from "./../../../../assets/icons/archive-box.svg";
import { ReactComponent as MoreIcon } from "./../../../../assets/icons/more.svg";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../../../components/general/Table";
import NotData from "../../../../components/layouts/NotData";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { InstanceVolumeBackup } from "../../../../types/instance";
import { useSearch } from "../../../../providers/SearchProvider";
import {
  InstancesCreateVolumeUrl,
  InstancesVolumeBackupUrl,
} from "../../../../utils/urls";
import Text, { HighlighterText } from "../../../../components/general/Text";
import Dropdown from "../../../../components/general/Dropdown";
import { Helmet } from "react-helmet";
import {
  getInstancesVolumeAsync,
  getInstancesVolumesAsync,
  selectInstancesSelectedVolume,
  selectInstancesSelectedVolumeLoading,
} from "../../../../store/instances/instancesVolumesSlice";
import {
  deleteInstancesVolumeBackupAsync,
  getInstanceVolumeBackupListAsync,
  handleShowVolumeBackupModal,
  restoreInstancesVolumeBackupAsync,
  selectInstancesVolumeBackupsList,
  selectInstancesVolumeBackupsListLoading,
} from "../../../../store/instances/instancesVolumeSlice";
import { ReactComponent as RefreshIcon } from "./../../../../assets/icons/arrow-path.svg";
import { formatTimestamp } from "../../../../utils/date";
import InstanceVolumeBackupModal from "./InstanceVolumeBackupModal";

const InstanceVolumeBackupTab = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [deleteVolumeBackup, setDeleteVolumeBackup] = useState<string | null>(
    null
  );
  const volume = useAppSelector(selectInstancesSelectedVolume);
  const volumeLoading = useAppSelector(selectInstancesSelectedVolumeLoading);
  const volumeBackupsList = useAppSelector(selectInstancesVolumeBackupsList);
  const volumeBackupsListLoading = useAppSelector(
    selectInstancesVolumeBackupsListLoading
  );

  const [filteredVolumeBackups, setFilteredVolumeBackups] = useState<
    InstanceVolumeBackup[]
  >([]);

  const dispatch = useAppDispatch();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (volume === null && id !== undefined) {
      dispatch(getInstancesVolumeAsync({ id }));
    }
  }, [dispatch, id, volume]);

  useEffect(() => {
    if (volume && volume.id) {
      dispatch(
        getInstanceVolumeBackupListAsync({
          volumeId: volume?.id,
          regionName: volume?.region,
        })
      );
    }
  }, [dispatch, volume?.id]);

  useEffect(() => {
    const lookedUpApiKeys = volumeBackupsList.filter((key) =>
      key.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredVolumeBackups(lookedUpApiKeys);
  }, [dispatch, searchValue, volumeBackupsList]);

  return (
    <div className="">
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Volume backups</title>
        <meta name="description" content="" />
      </Helmet>

      <div className="flex flex-col gap-3">
        <div className="flex transition-all duration-500">
          <div className="ml-auto flex items-center justify-end w-full">
            <Button
              type="primary"
              buttonProps={{
                onClick: () =>
                  volume && dispatch(handleShowVolumeBackupModal({ volume })),
              }}
              buttonClassName="group"
            >
              Create a volume backup
              <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>
          </div>
        </div>

        <div className="mt-6">
          <Table loading={volumeBackupsListLoading || volumeLoading}>
            <TableHead>
              <TableRow>
                <TableTH lable="Name" />
                <TableTH lable="Region" />
                <TableTH lable="Volume" />
                <TableTH lable="Creation date" />
                <TableTH lable="Size" />
                <TableTH lable="Status" />
                <TableTH lable="" />
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredVolumeBackups.map((volumeBackup: any, index: any) => (
                <TableRow key={index} withoutHoverStyle>
                  <TableTD className="w-36">
                    <div className="flex flex-col gap-1">
                      <HighlighterText
                        searchValue={searchValue}
                        textToHighlight={volumeBackup.name}
                      />
                    </div>
                  </TableTD>
                  <TableTD>
                    <Text className="text-sm">{volumeBackup.region}</Text>
                  </TableTD>
                  <TableTD>
                    <Text className="text-sm whitespace-nowrap">
                      {volumeBackup.id}
                    </Text>
                  </TableTD>
                  <TableTD>
                    <Text className="text-sm whitespace-nowrap">
                      {
                        formatTimestamp(
                          volumeBackup.creationDate,
                          "MMM DD hh:mm:ss A"
                        ).datetime
                      }
                    </Text>
                  </TableTD>
                  <TableTD>
                    <Text className="text-sm whitespace-nowrap">
                      {volumeBackup.size} GiB
                    </Text>
                  </TableTD>
                  <TableTD>
                    <Text
                      className="text-sm whitespace-nowrap "
                      type={
                        volumeBackup.status === "ok"
                          ? "success-alert"
                          : volumeBackup.status === "deleting"
                          ? "error-alert"
                          : "primary-alert"
                      }
                    >
                      {volumeBackup.status}
                    </Text>
                  </TableTD>
                  <TableTD className="w-10">
                    <div className="w-8 flex justify-center items-center">
                      <Dropdown
                        positionClassName="!w-49"
                        menu={[
                          {
                            key: "restore",
                            label: (
                              <div className="flex items-center gap-1">
                                <RefreshIcon className="w-4" />
                                Restore a volume
                              </div>
                            ),
                            onClick: (_, close) => {
                              close();
                              volume &&
                                dispatch(
                                  restoreInstancesVolumeBackupAsync({
                                    volumeId: volume.id,
                                    regionName: volume?.region,
                                    volumeBackupId: volumeBackup?.id,
                                    data: { volumeId: volume?.id },
                                  })
                                ).then((action) => {
                                  if (
                                    action.type ===
                                    "instances/volume/backup/restore/fulfilled"
                                  ) {
                                    dispatch(
                                      getInstanceVolumeBackupListAsync({
                                        volumeId: volume?.id,
                                        regionName: volume?.region,
                                      })
                                    );
                                  }
                                });
                            },
                            disabled:
                              volumeBackup?.status === "restoring" ||
                              volumeBackup?.status === "creating" ||
                              volumeBackup?.status === "deleting",
                          },

                          {
                            key: "create",
                            label: (
                              <div className="flex items-center gap-1">
                                <PlusIcon className="w-4" />
                                Create a volume
                              </div>
                            ),
                            onClick: (_, close) => {
                              close();
                              navigate(InstancesCreateVolumeUrl);
                            },
                            disabled:
                              volumeBackup?.status === "restoring" ||
                              volumeBackup?.status === "creating" ||
                              volumeBackup?.status === "deleting",
                          },

                          // {
                          //   type: "divider",
                          // },
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: (_, close) => {
                              close();
                              setDeleteVolumeBackup(volumeBackup.id);
                            },
                            disabled:
                              volumeBackup?.status === "restoring" ||
                              volumeBackup?.status === "creating" ||
                              volumeBackup?.status === "deleting",
                          },
                        ]}
                        type="simple"
                      >
                        <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                          <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                        </div>
                      </Dropdown>
                    </div>
                  </TableTD>
                </TableRow>
              ))}
              {volumeBackupsList.length === 0 && (
                <TableRow withoutHoverStyle={true}>
                  <TableTD colSpan={7} className="!border-0">
                    <NotData text="You have not created any Volume Backup yet" />
                  </TableTD>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
      <InstanceVolumeBackupModal />
      <DeleteModal
        title="Delete a Volume Backup"
        isOpen={deleteVolumeBackup !== null}
        onClose={() => setDeleteVolumeBackup(null)}
        description="To confirm, type 'DELETE' in the box below"
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        loading={volumeBackupsListLoading}
        onConfirm={() => {
          deleteVolumeBackup &&
            volume &&
            dispatch(
              deleteInstancesVolumeBackupAsync({
                volumeId: volume.id,
                regionName: volume.region,
                volumeBackupId: deleteVolumeBackup,
              })
            ).then((action) => {
              if (action.type === "instances/volume/backup/delete/fulfilled") {
                dispatch(
                  getInstancesVolumeAsync({
                    id: volume?.id,
                  })
                );
                dispatch(
                  getInstanceVolumeBackupListAsync({
                    volumeId: volume?.id,
                    regionName: volume?.region,
                  })
                );
              }
              setDeleteVolumeBackup(null);
            });
        }}
        confirmString={
          volumeBackupsList.find((v) => v.id === deleteVolumeBackup)?.name
        }
      />
    </div>
  );
};

export default InstanceVolumeBackupTab;
