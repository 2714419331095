import Button from "../../buttons/Button";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { useEffect, useMemo, useState } from "react";
import QuestionModal from "../../modals/QuestionModal";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  selectRpc,
  getRpcTokensAsync,
  createRpcTokenAsync,
  selectRpcSecurityTokens,
  selectRpcTokenActionLoading,
  deleteRpcTokenAsync,
  createRpcWhiteListAsync,
  selectRpcWhiteListActionLoading,
  selectRpcWhiteList,
  getRpcWhiteListAsync,
  deleteRpcWhitelistAsync,
  selectRpcDomainMaskActionLoading,
  selectRpcDomainMasks,
  createRpcDomainMaskAsync,
  getRpcDomainMasksAsync,
  deleteRpcDomainMaskAsync,
  // createRpcContractAddressAsync,
  // createRpcSourceIpAsync,
  // openJwtEditorModal,
  // selectContractAddressCreateLoading,
  // selectSourceIpCreateLoading,
} from "../../../store/rpc/rpcSlice";
import JwtEditorModal from "./JwtEditorModal";
// import Loading from "../../general/Loading";
import AddressInput from "../../inputs/AddressInput";
import { AddressItem, AuthenticationToken } from "./DataRows";
import { EmptyList } from "../../layouts/NotData";
// import Select, { SelectOption } from "../../inputs/Select";
import { domainNameRegex, ipv4Regex } from "../../../utils/regex";
import Text from "../../general/Text";
import CopyableKey from "../../general/CopyableKey";
import Card from "../../general/Card";

// const WhitelistSelectOptions: SelectOption<number>[] = [
//   {
//     label: "Domain Name",
//     value: 0,
//   },
//   {
//     label: "IP Address",
//     value: 1,
//   },
// ];
const RpcEndpointSecurityTab = () => {
  const rpc = useAppSelector(selectRpc);

  const rpcSecurityTokens = useAppSelector(selectRpcSecurityTokens);
  const tokenActionLoading = useAppSelector(selectRpcTokenActionLoading);

  const rpcWhitelist = useAppSelector(selectRpcWhiteList);
  const whitelistActionLoading = useAppSelector(
    selectRpcWhiteListActionLoading
  );

  const rpcReferrers = useMemo(() => {
    return rpcWhitelist.filter((w) => w.WhiteListType === 0);
  }, [rpcWhitelist]);

  const rpcSources = useMemo(() => {
    return rpcWhitelist.filter((w) => w.WhiteListType === 1);
  }, [rpcWhitelist]);

  const rpcDomainMasks = useAppSelector(selectRpcDomainMasks);
  const domainMaskActionLoading = useAppSelector(
    selectRpcDomainMaskActionLoading
  );

  // const sourceIpCreateLoading = useAppSelector(selectSourceIpCreateLoading);
  // const contractAddressCreateLoading = useAppSelector(
  //   selectContractAddressCreateLoading
  // );

  const dispatch = useAppDispatch();

  //delete states
  const [deleteAuthToken, setDeleteAuthToken] = useState<null | number>(null);
  const [deleteJWT, setDeleteJWT] = useState<null | number>(null);
  const [deleteWhitelist, setDeleteWhitelist] = useState<null | number>(null);
  const [deleteDomainMask, setDeleteDomainMask] = useState<null | number>(null);
  const [deleteIp, setDeleteIp] = useState<null | number>(null);
  const [deleteContract, setDeleteContract] = useState<null | number>(null);

  useEffect(() => {
    if (rpc !== null) {
      dispatch(getRpcTokensAsync({ id: rpc.Id }));
    }
  }, [dispatch, rpc]);

  useEffect(() => {
    if (rpc !== null) {
      dispatch(getRpcWhiteListAsync({ id: rpc.Id }));
    }
  }, [dispatch, rpc]);

  useEffect(() => {
    if (rpc !== null) {
      dispatch(getRpcDomainMasksAsync({ id: rpc.Id }));
    }
  }, [dispatch, rpc]);

  const handleCreateToken = () => {
    if (rpc) {
      dispatch(createRpcTokenAsync({ id: rpc.Id })).then((action) => {
        if (action.type === "rpc/tokens/create/fulfilled") {
          dispatch(getRpcTokensAsync({ id: rpc.Id }));
        }
      });
    }
  };

  const handleDeleteSecurityToken = () => {
    if (rpc && deleteAuthToken) {
      dispatch(
        deleteRpcTokenAsync({ id: rpc.Id, tokenId: deleteAuthToken })
      ).then((action) => {
        if (action.type === "rpc/tokens/delete/fulfilled") {
          dispatch(getRpcTokensAsync({ id: rpc.Id }));
          setDeleteAuthToken(null);
        }
      });
    }
  };

  const handleCreateWhitelist = (value: string, type: number) => {
    if (rpc) {
      dispatch(
        createRpcWhiteListAsync({
          id: rpc.Id,
          value,
          type,
        })
      ).then((action) => {
        if (action.type === "rpc/whitelist/create/fulfilled") {
          dispatch(getRpcWhiteListAsync({ id: rpc.Id }));
        }
      });
    }
  };

  const handleDeleteWhitelist = () => {
    if (rpc && deleteWhitelist) {
      dispatch(
        deleteRpcWhitelistAsync({ id: rpc.Id, tokenId: deleteWhitelist })
      ).then((action) => {
        if (action.type === "rpc/whitelist/delete/fulfilled") {
          dispatch(getRpcWhiteListAsync({ id: rpc.Id }));
          setDeleteWhitelist(null);
        }
      });
    }
  };

  const handleCreateDomainMask = (value: string) => {
    if (rpc) {
      dispatch(createRpcDomainMaskAsync({ id: rpc.Id, value })).then(
        (action) => {
          if (action.type === "rpc/domain-mask/create/fulfilled") {
            dispatch(getRpcDomainMasksAsync({ id: rpc.Id }));
          }
        }
      );
    }
  };

  const handleDeleteDomainMask = () => {
    if (rpc && deleteDomainMask) {
      dispatch(
        deleteRpcDomainMaskAsync({ id: rpc.Id, tokenId: deleteDomainMask })
      ).then((action) => {
        if (action.type === "rpc/domain-mask/delete/fulfilled") {
          dispatch(getRpcDomainMasksAsync({ id: rpc.Id }));
          setDeleteDomainMask(null);
        }
      });
    }
  };

  // const handleCreateSourceIp = (value: string) => {
  //   if (rpc) {
  //     dispatch(createRpcSourceIpAsync({ id: rpc.Id, value })).then((action) => {
  //       if (action.type === "rpc/source-ip/create/fulfilled") {
  //         //TODO: fetch new data
  //       }
  //     });
  //   }
  // };

  // const handleCreateContractAddress = (value: string) => {
  //   if (rpc) {
  //     dispatch(createRpcContractAddressAsync({ id: rpc.Id, value })).then(
  //       (action) => {
  //         if (action.type === "rpc/contract-address/create/fulfilled") {
  //           //TODO: fetch new data
  //         }
  //       }
  //     );
  //   }
  // };

  return (
    <div className="space-y-6">
      <div>
        <Text className="mb-2 whitespace-nowrap font-medium">
          Authentication
        </Text>

        {/* Tokens */}
        <Card
          title="Tokens"
          description="You can disable token validation here. This means anyone who knows
        the host of your endpoint will be able to send requests, unless
        you add items to your referrer whitelist below."
        >
          <div className="mt-2">
            <div className="border rounded-lg dark:border-dark-2 dark:bg-dark-3 bg-white divide-y dark:divide-dark-2">
              {rpcSecurityTokens.map((rpcSecurityToken, i) => (
                <AuthenticationToken
                  key={i}
                  token={rpcSecurityToken}
                  onDelete={(id) => setDeleteAuthToken(id)}
                  deleteDisabled={!rpc || rpc.RPCNodeStatus !== "RUNNING"}
                />
              ))}
              {/* {rpcSecurityTokensLoading && (
        <Loading style={{ minHeight: "100px" }} />
      )} */}
            </div>
            <div className="flex justify-end pt-2">
              <Button
                loading={tokenActionLoading}
                buttonProps={{
                  disabled:
                    !rpc ||
                    tokenActionLoading ||
                    rpc.RPCNodeStatus !== "RUNNING",
                  onClick: handleCreateToken,
                }}
                contentClassName="w-24"
                type="light"
                startIcon={<PlusIcon className="w-4 h-4" />}
              >
                App Token
              </Button>
            </div>
          </div>
        </Card>

        {/* JSON Web Token */}
        {/* <div>
        <div className="space-y-4">
          <div>
            <div className="mt-4 text-slate-800 dark:text-slate-100 font-medium text-base">
              JSON Web Token
            </div>
            <div className="text-slate-500 dark:text-slate-400 text-sm">
              Add abuse mitigation to your endpoint with JWTs. Here is an article on how to create JWTs to front end applications.
            </div>
          </div>
        </div>
        <div className="mt-2">
          <div className="border rounded-lg dark:border-dark-2 dark:bg-dark-3 bg-white divide-y dark:divide-dark-2">
            <EmptyList text="No JSON web tokens added yet" />
            <AuthenticationToken />
          </div>
          <div className="flex justify-end pt-2">
            <Button
              contentClassName="w-24"
              type="light"
              startIcon={<PlusIcon className="w-4 h-4" />}
              buttonProps={{ onClick: () => dispatch(openJwtEditorModal()) }}
            >
              App JWT
            </Button>
          </div>
        </div>
      </div> */}
      </div>

      <div>
        <Text className="mb-2 whitespace-nowrap font-medium">Whitelists</Text>

        {/* Referrer */}
        <Card
          className="mb-6"
          title="Referrer"
          description="You can set up the host names which you wish to whitelist for
        access to your endpoint. If a host name is not on the whitelist,
        it will be denied access. Using a wildcard character (*) is only
        supported at the beginning of the domain."
        >
          <div className="mt-2">
            <div className="border rounded-lg dark:border-dark-2 dark:bg-dark-3 bg-white divide-y dark:divide-dark-2">
              {rpcWhitelist.length === 0 && (
                <EmptyList text="No referrers added yet" />
              )}
              {rpcReferrers.map((refrrer, i) => (
                <AddressItem
                  key={i}
                  value={
                    <div className="flex items-center gap-2">
                      <Text type="subtext" className="w-[120px]">
                        Domain Name
                      </Text>
                      <CopyableKey text={refrrer.WhiteList} />
                    </div>
                  }
                  onDelete={() => setDeleteWhitelist(refrrer.Id)}
                  deleteDisabled={!rpc || rpc.RPCNodeStatus !== "RUNNING"}
                />
              ))}
            </div>
          </div>
          <div className="flex items-start gap-2">
            <AddressInput
              placeholder="Example: djuno.io"
              loading={whitelistActionLoading}
              disabled={
                !rpc ||
                whitelistActionLoading ||
                rpc.RPCNodeStatus !== "RUNNING"
              }
              onAdd={(value: string) => handleCreateWhitelist(value, 0)}
              regex={domainNameRegex}
              errorMessage={`please enter a valid domain name`}
            />
          </div>
        </Card>

        {/* Source  */}
        <Card
          className="mb-6"
          title="Source IP"
          description="You can add  IP addresses that you wish to whitelist for access to your endpoint. If an IP address is not on the whitelist, it will be denied access."
        >
          <div className="mt-2">
            <div className="border rounded-lg dark:border-dark-2 dark:bg-dark-3 bg-white divide-y dark:divide-dark-2">
              {rpcWhitelist.length === 0 && (
                <EmptyList text="No referrers added yet" />
              )}
              {rpcSources.map((source, i) => (
                <AddressItem
                  key={i}
                  value={
                    <div className="flex items-center gap-2">
                      <Text type="subtext" className="w-[120px]">
                        IP Address
                      </Text>
                      <CopyableKey text={source.WhiteList} />
                    </div>
                  }
                  onDelete={() => setDeleteWhitelist(source.Id)}
                  deleteDisabled={!rpc || rpc.RPCNodeStatus !== "RUNNING"}
                />
              ))}
            </div>
          </div>
          <div className="flex items-start gap-2">
            <AddressInput
              placeholder="Example: 000.000.0.0"
              loading={whitelistActionLoading}
              disabled={
                !rpc ||
                whitelistActionLoading ||
                rpc.RPCNodeStatus !== "RUNNING"
              }
              onAdd={(value: string) => handleCreateWhitelist(value, 1)}
              regex={ipv4Regex}
              errorMessage={`please enter a valid`}
            />
          </div>
        </Card>

        {/* Domain Mask */}
        <Card
          title="Domain Mask"
          description={`If you would like to mask your Djuno Cloud RPC endpoint, simply add the
          domain name here then create a CNAME record that points to
          ${rpc?.RpcName}.`}
        >
          <div className="mt-2">
            <div className="border rounded-lg dark:border-dark-2 dark:bg-dark-3 bg-white divide-y dark:divide-dark-2">
              {rpcDomainMasks.length === 0 && (
                <EmptyList text="No domains added yet" />
              )}
              {rpcDomainMasks.map((domainMask, i) => (
                <AddressItem
                  key={i}
                  value={<CopyableKey text={domainMask.Mask} />}
                  onDelete={() => setDeleteDomainMask(domainMask.Id)}
                  deleteDisabled={!rpc || rpc.RPCNodeStatus !== "RUNNING"}
                />
              ))}
            </div>
          </div>
          <AddressInput
            loading={domainMaskActionLoading}
            disabled={!rpc || rpc.RPCNodeStatus !== "RUNNING"}
            placeholder="Example: djuno.io"
            onAdd={handleCreateDomainMask}
            regex={/^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/}
            errorMessage="please enter a valid domain name"
          />
        </Card>
      </div>

      <QuestionModal
        isOpen={deleteAuthToken !== null}
        onClose={() => setDeleteAuthToken(null)}
        onConfirm={handleDeleteSecurityToken}
        loading={tokenActionLoading}
        confirmButtonType="danger"
      />
      <QuestionModal
        isOpen={deleteJWT !== null}
        onClose={() => setDeleteJWT(null)}
        onConfirm={() => {}}
      />
      <QuestionModal
        isOpen={deleteWhitelist !== null}
        onClose={() => setDeleteWhitelist(null)}
        onConfirm={handleDeleteWhitelist}
        loading={whitelistActionLoading}
        confirmButtonType="danger"
      />
      <QuestionModal
        isOpen={deleteDomainMask !== null}
        onClose={() => setDeleteDomainMask(null)}
        onConfirm={handleDeleteDomainMask}
        loading={domainMaskActionLoading}
        confirmButtonType="danger"
      />
      <QuestionModal
        isOpen={deleteIp !== null}
        onClose={() => setDeleteIp(null)}
        onConfirm={() => {}}
      />
      <QuestionModal
        isOpen={deleteContract !== null}
        onClose={() => setDeleteContract(null)}
        onConfirm={() => {}}
      />
      <JwtEditorModal />
    </div>
  );
};

export default RpcEndpointSecurityTab;
