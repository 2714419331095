import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import { isAxiosError } from "axios";
import toast from "react-hot-toast";
import { ToastClasses } from "../../components/modals/alerts";
import { getS3LogsApi } from "../../apis/s3API";
import { S3Log, S3LogsState } from "../../types/s3-logs";
import { changeS3ActiveStatus } from "./s3PublicSlice";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";

const initialState: S3LogsState = {
  logs: [],
  loading: false,
  actionLoading: false,
  showEditor: false,
  selectedLog: null,
};

export const getS3LogsAsync = createAsyncThunk<
  { logs: S3Log[] },
  undefined,
  IThunkRejectValue
>("s3-logs", async (_, { rejectWithValue, fulfillWithValue, dispatch }) => {
  try {
    const response = await getS3LogsApi();

    const { Result, NodeStatus } = response.data;
    const logs = Result || [];

    dispatch(changeS3ActiveStatus(NodeStatus === 2));

    return fulfillWithValue({ logs });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const logsSlice = createSlice({
  name: "logs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getS3LogsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getS3LogsAsync.fulfilled, (state, action) => {
        const { logs } = action.payload;

        state.logs = logs;
        state.loading = false;
      })
      .addCase(getS3LogsAsync.rejected, (state, { payload }) => {
        state.loading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectS3Logs = (state: RootState) => state.s3Logs.logs;
export const selectLoading = (state: RootState) => state.s3Logs.loading;

// export const {} = logsSlice.actions;
export default logsSlice.reducer;
