import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { S3BucketEventSchema } from "../../../../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import Modal from "../../../../modals/Modal";
import Button from "../../../../buttons/Button";
import {
  addBucketEventsAsync,
  getBucketEventsAsync,
  handleHideEventEditor,
  selectBucketDetails,
  selectBucketDetailsLoading,
  selectBucketEventsActionLoading,
  selectShowBucketEventEditor,
} from "../../../../../store/s3/buckets/bucketSlice";
import Input from "../../../../inputs/Input";
import Text from "../../../../general/Text";
import { useEffect, useState } from "react";
import Select, { SelectOption } from "../../../../inputs/Select";
import {
  getS3ArnsAsync,
  selectS3Arns,
  selectS3ArnsLoading,
} from "../../../../../store/s3/eventsSlice";
import Checkbox from "../../../../inputs/Checkbox";

const S3SubscribeEventsModal = () => {
  const isOpen = useAppSelector(selectShowBucketEventEditor);
  const actionLoading = useAppSelector(selectBucketEventsActionLoading);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const bucketDetailsLoading = useAppSelector(selectBucketDetailsLoading);

  const arns = useAppSelector(selectS3Arns);
  const arnsLoading = useAppSelector(selectS3ArnsLoading);

  const dispatch = useAppDispatch();

  const [selectedARN, setSelectedARN] = useState<SelectOption<string>>();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(S3BucketEventSchema),
  });

  useEffect(() => {
    if (isOpen && arns.length === 0) {
      dispatch(getS3ArnsAsync());
    }
  }, [dispatch, arns.length, isOpen]);

  const handleClearComponent = () => {
    reset();
    setSelectedARN(undefined);
  };

  const onSubmit = (data: any) => {
    console.log(data);
    if (bucketDetails && selectedARN) {
      let events = [];
      if (isPut) events.push("put");
      if (isGet) events.push("get");
      if (isDelete) events.push("delete");
      if (isReplica) events.push("replica");
      if (isIlm) events.push("ilm");
      if (isScanner) events.push("scanner");
      const apiData = {
        configuration: {
          arn: selectedARN.value,
          events,
          prefix: data.prefix,
          suffix: data.suffix,
        },
        ignoreExisting: true,
      };
      console.log(apiData);
      dispatch(
        addBucketEventsAsync({
          bucketName: bucketDetails.name,
          data: apiData,
        })
      ).then((action) => {
        if (action.type === "bucket/events/add/fulfilled") {
          handleClearComponent();
          dispatch(handleHideEventEditor());
          dispatch(getBucketEventsAsync({ name: bucketDetails.name }));
        }
      });
    }
  };

  const isPut = watch("put");
  const isGet = watch("get");
  const isDelete = watch("delete");
  const isReplica = watch("replica");
  const isIlm = watch("ilm");
  const isScanner = watch("scanner");
  const isAllowToSubmit =
    selectedARN !== undefined &&
    (isPut || isDelete || isGet || isIlm || isReplica || isScanner);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleHideEventEditor());
        handleClearComponent();
      }}
      contentClassName="!max-w-2xl"
      containerClassName="!items-start"
      title={<Text type="title">Subscribe To Bucket Events</Text>}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col w-full gap-3">
          <Select
            label="ARN"
            options={[...arns.map((arn) => ({ label: arn, value: arn }))]}
            loading={arnsLoading}
            selected={selectedARN}
            setSelected={setSelectedARN}
            error={selectedARN ? undefined : "ARN is a required field!"}
          />
          <Input
            label="Prefix"
            inputProps={{ ...register("prefix") }}
            error={errors.prefix?.message}
          />
          <Input
            label="Suffix"
            inputProps={{ ...register("suffix") }}
            error={errors.suffix?.message}
          />

          <div className="flex flex-col gap-2">
            <Text className="text-sm">Select Event</Text>
            <Checkbox
              id="put"
              label="PUT - Object uploaded"
              inputProps={{ ...register("put") }}
            />
            <Checkbox
              id="get"
              label="GET - Object accessed"
              inputProps={{ ...register("get") }}
            />
            <Checkbox
              id="delete"
              label="DELETE - Object deleted"
              inputProps={{ ...register("delete") }}
            />
            <Checkbox
              id="replication"
              label="REPLICA - Object replicated"
              inputProps={{ ...register("replica") }}
            />
            <Checkbox
              id="ILM"
              label="ILM - Object transitioned"
              inputProps={{ ...register("ilm") }}
            />
            <Checkbox
              id="scanner"
              label="SCANNER - Object has too many versions / Prefixes has too many sub-folders"
              inputProps={{ ...register("scanner") }}
            />
          </div>
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button
            buttonProps={{
              onClick: (e) => {
                e.preventDefault();
                dispatch(handleHideEventEditor());
              },
            }}
            type="light"
            buttonClassName="w-[70px]"
          >
            Cancel
          </Button>
          <Button
            loading={actionLoading}
            type="primary"
            buttonProps={{
              disabled: bucketDetailsLoading || !isAllowToSubmit,
              type: "submit",
            }}
            buttonClassName="w-[70px]"
          >
            Set
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default S3SubscribeEventsModal;
