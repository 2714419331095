import { useAppSelector } from "../../../hooks";
import { S3NotActiveTab } from "../../layouts/NotData";

import { selectS3Status } from "../../../store/s3/s3PublicSlice";
import { Web3StoragePlansBox } from "../../plans/Web3StoragePlansBox";

const S3PlansTab = () => {
  const s3Status = useAppSelector(selectS3Status);

  if (!s3Status) {
    return <S3NotActiveTab />;
  }
  return (
    <>
      <Web3StoragePlansBox />
    </>
  );
};

export default S3PlansTab;
