import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createS3EventAsync,
  getS3EventsAsync,
  selectActionLoading,
  selectLoading,
  selectSelectedS3Event,
} from "../../../../store/s3/eventsSlice";
import Button from "../../../buttons/Button";
import { S3AmqpEventSchema } from "../../../../utils/validations";
import Input from "../../../inputs/Input";
import { useEffect, useState } from "react";
import Textarea from "../../../inputs/Textarea";
import Text from "../../../general/Text";
import Switcher from "../../../inputs/Switcher";
import { InfoTooltip } from "../../../general/Tooltip";
import { S3EventsUrl } from "../../../../utils/urls";
import { useNavigate } from "react-router-dom";
import useGetS3Events from "../../../../hooks/useGetS3Events";

const AmqpForm = () => {
  const event = useAppSelector(selectSelectedS3Event);
  const loading = useAppSelector(selectActionLoading);
  const eventsLoading = useAppSelector(selectLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const getS3Events = useGetS3Events();

  const [enableMandatory, setEnableMandatory] = useState<boolean>(false);
  const [enableDurable, setEnableDurable] = useState<boolean>(false);
  const [enableNoWait, setEnableNoWait] = useState<boolean>(false);
  const [enableInternal, setEnableInternal] = useState<boolean>(false);
  const [enableAutoDeleted, setEnableAutoDeleted] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(S3AmqpEventSchema),
  });

  useEffect(() => {
    if (event) {
    } else {
    }
  }, [event, setValue]);

  const onSubmit = (data: any) => {
    let keyValues = [
      {
        key: "url",
        value: data.url,
      },
      {
        key: "exchange",
        value: data.exchange,
      },
      {
        key: "exchange_type",
        value: data.exchange_type,
      },
      {
        key: "routing_key",
        value: data.routing_key,
      },
      {
        key: "mandatory",
        value: enableMandatory ? "on" : "off",
      },
      {
        key: "durable",
        value: enableDurable ? "on" : "off",
      },
      {
        key: "no_wait",
        value: enableNoWait ? "on" : "off",
      },
      {
        key: "internal",
        value: enableInternal ? "on" : "off",
      },
      {
        key: "auto_deleted",
        value: enableAutoDeleted ? "on" : "off",
      },
      {
        key: "delivery_mode",
        value: data.delivery_mode,
      },
      {
        key: "queue_dir",
        value: data.queue_dir,
      },
      {
        key: "comment",
        value: data.comment,
      },
    ];
    if (data.queue_limit !== null) {
      keyValues = [
        ...keyValues,
        {
          key: "queue_limit",
          value: data.queue_limit.toString(),
        },
      ];
    }
    const formData = {
      key_values: keyValues,
    };
    // if (event) {
    // dispatch(
    //   updateS3EventAsync({
    //     id: event.Id,
    //     data: {
    //       label: data.label,
    //     },
    //   })
    // ).then((action) => {
    //   if (action.type === "s3-events/update/fulfilled") {
    //     // handleClearComponent();
    //     dispatch(handleHideEditor());
    //     dispatch(getS3EventsAsync());
    //     reset();
    //   }
    // });
    // } else {
    dispatch(
      createS3EventAsync({
        event: "amqp",
        identifier: data.identifier,
        data: formData,
      })
    ).then((action) => {
      if (action.type === "s3-events/create/fulfilled") {
        getS3Events(() => {
          reset();
          navigate(S3EventsUrl);
        });
      }
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col w-full gap-5">
        <Input
          label="Identifier"
          tooltip="Unique descriptive string for this destination"
          placeholder="Enter Destination Identifier"
          inputProps={{ ...register("identifier") }}
          error={errors.identifier?.message}
          required
        />

        <Input
          label="URL"
          tooltip="AMQP server endpoint e.g. `amqp://myuser:mypassword@localhost:5672`"
          placeholder=""
          inputProps={{ ...register("url") }}
          error={errors.url?.message}
        />

        <Input
          label="Exchange"
          tooltip="Name of the AMQP exchange"
          placeholder="Enter Exchange"
          inputProps={{ ...register("exchange") }}
          error={errors.exchange?.message}
        />

        <Input
          label="Exchange Type"
          tooltip="AMQP exchange type"
          placeholder="Enter Exchange Type"
          inputProps={{ ...register("exchange_type") }}
          error={errors.exchange_type?.message}
        />

        <Input
          label="Routing Key"
          tooltip="Routing key for publishing"
          placeholder="Enter Routing Key"
          inputProps={{ ...register("routing_key") }}
          error={errors.routing_key?.message}
        />

        <div className="flex items-center justify-between w-full">
          <div className="flex gap-1 items-center">
            <Text type="title" className="text-sm !font-medium">
              Mandatory
            </Text>
            <InfoTooltip content="Quietly ignore undelivered messages when set to 'off', default is 'on'" />
          </div>
          <Switcher on={enableMandatory} onToggle={setEnableMandatory} />
        </div>

        <div className="flex items-center justify-between w-full">
          <div className="flex gap-1 items-center">
            <Text type="title" className="text-sm !font-medium">
              Durable
            </Text>
            <InfoTooltip content="Persist queue across broker restarts when set to 'on', default is 'off'" />
          </div>
          <Switcher on={enableDurable} onToggle={setEnableDurable} />
        </div>

        <div className="flex items-center justify-between w-full">
          <div className="flex gap-1 items-center">
            <Text type="title" className="text-sm !font-medium">
              No Wait
            </Text>
            <InfoTooltip content="Non-blocking message delivery when set to 'on', default is 'off'" />
          </div>
          <Switcher on={enableNoWait} onToggle={setEnableNoWait} />
        </div>

        <div className="flex items-center justify-between w-full">
          <div className="flex gap-1 items-center">
            <Text type="title" className="text-sm !font-medium">
              Internal
            </Text>
            <InfoTooltip content="Set to 'on' for exchange to be not used directly by publishers, but only when bound to other exchanges" />
          </div>
          <Switcher on={enableInternal} onToggle={setEnableInternal} />
        </div>

        <div className="flex items-center justify-between w-full">
          <div className="flex gap-1 items-center">
            <Text type="title" className="text-sm !font-medium">
              Auto Deleted
            </Text>
            <InfoTooltip content="Auto delete queue when set to 'on', when there are no consumers" />
          </div>
          <Switcher on={enableAutoDeleted} onToggle={setEnableAutoDeleted} />
        </div>

        <Input
          label="Delivery Mode"
          tooltip="Set to '1' for non-persistent or '2' for persistent queue"
          placeholder="ِEnter Kafka Version"
          inputProps={{ ...register("delivery_mode") }}
          error={errors.delivery_mode?.message}
        />

        <Input
          label="Queue Directory"
          tooltip="Staging directory for undelivered messages e.g. '/home/events'"
          placeholder="ِEnter Queue Directory"
          inputProps={{ ...register("queue_dir") }}
          error={errors.queue_dir?.message}
        />

        <Input
          label="Queue Limit"
          tooltip="Maximum limit for undelivered messages, defaults to '10000'"
          placeholder="ِEnter Queue Limit"
          inputProps={{ ...register("queue_limit"), type: "number" }}
          error={errors.queue_limit?.message}
        />

        <Textarea
          label="Comment"
          placeholder="Enter custom notes if any"
          textareaProps={{ ...register("comment") }}
          error={errors.comment?.message}
        />
      </div>
      <div className="mt-4 flex justify-end">
        <Button
          loading={loading || eventsLoading}
          type="primary"
          buttonProps={{
            disabled: loading || eventsLoading,
            type: "submit",
          }}
          buttonClassName="w-[160px]"
        >
          {event ? "Update Evet Target" : "Save Evet Target"}
        </Button>
      </div>
    </form>
  );
};
export default AmqpForm;
