import { PropsWithChildren, useEffect, useState } from "react";
import { S3bucketNameSchema } from "../../../../utils/validations";
import { useAppSelector } from "../../../../hooks";
import { selectBuckets } from "../../../../store/s3/buckets/bucketsSlice";
import classNames from "classnames";
import { ReactComponent as CheckIcon } from "./../../../../assets/icons/check.svg";
import { ReactComponent as ErrorIcon } from "./../../../../assets/icons/close.svg";

const S3BucketNameValidation = (props: PropsWithChildren<{ name: string }>) => {
  const { name } = props;
  const buckets = useAppSelector(selectBuckets);
  const [errors, setErrors] = useState<{ type: string; message: string }[]>();

  useEffect(() => {
    const nameValidationErrors = S3bucketNameSchema(name, [
      ...buckets.map((b) => b.name),
    ]);
    console.log("nameValidationErrors:", nameValidationErrors);
    setErrors(nameValidationErrors);
  }, [buckets, name]);

  return (
    <div className="flex flex-col w-full">
      <NameRule
        error={
          !!errors?.find((e) => e.type === "string") ||
          !!errors?.find((e) => e.type === "least3characters") ||
          !!errors?.find((e) => e.type === "most63characters")
        }
        text="Bucket names must be between 3 (min) and 63 (max) characters long"
      />
      <NameRule
        error={
          !!errors?.find((e) => e.type === "InvalidCharacters") ||
          !!errors?.find((e) => e.type === "finishWithDot")
        }
        text="Bucket names can consist only of lowercase letters, numbers, dots (.), and hyphens (-)"
      />
      <NameRule
        error={!!errors?.find((e) => e.type === "twoAdjacentPeriods")}
        text="Bucket names must not contain two adjacent periods, or a period adjacent to a hyphen"
      />

      <NameRule
        error={!!errors?.find((e) => e.type === "IPaddress")}
        text="Bucket names must not be formatted as an IP address (for example, 192.168.5.4)"
      />
      <NameRule
        error={!!errors?.find((e) => e.type === "prefixXn")}
        text="Bucket names must not start with the prefix xn--"
      />
      <NameRule
        error={!!errors?.find((e) => e.type === "suffixS3alias")}
        text="Bucket names must not end with the suffix -s3alias"
      />
      <NameRule
        error={!!errors?.find((e) => e.type === "partition")}
        text="Bucket names must be unique within a partition"
      />
    </div>
  );
};

const NameRule = ({ text, error }: { text: string; error: boolean }) => {
  return (
    <p
      className={classNames(
        "mt-2 text-sm flex items-start gap-1 font-semibold",
        {
          "text-red-600 dark:text-red-500": error,
          "text-slate-600 dark:text-primary-50": !error,
        }
      )}
    >
      <div style={{ width: 16, height: 16, marginTop: 5 }}>
        {error ? (
          <ErrorIcon className="w-full" />
        ) : (
          <CheckIcon className="w-full text-green-500" />
        )}
      </div>
      {text}
    </p>
  );
};
export default S3BucketNameValidation;
