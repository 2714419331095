import axios, { AxiosError, isAxiosError } from "axios";
import type { LoginUser } from "../types/auth";
import { getLocalStorage, setLocalStorage } from "../utils/localStorage";
import { Environment } from "../types/environment";
import { SignInUrl } from "../utils/urls";

export const PublicErrorMessage =
  "There was a problem receiving information. Try again later";

export const BASE_URL =
  process.env.REACT_APP_MODE === "develop"
    ? process.env.REACT_APP_BACK_END_URL + "v1"
    : "/v1";

export const S3_BASE_URL =
  process.env.REACT_APP_MODE === "develop"
    ? process.env.REACT_APP_S3_BACK_END_URL + "simple-storage"
    : "/simple-storage";

export const BLOCK_EVENT_BASE_URL =
  process.env.REACT_APP_MODE === "develop"
    ? process.env.REACT_APP_BLOCK_EVENT_BACK_END_URL + "event-api/v1"
    : "/event-api/v1";

export const DBS_BASE_URL =
  process.env.REACT_APP_MODE === "develop"
    ? process.env.REACT_APP_DBS_BACK_END_URL + "databases"
    : "/databases";

export const WF_BASE_URL =
  process.env.REACT_APP_MODE === "develop" &&
  process.env.REACT_APP_USE_DIRECT_WF === "true"
    ? process.env.REACT_APP_WF_BACK_END_URL || ""
    : "/wf";

export const INSTANCES_BASE_URL =
  process.env.REACT_APP_MODE === "develop"
    ? process.env.REACT_APP_INSTANCES_BACK_END_URL + "instance-services"
    : "/instance-services";

export const Axios = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const jwtAxios = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

type ApiScope = "s3" | "event" | "dbs" | "wf" | "instances";
export const jwtEnvAxios = (
  scope: ApiScope | undefined = undefined,
  withoutEnv: boolean = false
) => {
  const selectedEnvId = getLocalStorage<Environment | null>("env", null)?.Id;

  let BaseUrl = BASE_URL;

  switch (scope) {
    case "s3":
      if (withoutEnv) {
        BaseUrl = S3_BASE_URL;
      } else {
        BaseUrl = S3_BASE_URL + "/environments/" + selectedEnvId;
      }
      break;

    case "event":
      if (withoutEnv) {
        BaseUrl = BLOCK_EVENT_BASE_URL;
      } else {
        BaseUrl = BLOCK_EVENT_BASE_URL + "/environments/" + selectedEnvId;
      }
      break;

    case "dbs":
      if (withoutEnv) {
        BaseUrl = DBS_BASE_URL;
      } else {
        BaseUrl = DBS_BASE_URL + "/environments/" + selectedEnvId;
      }
      break;

    case "wf":
      if (process.env.REACT_APP_USE_DIRECT_WF === "true") {
        BaseUrl = WF_BASE_URL;
      } else {
        if (withoutEnv) {
          BaseUrl = BaseUrl + WF_BASE_URL;
        } else {
          BaseUrl = BaseUrl + "/environments/" + selectedEnvId + WF_BASE_URL;
        }
      }
      break;

    case "instances":
      if (withoutEnv) {
        BaseUrl = INSTANCES_BASE_URL;
      } else {
        BaseUrl = INSTANCES_BASE_URL + "/environments/" + selectedEnvId;
      }
      break;

    default:
      if (withoutEnv) {
      } else {
        BaseUrl = BaseUrl + "/environments/" + selectedEnvId;
      }
      break;
  }
  // if (scope === "s3") {
  //   BaseUrl = S3_BASE_URL + "/" + selectedEnvId;
  // } else if (scope === "event") {
  //   BaseUrl = BLOCK_EVENT_BASE_URL + "/environments/" + selectedEnvId;
  // } else if (scope === "dbs") {
  //   if (withoutEnv) {
  //     BaseUrl = DBS_BASE_URL;
  //   } else {
  //     BaseUrl = DBS_BASE_URL + "/environments/" + selectedEnvId;
  //   }
  // } else if (scope === "wf") {
  //   if (process.env.REACT_APP_USE_DIRECT_WF === "true") {
  //     BaseUrl = WF_BASE_URL;
  //   } else {
  //     BaseUrl = BaseUrl + WF_BASE_URL;
  //   }
  // } else if (scope === "instances") {
  //   if (withoutEnv) {
  //     BaseUrl = INSTANCES_BASE_URL;
  //   } else {
  //     BaseUrl = INSTANCES_BASE_URL + "/environments/" + selectedEnvId;
  //   }
  // }

  let user = getLocalStorage<LoginUser | null>("login", null);
  const newAxios = axios.create({
    baseURL: BaseUrl,
    // withCredentials: true,
    headers: {
      Authorization: `Bearer ${user?.JwtToken}`,
      // "Content-Type": "application/json",
      Accept: "*/*",
    },
  });

  // newAxios.interceptors.request.use((config) => {
  //   let user = getLocalStorage<LoginUser | null>("login", null);
  //   config.headers.Authorization = `Bearer ${user?.JwtToken}`;
  //   return config;
  // });

  newAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error.response.status === 401) {
        setLocalStorage("isAuthenticate", false);
        setLocalStorage("login", null);
        setLocalStorage("user", null);
        window.location.href = SignInUrl;
      } else {
        return Promise.reject(error);
      }
    }
  );

  return newAxios;
};

jwtAxios.interceptors.request.use((config) => {
  let user = getLocalStorage<LoginUser | null>("login", null);
  config.headers.Authorization = `Bearer ${user?.JwtToken}`;
  return config;
});

jwtAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      setLocalStorage("isAuthenticate", false);
      setLocalStorage("login", null);
      setLocalStorage("user", null);
      window.location.href = SignInUrl;
    } else {
      return Promise.reject(error);
    }
  }
);

export const FetchCatchResponse = (e: any) => {
  let message = "network error";
  let errors = [];
  let statusCode: number | undefined;
  if (e instanceof AxiosError) {
    console.log("ERROR", e);
    message = e.response?.data.message || e.response?.data.title || e.message;
    statusCode = e.response?.status;
    errors = e.response?.data.errors;
  } else {
    console.log("ERROR", e);
  }
  return {
    data: null,
    status: false,
    message,
    errors,
    statusCode,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getExtractErrors = (e: AxiosError<any, any> | unknown) => {
  // console.log("isAxiosError(e)", isAxiosError(e));
  if (isAxiosError(e)) {
    let message = "";

    if (e.response?.data?.status === 400) {
      message = `<ul>${Object.values(e.response.data.errors).map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any) => `<li>${e}</li>`
      )}</ul>`;
    } else {
      message = `<ul><li>${
        e.response?.data.Message ||
        e.response?.data.message ||
        `${e.response?.status}: ${e.response?.statusText}` ||
        PublicErrorMessage
      }</li></ul>`;
    }
    return message;
  } else {
    // console.log(e && 'message' in e ? e.message)
    return "Bad Request";
  }
};

export const getExtractErrorCode = (e: AxiosError<any, any> | unknown) => {
  if (isAxiosError(e)) {
    return e.response?.status;
  } else {
    return 500;
  }
};
export default jwtAxios;
