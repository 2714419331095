import { useNavigate } from "react-router-dom";
import Dropdown from "../general/Dropdown";
import { DropdownElement } from "../../types/dropdown";
import UserAvatar from "./../../assets/images/blank-avatar.png";
// import { ReactComponent as AvatarIcon } from "./../../assets/icons/avatar.svg";
import { ReactComponent as UserIcon } from "./../../assets/icons/user.svg";
import { ReactComponent as TeamsIcon } from "./../../assets/icons/teams.svg";
import { ReactComponent as SignOutIcon } from "./../../assets/icons/sign-out.svg";
import { SettingsProfileUrl, SettingsTeamsUrl } from "../../utils/urls";
import {
  logout,
  selectOnStageEnv,
  selectUser,
} from "../../store/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import Text from "../general/Text";
import { BASE_URL } from "../../apis";

const UserDropdown: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const onStageEnv = useAppSelector(selectOnStageEnv);
  const dispatch = useAppDispatch();

  const userDropdoemItems: Array<DropdownElement> = [
    {
      key: "1",
      label: (
        <div className="flex items-center gap-2">
          <UserIcon className="w-4 h-4" /> Profile
        </div>
      ),
      onClick: (_, close) => {
        close();
        navigate(SettingsProfileUrl);
      },
    },
    {
      key: "2",
      label: (
        <div className="flex items-center gap-2">
          <TeamsIcon className="w-4 h-4" /> Teams
        </div>
      ),
      onClick: (_, close) => {
        close();
        navigate(SettingsTeamsUrl);
      },
      disabled: !onStageEnv?.IsAccountOwner,
    },
    { type: "divider" },
    {
      key: "3",
      label: (
        <div className="flex items-center gap-2">
          <SignOutIcon className="w-4 h-4" /> Sign out
        </div>
      ),
      danger: true,
      onClick: () => dispatch(logout()),
    },
  ];
  return (
    <Dropdown
      title={`${user?.FullName} (${user?.Email})`}
      menu={userDropdoemItems}
      type="simple"
      positionClassName="right-0"
    >
      <div className="flex items-center gap-2">
        <div className="w-7 h-7 aspect-square rounded-full overflow-hidden shadow">
          <img
            src={
              user?.AvatarLink !== null
                ? BASE_URL + user?.AvatarLink
                : UserAvatar
            }
            alt={user?.FullName}
            className="w-full h-full"
          />
        </div>
        <div className="hidden md:block whitespace-nowrap">
          <Text>{user?.FullName}</Text>
        </div>
      </div>
    </Dropdown>
  );
};
export default UserDropdown;
