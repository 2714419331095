import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  closeJwtEditorModal,
  createRpcJwtTokenAsync,
  selectIsOpenJwtEditorModal,
  selectJwtCreateLoading,
} from "../../../store/rpc/rpcSlice";
import Modal from "../../modals/Modal";
import Input from "../../inputs/Input";
import Button from "../../buttons/Button";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { RpcJwtSchema } from "../../../utils/validations";
import Textarea from "../../inputs/Textarea";

const JwtEditorModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectIsOpenJwtEditorModal);
  const loading = useAppSelector(selectJwtCreateLoading);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(RpcJwtSchema),
  });

  const onSubmit = (data: any) => {
    dispatch(
      createRpcJwtTokenAsync({
        id: 1,
        name: data.name,
        publicKey: data.publicKey,
      })
    ).then((action) => {
      if (action.type === "rpc/jwt-token/create/fulfilled") {
        reset();
        //TODO: fetch new data
        dispatch(closeJwtEditorModal());
      }
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        reset();
        dispatch(closeJwtEditorModal());
      }}
      contentClassName="max-w-2xl"
      //   containerClassName="!items-start"
      title="Add a JSON Web Token"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5">
          <Input
            label="Name"
            inputProps={{ ...register("name") }}
            error={errors.name?.message}
            placeholder=""
            required
          />
        </div>
        <div className="mt-5">
          <Textarea
            label="Public Key"
            textareaProps={{ ...register("publicKey"), rows: 7 }}
            error={errors.publicKey?.message}
            textareaClassName="text-xs"
            placeholder="-----BEGIN PUBLIC KEY-----"
            required
          />
        </div>

        <div className="mt-4 flex justify-end">
          <Button
            contentClassName="w-10"
            type="primary"
            buttonProps={{
              disabled: loading,
              type: "submit",
            }}
            loading={loading}
          >
            Create
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default JwtEditorModal;
