import { Outlet, useNavigate, useParams } from "react-router-dom";
import Tabs from "../../components/general/Tabs";
import { TabOption, TabOptions } from "../../types/tabs";
import {
  InstancesUrl,
  InstanceOverviewUrl,
  InstanceVncConsoleUrl,
  InstanceNetworksUrl,
  InstanceImagesUrl,
  InstanceRescaleUrl,
  InstanceBackupsUrl,
  InstanceDeleteUrl,
} from "../../utils/urls";
import { ReactComponent as RightArrow } from "./../../assets/icons/arrow-right.svg";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import classNames from "classnames";
import Text from "../../components/general/Text";
import { Helmet } from "react-helmet";
import { ReactComponent as BackupIcon } from "./../../assets/icons/arrow-up-on-square.svg";
import { Instance } from "../../types/instance";
import {
  getInstanceAsync,
  selectInstance,
  selectInstanceLoading,
} from "../../store/instances/instanceSlice";
import { ReactComponent as ComputerIcon } from "./../../assets/icons/computer-desktop.svg";
import { ReactComponent as GlobeIcon } from "./../../assets/icons/globe-alt.svg";
import { ReactComponent as DockerIcon } from "./../../assets/icons/socials/docker.svg";
import { ReactComponent as SellIcon } from "./../../assets/icons/shell.svg";
import { ReactComponent as DeleteIcon } from "./../../assets/icons/archive-box.svg";
import { ReactComponent as RescaleIcone } from "./../../assets/icons/arrow-path.svg";

// export const generateServicePermissions = () => {
//   const permissions: Record<string, DBSEngineType[] | "*"> = {
//   };

//   return permissions;
// };

export const generateInstanceRoutes = (instance: Instance) => {
  const options: TabOptions = [];

  const overviewOption = {
    label: (
      <div className="flex items-center gap-1">
        <ComputerIcon className="w-3" />
        Overview
      </div>
    ),
    url: InstanceOverviewUrl(instance.id),
    testId: "1th-tab",
  };
  const networksOption = {
    label: (
      <div className="flex items-center gap-1">
        <GlobeIcon className="w-3" />
        Networks
      </div>
    ),
    url: InstanceNetworksUrl(instance.id),
    testId: "2th-tab",
  };
  const imagesOption = {
    label: (
      <div className="flex items-center gap-1">
        <DockerIcon className="w-3" />
        Images
      </div>
    ),
    url: InstanceImagesUrl(instance.id),
    testId: "3th-tab",
  };
  const rescaleOption = {
    label: (
      <div className="flex items-center gap-1">
        <RescaleIcone className="w-3" />
        Rescale
      </div>
    ),
    url: InstanceRescaleUrl(instance.id),
    testId: "4th-tab",
  };
  const backupsOption = {
    label: (
      <div className="flex items-center gap-1">
        <BackupIcon className="w-3" />
        Backups
      </div>
    ),
    url: InstanceBackupsUrl(instance.id),
    testId: "5th-tab",
  };
  const vncConsoleOption: TabOption = {
    label: (
      <div className="flex items-center gap-1">
        <SellIcon className="w-3" />
        VNC console
      </div>
    ),
    url: InstanceVncConsoleUrl(instance.id),
    testId: "6th-tab",
  };
  const deleteOption: TabOption = {
    label: (
      <div className="flex items-center gap-1">
        <DeleteIcon className="w-3" />
        Delete
      </div>
    ),
    url: InstanceDeleteUrl(instance.id),
    testId: "7th-tab",
  };

  options.push(overviewOption);
  options.push(networksOption);
  options.push(imagesOption);
  options.push(rescaleOption);
  options.push(backupsOption);
  options.push(vncConsoleOption);
  options.push(deleteOption);

  return options;
};

const InstancePage = () => {
  const { instanceId } = useParams();
  const instance = useAppSelector(selectInstance);
  const loading = useAppSelector(selectInstanceLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const InstancePageMenus = useMemo(() => {
    if (instance) {
      return generateInstanceRoutes(instance);
    }
    return [];
  }, [instance]);

  useEffect(() => {
    if (instanceId) {
      dispatch(getInstanceAsync({ instanceId }));
    }
  }, [dispatch, instanceId]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (
      instance &&
      [
        "SHELVING",
        "UNSHELVING",
        "BUILD",
        "RESCUING",
        "UNRESCUING",
        "REBOOT",
        "HARD_REBOOT",
        "REBUILD",
        "RESIZE",
        "VERIFY_RESIZE",
        "SNAPSHOTING",
      ].includes(instance.status)
    ) {
      interval = setInterval(() => {
        dispatch(
          getInstanceAsync({
            instanceId: instance.id,
            withoutLoading: true,
          })
        );
      }, 20000);
    } else {
      if (interval) clearInterval(interval);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, instance]);

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | Database (
          {instance ? instance.name : ""})
        </title>
        <meta
          name="description"
          content="Deploy an option from our range of instances, and harness the flexibility of the cloud to grow in a way that suits your needs."
        />
      </Helmet>
      <div className="flex items-center justify-between h-16">
        <div className="px-6 items-center justify-between flex flex-1 transition duration-150">
          <div className="flex items-center gap-2">
            <RightArrow
              onClick={() => navigate(InstancesUrl)}
              className="rotate-180 w-5 h-5 hover:scale-110 transition-all duration-500 text-slate-800 dark:text-slate-100 cursor-pointer"
            />
            <div
              className={classNames(
                " dark:text-slate-100 min-w-[200px] h-8 rounded-md px-2 flex items-center gap-2",
                {
                  "bg-slate-300 dark:bg-slate-50/10 animate-pulse": loading,
                }
              )}
            >
              <div className="flex flex-col">
                <Text type="title" className="text-lg">
                  {instance?.name}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="px-6 mt-5">
        <div className="h-11">
          <Tabs useUrl options={InstancePageMenus} />
        </div>
        <div className="py-6">
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default InstancePage;
