import Button from "../../../buttons/Button";
import Card from "../../../general/Card";
import Text from "../../../general/Text";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  deleteInstancesVolumesAsync,
  getInstancesVolumesAsync,
  selectInstancesSelectedVolume,
  selectInstancesSelectedVolumeLoading,
  selectInstancesVolumesActionLoading,
} from "../../../../store/instances/instancesVolumesSlice";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { InstancesVolumesUrl } from "../../../../utils/urls";
import { DeleteModal } from "../../../modals/QuestionModal";

const VolumeDeleteTab = () => {
  const [deleteVolume, setDeleteVolume] = useState<string | null>(null);
  const volume = useAppSelector(selectInstancesSelectedVolume);
  const actionLoading = useAppSelector(selectInstancesVolumesActionLoading);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <div>
      {" "}
      <Card title="Delete">
        <div className="mt-2 grid gap-4 w-full">
          <Text className="text-sm">Deleting your volume...</Text>

          <>
            <div className="p-2">
              {volume && (
                <Button
                  buttonProps={{
                    onClick: () => {
                      setDeleteVolume(volume.id);
                    },
                  }}
                  type="danger"
                >
                  Delete volume
                </Button>
              )}
            </div>
          </>
        </div>
      </Card>
      <DeleteModal
        title="Delete a Volume"
        isOpen={deleteVolume !== null}
        onClose={() => setDeleteVolume(null)}
        description="To confirm, type 'DELETE' in the box below"
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        loading={actionLoading}
        onConfirm={() => {
          deleteVolume &&
            dispatch(deleteInstancesVolumesAsync({ id: deleteVolume })).then(
              (action) => {
                if (action.type === "instances/volumes/delete/fulfilled") {
                  dispatch(getInstancesVolumesAsync({}));
                  navigate(InstancesVolumesUrl);
                }
                setDeleteVolume(null);
              }
            );
        }}
        confirmString={volume?.name}
      />
    </div>
  );
};

export default VolumeDeleteTab;
