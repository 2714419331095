import { Outlet } from "react-router-dom";
import Tabs from "../../components/general/Tabs";
import { TabOptions } from "../../types/tabs";
import {
  BlockEventDestinationsUrl,
  BlockEventNotificationsUrl,
  BlockEventPlansUrl,
} from "../../utils/urls";
import BlockEventDesticationTab from "../../components/block-events/destinations/BlockEventDesticationsTab";
import BlockEventNotificationsTab from "../../components/block-events/notifications/BlockEventNotificationsTab";
import { ReactComponent as BellIcon } from "../../assets/icons/bell.svg";
import { ReactComponent as IPIcon } from "../../assets/icons/ip.svg";
import { ReactComponent as ListBulletIcon } from "./../../assets/icons/list-bullet.svg";
import BlockEventPlansTab from "../../components/block-events/plans/BlockEventPlansTab";

export const BlockEventsPageMenus: TabOptions = [
  {
    label: (
      <div className="flex items-center gap-1">
        <BellIcon className="w-3" />
        Events
      </div>
    ),
    url: BlockEventNotificationsUrl,
    element: <BlockEventNotificationsTab />,
    testId: "1th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <IPIcon className="w-3" />
        Destinations
      </div>
    ),
    url: BlockEventDestinationsUrl,
    element: <BlockEventDesticationTab />,
    testId: "2th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <ListBulletIcon className="w-3" />
        Plans
      </div>
    ),
    url: BlockEventPlansUrl,
    element: <BlockEventPlansTab />,
    testId: "3th-tab",
  },
];

const BlockEventsPage = () => {
  return (
    <>
      <div className="flex items-center justify-between h-16">
        <div className="px-6 items-center justify-between flex flex-1 transition duration-150">
          <div className="font-medium mr-2 text-standard text-xl dark:text-slate-100">
            Block Events
          </div>
        </div>
        {/* <div className="mr-6 w-96"></div> */}
      </div>
      <div className="px-6">
        <Tabs
          useUrl
          options={BlockEventsPageMenus.map((o) => ({
            label: o.label,
            url: o.url,
            testId: o.testId,
          }))}
        />
        <div className="py-6">
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default BlockEventsPage;
