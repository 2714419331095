import classNames from "classnames";
import { HTMLAttributes, PropsWithChildren } from "react";

interface IconBtnWrapperProps extends HTMLAttributes<HTMLDivElement> {
  type?: "default" | "danger";
}

export const IconBtnWrapper: React.FC<
  PropsWithChildren<IconBtnWrapperProps>
> = ({ children, className, type, ...props }) => {
  return (
    <div
      {...props}
      className={classNames(
        "p-2 rounded-md flex items-center justify-center w-8 aspect-square hover:cursor-pointer transition-colors duration-300",
        {
          "dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200":
            type === undefined || type === "default",
          " hover:dark:bg-dark-2 hover:bg-red-100 hover:text-red-500":
            type === "danger",
        },
        className
      )}
    >
      {children}
    </div>
  );
};
