import { useCallback } from "react";
import {
  SearchActionType,
  useSearch,
  useSearchDispatch,
} from "../../providers/SearchProvider";
import Input from "../inputs/Input";
import { ReactComponent as SearchIcon } from "./../../assets/icons/search.svg";

const SearchInput = () => {
  const { value } = useSearch();
  const dispatch = useSearchDispatch();
  const handleChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      if (!value) dispatch({ type: SearchActionType.CLEAR, payload: value });
      else {
        dispatch({ type: SearchActionType.SET_VALUE, payload: value });
      }
    },
    [dispatch]
  );

  return (
    <div className="flex items-center gap-1 px-6 lg:px-0">
      <SearchIcon className="w-6 aspect-square text-slate-400" />
      <div className="flex-1">
        <Input
          placeholder="Type here to search..."
          inputProps={{ value, onChange: handleChange }}
          // error={"test"}
          type={"simple"}
          className="!text-base"
        />
      </div>
    </div>
  );
};
export default SearchInput;
