import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { S3AnonymousAccessSchema } from "../../../../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import Modal from "../../../../modals/Modal";
import Button from "../../../../buttons/Button";
import { selectBucketDetails } from "../../../../../store/s3/buckets/bucketSlice";
import {
  getBucketAnonymousesAsync,
  handleHideAnonymousModal,
  selectBucketAnonymousesActionLoading,
  selectSelectedAnonymouse,
  selectShowBucketAnonymousModal,
  setBucketAnonymousAccessAsync,
} from "../../../../../store/s3/buckets/setting/anonymousSlice";
import Input from "../../../../inputs/Input";
import { selectSelectedObject } from "../../../../../store/s3/buckets/objectSlice";
import Text from "../../../../general/Text";
import { useEffect, useState } from "react";
import Select, { SelectOption } from "../../../../inputs/Select";

const AccessOptions: SelectOption<string>[] = [
  {
    label: "readonly",
    value: "readonly",
  },
  {
    label: "writeonly",
    value: "writeonly",
  },
  {
    label: "readwrite",
    value: "readwrite",
  },
];
const S3AnonymousAccessModal = () => {
  const isOpen = useAppSelector(selectShowBucketAnonymousModal);
  const loading = useAppSelector(selectBucketAnonymousesActionLoading);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const selectedObject = useAppSelector(selectSelectedObject);
  const selectedAnonymouse = useAppSelector(selectSelectedAnonymouse);

  const [selectedAccess, setSelectedAccess] = useState(AccessOptions[0]);
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(S3AnonymousAccessSchema),
  });

  //edit
  useEffect(() => {
    console.log(selectedAnonymouse);
    if (isOpen) {
      if (selectedAnonymouse) {
        setValue("prefix", selectedAnonymouse.prefix || "/");
        setSelectedAccess({
          label: selectedAnonymouse.access,
          value: selectedAnonymouse.access,
        });
      } else {
        setValue("prefix", "");
        setSelectedAccess(AccessOptions[0]);
      }
    }
  }, [isOpen, selectedAnonymouse, setValue]);

  const handleClearComponent = () => {
    reset();
    setSelectedAccess(AccessOptions[0]);
  };

  const onSubmit = (data: any) => {
    if (bucketDetails) {
      dispatch(
        setBucketAnonymousAccessAsync({
          bucketName: bucketDetails.name,
          data: {
            prefix: data.prefix !== undefined ? data.prefix : "/",
            access: selectedAccess.value,
          },
        })
      ).then((action) => {
        if (action.type === "bucket/anonymous-access/set/fulfilled") {
          handleClearComponent();
          dispatch(handleHideAnonymousModal());
          dispatch(getBucketAnonymousesAsync({ name: bucketDetails.name }));
        }
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleHideAnonymousModal());
        handleClearComponent();
      }}
      contentClassName="max-w-lg"
      containerClassName="!items-start"
      title={<Text type="title">Add Anonymous Access Rule</Text>}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col w-full gap-3">
          <Input
            label="Prefix"
            tooltip="Enter '/' to apply the rule to all prefixes and objects at the bucket root. Do not include the wildcard asterisk '*' as part of the prefix *unless* it is an explicit part of the prefix name. The Console automatically appends an asterisk to the appropriate sections of the resulting IAM policy."
            inputProps={{
              ...register("prefix"),
              disabled: !!selectedAnonymouse,
            }}
            error={errors.prefix?.message}
          />
          <Select
            label="Access"
            options={AccessOptions}
            selected={selectedAccess}
            setSelected={(o) => o && setSelectedAccess(o)}
          />
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button
            buttonProps={{
              onClick: (e) => {
                e.preventDefault();
                dispatch(handleHideAnonymousModal());
              },
            }}
            type="light"
            buttonClassName="w-[70px]"
          >
            Cancel
          </Button>
          <Button
            loading={loading}
            type="primary"
            buttonProps={{
              disabled: loading,
              type: "submit",
            }}
            buttonClassName="w-[70px]"
          >
            Set
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default S3AnonymousAccessModal;
