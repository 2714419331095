// import { useAppDispatch } from "../../../hooks";

import Text from "../../general/Text";
import { UniversalPlansBox } from "../../plans/UniversalPlansBox";

const MarketApiPlansTab = () => {
  // const dispatch = useAppDispatch();

  return (
    <>
      {/* <div className="flex transition-all duration-500">
        <Text>Plans</Text>
        <div className="ml-auto flex items-center justify-end gap-1 md:gap-2"></div>
      </div> */}
      <UniversalPlansBox />
    </>
  );
};

export default MarketApiPlansTab;
