import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isAxiosError } from "axios";
import toast from "react-hot-toast";
import { ToastClasses } from "../../../../components/modals/alerts";
import { IThunkRejectValue, RootState } from "../../../../types";
import {
  S3Replication,
  S3ReplicationComplete,
  S3ReplicationState,
  S3UpdateReplication,
} from "../../../../types/s3-replication";
import {
  createBucketReplicationsApi,
  deleteBucketReplicationsApi,
  getBucketReplicatiosApi,
  getOneBucketReplicatiosApi,
  updateBucketReplicationsApi,
} from "../../../../apis/s3/replicationAPI";
import { changeS3ActiveStatus } from "../../s3PublicSlice";
import { getExtractErrors } from "../../../../apis";
import { CustomErrorToast } from "../../../../components/general/Toast";

const initialState: S3ReplicationState = {
  replications: [],
  selectedReplication: null,
  replication: null, //for update
  showReplicationEditor: false,
  replicationLoading: false,
  replicationActionLoading: false,
};

export const getBucketReplicationsAsync = createAsyncThunk<
  { replications: S3Replication[] },
  { name: string },
  IThunkRejectValue
>(
  "bucket/replications",
  async ({ name }, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getBucketReplicatiosApi(name);
      const { Result, NodeStatus } = response.data;

      const replications = Result || [];
      dispatch(changeS3ActiveStatus(NodeStatus === 2));
      return fulfillWithValue({ replications });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getOneBucketReplicationsAsync = createAsyncThunk<
  { replication: S3ReplicationComplete },
  { name: string; id: string },
  IThunkRejectValue
>(
  "bucket/replication",
  async ({ name, id }, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getOneBucketReplicatiosApi(name, id);
      const { Result, NodeStatus } = response.data;

      const replication = Result;
      dispatch(changeS3ActiveStatus(NodeStatus === 2));
      return fulfillWithValue({ replication });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const createBucketReplicationAsync = createAsyncThunk<
  any,
  {
    data: any;
  },
  IThunkRejectValue
>(
  "bucket/replications/create",
  async ({ data }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const res = await createBucketReplicationsApi(data);
      console.log("res", res.data);
      const { Result } = res.data;

      return fulfillWithValue({ replication: Result });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const updateBucketReplicationAsync = createAsyncThunk<
  any,
  { bucketName: string; data: S3UpdateReplication; id: string },
  IThunkRejectValue
>(
  "bucket/replications/update",
  async ({ bucketName, data, id }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await updateBucketReplicationsApi(bucketName, data, id);
      return fulfillWithValue({ replication: result.data });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteBucketReplicationAsync = createAsyncThunk<
  boolean,
  {
    bucketName: string;
    id: string;
  },
  IThunkRejectValue
>(
  "bucket/replication/delete",
  async ({ bucketName, id }, { rejectWithValue }) => {
    try {
      await deleteBucketReplicationsApi(bucketName, id);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const replicationSlice = createSlice({
  name: "bucket-replication",
  initialState,
  reducers: {
    handleShowReplicationEditor: (
      state,
      action: { payload: { replication?: S3Replication } }
    ) => {
      state.showReplicationEditor = true;
      if (typeof action.payload.replication !== "undefined") {
        state.selectedReplication = action.payload.replication;
      }
    },
    handleHideReplicationEditor: (state) => {
      state.showReplicationEditor = false;
      state.replication = null;
      state.selectedReplication = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBucketReplicationsAsync.pending, (state) => {
        state.replicationLoading = true;
      })
      .addCase(getBucketReplicationsAsync.fulfilled, (state, action) => {
        state.replications = action.payload.replications;
        state.replicationLoading = false;
      })
      .addCase(getBucketReplicationsAsync.rejected, (state, { payload }) => {
        state.replicationLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(getOneBucketReplicationsAsync.pending, (state) => {
        state.replicationLoading = true;
      })
      .addCase(getOneBucketReplicationsAsync.fulfilled, (state, action) => {
        state.replication = action.payload.replication;
        state.replicationLoading = false;
      })
      .addCase(getOneBucketReplicationsAsync.rejected, (state, { payload }) => {
        state.replicationLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createBucketReplicationAsync.pending, (state) => {
        state.replicationActionLoading = true;
      })
      .addCase(createBucketReplicationAsync.fulfilled, (state, { payload }) => {
        state.replicationActionLoading = false;
      })
      .addCase(createBucketReplicationAsync.rejected, (state, { payload }) => {
        state.replicationActionLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateBucketReplicationAsync.pending, (state) => {
        state.replicationActionLoading = true;
      })
      .addCase(updateBucketReplicationAsync.fulfilled, (state) => {
        state.replicationActionLoading = false;
      })
      .addCase(updateBucketReplicationAsync.rejected, (state, { payload }) => {
        state.replicationActionLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteBucketReplicationAsync.pending, (state) => {
        state.replicationActionLoading = true;
      })
      .addCase(deleteBucketReplicationAsync.fulfilled, (state) => {
        state.replicationActionLoading = false;
      })
      .addCase(deleteBucketReplicationAsync.rejected, (state, { payload }) => {
        state.replicationActionLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectReplications = (state: RootState) =>
  state.s3Replication.replications;

export const selectReplication = (state: RootState) =>
  state.s3Replication.replication;

export const selectSelectedReplication = (state: RootState) =>
  state.s3Replication.selectedReplication;

export const selectBucketLReplicationsLoading = (state: RootState) =>
  state.s3Replication.replicationLoading;
export const selectBucketReplicationActionLoading = (state: RootState) =>
  state.s3Replication.replicationActionLoading;
export const selectShowBucketReplicationEditor = (state: RootState) =>
  state.s3Replication.showReplicationEditor;

export const { handleShowReplicationEditor, handleHideReplicationEditor } =
  replicationSlice.actions;
export default replicationSlice.reducer;
