import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isAxiosError } from "axios";
import toast from "react-hot-toast";
import { IThunkRejectValue, RootState } from "../../types";
import { ToastClasses } from "../../components/modals/alerts";
import { WebApp, WebAppsState } from "../../types/web-app";
import { deleteWebAppApi, getWebAppsApi } from "../../apis/webAppsAPI";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";

export const dummyWebApps: WebApp[] = [
  {
    Id: 1,
    LastDeployed: "2024-02-28T18:03:39.753584",
    Name: "test-name",
    Region: { title: "Oregon (US West)", value: "oregon" },
    RunTime: "docker",
    Status: 2,
    Type: 1,
    Plan: { id: 1, title: "Free", ram: "512 MB", price: "0", cpu: "0.1" },
    DockerCommand: null,
    PreDeployCommand: null,
    ExternalImage: {
      ImageHost: "docker.io",
      ImageName: "nginx",
      ImageRef: "latest",
      ImageRepository: "library",
      ImageURL: "docker.io/library/nginx:latest",
      OwnerId: "usr-budirqf93em7sijmvtb0",
      RegistryCredentialId: null,
    },
    URL: "https://mysql-ffln.onrender.com",
    HealthCheckPath: null,
  },
];
const initialState: WebAppsState = {
  webApps: [...dummyWebApps],
  loading: false,
  actionLoading: false,
  webApp: null,
};

export const getWebAppsAsync = createAsyncThunk<
  { webApps: WebApp[] },
  undefined,
  IThunkRejectValue
>("web-apps", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getWebAppsApi();
    const webApps = response.data.Result;

    return fulfillWithValue({ webApps });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const deleteWebAppAsync = createAsyncThunk<
  boolean,
  { id: number },
  { rejectValue: { message: string } }
>("web-apps/delete", async ({ id }, { rejectWithValue }) => {
  try {
    await deleteWebAppApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const webAppsSlice = createSlice({
  name: "webApps",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWebAppsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWebAppsAsync.fulfilled, (state, action) => {
        state.webApps = action.payload.webApps;
        state.loading = false;
      })
      .addCase(getWebAppsAsync.rejected, (state, { payload }) => {
        state.loading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteWebAppAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(deleteWebAppAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(deleteWebAppAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectWebApps = (state: RootState) => state.webApps.webApps;

export const selectWebAppsLoading = (state: RootState) => state.webApps.loading;

export const selectWebAppsActionLoading = (state: RootState) =>
  state.webApps.actionLoading;

export const selectWebApp = (state: RootState) => state.webApps.webApp;

// export const {} = webAppsSlice.actions;
export default webAppsSlice.reducer;
