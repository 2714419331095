import Text from "../../../general/Text";
import { ReactComponent as FolderIcon } from "./../../../../assets/icons/folder-open.svg";

const ObjectsCountWidget: React.FunctionComponent<{
  objectCount?: number;
  loading?: boolean;
}> = ({ objectCount, loading }) => {
  return (
    <div className="w-full col-span-2 lg:col-span-1 rounded-lg border dark:border-dark-2 bg-white dark:bg-dark-3 p-5">
      <div className="flex flex-col w-full gap-3">
        <div className="flex justify-between items-center">
          <Text>Objects</Text>
          <FolderIcon className="w-5 dark:text-slate-50" />
        </div>
        <div className="h-12">
          <Text type="title" className="text-5xl !h-12" loading={loading}>
            {objectCount}
          </Text>
        </div>
      </div>
    </div>
  );
};
export default ObjectsCountWidget;
