import Text from "../../general/Text";
import { LoadingSpin } from "../../general/Loading";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useRef } from "react";
import {
  getInstanceAsync,
  getInstanceVncAsync,
  selectInstance,
  selectInstanceVnc,
  selectInstanceVncLoading,
} from "../../../store/instances/instanceSlice";
import { ReactComponent as NoticeIcon } from "./../../../assets/icons/logs/notice.svg";
import Alert from "../../general/Alert";
import { useNavigate, useParams } from "react-router-dom";
import { InstancesUrl } from "../../../utils/urls";

const InstanceVcnConsoleTab = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const instance = useAppSelector(selectInstance);
  const vnc = useAppSelector(selectInstanceVnc);
  const vncLoading = useAppSelector(selectInstanceVncLoading);
  const vncRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getInstanceAsync({ instanceId: id })).then((action) => {
        if (action && action.type === "instance/rejected") {
          navigate(InstancesUrl);
        }
      });
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (instance) {
      dispatch(
        getInstanceVncAsync({
          instanceId: instance.id,
        })
      );
    }
  }, [dispatch, instance]);

  useEffect(() => {
    if (vnc && vncRef && vncRef.current) {
      if (!vncLoading) {
        setTimeout(() => {
          if (vncRef.current)
            vncRef.current.scrollTop = vncRef.current.scrollHeight || 400;
        }, 10);
      }
    }
  }, [vnc, vncLoading]);

  return (
    <div>
      <div className="flex flex-col items-start w-full justify-between gap-2 mb-5">
        <Text className="text-sm">
          Tip: if you're unable to type in the window, click on the blue bar
          below
        </Text>
        <Alert
          type="warning"
          className="text-xs mb-3"
          icon={<NoticeIcon className="w-5 mr-1 aspect-square" />}
        >
          To use the VNC console on the {instance?.name} machine, you must first
          log in via SSH and configure a user with a password
        </Alert>
      </div>

      <div
        className="flex flex-col w-full bg-gray-100 dark:bg-dark-3 relative rounded-tl-xl rounded-tr-xl rounded-xl py-2.5 h-[400px]"
        ref={vncRef}
      >
        <div className="flex-1 flex flex-col  ">
          {/* VNC Viewer */}
          {vnc && !vncLoading && vnc.url && (
            <iframe
              className="h-full w-full"
              src={vnc.url}
              seamless
              title="VNC Viewer"
            ></iframe>
          )}

          {/* Empty VNC message */}
          {!vnc && !vncLoading && (
            <div className="h-full flex items-center justify-center px-4">
              <div className="flex flex-col items-center">
                <div className="text-center space-y-1">
                  <Text className="antialiased font-sans font-medium text-base leading-6">
                    No VNC to show
                  </Text>
                </div>
              </div>
            </div>
          )}

          {/* Loading */}
          {vncLoading && (
            <div className="h-full flex items-center justify-center px-4">
              <LoadingSpin borderSize={2} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InstanceVcnConsoleTab;
