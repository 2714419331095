import {
  PieChart as RePieChart,
  Pie,
  Tooltip,
  LabelList,
  Label,
  Cell,
} from "recharts";
import { GraphChartCustomTooltipProps } from "../../../types/s3";
import Text from "../Text";

export type PieChartProps = {
  data: Array<PieChartData>;
  centerLabel?: string;
};

export interface PieChartData {
  name: string;
  value: number;
  color?: string;
  className?: string;
}

const PieChart: React.FunctionComponent<PieChartProps> = ({
  data,
  centerLabel,
}) => {
  return (
    <RePieChart width={110} height={110}>
      <Pie
        dataKey="value"
        data={data}
        cx="50%"
        cy="50%"
        outerRadius={45}
        innerRadius={36}
        // className="fill-[#8884d8]"
        stroke="0"
      >
        {centerLabel && (
          <Label position="center" className="text-xs" value={centerLabel} />
        )}
        {data.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={data[index].color}
            className={data[index].className}
          />
        ))}
      </Pie>
      <Tooltip content={<PieCustomTooltip />} />
    </RePieChart>
  );
};

const PieCustomTooltip = ({
  active,
  payload,
}: GraphChartCustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white/70 dark:bg-dark-3/80 text-xs p-2 border dark:border-dark-2 rounded-md max-h-40 overflow-y-auto">
        <div className="flex flex-col">
          <Text className="text-center text-base" type="subtext">
            {payload[0].name}: {payload[0].value}%
          </Text>
        </div>
      </div>
    );
  }

  return null;
};
export default PieChart;
