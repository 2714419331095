import { useFieldArray } from "react-hook-form";
import Button from "../../../buttons/Button";
import Input from "../../../inputs/Input";
import Switcher from "../../../inputs/Switcher";
import { ReactComponent as ArchiveIcon } from "./../../../../assets/icons/archive-box.svg";
import { ReactComponent as PlusIcon } from "./../../../../assets/icons/plus.svg";

const S3ExclodeForm = ({
  enabledExclodeFolders,
  setExcludeFoldersStatus,
  control,
  errors,
  register,
}: {
  enabledExclodeFolders: boolean;
  setExcludeFoldersStatus: (status: boolean) => void;
  control: any;
  errors: any;
  register: any;
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "excludePrefixes",
    shouldUnregister: false,
  });
  return (
    <>
      <div className="flex items-center justify-between w-full">
        <div className="text-sm text-slate-700 dark:text-slate-200">
          Exclude Folders
        </div>
        <Switcher
          onToggle={setExcludeFoldersStatus}
          on={enabledExclodeFolders}
        />
      </div>
      <div className="flex flex-col gap-2">
        <div className="text-sm text-slate-700 dark:text-slate-200 whitespace-nowrap">
          Excluded Prefixes
        </div>
        <div className="flex items-center justify-between w-full">
          <div className="w-full dark:bg-dark-1 border dark:border-dark-2 rounded-lg">
            <div className="flex flex-col w-full">
              {fields.map((_, index) => (
                <div key={index} className="grid gap-3 grid-cols-12 pt-2 mx-2">
                  <div className="col-span-11">
                    <Input
                      label=""
                      inputProps={{
                        ...register(`excludePrefixes.${index}.prefix` as const),
                      }}
                      error={
                        errors.excludePrefixes &&
                        errors.excludePrefixes[index] &&
                        errors.excludePrefixes[index]?.prefix &&
                        errors.excludePrefixes[index]?.prefix?.message
                      }
                    />
                  </div>
                  <div className="col-span-1 flex items-start justify-center">
                    <Button
                      type="icon"
                      buttonProps={{
                        onClick: (e) => {
                          e.preventDefault();
                          remove(index);
                        },
                        disabled: false,
                      }}
                      buttonClassName="mt-1.5"
                    >
                      <ArchiveIcon className="w-5 text-slate-700 dark:text-slate-300 hover:text-red-500 hover:dark:text-red-400" />
                    </Button>
                  </div>
                </div>
              ))}
              <div className="flex justify-end m-2">
                <Button
                  type="light"
                  buttonProps={{
                    onClick: (e) => {
                      e.preventDefault();
                      append(
                        {
                          prefix: "",
                        },
                        { shouldFocus: true }
                      );
                    },
                  }}
                >
                  <PlusIcon className="w-4" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default S3ExclodeForm;
