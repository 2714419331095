import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./../../../hooks";

import {
  deleteWebAppDiskAsync,
  getWebAppDiskAsync,
  saveWebAppDiskAsync,
  selectWebApp,
  selectWebAppDisk,
  selectWebAppDiskActionLoading,
  selectWebAppDiskLoading,
  selectWebAppLoading,
} from "../../../store/web-app/webAppSlice";
import { useForm } from "react-hook-form";
import Input from "../../inputs/Input";
import Button from "../../buttons/Button";
import { AnimatePresence, motion } from "framer-motion";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as PlusIcon } from "./../../../../assets/icons/plus.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { WebAppDiskSchema } from "../../../utils/validations";
import Card from "../../general/Card";
import Loading from "../../general/Loading";
import Text, { A } from "../../general/Text";
import QuestionModal from "../../modals/QuestionModal";
import { deleteWebAppDiskApi } from "../../../apis/webAppsAPI";

const WebAppDisksTab = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);

  const [hasDisk, setHasDisk] = useState<boolean>(false);
  const [editable, setEditable] = useState<boolean>(false);
  const [deleteQuestion, setDeleteQuestion] = useState<boolean>(false);

  const disk = useAppSelector(selectWebAppDisk);
  const diskLoading = useAppSelector(selectWebAppDiskLoading);
  const diskActionLoading = useAppSelector(selectWebAppDiskActionLoading);

  const dispatch = useAppDispatch();

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(WebAppDiskSchema),
  });

  useEffect(() => {
    if (webApp) {
      dispatch(getWebAppDiskAsync({ webAppId: webApp.Id.toString() }));
    }
  }, [dispatch, webApp]);

  useEffect(() => {
    setHasDisk(!!disk);
    if (disk) {
      setValue("DiskName", disk.DiskName);
      setValue("DiskMountPath", disk.DiskMountPath);
      setValue("DiskSize", disk.DiskSize);
    } else {
      reset({ DiskSize: 10 });
    }
  }, [dispatch, disk, reset, setValue]);

  const handleSubmitForm = (data: any) => {
    if (webApp)
      dispatch(
        saveWebAppDiskAsync({
          webAppId: webApp.Id.toString(),
          data,
        })
      ).then((action) => {
        if (action.type === "web-app/disk/save/fulfilled") {
          setEditable(false);
          dispatch(getWebAppDiskAsync({ webAppId: webApp.Id.toString() }));
        }
      });
  };

  return (
    <div className="flex flex-col gap-6">
      {diskLoading && <Loading borderSize={2} className="min-h-[200px]" />}
      {!diskLoading && !hasDisk && (
        <div className="antialiased text-center font-sans font-normal text-sm leading-6 space-y-4 max-w-lg mx-auto mt-5">
          <Text className="">
            Create a disk to store application data that needs to persist across
            deploys. You can mount it on any absolute path and read and write to
            it using standard filesystem primitives. Disks are charged at
            $0.25/GB per month.
          </Text>
          <Text>
            Adding a disk prevents zero downtime deploys. <A>Learn more.</A>
          </Text>
          <Button
            buttonProps={{
              onClick: () => {
                setHasDisk(true);
                setEditable(true);
              },
            }}
          >
            Add Disk
          </Button>
        </div>
      )}

      {!diskLoading && hasDisk && (
        <Card>
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-5 md:gap-10 mt-5">
                <div className="flex flex-col w-full md:flex-row gap-0.5 md:gap-x-5">
                  <div className="antialiased font-sans font-normal text-sm leading-6 md:w-1/2">
                    <Text className="">Name</Text>
                    <Text type="subtext" className="text-xs">
                      An identifying name for this disk. Used only for display.
                    </Text>
                  </div>
                  <div className="flex-1">
                    <Input
                      inputProps={{
                        ...register("DiskName"),
                        readOnly: !editable,
                      }}
                      error={errors.DiskName?.message}
                    />
                  </div>
                </div>
                <div className="flex flex-col w-full md:flex-row gap-0.5 md:gap-x-5">
                  <div className="antialiased font-sans font-normal text-sm leading-6 md:w-1/2">
                    <Text className="">Mount Path</Text>
                    <Text type="subtext" className="text-xs">
                      The absolute path to mount this disk, like /var/lib/data.
                      Can not be the root (/) directory.
                    </Text>
                  </div>
                  <div className="flex-1">
                    <Input
                      inputProps={{
                        ...register("DiskMountPath"),
                        readOnly: !editable,
                      }}
                      error={errors.DiskMountPath?.message}
                    />
                  </div>
                </div>
                <div className="flex flex-col w-full md:flex-row gap-0.5 md:gap-x-5">
                  <div className="antialiased font-sans font-normal text-sm leading-6 md:w-1/2">
                    <Text className="">Size (GB)</Text>
                    <Text type="subtext" className="text-xs">
                      You can increase the size later, but you can't decrease
                      it. Pick the lowest value that works for your app.
                    </Text>
                  </div>
                  <div className="flex-1">
                    <Input
                      inputProps={{
                        type: "number",
                        ...register("DiskSize"),
                        readOnly: !editable,
                      }}
                      error={errors.DiskSize?.message}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-end">
                {disk && !editable && (
                  <div className="flex gap-2 items-center">
                    <Button
                      type="danger"
                      buttonProps={{
                        onClick: (e) => {
                          e.preventDefault();
                          setDeleteQuestion(true);
                        },
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      type="light"
                      buttonProps={{
                        onClick: (e) => {
                          e.preventDefault();
                          setEditable(true);
                        },
                      }}
                    >
                      Edit
                    </Button>
                  </div>
                )}
                {(!disk || editable) && (
                  <div className="flex gap-2 items-center">
                    <Button
                      type="danger-light"
                      buttonProps={{
                        onClick: (e) => {
                          e.preventDefault();
                          setEditable(false);
                          if (disk) {
                            setHasDisk(true);
                            reset({
                              DiskName: disk.DiskName,
                              DiskMountPath: disk.DiskMountPath,
                              DiskSize: disk.DiskSize,
                            });
                          } else {
                            setHasDisk(false);
                            reset({ DiskSize: 10 });
                          }
                        },
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      buttonProps={{
                        disabled: !isValid,
                        type: "submit",
                      }}
                      loading={diskActionLoading}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </form>
        </Card>
      )}
      <QuestionModal
        isOpen={deleteQuestion}
        title="Delete disk"
        onClose={() => {
          setDeleteQuestion(false);
        }}
        onConfirm={() =>
          webApp &&
          dispatch(
            deleteWebAppDiskAsync({ webAppId: webApp.Id.toString() })
          ).then((action) => {
            if (action.type === "web-app/disk/dalete/fulfilled") {
              reset({ DiskSize: 10 });
              setHasDisk(false);
              setEditable(false);
            }
          })
        }
        confirmButtonType="danger"
        loading={diskActionLoading}
        description={
          <div className="text-sm flex flex-col gap-2 my-4">
            <Text>
              All data for test-disk will be deleted immediately. This action
              cannot be undone.
            </Text>
            <Text>Are you sure you want to delete this disk?</Text>
          </div>
        }
      />
    </div>
  );
};

export default WebAppDisksTab;
