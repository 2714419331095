import { PropsWithChildren, useState } from "react";
import Button, { ButtonTypes } from "../buttons/Button";
import Modal from "./Modal";
import Text from "../general/Text";
import Input from "../inputs/Input";

export type QuestionModalType = {
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  loading?: boolean;
  onConfirm?: () => void;
  confirmButtonType?: ButtonTypes;
  confirmButtonText?: string;
  contentClassName?: string;
  confirmButtonClassName?: string;
};

export type DeleteModalType = QuestionModalType & {
  confirmString?: string;
  error?: string;
};

const QuestionModal = (props: PropsWithChildren<QuestionModalType>) => {
  const isOpen = props.isOpen;

  const handleConfirm = () => {
    if (props.onConfirm) {
      props.onConfirm();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title={props.title || "Are you sure delete this item?"}
      onClose={() => (props.onClose ? props.onClose() : {})}
      contentClassName={props.contentClassName || "!max-w-sm"}
    >
      <div className="mt-5 dark:text-slate-100 text-sm">
        {props.description ||
          props.children ||
          "You won't be able to revert this!"}
      </div>

      <div className="mt-4 flex justify-end gap-2">
        <Button
          type="light"
          buttonProps={{
            onClick: () => (props.onClose ? props.onClose() : {}),
          }}
        >
          Cancel
        </Button>
        <Button
          contentClassName={props.confirmButtonClassName || "w-10"}
          loading={props.loading || false}
          type={props.confirmButtonType || "primary"}
          buttonProps={{
            onClick: handleConfirm,
            disabled: props.loading || false,
          }}
        >
          {props.confirmButtonText || "Yes!"}
        </Button>
      </div>
    </Modal>
  );
};
export default QuestionModal;

const DeleteModal = (props: PropsWithChildren<DeleteModalType>) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setError(null);
  };

  const handleConfirm = () => {
    if (props.confirmString) {
      // Trim the input value only when validating
      if (inputValue === props.confirmString) {
        if (props.onConfirm) {
          props.onConfirm();
          setInputValue("");
        }
      } else {
        setError(`Please type exactly "${props.confirmString}"`);
      }
    } else {
      if (props.onConfirm) {
        props.onConfirm();
        setInputValue("");
      }
    }
  };

  const handleClose = () => {
    setInputValue("");
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      title={props.title || "Delete item"}
      onClose={handleClose}
      contentClassName={props.contentClassName || "!max-w-sm"}
    >
      <div className="space-y-2">
        {props.confirmString ? (
          <>
            <Text className="text-xs mt-5">
              To confirm, type{" "}
              <span className="font-semibold">"{props.confirmString}"</span> in
              the box below
            </Text>
            <Input
              inputProps={{
                value: inputValue,
                onChange: handleInputChange,
                placeholder: `Type "${props.confirmString}" to confirm`,
              }}
            />
            {error && (
              <Text className="text-red-500 text-xs mt-2">{error}</Text>
            )}
          </>
        ) : (
          <div className="py-5">
            <Text className="sm">You won't be able to revert this!</Text>
          </div>
        )}
      </div>

      <div className="mt-4 flex justify-end gap-2">
        <Button
          type="light"
          buttonProps={{
            onClick: handleClose,
          }}
        >
          Cancel
        </Button>
        <Button
          contentClassName={props.confirmButtonClassName || "w-10"}
          loading={props.loading || false}
          type={props.confirmButtonType || "primary"}
          buttonProps={{
            onClick: handleConfirm,
            disabled:
              props.loading ||
              (props.confirmString
                ? inputValue !== props.confirmString
                : false),
          }}
        >
          {props.confirmButtonText || "Confirm"}
        </Button>
      </div>
    </Modal>
  );
};

export { DeleteModal };
