import { Handle, Position, NodeProps } from "reactflow";

import classNames from "classnames";
import { PropsWithChildren, forwardRef } from "react";
import { ReactComponent as CheckIcon } from "./../../../../assets/icons/check.svg";
import { ReactComponent as ExclamationIcon } from "./../../../../assets/icons/exclamation-circle.svg";
import Text from "../../../general/Text";
import { getNodeIconUrl } from "../../../../utils/wfHelper";
import { useAppSelector } from "../../../../hooks";
import { selectWorkflowDirection } from "../../../../store/workflows/workflowsSlice";
const handlerPosition = [["50%"], ["30%", "70%"]];
interface ICanvasNode {
  name: string;
  label: string;
  icon: string;
  selected: boolean;
  outputResponses?: { submit: boolean; needRetest: boolean };
  inputAnchors: Array<{ id: string }>;
  outputAnchors: Array<{ id: string }>;
}
const CanvasNode = ({ data }: NodeProps<ICanvasNode>) => {
  const isHorizontal = useAppSelector(selectWorkflowDirection) === "ttb";

  return (
    <WorkflowNodeCard selected={data.selected}>
      {data && data.outputResponses && data.outputResponses.submit && (
        <CheckIcon className="rounded-full bg-success text-white absolute -top-2 -right-2 w-4 h-4" />
      )}
      {data && data.outputResponses && data.outputResponses.needRetest && (
        <ExclamationIcon className="rounded-full text-error absolute -top-2 -right-2 w-4 h-4" />
      )}

      <div>
        {data.inputAnchors.map((inputAnchor, index) => (
          <Handle
            type="target"
            position={isHorizontal ? Position.Top : Position.Left}
            key={inputAnchor.id}
            id={inputAnchor.id}
            className={classNames("!w-3 !h-3 !border-0", {
              "!bg-primary-400": data.selected,
              "!bg-secondary-300 dark:!bg-gray-500": !data.selected,
            })}
            style={{
              height: 15,
              width: 15,
              top: isHorizontal ? -7.5 : undefined,
              left: isHorizontal
                ? handlerPosition[data.inputAnchors.length - 1][index]
                : undefined,
              bottom: !isHorizontal
                ? handlerPosition[data.inputAnchors.length - 1][index]
                : undefined,
            }}
          />
        ))}

        <div className="flex items-center">
          <div className="w-8 aspect-square mr-1 rounded-full bg-white dark:bg-white/10 cursor-grab">
            {/* {image === "" && (
              <div className="w-full h-full bg-slate-200 animate-pulse rounded-full" />
            )} */}
            {/* {image !== "" && ( */}
            <img
              className="w-full h-full p-1 object-contain"
              alt={data.name}
              src={getNodeIconUrl(data.icon)}
            />
            {/* )} */}
          </div>
          <Text className="text-sm">{data.label}</Text>
        </div>

        {data.outputAnchors.map((outputAnchor, index) => (
          <Handle
            type="source"
            position={isHorizontal ? Position.Bottom : Position.Right}
            key={outputAnchor.id}
            id={outputAnchor.id}
            className={classNames("!w-3 !h-3 !border-0", {
              "!bg-primary-400": data.selected,
              "!bg-secondary-300 dark:!bg-gray-500": !data.selected,
            })}
            style={{
              bottom: isHorizontal ? -7.5 : undefined,
              left: isHorizontal
                ? handlerPosition[data.outputAnchors.length - 1][index]
                : undefined,
              top: !isHorizontal
                ? handlerPosition[data.outputAnchors.length - 1][index]
                : undefined,
            }}
          />
        ))}
      </div>
    </WorkflowNodeCard>
  );
};

const WorkflowNodeCard = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{ selected: boolean }>
>(function WorkflowNodeCard({ selected, children }, ref) {
  return (
    <div
      ref={ref}
      className={classNames(
        "w-[200px] h-auto p-2 border border-secondary-300 dark:border-gray-500 shadow-[0 2px 14px 0 rgb(32 40 45 / 8%)] rounded-lg bg-white dark:bg-dark-1",
        { "!border-primary-400": selected }
      )}
    >
      {children}
    </div>
  );
});
export default CanvasNode;
