import Modal from "../../modals/Modal";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  handleHideNamespaceEditorModal,
  selectShowNamespaceEditor,
} from "../../../store/database/serviceSlice";
import DBSNamespaceCreateTab from "./DBSNamespaceCreateTab";

const DBSNamespaceEditorModal = () => {
  const isOpen = useAppSelector(selectShowNamespaceEditor);
  const dispatch = useAppDispatch();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleHideNamespaceEditorModal());
      }}
      contentClassName="max-w-4xl"
      containerClassName="!items-start"
      title={"Update Namespace"}
    >
      <DBSNamespaceCreateTab />
    </Modal>
  );
};

export default DBSNamespaceEditorModal;
