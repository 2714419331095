import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Text from "../../general/Text";
import { WebAppDeployOption, WebAppDeployTypes } from "../../../types/web-app";
import {
  handleChangeWebAppDeployType,
  selectWebAppSelectedDeployType,
} from "../../../store/web-app/webAppCreateSlice";
import { useCallback } from "react";

const deployTypes: WebAppDeployOption[] = [
  {
    name: "git",
    title: "Build and deploy from a Git repository",
    description: "Connect a GitHub or GitLab repository.",
  },
  {
    name: "image",
    title: "Deploy an existing image from a registry",
    description:
      "Pull a public image from any registry or a private image from Docker Hub, GitHub, or GitLab.",
  },
];

const SelectDeployingTypeStep = () => {
  const selectedDeployType = useAppSelector(selectWebAppSelectedDeployType);

  const dispatch = useAppDispatch();

  const handleChangeDeployType = useCallback(
    (deployType: WebAppDeployTypes) => {
      dispatch(handleChangeWebAppDeployType(deployType));
    },
    [dispatch]
  );

  return (
    <div className="max-w-3xl mx-auto pb-12 flex flex-col">
      <Text className="font-medium">
        How would you like to deploy your web service?
      </Text>
      <div className="mt-4 w-full pb-12 flex flex-col gap-4">
        {deployTypes.map((deployType, index) => (
          <DeployTypeCard
            key={index}
            {...deployType}
            selected={selectedDeployType === deployType.name}
            onClick={handleChangeDeployType}
          />
        ))}
      </div>
    </div>
  );
};

export const DeployTypeCard: React.FC<
  WebAppDeployOption & {
    selected?: boolean;
    onClick?: (deplotType: WebAppDeployTypes) => void;
  }
> = ({ name, title, description, selected, onClick }) => {
  return (
    <div
      className={classNames(
        "col-span-2 md:col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300",
        {
          "border-primary-400 dark:border-primary-400": selected,
          "cursor-pointer": onClick,
        }
      )}
      onClick={() => (onClick ? onClick(name) : {})}
    >
      <div className="flex flex-col space-y-3 p-3 items-start justify-start">
        <Text className="text-sm font-medium">{title}</Text>
        <Text className="text-sm" type="subtext">
          {description}
        </Text>
      </div>
    </div>
  );
};
export default SelectDeployingTypeStep;
