import { Switch } from "@headlessui/react";
import classNames from "classnames";
import { useEffect, useState } from "react";
import Loading from "../general/Loading";

const Switcher = ({
  on,
  onToggle,
  disabled,
  loading,
}: {
  on?: boolean;
  onToggle?: (value: boolean) => void;
  disabled?: boolean;
  loading?: boolean;
}) => {
  const [enabled, setEnabled] = useState<boolean>(on || false);

  useEffect(() => {
    setEnabled(on || false);
  }, [on]);

  const handleChange = (v: boolean) => {
    if (!disabled && !loading) {
      // setEnabled(v);
      onToggle && onToggle(v);
    }
  };
  return (
    <Switch
      checked={enabled}
      onChange={handleChange}
      className={classNames(
        "h-[24px] w-[46px]",
        "relative inline-flex shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75",
        {
          "bg-primary-400 dark:bg-primary-500": enabled && !disabled,
          "bg-primary-200 dark:bg-primary-200/70": enabled && disabled,
          "bg-gray-200 dark:bg-gray-600": !enabled,
          "!cursor-not-allowed": disabled,
        }
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
          "h-[20px] w-[20px]",
          "pointer-events-none inline-block  transform rounded-full bg-white dark:bg-dark-2 shadow-lg ring-0 transition duration-200 ease-in-out",
          {
            "translate-x-[22px]": enabled,
            "translate-x-0": !enabled,
          }
        )}
      >
        {loading && <Loading size={13} borderSize={2} />}
      </span>
    </Switch>
  );
};
export default Switcher;
