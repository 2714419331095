import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getBillingInformationAsync,
  handleShowBillingInformationModal,
  selectBillingInformation,
  selectBillingInformationActionLoading,
  selectBillingInformationLoading,
  selectShowBillingInformationModal,
  updateBillingInformationAsync,
} from "../../../store/billing/billingSlice";
import Button from "../../buttons/Button";
import Input from "../../inputs/Input";
import Modal from "../../modals/Modal";
import { yupResolver } from "@hookform/resolvers/yup";
import { BillingInformationSchema } from "../../../utils/validations";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  getCountrieAsync,
  selectCountries,
  selectCountriesLoading,
} from "../../../store/publicSlice";
import { Select2, SelectOption } from "../../inputs/Select";
import Text from "../../general/Text";
import { getCountryFlag } from "../../../utils";
import axios from "axios";

const BillingInformationModal = () => {
  const isOpen = useAppSelector(selectShowBillingInformationModal);
  const dispatch = useAppDispatch();

  const billingInformation = useAppSelector(selectBillingInformation);
  const billingInformationLoading = useAppSelector(
    selectBillingInformationLoading
  );
  const billingInformationActionLoading = useAppSelector(
    selectBillingInformationActionLoading
  );

  const countries = useAppSelector(selectCountries);
  const countriesLoading = useAppSelector(selectCountriesLoading);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    control,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(BillingInformationSchema()),
  });

  const selectedCountryId = watch("CountryId");
  // const selectedState = watch("State");

  useEffect(() => {
    if (billingInformation && isOpen) {
      setValue("CountryId", billingInformation.CountryId);
      setValue("State", billingInformation.State);
      setValue("City", billingInformation.City);
      setValue("ZipCode", billingInformation.ZipCode);
      setValue("AddressLine1", billingInformation.AddressLine1);
      setValue("AddressLine2", billingInformation.AddressLine2);
      setValue("VatNumber", billingInformation.VATNumber);
    }
  }, [billingInformation, isOpen, setValue]);

  useEffect(() => {
    if (countries.length === 0 && isOpen) {
      dispatch(getCountrieAsync());
    }
  }, [countries.length, dispatch, isOpen]);

  const handleSubmitForm = (data: any) => {
    dispatch(updateBillingInformationAsync({ data })).then((action) => {
      if (action.type === "billing/update-informations/fulfilled") {
        dispatch(getBillingInformationAsync());
        dispatch(handleShowBillingInformationModal(false));
      }
    });
  };

  const handleClose = useCallback(() => {
    dispatch(handleShowBillingInformationModal(false));
    reset();
  }, [dispatch, reset]);

  //------------------- universal section -------------------
  //https://www.universal-tutorial.com/rest-apis/free-rest-api-for-country-state-city

  const [universalLoading, setuniversalLoading] = useState(false);
  const [authToken, setAuthToken] = useState<string>();

  const [statesLoading, setStatesLoading] = useState(false);
  const [states, setstates] = useState<Array<{ state_name: string }>>([]);

  // const [citiesLoading, setCitiesLoading] = useState(false);
  // const [cities, setCities] = useState<Array<{ city_name: string }>>([]);

  const getApiToken = useCallback(async () => {
    try {
      setuniversalLoading(true);
      const res = await axios.get(
        `https://www.universal-tutorial.com/api/getaccesstoken`,
        {
          headers: {
            Accept: "application/json",
            "api-token":
              "VNAza51kTGYthfaihvXw9BTaxfEugvInf1IQWzQNP6uW_B1QCFQL9s8Bk0-0GIqWElU",
            "user-email": "ansar@djuno.io",
          },
        }
      );
      setuniversalLoading(false);
      setAuthToken(res.data.auth_token);
    } catch (e) {
      setuniversalLoading(false);
      handleClose();
    }
  }, [handleClose]);

  //get auth-token
  useEffect(() => {
    if (isOpen) {
      getApiToken();
    }
  }, [getApiToken, isOpen]);

  const selectedCountry = useMemo(() => {
    return countries.find((c) => c.Id === Number(selectedCountryId));
  }, [countries, selectedCountryId]);

  //get states of country
  useEffect(() => {
    const getStates = async (name: string) => {
      try {
        setStatesLoading(true);
        const res = await axios.get(
          `https://www.universal-tutorial.com/api/states/${name}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              Accept: "application/json",
            },
          }
        );
        const states = res.data as Array<{ state_name: string }>;
        setStatesLoading(false);
        setstates(states);
        if (billingInformation) {
          setValue("State", billingInformation.State);
        } else {
          setValue("State", states[0].state_name);
        }
      } catch (e) {
        setStatesLoading(false);
        setstates([]);
      }
    };

    if (selectedCountry && authToken) {
      getStates(selectedCountry.Name);
    }
  }, [authToken, selectedCountry, setValue]);

  //get cities of state
  // useEffect(() => {
  //   const getCities = async (name: string) => {
  //     try {
  //       setCitiesLoading(true);
  //       const res = await axios.get(
  //         `https://www.universal-tutorial.com/api/cities/${name}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${authToken}`,
  //             Accept: "application/json",
  //           },
  //         }
  //       );

  //       const cities = res.data as Array<{ city_name: string }>;
  //       setCitiesLoading(false);
  //       setCities(cities);
  //       setValue("City", cities[0].city_name);
  //     } catch (e) {
  //       setCitiesLoading(false);
  //       setCities([]);
  //     }
  //   };

  //   if (selectedState && authToken) {
  //     getCities(`Appenzell-Ausser_Rhoden`);
  //   }
  // }, [authToken, selectedState, setValue]);

  //------------------- end universal section -------------------

  const countryOprions = useMemo(() => {
    if (countries.length !== 0) {
      const options: SelectOption[] = countries.map((country) => ({
        label: (
          <div className="flex items-center gap-1">
            <img
              alt={country.Name}
              src={getCountryFlag(country.Iso3)}
              className="w-4 h-4"
            />
            <Text className="text-sm">{country.Name}</Text>
          </div>
        ),
        value: country.Id,
        extraData: { iso3: country.Iso3 },
      }));
      return options;
    } else {
      return [];
    }
  }, [countries]);

  return (
    <Modal
      isOpen={isOpen}
      title={"Update billing information"}
      contentClassName="max-w-lg"
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="col-span-2 mt-5">
          <Input
            label="Address 1"
            inputProps={{ ...register("AddressLine1") }}
            error={errors.AddressLine1?.message}
          />
        </div>
        <div className="col-span-2 mt-5">
          <Input
            label="Address 2"
            inputProps={{ ...register("AddressLine2") }}
            error={errors.AddressLine2?.message}
          />
        </div>
        <div className="grid grid-cols-2 gap-5 mt-5">
          <div className="col-span-1">
            <Controller
              name="CountryId"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select2
                  label="Country"
                  options={countryOprions}
                  value={value}
                  onChange={onChange}
                  loading={countriesLoading}
                  emptyString="Select a country"
                  error={errors.CountryId?.message}
                />
              )}
            />
          </div>
          <div className="col-span-1">
            {/* <Input
              label="State"
              inputProps={{ ...register("State") }}
              error={errors.State?.message}
            /> */}

            <Controller
              name="State"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select2
                  label="State"
                  options={states.map((s) => ({
                    label: s.state_name,
                    value: s.state_name,
                  }))}
                  value={value}
                  onChange={onChange}
                  loading={countriesLoading || statesLoading}
                  emptyString="Select an state"
                  error={errors.State?.message}
                />
              )}
            />
          </div>
          <div className="col-span-1">
            <Input
              label="City"
              inputProps={{ ...register("City") }}
              error={errors.City?.message}
            />
            {/* <Controller
              name="City"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select2
                  label="City"
                  options={cities.map((s) => ({
                    label: s.city_name,
                    value: s.city_name,
                  }))}
                  value={value}
                  onChange={onChange}
                  loading={statesLoading || citiesLoading}
                  emptyString="Select a city"
                  error={errors.City?.message}
                />
              )}
            /> */}
          </div>
          <div className="col-span-1">
            <Input
              label="Zipcode"
              inputProps={{ ...register("ZipCode") }}
              error={errors.ZipCode?.message}
            />
          </div>
          <div className="col-span-1">
            <Input
              label="Vat Number"
              inputProps={{ ...register("VatNumber") }}
              error={errors.ZipCode?.message}
            />
          </div>
        </div>
        <div className="flex justify-end mt-5">
          <Button
            type="primary"
            buttonProps={{ type: "submit" }}
            loading={
              billingInformationLoading ||
              billingInformationActionLoading ||
              universalLoading
            }
          >
            Update
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default BillingInformationModal;
