import { useAppSelector } from "../../../hooks";
import { IpfsNotActiveTab } from "../../layouts/NotData";
import { Web3StoragePlansBox } from "../../plans/Web3StoragePlansBox";
import { selectIpfsStatus } from "../../../store/ipfs/ipfsPublicSlice";

const IpfsPlansTab = () => {
  const ipfsStatus = useAppSelector(selectIpfsStatus);

  if (!ipfsStatus) {
    return <IpfsNotActiveTab />;
  }
  return (
    <>
      <Web3StoragePlansBox />
    </>
  );
};

export default IpfsPlansTab;
