import { useNavigate } from "react-router-dom";
import { S3TierAddFormUrl } from "../../../utils/urls";
import Text from "../../general/Text";
import { DestinationCard } from "../events/S3SelectDestination";
import { TierTypeOption } from "../../../types/s3-tiers";
import { S3NotActiveTab } from "../../layouts/NotData";
import { useAppSelector } from "../../../hooks";
import { selectS3Status } from "../../../store/s3/s3PublicSlice";

export const s3TierTypes: {
  type: string;
  options: Array<TierTypeOption>;
}[] = [
  {
    type: "",
    options: [
      {
        key: "minio",
        name: "MinIO",
        iconUrl: "https://cdn.djuno.cloud/tiers/minio.svg",
      },
      {
        key: "gcs",
        name: "Google Cloud Storage",
        iconUrl: "https://cdn.djuno.cloud/tiers/gcp.svg",
      },
      {
        key: "s3",
        name: "Amazon S3",
        iconUrl: "https://cdn.djuno.cloud/tiers/awss3.svg",
      },
      {
        key: "azure",
        name: "Azure",
        iconUrl: "https://cdn.djuno.cloud/tiers/azure.svg",
      },
    ],
  },
];

const S3SelectTierType = () => {
  const s3Status = useAppSelector(selectS3Status);
  const navigate = useNavigate();

  if (!s3Status) {
    return <S3NotActiveTab />;
  }
  return (
    <div className="pb-20">
      <div className="grid grid-cols-5 p-6">
        <div className="col-span-3 flex flex-col gap-3">
          <Text type="title" className="text-md">
            Select Tier Type
          </Text>
          {s3TierTypes.map((tierType, i) => (
            <div key={i} className="flex flex-col">
              <Text type="title" className="text-md mb-2">
                {tierType.type}
              </Text>
              <div className="grid grid-cols-2 gap-3">
                {tierType.options.map((option, j) => (
                  <DestinationCard
                    key={j}
                    name={option.name}
                    iconUrl={option.iconUrl}
                    onClick={() => navigate(S3TierAddFormUrl(option.key))}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default S3SelectTierType;
