import NotData from "./../../../components/layouts/NotData";
import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "./../../../hooks";
import { useEffect, useState } from "react";
import Button from "./../../../components/buttons/Button";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as DetailsIcon } from "./../../../assets/icons/bars-3-bottom-left.svg";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil-square.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import { ReactComponent as MongoIcon } from "./../../../assets/icons/db/mongoDB.svg";
import { ReactComponent as CircleStackIcon } from "./../../../assets/icons/circle-stack.svg";
import { ReactComponent as MongoTypeIcon } from "./../../../assets/icons/db/mongodb-logo.svg";
import { ReactComponent as MySQLIcon } from "./../../../assets/icons/db/mysql-logo.svg";
import { ReactComponent as PostgreIcon } from "./../../../assets/icons/db/postgresql.svg";
import { ReactComponent as RedisIcon } from "./../../../assets/icons/db/redis-logo.svg";
import { ReactComponent as CassandraIcon } from "./../../../assets/icons/db/cassandra-logo.svg";

import { useNavigate } from "react-router-dom";
import {
  DatabaseServiceCreateUrl,
  DatabaseServiceGeneralInformationUrl,
} from "./../../../utils/urls";
import Dropdown from "./../../../components/general/Dropdown";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "./../../../components/general/Table";
import QuestionModal, {
  DeleteModal,
} from "./../../../components/modals/QuestionModal";
import Text from "./../../../components/general/Text";
import { useSearch } from "./../../../providers/SearchProvider";
import Highlighter from "react-highlight-words";
import {
  deleteDBSAsync,
  getDatabaseServicesAsync,
  handleShowDatabaseRenameModal,
  selectDatabaseServices,
  selectDatabaseServicesActionLoading,
  selectDatabaseServicesLoading,
} from "./../../../store/database/servicesSlice";
import { DBSEngineType, DatabaseService } from "./../../../types/database";
import { formatTimestamp } from "./../../../utils/date";
import classNames from "classnames";
import { capitalizeFirstLetter } from "./../../../utils";
import DatabaseRenameModal from "./DatabaseRenameModal";
import { selectDatabaseServiceRenameIsOpen } from "../../../store/instances/instancesSlice";

const DatabaseServicesTab = () => {
  const [deleteService, setDeleteService] = useState<DatabaseService | null>(
    null
  );

  const services = useAppSelector(selectDatabaseServices);
  const [filteredServices, setFilteredServices] = useState<DatabaseService[]>(
    []
  );
  const loading = useAppSelector(selectDatabaseServicesLoading);
  const actionLoading = useAppSelector(selectDatabaseServicesActionLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    dispatch(getDatabaseServicesAsync());
    // dispatch(getDBSCapabilitiesAsync());
    // dispatch(getDBSAvailabilityAsync());
  }, [dispatch]);

  useEffect(() => {
    if (services.length > 0) {
      const lookedUpFiles = services.filter((service) =>
        service.description.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredServices(lookedUpFiles);
    }
  }, [dispatch, searchValue, services]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Database Services</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="">
        <div className="flex items-center justify-between">
          <div className="items-center justify-between flex flex-1 transition duration-150">
            <Text className="font-medium text-lg"></Text>
          </div>
          <div className="flex items-center gap-2">
            <Button
              type="light"
              buttonProps={{
                onClick: () => dispatch(getDatabaseServicesAsync()),
              }}
              buttonClassName="group"
              tooltip="Refresh"
            >
              <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>
            <Button
              type="primary"
              buttonProps={{
                onClick: () => navigate(DatabaseServiceCreateUrl),
              }}
              buttonClassName="group"
            >
              Create a Service
              <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>
          </div>
        </div>

        <div className="mt-5 w-full">
          <Table loading={loading}>
            <TableHead>
              <TableRow>
                <TableTH lable="Name" />
                <TableTH lable="Engine" />
                <TableTH lable="Solution" />
                <TableTH lable="Node template" />
                <TableTH lable="Usable storage" />
                <TableTH lable="Location" />
                <TableTH lable="Nodes" />
                <TableTH lable="Creation date" />
                <TableTH lable="Status" />
                <TableTH lable="" />
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredServices.map((service, i) => (
                <TableRow
                  key={i}
                  onClick={() =>
                    navigate(DatabaseServiceGeneralInformationUrl(service.id))
                  }
                >
                  <TableTD className="cursor-pointer">
                    <div className="flex items-center flex-row justify-between space-x-3">
                      <Highlighter
                        highlightClassName="bg-primary-100 dark:bg-primary-300 rounded-sm"
                        className="whitespace-nowrap max-w-[240px] text-ellipsis overflow-hidden text-sm font-medium"
                        searchWords={[searchValue]}
                        autoEscape={true}
                        textToHighlight={service.description}
                      />
                    </div>
                  </TableTD>
                  <TableTD className="cursor-pointer">
                    <Text
                      className="text-xs md:text-sm flex items-center whitespace-nowrap gap-0.5"
                      // type="neutral-alert"
                    >
                      {getDBserviceIcon(service.engine)}
                      {`${service.engine} ${service.version}`}
                    </Text>
                  </TableTD>

                  <TableTD className="cursor-pointer">
                    <Text className="text-xs md:text-sm">{service.plan}</Text>
                  </TableTD>

                  <TableTD className="cursor-pointer">
                    <Text className="text-xs md:text-sm"></Text>
                  </TableTD>

                  <TableTD className="cursor-pointer">
                    <Text className="text-xs md:text-sm">
                      {`${service.storage.size.value} ${service.storage.size.unit}`}
                    </Text>
                  </TableTD>

                  <TableTD className="cursor-pointer">
                    <Text className="text-xs md:text-sm"></Text>
                  </TableTD>

                  <TableTD className="cursor-pointer">
                    <div className="flex items-center gap-0.5">
                      {service.nodes.map((node, j) => (
                        <div
                          key={j}
                          className={classNames(
                            "w-3 aspect-square rounded-full",
                            {
                              "bg-success": node.status === "READY",
                              "bg-secondary-200 dark:bg-secondary-500":
                                node.status !== "READY",
                            }
                          )}
                        />
                      ))}
                    </div>
                  </TableTD>

                  <TableTD className="cursor-pointer">
                    <Text className="text-xs md:text-sm">
                      {formatTimestamp(service.createdAt, "DD/MM/YYYY").date}
                    </Text>
                  </TableTD>
                  <TableTD className="cursor-pointer">
                    <Text
                      className="text-xs"
                      type={
                        service.status === "CREATING"
                          ? "warning-alert"
                          : service.status === "READY"
                          ? "success-alert"
                          : "primary-alert"
                      }
                    >
                      {service.status}
                    </Text>
                  </TableTD>

                  <TableTD className="w-32 gap-1">
                    <div className="h-full w-full inline-flex items-center justify-center gap-1">
                      <div className="w-8 flex justify-center items-center">
                        <Dropdown
                          positionClassName="!w-48"
                          menu={[
                            {
                              key: "1",
                              label: (
                                <div className="flex items-center gap-1">
                                  <DetailsIcon className="w-4" />
                                  General Information
                                </div>
                              ),
                              onClick: () =>
                                navigate(
                                  DatabaseServiceGeneralInformationUrl(
                                    service.id
                                  )
                                ),
                              disabled: loading,
                            },
                            {
                              key: "rename",
                              label: (
                                <div className="flex items-center gap-1">
                                  <EditIcon className="w-4" />
                                  Rename database
                                </div>
                              ),
                              onClick: () =>
                                dispatch(
                                  handleShowDatabaseRenameModal(service)
                                ),
                              disabled: loading || service.status !== "READY",
                            },
                            {
                              type: "divider",
                            },
                            {
                              key: "end",
                              label: (
                                <div className="flex items-center gap-1">
                                  <ArchiveIcon className="w-4" />
                                  Delete
                                </div>
                              ),
                              danger: true,
                              onClick: (_, close) => {
                                close();
                                setDeleteService(service);
                              },
                              disabled: loading || service.status !== "READY",
                            },
                          ]}
                          type="simple"
                        >
                          <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                            <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                          </div>
                        </Dropdown>
                      </div>
                      <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" />
                    </div>
                  </TableTD>
                </TableRow>
              ))}

              {services.length === 0 && (
                <TableRow withoutHoverStyle={true}>
                  <TableTD colSpan={10} className="!border-0">
                    <NotData text="You have not created any service yet" />
                  </TableTD>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <DatabaseRenameModal />
        <DeleteModal
          title="Delete a Database Service"
          isOpen={deleteService !== null}
          onClose={() => setDeleteService(null)}
          description="To confirm, type 'DELETE' in the box below"
          confirmButtonText="Confirm"
          confirmButtonType="danger"
          confirmButtonClassName="w-full"
          loading={actionLoading}
          onConfirm={() => {
            deleteService &&
              dispatch(
                deleteDBSAsync({
                  clusterId: deleteService.id,
                  engine: deleteService.engine,
                })
              ).then((action) => {
                if (action.type === "services/delete/fulfilled") {
                  dispatch(getDatabaseServicesAsync());
                  setDeleteService(null);
                }
              });
          }}
          confirmString={deleteService?.description}
        />
      </div>
    </>
  );
};

const getDBserviceIcon = (serviceType: DBSEngineType) => {
  switch (serviceType) {
    case "mongodb":
      return <MongoIcon className="w-5 aspect-square" />;

    case "mysql":
      return <MySQLIcon className="w-5 aspect-square" />;

    case "postgresql":
      return <PostgreIcon className="w-5 aspect-square" />;

    case "redis":
      return <RedisIcon className="w-5 aspect-square" />;

    case "cassandra":
      return <CassandraIcon className="w-5 aspect-square" />;

    case "m3db":
      return <CircleStackIcon className="w-5 aspect-square" />;

    case "m3aggregator":
      return <CircleStackIcon className="w-5 aspect-square" />;

    default:
      return <CircleStackIcon className="w-5 aspect-square" />;
  }
};

export const getDBserviveData = (serviceType: string) => {
  switch (serviceType) {
    case "mongodb":
      return {
        title: "MongoDB",
        Icon: <MongoTypeIcon className="h-16" />,
      };
    case "mysql":
      return {
        title: "MySQL",
        Icon: <MySQLIcon className="h-16" />,
      };
    case "postgresql":
      return {
        title: "PostgreSQL",
        Icon: <PostgreIcon className="h-10" />,
      };
    case "redis":
      return {
        title: "Redis™*",
        Icon: <RedisIcon className="h-10" />,
      };
    case "cassandra":
      return {
        title: "Cassandra",
        Icon: <CassandraIcon className="h-12" />,
      };
    case "m3db":
      return {
        title: "M3DB",
        Icon: <CircleStackIcon className="h-10" />,
      };
    case "m3aggregator":
      return {
        title: "M3 Aggregator",
        Icon: <CircleStackIcon className="h-10" />,
      };
    default:
      return {
        title: capitalizeFirstLetter(serviceType),
        Icon: <CircleStackIcon className="h-10" />,
      };
  }
};
export default DatabaseServicesTab;
