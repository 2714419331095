import { useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  getMeAsync,
  selectProfileUpdateLoading,
  selectUser,
  updateProfileAvatarAsync,
} from "../../../../store/auth/authSlice";
import UserDefaultAvatar from "./../../../../assets/images/blank-avatar.png";
import Modal from "../../../modals/Modal";
import AvatarEditor from "react-avatar-editor";
import Text from "../../../general/Text";
import Button from "../../../buttons/Button";
import { convertBlobToFile } from "../../../../utils/file";
import { BASE_URL } from "../../../../apis";
import { ReactComponent as EditIcon } from "./../../../../assets/icons/pencil-square.svg";

const UserAvatar: React.FunctionComponent = () => {
  const authUser = useAppSelector(selectUser);
  const updateLoading = useAppSelector(selectProfileUpdateLoading);
  const dispatch = useAppDispatch();

  const imageInputRef = useRef<HTMLInputElement>(null);
  const editor = useRef<AvatarEditor>(null);

  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [scale, setScale] = useState<number>(1);

  const handleSelectImage = () => imageInputRef.current?.click();

  const handleSelectedImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handleUploadImage = async () => {
    if (editor && selectedImage) {
      const canvasScaled = editor.current?.getImageScaledToCanvas();
      if (canvasScaled) {
        const dataUrl = canvasScaled.toDataURL();
        const res = await fetch(dataUrl);
        const blob = await res.blob();
        const img = convertBlobToFile(blob, selectedImage.name);
        dispatch(
          updateProfileAvatarAsync({
            img,
          })
        ).then((action) => {
          if (action.type === "auth/update-profile-avatar/fulfilled") {
            setSelectedImage(null);
            dispatch(getMeAsync({}));
          }
        });
      }
    }
  };

  return (
    <div className="">
      <div className="w-20 h-20 aspect-square rounded-full overflow-hidden shadow group relative">
        <div
          className="absolute top-0 left-0 bottom-0 right-0 bg-white/30 dark:bg-black/10 backdrop-blur-[4px] justify-center items-center hidden group-hover:flex transition-all duration-200 cursor-pointer"
          onClick={handleSelectImage}
        >
          <EditIcon className="w-6 h-6 text-secondary-800 dark:text-secondary-800" />
        </div>
        <img
          src={
            authUser?.AvatarLink !== null
              ? BASE_URL + authUser?.AvatarLink
              : UserDefaultAvatar
          }
          alt={authUser?.FullName}
          className="w-full h-full cursor-pointer"
        />
      </div>
      <input
        ref={imageInputRef}
        type="file"
        hidden
        accept="image/*"
        onChange={handleSelectedImage}
      />
      <Modal
        isOpen={selectedImage !== null}
        title="Update profile image"
        contentClassName="!w-[350px]"
        onClose={() => {
          setSelectedImage(null);
          // imageInputRef.current?.remove();
          if (imageInputRef.current) {
            imageInputRef.current.value = "";
          }
        }}
      >
        <div className="w-full min-h-[250px]">
          {selectedImage && (
            <div className="w-full flex flex-col gap-4 mt-5">
              <div className="w-full flex justify-center">
                <AvatarEditor
                  ref={editor}
                  image={selectedImage}
                  width={280}
                  height={280}
                  border={0}
                  color={[255, 255, 255, 0.2]} // RGBA
                  scale={scale}
                  rotate={0}
                  borderRadius={140}
                />
              </div>
              <div className="range flex flex-1 items-center gap-3">
                <Text className="text-sm">Scale: </Text>
                <input
                  type="range"
                  className="w-full cursor-pointer outline-none"
                  value={scale}
                  onChange={(e) => setScale(+e.target.value)}
                  min="1"
                  max="3"
                  step="0.1"
                />
                <Text className="text-sm w-7">{scale}</Text>
              </div>
            </div>
          )}
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            buttonProps={{
              // disabled: loading,
              onClick: handleUploadImage,
            }}
            loading={updateLoading}
            type="primary"
          >
            Update
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default UserAvatar;
