import Text from "../../general/Text";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getDBSMetricValuesAsync,
  selectDBSMetrics,
  selectDBSMetricsLoading,
  selectDatabaseService,
} from "../../../store/database/serviceSlice";
import { useEffect, useMemo, useState } from "react";
import Switcher from "../../inputs/Switcher";
import { Select2, SelectOption } from "../../inputs/Select";
import Loading from "../../general/Loading";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { IDBSMetricData } from "../../../types/database";
import { formatTimestamp } from "../../../utils/date";
import { GraphChartCustomTooltipProps } from "../../../types/s3";
import { round } from "../../../utils";
import { generateServicePermissions } from "../../../pages/databases/ServicePage";
import { DatabaseServiceGeneralInformationUrl } from "../../../utils/urls";
import { useNavigate } from "react-router-dom";

const periods: Array<SelectOption<string>> = [
  { label: "1 hour", value: "lastHour" },
  { label: "1 day", value: "lastDay" },
  { label: "1 week", value: "lastWeek" },
  { label: "1 month", value: "lastMonth" },
  { label: "1 year", value: "lastYear" },
];

const DatabaseMetricsTab = () => {
  const service = useAppSelector(selectDatabaseService);

  const metrics = useAppSelector(selectDBSMetrics);
  const metricsLoading = useAppSelector(selectDBSMetricsLoading);

  const [autoRefresh, setAutoRefresh] = useState<boolean>(false);
  const [selectedPeriod, setSelectedPeriod] = useState(periods[0].value);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (service) {
      const permissions = generateServicePermissions().metrics;
      if (!permissions.includes(service.engine)) {
        navigate(DatabaseServiceGeneralInformationUrl(service.id));
      } else {
        dispatch(
          getDBSMetricValuesAsync({
            engine: service.engine,
            clusterId: service.id,
            period: selectedPeriod,
            withoutError: true,
          })
        );
      }
    }
  }, [dispatch, navigate, selectedPeriod, service]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (service && autoRefresh) {
      interval = setInterval(() => {
        dispatch(
          getDBSMetricValuesAsync({
            engine: service.engine,
            clusterId: service.id,
            withoutLoading: true,
            withoutError: true,
            period: selectedPeriod,
          })
        );
      }, 60000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, service, autoRefresh, selectedPeriod]);

  console.log(metrics);
  return (
    <div>
      <div className="flex flex-col items-start w-full justify-between gap-2 pb-10">
        <Text className="text-sm">
          To help you track and manage your database instance, view its main
          metrics and statistics below. Their retention period depends on your
          service solution.
        </Text>
        <div className="flex items-center justify-between w-full mb-5">
          <Select2
            className="w-44"
            emptyString="Select an option"
            options={periods}
            value={selectedPeriod}
            onChange={(v) => setSelectedPeriod(v || periods[0].value)}
          />
          <div className="flex items-center gap-0.5">
            <Text className="text-xs">Auto-Refresh (1 min.)</Text>
            <Switcher on={autoRefresh} onToggle={setAutoRefresh} />
          </div>
        </div>
        {metricsLoading && (
          <Loading borderSize={2} className="!min-h-[300px]" />
        )}
        {!metricsLoading && Object.keys(metrics).length > 0 && (
          <div className="flex flex-col w-full gap-14">
            {Object.keys(metrics).map((key) => {
              return (
                <div className="h-[200px]">
                  <div className="w-full flex justify-center mt-5">
                    <Text className="font-semibold text-xs">
                      {chartName(metrics[key].name)}
                    </Text>
                  </div>
                  <DBSMetricChart data={metrics[key]} />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

const DBSMetricChart: React.FC<{ data: IDBSMetricData }> = ({ data }) => {
  const combineLineDataMemo = useMemo(() => {
    return () => combineGraphLineData(data);
  }, [data]);

  const renderXAxis = () => {
    return (
      <XAxis
        dataKey="name"
        tick={{ fontSize: 10 }}
        // interval={Math.ceil(combineLineDataMemo().length / 2)}
        // domain={[-1, 1]}
      />
    );
  };

  const renderYAxis = () => (
    <YAxis
      tick={{ fontSize: 10 }}
      // tickFormatter={(value) =>
      //   humanizeSize(value, { fractionDigits: 1 }).join("B")
      // }
    />
  );

  const renderChart = () => (
    <AreaChart
      data={combineLineDataMemo()}
      margin={{ top: 2, right: 15, bottom: 0, left: 0 }}
    >
      <defs>
        {data &&
          data.metrics?.map((metric, i) => {
            const _key = i * (metric.dataPoints?.length || 0);
            return (
              <linearGradient
                key={_key}
                id={`area-color-${_key}`}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="5%"
                  stopColor="rgba(31,143,255,0.1)"
                  stopOpacity="rgba(31,143,255,0.1)"
                />
                <stop offset="95%" stopColor="#eee" stopOpacity={0} />
              </linearGradient>
            );
          })}
      </defs>
      <CartesianGrid strokeDasharray="3 3" />
      {renderXAxis()}
      {renderYAxis()}
      <Tooltip content={GraphCustomTooltip} />
      {/* <Legend verticalAlign="bottom" hanging={200} /> */}
      {data &&
        data.metrics?.map((metric, i) => {
          const _key = i * (metric.dataPoints?.length || 0);
          return (
            <Area
              key={`area-${_key}`}
              dataKey={metric.hostname}
              type="monotone"
              fill={`url(#area-color-${_key})`}
              strokeWidth={2}
              stroke={chartColor(i)}
              strokeOpacity={1}
            />
          );
        })}
    </AreaChart>
  );

  return <ResponsiveContainer>{renderChart()}</ResponsiveContainer>;
};

export const combineGraphLineData = (
  data: IDBSMetricData | null,
  dateFormat: string = "DD/MM/YYYY HH:mm"
) => {
  let combinedData: Record<string, any>[] = [];
  if (
    data !== null &&
    data !== undefined &&
    data.metrics &&
    data.metrics.length > 0
  ) {
    const basicMetric = data.metrics[0];
    basicMetric.dataPoints.forEach(({ timestamp, value }) => {
      const time = formatTimestamp(timestamp, dateFormat, {
        isUTC: false,
      }).datetime;

      const entry = {
        name: time,
        timestamp,
        [basicMetric.hostname]: value,
      };
      combinedData.push(entry);
    });

    if (data.metrics.length > 1) {
      const extraMetrics = data.metrics.slice(1);
      for (let i = 0; i < extraMetrics.length; i++) {
        const metric = extraMetrics[i];
        combinedData = combinedData.map((cd, j) => ({
          ...cd,
          [metric.hostname]: metric.dataPoints[j]
            ? metric.dataPoints[j].value
            : null,
        }));
      }
    }
  }
  // Iterate over the values array of each line data
  return combinedData;
};

const GraphCustomTooltip = ({
  active,
  payload,
}: GraphChartCustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white/70 dark:bg-dark-3/80 text-xs p-2 border dark:border-dark-2 rounded-md max-h-40 overflow-y-auto">
        <div className="flex flex-col">
          <Text className="text-center " type="subtext">
            {
              formatTimestamp(
                payload[0].payload.timestamp,
                "DD/MM/YYYY HH:mm",
                { isUTC: false }
              ).datetime
            }
          </Text>
          {payload.map((item, i) => (
            <div className="flex items-center gap-1" key={i}>
              <span
                className="w-2 h-2 rounded-full"
                style={{ backgroundColor: chartColor(i) }}
              />
              <Text className="label">{`${item.dataKey} : ${round(
                item.payload[item.dataKey],
                4
              )}`}</Text>
            </div>
          ))}
        </div>
      </div>
    );
  }
};

const chartName = (label: string) => {
  switch (label) {
    case "cpu_usage_percent":
      return "CPU used (%)";

    case "disk_usage_percent":
      return "Disk space used (MB/s)";

    case "diskio_read":
      return "No. of IOPS (read)";

    case "diskio_writes":
      return "No. of IOPS (write)";

    case "net_receive":
      return "Network Reception (MB/s)";

    case "net_send":
      return "Network Transmission (MB/s)";

    default:
      return "";
  }
};

const chartColor = (i: number) => {
  const colors = ["rgb(0 ,116 ,229)", "rgb(249 ,115 ,22)", "rgb(126 ,34 ,206)"];
  const colorsLen = colors.length;

  if (i <= colorsLen - 1) {
    return colors[i];
  } else {
    return colors[i - colorsLen];
  }
};
export default DatabaseMetricsTab;
