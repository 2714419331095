import { useAppSelector } from "../../../../hooks";
import {
  selectInternodeData,
  selectInternodeDataLoading,
} from "../../../../store/s3/overviewSlice";
import GraphWidget from "./freams/GraphWidget";
import { S3WidgetType } from "../../../../types/s3";

const chartType: S3WidgetType = "internodeData";
const chartCode: number = 17;

const InternodeDataWidget = () => {
  const data = useAppSelector(selectInternodeData);
  const loading = useAppSelector(selectInternodeDataLoading);

  return (
    <GraphWidget
      title="Internode Data Tranfer"
      chartCode={chartCode}
      chartType={chartType}
      data={data}
      dataLoading={loading}
      chartDateFormat="HH:mm:ss"
      modalDateFormat="MM/DD HH:mm:ss"
    />
  );
};

export default InternodeDataWidget;
