import {
  UseFieldArrayAppend,
  UseFormReturn,
  useFieldArray,
  useForm,
} from "react-hook-form";
import Text, { A } from "../../general/Text";
import Input from "../../inputs/Input";
import { WebAppCreateFormData, WebAppSecretFile } from "../../../types/web-app";
import { Select2 } from "../../inputs/Select";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  handleChangeWebAppHasDisk,
  selectWebAppHasDisk,
  webAppInstances,
  webAppRegions,
} from "../../../store/web-app/webAppCreateSlice";
import { Controller } from "react-hook-form";
import Card from "../../general/Card";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import Button from "../../buttons/Button";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as ArrowDownIcon } from "./../../../assets/icons/arrow-down.svg";
import { uuid } from "../../../utils/uuid";
import { useEffect, useState } from "react";
import Modal from "../../modals/Modal";
import Textarea from "../../inputs/Textarea";
import { WebAppSecretFileSchema } from "../../../utils/validations";
import { yupResolver } from "@hookform/resolvers/yup";
import ImportFromEnvFileModal from "../../settings/env-variables/ImportFromEnvFileModal";
import { handleShowImportFromEnvFileModal } from "../../../store/settings/env-variables/envVariablesSlice";
import { WebAppInstancesGroup } from "../single-page/WebAppPlanTab";

const CreateFormStep: React.FC<{
  form: UseFormReturn<WebAppCreateFormData>;
}> = ({ form }) => {
  const {
    register,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = form;

  const {
    fields: ENVsFields,
    append: ENVappend,
    remove: ENVremove,
  } = useFieldArray({
    control,
    name: "ENVs",
  });

  const handleGenerateEnvVal = (index: number) => {
    setValue(`ENVs.${index}.Value`, uuid(20));
  };

  const {
    fields: secretFileFields,
    append: secretFileAppend,
    remove: secretFileRemove,
  } = useFieldArray({
    control,
    name: "SecretFiles",
  });

  const [advanced, setAdvanced] = useState<boolean>(false);
  const [secretFileModal, setSecretFileModal] = useState<boolean>(false);
  const hasDisk = useAppSelector(selectWebAppHasDisk);

  const dispatch = useAppDispatch();
  return (
    <div className="flex my-5 mb-20 w-full">
      <div className="flex flex-col space-y-12 md:space-y-16 w-full">
        <div className="grid grid-cols-3 gap-x-10">
          <div className="col-span-1 text-sm">
            <Text className="font-medium">Name</Text>
            <Text type="subtext">A unique name for your web service.</Text>
          </div>
          <div className="col-span-2">
            <Input
              inputProps={{ ...register("Name") }}
              placeholder="example-server-name"
              error={errors.Name?.message}
            />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-x-10">
          <div className="col-span-1 text-sm">
            <Text className="font-medium">Region</Text>
            <Text type="subtext">
              The <A>region</A> where your web service runs. Services must be in
              the same region to communicate privately and you currently have
              services running in <span className="font-medium">Oregon</span>
            </Text>
          </div>
          <div className="col-span-2">
            <Controller
              control={control}
              name="Origin"
              render={({ field: { onChange, value } }) => (
                <Select2
                  options={[
                    ...webAppRegions.map((r) => ({
                      label: <div>{r.title}</div>,
                      value: r.value,
                    })),
                  ]}
                  value={value}
                  onChange={onChange}
                  error={form.formState.errors.Origin?.message}
                />
              )}
            />
          </div>
        </div>
        {/* Instance Type */}
        <div className="mb-6">
          <Card title="Instance Type">
            <WebAppInstancesGroup
              control={control}
              webAppInstances={webAppInstances}
            >
              <AnimatePresence>
                {errors.InstanceType &&
                  typeof errors.InstanceType.message === "string" && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                    >
                      <p className="mt-2 text-xs text-red-600 dark:text-red-500">
                        {errors.InstanceType.message}
                      </p>
                    </motion.div>
                  )}
              </AnimatePresence>
            </WebAppInstancesGroup>
          </Card>
        </div>

        {/* environment variables */}
        <div className="grid grid-cols-3 gap-x-10">
          <div className="col-span-1 text-sm">
            <Text className="font-medium flex items-center gap-1.5">
              Environment Variables{" "}
              <Text className="text-xs font-normal" type="subtext">
                Optional
              </Text>
            </Text>
            <Text type="subtext">
              Set environment-specific config and secrets (such as API keys),
              then read those values from your code. <A>Learn more</A>.
            </Text>
          </div>
          <div className="col-span-2">
            <div className="flex flex-col w-full">
              {ENVsFields.map((_, index) => (
                <div key={index} className="grid gap-3 grid-cols-12 pb-2">
                  <div className="col-span-4">
                    <Input
                      label=""
                      inputProps={{
                        ...register(`ENVs.${index}.Key` as const),
                      }}
                      error={
                        errors.ENVs &&
                        errors.ENVs[index] &&
                        errors.ENVs[index]?.Key &&
                        errors.ENVs[index]?.Key?.message
                      }
                      placeholder="Key"
                    />
                  </div>
                  <div className="col-span-7">
                    <div className="flex items-center gap-2">
                      <div className="flex-1">
                        <Input
                          label=""
                          inputProps={{
                            ...register(`ENVs.${index}.Value` as const),
                          }}
                          error={
                            errors.ENVs &&
                            errors.ENVs[index] &&
                            errors.ENVs[index]?.Value &&
                            errors.ENVs[index]?.Value?.message
                          }
                          placeholder="Value"
                          className="flex-1"
                        />
                      </div>
                      {!getValues(`ENVs.${index}.Value`) && (
                        <Button
                          buttonProps={{
                            onClick: (e) => {
                              e.preventDefault();
                              handleGenerateEnvVal(index);
                            },
                          }}
                        >
                          generate
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="col-span-1 flex items-start justify-center">
                    <Button
                      type="icon"
                      buttonProps={{
                        onClick: (e) => {
                          e.preventDefault();
                          ENVremove(index);
                        },
                        disabled: false,
                      }}
                      buttonClassName="mt-1.5"
                    >
                      <ArchiveIcon className="w-5 text-slate-700 dark:text-slate-300 hover:text-red-500 hover:dark:text-red-400" />
                    </Button>
                  </div>
                </div>
              ))}
              <AnimatePresence>
                {errors.ENVs &&
                  typeof errors.ENVs.root?.message === "string" && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                    >
                      <p className="mt-2 text-xs text-red-600 dark:text-red-500">
                        {errors.ENVs.root.message}
                      </p>
                    </motion.div>
                  )}
              </AnimatePresence>
              <div className="flex justify-start gap-2 mt-2">
                <Button
                  type="light"
                  buttonProps={{
                    onClick: (e) => {
                      e.preventDefault();
                      ENVappend({
                        Key: "",
                        Value: "",
                      });
                    },
                  }}
                >
                  <PlusIcon className="w-4" />
                  Add Environment Variable
                </Button>
                <Button
                  type="light"
                  buttonProps={{
                    onClick: (e) => {
                      e.preventDefault();
                      dispatch(handleShowImportFromEnvFileModal());
                    },
                  }}
                >
                  Add from .env
                </Button>
                <ImportFromEnvFileModal callback={ENVappend} />
              </div>
            </div>
          </div>
        </div>

        {/* advanced */}
        <div className="flex flex-col gap-3">
          <Button
            buttonProps={{
              onClick: (e) => {
                e.preventDefault();
                setAdvanced((prev) => !prev);
              },
            }}
          >
            Advanced
            <ArrowDownIcon
              className={classNames("w-4 transition-transform duration-300", {
                "-rotate-180": advanced,
              })}
            />
          </Button>
          <AnimatePresence>
            {advanced && (
              <Card>
                {/* Secret Files */}
                <div className="grid grid-cols-3 gap-x-10 my-6">
                  <div className="col-span-1 text-sm font-normal font-sans">
                    <Text>
                      Store plaintext files containing secret data (such as a
                      <span className="text-primary-300 ml-1">.env</span> file
                      or a private key)
                    </Text>
                    <Text className="mt-4">
                      Read these files during builds by absolute path at{" "}
                      <span className="text-primary-300 ml-1">{`/etc/secrets/<filename>`}</span>
                      .
                    </Text>
                  </div>
                  <div className="col-span-2">
                    <div className="flex flex-col w-full">
                      {secretFileFields.map((_, index) => (
                        <div
                          key={index}
                          className="grid gap-3 grid-cols-12 pb-2"
                        >
                          <div className="col-span-4">
                            <Input
                              label=""
                              inputProps={{
                                ...register(
                                  `SecretFiles.${index}.Filename` as const
                                ),
                                readOnly: true,
                              }}
                              error={
                                errors.SecretFiles &&
                                errors.SecretFiles[index] &&
                                errors.SecretFiles[index]?.Filename &&
                                errors.SecretFiles[index]?.Filename?.message
                              }
                              placeholder="file name"
                            />
                          </div>
                          <div className="col-span-7">
                            <div className="flex items-center gap-2">
                              <div className="flex-1">
                                <Input
                                  label=""
                                  inputProps={{
                                    ...register(
                                      `SecretFiles.${index}.FileContents` as const
                                    ),
                                    readOnly: true,
                                  }}
                                  error={
                                    errors.SecretFiles &&
                                    errors.SecretFiles[index] &&
                                    errors.SecretFiles[index]?.FileContents &&
                                    errors.SecretFiles[index]?.FileContents
                                      ?.message
                                  }
                                  placeholder="file contents"
                                  className="flex-1"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-span-1 flex items-start justify-center">
                            <Button
                              type="icon"
                              buttonProps={{
                                onClick: (e) => {
                                  e.preventDefault();
                                  secretFileRemove(index);
                                },
                                disabled: false,
                              }}
                            >
                              <ArchiveIcon className="w-5 text-slate-700 dark:text-slate-300 hover:text-red-500 hover:dark:text-red-400" />
                            </Button>
                          </div>
                        </div>
                      ))}

                      <div className="flex justify-start gap-2 mt-2">
                        <Button
                          type="light"
                          buttonProps={{
                            onClick: (e) => {
                              e.preventDefault();
                              setSecretFileModal(true);
                            },
                          }}
                        >
                          <PlusIcon className="w-4" />
                          Add Secret File
                        </Button>
                      </div>
                      <SecretFileModal
                        isOpen={secretFileModal}
                        onClose={() => setSecretFileModal(false)}
                        callback={secretFileAppend}
                      />
                    </div>
                  </div>
                </div>

                <hr className="dark:border-slate-300/20" />
                {/* Disc */}
                <div className="disk-container my-6">
                  <div className="antialiased font-sans font-normal text-sm leading-6 space-y-4 max-w-lg">
                    <Text className="">
                      Create a disk to store application data that needs to
                      persist across deploys. You can mount it on any absolute
                      path and read and write to it using standard filesystem
                      primitives. Disks are charged at $0.25/GB per month.
                    </Text>
                    <Text>
                      Adding a disk prevents zero downtime deploys.{" "}
                      <A>Learn more.</A>
                    </Text>
                  </div>
                  <div className="new-disk-container mt-2">
                    {!hasDisk && (
                      <Button
                        buttonProps={{
                          onClick: () =>
                            dispatch(handleChangeWebAppHasDisk(true)),
                        }}
                      >
                        Add Disk
                      </Button>
                    )}
                    {hasDisk && (
                      <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-5 md:gap-10 mt-10">
                          <div className="flex flex-col w-full md:flex-row gap-0.5 md:gap-x-5">
                            <div className="antialiased font-sans font-normal text-sm leading-6 md:w-1/2">
                              <Text className="">Name</Text>
                              <Text type="subtext" className="text-xs">
                                An identifying name for this disk. Used only for
                                display.
                              </Text>
                            </div>
                            <div className="flex-1">
                              <Input
                                inputProps={{ ...register("DiskName") }}
                                error={errors.DiskName?.message}
                              />
                            </div>
                          </div>
                          <div className="flex flex-col w-full md:flex-row gap-0.5 md:gap-x-5">
                            <div className="antialiased font-sans font-normal text-sm leading-6 md:w-1/2">
                              <Text className="">Mount Path</Text>
                              <Text type="subtext" className="text-xs">
                                The absolute path to mount this disk, like
                                /var/lib/data. Can not be the root (/)
                                directory.
                              </Text>
                            </div>
                            <div className="flex-1">
                              <Input
                                inputProps={{ ...register("DiskMountPath") }}
                                error={errors.DiskMountPath?.message}
                              />
                            </div>
                          </div>
                          <div className="flex flex-col w-full md:flex-row gap-0.5 md:gap-x-5">
                            <div className="antialiased font-sans font-normal text-sm leading-6 md:w-1/2">
                              <Text className="">Size (GB)</Text>
                              <Text type="subtext" className="text-xs">
                                You can increase the size later, but you can't
                                decrease it. Pick the lowest value that works
                                for your app.
                              </Text>
                            </div>
                            <div className="flex-1">
                              <Input
                                inputProps={{
                                  type: "number",
                                  ...register("DiskSize"),
                                }}
                                error={errors.DiskSize?.message}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-end">
                          <Button
                            type="danger"
                            buttonProps={{
                              onClick: () =>
                                dispatch(handleChangeWebAppHasDisk(false)),
                            }}
                          >
                            Remove
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <hr className="dark:border-slate-300/20" />

                {/* Health Check Path */}
                <div className="grid grid-cols-3 gap-x-10 mt-6">
                  <div className="col-span-1 text-sm">
                    <Text className="font-medium">Health Check Path</Text>
                    <Text type="subtext">
                      If you're running a server, enter the path where your
                      server will always return a{" "}
                      <span className="text-primary-300 ml-1">200 OK</span>{" "}
                      response. We use it to monitor your app and for{" "}
                      <A>zero downtime deploys</A>.
                    </Text>
                  </div>
                  <div className="col-span-2">
                    <Input
                      inputProps={{ ...register("HealthCheckPath") }}
                      placeholder="/healthz"
                      error={errors.HealthCheckPath?.message}
                    />
                  </div>
                </div>

                {/* Docker Command */}
                <div className="grid grid-cols-3 gap-x-10 mt-6">
                  <div className="col-span-1 text-sm">
                    <Text className="font-medium">Docker Command</Text>
                    <Text type="subtext">
                      Add an optional command to override the Docker{" "}
                      <span className="text-primary-300 ml-1">CMD</span> for
                      this service. This will also override the{" "}
                      <span className="text-primary-300 ml-1">ENTRYPOINT</span>
                      if defined in your Dockerfile. Examples:{" "}
                      <span className="text-primary-300 ml-1">
                        ./start.sh --type=worker
                      </span>{" "}
                      or{" "}
                      <span className="text-primary-300 ml-1">
                        ./bin/bash -c cd /some/dir && ./start.sh
                      </span>
                    </Text>
                  </div>
                  <div className="col-span-2">
                    <Input
                      inputProps={{ ...register("DockerCommand") }}
                      placeholder=""
                      error={errors.DockerCommand?.message}
                    />
                  </div>
                </div>

                {/* Pre-Deploy Command */}
                <div className="grid grid-cols-3 gap-x-10 mt-6">
                  <div className="col-span-1 text-sm">
                    <Text className="font-medium">Pre-Deploy Command</Text>
                    <Text type="subtext">
                      This command runs before starting your service. It is
                      typically used for tasks like running a database migration
                      or uploading assets to a CDN.{" "}
                    </Text>
                  </div>
                  <div className="col-span-2">
                    <Input
                      inputProps={{ ...register("PreDeployCommand") }}
                      placeholder=""
                      error={errors.PreDeployCommand?.message}
                    />
                  </div>
                </div>
              </Card>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export const SecretFileModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  callback?: any;
  selectedFile?: WebAppSecretFile;
}> = ({ isOpen, onClose, callback, selectedFile }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(WebAppSecretFileSchema),
  });

  const handleAddSecret = (data: any) => {
    if (callback) {
      callback(data);
      onClose();
    }
  };

  useEffect(() => {
    if (selectedFile) {
      setValue("Filename", selectedFile.Filename || "");
      setValue("FileContents", selectedFile.FileContents || "");
    } else {
      setValue("Filename", "");
      setValue("FileContents", "");
    }
  }, [selectedFile, setValue]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Secret File"
      contentClassName="max-w-xl"
    >
      <form onSubmit={handleSubmit(handleAddSecret)}>
        <div className="flex flex-col gap-4 mt-5">
          <Input
            label="Filename"
            placeholder="file.txt"
            inputProps={{ ...register("Filename") }}
            error={errors.Filename?.message}
          />
          <Textarea
            label="File Contents"
            placeholder=""
            textareaProps={{ rows: 10, ...register("FileContents") }}
            error={errors.FileContents?.message}
          />
          <div className="flex justify-end">
            <Button type="primary" buttonProps={{ type: "submit" }}>
              Save
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default CreateFormStep;
