import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import useShow from "../../hooks/useShow";
import AccountProvider from "../../providers/AccountProvider";
import { useEffect } from "react";
import Header from "./Header";
import SearchProvider from "../../providers/SearchProvider";
import ConfettiExplosion from "react-confetti-explosion";
import { useAppSelector } from "../../hooks";
import {
  selectConfettiStatus,
  selectOnStageEnv,
  selectUser,
} from "../../store/auth/authSlice";
// import PChatWidget from "../general/PChatWidget";

const PanelLayout = () => {
  const [showNav, { hide, show }] = useShow();
  const location = useLocation();
  const segments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");

  useEffect(() => {
    hide();
  }, [hide, location.pathname]);

  const confettiStatus = useAppSelector(selectConfettiStatus);
  const user = useAppSelector(selectUser);
  const env = useAppSelector(selectOnStageEnv);

  return (
    <SearchProvider>
      <div className="min-h-screen bg-white dark:bg-dark-1">
        <Sidebar segments={segments ?? [""]} show={showNav} />
        <div className="min-h-screen w-full lg:w-[calc(100%-300px)] ml-auto transition-all duration-200">
          {confettiStatus && (
            <div className="absolute top-0 left-0 bottom-0 right-0 w-full h-full flex justify-center items-center -translate-y-44">
              <ConfettiExplosion
                force={0.4}
                duration={2500}
                particleCount={100}
                width={850}
              />
            </div>
          )}

          <Header onHideNav={hide} onShowNav={show} />
          <div className="max-w-7xl mx-auto">
            <AccountProvider>
              <Outlet />
            </AccountProvider>
          </div>
        </div>
        {/* <PChatWidget user={user} env={env} /> */}
      </div>
    </SearchProvider>
  );
};

export default PanelLayout;
