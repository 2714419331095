import { RpcSecurityToken } from "../../../types/rpc";
import { copyToClipboard } from "../../../utils/copy";
import Dropdown from "../../general/Dropdown";
import { ReactComponent as CopyIcon } from "./../../../assets/icons/copy.svg";
import { ReactComponent as DeleteIcon } from "./../../../assets/icons/archive-box.svg";
import classNames from "classnames";
import { boolean } from "yup";

const AuthenticationToken = ({
  token,
  onDelete,
  deleteDisabled,
}: {
  token: RpcSecurityToken;
  onDelete?: (id: number) => void;
  deleteDisabled: boolean;
}) => {
  return (
    <div className="flex justify-between items-center flex-wrap py-2 w-full p-4">
      <div className="flex justify-between items-center flex-wrap md:flex-nowrap w-[calc(100%-80px)]">
        <div className="truncate  md:max-w-1/2 max-h-min text-slate-800 dark:text-slate-200 text-sm">
          {token.Token}
        </div>
        <div className="md:pl-4 truncate flex-nowrap md:max-w-1/2 max-h-min text-slate-400 dark:text-slate-400 text-sm">
          {token.MaskedAddress}
        </div>
      </div>
      <div className="flex items-center gap-5">
        <Dropdown
          type="simple"
          menu={[
            {
              key: "1",
              label: "Copy Token",
              onClick: (_, close) => {
                copyToClipboard(token.Token);
                close();
              },
            },
            {
              key: "2",
              label: "Copy URL",
              onClick: (_, close) => {
                copyToClipboard(token.MaskedAddress);
                close();
              },
            },
            // {
            //   key: "3",
            //   label: "Copy WebSocket Url",
            //   onClick: () => copyToClipboard(""),
            // },
          ]}
        >
          <CopyIcon className="w-5 h-5" />
        </Dropdown>

        <div className="h-10 flex justify-center items-center">
          <DeleteIcon
            onClick={() =>
              onDelete && !deleteDisabled ? onDelete(token.Id) : {}
            }
            className={classNames(
              "w-5 h-5 text-slate-800 dark:text-slate-100 transition-all duration-200",
              {
                "hover:text-red-600 dark:hover:text-red-300 cursor-pointer":
                  !deleteDisabled,
                "opacity-60 cursor-not-allowed": deleteDisabled,
              }
            )}
          />
        </div>
      </div>
    </div>
  );
};

const AddressItem = ({
  value,
  onDelete,
  deleteDisabled,
}: {
  value: string | React.ReactNode;
  onDelete?: () => void;
  deleteDisabled?: boolean;
}) => {
  return (
    <div className="flex justify-between items-center flex-wrap py-2 w-full p-4">
      <div className="flex justify-between items-center flex-wrap md:flex-nowrap w-[calc(100%-80px)]">
        <div className="truncate  max-h-min text-slate-800 dark:text-slate-200 text-sm">
          {value}
        </div>
      </div>
      <div className="flex items-center gap-5">
        <div className="h-10 flex justify-center items-center">
          <DeleteIcon
            onClick={() => (onDelete && !deleteDisabled ? onDelete() : {})}
            className={classNames(
              "w-5 h-5 text-slate-800 dark:text-slate-100 transition-all duration-200",
              {
                "hover:text-red-600 dark:hover:text-red-300 cursor-pointer":
                  !deleteDisabled,
                "opacity-60 cursor-not-allowed": deleteDisabled,
              }
            )}
          />
        </div>
      </div>
    </div>
  );
};

export { AddressItem, AuthenticationToken };
