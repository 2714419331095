import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useState } from "react";
import Button from "../../buttons/Button";

import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";

import { useNavigate } from "react-router-dom";
import { DatabaseServiceGeneralInformationUrl } from "../../../utils/urls";
import Dropdown from "../../general/Dropdown";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import QuestionModal, { DeleteModal } from "../../modals/QuestionModal";
import Text from "../../general/Text";
import { useSearch } from "../../../providers/SearchProvider";
import Highlighter from "react-highlight-words";
import {
  getDatabaseServicesAsync,
  selectDatabaseServicesActionLoading,
  updateDBSAsync,
} from "../../../store/database/servicesSlice";
import {
  getDatabaseServiceAsync,
  handleShowIPsEditorModal,
  selectDatabaseService,
  selectDatabaseServiceLoading,
  selectDelectedDatabaseServiceIP,
} from "../../../store/database/serviceSlice";
import IpsCreateModal from "./IpsCreateModal";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil-square.svg";
import { IpRestriction } from "../../../types/database";
import { generateServicePermissions } from "../../../pages/databases/ServicePage";

const AuthorisedIpsTab = () => {
  const [deleteIPAddress, setDeleteIPAddress] = useState<string | null>(null);
  const [filteredIpRestriction, setFilteredIpRestriction] = useState<
    IpRestriction[]
  >([]);
  const service = useAppSelector(selectDatabaseService);
  const loading = useAppSelector(selectDatabaseServiceLoading);
  const actionLoading = useAppSelector(selectDatabaseServicesActionLoading);
  const selectedIpAddress = useAppSelector(selectDelectedDatabaseServiceIP);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (service) {
      const permissions = generateServicePermissions().allowedIps;
      if (!permissions.includes(service.engine)) {
        navigate(DatabaseServiceGeneralInformationUrl(service.id));
      } else {
        const lookedUpFiles = service.ipRestrictions.filter((ip) =>
          ip.ip.toLowerCase().includes(searchValue.toLowerCase())
        );
        setFilteredIpRestriction(lookedUpFiles);
      }
    }
  }, [dispatch, navigate, searchValue, service]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Database</title>
        <meta name="description" content="" />
      </Helmet>

      {service && (
        <>
          <div className="flex items-center justify-between">
            <div className="items-center justify-between flex flex-1 transition duration-150">
              <Text className="font-medium text-lg">
                IPs authorised to access the database
              </Text>
            </div>
            <div className="flex items-center gap-2">
              <Button
                size="medium"
                type="light"
                buttonProps={{
                  onClick: () => {
                    if (service) {
                      dispatch(getDatabaseServicesAsync());
                      dispatch(getDatabaseServiceAsync({ id: service.id }));
                    }
                  },
                  disabled: !service,
                }}
                buttonClassName="group"
                tooltip="Refresh"
              >
                <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
              </Button>
              <Button
                type="primary"
                buttonProps={{
                  onClick: () => dispatch(handleShowIPsEditorModal({})),
                }}
                buttonClassName="group"
              >
                Add an IP address or IP block (CIDR)
                <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
              </Button>
            </div>
          </div>

          <div className="mt-5 w-full">
            <Table loading={loading}>
              <TableHead>
                <TableRow>
                  <TableTH lable="IP/mask" />
                  <TableTH lable="Description" />
                  <TableTH lable="" />
                </TableRow>
              </TableHead>
              <TableBody>
                {service &&
                  filteredIpRestriction.map((ip, i) => (
                    <TableRow key={i}>
                      <TableTD className="cursor-pointer">
                        <div className="flex items-center flex-row justify-between space-x-3">
                          <Highlighter
                            highlightClassName="bg-primary-100 dark:bg-primary-300 rounded-sm"
                            className="whitespace-nowrap max-w-[240px] text-ellipsis overflow-hidden text-sm font-medium"
                            searchWords={[searchValue]}
                            autoEscape={true}
                            textToHighlight={ip.ip}
                          />
                        </div>
                      </TableTD>
                      <TableTD className="cursor-pointer">
                        <Text className="text-xs md:text-sm">
                          {ip.description}
                        </Text>
                      </TableTD>
                      <TableTD className="w-32 gap-1">
                        <div className="h-full w-full inline-flex items-center justify-center gap-1">
                          <div className="w-8 flex justify-center items-center">
                            <Dropdown
                              positionClassName="!w-48"
                              menu={[
                                {
                                  key: "1",
                                  label: (
                                    <div className="flex items-center gap-1">
                                      <EditIcon className="w-4"></EditIcon>
                                      Edit IP address
                                    </div>
                                  ),
                                  onClick: () =>
                                    dispatch(handleShowIPsEditorModal({ ip })),
                                  disabled: loading,
                                },

                                {
                                  type: "divider",
                                },
                                {
                                  key: "end",
                                  label: (
                                    <div className="flex items-center gap-1">
                                      <ArchiveIcon className="w-4" />
                                      Delete IP address
                                    </div>
                                  ),
                                  danger: true,
                                  onClick: (_, close) => {
                                    close();
                                    setDeleteIPAddress(ip.ip);
                                  },
                                  disabled: loading,
                                },
                              ]}
                              type="simple"
                            >
                              <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                                <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                              </div>
                            </Dropdown>
                          </div>
                          <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" />
                        </div>
                      </TableTD>
                    </TableRow>
                  ))}

                {service?.ipRestrictions.length === 0 && !loading && (
                  <TableRow withoutHoverStyle={true}>
                    <TableTD colSpan={10} className="!border-0">
                      <Text className="antialiased font-sans font-medium text-base leading-6">
                        You have not created any IP yet
                      </Text>
                    </TableTD>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <IpsCreateModal />

          <DeleteModal
            title="Delete an IP Address"
            isOpen={deleteIPAddress !== null}
            onClose={() => setDeleteIPAddress(null)}
            description="To confirm, type 'DELETE' in the box below"
            confirmButtonText="Confirm"
            confirmButtonType="danger"
            confirmButtonClassName="w-full"
            onConfirm={() => {
              deleteIPAddress &&
                service &&
                dispatch(
                  updateDBSAsync({
                    id: service.id,
                    engine: service?.engine,
                    data: {
                      ipRestrictions: service.ipRestrictions
                        .map((restriction: any) => ({
                          ip: restriction.ip,
                          description: restriction.description,
                        }))
                        .filter((ip) => ip.ip !== deleteIPAddress),
                    },
                  })
                ).then((action) => {
                  if (action.type === "services/update/fulfilled") {
                    dispatch(getDatabaseServicesAsync());
                    dispatch(getDatabaseServiceAsync({ id: service.id }));
                  } else {
                  }
                  setDeleteIPAddress(null);
                });
            }}
            loading={actionLoading}
            confirmString={selectedIpAddress?.ip}
          />
        </>
      )}
    </>
  );
};

export default AuthorisedIpsTab;
