import classNames from "classnames";
import Select, { Select2, SelectOption } from "../../../inputs/Select";
import Text from "../../../general/Text";
import Input from "../../../inputs/Input";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  handleChangeExpressionValue,
  handleChangeNotificationName,
  handleChangeSelectedChain,
  handleChangeSelectedNetwork,
  handlesetSelectedTemplate,
  selectNotificationName,
  selectSelectedChain,
  selectSelectedNetwork,
  selectSelectedTemplate,
} from "../../../../store/block-event/blockEventCreateSlice";
// import { NotificationTemplate } from "../../../../types/block-events";
import {
  getBlockEventNetworksAsync,
  getBlockEventTemplatesAsync,
  selectBlockEventNetwoks,
  selectBlockEventNetwoksLoading,
  selectBlockEventTemplates,
  selectBlockEventTemplatesLoading,
} from "../../../../store/block-event/blockEventsSlice";
import { groupNetworksByChain } from "../../../../utils/service";
import { capitalizeFirstLetter } from "../../../../utils";
import { BlockEventNetwork } from "../../../../types/block-events";

const SelectTempleteStep = () => {
  const networks = useAppSelector(selectBlockEventNetwoks);
  const networksLoading = useAppSelector(selectBlockEventNetwoksLoading);

  const templates = useAppSelector(selectBlockEventTemplates);
  const templatesLoading = useAppSelector(selectBlockEventTemplatesLoading);

  const groupedNetworks = groupNetworksByChain(networks, templates);

  const notificationName = useAppSelector(selectNotificationName);

  const selectedChain = useAppSelector(selectSelectedChain);
  const selectedNetwork = useAppSelector(selectSelectedNetwork);
  const selectedTemplate = useAppSelector(selectSelectedTemplate);

  const dispatch = useAppDispatch();
  const [networkOptions, setNetworkOptions] = useState<SelectOption<string>[]>(
    () => {
      if (selectedChain) {
        return selectedChain.Networks.map((n) => ({
          label: capitalizeFirstLetter(n.NetworkName.split("-")[1]),
          value: n.NetworkName,
          extraData: { templates: n.Templates },
        }));
      } else {
        return [];
      }
    }
  );

  useEffect(() => {
    networks.length === 0 && dispatch(getBlockEventNetworksAsync());
  }, [dispatch, networks.length]);

  useEffect(() => {
    templates.length === 0 && dispatch(getBlockEventTemplatesAsync());
  }, [dispatch, templates.length]);

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(handleChangeNotificationName(event.target.value));
  };

  const handleChainChange = useCallback(
    (ChainName: string | undefined) => {
      const selectedChain = groupedNetworks.find(
        (gn) => gn.ChainName === ChainName
      );
      if (selectedChain) {
        dispatch(handleChangeSelectedChain(selectedChain));
        const selectedChainNetworks = selectedChain.Networks;

        const networkNewOptions = selectedChainNetworks.map((network) => ({
          label: capitalizeFirstLetter(network.NetworkName.split("-")[1]),
          value: network.NetworkName,
          extraData: { templates: network.Templates },
        }));
        setNetworkOptions(networkNewOptions || []);

        if (selectedChainNetworks.length > 0) {
          dispatch(handleChangeSelectedNetwork(selectedChainNetworks[0]));
        } else {
          dispatch(handleChangeSelectedNetwork(null));
        }
      } else {
        dispatch(handleChangeSelectedNetwork(null));
      }
    },
    [dispatch, groupedNetworks]
  );

  const handleNetworkChange = useCallback(
    (selectedOption: SelectOption<string> | undefined) => {
      if (networkOptions) {
        const chain = groupedNetworks.find(
          (gn) => gn.ChainName === selectedChain?.ChainName
        );
        if (chain) {
          const selectedNewNetwork = chain.Networks.find(
            (n) => n.NetworkName === selectedOption?.value
          );
          if (selectedNewNetwork) {
            dispatch(handleChangeSelectedNetwork(selectedNewNetwork));
          } else {
            dispatch(handleChangeSelectedNetwork(null));
            dispatch(handleChangeSelectedNetwork(null));
          }
        } else {
          dispatch(handleChangeSelectedNetwork(null));
          dispatch(handleChangeSelectedNetwork(null));
        }
      }
    },
    [dispatch, groupedNetworks, networkOptions, selectedChain?.ChainName]
  );

  // const networkTemplates = useMemo(() => {
  //   return templates.filter((temp) =>
  //     temp.BlockEventNetworks.find(
  //       (net) => net.NetworkName === selectedNetwork?.NetworkName
  //     )
  //   );
  // }, [selectedNetwork?.NetworkName, templates]);

  const handleSelectTemplate = (templateId: number) => {
    const selected = templates.find((obj) => obj.Id === templateId);
    if (selected) {
      dispatch(handlesetSelectedTemplate(selected));
      dispatch(handleChangeExpressionValue(selected.Expression));
    }
  };

  return (
    <div className="w-full pb-12 grid grid-cols-1 ">
      <div>
        <div className="mt-1 md:w-1/2">
          <Input
            label="Name your event"
            inputProps={{
              value: notificationName,
              onChange: handleChangeName,
            }}
          />
        </div>
      </div>

      <div className="mt-6">
        <Text className="text-sm">Chain and network</Text>
        <div className="mt-1 md:w-1/2 flex gap-4">
          {/* <Select<string>
            options={groupedNetworks.map((gn) => ({
              label: capitalizeFirstLetter(gn.ChainName),
              value: gn.ChainName,
            }))}
            selected={
              selectedChain
                ? {
                    label: selectedChain.ChainName,
                    value: selectedChain.ChainName,
                  }
                : undefined
            }
            setSelected={handleChainChange}
            className="!w-1/2"
            loading={networksLoading}
            emptyString="Select a chain"
          /> */}

          <Select2
            options={groupedNetworks.map((gn) => ({
              label: capitalizeFirstLetter(gn.ChainName),
              value: gn.ChainName,
            }))}
            value={selectedChain?.ChainName}
            onChange={handleChainChange}
            className="!w-1/2"
            loading={networksLoading}
            emptyString="Select a chain"
          />

          <Select<string>
            options={networkOptions}
            selected={
              selectedNetwork
                ? {
                    label: capitalizeFirstLetter(
                      selectedNetwork.NetworkName.split("-")[1]
                    ),
                    value: selectedNetwork.NetworkName,
                  }
                : undefined
            }
            setSelected={handleNetworkChange}
            className="!w-1/2"
            loading={networksLoading}
            emptyString="Select a chain first"
          />
        </div>
      </div>

      <div className="mt-12 w-full pb-12 grid grid-cols-4 gap-4">
        {selectedChain &&
          selectedNetwork?.Templates.map((template, index) => (
            <TemplateCard
              key={index}
              id={template.Id}
              name={template.Label}
              description={template.Description}
              tag={template.Tag}
              selected={selectedTemplate?.Id === template.Id}
              onClick={handleSelectTemplate}
            />
          ))}
        {}
      </div>
    </div>
  );
};

export const TemplateCard = ({
  id,
  name,
  description,
  tag,
  selected,
  onClick,
}: {
  id: number;
  name: string;
  description?: string;
  tag?: string | null;
  selected?: boolean;
  onClick?: (templateId: number) => void;
}) => {
  return (
    <div
      className={classNames(
        "col-span-2 md:col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300",
        {
          "border-primary-400 dark:border-primary-400": selected,
          "cursor-pointer": onClick,
        }
      )}
      onClick={() => (onClick ? onClick(id) : {})}
    >
      <div className="flex flex-col space-y-4 p-3 items-start justify-start">
        <Text className="text-sm font-semibold">{name}</Text>
        <Text type="subtext" className="text-sm font-light">
          {description}
        </Text>
        {tag && (
          <div className="border rounded-md px-1 py-0.5 dark:border-dark-2">
            <Text type="subtext" className="text-sm font-light">
              {tag}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};
export default SelectTempleteStep;
