import { AnimatePresence, motion } from "framer-motion";
import Button from "../buttons/Button";
import { useState } from "react";
import Input from "./Input";

const AddressInput = ({
  placeholder,
  loading,
  disabled,
  onAdd,
  regex,
  errorMessage,
}: {
  placeholder?: string;
  loading?: boolean;
  disabled?: boolean;
  onAdd?: (value: string) => void;
  regex?: RegExp;
  errorMessage?: string;
}) => {
  const [value, setValue] = useState("");
  const handleApp = () => {
    if (onAdd && value.length > 0) {
      if (regex) {
        if (regex.test(value)) {
          onAdd(value);
          setValue("");
        }
      } else {
        onAdd(value);
        setValue("");
      }
    }
  };

  const hasError = regex ? !regex.test(value) : false;

  const saveIsdesabled =
    loading || disabled || value === "" || (regex && !regex.test(value));

  return (
    <div className="flex space-x-2 pt-2 w-full">
      <div className="flex-1">
        <div className="flex flex-col">
          <Input
            inputProps={{
              value,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                setValue(e.target.value),
            }}
            placeholder={placeholder}
            className="!h-9"
          />
          <AnimatePresence>
            {hasError && errorMessage && value !== "" && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
              >
                <p className="mt-1 text-xs text-red-600 dark:text-red-500">
                  {errorMessage}
                </p>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      <Button
        loading={loading}
        buttonProps={{
          disabled: saveIsdesabled,
          onClick: handleApp,
        }}
        contentClassName="w-7 py-[1.5px]"
        type="primary"
      >
        Add
      </Button>
    </div>
  );
};
export default AddressInput;
