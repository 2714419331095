import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  selectEnvironmentCreateIsOpen,
  selectEnvironmentCreateLoading,
  closeEnvCreateModal,
  getEnvironmentsAsync,
  createEnvironmentAsync,
} from "../../store/environments/environmentsSlice";
import Modal from "../modals/Modal";
import Input from "../inputs/Input";
import Button from "../buttons/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateEnvSchema } from "../../utils/validations";

const EnvironmentCreateModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectEnvironmentCreateIsOpen);
  const loading = useAppSelector(selectEnvironmentCreateLoading);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateEnvSchema),
  });

  const onSubmit = (data: any) => {
    dispatch(
      createEnvironmentAsync({
        Name: data.name,
      })
    ).then((action) => {
      if (action.type === "environments/create/fulfilled") {
        reset();
        dispatch(closeEnvCreateModal());
        dispatch(getEnvironmentsAsync());
      }
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        reset();
        dispatch(closeEnvCreateModal());
      }}
      contentClassName="max-w-lg"
      //   containerClassName="!items-start"
      title="Create new environment"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5">
          <Input
            inputProps={{ ...register("name") }}
            error={errors.name?.message}
            placeholder="Environment name..."
          />
        </div>

        <div className="mt-4 flex justify-end">
          <Button
            type="primary"
            buttonProps={{
              disabled: loading,
              type: "submit",
            }}
            loading={loading}
          >
            Create
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default EnvironmentCreateModal;
