import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isAxiosError } from "axios";
import toast from "react-hot-toast";
import { IThunkRejectValue, RootState } from "../../types";
import { ToastClasses } from "../../components/modals/alerts";
import {
  Web3AuthEndpoint,
  Web3AuthEndpointApiData,
  Web3sState,
} from "../../types/web3-auth";
import {
  createWeb3AuthEndpointApi,
  deleteWeb3AuthEndpointApi,
  getWeb3AuthEndpointsApi,
} from "../../apis/web3AuthAPI";
import { CustomErrorToast } from "../../components/general/Toast";
import { getExtractErrors } from "../../apis";

const initialState: Web3sState = {
  endpoints: [],
  loading: false,
  editorModal: false,
  selectedEndpoint: null,
  actionLoading: false,
};

export const getWeb3AuthEndpointsAsync = createAsyncThunk<
  { endpoints: Web3AuthEndpoint[] },
  undefined,
  IThunkRejectValue
>("all-web3-auth", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getWeb3AuthEndpointsApi();
    const endpoints = response.data.Result;

    return fulfillWithValue({ endpoints });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createWeb3AuthEndpointAsync = createAsyncThunk<
  any,
  Web3AuthEndpointApiData,
  IThunkRejectValue
>("web3-auth/create", async (data, { rejectWithValue }) => {
  try {
    const response = await createWeb3AuthEndpointApi(data);
    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const deleteWeb3AuthAsync = createAsyncThunk<
  any,
  { id: number },
  IThunkRejectValue
>("web3-auth/delete", async ({ id }, { rejectWithValue }) => {
  try {
    const response = await deleteWeb3AuthEndpointApi(id);
    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const web3AuthEndpointsSlice = createSlice({
  name: "web3Auth",
  initialState,
  reducers: {
    openWeb3AuthEditorModal: (
      state,
      action: PayloadAction<{ endpoint?: Web3AuthEndpoint }>
    ) => {
      state.editorModal = true;
      if (action.payload.endpoint)
        state.selectedEndpoint = action.payload.endpoint;
    },
    closeWeb3AuthModal: (state) => {
      state.editorModal = false;
      state.selectedEndpoint = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWeb3AuthEndpointsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWeb3AuthEndpointsAsync.fulfilled, (state, action) => {
        state.endpoints = action.payload.endpoints;
        state.loading = false;
      })
      .addCase(getWeb3AuthEndpointsAsync.rejected, (state, { payload }) => {
        state.loading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createWeb3AuthEndpointAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(createWeb3AuthEndpointAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(createWeb3AuthEndpointAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteWeb3AuthAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(deleteWeb3AuthAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(deleteWeb3AuthAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectWeb3AuthEndpoints = (state: RootState) =>
  state.web3AuthEndpoints.endpoints;
export const selectWeb3EndpointsLoading = (state: RootState) =>
  state.web3AuthEndpoints.loading;

export const selectActionLoading = (state: RootState) =>
  state.web3AuthEndpoints.actionLoading;

export const selectSelectedWeb3AuthEndpoint = (state: RootState) =>
  state.web3AuthEndpoints.selectedEndpoint;

export const selectShowEditorModal = (state: RootState) =>
  state.web3AuthEndpoints.editorModal;

export const { openWeb3AuthEditorModal, closeWeb3AuthModal } =
  web3AuthEndpointsSlice.actions;
export default web3AuthEndpointsSlice.reducer;
