import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import Modal from "../../../../modals/Modal";
import Input from "../../../../inputs/Input";
import Button from "../../../../buttons/Button";
import { useEffect, useState } from "react";
import { ReactComponent as ArchiveIcon } from "./../../../../../assets/icons/archive-box.svg";
import { ReactComponent as PlusIcon } from "./../../../../../assets/icons/plus.svg";
import Select, { SelectOption } from "../../../../inputs/Select";
import { selectBucketDetails } from "../../../../../store/s3/buckets/bucketSlice";
import Accordion from "../../../../general/Accordion";
import Switcher from "../../../../inputs/Switcher";

import {
  createBucketReplicationAsync,
  getBucketReplicationsAsync,
  getOneBucketReplicationsAsync,
  handleHideReplicationEditor,
  selectBucketReplicationActionLoading,
  selectReplication,
  selectReplications,
  selectSelectedReplication,
  selectShowBucketReplicationEditor,
  updateBucketReplicationAsync,
} from "../../../../../store/s3/buckets/setting/replicationsSlice";
import {
  S3CreatedReplication,
  S3ReplicationTag,
  S3UpdateReplication,
} from "../../../../../types/s3-replication";
import { S3ReplicationSchema } from "../../../../../utils/validations";
import { binarySize } from "../../../../../utils/file";

export const replicationModes: SelectOption<string>[] = [
  {
    label: "Asynchronous",
    value: "async",
  },
  {
    label: "Synchronous",
    value: "sync",
  },
];

export const bandWidthOptions: SelectOption<string>[] = [
  { label: "MB", value: "M" },
  { label: "GB", value: "G" },
  { label: "TB", value: "T" },
  { label: "PB", value: "P" },
  { label: "EB", value: "E" },
];

const S3ReplicationEditorModal = () => {
  const isOpen = useAppSelector(selectShowBucketReplicationEditor);
  const loading = useAppSelector(selectBucketReplicationActionLoading);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const dispatch = useAppDispatch();
  const selectedReplication = useAppSelector(selectSelectedReplication);
  const replication = useAppSelector(selectReplication);
  const replications = useAppSelector(selectReplications);

  const [selectedMode, setSelectedMode] = useState<SelectOption>(
    replicationModes[0]
  );
  const [selectedBandWidth, setSelectedBandWidth] = useState<
    SelectOption<string>
  >(bandWidthOptions[0]);
  const [useTLS, setUseTLS] = useState<boolean>(true);
  const [ruleState, setRuleState] = useState<boolean>(true);
  const [metadataSync, setMetadataSync] = useState<boolean>(true);
  const [deleteMarker, setDeleteMarker] = useState<boolean>(true);
  const [deletes, setDeletes] = useState<boolean>(true);
  const [replicateExistingObjects, setReplicateExistingObjects] =
    useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(S3ReplicationSchema(selectedReplication !== null)),
    shouldUnregister: true,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "tags",
    shouldUnregister: true,
  });

  useEffect(() => {
    if (isOpen && bucketDetails && selectedReplication) {
      dispatch(
        getOneBucketReplicationsAsync({
          name: bucketDetails.name,
          id: selectedReplication.id,
        })
      );
    }
  }, [isOpen, selectedReplication, dispatch]);

  const maxPriority = Math.max(...replications.map((r) => r.priority));

  useEffect(() => {
    if (isOpen) {
      setValue("priority", replications.length > 0 ? maxPriority + 1 : 1);
      setValue("bandwidth", 100);
      setValue("healthCheckPeriod", 60);
      setRuleState(true);
      setUseTLS(true);
      setDeleteMarker(true);
      setDeletes(true);
      setMetadataSync(true);
    }
  }, [isOpen, setValue]);

  useEffect(() => {
    if (isOpen && replication) {
      const tagsArray = replication.tags
        ? replication.tags.split("&").map((tag) => {
            const [key, value] = tag.split("=");
            return { key: key, value: value };
          })
        : [];

      setValue("tags", tagsArray as { key: string; value: string }[]);
      setValue("storageClass", replication.storageClass || "");
      setValue("arn", replication.destination.bucket);
      setValue("prefix", replication.prefix || "");
      setValue("priority", replication.priority);
      setMetadataSync(replication.metadata_replication);
      setDeletes(replication.deletes_replication);
      setDeleteMarker(replication.delete_marker_replication);
      setReplicateExistingObjects(replication.existingObjects);
      setRuleState(replication.status === "Enabled" ? true : false);
    }
  }, [isOpen, replication, setValue]);

  const handleClearComponent = () => {
    reset();
    setValue("targetBucket", "");
    setValue("prefix", "");
    setValue("priority", null);
    setValue("tags", [{ key: "", value: "" }]);
    setMetadataSync(false);
    setDeletes(false);
    setDeleteMarker(false);
  };

  const onSubmit = (data: any) => {
    const bandwidth = data.bandwidth as number;
    const bandwidthSize = binarySize(bandwidth, selectedBandWidth.value);
    if (selectedReplication && bucketDetails) {
      const tags = (data.tags as S3ReplicationTag[])
        .filter((tag) => tag.key !== "")
        .map((tag) => `${tag.key}=${tag.value}`)
        .join("&");
      const updatedData: S3UpdateReplication = {
        prefix: data.prefix,
        priority: data.priority,
        arn: data.arn,
        ruleState: ruleState,
        tags,
        storageClass: data.storageClass,
        replicateDeleteMarkers: deleteMarker,
        replicateDeletes: deletes,
        replicateExistingObjects: replicateExistingObjects,
        replicateMetadata: metadataSync,
      };

      dispatch(
        updateBucketReplicationAsync({
          bucketName: bucketDetails.name,
          data: updatedData,
          id: selectedReplication.id,
        })
      ).then((action) => {
        if (action.type === "bucket/replications/update/fulfilled") {
          handleClearComponent();
          dispatch(handleHideReplicationEditor());
          dispatch(getBucketReplicationsAsync({ name: bucketDetails.name }));
        }
      });
    } else {
      const tags = (data.tags as S3ReplicationTag[])
        .filter((tag) => tag.key !== "")
        .map((tag) => `${tag.key}=${tag.value}`)
        .join("&");
      if (bucketDetails) {
        const createdData: S3CreatedReplication = {
          accessKey: data.accessKey,
          secretKey: data.secretKey,
          targetURL: (useTLS ? "https://" : "http") + data.targetURL,
          region: data.region,
          bucketsRelation: [
            {
              originBucket: bucketDetails.name,
              destinationBucket: data.targetBucket,
            },
          ],
          syncMode: selectedMode.value as "async" | "sync",
          bandwidth: bandwidthSize,
          healthCheckPeriod: data.healthCheckPeriod,
          prefix: data.prefix,
          tags: tags,
          priority: data.priority,
          storageClass: data.storageClass,
          // replicateExistingObjects: replicateExistingObjects,
          replicateDeleteMarkers: deleteMarker,
          replicateDeletes: deletes,
          replicateMetadata: metadataSync,
        };

        console.log("createdData", createdData);

        dispatch(createBucketReplicationAsync({ data: createdData })).then(
          (action) => {
            if (action.type === "bucket/replications/create/fulfilled") {
              handleClearComponent();
              dispatch(handleHideReplicationEditor());
              dispatch(
                getBucketReplicationsAsync({ name: bucketDetails.name })
              );
            }
          }
        );
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleHideReplicationEditor());
        reset();
        handleClearComponent();
      }}
      contentClassName="max-w-lg"
      containerClassName="!items-start"
      title={
        selectedReplication
          ? "Update Bucket Replication"
          : "Set Bucket Replication"
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {selectedReplication && (
          <>
            <div className="mt-5 flex items-center justify-between w-full">
              <div className="flex gap-2 items-center">
                <div className="text-sm font-medium text-slate-800  dark:text-slate-200 mb-1">
                  Rule State
                </div>
              </div>
              <Switcher onToggle={setRuleState} on={ruleState} />
            </div>
            <div className="mt-5 flex flex-col w-full gap-5">
              <Input
                label="Destination"
                inputProps={{ ...register("arn"), type: "string" }}
                error={errors.arn?.message}
                placeholder=""
              />
            </div>
          </>
        )}

        <div className="mt-5 flex flex-col w-full gap-5">
          <Input
            label="Priority"
            inputProps={{ ...register("priority"), type: "number" }}
            error={errors.priority?.message}
            placeholder=""
            required
          />

          {!selectedReplication && (
            <>
              <Input
                label="Target URL"
                inputProps={{
                  ...register("targetURL"),
                  type: "string",
                }}
                error={errors.targetURL?.message}
                placeholder="play.min.io"
                hint={useTLS ? "https://" : "http://"}
                required
              />

              <div className="flex items-center justify-between w-full">
                <div className="flex gap-2 items-center">
                  <div className="text-sm font-medium text-slate-800  dark:text-slate-200 mb-1">
                    Use TLS
                  </div>
                </div>
                <Switcher onToggle={setUseTLS} on={useTLS} />
              </div>
              <Input
                label="Access Key"
                inputProps={{ ...register("accessKey"), type: "string" }}
                error={errors.accessKey?.message}
                placeholder=""
                required
              />
              <Input
                label="Secret Key"
                inputProps={{ ...register("secretKey"), type: "string" }}
                error={errors.secretKey?.message}
                placeholder=""
                required
              />
              <Input
                label="Target Bucket"
                inputProps={{
                  ...register("targetBucket"),
                  type: "string",
                  // onChange: handleTargetBucketChange,
                }}
                error={errors.targetBucket?.message}
                placeholder=""
                required
              />
              <Input
                label="Region"
                inputProps={{
                  ...register("region"),
                  type: "string",
                }}
                error={errors.region?.message}
                placeholder=""
              />
              <Select
                label="Replication Mode"
                options={replicationModes}
                selected={selectedMode}
                setSelected={(o) => o && setSelectedMode(o)}
              />

              <div className="">
                <div className="text-sm font-medium text-slate-800  dark:text-slate-200 mb-1">
                  Bandwidth
                </div>
                <div className="flex gap-1">
                  <div className="flex-1">
                    <Input
                      inputProps={{
                        ...register("bandwidth"),
                        type: "number",
                      }}
                      error={errors.bandwidth?.message}
                      placeholder=""
                    />
                  </div>
                  <div className="w-20">
                    <Select
                      options={bandWidthOptions}
                      selected={selectedBandWidth}
                      setSelected={(o) => o && setSelectedBandWidth(o)}
                    />
                  </div>
                </div>
              </div>

              <Input
                label="Health Check Duration"
                inputProps={{
                  ...register("healthCheckPeriod"),
                  type: "number",
                }}
                error={errors.healthCheckPeriod?.message}
                placeholder=""
                required
              />
            </>
          )}

          <Input
            label="Storage Class"
            inputProps={{ ...register("storageClass"), type: "string" }}
            error={errors.storageClass?.message}
            placeholder="STANDARD_IA,REDUCED_REDUNDANCY etc"
          />
          <div className="mt-5 w-full overflow-x-auto  min-h-[calc(100%-6rem)]">
            <Accordion
              items={[
                {
                  label: "Object Filters",
                  panel: (
                    <div className="">
                      <Input
                        label="Prefix"
                        inputProps={{ ...register("prefix") }}
                        error={errors.prefix?.message}
                      />
                      <div className="mt-2">
                        <label className="flex items-center gap-1 text-sm font-medium text-slate-800 dark:text-slate-50 whitespace-nowrap">
                          Tags
                        </label>
                        <div className="flex flex-col w-full">
                          {fields.map((_, index) => (
                            <div
                              key={index}
                              className="grid gap-3 grid-cols-12 pb-2"
                            >
                              <div className="col-span-4">
                                <Input
                                  label=""
                                  inputProps={{
                                    ...register(`tags.${index}.key` as const),
                                  }}
                                  error={
                                    errors.tags &&
                                    errors.tags[index] &&
                                    errors.tags[index]?.key &&
                                    errors.tags[index]?.key?.message
                                  }
                                  placeholder="Tag Key"
                                />
                              </div>
                              <div className="col-span-7">
                                <Input
                                  label=""
                                  inputProps={{
                                    ...register(`tags.${index}.value` as const),
                                  }}
                                  error={
                                    errors.tags &&
                                    errors.tags[index] &&
                                    errors.tags[index]?.value &&
                                    errors.tags[index]?.value?.message
                                  }
                                  placeholder="Tag Value"
                                />
                              </div>
                              <div className="col-span-1 flex items-start justify-center">
                                <Button
                                  type="icon"
                                  buttonProps={{
                                    onClick: (e) => {
                                      e.preventDefault();
                                      if (index !== 0) remove(index);
                                    },
                                    disabled: false,
                                  }}
                                  buttonClassName="mt-1.5"
                                >
                                  <ArchiveIcon className="w-5 text-slate-700 dark:text-slate-300 hover:text-red-500 hover:dark:text-red-400" />
                                </Button>
                              </div>
                            </div>
                          ))}
                          <div className="flex justify-end m-2">
                            <Button
                              type="light"
                              buttonProps={{
                                onClick: (event) => {
                                  event.preventDefault();
                                  append({
                                    key: "",
                                    value: "",
                                  });
                                },
                              }}
                            >
                              <PlusIcon className="w-4" />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ),
                },
              ]}
            />
          </div>
          <div className="mt-5 w-full flex overflow-x-auto  min-h-[calc(100%-6rem)]">
            <Accordion
              items={[
                {
                  label: "Replication Options",
                  panel: (
                    <div className=" ">
                      {selectedReplication && (
                        <div className="flex items-center justify-between w-full mt-2">
                          <div className="text-sm text-slate-700 dark:text-slate-200">
                            Existing Objects
                            <p className="text-sm text-slate-400 dark:text-gray-500 text-center">
                              Replicate existing objects
                            </p>
                          </div>

                          <Switcher
                            onToggle={setReplicateExistingObjects}
                            on={replicateExistingObjects}
                          />
                        </div>
                      )}

                      <div className="flex items-center justify-between w-full mt-2">
                        <div className="text-sm text-slate-700 dark:text-slate-200">
                          Metadata Sync
                          <p className="text-sm text-slate-400 dark:text-gray-500 text-center">
                            Metadata Sync
                          </p>
                        </div>

                        <Switcher
                          onToggle={setMetadataSync}
                          on={metadataSync}
                        />
                      </div>
                      <div className="flex items-center justify-between w-full mt-2">
                        <div className="text-sm text-slate-700 dark:text-slate-200">
                          Delete Marker
                          <p className="text-sm text-slate-400 dark:text-gray-500 text-center">
                            Replicate soft deletes
                          </p>
                        </div>

                        <Switcher
                          onToggle={setDeleteMarker}
                          on={deleteMarker}
                        />
                      </div>
                      <div className="flex items-center justify-between w-full mt-2">
                        <div className="text-sm text-slate-700 dark:text-slate-200">
                          Deletes
                          <p className="text-sm text-slate-400 dark:text-gray-500 text-center">
                            Replicate versioned deletes
                          </p>
                        </div>

                        <Switcher on={deletes} onToggle={setDeletes} />
                      </div>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            loading={loading}
            type="primary"
            buttonProps={{
              disabled: loading,
              type: "submit",
            }}
            buttonClassName="w-[100px]"
          >
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default S3ReplicationEditorModal;
