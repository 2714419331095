import { useAppSelector } from "../../../../hooks";
import {
  selectDataReceived,
  selectDataReceivedLoading,
} from "../../../../store/s3/overviewSlice";
import GraphWidget from "./freams/GraphWidget";
import { S3WidgetType } from "../../../../types/s3";

const chartType: S3WidgetType = "dataReceived";
const chartCode: number = 63;

const DataReceivedWidget = () => {
  const data = useAppSelector(selectDataReceived);
  const loading = useAppSelector(selectDataReceivedLoading);

  return (
    <GraphWidget
      title="API Data Received Rate"
      chartCode={chartCode}
      chartType={chartType}
      data={data}
      dataLoading={loading}
    />
  );
};

export default DataReceivedWidget;
