import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Text, { A } from "../../general/Text";
import Card from "../../general/Card";
import { ReactComponent as GithubIcon } from "./../../../assets/icons/socials/github.svg";
import { ReactComponent as GitlabIcon } from "./../../../assets/icons/socials/gitlab.svg";
import { ReactComponent as BitbucketIcon } from "./../../../assets/icons/socials/bitbucket.svg";
import { ReactComponent as GithubColorIcon } from "./../../../assets/icons/socials/github-color.svg";
import { ReactComponent as GitlabColorIcon } from "./../../../assets/icons/socials/gitlab-color.svg";
import { ReactComponent as DockerColorIcon } from "./../../../assets/icons/socials/docker-color.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as LinkIcon } from "./../../../assets/icons/arrow-top-right-on-square.svg";
import { Helmet } from "react-helmet";
import Button from "../../buttons/Button";
import NotData from "../../layouts/NotData";
import {
  getCredentialsAsync,
  openAddCredentialModal,
  removeCredentialAsync,
  selectCredentialRemoveLoading,
  selectCredentials,
  selectCredentialsLoading,
} from "../../../store/settings/registeries/registeriesSlice";
import AddCredentialModal from "./AddCredentialModal";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import QuestionModal from "../../modals/QuestionModal";
import { Credential } from "../../../types/registeries";
import { timeAgo } from "../../../utils/date";
import { useSearchParams } from "react-router-dom";
import {
  getRepositoriesAccountsAsync,
  selectGithubReposData,
  selectGithubReposLoading,
  selectRepoAccounts,
  selectRepoAccountsLoading,
  setGithubReposData,
  setGithubReposLoading,
  setInstallationData,
} from "../../../store/settings/registeries/repositoriesSlice";
import { getGithubRepos } from "../../../apis/repositoriesAPI";
import { selectOnStageEnv } from "../../../store/auth/authSlice";

const RegisteriesTab = () => {
  const env = useAppSelector(selectOnStageEnv);

  const credentials = useAppSelector(selectCredentials);
  const credentialsLoading = useAppSelector(selectCredentialsLoading);

  const [query] = useSearchParams();
  const installation_id = query.get("installation_id");
  // const setup_action = query.get("setup_action");
  // const source = query.get("source");

  //accounts
  const repoAccounts = useAppSelector(selectRepoAccounts);
  const repoAccountsLoading = useAppSelector(selectRepoAccountsLoading);
  const githubToken = repoAccounts.GithubAccessToken;
  const githubAccount = repoAccounts.GithubAccount;

  //github data
  const githubReposData = useAppSelector(selectGithubReposData);
  const githubReposLoading = useAppSelector(selectGithubReposLoading);
  const githubHtmlUrl = useMemo(() => {
    return githubAccount
      ? githubAccount.html_url
      : githubReposData && githubReposData.repositories.length > 0
      ? githubReposData.repositories[0].owner.html_url
      : "";
  }, [githubAccount, githubReposData]);
  const githubAvatarUrl = useMemo(() => {
    return githubAccount
      ? githubAccount.avatar_url
      : githubReposData && githubReposData.repositories.length > 0
      ? githubReposData.repositories[0].owner.avatar_url
      : "";
  }, [githubAccount, githubReposData]);
  const githubLogin = useMemo(() => {
    return githubAccount
      ? githubAccount.login
      : githubReposData && githubReposData.repositories.length > 0
      ? githubReposData.repositories[0].owner.login
      : "";
  }, [githubAccount, githubReposData]);

  const dispatch = useAppDispatch();

  const [deleteCredential, setDeleteCredential] = useState<Credential>();
  const credentialRemoveLoading = useAppSelector(selectCredentialRemoveLoading);

  //get credentials
  useEffect(() => {
    dispatch(getCredentialsAsync());
  }, [dispatch]);

  const handleInstallGithub = () => {
    window.location.href =
      "https://github.com/apps/djuno-web3-0-cloud/installations/new";
  };

  useEffect(() => {
    if (installation_id) {
      dispatch(
        setInstallationData({
          installationId: installation_id,
          installationModal: true,
        })
      );
    }
  }, [dispatch, installation_id]);

  // get github repos
  useEffect(() => {
    if (!installation_id && !repoAccountsLoading && !githubReposLoading) {
      if (repoAccounts.GithubAccessToken) {
        getGithubRepos(repoAccounts.GithubAccessToken).then((value) => {
          dispatch(setGithubReposLoading(true));
          if (value?.status === 200) {
            dispatch(setGithubReposData(value.data));
          }
        });
      } else {
        dispatch(setGithubReposData(null));
      }
    }
  }, [
    dispatch,
    githubReposLoading,
    installation_id,
    repoAccounts.GithubAccessToken,
    repoAccountsLoading,
  ]);

  useEffect(() => {
    dispatch(getRepositoriesAccountsAsync());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Registeries</title>
        <meta name="description" content="" />
      </Helmet>

      <div className="w-full flex flex-col gap-10">
        <Card
          title="Container Registery Credentials"
          description={
            <>
              Srore registery credentials to pull and deploy private images.{" "}
              <A href="https://blogs.djuno.io/privacy-notice" target="_blank">
                Learn more
              </A>
            </>
          }
          setting={
            <Button
              buttonProps={{
                onClick: () => dispatch(openAddCredentialModal()),
              }}
            >
              Add Credential
            </Button>
          }
        >
          <Table loading={credentialsLoading}>
            <TableHead>
              <TableRow>
                <TableTH lable="Name" />
                <TableTH lable="Registery" />
                <TableTH lable="Created" />
                <TableTH lable="" />
              </TableRow>
            </TableHead>
            <TableBody>
              {credentials.map((credential, i) => {
                const registeryData = getRegisteryData(
                  credential.RegisteryType
                );
                return (
                  <TableRow key={i}>
                    <TableTD>
                      <Text className="text-xs md:text-sm">
                        {credential.Name}
                      </Text>
                    </TableTD>
                    <TableTD>
                      {/* <Tooltip
                        clickable
                        className="!bg-gray-200"
                        content={registeryData.Description}
                      > */}
                      <div className="flex items-center gap-1">
                        {registeryData.Icon}
                        <Text className="text-xs md:text-sm">
                          {registeryData.Title}
                        </Text>
                      </div>
                      {/* </Tooltip> */}
                    </TableTD>
                    <TableTD>
                      <Text className="text-xs md:text-sm">
                        {timeAgo(credential.CreatedAt)}
                      </Text>
                    </TableTD>
                    <TableTD>
                      <div className="flex w-full justify-end">
                        {env?.IsAccountOwner && (
                          <div
                            onClick={() => setDeleteCredential(credential)}
                            className="p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200 cursor-pointer"
                          >
                            <ArchiveIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                          </div>
                        )}
                      </div>
                    </TableTD>
                  </TableRow>
                );
              })}
              {credentials.length === 0 && (
                <TableRow withoutHoverStyle={true}>
                  <TableTD colSpan={4} className="!border-none">
                    <NotData />
                  </TableTD>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Card>

        <Card title="Code repository">
          <div className="flex flex-col py-5 gap-4">
            <div className="flex items-center">
              <div className="w-32">
                <Text className="text-sm">Github</Text>
              </div>
              {githubAccount === null && githubToken === null && (
                <Button
                  loading={repoAccountsLoading || githubReposLoading}
                  buttonProps={{ onClick: handleInstallGithub }}
                >
                  <GithubIcon className="w-4" />
                  <Text type="transparent">Connect Github</Text>
                </Button>
              )}
              {githubToken !== null && (
                <A
                  href={githubHtmlUrl}
                  target="_blank"
                  className="flex items-center gap-1 text-slate-700 hover:text-slate-900 dark:text-slate-300 dark:hover:text-slate-200"
                >
                  <div className="-mr- w-7 h-7 bg-gray-200 bg-gray-300/30 dark:bg-gray-500/20 rounded-full flex items-center text-center justify-center overflow-hidden">
                    <img
                      alt={githubAccount?.login}
                      className="w-full"
                      src={githubAvatarUrl}
                    />
                  </div>
                  <Text className="text-sm font-semibold" type="transparent">
                    {githubLogin}
                  </Text>
                  <LinkIcon className="w-4" />
                  <Text type="subtext" className="text-xs ml-2">
                    {githubReposData?.total_count} repo
                    {githubReposData && githubReposData.total_count > 1 && "s"}
                  </Text>
                </A>
              )}
            </div>
            <div className="flex items-center">
              <div className="w-32">
                <Text className="text-sm">Bitbucket</Text>
              </div>
              <Button buttonProps={{ disabled: true }}>
                <BitbucketIcon className="w-4" />
                <Text type="transparent">Connect Bitbucket</Text>
              </Button>
            </div>
            <div className="flex items-center">
              <div className="w-32">
                <Text className="text-sm">Gitlab</Text>
              </div>
              <Button buttonProps={{ disabled: true }}>
                <GitlabIcon className="w-4" />
                <Text type="transparent">Connect Gitlab</Text>
              </Button>
            </div>
          </div>
        </Card>
        <AddCredentialModal />
        <QuestionModal
          title="Remove Credential"
          isOpen={deleteCredential !== undefined}
          onClose={() => setDeleteCredential(undefined)}
          confirmButtonType="danger"
          onConfirm={() => {
            deleteCredential &&
              dispatch(removeCredentialAsync({ id: deleteCredential.Id })).then(
                (action) => {
                  if (
                    action.type === "registeries/credentials/remove/fulfilled"
                  ) {
                    dispatch(getCredentialsAsync());
                    setDeleteCredential(undefined);
                  }
                }
              );
          }}
          loading={credentialRemoveLoading}
        />
      </div>
    </>
  );
};

const GithubDesc = (
  <Text className="text-sm">
    You'll need to create a personal access token with the required{" "}
    <Text className="!inline-block" type="neutral-alert">
      read:packages
    </Text>{" "}
    permission in{" "}
    <A
      target="_blank"
      href="https://github.com/settings/tokens/new?description=&scopes=read%3Apackages"
    >
      GitHub
    </A>{" "}
    <Text className="font-semibold inline-block">{`Settings > Developer Settings`}</Text>
  </Text>
);

const GitlabDesc = (
  <Text className="text-sm">
    You'll need to create a personal access token with the required{" "}
    <Text className="!inline-block" type="neutral-alert">
      read registery
    </Text>{" "}
    permission in{" "}
    <A
      target="_blank"
      href="https://gitlab.com/-/profile/personal_access_tokens?name=&scopes=read_registry"
    >
      GitLab
    </A>{" "}
    by navigating to{" "}
    <Text className="font-semibold inline-block">{`Edit Profile > Access Tokens`}</Text>
  </Text>
);

const DockerDesc = (
  <Text className="text-sm gap-1">
    Enter your Docker Hub username and password. or create an access token with{" "}
    <Text className="!inline-block" type="neutral-alert">
      read
    </Text>{" "}
    access permission to use in place of your password by visiting{" "}
    <A
      target="_blank"
      href="https://hub.docker.com/settings/security?generateToken=true"
    >
      Docker Hub
    </A>{" "}
    <Text className="font-semibold !inline-block">{`Account Settings> Security`}</Text>
  </Text>
);

export const getRegisteryData = (typeValue: number) => {
  let data = {
    Title: "Github",
    Icon: <GithubColorIcon className="w-4" />,
    Description: GithubDesc,
  };
  if (typeValue === 1) {
    data = {
      Title: "Gitlab",
      Icon: <GitlabColorIcon className="w-4" />,
      Description: GitlabDesc,
    };
  } else if (typeValue === 2) {
    data = {
      Title: "Docker",
      Icon: <DockerColorIcon className="w-4" />,
      Description: DockerDesc,
    };
  }
  return data;
};
export default RegisteriesTab;
