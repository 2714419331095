import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  handleAddToSelectedDestination,
  handleRemoveToSelectedDestination,
  selectSelectedDestinations,
} from "../../../../store/block-event/blockEventCreateSlice";
import {
  getDestinationsAsync,
  handleShowDestinationEditor,
  selectDestinations,
  selectDestinationsLoading,
} from "../../../../store/block-event/destinationsSlice";
import Button from "../../../buttons/Button";
import Accordion from "../../../general/Accordion";
import Text from "../../../general/Text";
import Switcher from "../../../inputs/Switcher";
import DestinationEditorModal from "../../destinations/DestinationEditorModal";
import { ReactComponent as PlusIcon } from "./../../../../assets/icons/plus.svg";

const SelectDestinationStep: React.FC<{ withoutHeader?: boolean }> = ({
  withoutHeader,
}) => {
  const destinations = useAppSelector(selectDestinations);
  const destinationsLoading = useAppSelector(selectDestinationsLoading);

  const selectedDestinations = useAppSelector(selectSelectedDestinations);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getDestinationsAsync());
  }, [dispatch]);

  const DestinationsAccordion = () => {
    return (
      <Accordion
        loading={destinationsLoading}
        items={[
          ...destinations.map((destination) => ({
            label: (
              <div className="flex items-center justify-between w-full pr-5">
                <Text>{destination.Name}</Text>
                <Switcher
                  on={
                    selectedDestinations.find(
                      (sd) => sd.Id === destination.Id
                    ) !== undefined
                  }
                  onToggle={(status) => {
                    if (status) {
                      dispatch(handleAddToSelectedDestination(destination));
                    } else {
                      dispatch(handleRemoveToSelectedDestination(destination));
                    }
                  }}
                />
              </div>
            ),
            panel: (
              <div>
                <Text className="text-sm">{destination.To_url}</Text>
              </div>
            ),
          })),
        ]}
      />
    );
  };
  if (withoutHeader) return <DestinationsAccordion />;
  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center justify-between gap-1 md:gap-2 w-full">
        <Text className="font-medium">Select Destination</Text>
        <Button
          type="primary"
          buttonProps={{
            onClick: () => dispatch(handleShowDestinationEditor({})),
          }}
        >
          Create Destination
          <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
        </Button>
      </div>
      <div className="mt-5">
        <DestinationsAccordion />
      </div>
      <DestinationEditorModal />
    </div>
  );
};

export default SelectDestinationStep;
