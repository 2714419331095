import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isAxiosError } from "axios";
import toast from "react-hot-toast";
import { IThunkRejectValue, RootState } from "../../types";
import { ToastClasses } from "../../components/modals/alerts";
import {
  Web3AuthApiKey,
  Web3AuthEndpoint,
  Web3AuthEndpointState,
  Web3AuthEndpointUser,
  Web3AuthEndpointUserData,
} from "../../types/web3-auth";
import {
  createWeb3AuthEndpointApiKeyApi,
  createWeb3AuthEndpointUsersDataApi,
  deleteWeb3AuthEndpointApiKeyApi,
  deleteWeb3AuthEndpointUsersDataApi,
  getWeb3AuthEndpointApi,
  getWeb3AuthEndpointApiKeysApi,
  getWeb3AuthEndpointUsersApi,
  getWeb3AuthEndpointUsersDataApi,
  refreshWeb3AuthEncryptionKeyApi,
  updateWeb3AuthEndpointApi,
  updateWeb3AuthEndpointApiKeyApi,
} from "../../apis/web3AuthAPI";
import { getExtractErrorCode, getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";

const initialState: Web3AuthEndpointState = {
  endpoint: null,
  endpointLoading: false,
  endpointUpdateLoading: false,
  users: [],
  usersLoading: false,
  selectedUser: null,
  showUserDataInfoModal: false,
  actionLoading: false, //user-datas,api-keys
  usersData: [],
  usersDataLoading: false,
  apiKeys: [],
  apiKeysLoading: false,
  selectedApiKey: null,
  showApiKeyEditor: false,
  userData: null,
};

export const getWeb3AuthEndpointAsync = createAsyncThunk<
  { endpoint: Web3AuthEndpoint },
  string,
  IThunkRejectValue
>("web3-auth/endpoint", async (id, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getWeb3AuthEndpointApi(id);
    const endpoint = response.data.Result;

    return fulfillWithValue({ endpoint });
  } catch (e) {
    return rejectWithValue({
      message: getExtractErrors(e),
      code: getExtractErrorCode(e),
    });
  }
});

export const updateWeb3AuthEndpointAsync = createAsyncThunk<
  any,
  { id: number; data: any },
  IThunkRejectValue
>("web3-auth/endpoint/update", async ({ id, data }, { rejectWithValue }) => {
  try {
    const response = await updateWeb3AuthEndpointApi(id, data);
    return response.data;
  } catch (e) {
    return rejectWithValue({
      message: getExtractErrors(e),
    });
  }
});

export const refreshWeb3AuthEncryptionKeyAsync = createAsyncThunk<
  any,
  { id: number },
  IThunkRejectValue
>(
  "web3-auth/endpoint/encryption-key/update",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await refreshWeb3AuthEncryptionKeyApi(id);
      return response.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getWeb3AuthEndpointUsersAsync = createAsyncThunk<
  { users: Web3AuthEndpointUser[] },
  string,
  IThunkRejectValue
>(
  "web3-auth/endpoint/users",
  async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getWeb3AuthEndpointUsersApi(id);
      const users = response.data.Result;

      return fulfillWithValue({ users });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getWeb3AuthEndpointUsersDataAsync = createAsyncThunk<
  { usersData: Web3AuthEndpointUserData[] },
  string,
  IThunkRejectValue
>(
  "web3-auth/endpoint/users-data",
  async (endpointId, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getWeb3AuthEndpointUsersDataApi(endpointId);
      const usersData = response.data.Result;

      return fulfillWithValue({ usersData });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const createWeb3AuthEndpointUsersDataAsync = createAsyncThunk<
  boolean,
  { endpointId: string; data: any },
  { rejectValue: { message: string } }
>(
  "web3-auth/endpoint/users-data/create",
  async ({ endpointId, data }, { rejectWithValue }) => {
    try {
      await createWeb3AuthEndpointUsersDataApi(endpointId, data);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteWeb3AuthEndpointUsersDataAsync = createAsyncThunk<
  any,
  { endpointId: number; userDataId: number },
  IThunkRejectValue
>(
  "web3-auth/endpoint/users-data/delete",
  async ({ endpointId, userDataId }, { rejectWithValue }) => {
    try {
      const response = await deleteWeb3AuthEndpointUsersDataApi(
        endpointId,
        userDataId
      );
      return response.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getWeb3AuthEndpointApiKeysAsync = createAsyncThunk<
  { apiKeys: Web3AuthApiKey[] },
  string,
  IThunkRejectValue
>(
  "web3-auth/endpoint/api-keys",
  async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getWeb3AuthEndpointApiKeysApi(id);
      const apiKeys = response.data.Result;

      return fulfillWithValue({ apiKeys });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteWeb3AuthEndpointApiKeyAsync = createAsyncThunk<
  any,
  { endpointId: number; id: number },
  IThunkRejectValue
>(
  "web3-auth/endpoint/api-keys/delete",
  async ({ endpointId, id }, { rejectWithValue }) => {
    try {
      const response = await deleteWeb3AuthEndpointApiKeyApi(endpointId, id);
      return response.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const createWeb3AuthEndpointApiKeyAsync = createAsyncThunk<
  any,
  { endpointId: number; data: any },
  IThunkRejectValue
>(
  "web3-auth/endpoint/api-keys/create",
  async ({ endpointId, data }, { rejectWithValue }) => {
    try {
      const response = await createWeb3AuthEndpointApiKeyApi(endpointId, data);
      return response.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const updateWeb3AuthEndpointApiKeyAsync = createAsyncThunk<
  any,
  { endpointId: number; id: number; data: any },
  IThunkRejectValue
>(
  "web3-auth/endpoint/api-keys/update",
  async ({ endpointId, id, data }, { rejectWithValue }) => {
    try {
      const response = await updateWeb3AuthEndpointApiKeyApi(
        endpointId,
        id,
        data
      );
      return response.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const web3AuthEndpointSlice = createSlice({
  name: "web3AuthEndpoint",
  initialState,
  reducers: {
    handleSetEndpoint: (state, action: PayloadAction<Web3AuthEndpoint>) => {
      state.endpoint = action.payload;
    },
    handleShowApiKeyEditor: (
      state,
      action: PayloadAction<{ apiKey?: Web3AuthApiKey }>
    ) => {
      state.showApiKeyEditor = true;
      if (action.payload.apiKey) {
        state.selectedApiKey = action.payload.apiKey;
      }
    },
    handleHideApiKeyEditor: (state) => {
      state.showApiKeyEditor = false;
      state.selectedApiKey = null;
    },
    handleUserDataInfoModal: (
      state,
      action: PayloadAction<{ user?: Web3AuthEndpointUser; status?: boolean }>
    ) => {
      state.showUserDataInfoModal = action.payload.status || false;
      if (action.payload.user) {
        state.selectedUser = action.payload.user;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWeb3AuthEndpointAsync.pending, (state) => {
        state.endpointLoading = true;
      })
      .addCase(getWeb3AuthEndpointAsync.fulfilled, (state, action) => {
        state.endpoint = action.payload.endpoint;
        state.endpointLoading = false;
      })
      .addCase(getWeb3AuthEndpointAsync.rejected, (state, { payload }) => {
        state.endpointLoading = false;
        if (payload?.code !== 404)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateWeb3AuthEndpointAsync.pending, (state) => {
        state.endpointUpdateLoading = true;
      })
      .addCase(updateWeb3AuthEndpointAsync.fulfilled, (state) => {
        state.endpointUpdateLoading = false;
        toast.success("App updated successfully!", {
          className: ToastClasses,
        });
      })
      .addCase(updateWeb3AuthEndpointAsync.rejected, (state, { payload }) => {
        state.endpointUpdateLoading = false;

        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(refreshWeb3AuthEncryptionKeyAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(refreshWeb3AuthEncryptionKeyAsync.fulfilled, (state) => {
        state.actionLoading = false;
        toast.success("Key refreshed successfully!", {
          className: ToastClasses,
        });
      })
      .addCase(
        refreshWeb3AuthEncryptionKeyAsync.rejected,
        (state, { payload }) => {
          state.actionLoading = false;
          toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(getWeb3AuthEndpointUsersAsync.pending, (state) => {
        state.usersLoading = true;
      })
      .addCase(getWeb3AuthEndpointUsersAsync.fulfilled, (state, action) => {
        state.users = action.payload.users;
        state.usersLoading = false;
      })
      .addCase(getWeb3AuthEndpointUsersAsync.rejected, (state, { payload }) => {
        state.usersLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getWeb3AuthEndpointUsersDataAsync.pending, (state) => {
        state.usersDataLoading = true;
      })
      .addCase(getWeb3AuthEndpointUsersDataAsync.fulfilled, (state, action) => {
        state.usersData = action.payload.usersData;
        state.usersDataLoading = false;
      })
      .addCase(
        getWeb3AuthEndpointUsersDataAsync.rejected,
        (state, { payload }) => {
          state.usersDataLoading = false;
          toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(createWeb3AuthEndpointUsersDataAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(createWeb3AuthEndpointUsersDataAsync.fulfilled, (state) => {
        state.actionLoading = false;
        toast.success("Data created successfully!", {
          className: ToastClasses,
        });
      })
      .addCase(
        createWeb3AuthEndpointUsersDataAsync.rejected,
        (state, { payload }) => {
          state.actionLoading = false;
          toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(deleteWeb3AuthEndpointUsersDataAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(deleteWeb3AuthEndpointUsersDataAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(
        deleteWeb3AuthEndpointUsersDataAsync.rejected,
        (state, { payload }) => {
          state.actionLoading = false;
          toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(getWeb3AuthEndpointApiKeysAsync.pending, (state) => {
        state.apiKeysLoading = true;
      })
      .addCase(getWeb3AuthEndpointApiKeysAsync.fulfilled, (state, action) => {
        state.apiKeys = action.payload.apiKeys;
        state.apiKeysLoading = false;
      })
      .addCase(
        getWeb3AuthEndpointApiKeysAsync.rejected,
        (state, { payload }) => {
          state.apiKeysLoading = false;
          toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(deleteWeb3AuthEndpointApiKeyAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(deleteWeb3AuthEndpointApiKeyAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(
        deleteWeb3AuthEndpointApiKeyAsync.rejected,
        (state, { payload }) => {
          state.actionLoading = false;
          toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(createWeb3AuthEndpointApiKeyAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(createWeb3AuthEndpointApiKeyAsync.fulfilled, (state) => {
        state.actionLoading = false;
        toast.success("Data created successfully!", {
          className: ToastClasses,
        });
      })
      .addCase(
        createWeb3AuthEndpointApiKeyAsync.rejected,
        (state, { payload }) => {
          state.actionLoading = false;
          toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(updateWeb3AuthEndpointApiKeyAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(updateWeb3AuthEndpointApiKeyAsync.fulfilled, (state) => {
        state.actionLoading = false;
        toast.success("Data updated successfully!", {
          className: ToastClasses,
        });
      })
      .addCase(
        updateWeb3AuthEndpointApiKeyAsync.rejected,
        (state, { payload }) => {
          state.actionLoading = false;
          toast.error(() => CustomErrorToast(payload?.message));
        }
      );
  },
});

export const selectWeb3AuthEndpointActionLoading = (state: RootState) =>
  state.web3AuthEndpoint.actionLoading;

export const selectWeb3AuthEndpoint = (state: RootState) =>
  state.web3AuthEndpoint.endpoint;
export const selectWeb3AuthEndpointLoading = (state: RootState) =>
  state.web3AuthEndpoint.endpointLoading;
export const selectWeb3AuthEndpointUpdateLoading = (state: RootState) =>
  state.web3AuthEndpoint.endpointUpdateLoading;

export const selectWeb3AuthEndpointUsers = (state: RootState) =>
  state.web3AuthEndpoint.users;
export const selectWeb3AuthEndpointUsersLoading = (state: RootState) =>
  state.web3AuthEndpoint.usersLoading;

export const selectWeb3AuthShowUserDataInfoModal = (state: RootState) =>
  state.web3AuthEndpoint.showUserDataInfoModal;
export const selectWeb3AuthSelectedUser = (state: RootState) =>
  state.web3AuthEndpoint.selectedUser;

export const selectWeb3AuthEndpointUsersData = (state: RootState) =>
  state.web3AuthEndpoint.usersData;
export const selectWeb3AuthEndpointUsersDataLoading = (state: RootState) =>
  state.web3AuthEndpoint.usersDataLoading;

export const selectWeb3AuthEndpointApiKeys = (state: RootState) =>
  state.web3AuthEndpoint.apiKeys;
export const selectWeb3AuthEndpointApiKeysLoading = (state: RootState) =>
  state.web3AuthEndpoint.apiKeysLoading;
export const selectShowWeb3ApiKeyEditor = (state: RootState) =>
  state.web3AuthEndpoint.showApiKeyEditor;
export const selectSelectedWeb3ApiKey = (state: RootState) =>
  state.web3AuthEndpoint.selectedApiKey;

export const {
  handleSetEndpoint,
  handleShowApiKeyEditor,
  handleHideApiKeyEditor,
  handleUserDataInfoModal,
} = web3AuthEndpointSlice.actions;
export default web3AuthEndpointSlice.reducer;
