// import { BlockEventsPlansBox } from "../../plans/BlockEventsPlansBox";
import { UniversalPlansBox } from "../../plans/UniversalPlansBox";

const BlockEventPlansTab = () => {
  return (
    <>
      {/* <BlockEventsPlansBox /> */}
      <UniversalPlansBox />
    </>
  );
};

export default BlockEventPlansTab;
