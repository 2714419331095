import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Instance,
  InstanceBackup,
  InstanceFlavor,
  InstanceImage,
  InstanceLoginInformation,
  InstanceModel,
  InstanceName,
  InstanceReinstallImage,
  InstanceSnapshot,
  InstanceState,
  InstanceVnc,
} from "../../types/instance";
import { IThunkRejectValue, RootState } from "../../types";
import {
  applicationAccessInstanceApi,
  bootInstanceApi,
  createAutomaticBackupInstanceApi,
  createBackupInstanceApi,
  createInstanceAutomatedBackupApi,
  deleteInstanceBackupWorkflowProcessApi,
  deleteInstanceBackupWorkflowApi,
  getBackupsInstanceApi,
  getInstanceApi,
  getInstanceEnableBackupApi,
  getInstanceSnapshotApi,
  getInstanceSnapshotDetailsApi,
  getInstanceVncApi,
  getInstancesOneFlavorApi,
  instancesAttachVolumeApi,
  reactiveInstanceApi,
  rebootInstanceApi,
  reinstallInstanceImageApi,
  rescueModeInstanceApi,
  stopInstanceApi,
  suspendInstanceApi,
  updateInstanceModelApi,
  updateInstanceNameApi,
  deleteInstanceBackupSnapshotApi,
  updateInstanceMonthlyBillingApi,
} from "../../apis/instancesAPI";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import toast from "react-hot-toast";

const initialState: InstanceState = {
  instance: null,
  instanceLoading: false,

  instanceActionloading: false, //attach

  instanceFlavor: null,
  instanceFlavorLoading: false,

  instanceVnc: null,
  instanceVncLoading: false,

  instanceShowModal: false,
  instanceShowStopModal: false,
  instanceShowRebootModal: false,
  instanceShowHotModal: false,
  instanceShowColdModal: false,
  instanceShowSuspendModal: false,
  instanceShowReinstallModal: false,
  instanceShowRescueModal: false,
  instanceShowBootModal: false,
  instanceShowReactiveModal: false,
  instanceShowNameModal: false,

  //backups
  instaceBackups: [],
  instaceBackupsLoading: false,
  instaceBackup: null,
  instaceBackupLoading: false,
  instanceBackupActionLoading: false,

  instanceShowDeleteModal: false,
  instanceShowVolumeModal: false,
  instanceShowApplicationModal: false,

  loginInformation: null,
  loginInformationLoading: false,

  instanceSnapshots: [],
  instanceSnapshotsloading: false,

  instanceSnapshot: null,
  instanceSnapshotloading: false,
};

export const getInstanceAsync = createAsyncThunk<
  { instance: Instance },
  { instanceId: string; withoutLoading?: boolean },
  IThunkRejectValue
>(
  "instance",
  async (
    { instanceId, withoutLoading = false },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    // try {
    //   const response = await getInstanceApi(instanceId);
    //   const instance = response.data.Result;
    //   return fulfillWithValue({ instance });
    // } catch (e) {
    //   return rejectWithValue({ message: getExtractErrors(e) });
    // }
    try {
      dispatch(
        getInstanceAsync.pending(requestId, {
          instanceId,
          withoutLoading,
        })
      );

      const response = await getInstanceApi(instanceId);
      const instance = response.data.Result;

      return fulfillWithValue({ instance });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getInstanceOneFlavorAsync = createAsyncThunk<
  { instanceFlavor: InstanceFlavor },
  { flavorId: string },
  IThunkRejectValue
>(
  "instance/flavor",
  async ({ flavorId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getInstancesOneFlavorApi(flavorId);
      const instanceFlavor = response.data.Result;
      return fulfillWithValue({ instanceFlavor });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getInstanceVncAsync = createAsyncThunk<
  { vnc: InstanceVnc },
  { instanceId: string },
  IThunkRejectValue
>(
  "instance/vnc",
  async ({ instanceId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getInstanceVncApi(instanceId);
      const vnc = response.data.Result;
      return fulfillWithValue({ vnc });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const updateInstanceNameAsync = createAsyncThunk<
  any,
  { instanceId: string; data: InstanceName },
  IThunkRejectValue
>("instance/update", async ({ instanceId, data }, { rejectWithValue }) => {
  try {
    const response = await updateInstanceNameApi(instanceId, data);
    return response;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const updateInstanceModelAsync = createAsyncThunk<
  any,
  { instanceId: string; data: InstanceModel },
  IThunkRejectValue
>("instance/resize", async ({ instanceId, data }, { rejectWithValue }) => {
  try {
    const response = await updateInstanceModelApi(instanceId, data);
    return response;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const updateInstanceMonthlyBillingAsync = createAsyncThunk<
  any,
  { instanceId: string },
  IThunkRejectValue
>(
  "instance/update-billing-type",
  async ({ instanceId }, { rejectWithValue }) => {
    try {
      const response = await updateInstanceMonthlyBillingApi(instanceId);
      return response;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const reinstallInstanceImageAsync = createAsyncThunk<
  any,
  { instanceId: string; data: any },
  IThunkRejectValue
>("instance/reinstall", async ({ instanceId, data }, { rejectWithValue }) => {
  try {
    const response = await reinstallInstanceImageApi(instanceId, data);
    return response;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createBackupInstanceAsync = createAsyncThunk<
  any,
  { instanceId: string; data: any },
  IThunkRejectValue
>("instance/backup", async ({ instanceId, data }, { rejectWithValue }) => {
  try {
    const response = await createBackupInstanceApi(instanceId, data);
    return response;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const stopInstanceAsync = createAsyncThunk<
  any,
  { instanceId: string; data: any },
  IThunkRejectValue
>("instance/stop", async ({ instanceId, data }, { rejectWithValue }) => {
  try {
    const response = await stopInstanceApi(instanceId, data);
    return response;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const rescueModeInstanceAsync = createAsyncThunk<
  any,
  { instanceId: string; data: any },
  IThunkRejectValue
>("instance/rescue", async ({ instanceId, data }, { rejectWithValue }) => {
  try {
    const response = await rescueModeInstanceApi(instanceId, data);
    return response;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const rebootInstanceAsync = createAsyncThunk<
  any,
  { instanceId: string; data: any },
  IThunkRejectValue
>("instance/reboot", async ({ instanceId, data }, { rejectWithValue }) => {
  try {
    const response = await rebootInstanceApi(instanceId, data);
    return response;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const suspendInstanceAsync = createAsyncThunk<
  any,
  { instanceId: string; data: any },
  IThunkRejectValue
>("instance/shelve", async ({ instanceId, data }, { rejectWithValue }) => {
  try {
    const response = await suspendInstanceApi(instanceId, data);
    return response;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const bootInstanceAsync = createAsyncThunk<
  any,
  { instanceId: string; data: any },
  IThunkRejectValue
>("instance/boot", async ({ instanceId, data }, { rejectWithValue }) => {
  try {
    const response = await bootInstanceApi(instanceId, data);
    return response;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const reactiveInstanceAsync = createAsyncThunk<
  any,
  { instanceId: string; data: any },
  IThunkRejectValue
>("instance/unshelve", async ({ instanceId, data }, { rejectWithValue }) => {
  try {
    const response = await reactiveInstanceApi(instanceId, data);
    return response;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createAutomaticBackupInstanceAsync = createAsyncThunk<
  any,
  { instanceId: string; regionName: string; data: any },
  IThunkRejectValue
>(
  "instance/automatic-backup",
  async ({ instanceId, regionName, data }, { rejectWithValue }) => {
    try {
      const response = await createAutomaticBackupInstanceApi(regionName, data);
      return response;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getBackupsInstanceAsync = createAsyncThunk<
  { backups: InstanceBackup[] },
  { regionName: string },
  IThunkRejectValue
>(
  "instance/backups",
  async ({ regionName }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getBackupsInstanceApi(regionName);
      const backups = response.data.Result;
      return fulfillWithValue({ backups });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteInstanceBackupWorkflowProcessAsync = createAsyncThunk<
  true,
  { regionName: string; backupWorkflowId: string },
  IThunkRejectValue
>(
  "instance/backup/delete",
  async ({ regionName, backupWorkflowId }, { rejectWithValue }) => {
    try {
      await deleteInstanceBackupWorkflowProcessApi(
        regionName,
        backupWorkflowId
      );
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const instancesAttachVolumeAsync = createAsyncThunk<
  boolean,
  { volumeId: string; data: any },
  IThunkRejectValue
>("instance/volume/attach", async ({ volumeId, data }, { rejectWithValue }) => {
  try {
    await instancesAttachVolumeApi(volumeId, data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const applicationAccessInstanceAsync = createAsyncThunk<
  { loginInformation: InstanceLoginInformation },
  { instanceId: string; data: any },
  IThunkRejectValue
>(
  "instance/applicationAccess",
  async ({ instanceId, data }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await applicationAccessInstanceApi(instanceId, data);
      const loginInformation = response.data.Result;
      return fulfillWithValue({ loginInformation });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const createInstanceAutomatedBackupAsync = createAsyncThunk<
  boolean,
  { regionName: string; data: any },
  IThunkRejectValue
>(
  "instance/automated/backup",
  async ({ regionName, data }, { rejectWithValue }) => {
    try {
      await createInstanceAutomatedBackupApi(regionName, data);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);
export const deleteInstanceBackupWorkflowAsync = createAsyncThunk<
  true,
  { regionName: string; backupWorkflowId: string },
  IThunkRejectValue
>(
  "instance/backup/workflow/delete",
  async ({ regionName, backupWorkflowId }, { rejectWithValue }) => {
    try {
      await deleteInstanceBackupWorkflowApi(regionName, backupWorkflowId);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getInstanceSnapshotAsync = createAsyncThunk<
  { snapshots: InstanceSnapshot[] },
  { instanceId: string },
  IThunkRejectValue
>(
  "instances/snapshot",
  async ({ instanceId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getInstanceSnapshotApi(instanceId);
      const snapshotsObj = response.data.Result;
      const snapshots: InstanceSnapshot[] = Object.values(snapshotsObj);
      return fulfillWithValue({ snapshots });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);
export const getInstanceSnapshotDtailsAsync = createAsyncThunk<
  { snapshot: InstanceSnapshot },
  { instanceId: string; imageId: string },
  IThunkRejectValue
>(
  "instances/snapshot/details",
  async ({ instanceId, imageId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getInstanceSnapshotDetailsApi(instanceId, imageId);
      const snapshot = response.data.Result;
      return fulfillWithValue({ snapshot });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);
export const deleteInstanceBackupSnapshotAsync = createAsyncThunk<
  true,
  { instanceId: string; imageId: string },
  IThunkRejectValue
>(
  "instance/snapshot/delete",
  async ({ instanceId, imageId }, { rejectWithValue }) => {
    try {
      await deleteInstanceBackupSnapshotApi(instanceId, imageId);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);
export const getInstanceEnableBackupAsync = createAsyncThunk<
  { enableBackup: InstanceBackup },
  { instanceId: string; regionName: string },
  IThunkRejectValue
>(
  "instances/enable/backup",
  async ({ instanceId, regionName }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getInstanceEnableBackupApi(instanceId, regionName);
      const enableBackup = response.data.Result;
      return fulfillWithValue({ enableBackup });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

const instanceSlice = createSlice({
  name: "instance",
  initialState,
  reducers: {
    handleInstanceShowModal: (state, action: PayloadAction<Instance>) => {
      state.instanceShowModal = true;
      state.instance = action.payload;
    },
    handleInstanceHideModal: (state) => {
      state.instanceShowModal = false;
      // state.instance = null;
    },
    handleInstanceStopShowModal: (state, action: PayloadAction<Instance>) => {
      state.instanceShowStopModal = true;
      state.instance = action.payload;
    },
    handleInstanceStopHideModal: (state) => {
      state.instanceShowStopModal = false;
      // state.instance = null;
    },
    handleInstanceRebootShowModal: (state, action: PayloadAction<Instance>) => {
      state.instanceShowRebootModal = true;
      state.instance = action.payload;
    },
    handleInstanceRebootHideModal: (state) => {
      state.instanceShowRebootModal = false;
      // state.instance = null;
    },
    handleInstanceHotShowModal: (state, action: PayloadAction<Instance>) => {
      state.instanceShowHotModal = true;
      state.instance = action.payload;
    },
    handleInstanceHotHideModal: (state) => {
      state.instanceShowHotModal = false;
      // state.instance = null;
    },
    handleInstanceColdShowModal: (state, action: PayloadAction<Instance>) => {
      state.instanceShowColdModal = true;
      state.instance = action.payload;
    },
    handleInstanceColdHideModal: (state) => {
      state.instanceShowColdModal = false;
      // state.instance = null;
    },
    handleInstanceSuspendShowModal: (
      state,
      action: PayloadAction<Instance>
    ) => {
      state.instanceShowSuspendModal = true;
      state.instance = action.payload;
    },
    handleInstanceSuspendHideModal: (state) => {
      state.instanceShowSuspendModal = false;
      // state.instance = null;
    },
    handleInstanceReinstallShowModal: (
      state,
      action: PayloadAction<Instance>
    ) => {
      state.instanceShowReinstallModal = true;
      state.instance = action.payload;
    },
    handleInstanceReinstallHideModal: (state) => {
      state.instanceShowReinstallModal = false;
      // state.instance = null;
    },
    handleInstanceRescueShowModal: (state, action: PayloadAction<Instance>) => {
      state.instanceShowRescueModal = true;
      state.instance = action.payload;
    },
    handleInstanceRescueHideModal: (state) => {
      state.instanceShowRescueModal = false;
      // state.instance = null;
    },
    handleInstanceBootShowModal: (state, action: PayloadAction<Instance>) => {
      state.instanceShowBootModal = true;
      state.instance = action.payload;
    },
    handleInstanceBootHideModal: (state) => {
      state.instanceShowBootModal = false;
      // state.instance = null;
    },
    handleInstanceReactiveShowModal: (
      state,
      action: PayloadAction<Instance>
    ) => {
      state.instanceShowReactiveModal = true;
      state.instance = action.payload;
    },
    handleInstanceReactiveHideModal: (state) => {
      state.instanceShowReactiveModal = false;
      // state.instance = null;
    },
    handleInstanceNameShowModal: (state, action: PayloadAction<Instance>) => {
      state.instanceShowNameModal = true;
      state.instance = action.payload;
    },
    handleInstanceNameHideModal: (state) => {
      state.instanceShowNameModal = false;
      // state.instance = null;
    },
    handleInstanceDeleteShowModal: (state, action: PayloadAction<Instance>) => {
      state.instanceShowDeleteModal = true;
      state.instance = action.payload;
    },
    handleInstanceDeleteHideModal: (state) => {
      state.instanceShowDeleteModal = false;
      // state.instance = null;
    },
    handleInstanceVolumeShowModal: (state, action: PayloadAction<Instance>) => {
      state.instanceShowVolumeModal = true;
      state.instance = action.payload;
    },
    handleInstanceVolumeHideModal: (state) => {
      state.instanceShowVolumeModal = false;
    },
    handleInstanceApplicationLoginShowModal: (
      state,
      action: PayloadAction<Instance>
    ) => {
      state.instanceShowApplicationModal = true;
      state.instance = action.payload;
    },
    handleInstanceApplicationLoginHideModal: (state) => {
      state.instanceShowApplicationModal = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInstanceAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.instanceLoading = true;
      })
      .addCase(getInstanceAsync.fulfilled, (state, action) => {
        const { instance } = action.payload;
        state.instance = instance;
        state.instanceLoading = false;
      })
      .addCase(getInstanceAsync.rejected, (state, { payload }) => {
        state.instanceLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(getInstanceOneFlavorAsync.pending, (state) => {
        state.instanceFlavorLoading = true;
      })
      .addCase(getInstanceOneFlavorAsync.fulfilled, (state, action) => {
        const { instanceFlavor } = action.payload;
        state.instanceFlavor = instanceFlavor;
        state.instanceFlavorLoading = false;
      })
      .addCase(getInstanceOneFlavorAsync.rejected, (state, { payload }) => {
        state.instanceFlavorLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(getInstanceVncAsync.pending, (state) => {
        state.instanceVncLoading = true;
      })
      .addCase(getInstanceVncAsync.fulfilled, (state, action) => {
        const { vnc } = action.payload;
        state.instanceVnc = vnc;
        state.instanceVncLoading = false;
      })
      .addCase(getInstanceVncAsync.rejected, (state, { payload }) => {
        state.instanceVncLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(updateInstanceNameAsync.pending, (state) => {
        state.instanceLoading = true;
      })
      .addCase(updateInstanceNameAsync.fulfilled, (state) => {
        state.instanceLoading = false;
      })
      .addCase(updateInstanceNameAsync.rejected, (state, { payload }) => {
        state.instanceLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(updateInstanceModelAsync.pending, (state) => {
        state.instanceLoading = true;
      })
      .addCase(updateInstanceModelAsync.fulfilled, (state) => {
        state.instanceLoading = false;
      })
      .addCase(updateInstanceModelAsync.rejected, (state, { payload }) => {
        state.instanceLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateInstanceMonthlyBillingAsync.pending, (state) => {
        state.instanceLoading = true;
      })
      .addCase(updateInstanceMonthlyBillingAsync.fulfilled, (state) => {
        state.instanceLoading = false;
      })
      .addCase(
        updateInstanceMonthlyBillingAsync.rejected,
        (state, { payload }) => {
          state.instanceLoading = false;
          toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(reinstallInstanceImageAsync.pending, (state) => {
        state.instanceLoading = true;
      })
      .addCase(reinstallInstanceImageAsync.fulfilled, (state) => {
        state.instanceLoading = false;
      })
      .addCase(reinstallInstanceImageAsync.rejected, (state, { payload }) => {
        state.instanceLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(createBackupInstanceAsync.pending, (state) => {
        state.instanceLoading = true;
      })
      .addCase(createBackupInstanceAsync.fulfilled, (state) => {
        state.instanceLoading = false;
      })
      .addCase(createBackupInstanceAsync.rejected, (state, { payload }) => {
        state.instanceLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(stopInstanceAsync.pending, (state) => {
        state.instanceLoading = true;
      })
      .addCase(stopInstanceAsync.fulfilled, (state) => {
        state.instanceLoading = false;
      })
      .addCase(stopInstanceAsync.rejected, (state, { payload }) => {
        state.instanceLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(rescueModeInstanceAsync.pending, (state) => {
        state.instanceLoading = true;
      })
      .addCase(rescueModeInstanceAsync.fulfilled, (state) => {
        state.instanceLoading = false;
      })
      .addCase(rescueModeInstanceAsync.rejected, (state, { payload }) => {
        state.instanceLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(rebootInstanceAsync.pending, (state) => {
        state.instanceLoading = true;
      })
      .addCase(rebootInstanceAsync.fulfilled, (state) => {
        state.instanceLoading = false;
      })
      .addCase(rebootInstanceAsync.rejected, (state, { payload }) => {
        state.instanceLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(suspendInstanceAsync.pending, (state) => {
        state.instanceLoading = true;
      })
      .addCase(suspendInstanceAsync.fulfilled, (state) => {
        state.instanceLoading = false;
      })
      .addCase(suspendInstanceAsync.rejected, (state, { payload }) => {
        state.instanceLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(bootInstanceAsync.pending, (state) => {
        state.instanceLoading = true;
      })
      .addCase(bootInstanceAsync.fulfilled, (state) => {
        state.instanceLoading = false;
      })
      .addCase(bootInstanceAsync.rejected, (state, { payload }) => {
        state.instanceLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(reactiveInstanceAsync.pending, (state) => {
        state.instanceLoading = true;
      })
      .addCase(reactiveInstanceAsync.fulfilled, (state) => {
        state.instanceLoading = false;
      })
      .addCase(reactiveInstanceAsync.rejected, (state, { payload }) => {
        state.instanceLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(createAutomaticBackupInstanceAsync.pending, (state) => {
        state.instanceLoading = true;
      })
      .addCase(createAutomaticBackupInstanceAsync.fulfilled, (state) => {
        state.instanceLoading = false;
      })
      .addCase(
        createAutomaticBackupInstanceAsync.rejected,
        (state, { payload }) => {
          state.instanceLoading = false;
          toast.error(() => CustomErrorToast(payload?.message));
        }
      );
    builder
      .addCase(getBackupsInstanceAsync.pending, (state) => {
        state.instaceBackupsLoading = true;
      })
      .addCase(getBackupsInstanceAsync.fulfilled, (state, action) => {
        const { backups } = action.payload;
        state.instaceBackups = backups;
        state.instaceBackupsLoading = false;
      })
      .addCase(getBackupsInstanceAsync.rejected, (state, { payload }) => {
        state.instaceBackupsLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteInstanceBackupWorkflowProcessAsync.pending, (state) => {
        state.instanceBackupActionLoading = true;
      })
      .addCase(deleteInstanceBackupWorkflowProcessAsync.fulfilled, (state) => {
        state.instanceBackupActionLoading = false;
        state.instaceBackup = null;
      })

      .addCase(
        deleteInstanceBackupWorkflowProcessAsync.rejected,
        (state, { payload }) => {
          state.instanceBackupActionLoading = false;
          toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(instancesAttachVolumeAsync.pending, (state) => {
        state.instanceActionloading = true;
      })
      .addCase(instancesAttachVolumeAsync.fulfilled, (state) => {
        state.instanceActionloading = false;
      })
      .addCase(instancesAttachVolumeAsync.rejected, (state, { payload }) => {
        state.instanceActionloading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(applicationAccessInstanceAsync.pending, (state) => {
        state.loginInformationLoading = true;
      })
      .addCase(applicationAccessInstanceAsync.fulfilled, (state, action) => {
        const { loginInformation } = action.payload;
        state.loginInformation = loginInformation;
        state.loginInformationLoading = false;
      })
      .addCase(
        applicationAccessInstanceAsync.rejected,
        (state, { payload }) => {
          state.loginInformationLoading = false;
          toast.error(() => CustomErrorToast(payload?.message));
        }
      );
    builder
      .addCase(createInstanceAutomatedBackupAsync.pending, (state) => {
        state.instanceLoading = true;
      })
      .addCase(createInstanceAutomatedBackupAsync.fulfilled, (state) => {
        state.instanceLoading = false;
      })
      .addCase(
        createInstanceAutomatedBackupAsync.rejected,
        (state, { payload }) => {
          state.instanceLoading = false;
          toast.error(() => CustomErrorToast(payload?.message));
        }
      );
    builder
      .addCase(deleteInstanceBackupWorkflowAsync.pending, (state) => {
        state.instanceLoading = true;
      })
      .addCase(deleteInstanceBackupWorkflowAsync.fulfilled, (state) => {
        state.instanceLoading = false;
      })
      .addCase(
        deleteInstanceBackupWorkflowAsync.rejected,
        (state, { payload }) => {
          state.instanceLoading = false;
          toast.error(() => CustomErrorToast(payload?.message));
        }
      );
    builder
      .addCase(getInstanceSnapshotAsync.pending, (state) => {
        state.instanceSnapshotsloading = true;
      })
      .addCase(getInstanceSnapshotAsync.fulfilled, (state, action) => {
        const { snapshots } = action.payload;
        state.instanceSnapshots = snapshots;
        state.instanceSnapshotsloading = false;
      })
      .addCase(getInstanceSnapshotAsync.rejected, (state, { payload }) => {
        state.instanceSnapshotsloading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(getInstanceSnapshotDtailsAsync.pending, (state) => {
        state.instanceSnapshotloading = true;
      })
      .addCase(getInstanceSnapshotDtailsAsync.fulfilled, (state, action) => {
        const { snapshot } = action.payload;
        state.instanceSnapshot = snapshot;
        state.instanceSnapshotloading = false;
      })
      .addCase(
        getInstanceSnapshotDtailsAsync.rejected,
        (state, { payload }) => {
          state.instanceSnapshotloading = false;
          toast.error(() => CustomErrorToast(payload?.message));
        }
      );
    builder
      .addCase(deleteInstanceBackupSnapshotAsync.pending, (state) => {
        state.instanceSnapshotloading = true;
      })
      .addCase(deleteInstanceBackupSnapshotAsync.fulfilled, (state) => {
        state.instanceSnapshotloading = false;
      })
      .addCase(
        deleteInstanceBackupSnapshotAsync.rejected,
        (state, { payload }) => {
          state.instanceSnapshotloading = false;
          toast.error(() => CustomErrorToast(payload?.message));
        }
      );
    builder
      .addCase(getInstanceEnableBackupAsync.pending, (state) => {
        state.instaceBackupLoading = true;
      })
      .addCase(getInstanceEnableBackupAsync.fulfilled, (state, action) => {
        const { enableBackup } = action.payload;
        state.instaceBackup = enableBackup;
        state.instaceBackupLoading = false;
      })
      .addCase(getInstanceEnableBackupAsync.rejected, (state, { payload }) => {
        state.instaceBackupLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectInstance = (state: RootState) => state.instance.instance;
export const selectInstanceLoading = (state: RootState) =>
  state.instance.instanceLoading;

export const selectInstanceActionLoading = (state: RootState) =>
  state.instance.instanceActionloading;

export const selectInstanceFlavor = (state: RootState) =>
  state.instance.instanceFlavor;
export const selectInstanceFlavorLoading = (state: RootState) =>
  state.instance.instanceFlavorLoading;

export const selectInstanceVnc = (state: RootState) =>
  state.instance.instanceVnc;
export const selectInstanceVncLoading = (state: RootState) =>
  state.instance.instanceVncLoading;

export const selectShowModal = (state: RootState) =>
  state.instance.instanceShowModal;
export const selectShowStopModal = (state: RootState) =>
  state.instance.instanceShowStopModal;
export const selectShowRebootModal = (state: RootState) =>
  state.instance.instanceShowRebootModal;
export const selectShowHotModal = (state: RootState) =>
  state.instance.instanceShowHotModal;
export const selectShowColdModal = (state: RootState) =>
  state.instance.instanceShowColdModal;
export const selectShowSuspendModal = (state: RootState) =>
  state.instance.instanceShowSuspendModal;
export const selectShowReinstallModal = (state: RootState) =>
  state.instance.instanceShowReinstallModal;
export const selectShowRescueModal = (state: RootState) =>
  state.instance.instanceShowRescueModal;
export const selectShowBootModal = (state: RootState) =>
  state.instance.instanceShowBootModal;
export const selectShowReactiveModal = (state: RootState) =>
  state.instance.instanceShowReactiveModal;
export const selectShowNameModal = (state: RootState) =>
  state.instance.instanceShowNameModal;
export const selectShowVolumeModal = (state: RootState) =>
  state.instance.instanceShowVolumeModal;
export const selectShowApplicationLoginModal = (state: RootState) =>
  state.instance.instanceShowApplicationModal;

export const selectInstanceBackups = (state: RootState) =>
  state.instance.instaceBackups;
export const selectInstanceBackupsLoading = (state: RootState) =>
  state.instance.instaceBackupsLoading;
export const selectInstanceBackup = (state: RootState) =>
  state.instance.instaceBackup;
export const selectInstanceBackupLoading = (state: RootState) =>
  state.instance.instaceBackupLoading;

export const selectShowDeleteModal = (state: RootState) =>
  state.instance.instanceShowDeleteModal;

export const selectInstanceLoginInformation = (state: RootState) =>
  state.instance.loginInformation;
export const selectInstanceLoginInformationLoading = (state: RootState) =>
  state.instance.loginInformationLoading;

export const selectInstanceSnapshops = (state: RootState) =>
  state.instance.instanceSnapshots;
export const selectInstanceSnapshopsLoading = (state: RootState) =>
  state.instance.instanceSnapshotsloading;

export const selectInstanceSnapshop = (state: RootState) =>
  state.instance.instanceSnapshot;
export const selectInstanceSnapshopLoading = (state: RootState) =>
  state.instance.instanceSnapshotloading;

export const selectInstanceBackupActionLoading = (state: RootState) =>
  state.instance.instanceBackupActionLoading;

export const {
  handleInstanceHideModal,
  handleInstanceShowModal,
  handleInstanceStopShowModal,
  handleInstanceStopHideModal,
  handleInstanceRebootShowModal,
  handleInstanceRebootHideModal,
  handleInstanceHotHideModal,
  handleInstanceHotShowModal,
  handleInstanceColdHideModal,
  handleInstanceColdShowModal,
  handleInstanceSuspendHideModal,
  handleInstanceSuspendShowModal,
  handleInstanceReinstallHideModal,
  handleInstanceReinstallShowModal,
  handleInstanceRescueHideModal,
  handleInstanceRescueShowModal,
  handleInstanceBootHideModal,
  handleInstanceBootShowModal,
  handleInstanceReactiveHideModal,
  handleInstanceReactiveShowModal,
  handleInstanceNameHideModal,
  handleInstanceNameShowModal,
  handleInstanceDeleteHideModal,
  handleInstanceDeleteShowModal,
  handleInstanceVolumeHideModal,
  handleInstanceVolumeShowModal,
  handleInstanceApplicationLoginHideModal,
  handleInstanceApplicationLoginShowModal,
} = instanceSlice.actions;
export default instanceSlice.reducer;
