import Text from "../../components/general/Text";
import { Helmet } from "react-helmet";
import { TabOptions } from "../../types/tabs";
import Tabs from "../../components/general/Tabs";
import { Outlet } from "react-router-dom";
import { ReactComponent as ListBulletIcon } from "./../../assets/icons/list-bullet.svg";
import { ReactComponent as CubeIcon } from "./../../assets/icons/cube-transparent.svg";
import { Web3AuthEndpointsUrl, Web3AuthPlansUrl } from "../../utils/urls";
import Web3AuthEndpointsTab from "../../components/web3-auth/endpoint/Web3AuthEndpintsTab";
import Web3AuthPlansTab from "../../components/web3-auth/plans/Web3AuthPlansTab";

export const Web3AuthEndpontsPageMenus: TabOptions = [
  {
    label: (
      <div className="flex items-center gap-1">
        <CubeIcon className="w-3" />
        Endpoints
      </div>
    ),
    url: Web3AuthEndpointsUrl,
    element: <Web3AuthEndpointsTab />,
    testId: "1th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <ListBulletIcon className="w-3" />
        Plans
      </div>
    ),
    url: Web3AuthPlansUrl,
    element: <Web3AuthPlansTab />,
    testId: "2th-tab",
  },
];

const Web3AuthEndpintsPage = () => {
  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Web3-auth</title>
        <meta
          name="description"
          content="web3-auth refers to authentication mechanisms used within Web3 applications, which are decentralized applications built on blockchain technology. Web3-auth facilitates user authentication and authorization processes within these decentralized applications"
        />
      </Helmet>
      <div className="flex items-center justify-between h-20">
        <div className="px-6 items-center justify-between flex flex-1 transition duration-150">
          <Text className="font-medium text-lg">Web3 Auth Endpoints</Text>
        </div>
        {/* <div className="mr-6 w-96"></div> */}
      </div>
      <div className="mx-6">
        <Tabs
          useUrl
          options={Web3AuthEndpontsPageMenus.map((o) => ({
            label: o.label,
            url: o.url,
            testId: o.testId,
          }))}
        />
        <div className="py-6">
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default Web3AuthEndpintsPage;
