import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createS3EventAsync,
  getS3EventsAsync,
  selectActionLoading,
  selectLoading,
  selectSelectedS3Event,
} from "../../../../store/s3/eventsSlice";
import Button from "../../../buttons/Button";
import { S3NatsEventSchema } from "../../../../utils/validations";
import Input from "../../../inputs/Input";
import { useEffect, useState } from "react";
import Textarea from "../../../inputs/Textarea";
import Text from "../../../general/Text";
import Switcher from "../../../inputs/Switcher";
import { InfoTooltip } from "../../../general/Tooltip";
import { S3EventsUrl } from "../../../../utils/urls";
import { useNavigate } from "react-router-dom";
import useGetS3Events from "../../../../hooks/useGetS3Events";

const NatsForm = () => {
  const event = useAppSelector(selectSelectedS3Event);
  const loading = useAppSelector(selectActionLoading);
  const eventsLoading = useAppSelector(selectLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getS3Events = useGetS3Events();

  const [enableTLS, setEnableTLS] = useState<boolean>(false);
  const [enableTlsSkip, setEnableTlsSkip] = useState<boolean>(false);
  const [enableStreaming, setEnableStreaming] = useState<boolean>(false);
  const [enableStreamingAsync, setEnableStreamingAsync] =
    useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(S3NatsEventSchema),
  });

  useEffect(() => {
    if (event) {
    } else {
    }
  }, [event, setValue]);

  const onSubmit = (data: any) => {
    let keyValues = [
      {
        key: "address",
        value: data.address,
      },
      {
        key: "subject",
        value: data.subject,
      },
      {
        key: "username",
        value: data.username,
      },
      {
        key: "password",
        value: data.password,
      },
      {
        key: "token",
        value: data.token,
      },
      {
        key: "tls",
        value: enableTLS ? "on" : "off",
      },
      {
        key: "tls_skip_verify",
        value: enableTlsSkip ? "on" : "off",
      },
      {
        key: "ping_interval",
        value: data.ping_interval,
      },
      {
        key: "streaming",
        value: enableStreaming ? "on" : "off",
      },
      {
        key: "streaming_async",
        value: enableStreamingAsync ? "on" : "off",
      },
      {
        key: "streaming_max_pub_acks_in_flight",
        value: data.streaming_max_pub_acks_in_flight,
      },
      {
        key: "streaming_cluster_id",
        value: data.streaming_cluster_id,
      },
      {
        key: "cert_authority",
        value: data.cert_authority,
      },
      {
        key: "client_cert",
        value: data.client_cert,
      },
      {
        key: "client_key",
        value: data.client_key,
      },
      {
        key: "queue_dir",
        value: data.queue_dir,
      },
      {
        key: "comment",
        value: data.comment,
      },
    ];
    if (data.queue_limit !== null) {
      keyValues = [
        ...keyValues,
        {
          key: "queue_limit",
          value: data.queue_limit.toString(),
        },
      ];
    }
    const formData = {
      key_values: keyValues,
    };
    // if (event) {
    // dispatch(
    //   updateS3EventAsync({
    //     id: event.Id,
    //     data: {
    //       label: data.label,
    //     },
    //   })
    // ).then((action) => {
    //   if (action.type === "s3-events/update/fulfilled") {
    //     // handleClearComponent();
    //     dispatch(handleHideEditor());
    //     dispatch(getS3EventsAsync());
    //     reset();
    //   }
    // });
    // } else {
    dispatch(
      createS3EventAsync({
        event: "nats",
        identifier: data.identifier,
        data: formData,
      })
    ).then((action) => {
      if (action.type === "s3-events/create/fulfilled") {
        getS3Events(() => {
          reset();
          navigate(S3EventsUrl);
        });
      }
    });
    // }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col w-full gap-5">
        <Input
          label="Identifier"
          tooltip="Unique descriptive string for this destination"
          placeholder="Enter Destination Identifier"
          inputProps={{ ...register("identifier") }}
          error={errors.identifier?.message}
          required
        />

        <Input
          label="Address"
          tooltip="NATS server address e.g. '0.0.0.0:4222'"
          placeholder="Enter Address"
          inputProps={{ ...register("address") }}
          error={errors.address?.message}
        />

        <Input
          label="Subject"
          tooltip="NATS subscription subject"
          placeholder="Enter NATS Subject"
          inputProps={{ ...register("subject") }}
          error={errors.subject?.message}
        />

        <Input
          label="Username"
          tooltip="NATS username"
          placeholder="Enter NATS Username"
          inputProps={{ ...register("username") }}
          error={errors.username?.message}
        />

        <Input
          label="SPassword"
          tooltip="NATS password"
          placeholder="Enter NATS Password"
          inputProps={{ ...register("password") }}
          error={errors.password?.message}
        />

        <Input
          label="Token"
          tooltip="NATS token"
          inputProps={{ ...register("token") }}
          error={errors.token?.message}
        />

        <div className="flex items-center justify-between w-full">
          <div className="flex gap-1 items-center">
            <Text type="title" className="text-sm !font-medium">
              TLS
            </Text>
            <InfoTooltip content="Set to 'on' to enable TLS" />
          </div>
          <Switcher on={enableTLS} onToggle={setEnableTLS} />
        </div>

        <div className="flex items-center justify-between w-full">
          <div className="flex gap-1 items-center">
            <Text type="title" className="text-sm !font-medium">
              TLS Skip Verify
            </Text>
            <InfoTooltip content="Trust server TLS without verification, defaults to 'on' (verify)" />
          </div>
          <Switcher on={enableTlsSkip} onToggle={setEnableTlsSkip} />
        </div>

        <Input
          label="Ping Interval"
          tooltip="Client ping commands interval in s,m,h,d. Disabled by default"
          placeholder="ِEnter Ping Interval"
          inputProps={{ ...register("ping_interval") }}
          error={errors.ping_interval?.message}
        />

        <div className="flex items-center justify-between w-full">
          <div className="flex gap-1 items-center">
            <Text type="title" className="text-sm !font-medium">
              Streaming
            </Text>
            <InfoTooltip content="Set to 'on' to use streming NATS server" />
          </div>
          <Switcher on={enableStreaming} onToggle={setEnableStreaming} />
        </div>

        <div className="flex items-center justify-between w-full">
          <div className="flex gap-1 items-center">
            <Text type="title" className="text-sm !font-medium">
              Streaming async
            </Text>
            <InfoTooltip content="Set to 'on' to enable asynchronous publish" />
          </div>
          <Switcher
            on={enableStreamingAsync}
            onToggle={setEnableStreamingAsync}
          />
        </div>

        <Input
          label="Streaming max publish ACKS in flight"
          tooltip="Number of messages to publish without waiting for ACKs"
          placeholder="ِEnter Streaming in flight value"
          inputProps={{ ...register("streaming_max_pub_acks_in_flight") }}
          error={errors.streaming_max_pub_acks_in_flight?.message}
        />

        <Input
          label="Streaming Cluster ID"
          tooltip="Unique ID for NATS streaming cluster"
          placeholder="ِEnter Streaming Cluster ID"
          inputProps={{ ...register("streaming_cluster_id") }}
          error={errors.streaming_cluster_id?.message}
        />

        <Input
          label="Cert Authority"
          tooltip="Path to certificate chain of the target NATS server"
          placeholder="ِEnter Cert Authority"
          inputProps={{ ...register("cert_authority") }}
          error={errors.cert_authority?.message}
        />

        <Input
          label="Client Cert"
          tooltip="Client cert for NATS mTLS auth"
          placeholder="ِEnter Client Cert"
          inputProps={{ ...register("client_cert") }}
          error={errors.client_cert?.message}
        />

        <Input
          label="Client Key"
          tooltip="Client cert key for NATS mTLS authorization"
          placeholder="ِEnter Client Key"
          inputProps={{ ...register("client_key") }}
          error={errors.client_key?.message}
        />

        <Input
          label="Queue Directory"
          tooltip="Staging directory for undelivered messages e.g. '/home/events'"
          placeholder="ِEnter Queue Directory"
          inputProps={{ ...register("queue_dir") }}
          error={errors.queue_dir?.message}
        />

        <Input
          label="Queue Limit"
          tooltip="Maximum limit for undelivered messages, defaults to '10000'"
          placeholder="ِEnter Queue Limit"
          inputProps={{ ...register("queue_limit"), type: "number" }}
          error={errors.queue_limit?.message}
        />

        <Textarea
          label="Comment"
          placeholder="Enter custom notes if any"
          textareaProps={{ ...register("comment") }}
          error={errors.comment?.message}
        />
      </div>
      <div className="mt-4 flex justify-end">
        <Button
          loading={loading || eventsLoading}
          type="primary"
          buttonProps={{
            disabled: loading || eventsLoading,
            type: "submit",
          }}
          buttonClassName="w-[160px]"
        >
          {event ? "Update Evet Target" : "Save Evet Target"}
        </Button>
      </div>
    </form>
  );
};
export default NatsForm;
