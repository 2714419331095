import { useAppSelector } from "../../../../hooks";
import {
  selectNodeCPU,
  selectNodeCpuLoading,
} from "../../../../store/s3/overviewSlice";
import GraphWidget from "./freams/GraphWidget";
import { S3WidgetType } from "../../../../types/s3";

const chartType: S3WidgetType = "nodeCPU";
const chartCode: number = 77;

const NodeCpuWidget = () => {
  const data = useAppSelector(selectNodeCPU);
  const loading = useAppSelector(selectNodeCpuLoading);

  return (
    <GraphWidget
      title="Node CPU Usage"
      chartCode={chartCode}
      chartType={chartType}
      data={data}
      dataLoading={loading}
    />
  );
};

export default NodeCpuWidget;
