import { useAppSelector } from "../../../../hooks";
import {
  selectDataUsage,
  selectDataUsageLoading,
} from "../../../../store/s3/overviewSlice";
import GraphWidget from "./freams/GraphWidget";
import { S3WidgetType } from "../../../../types/s3";

const chartType: S3WidgetType = "dataUsage";
const chartCode: number = 68;

const DataUsageWidget = () => {
  const data = useAppSelector(selectDataUsage);
  const loading = useAppSelector(selectDataUsageLoading);

  return (
    <GraphWidget
      title="Data Usage Growth"
      chartCode={chartCode}
      chartType={chartType}
      data={data}
      dataLoading={loading}
      chartDateFormat="HH:mm:ss"
      modalDateFormat="MM/DD HH:mm:ss"
      showMonitor={false}
      type="area"
    />
  );
};

export default DataUsageWidget;
