import Highlighter from "react-highlight-words";
import { BlockEventDestination } from "../../../types/block-events";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import Text from "../../general/Text";
import NotData from "../../layouts/NotData";

const BlockEventDestinationsTable: React.FC<{
  destinations: BlockEventDestination[];
  loading?: boolean;
  rowCallback?: (destination: BlockEventDestination) => void;
  searchValue?: string;
  actionsRendered?: (destination: BlockEventDestination) => React.ReactNode;
}> = ({ destinations, loading, rowCallback, searchValue, actionsRendered }) => {
  return (
    <Table loading={loading}>
      <TableHead>
        <TableRow>
          <TableTH lable="Name" />
          <TableTH lable="URL" />
          <TableTH lable="Alerts" />
          <TableTH lable="" />
        </TableRow>
      </TableHead>
      <TableBody>
        {destinations.map((destination, index) => (
          <TableRow
            key={index}
            onClick={() => {
              rowCallback && rowCallback(destination);
            }}
            className="cursor-pointer"
          >
            <TableTD className="w-36">
              <Highlighter
                highlightClassName="bg-primary-100 dark:bg-primary-300 rounded-sm"
                className="whitespace-nowrap max-w-[110px] md:max-w-none text-ellipsis overflow-hidden text-sm font-medium"
                searchWords={[searchValue || ""]}
                autoEscape={true}
                textToHighlight={destination.Name}
              />
            </TableTD>
            <TableTD>
              <Text className="text-sm">{destination.To_url}</Text>
            </TableTD>
            <TableTD>
              {/* TODO */}
              <Text className="text-sm">0</Text>
            </TableTD>
            <TableTD className="w-32">
              {actionsRendered && actionsRendered(destination)}
            </TableTD>
          </TableRow>
        ))}
        {destinations.length === 0 && (
          <TableRow withoutHoverStyle={true}>
            <TableTD colSpan={4} className="!border-0">
              <NotData text="You have not created any destination yet" />
            </TableTD>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
export default BlockEventDestinationsTable;
