import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  InstanceBillingSchema,
  InstanceModelSchema,
} from "../../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getInstanceAsync,
  selectInstance,
  selectInstanceLoading,
  updateInstanceModelAsync,
  updateInstanceMonthlyBillingAsync,
} from "../../../store/instances/instanceSlice";
import { useEffect, useMemo, useState } from "react";
import {
  getInstancesAsync,
  getInstancesFlavorsAsync,
  selectInstancesFlavors,
  selectInstancesFlavorsLoading,
} from "../../../store/instances/instancesSlice";
import {
  getDBSCatalogAsync,
  selectDBSCatalog,
} from "../../../store/database/servicesSlice";
import { makeBillingPeriodsOptions, makeModels } from "../create/utils";
import { DBSAddon } from "../../../types/database";
import Button from "../../buttons/Button";
import { InstanceBillingInput, InstanceModelInput } from "../create/components";
import toast from "react-hot-toast";
import { CustomToast } from "../../general/Toast";
import { InstanceBillingPeriod } from "../../../types/instance";

const InstanceRescaleTab = () => {
  const instance = useAppSelector(selectInstance);
  const instanceLoading = useAppSelector(selectInstanceLoading);

  const flavors = useAppSelector(selectInstancesFlavors);
  const flavorsLoading = useAppSelector(selectInstancesFlavorsLoading);

  const catalog = useAppSelector(selectDBSCatalog);
  const [addons, setAddons] = useState<Array<DBSAddon>>([]);

  const dispatch = useAppDispatch();

  const modelForm = useForm({
    resolver: yupResolver(InstanceModelSchema),
    mode: "all",
  });

  const billingForm = useForm({
    resolver: yupResolver(InstanceBillingSchema),
    mode: "all",
  });

  const {
    handleSubmit: handleSubmitModel,
    formState: { errors: modelErrors },
    setValue: setModelValue,
    control: modelControl,
    watch: watchModel,
    reset: resetModel,
  } = modelForm;

  const {
    handleSubmit: handleSubmitBilling,
    formState: { errors: billingErrors },
    setValue: setBillingValue,
    control: billingControl,
    watch: watchBilling,
  } = billingForm;

  useEffect(() => {
    if (instance) {
      // console.log(instance);
      setModelValue("selectedFlavor", instance.flavor.name);
      setBillingValue(
        "billingPeriod",
        instance.monthlyBilling !== null ? "monthly" : "hourly"
      );
    }
  }, [instance, setBillingValue, setModelValue]);

  const selectedFlavorName = watchModel("selectedFlavor");
  const billingPeriod = watchBilling("billingPeriod");

  // Question => Why don't we use flavor data inside of the selected instance?
  // Answer => Because we need to change the selectedFlavor data by InstanceModelInput component and it is not a fixed data
  const selectedFlavor = useMemo(() => {
    if (instance) {
      const filteredFlovors = flavors
        .filter((f) => f.name === selectedFlavorName)
        .filter((f) => {
          return f.region === instance.flavor.region;
        });
      if (filteredFlovors.length) return filteredFlovors[0];
    }
  }, [flavors, instance, selectedFlavorName]);

  const currentBillingType = useMemo(() => {
    if (instance)
      return instance.monthlyBilling !== null ? "monthly" : "hourly";
  }, [instance]);

  const models = useMemo(() => {
    return makeModels(flavors, instance?.flavor.region);
  }, [flavors, instance]);

  //generate billing period options from current instance flavor
  const billingPeriodsOptions: InstanceBillingPeriod[] | undefined =
    useMemo(() => {
      if (instance) return makeBillingPeriodsOptions(instance.flavor);
    }, [instance]);

  useEffect(() => {
    dispatch(getInstancesFlavorsAsync());
    dispatch(getDBSCatalogAsync());
  }, [dispatch]);

  // filter addons by selected engine and period
  useEffect(() => {
    if (catalog) {
      setAddons(catalog.addons);
    }
  }, [catalog]);

  const onSubmitModel = () => {
    if (instance && selectedFlavor) {
      dispatch(
        updateInstanceModelAsync({
          instanceId: instance.id,
          data: {
            flavorId: selectedFlavor.id,
          },
        })
      ).then((action) => {
        if (action.type === "instance/resize/fulfilled") {
          toast.success(() =>
            CustomToast(
              "Instance model changed successfully! It resizes after a few minutes"
            )
          );
          resetModel();
          dispatch(getInstancesAsync({ withoutLoading: false }));
          dispatch(getInstanceAsync({ instanceId: instance.id }));
        }
      });
    }
  };

  const onSubmitBilling = () => {
    if (instance && selectedFlavor) {
      dispatch(
        updateInstanceMonthlyBillingAsync({
          instanceId: instance.id,
        })
      ).then((action) => {
        if (action.type === "instance/update-billing-type/fulfilled") {
          toast.success(() =>
            CustomToast(
              "Instance billing type changed successfully! It update after a few minutes"
            )
          );
          resetModel();
          dispatch(getInstancesAsync({ withoutLoading: false }));
          dispatch(getInstanceAsync({ instanceId: instance.id }));
        }
      });
    }
  };

  return (
    <div>
      <div className="flex flex-col gap-4">
        <form onSubmit={handleSubmitModel(onSubmitModel)} className="w-full">
          <div className="w-full">
            <div className="mt-3">
              <InstanceModelInput
                models={models}
                control={modelControl}
                selectedFlavor={selectedFlavor}
                currentFlavor={instance?.flavor}
                addons={addons}
                errorMessage={modelErrors.selectedFlavor?.message}
                loading={flavorsLoading}
              />
            </div>
          </div>
          {!flavorsLoading && (
            <div className="mt-0 flex gap-5">
              <Button
                loading={instanceLoading}
                type="primary"
                buttonProps={{
                  type: "submit",
                  disabled: instance?.status !== "ACTIVE" || instanceLoading,
                }}
              >
                Modify template
              </Button>
            </div>
          )}
        </form>

        <form onSubmit={handleSubmitBilling(onSubmitBilling)}>
          {billingPeriodsOptions && !flavorsLoading && (
            <>
              <div className="mt-5 mb-5">
                {selectedFlavor && (
                  <InstanceBillingInput
                    billingPeriodsOptions={billingPeriodsOptions}
                    addons={addons}
                    numberOfInstances={1}
                    control={billingControl}
                    currentValue={currentBillingType}
                  />
                )}
              </div>
              <div className="mt-3 flex gap-5">
                <Button
                  loading={instanceLoading}
                  type="primary"
                  buttonProps={{
                    type: "submit",
                    disabled:
                      instance?.status !== "ACTIVE" ||
                      currentBillingType === billingPeriod,
                  }}
                >
                  Change how you pay
                </Button>
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default InstanceRescaleTab;
