import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import Modal from "../../../../modals/Modal";
import Button from "../../../../buttons/Button";
import {
  createBucketsKmsKeyAsync,
  getBucketsKmsKeysAsync,
  handleHideKmsEditor,
  selectKmsLoading,
  selectShowKmsEditor,
} from "../../../../../store/s3/buckets/bucketsSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../../../inputs/Input";
import { S3KmsSchema } from "../../../../../utils/validations";
import Text from "../../../../general/Text";

const S3KmsEditorModal = () => {
  const isOpen = useAppSelector(selectShowKmsEditor);
  const kmsKeysLoading = useAppSelector(selectKmsLoading);

  const dispatch = useAppDispatch();

  const handleClearComponent = () => {
    reset();
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(S3KmsSchema),
  });

  const onSubmit = (data: any) => {
    dispatch(
      createBucketsKmsKeyAsync({
        name: data.name,
      })
    ).then((action) => {
      if (action.type === "buckets/kms-keys/create") {
        handleClearComponent();
        dispatch(handleHideKmsEditor());
        dispatch(getBucketsKmsKeysAsync());
      }
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleHideKmsEditor());
        handleClearComponent();
      }}
      contentClassName="max-w-lg"
      containerClassName="!items-start"
      title={"Create Key"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text className="mt-5 ">
          Create a new cryptographic key in the Key Management Service server
          connected to MINIO
        </Text>
        <div className="mt-5 flex flex-col w-full gap-5">
          <Input
            label="Key Name"
            inputProps={{ ...register("name") }}
            error={errors.name?.message}
          />
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button
            buttonProps={{
              onClick: (e) => {
                e.preventDefault();
                dispatch(handleHideKmsEditor());
              },
            }}
            type="light"
            buttonClassName="w-[70px]"
          >
            Cancel
          </Button>
          <Button
            loading={kmsKeysLoading}
            type="primary"
            buttonProps={{
              disabled: kmsKeysLoading,
              type: "submit",
            }}
            buttonClassName="w-[70px]"
          >
            Create
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default S3KmsEditorModal;
