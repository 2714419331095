import { useAppSelector } from "../../../hooks";
import { WorkflowNotActiveTab } from "../../layouts/NotData";
import { selectWorkflowStatus } from "../../../store/workflows/workflowsSlice";
import { WorkflowPlansBox } from "../../plans/WorkflowPlansBox";

const WorkflowPlansTab = () => {
  const workflowStatus = useAppSelector(selectWorkflowStatus);

  if (!workflowStatus) {
    return <WorkflowNotActiveTab />;
  }
  return (
    <>
      <WorkflowPlansBox />
    </>
  );
};

export default WorkflowPlansTab;
