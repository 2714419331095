import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";

import {
  Box,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Chip,
  Stack,
  IconButton,
} from "@mui/material";

// third-party
import moment from "moment";
import ReactJson from "react-json-view";

import AttachmentDialog from "./../dialog/AttachmentDialog";
import HTMLDialog from "./../dialog/HTMLDialog";
import ExpandDataDialog from "./../dialog/ExpandDataDialog";

import { ReactComponent as IconTrash } from "./../../../../assets/icons/archive-box.svg";
import { ReactComponent as IconArrowsMaximize } from "./../../../../assets/icons/arrows-pointing-out.svg";
import { ReactComponent as ExpandMoreIcon } from "./../../../../assets/icons/arrow-down.svg";

// utils
import { copyToClipboard } from "./../../../../utils/wfHelper";
import useDarkMode from "../../../../hooks/useDarkMode";
import toast from "react-hot-toast";
import { ToastClasses } from "../../../modals/alerts";
import Button from "../../../buttons/Button";
import Text from "../../../general/Text";
import QuestionModal from "../../../modals/QuestionModal";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  deleteWorkflowExecutionAsync,
  getWorkflowAsync,
  selectWorkflowDeleteExecutionLoading,
} from "../../../../store/workflows/workflowSlice";

// ==============================|| EXECUTIONS ||============================== //

const Executions = ({ workflowShortId, execution, executionCount }) => {
  const theme = useTheme();
  const { mode } = useDarkMode();

  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const [showHTMLDialog, setShowHTMLDialog] = useState(false);
  const [HTMLDialogProps, setHTMLDialogProps] = useState({});
  const [showAttachmentDialog, setShowAttachmentDialog] = useState(false);
  const [attachmentDialogProps, setAttachmentDialogProps] = useState({});
  const [showExpandDialog, setShowExpandDialog] = useState(false);
  const [expandDialogProps, setExpandDialogProps] = useState({});
  const [deleteExecutionId, setDeleteExecutionid] = useState(null);
  const deleteExecutionLoading = useAppSelector(
    selectWorkflowDeleteExecutionLoading
  );

  //   const dispatch = useDispatch();
  const varPrevOpen = useRef(open);
  //   const { confirm } = useConfirm();

  //   useNotifier();
  //   const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  //   const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  const handleAccordionChange = (executionShortId) => (event, isExpanded) => {
    setExpanded(isExpanded ? executionShortId : false);
  };

  const setChipColor = (execState) => {
    if (execState === "INPROGRESS") return theme.palette.warning.dark;
    if (execState === "FINISHED") return theme.palette.success.dark;
    if (execState === "ERROR") return theme.palette.error.dark;
    if (execState === "TERMINATED" || execState === "TIMEOUT")
      return theme.palette.grey["700"];
    return theme.palette.primary.dark;
  };

  const setChipBgColor = (execState) => {
    if (execState === "INPROGRESS") return theme.palette.warning.light;
    if (execState === "FINISHED") return theme.palette.success.light;
    if (execState === "ERROR") return theme.palette.error.light;
    if (execState === "TERMINATED" || execState === "TIMEOUT")
      return theme.palette.grey["300"];
    return theme.palette.primary.light;
  };

  const openAttachmentDialog = (executionData) => {
    const dialogProp = {
      title: "Attachments",
      executionData,
    };
    setAttachmentDialogProps(dialogProp);
    setShowAttachmentDialog(true);
  };

  const openHTMLDialog = (executionData) => {
    const dialogProp = {
      title: "HTML",
      executionData,
    };
    setHTMLDialogProps(dialogProp);
    setShowHTMLDialog(true);
  };

  const onExpandDialogClicked = (executionData, nodeLabel) => {
    const dialogProp = {
      title: `Execution Data: ${nodeLabel}`,
      data: executionData,
    };
    setExpandDialogProps(dialogProp);
    setShowExpandDialog(true);
  };

  const handleDelete = async () => {
    if (deleteExecutionId !== null)
      dispatch(deleteWorkflowExecutionAsync(deleteExecutionId)).then(
        (action) => {
          if (action.type === "workflow/executions/delete/fulfilled") {
            setDeleteExecutionid(null);
            dispatch(getWorkflowAsync(workflowShortId));
            toast.success("Execution deleted!", { className: ToastClasses });
          }
        }
      );
  };

  // Handle Accordian
  useEffect(() => {
    varPrevOpen.current = open;
  }, [open]);

  //   useEffect(() => {
  //     setOpen(isExecutionOpen);
  //   }, [isExecutionOpen]);

  return (
    <>
      <Paper className="bg-white dark:bg-dark-3 border-2 border-white dark:border-dark-2">
        <div className="shadow-md">
          <Box sx={{ p: 2 }}>
            <Stack>
              <Text className="text-sm font-bold">
                {executionCount} Executions
              </Text>
            </Stack>
          </Box>
          <div className="max-h-[calc(100vh-250px)] overflow-y-auto">
            {executionCount === 0 && execution.length === 0 && (
              <Box sx={{ p: 2 }}>No executions yet</Box>
            )}

            {executionCount > 0 && execution.length > 0 && (
              <Box sx={{ p: 2 }}>
                <List
                  className="!w-[330px] md:!w-[380px]"
                  sx={{
                    width: "100%",
                    py: 0,
                    borderRadius: "10px",
                    "& .MuiListItemSecondaryAction-root": {
                      top: 22,
                    },
                    "& .MuiDivider-root": {
                      my: 0,
                    },
                    "& .list-container": {
                      pl: 7,
                    },
                  }}
                >
                  {execution &&
                    execution.map((exec, index) => (
                      <Box key={index}>
                        <Accordion
                          expanded={expanded === exec.shortId}
                          onChange={handleAccordionChange(exec.shortId)}
                          sx={{ boxShadow: "none" }}
                          className="bg-white dark:bg-dark-3"
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon className="w-4 h-4  text-slate-700 dark:text-slate-300" />
                            }
                            aria-controls={`${exec.shortId}-content`}
                            id={`${exec.shortId}-header`}
                          >
                            <Stack sx={{ p: 1, mr: 1 }} direction="column">
                              <Stack
                                sx={{ mb: 1, alignItems: "center" }}
                                direction="row"
                              >
                                <Text className="text-sm">{exec.shortId}</Text>
                                {exec.state && (
                                  <Chip
                                    sx={{
                                      color: setChipColor(exec.state),
                                      backgroundColor: setChipBgColor(
                                        exec.state
                                      ),
                                      ml: 1,
                                      py: 1,
                                      fontSize: 10,
                                    }}
                                    label={exec.state}
                                  />
                                )}
                              </Stack>
                              <Stack
                                sx={{ mb: -1, alignItems: "center" }}
                                direction="row"
                              >
                                <Text className="text-xs " type="subtext">
                                  {moment(exec.createdDate).format(
                                    "MMMM Do YYYY, h:mm:ss A z"
                                  )}
                                </Text>
                                <IconButton
                                  size="small"
                                  sx={{ height: 25, width: 25, ml: 1 }}
                                  title="Delete Execution"
                                  color="error"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setDeleteExecutionid(exec.shortId);
                                  }}
                                >
                                  <IconTrash />
                                </IconButton>
                              </Stack>
                            </Stack>
                          </AccordionSummary>
                          {JSON.parse(exec.executionData).map(
                            (execData, execDataIndex) => (
                              <AccordionDetails key={execDataIndex}>
                                <Box
                                  sx={{
                                    p: 2,
                                    backgroundColor:
                                      theme.palette.secondary.light,
                                    borderRadius: `15px`,
                                    position: "relative",
                                  }}
                                  key={execDataIndex}
                                >
                                  <Text className="text-sm">
                                    {execData.nodeLabel}
                                  </Text>
                                  <ReactJson
                                    theme={
                                      mode === "dark" ? "ocean" : "rjv-default"
                                    }
                                    style={{
                                      padding: 10,
                                      borderRadius: 10,
                                    }}
                                    collapsed
                                    src={execData.data}
                                    enableClipboard={(e) => copyToClipboard(e)}
                                  />
                                  <IconButton
                                    size="small"
                                    sx={{
                                      height: 25,
                                      width: 25,
                                      position: "absolute",
                                      top: 5,
                                      right: 5,
                                    }}
                                    title="Expand Data"
                                    color="primary"
                                    onClick={() =>
                                      onExpandDialogClicked(
                                        execData.data,
                                        execData.nodeLabel
                                      )
                                    }
                                  >
                                    <IconArrowsMaximize />
                                  </IconButton>
                                  <div>
                                    {execData.data.map(
                                      (execObj, execObjIndex) => (
                                        <div key={execObjIndex}>
                                          {execObj.html && (
                                            <Text className="text-sm mt-1">
                                              HTML
                                            </Text>
                                          )}
                                          {execObj.html && (
                                            <div
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                                maxHeight: 400,
                                                overflow: "auto",
                                                backgroundColor:
                                                  theme.palette.card.main,
                                                color:
                                                  mode === "dark"
                                                    ? "white"
                                                    : "black",
                                                borderRadius: 5,
                                              }}
                                              dangerouslySetInnerHTML={{
                                                __html: execObj.html,
                                              }}
                                            />
                                          )}
                                          {execObj.html && (
                                            <Button
                                              size="small"
                                              className="mt-2"
                                              buttonProps={{
                                                onClick: () =>
                                                  openHTMLDialog(execData.data),
                                              }}
                                            >
                                              View HTML
                                            </Button>
                                          )}

                                          {execObj.attachments && (
                                            <Text className="text-sm mt-1">
                                              Attachments
                                            </Text>
                                          )}
                                          {execObj.attachments &&
                                            execObj.attachments.map(
                                              (attachment, attchIndex) => (
                                                <div key={attchIndex}>
                                                  <Text className="text-sm">
                                                    Item {execObjIndex} |{" "}
                                                    {attachment.filename
                                                      ? attachment.filename
                                                      : `Attachment ${attchIndex}`}
                                                  </Text>
                                                  <embed
                                                    src={attachment.content}
                                                    width="100%"
                                                    height="100%"
                                                    style={{
                                                      borderStyle: "solid",
                                                    }}
                                                    type={
                                                      attachment.contentType
                                                    }
                                                  />
                                                  <Button
                                                    size="small"
                                                    buttonClassName="mt-2"
                                                    buttonProps={{
                                                      onClick: () =>
                                                        openAttachmentDialog(
                                                          execData.data
                                                        ),
                                                    }}
                                                  >
                                                    View Attachment
                                                  </Button>
                                                </div>
                                              )
                                            )}
                                        </div>
                                      )
                                    )}
                                  </div>
                                </Box>
                              </AccordionDetails>
                            )
                          )}
                        </Accordion>
                      </Box>
                    ))}
                </List>
              </Box>
            )}
          </div>
        </div>
      </Paper>

      <AttachmentDialog
        show={showAttachmentDialog}
        dialogProps={attachmentDialogProps}
        onCancel={() => setShowAttachmentDialog(false)}
      />
      <HTMLDialog
        show={showHTMLDialog}
        dialogProps={HTMLDialogProps}
        onCancel={() => setShowHTMLDialog(false)}
      />
      <ExpandDataDialog
        show={showExpandDialog}
        dialogProps={expandDialogProps}
        onCancel={() => setShowExpandDialog(false)}
      />

      <QuestionModal
        isOpen={deleteExecutionId !== null}
        onClose={() => setDeleteExecutionid(null)}
        confirmButtonType="danger"
        onConfirm={handleDelete}
        loading={deleteExecutionLoading}
      />
    </>
  );
};

Executions.propTypes = {
  workflowShortId: PropTypes.string,
  execution: PropTypes.array,
  executionCount: PropTypes.number,
};

export default Executions;
