import {
  DatabaseService,
  DBSEngineType,
  IDBSCertificate,
} from "../../../types/database";
import { CopyableInput } from "../../general/CopyableKey";
import { ReactComponent as DownloadIcon } from "./../../../assets/icons/arrow-down-tray.svg";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getDBSCertificateAsync,
  selectCertificate,
  selectCertificateLoading,
} from "../../../store/database/serviceSlice";
import { useEffect, useMemo } from "react";
import Button from "../../buttons/Button";
import Text from "../../general/Text";

export const downloadCertificate = (certificate: IDBSCertificate | null) => {
  if (certificate?.ca) {
    const blob = new Blob([certificate.ca], {
      type: "text/plain;charset=utf-8",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "ca_certificate.txt";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } else {
    console.error("No CA certificate available to download");
  }
};

const DBSCertificate: React.FC<{
  title?: string;
  service: DatabaseService;
  validEngines?: Array<DBSEngineType>;
  disable?: boolean;
}> = ({ title, service, validEngines, disable = false }) => {
  const certificate = useAppSelector(selectCertificate);
  const certificateLoading = useAppSelector(selectCertificateLoading);

  const dispatch = useAppDispatch();

  const isValideService = useMemo(() => {
    return !!(
      validEngines &&
      validEngines.length &&
      validEngines.includes(service.engine)
    );
  }, [service.engine, validEngines]);

  useEffect(() => {
    if (service && isValideService && !disable) {
      dispatch(
        getDBSCertificateAsync({ engine: service.engine, id: service.id })
      );
    }
  }, [dispatch, isValideService, service, disable]);

  if (!isValideService) return null;
  return (
    <div className="p-2">
      <div className="flex flex-col">
        <Text className="text-sm font-medium">{title}</Text>
        <div className="flex items-center gap-1">
          <CopyableInput value={certificate?.ca} loading={certificateLoading} />
          <Button
            buttonProps={{
              onClick: () => downloadCertificate(certificate),
            }}
            type="icon"
            tooltip="Download"
          >
            <DownloadIcon className="w-4 h-4" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DBSCertificate;
