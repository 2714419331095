import { useAppSelector } from "../../../../hooks";
import {
  selectDataSent,
  selectDataSentLoading,
} from "../../../../store/s3/overviewSlice";
import GraphWidget from "./freams/GraphWidget";
import { S3WidgetType } from "../../../../types/s3";

const chartType: S3WidgetType = "dataSent";
const chartCode: number = 70;

const DataSentWidget = () => {
  const data = useAppSelector(selectDataSent);
  const loading = useAppSelector(selectDataSentLoading);

  return (
    <GraphWidget
      title="API Data Sent Rate"
      chartCode={chartCode}
      chartType={chartType}
      data={data}
      dataLoading={loading}
    />
  );
};

export default DataSentWidget;
