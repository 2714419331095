import classNames from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import React from "react";

const Checkbox = ({
  id,
  inputProps,
  label,
  error,
  required,
}: {
  id?: string;
  inputProps?: React.HTMLProps<HTMLInputElement>;
  label?: React.ReactNode;
  required?: boolean;
  error?: string;
}) => {
  return (
    <div className="flex flex-col">
      <div className="flex gap-1 cursor-pointer">
        <input
          type="checkbox"
          id={id}
          {...inputProps}
          className={classNames(
            "border text-sm rounded-lg  block w-4 h-4 dark:bg-zinc-800 outline-none mt-[0.1rem] cursor-pointer",
            {
              "bg-red-50 border-red-500 text-red-900 placeholder-red-700  focus:ring-red-500 focus:border-red-500 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500":
                error,
              "dark:border-zinc-600 dark:text-slate-50 dark:placeholder-gray-500  dark:focus:ring-blue-500 dark:focus:border-blue-500":
                !error,
            }
          )}
        />
        {label && (
          <label
            htmlFor={id}
            className={classNames(
              "block text-sm text-slate-800 dark:text-slate-50 select-none cursor-pointer",
              {
                "text-red-700 dark:text-red-500": error,
              }
            )}
          >
            {label}
            {required && <span className="text-red-500 mx-1">*</span>}
          </label>
        )}
      </div>
      <AnimatePresence>
        {error && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
          >
            <p className="mt-2 text-xs text-red-600 dark:text-red-500">
              {error}
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Checkbox;
