import React, { PropsWithChildren, useCallback, useEffect } from "react";
import { useState } from "react";

import Loading from "../components/general/Loading";
import { useAppDispatch, useAppSelector } from "../hooks";
import SurveyModal from "../components/modals/SurveyModal";
import CardDetailsBox from "../components/settings/billing/CardDetailsBox";
import {
  getMeAsync,
  handleSelectEnv,
  logout,
  selectLogin,
  selectLoginLoading,
  selectOnStageEnv,
  selectUser,
  selectUserLoading,
} from "../store/auth/authSlice";
import EmailNotVerify from "../components/layouts/EmailNotVerify";
import { useNavigate } from "react-router-dom";
import { SignInUrl } from "../utils/urls";
import EnvironmentSelectModal from "../components/environments/EnvironmentSelectModal";
import {
  closeEnvSelectionModal,
  getEnvironmentAsync,
  openEnvSelectionModal,
} from "../store/environments/environmentsSlice";
import InstallationModal from "../components/web-apps/InstallationModal";
import {
  makeZeroOrderAsync,
  selectLoadingCardDetail,
  selectZeroOrderLoading,
  waitingStatusAsync,
} from "../store/billing/billingSlice";
import { BillingIntentResponse } from "../types/billing";
import { sleep } from "../utils/sleep";
import { Environment } from "../types/environment";
import Card from "../components/general/Card";
type Status = "not-verify" | "card-information" | "survey" | "loading" | "ok";

const AccountProvider = ({ children }: PropsWithChildren) => {
  const [status, setStatus] = useState<Status>("loading");
  const loginUser = useAppSelector(selectLogin);
  const loginUserLoading = useAppSelector(selectLoginLoading);
  const user = useAppSelector(selectUser);
  const userLoading = useAppSelector(selectUserLoading);
  const selectedEnv = useAppSelector(selectOnStageEnv);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const key = selectedEnv ? selectedEnv.Id : "no-env";

  const zeroOrderLoading = useAppSelector(selectZeroOrderLoading);
  const savingLoading = useAppSelector(selectLoadingCardDetail);
  const [cardDetailsLoading, setCardDetailsLoading] = useState(false);

  useEffect(() => {
    dispatch(getMeAsync({}));
  }, [dispatch]);

  useEffect(() => {
    if (loginUser) {
      if (user) {
        // check user email status
        if (!user.IsVerified) {
          setStatus("not-verify");
        } else {
          if (selectedEnv) {
            setStatus("loading");
            dispatch(closeEnvSelectionModal());

            if (selectedEnv.EnvironmentStatus === 4) {
              setStatus("ok");
            } else {
              //we should check user card information first
              if (
                selectedEnv.EnvironmentStatus !== 3 &&
                selectedEnv.EnvironmentStatus !== 4
              ) {
                setStatus("card-information");
              } else {
                //if selected enviroment is not finilized, we should show the SurveyModal
                if (selectedEnv.EnvironmentStatus === 3) {
                  setStatus("survey");
                } else {
                  //
                }
              }
            }
          } else {
            setStatus("loading");
            dispatch(openEnvSelectionModal({ closable: false }));
          }
        }
      } else {
        if (userLoading) {
          setStatus("loading");
        } else {
          dispatch(logout());
          navigate(SignInUrl);
        }
      }
    } else {
      if (loginUserLoading) {
        setStatus("loading");
      } else {
        dispatch(logout());
        navigate(SignInUrl);
      }
    }
  }, [
    dispatch,
    navigate,
    loginUser,
    user,
    loginUserLoading,
    userLoading,
    selectedEnv,
  ]);

  const handleSelectedEnv = useCallback(async () => {
    if (selectedEnv && selectedEnv.EnvironmentStatus === 2) {
      setCardDetailsLoading(true);
      await sleep(10000);
      dispatch(getEnvironmentAsync({ id: selectedEnv.Id })).then((action) => {
        if (action.type === "environments/getOne/fulfilled") {
          const env = (action.payload as { environment: Environment })
            .environment;
          if (env.EnvironmentStatus === 2) {
            handleSelectedEnv();
          } else {
            dispatch(handleSelectEnv({ env }));
          }
        }
      });
    }
  }, [dispatch, selectedEnv]);

  const handleBeforInitPaymentMethod = async () => {
    if (selectedEnv) {
      const envStatus = selectedEnv.EnvironmentStatus; //available status: 0,1,2
      if (envStatus === 0 || envStatus === 1 || envStatus === 2) {
        return dispatch(makeZeroOrderAsync()).then((action) => {
          if (action.type === "billing/make-zero-order/fulfilled") {
            const orderToken = (action.payload as BillingIntentResponse).Token;
            return orderToken;
            // return "4ba308d2-f98e-44e6-9785-e427ab034814";
          } else {
            return undefined;
          }
        });
      } else {
        //waiting for complite
        handleSelectedEnv();
      }
    }
  };

  const handleAfterInitPaymentMethod = async (orderToken: string) => {
    if (selectedEnv)
      dispatch(waitingStatusAsync({ token: orderToken })).then((action) => {
        if (action.type === "billing/waiting-status/fulfilled") {
          // dispatch(getEnvironmentsAsync());
          dispatch(getEnvironmentAsync({ id: selectedEnv.Id })).then(
            (action) => {
              if (action.type === "environments/getOne/fulfilled") {
                const env = (action.payload as { environment: Environment })
                  .environment;
                dispatch(handleSelectEnv({ env }));
                handleSelectedEnv();
              }
            }
          );
        }
      });
  };

  return (
    <React.Fragment key={key}>
      {status === "not-verify" && <EmailNotVerify />}
      {status === "card-information" && (
        <div className="w-full h-[calc(100vh-4rem)] flex justify-center items-center">
          <Card title="Payment details" className="max-w-xl">
            <CardDetailsBox
              beforInitFn={handleBeforInitPaymentMethod}
              afterInitFn={handleAfterInitPaymentMethod}
              initLoading={zeroOrderLoading || cardDetailsLoading}
              savingLoading={savingLoading}
              username={user?.FullName}
            />
          </Card>
        </div>
      )}
      {status === "survey" && <SurveyModal />}
      {status === "loading" && (
        <Loading borderSize={2} className="!h-[calc(100vh-4rem)]" />
      )}
      {status === "ok" &&
        selectedEnv &&
        selectedEnv.EnvironmentStatus === 4 && <>{children}</>}
      <EnvironmentSelectModal />
      <InstallationModal />
    </React.Fragment>
  );
};
export default AccountProvider;
