import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  selectRpc,
  selectLoading,
  selectRpcMetrics,
  selectRpcMetricsLoading,
  getRpcMetricsAsync,
} from "../../../store/rpc/rpcSlice";
import Text from "../../general/Text";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { GraphChartCustomTooltipProps } from "../../../types/s3";
import { formatTimestamp } from "../../../utils/date";
import { round } from "../../../utils";
import { RpcMetric } from "../../../types/rpc";
import Loading from "../../general/Loading";

const RpcEndpointMetricsTab = () => {
  const rpc = useAppSelector(selectRpc);
  const loading = useAppSelector(selectLoading);

  const rpcMetrics = useAppSelector(selectRpcMetrics);
  const rpcMetricsLoading = useAppSelector(selectRpcMetricsLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (rpc) dispatch(getRpcMetricsAsync({ id: rpc.Id }));
  }, [rpc, dispatch]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (rpc) {
      interval = setInterval(() => {
        dispatch(getRpcMetricsAsync({ id: rpc.Id, withoutLoading: true }));
      }, 10000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, rpc]);

  return (
    <>
      <div className="flex transition-all duration-500">
        <div className="text-slate-800 dark:text-slate-100"></div>
        <div className="ml-auto flex items-center justify-end gap-1 md:gap-2"></div>
      </div>
      {(rpcMetricsLoading || loading) && (
        <Loading borderSize={2} className="!min-h-[300px]" />
      )}
      {!rpcMetricsLoading &&
        rpcMetrics &&
        Object.keys(rpcMetrics).length > 0 && (
          <div className="flex flex-col w-full gap-14">
            {Object.keys(rpcMetrics).map((key) => {
              return (
                <div className="h-[200px]">
                  <div className="w-full flex justify-center mt-5">
                    <Text className="font-semibold text-xs">
                      {chartName(key)}
                    </Text>
                  </div>
                  <RpcMetricChart
                    chartName={chartName(key)}
                    data={rpcMetrics[key as keyof typeof rpcMetrics]}
                  />
                </div>
              );
            })}
          </div>
        )}
    </>
  );
};

export const RpcMetricChart: React.FC<{
  data: Array<RpcMetric>;
  chartName: string;
}> = ({ data, chartName }) => {
  const combineLineDataMemo = useMemo(() => {
    return () => combineGraphLineData(data);
  }, [data]);

  const renderXAxis = () => {
    return (
      <XAxis
        dataKey="name"
        tick={{ fontSize: 10 }}
        // interval={Math.ceil(combineLineDataMemo().length / 2)}
        // domain={[-1, 1]}
      />
    );
  };

  const renderYAxis = () => (
    <YAxis
      tick={{ fontSize: 10 }}
      // tickFormatter={(value) =>
      //   humanizeSize(value, { fractionDigits: 1 }).join("B")
      // }
    />
  );

  const renderChart = () => (
    <AreaChart
      data={combineLineDataMemo()}
      margin={{ top: 2, right: 15, bottom: 0, left: 0 }}
    >
      <defs>
        {data &&
          data.map((d, i) => {
            const _key = i;
            return (
              <linearGradient
                key={_key}
                id={`area-color-${_key}`}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="5%"
                  stopColor="rgba(31,143,255,0.1)"
                  stopOpacity="rgba(31,143,255,0.1)"
                />
                <stop offset="95%" stopColor="#eee" stopOpacity={0} />
              </linearGradient>
            );
          })}
      </defs>
      <CartesianGrid strokeDasharray="3 3" />
      {renderXAxis()}
      {renderYAxis()}
      <Tooltip content={GraphCustomTooltip} />
      {/* <Legend verticalAlign="bottom" hanging={200} /> */}
      {data &&
        data.map((d, i) => {
          const _key = i;
          return (
            <Area
              key={`area-${_key}`}
              dataKey={d.Name ? d.Name + "_" + i : "value"}
              type="monotone"
              fill={`url(#area-color-${_key})`}
              strokeWidth={2}
              stroke={chartColor(i)}
              strokeOpacity={1}
            />
          );
        })}
    </AreaChart>
  );

  return <ResponsiveContainer>{renderChart()}</ResponsiveContainer>;
};

export const combineGraphLineData = (
  data: RpcMetric[] | null,
  dateFormat: string = "DD/MM/YYYY HH:mm"
) => {
  let combinedData: Record<string, any>[] = [];

  if (data !== null && data !== undefined && data.length > 0) {
    const basicMetric = data[0];
    basicMetric.Data.forEach(([timestamp, value], i) => {
      const time = formatTimestamp(timestamp / 1000, dateFormat, {
        isUTC: false,
      }).datetime;

      const entry = {
        name: time,
        timestamp,
        [basicMetric.Name ? basicMetric.Name + "_0" : "value"]: value,
      };
      combinedData.push(entry);
    });

    if (data.length > 1) {
      const extraMetrics = data.slice(1);
      for (let i = 0; i < extraMetrics.length; i++) {
        const metric = extraMetrics[i];
        combinedData = combinedData.map((cd, j) => ({
          ...cd,
          [metric.Name ? metric.Name + "_" + (i + 1) : "value"]: metric.Data[j]
            ? metric.Data[j][1]
            : 0,
        }));
      }
    }
  }
  // Iterate over the values array of each line data
  return combinedData;
};

const GraphCustomTooltip = ({
  active,
  payload,
}: GraphChartCustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white/70 dark:bg-dark-3/80 text-xs p-2 border dark:border-dark-2 rounded-md max-h-40 overflow-y-auto">
        <div className="flex flex-col">
          <Text className="text-center " type="subtext">
            {
              formatTimestamp(
                payload[0].payload.timestamp / 1000,
                "DD/MM/YYYY HH:mm",
                { isUTC: false }
              ).datetime
            }
          </Text>
          {payload.map((item, i) => (
            <div className="flex items-center gap-1" key={i}>
              <span
                className="w-2 h-2 rounded-full"
                style={{ backgroundColor: chartColor(i) }}
              />
              <Text className="label">{`${item.dataKey.split("_")[0]} : ${round(
                item.payload[item.dataKey],
                4
              )}`}</Text>
            </div>
          ))}
        </div>
      </div>
    );
  }
};

export const chartName = (label: string) => {
  return label.match(/[A-Z][a-z]+/g)?.join(" ") || "";
};

const chartColor = (i: number) => {
  const colors = [
    "rgb(0 ,116 ,229)",
    "rgb(249 ,115 ,22)",
    "rgb(126 ,34 ,206)",
    "rgb(148 ,11 ,85)",
    "rgb(99 ,110 ,23)",
  ];
  const colorsLen = colors.length;

  if (i <= colorsLen - 1) {
    return colors[i];
  } else {
    return colors[i - colorsLen];
  }
};

export default RpcEndpointMetricsTab;
