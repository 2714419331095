import jwtAxios, { Axios } from ".";
import {
  ForgotPasswordApiType,
  LoginApiType,
  RegisterApiType,
} from "../types/auth";

const LOGIN_API_URL = `/accounts/login`;
const REGISTER_API_URL = (token: string | null) =>
  token !== null ? `/accounts?token=${token}` : `/accounts`;
const GET_ME_API_URL = `/accounts`;
// const LOGOUT_API_URL = `/me/logout`;
// const SSO_LOGIN_API_URL = "/public/sso";
const FORGOT_PASS_API_URL = `/accounts/forgot-password`;
const RESET_PASS_API_URL = (token: string) =>
  `/accounts/reset-password?token=${token}`;
const CHANGE_PASS_API_URL = `/accounts/change-password`;
const VERIFICATION_EMAIL_API_URL = `/accounts/verify-email`;
const RESEND_VERIFICATION_EMAIL_API_URL = `/accounts/resend-verify-email`;
const UPDATE_PROFILE_API_URL = `/accounts`;
const UPDATE_PROFILE_AVATAR_API_URL = `/accounts/avatar`;

const GENERATE_OTP_API_URL = `/accounts/qr-code`;
const ENABLE_2FA_API_URL = `/accounts/enable-two-factor`;
const GET_RECOVERY_STRING_API_URL = `/accounts/recovery-code`;
const RE_GENERATE_RECOVERY_STRING_API_URL = `/accounts/regenerate-recovery-code`;
const DISABLE_2FA_API_URL = `/accounts/disable-two-factor`;
const VALIDATE_2FA_API_URL = `/accounts/login-two-factor`;

export function loginApi(data: LoginApiType) {
  return Axios.post(LOGIN_API_URL, data);
}

export function registerApi(token: string | null, data: RegisterApiType) {
  return Axios.post(REGISTER_API_URL(token), data);
}

export function getMeApi() {
  return jwtAxios.get(GET_ME_API_URL);
}

// export function logoutApi() {
//   return jwtAxios.post(LOGOUT_API_URL);
// }

// export function loginWithSsoApi(token: string) {
//   return jwtAxios.post(SSO_LOGIN_API_URL, {
//     code: token,
//   });
// }

export function forgotPasswordApi(data: ForgotPasswordApiType) {
  return jwtAxios.post(FORGOT_PASS_API_URL, data);
}

export function resetPasswordApi(
  password: string,
  passwordConfirmation: string,
  token: string
) {
  return Axios.post(RESET_PASS_API_URL(token), {
    password,
    passwordConfirmation,
  });
}

export function changePasswordApi(
  password: string,
  newPassword: string,
  passwordConfirmation: string
) {
  return jwtAxios.post(CHANGE_PASS_API_URL, {
    CurrentPassword: password,
    Password: newPassword,
    PasswordConfirmation: passwordConfirmation,
  });
}

export function verificationEmailApi(token: string) {
  return Axios.post(VERIFICATION_EMAIL_API_URL, {
    token,
  });
}

export function resendVerificationEmailApi() {
  return jwtAxios.get(RESEND_VERIFICATION_EMAIL_API_URL);
}

export function updateProfileApi(data: { FullName: string }) {
  return jwtAxios.put(UPDATE_PROFILE_API_URL, data);
}

export function updateProfileAvatarApi(data: { img: File }) {
  return jwtAxios.post(UPDATE_PROFILE_AVATAR_API_URL, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function generateOTPApi() {
  return jwtAxios.get(GENERATE_OTP_API_URL);
}

export function enable2faApi(data: any) {
  return jwtAxios.put(ENABLE_2FA_API_URL, data);
}

export function getRecoveryStringApi() {
  return jwtAxios.get(GET_RECOVERY_STRING_API_URL);
}

export function reGenerateRecoveryStringApi() {
  return jwtAxios.put(RE_GENERATE_RECOVERY_STRING_API_URL);
}

export function disable2faApi() {
  return jwtAxios.put(DISABLE_2FA_API_URL);
}

export function validate2faApi(data: any) {
  return jwtAxios.post(VALIDATE_2FA_API_URL, data);
}
