import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Button from "../buttons/Button";
import { HomeUrl, SignInUrl } from "../../utils/urls";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../inputs/Input";
import Checkbox from "../inputs/Checkbox";
import { SignUpSchema, validatePassword } from "../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getMeAsync,
  registerAsync,
  selectLoginLoading,
  selectUserLoading,
} from "../../store/auth/authSlice";
import Loading from "../general/Loading";
import { useEffect, useState } from "react";
import { PasswordErrors } from "../../types";
import classNames from "classnames";
import Text, { A } from "../general/Text";

const SignUp = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectLoginLoading);
  const userLoading = useAppSelector(selectUserLoading);
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const inviteToken = query.get("token");
  const envName = query.get("envName") || "";

  const isInvitedMode =
    inviteToken !== undefined && inviteToken !== null && inviteToken !== "";

  const [password_error, setPasswordErrors] = useState<PasswordErrors>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(SignUpSchema(isInvitedMode)),
  });

  const selectedPassword = watch("password");

  const onSubmit = (data: any) => {
    dispatch(
      registerAsync({
        token: isInvitedMode ? inviteToken : null,
        data: {
          FullName: data.fullName,
          OrganizationName: data.organizationName,
          Email: data.email,
          Password: data.password,
          AgreedWithTerms: data.termsOfService,
        },
      })
    ).then((action) => {
      if (action.type === "auth/register/fulfilled") {
        dispatch(getMeAsync({})).then((action) => {
          if (action.type === "auth/me/fulfilled") {
            navigate(HomeUrl);
          }
        });
      }
    });
  };

  useEffect(() => {
    setValue("organizationName", envName);
  }, [envName, setValue]);

  useEffect(() => {
    setPasswordErrors(validatePassword(selectedPassword));
  }, [selectedPassword]);

  return (
    <>
      <Text
        type="title"
        className="mb-7 text-center"
        value={`Sign up for an account`}
      />
      {!userLoading && (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div className="gap-4 flex flex-col">
            <Input
              inputProps={{ ...register("fullName") }}
              label="Full name"
              required
              placeholder="Jone Doe"
              error={errors.fullName?.message}
              hint="4 characters minimum"
            />
            <Input
              inputProps={{
                ...register("organizationName"),
                disabled: isInvitedMode,
              }}
              label="Organization name"
              required={!isInvitedMode}
              placeholder={isInvitedMode ? "" : "My Company"}
              error={errors.organizationName?.message}
              hint={
                isInvitedMode
                  ? "You are invited to this organization"
                  : "4 characters minimum"
              }
            />
            {!isInvitedMode && (
              <Input
                inputProps={{ ...register("email") }}
                label="Email address"
                required
                placeholder="your@email.com"
                error={errors.email?.message}
              />
            )}
            <Input
              label="Password"
              inputProps={{
                type: "password",
                ...register("password"),
                autoComplete: "new-password",
              }}
              required
              placeholder="●●●●●●"
              error={errors.password?.message}
              hint={
                <div className="flex gap-1 flex-wrap">
                  <span
                    className={classNames("whitespace-nowrap", {
                      "text-success": !password_error?.length,
                    })}
                  >
                    8-64 chars
                  </span>
                  |
                  <span
                    className={classNames("whitespace-nowrap", {
                      "text-success": !password_error?.lowercase,
                    })}
                  >
                    1 lowercase
                  </span>
                  |
                  <span
                    className={classNames("whitespace-nowrap", {
                      "text-success": !password_error?.uppercase,
                    })}
                  >
                    1 uppercase
                  </span>
                  |
                  <span
                    className={classNames("whitespace-nowrap", {
                      "text-success": !password_error?.number,
                    })}
                  >
                    1 number
                  </span>{" "}
                  |
                  <span
                    className={classNames("whitespace-nowrap", {
                      "text-success": !password_error?.special_char,
                    })}
                  >
                    1 special char
                  </span>
                </div>
              }
              labelClassName="!items-start"
            />
            <Checkbox
              inputProps={{ ...register("termsOfService") }}
              id="TermsOfService"
              label={
                <>
                  By creating an account, I agree to Djuno Cloud's{" "}
                  <A href="https://blogs.djuno.io/term-of-use/" target="_blank">
                    Terms of Service
                  </A>{" "}
                  and{" "}
                  <A
                    href="https://blogs.djuno.io/privacy-notice/"
                    target="_blank"
                  >
                    Privacy Policy
                  </A>
                  .
                </>
              }
              error={errors.termsOfService?.message}
            />
            <div className="flex flex-col">
              <Button
                type="primary"
                buttonProps={{ type: "submit", disabled: loading }}
                buttonClassName="!w-full"
                loading={loading}
              >
                Create a free account
              </Button>
              <div className="flex gap-1 mt-2 justify-center">
                <span className="text-sm text-slate-800 dark:text-slate-50">
                  Already have an account?
                </span>
                <Link
                  to={SignInUrl}
                  className="text-sm text-sky-500 hover:text-sky-600"
                >
                  Sign in
                </Link>
              </div>
            </div>
          </div>
        </form>
      )}
      {userLoading && <Loading borderSize={2} style={{ minHeight: 200 }} />}
    </>
  );
};
export default SignUp;
