import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getSurveyQuestionsAsync,
  selectSaveSurveyQuestionsLoading,
  selectSurveyQuestions,
  selectSurveyQuestionsLoading,
  setSurveyQuestionsAsync,
} from "../../store/survey/surveySlice";
import { SurveyAnswer, SurveyQuestion } from "../../types/survey";
import Button from "../buttons/Button";
import classNames from "classnames";
import { getMeAsync } from "../../store/auth/authSlice";
import { getEnvironmentsAsync } from "../../store/environments/environmentsSlice";
import Loading from "../general/Loading";
import useConfitti from "../../hooks/useConfitti";

const SurveyModal = () => {
  const questions = useAppSelector(selectSurveyQuestions);
  const questionsLoading = useAppSelector(selectSurveyQuestionsLoading);
  const saveLoading = useAppSelector(selectSaveSurveyQuestionsLoading);

  const [onStageQuestion, setOnStageQuestion] = useState<SurveyQuestion | null>(
    questions[0]
  );
  const [step, setStep] = useState(0);
  const [answers, setAnswers] = useState<Array<SurveyAnswer>>([]);

  const { run } = useConfitti();

  const dispatch = useAppDispatch();
  const selectedOption = answers.find((a) => a.id === onStageQuestion?.Id);
  const hasNextGuestion = !!questions[step + 1];

  //get questions if there is not any question in the store
  useEffect(() => {
    if (questions.length === 0) dispatch(getSurveyQuestionsAsync());
  }, [dispatch, questions.length]);

  //select question to show when have question list and change step
  useEffect(() => {
    if (questions.length > 0) setOnStageQuestion(questions[step]);
  }, [questions, step]);

  const handleCheckOption = (option: string) => {
    if (onStageQuestion) {
      const answer: SurveyAnswer = {
        id: onStageQuestion.Id,
        option,
      };
      setAnswers((prevAnswers) => {
        let found = false;
        let newAnswers = prevAnswers.map((a) => {
          if (a.id === onStageQuestion.Id) {
            found = true;
            return answer;
          } else {
            return { ...a };
          }
        });
        if (!found) {
          newAnswers = [...prevAnswers, answer];
        }
        return newAnswers;
      });
    }
  };

  const handleGoNextQuestion = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handleGetStarted = () => {
    dispatch(setSurveyQuestionsAsync({ answers })).then((action) => {
      setAnswers([]);
      setOnStageQuestion(questions[0]);
      setStep(0);
      if (action.type === "survey/questions/answer/fulfilled") {
        run();
        dispatch(getMeAsync({}));
        dispatch(getEnvironmentsAsync());
      }
    });
  };
  return (
    <div className="w-full h-[calc(100vh-4rem)] flex justify-center items-center">
      <div className="flex flex-col min-w-full px-6 md:px-0 md:min-w-[420px]">
        <div className="mt-5">
          {questionsLoading && (
            <div className="min-h-[200px] flex justify-center items-center">
              <Loading />
            </div>
          )}
          <div className="flex flex-col border-2 bg-white dark:border-dark-2 dark:bg-dark-1 rounded-lg overflow-hidden">
            {!questionsLoading &&
              onStageQuestion?.Options.map((option, index) => (
                <div
                  onClick={() => handleCheckOption(option)}
                  key={index}
                  className={classNames(
                    "flex gap-2 items-center w-full dark:border-dark-2 hover:bg-slate-50 hover:dark:bg-black/20 px-3 py-2.5 dark:text-slate-200 text-sm cursor-pointer",
                    {
                      "border-b-2":
                        onStageQuestion.Options.length !== index + 1,
                    }
                  )}
                >
                  <div
                    className={classNames("w-4 h-4 border rounded-full", {
                      "dark:bg-dark-3  dark:border-dark-2":
                        selectedOption?.option !== option,
                      "bg-primary-300  border-0":
                        selectedOption?.option === option,
                    })}
                  />
                  {option}
                </div>
              ))}
          </div>
        </div>

        <div className="mt-4 flex justify-end">
          <Button
            type="primary"
            buttonProps={{
              disabled: !!!selectedOption || questionsLoading,
              onClick: hasNextGuestion
                ? handleGoNextQuestion
                : handleGetStarted,
            }}
            loading={questionsLoading || saveLoading}
          >
            {hasNextGuestion ? "Next" : "GetStarted"}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default SurveyModal;
