import { Outlet, useNavigate, useParams } from "react-router-dom";
import Tabs from "../../components/general/Tabs";
import { TabOptions } from "../../types/tabs";
import {
  RpcEndpointsUrl,
  RpcEndpontGetStartedUrl,
  RpcEndpontMetricsUrl,
  RpcEndpontSecurityUrl,
} from "../../utils/urls";
import { ReactComponent as RightArrow } from "./../../assets/icons/arrow-right.svg";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getRpcAsync,
  selectLoading,
  selectRpc,
} from "../../store/rpc/rpcSlice";
import classNames from "classnames";
import Text from "../../components/general/Text";
import { Helmet } from "react-helmet";
import { NotExist, RpcNotActiveTab } from "../../components/layouts/NotData";
import { ReactComponent as SecurityIcon } from "./../../assets/icons/lock-closed.svg";
import { ReactComponent as ExportIcon } from "./../../assets/icons/arrow-right-start-on-rectangle.svg";
import { ReactComponent as MetricsIcon } from "./../../assets/icons/adjustments-vertical.svg";

const generateRpcEndpointRoutes = (id: string | undefined): TabOptions => {
  return [
    {
      label: (
        <div className="flex items-center gap-1">
          <ExportIcon className="w-3" />
          Get Started
        </div>
      ),
      url: RpcEndpontGetStartedUrl(id ? +id : 0),
      testId: "1th-tab",
    },

    {
      label: (
        <div className="flex items-center gap-1">
          <MetricsIcon className="w-3" />
          Metrics
        </div>
      ),
      url: RpcEndpontMetricsUrl(id ? +id : 0),
      testId: "2th-tab",
    },
    {
      label: (
        <div className="flex items-center gap-1">
          <SecurityIcon className="w-3" />
          Security
        </div>
      ),
      url: RpcEndpontSecurityUrl(id ? +id : 0),
      testId: "3th-tab",
    },
  ];
};

const RpcEndpointPage = () => {
  const { id } = useParams();
  const rpc = useAppSelector(selectRpc);
  const loading = useAppSelector(selectLoading);
  const EndpontPageMenus = generateRpcEndpointRoutes(id);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isExist, setIsExist] = useState<boolean>(true);

  useEffect(() => {
    if (id) {
      dispatch(getRpcAsync({ id: +id })).then((action) => {
        if (action && action.type === "rpc/rejected") {
          setIsExist(false);
        }
      });
    }
  }, [dispatch, id]);

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | Endpoints ({rpc ? rpc.RpcName : ""})
        </title>
        <meta
          name="description"
          content="RPC-endpoints, or Remote Procedure Call endpoints, are a fundamental aspect of distributed computing systems, enabling communication between different components or services across a network"
        />
      </Helmet>
      <div className="flex items-center justify-between h-16">
        <div className="px-6 items-center justify-between flex flex-1 transition duration-150">
          <div className="flex items-center gap-2">
            <RightArrow
              onClick={() => navigate(RpcEndpointsUrl)}
              className="rotate-180 w-5 h-5 hover:scale-110 transition-all duration-500 text-slate-800 dark:text-slate-100 cursor-pointer"
            />
            <div
              className={classNames(
                " dark:text-slate-100 min-w-[200px] h-8 rounded-md px-2 flex items-center gap-2",
                {
                  "bg-slate-300 dark:bg-slate-50/10 animate-pulse": loading,
                }
              )}
            >
              {rpc ? (
                <div className="w-10 h-10 rounded-md ring-0 flex items-center text-center justify-center border dark:border-dark-2">
                  <img src={rpc?.Icon} alt={rpc?.RpcName} className="w-6 h-6" />
                </div>
              ) : null}
              <div className="flex flex-col">
                <Text type="title" className="text-lg">
                  {rpc?.RpcName}
                </Text>
                <Text type="subtext" className="text-sm">
                  {rpc && `${rpc?.ServiceName} (${rpc?.NetworkType})`}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isExist && rpc?.RPCNodeStatus === "RUNNING" && (
        <div className="px-6 mt-5 mb-10">
          <Tabs
            useUrl
            options={EndpontPageMenus.map((o) => ({
              label: o.label,
              url: o.url,
              testId: o.testId,
            }))}
          />
          <div className="py-6">
            <Outlet />
          </div>
        </div>
      )}
      {!isExist && <NotExist url={() => navigate(RpcEndpointsUrl)} />}
      {!loading && rpc && rpc.RPCNodeStatus !== "RUNNING" && (
        <RpcNotActiveTab />
      )}
    </>
  );
};
export default RpcEndpointPage;
