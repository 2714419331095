import { Outlet } from "react-router-dom";
import Tabs from "../../components/general/Tabs";
import { TabOptions } from "../../types/tabs";
import {
  WorkflowApiKeysUrl,
  WorkflowContractsUrl,
  WorkflowPlanesUrl,
  WorkflowWalletsUrl,
  WorkflowsUrl,
} from "../../utils/urls";
import Text from "../../components/general/Text";
import { Helmet } from "react-helmet";
import WorkflowsTab from "../../components/workflows/workflows/WorkflowsTab";
import WorkflowsContractsTab from "../../components/workflows/contracts/WorkflowsContractsTab";
import WorkflowsWalletsTab from "../../components/workflows/wallets/WorkflowsWalletsTab";
import WorkflowsApiKeysTab from "../../components/workflows/api_keys/WorkflowsApiKeysTab";
import { ReactComponent as KeyIcon } from "./../../assets/icons/key.svg";
import { ReactComponent as WalletIcon } from "./../../assets/icons/wallet.svg";
import { ReactComponent as DocumentIcon } from "./../../assets/icons/document-text.svg";
import { ReactComponent as WorkflowIcon } from "./../../assets/icons/workflow.svg";
import { ReactComponent as ListBulletIcon } from "./../../assets/icons/list-bullet.svg";
import WorkflowPlanesTab from "../../components/workflows/plans/WorkflowPlansTab";

export const WorkflowStudioPageMenus: TabOptions = [
  {
    label: (
      <div className="flex items-center gap-1">
        <WorkflowIcon className="w-3" />
        Workflows
      </div>
    ),
    url: WorkflowsUrl,
    element: <WorkflowsTab />,
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <DocumentIcon className="w-3" />
        Contracts
      </div>
    ),
    url: WorkflowContractsUrl,
    element: <WorkflowsContractsTab />,
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <WalletIcon className="w-3" />
        Wallets
      </div>
    ),
    url: WorkflowWalletsUrl,
    element: <WorkflowsWalletsTab />,
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <KeyIcon className="w-3" />
        Access Keys
      </div>
    ),
    url: WorkflowApiKeysUrl,
    element: <WorkflowsApiKeysTab />,
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <ListBulletIcon className="w-3" />
        Plans
      </div>
    ),
    url: WorkflowPlanesUrl,
    element: <WorkflowPlanesTab />,
  },
];

const WorkflowStudioPage = () => {
  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Workflow Studio</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="flex items-center justify-between h-20">
        <div className="px-6 items-center justify-between flex flex-1 transition duration-150">
          <Text className="font-medium text-lg">Workflows</Text>
        </div>
        <div className="mr-6 w-96"></div>
      </div>
      <div className="mx-6">
        <Tabs
          useUrl
          options={WorkflowStudioPageMenus.map((o) => ({
            label: o.label,
            url: o.url,
          }))}
        />
        <div className="py-6">
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default WorkflowStudioPage;
