import { useAppDispatch, useAppSelector } from "../../../../hooks";
import Modal from "../../../modals/Modal";
import Input from "../../../inputs/Input";
import Button from "../../../buttons/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Enable2FASchema } from "../../../../utils/validations";
import {
  enableTwoFactorAsync,
  generateOTPAsync,
  getMeAsync,
  handleHideEnable2fa,
  handleShowRecoveryModal,
  selectEnable2FAloading,
  selectGenerateOTPloading,
  selectShowEnable2fa,
} from "../../../../store/auth/authSlice";
import { useCallback, useState } from "react";
import Text from "../../../general/Text";
import Loading from "../../../general/Loading";
import QRCode from "qrcode";
import CopyableKey from "../../../general/CopyableKey";
import { Generate2FaResponse } from "../../../../types/auth";

const SetupTwoFactorAuthModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectShowEnable2fa);
  const generateOTPloading = useAppSelector(selectGenerateOTPloading);
  const enable2faLoading = useAppSelector(selectEnable2FAloading);

  const [qrcodeUrl, setQrCodeUrl] = useState("");
  const [base32, setBase32] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Enable2FASchema),
  });

  const handleGenerateOTP = useCallback(() => {
    dispatch(generateOTPAsync()).then((action) => {
      if (action.type === "auth/generate-otp/fulfilled") {
        const res = action.payload as Generate2FaResponse;
        QRCode.toDataURL(res.QrcodeUrl).then(setQrCodeUrl);
        setBase32(res.Base32Url);
      }
    });
  }, [dispatch]);

  const onSubmit = (data: any) => {
    dispatch(
      enableTwoFactorAsync({
        code: data.code,
      })
    ).then((action) => {
      if (action.type === "auth/enable-2fa/fulfilled") {
        const res = action.payload.Result as string[];
        reset();
        dispatch(getMeAsync({}));
        dispatch(handleHideEnable2fa());
        dispatch(
          handleShowRecoveryModal({
            recoveryString: res,
            canRegenerateRecovery: false,
          })
        );
      }
    });
  };

  const handleCloseModal = useCallback(() => {
    reset();
    dispatch(handleHideEnable2fa());
    setQrCodeUrl("");
    setBase32("");
  }, [dispatch, reset]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCloseModal}
      contentClassName="!max-w-lg"
      title="Enable Two-Factor Authentication"
      rendered={handleGenerateOTP}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col gap-4">
          <Text className="text-sm">
            Scan the QR code in your authenticator app or browser extention and
            enter the authentication code blowe.
          </Text>

          <div className="flex justify-center my-3">
            <div className="block w-56 h-56">
              {qrcodeUrl === "" || generateOTPloading ? (
                <Loading borderSize={2} />
              ) : (
                <img
                  className="block w-full h-full object-contain"
                  src={qrcodeUrl}
                  alt="qrcode url"
                />
              )}
              {qrcodeUrl !== ""}
            </div>
          </div>

          <div>
            <Text className="text-sm">Or Enter Code Into Your App</Text>
            <Text className="text-sm flex items-center gap-1">
              SecretKey:{" "}
              <CopyableKey
                className="!w-56 md:!w-64"
                text={base32}
                loading={generateOTPloading}
              />
            </Text>
          </div>
          <Input
            label="Code"
            inputProps={{ ...register("code") }}
            error={errors.code?.message}
            required
            placeholder="000000"
          />
        </div>

        <div className="mt-4 flex justify-end">
          <Button
            type="primary"
            buttonProps={{
              disabled: generateOTPloading,
              type: "submit",
            }}
            loading={enable2faLoading}
          >
            Enable 2FA
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default SetupTwoFactorAuthModal;
