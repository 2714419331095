import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import Modal from "../../../../modals/Modal";
import Button from "../../../../buttons/Button";
import {
  handleHideRewindModal,
  selectBucketDetails,
  selectBucketRewindLoading,
  selectShowRewindModal,
  setBucketRewindAsync,
} from "../../../../../store/s3/buckets/bucketSlice";

import { yupResolver } from "@hookform/resolvers/yup";
import { S3RewindSchema } from "../../../../../utils/validations";
import Input from "../../../../inputs/Input";
import { useForm } from "react-hook-form";

const S3RewindModal = () => {
  const isOpen = useAppSelector(selectShowRewindModal);
  const loading = useAppSelector(selectBucketRewindLoading);
  const bucketDetails = useAppSelector(selectBucketDetails);

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(S3RewindSchema),
  });

  const handleClearComponent = () => {
    reset();
  };

  const onSubmit = (data: any) => {
    console.log(data);
    if (bucketDetails) {
      dispatch(
        setBucketRewindAsync({
          name: bucketDetails.name,
          date: data.time,
        })
      ).then((action) => {
        if (action.type === "bucket/rewind/set/fulfilled") {
          handleClearComponent();
          dispatch(handleHideRewindModal());
        }
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleHideRewindModal());
        handleClearComponent();
      }}
      contentClassName="max-w-lg"
      // containerClassName="!items-start"
      title={`Rewind - ${bucketDetails?.name}`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col w-full gap-5">
          <Input
            label="Rewind to"
            inputProps={{ ...register("time"), type: "datetime-local" }}
            error={errors.time?.message}
          />
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button
            buttonProps={{
              onClick: (e) => {
                e.preventDefault();
                handleClearComponent();
                dispatch(handleHideRewindModal());
              },
            }}
            type="light"
            buttonClassName="w-[70px]"
          >
            Cancel
          </Button>
          <Button
            loading={loading}
            type="primary"
            buttonProps={{
              disabled: loading,
              type: "submit",
            }}
            buttonClassName="w-[160px]"
          >
            Show Rewind Data
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default S3RewindModal;
