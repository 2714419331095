import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  selectS3Events,
  deleteS3EventAsync,
  getS3EventsAsync,
  selectActionLoading,
  selectLoading,
  handleChangeEventActionLoading,
} from "../../../store/s3/eventsSlice";
import Button from "../../buttons/Button";
import S3EventEditorModal from "./forms/S3EventEditorModal";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import Dropdown from "../../general/Dropdown";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import NotData, { S3NotActiveTab } from "../../layouts/NotData";
import QuestionModal, { DeleteModal } from "../../modals/QuestionModal";
import { useNavigate } from "react-router-dom";
import { S3EventAddUrl } from "../../../utils/urls";
import Text from "../../general/Text";
import { S3Event } from "../../../types/s3-events";
import { selectS3Status } from "../../../store/s3/s3PublicSlice";
import useGetS3Events from "../../../hooks/useGetS3Events";

const S3EventsTab = () => {
  const s3Status = useAppSelector(selectS3Status);
  const events = useAppSelector(selectS3Events);
  const loading = useAppSelector(selectLoading);
  const actionLoading = useAppSelector(selectActionLoading);
  const [deleteEvent, setDeleteEvent] = useState<S3Event | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const getS3Events = useGetS3Events();

  useEffect(() => {
    if (events.length === 0) {
      dispatch(getS3EventsAsync());
    }
  }, [events.length, dispatch]);

  const handleDelete = () => {
    if (deleteEvent) {
      dispatch(
        deleteS3EventAsync({
          event: deleteEvent.service,
          identifier: deleteEvent.account_id,
        })
      ).then((action) => {
        if (action.type === "s3-events/delete/fulfilled") {
          getS3Events();
        }
        setDeleteEvent(null);
      });
    }
  };

  if (!s3Status) {
    return <S3NotActiveTab />;
  }
  return (
    <>
      <div className="flex transition-all duration-500 items-center justify-between gap-3">
        <Text className="whitespace-nowrap font-medium">Events</Text>
        <div className="flex items-center justify-end gap-1 md:gap-2 ">
          <Button
            type="primary"
            buttonProps={{
              onClick: () => navigate(S3EventAddUrl),
            }}
            buttonClassName="group"
          >
            Create Event Target
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>
      <div className="mt-5 w-full">
        <Table loading={loading}>
          <TableHead>
            <TableRow>
              <TableTH lable="Status" />
              <TableTH lable="Service" />
              <TableTH lable="" />
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((event, i) => (
              <TableRow key={i}>
                <TableTD>
                  <div className="flex items-center flex-row justify-between space-x-4">
                    <Text className="text-xs md:text-sm">{event.status}</Text>
                  </div>
                </TableTD>
                <TableTD>
                  <div className="flex items-center flex-row justify-between space-x-4">
                    <Text className="text-xs md:text-sm">
                      {event.service} : {event.account_id}
                    </Text>
                  </div>
                </TableTD>
                <TableTD className="w-32 gap-1">
                  <div className="h-full w-full inline-flex items-center justify-end gap-1">
                    <div className="w-8 flex justify-end items-center">
                      <Dropdown
                        positionClassName="!w-40"
                        menu={[
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: (_, close) => {
                              close();
                              setDeleteEvent(event);
                            },
                          },
                        ]}
                        type="simple"
                      >
                        <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                          <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                        </div>
                      </Dropdown>
                    </div>
                  </div>
                </TableTD>
              </TableRow>
            ))}

            {events.length === 0 && (
              <TableRow withoutHoverStyle>
                <TableTD colSpan={3} className="!border-0">
                  <NotData text="You have not created any Event yet" />
                </TableTD>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <S3EventEditorModal />
      <QuestionModal
        isOpen={deleteEvent !== null}
        onClose={() => setDeleteEvent(null)}
        onConfirm={handleDelete}
        loading={actionLoading}
        confirmButtonType="danger"
      />
      <DeleteModal
        title="Delete Event"
        isOpen={deleteEvent !== null}
        onClose={() => setDeleteEvent(null)}
        onConfirm={handleDelete}
        loading={actionLoading}
        confirmString={
          events.find((event) => event.account_id === deleteEvent?.account_id)
            ?.account_id
        }
        confirmButtonType="danger"
      />
    </>
  );
};

export default S3EventsTab;
