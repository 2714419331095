import Text from "../../../../general/Text";
import { ReactComponent as CheckIcon } from "./../../../../../assets/icons/check.svg";

const StatWidget: React.FunctionComponent<{
  value: string | number | React.ReactNode;
  dataLoading: boolean;
  title?: string;
  icon?: React.ReactNode;
}> = ({ value, dataLoading, title, icon }) => {
  return (
    <div className="flex flex-col justify-center w-full col-span-3 md:col-span-1 rounded-lg border dark:border-dark-2 bg-white dark:bg-dark-3 p-5 pb-3 group">
      <div className="flex items-center gap-3 mb-2 justify-between">
        <div className="flex items-center gap-1">
          {icon}
          <Text className="text-sm">{title}</Text>
        </div>
        <div className="flex items-center gap-2 text-slate-800 dark:text-slate-200 text-sm">
          {value}
          <CheckIcon className="w-3" />
        </div>
      </div>
    </div>
  );
};

export default StatWidget;
