import { useNavigate, useParams } from "react-router-dom";
import { BlockEventDestinationsUrl } from "../../utils/urls";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import classNames from "classnames";
import { ReactComponent as RightArrow } from "./../../assets/icons/arrow-right.svg";
import Text from "../../components/general/Text";
import { Helmet } from "react-helmet";
import {
  getDestinationAsync,
  getDestinationPayloadTypesAsync,
  getDestinationWebhookTypesAsync,
  selectDestination,
  selectDestinationLoading,
  selectDestinationsPayloadTypes,
  selectDestinationsPayloadTypesLoading,
  // selectDestinationsWebhookTypes,
  // selectDestinationsWebhookTypesLoading,
} from "../../store/block-event/destinationsSlice";
import Card from "../../components/general/Card";
import Tabs from "../../components/general/Tabs";
import JsonViewer from "../../components/general/JsonViewer";

const BlockEventDestinationPage = () => {
  const { destId } = useParams();
  const destination = useAppSelector(selectDestination);
  const destinationLoading = useAppSelector(selectDestinationLoading);

  // const webhookTypes = useAppSelector(selectDestinationsWebhookTypes);
  // const webhookTypesLoading = useAppSelector(
  //   selectDestinationsWebhookTypesLoading
  // );

  const payloadTypes = useAppSelector(selectDestinationsPayloadTypes);
  const payloadTypesLoading = useAppSelector(
    selectDestinationsPayloadTypesLoading
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (destination === null && destId) {
      dispatch(getDestinationAsync(destId));
    }
  }, [destination, dispatch, destId]);

  useEffect(() => {
    if (payloadTypes.length === 0) {
      dispatch(getDestinationPayloadTypesAsync());
    }
  }, [dispatch, payloadTypes.length]);

  // useEffect(() => {
  //   if (webhookTypes.length === 0) {
  //     dispatch(getDestinationWebhookTypesAsync());
  //   }
  // }, [dispatch, webhookTypes.length]);

  // const webhookType = useMemo(
  //   () => webhookTypes.find((wt) => wt.Value === destination?.Webhook_type),
  //   [destination?.Webhook_type, webhookTypes]
  // );

  const payloadType = useMemo(
    () => payloadTypes.find((pt) => pt.Id === destination?.Payload_type),
    [destination?.Payload_type, payloadTypes]
  );

  // console.log(webhookTypesLoading);
  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | Destinations - (
          {destination?.Name || ""})
        </title>
        <meta name="description" content="" />
      </Helmet>
      <div className="flex items-center justify-between h-20">
        <div className="px-6 items-center justify-between flex flex-1 transition duration-150">
          <div className="flex items-center gap-2">
            <RightArrow
              onClick={() => navigate(BlockEventDestinationsUrl)}
              className="rotate-180 w-5 h-5 hover:scale-110 transition-all duration-500 text-slate-800 dark:text-slate-100 cursor-pointer"
            />
            <div
              className={classNames(
                " dark:text-slate-100 min-w-[200px] h-8 rounded-md px-2 flex items-center",
                {
                  "bg-slate-300 dark:bg-slate-50/10 animate-pulse":
                    destinationLoading,
                }
              )}
            >
              <Text className="font-medium">{destination?.Name}</Text>
            </div>
          </div>
        </div>
        <div className="mr-6 w-96"></div>
      </div>
      <div className="mx-6 flex flex-col gap-7 pb-20">
        <Card title="Details">
          <div className="w-full md:w-3/4 lg:w-1/2">
            <div className="flex flex-col w-full  gap-4">
              <div className="flex flex-col md:flex-row md:items-center gap-1">
                <div className="w-36">
                  <Text className="text-sm whitespace-nowrap">Name:</Text>
                </div>
                <div className="flex-1">
                  <Text className="text-sm whitespace-nowrap">
                    {destination?.Name}
                  </Text>
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:items-center gap-1">
                <div className="w-36">
                  <Text className="text-sm whitespace-nowrap">URL:</Text>
                </div>
                <div className="flex-1">
                  <Text className="text-sm whitespace-nowrap">
                    {destination?.To_url}
                  </Text>
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:items-center gap-1">
                <div className="w-36">
                  <Text className="text-sm whitespace-nowrap">
                    Request type:
                  </Text>
                </div>
                <div className="flex-1">
                  <Text className="text-sm whitespace-nowrap">
                    {destination?.Webhook_type}
                  </Text>
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:items-center gap-1">
                <div className="w-36">
                  <Text className="text-sm whitespace-nowrap">
                    Payload size:
                  </Text>
                </div>
                <div className="flex-1">
                  <Text
                    loading={payloadTypesLoading}
                    className="text-sm whitespace-nowrap p-0.5"
                  >
                    {payloadType?.Id} - {payloadType?.Name}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </Card>
        <Card title="Example Response">
          <Tabs
            listClassName="max-w-xs mb-6"
            options={[
              {
                label: "EVM JSON Sample",
                element: (
                  <JsonViewer
                    value={payloadType ? JSON.parse(payloadType.Example) : null}
                  />
                ),
              },
              {
                label: "EVM Type Definition",
                element: (
                  <pre className="text-sm max-h-[400px] overflow-y-auto p-2 text-slate-600 dark:text-slate-300">
                    {payloadType ? payloadType.EvmTypeDefinition : null}
                  </pre>
                ),
              },
            ]}
            tabType="creamy"
          />
        </Card>
      </div>
    </>
  );
};
export default BlockEventDestinationPage;
