import { FormProvider, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { InstanceSshCreateSchema } from "../../../utils/validations";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createInstancesSshKeysAsync,
  getInstancesSshKeysAsync,
  handleInstancesSshKeyEditor,
  selectInstancesSshKeysActionLoading,
  selectShowInstancesSshKeysEditor,
} from "../../../store/instances/instancesSSHkeysSlice";
import Input from "../../inputs/Input";
import Textarea from "../../inputs/Textarea";
import Text, { A } from "../../general/Text";
import Button from "../../buttons/Button";
import Modal from "../../modals/Modal";

export const InstancesSskKeyEditorModal: React.FC = () => {
  const isOpen = useAppSelector(selectShowInstancesSshKeysEditor);

  const dispatch = useAppDispatch();

  const sshKeysActionLoading = useAppSelector(
    selectInstancesSshKeysActionLoading
  );
  const methods = useForm({
    resolver: yupResolver(InstanceSshCreateSchema()),
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = methods;

  const handleSubmitForm = (data: any) => {
    // console.log(data);
    dispatch(createInstancesSshKeysAsync({ data })).then((action) => {
      if (action.type === "instances/ssh-keys/create/fulfilled") {
        dispatch(getInstancesSshKeysAsync());
        handleClose();
      }
    });
  };

  const handleClose = () => {
    reset();
    dispatch(handleInstancesSshKeyEditor({ status: false }));
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      contentClassName="!max-w-xl overflow-hidden"
      title={"Add SSH key"}
    >
      <form className="mt-5" onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="flex flex-col gap-3">
          <Input
            label="SSH key name"
            inputProps={{ ...register("name") }}
            error={errors.name?.message}
          />
          <Textarea
            label="SSH key"
            textareaProps={{ rows: 6, ...register("publicKey") }}
            error={errors.publicKey?.message}
          />
          <Text className="text-xs my-2">
            SSH keys are required to connect to your service. Please refer to
            our <A>guide</A> to find out more. Your SSH key will be available
            for all regions and OVHcloud datacenters. Only RSA and ECDSA SSH
            keys are accepted. You cannot use ED25519 SSH keys.
          </Text>
          <div className="flex justify-end gap-2">
            <Button
              type="default"
              buttonProps={{
                onClick: (e: any) => {
                  handleClose();
                },
              }}
            >
              Cancel
            </Button>
            <Button type="primary" loading={sshKeysActionLoading}>
              Add a key
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
