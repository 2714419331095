import NotData from "../../layouts/NotData";
import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useState } from "react";
import Button from "../../buttons/Button";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right.svg";

import { useNavigate } from "react-router-dom";
import {
  DatabaseServiceGeneralInformationUrl,
  DatabaseServiceNamespacesCreateUrl,
} from "../../../utils/urls";
import Dropdown from "../../general/Dropdown";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import QuestionModal, { DeleteModal } from "../../modals/QuestionModal";
import Text, { HighlighterText } from "../../general/Text";
import { useSearch } from "../../../providers/SearchProvider";
import {
  getDatabaseServicesAsync,
  selectDatabaseServicesActionLoading,
  selectDatabaseServicesLoading,
} from "../../../store/database/servicesSlice";
import {
  deleteDBSNmaespaceAsync,
  getDBSNamespacesAsync,
  getDatabaseServiceAsync,
  handleShowNamespaceEditorModal,
  selectDatabaseService,
  selectNamespaces,
  selectNamespacesLoading,
  selectSelectedNamespace,
} from "../../../store/database/serviceSlice";
import { IDBSNamespaces } from "../../../types/database";
import { generateServicePermissions } from "../../../pages/databases/ServicePage";
import { ReactComponent as DeleteIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil-square.svg";
import DBSNamespaceEditorModal from "./DBSNamespaceEditorModal";
import { convertFromISO8601Duration } from "./DBSNamespaceCreateTab";

const DBSNamespacesTab = () => {
  const [deleteNamespace, setDeleteNamespace] = useState<string | null>(null);
  const [filteredNamespaces, setFilteredNamespaces] = useState<
    IDBSNamespaces[]
  >([]);

  const service = useAppSelector(selectDatabaseService);
  const namespaces = useAppSelector(selectNamespaces);
  const namespacesLoading = useAppSelector(selectNamespacesLoading);
  const selectednamespace = useAppSelector(selectSelectedNamespace);
  const loading = useAppSelector(selectDatabaseServicesLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (service) {
      const permissions = generateServicePermissions().users;
      if (!permissions.includes(service.engine)) {
        navigate(DatabaseServiceGeneralInformationUrl(service.id));
      } else {
        dispatch(
          getDBSNamespacesAsync({ id: service.id, engine: service.engine })
        );
      }
    }
  }, [dispatch, navigate, service]);

  useEffect(() => {
    const lookedUpFiles = namespaces.filter((namespace) =>
      namespace.name?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredNamespaces(lookedUpFiles);
  }, [dispatch, searchValue, namespaces]);

  // const isAnyUserDeleting = namespaces.some((namespace) => namespace.status === "DELETING");

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Database</title>
        <meta name="description" content="" />
      </Helmet>

      {service && (
        <>
          <div className="flex flex-col gap-3">
            <div className=" flex flex-col flex-1 transition duration-150">
              <Text className="font-medium text-lg">Namespaces</Text>
              <Text className="text-sm ">
                Point collections. The namespaces are either non-aggregated
                (includes all point clouds as they arrive) or aggregated (only
                one point is stored for each interval configured for the
                namespace).
              </Text>
            </div>

            <div className="flex justify-end">
              {service && namespaces && (
                <Button
                  type="primary"
                  buttonProps={{
                    onClick: () =>
                      navigate(DatabaseServiceNamespacesCreateUrl(service?.id)),
                    // disabled: isAnyUserDeleting,
                  }}
                  buttonClassName="group"
                >
                  Add a namespaces
                  <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
                </Button>
              )}
            </div>
          </div>
          <div className="mt-5 w-full">
            <Table loading={namespacesLoading}>
              <TableHead>
                <TableRow>
                  <TableTH lable="Name" />
                  <TableTH lable="Type" />
                  <TableTH lable="Retention period" />
                  <TableTH lable="Resolution" />
                  <TableTH lable="" />
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredNamespaces.map((namespace, i) => (
                  <TableRow key={i}>
                    <TableTD className="cursor-pointer">
                      <div className="flex items-center flex-row justify-between space-x-3">
                        <HighlighterText
                          searchValue={searchValue}
                          textToHighlight={namespace.name}
                        />
                      </div>
                    </TableTD>

                    <TableTD className="cursor-pointer">
                      <Text className="text-xs md:text-sm">
                        {namespace.type}
                      </Text>
                    </TableTD>
                    <TableTD className="cursor-pointer">
                      <Text className="text-xs">
                        {convertFromISO8601Duration(
                          namespace.retention.periodDuration
                        )}
                      </Text>
                    </TableTD>
                    <TableTD className="cursor-pointer">
                      <Text className="text-xs">
                        {convertFromISO8601Duration(namespace.resolution)}
                      </Text>
                    </TableTD>

                    <TableTD className="w-32 gap-1">
                      <div className="h-full w-full inline-flex items-center justify-center gap-1">
                        <div className="w-8 flex justify-center items-center ">
                          <Dropdown
                            positionClassName="!w-48"
                            menu={[
                              {
                                key: "1",
                                label: (
                                  <div className="flex items-center gap-1">
                                    <EditIcon className="w-4"></EditIcon>
                                    Edit
                                  </div>
                                ),
                                onClick: () =>
                                  dispatch(
                                    handleShowNamespaceEditorModal({
                                      namespace,
                                    })
                                  ),
                                disabled: loading,
                              },
                              {
                                type: "divider",
                              },
                              {
                                key: "end",
                                label: (
                                  <div className="flex items-center gap-1">
                                    <DeleteIcon className="w-4" />
                                    Delete
                                  </div>
                                ),
                                danger: true,
                                onClick: (_, close) => {
                                  close();
                                  setDeleteNamespace(namespace.id);
                                },
                              },
                            ]}
                            type="simple"
                          >
                            <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                              <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                            </div>
                          </Dropdown>
                        </div>
                        <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" />
                      </div>
                    </TableTD>
                  </TableRow>
                ))}

                {namespaces.length === 0 && !namespacesLoading && (
                  <TableRow withoutHoverStyle={true}>
                    <TableTD colSpan={10} className="!border-0">
                      <NotData text="You have not created any namespace yet" />
                    </TableTD>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>

          <DBSNamespaceEditorModal />

          <DeleteModal
            title="Delete a Namespace"
            isOpen={deleteNamespace !== null}
            onClose={() => setDeleteNamespace(null)}
            description="To confirm, type 'DELETE' in the box below"
            confirmButtonText="Confirm"
            confirmButtonType="danger"
            confirmButtonClassName="w-full"
            onConfirm={() => {
              deleteNamespace &&
                service &&
                dispatch(
                  deleteDBSNmaespaceAsync({
                    id: service.id,
                    engine: service.engine,
                    namespaceId: deleteNamespace,
                  })
                ).then((action) => {
                  if (action.type === "service/namespace/delete/fulfilled") {
                    dispatch(getDatabaseServicesAsync());
                    dispatch(getDatabaseServiceAsync({ id: service.id }));
                    dispatch(
                      getDBSNamespacesAsync({
                        id: service.id,
                        engine: service.engine,
                      })
                    );
                  } else {
                  }
                  setDeleteNamespace(null);
                });
            }}
            loading={namespacesLoading}
            confirmString={selectednamespace?.name}
          />
        </>
      )}
    </>
  );
};

export default DBSNamespacesTab;
