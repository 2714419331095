import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Instance } from "../../../types/instance";
import {
  deleteInstanceAsync,
  getInstancesAsync,
  selectInstances,
  selectInstancesActionLoading,
  selectInstancesLoading,
} from "../../../store/instances/instancesSlice";
import { useNavigate } from "react-router-dom";
import { useSearch } from "../../../providers/SearchProvider";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as DetailsIcon } from "./../../../assets/icons/bars-3-bottom-left.svg";
// import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil-square.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
// import { Helmet } from "react-helmet";
import Button from "../../buttons/Button";
import Text, { HighlighterText } from "../../general/Text";
import {
  InstanceOverviewUrl,
  InstancesCreateUrl,
  InstancesUrl,
} from "../../../utils/urls";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import Dropdown from "../../general/Dropdown";
import NotData from "../../layouts/NotData";
import QuestionModal, { DeleteModal } from "../../modals/QuestionModal";
import {
  getInstancesVolumesAsync,
  selectInstancesVolumes,
} from "../../../store/instances/instancesVolumesSlice";
import {
  handleInstanceDeleteHideModal,
  handleInstanceDeleteShowModal,
  selectInstance,
  selectInstanceBackupsLoading,
  selectShowDeleteModal,
} from "../../../store/instances/instanceSlice";

const InstancesTab = () => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [filteredInstances, setFilteredInstances] = useState<Instance[]>([]);
  const instances = useAppSelector(selectInstances);
  const loading = useAppSelector(selectInstancesLoading);
  const volumes = useAppSelector(selectInstancesVolumes);
  const instance = useAppSelector(selectInstance);
  const isOpen = useAppSelector(selectShowDeleteModal);
  const instanceBackupsLoading = useAppSelector(selectInstanceBackupsLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    dispatch(getInstancesAsync({ withoutLoading: false }));
  }, [dispatch]);

  useEffect(() => {
    const lookedUpFiles = instances.filter((instance) =>
      instance.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredInstances(lookedUpFiles);
  }, [dispatch, searchValue, instances]);

  useEffect(() => {
    if (volumes.length === 0) {
      dispatch(getInstancesVolumesAsync({}));
    }
  }, [dispatch]);

  useEffect(() => {
    let interval: any;

    const statusesToCheck = [
      "SHELVING",
      "UNSHELVING",
      "BUILD",
      "RESCUING",
      "UNRESCUING",
      "REBOOT",
      "HARD_REBOOT",
      "REBUILD",
    ];
    const shouldSetInterval = instances.some((instance) =>
      statusesToCheck.includes(instance?.status)
    );

    if (shouldSetInterval) {
      interval = setInterval(() => {
        dispatch(getInstancesAsync({ withoutLoading: true }));
      }, 20000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, instances]);

  const handleDeleteInstance = async () => {
    if (instance) {
      setDeleteLoading(true);
      const action = await dispatch(deleteInstanceAsync({ id: instance.id }));
      if (action.type === "instances/delete/fulfilled") {
        dispatch(getInstancesAsync({ withoutLoading: false }));
        navigate(InstancesUrl);
        handleClose();
      }
      setDeleteLoading(false);
    }
  };

  const handleClose = () => {
    dispatch(handleInstanceDeleteHideModal());
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="items-center justify-between flex flex-1 transition duration-150">
          {/* <Text className="font-medium text-lg">Instances</Text> */}
        </div>
        <div className="flex items-center gap-2">
          <Button
            size="medium"
            type="light"
            buttonProps={{
              onClick: () =>
                dispatch(getInstancesAsync({ withoutLoading: true })),
            }}
            buttonClassName="group"
            tooltip="Refresh"
          >
            <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
          <Button
            type="primary"
            buttonProps={{
              onClick: () => navigate(InstancesCreateUrl),
            }}
            buttonClassName="group"
          >
            Create an instance
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>

      <div className="mt-5 w-full">
        <Table loading={loading}>
          <TableHead>
            <TableRow>
              <TableTH lable="Name/ID" />
              <TableTH lable="Location" />
              <TableTH lable="Template" />
              <TableTH lable="Image" />
              <TableTH lable="Public IPs" />
              <TableTH lable="Private IPs" />
              <TableTH lable="Volumes" />
              <TableTH lable="Status" />
              <TableTH lable="" />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredInstances.map((instance, i) => (
              <TableRow
                key={i}
                onClick={() => navigate(InstanceOverviewUrl(instance.id))}
              >
                <TableTD className="cursor-pointer">
                  <div className="flex flex-col gap-1">
                    <HighlighterText
                      searchValue={searchValue}
                      textToHighlight={instance.name}
                    />
                    <HighlighterText
                      searchValue={searchValue}
                      textToHighlight={instance.id}
                      className="!text-xs"
                    />
                  </div>
                </TableTD>
                <TableTD className="cursor-pointer">
                  <Text className="text-xs md:text-sm">{instance.region}</Text>
                </TableTD>

                <TableTD className="cursor-pointer">
                  <Text className="text-xs md:text-sm">
                    {instance.planCode?.split(".")[0]}
                  </Text>
                </TableTD>

                <TableTD className="cursor-pointer">
                  <Text className="text-xs md:text-sm whitespace-nowrap">
                    {instance?.image?.name}
                  </Text>
                </TableTD>

                <TableTD className="cursor-pointer">
                  <Text className="text-xs md:text-sm">
                    {
                      instance.ipAddresses.filter(
                        (ip) => ip.version === 4 && ip.type === "public"
                      )[0]?.ip
                    }
                  </Text>
                </TableTD>

                <TableTD className="cursor-pointer">
                  <Text className="text-xs md:text-sm">
                    {
                      instance.ipAddresses.filter(
                        (ip) => ip.version === 4 && ip.type === "private"
                      )[0]?.ip
                    }
                  </Text>
                </TableTD>

                <TableTD className="cursor-pointer">
                  <Text className="text-xs md:text-sm">
                    {volumes
                      ? volumes
                          .filter(
                            (volume) => volume?.attachedTo[0] === instance.id
                          )
                          ?.map((v, j) => (
                            <div className="flex flex-col" key={j}>
                              {v.name}
                            </div>
                          ))
                      : " "}
                  </Text>
                </TableTD>
                <TableTD className="cursor-pointer">
                  <Text
                    className="text-xs"
                    type={
                      instance.status === "ACTIVE"
                        ? "success-alert"
                        : "warning-alert"
                    }
                  >
                    {instance.status}
                  </Text>
                </TableTD>

                <TableTD className="w-32 gap-1">
                  <div className="h-full w-full inline-flex items-center justify-center gap-1">
                    <div className="w-8 flex justify-center items-center">
                      <Dropdown
                        positionClassName="!w-48"
                        menu={[
                          {
                            key: "1",
                            label: (
                              <div className="flex items-center gap-1">
                                <DetailsIcon className="w-4" />
                                General Information
                              </div>
                            ),
                            onClick: () =>
                              navigate(InstanceOverviewUrl(instance.id)),
                            disabled: loading,
                          },
                          {
                            type: "divider",
                          },
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: (_, close) => {
                              close();
                              dispatch(handleInstanceDeleteShowModal(instance));
                            },
                            disabled: loading,
                          },
                        ]}
                        type="simple"
                      >
                        <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                          <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                        </div>
                      </Dropdown>
                    </div>
                    <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" />
                  </div>
                </TableTD>
              </TableRow>
            ))}

            {instances.length === 0 && (
              <TableRow withoutHoverStyle={true}>
                <TableTD colSpan={9} className="!border-0">
                  <NotData text="You have not created any instance yet" />
                </TableTD>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DeleteModal
        isOpen={isOpen}
        title="Delete instance"
        onClose={handleClose}
        description="To confirm, type 'DELETE' in the box below"
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        loading={loading || instanceBackupsLoading}
        onConfirm={handleDeleteInstance}
        confirmString={instance?.name}
      />
    </>
  );
};

export default InstancesTab;
