import { useNavigate } from "react-router-dom";
import Dropdown from "../general/Dropdown";
import { DropdownElement } from "../../types/dropdown";
import { ReactComponent as BellIcon } from "./../../assets/icons/bell.svg";
import { SettingsProfileUrl } from "../../utils/urls";
import Text from "../general/Text";

const NotifDropdown: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const notifDropdoemItems: Array<DropdownElement> = [
    {
      key: "1",
      label: (
        <div className="flex items-center gap-2">
          <div className="w-6 h-6 rounded-full aspect-square bg-primary-50 dark:bg-primary-100/10" />
          <Text>Notif text...</Text>
        </div>
      ),
      onClick: (_, close) => {
        close();
        navigate(SettingsProfileUrl);
      },
    },
  ];

  return (
    <Dropdown
      menu={notifDropdoemItems}
      type="simple"
      positionClassName="right-0"
    >
      <div className="flex items-center gap-2">
        <div className="w-5 h-5 aspect-square rounded-full overflow-hidden">
          <BellIcon className="w-full h-full  hover:scale-110" />
        </div>
      </div>
    </Dropdown>
  );
};
export default NotifDropdown;
