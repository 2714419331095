import { MouseEvent, useCallback, useEffect, useMemo, useState } from "react";

import {
  selectFiles,
  selectLoading,
  updateBreadCrumb,
  fetchFilesAysnc,
} from "../../../store/ipfs/filesSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { IpfsFile } from "../../../types/ipfs-file";
import File from "./File";
import { useSearch } from "../../../providers/SearchProvider";
import Modal from "../../modals/Modal";
import Loading from "../../general/Loading";
import { EmptyFileList } from "../../layouts/NotData";
import { GET_IPFS_FILE_URL, getExportApi } from "../../../apis/filesAPI";
import { selectOnStageEnv } from "../../../store/auth/authSlice";
import {
  downloadFileAsync,
  selectBreadCrumbItems,
} from "../../../store/ipfs/filesSlice";
import { FileTypes } from "../../../types";
import { copyToClipboard } from "../../../utils/copy";
import { fileParentFinder } from "../../../utils/file";

function FileList() {
  const files = useAppSelector(selectFiles);
  const [filteredFiles, setSearchedFiles] = useState<IpfsFile[]>([]);
  const filesLoading = useAppSelector(selectLoading);

  const breadCrumbItems = useAppSelector(selectBreadCrumbItems);
  const onStageEnv = useAppSelector(selectOnStageEnv);

  const [showFile, setShowFile] = useState<IpfsFile | undefined>(undefined);
  const [downloadLoadings, setDownloadLoadings] = useState<string[]>([]);
  const [exportLoadings, setExportLoadings] = useState<string[]>([]);

  const { value: searchValue } = useSearch();
  const dispatch = useAppDispatch();

  const currentPath = useMemo(() => {
    return fileParentFinder(breadCrumbItems);
  }, [breadCrumbItems]);

  useEffect(() => {
    const lookedUpFiles = files.filter((file) =>
      file.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setSearchedFiles(lookedUpFiles);
  }, [dispatch, searchValue, files]);

  const handleFile = useCallback(
    (
      file: IpfsFile,
      e: MouseEvent<HTMLDivElement>,
      fileType: FileTypes | undefined
    ) => {
      e.preventDefault();
      e.stopPropagation();
      if (file.is_folder) {
        dispatch(updateBreadCrumb({ title: file.name }));
        dispatch(fetchFilesAysnc());
      }

      if (fileType === "photo") {
        setShowFile(file);
      }
    },
    [dispatch]
  );

  const handleDownload = useCallback(
    (file: IpfsFile) => {
      setDownloadLoadings((prev) => [...prev, file.name]);
      dispatch(downloadFileAsync({ file, path: currentPath })).then(() => {
        setDownloadLoadings((prev) => {
          return prev.filter((d) => d !== file.name);
        });
      });
    },
    [currentPath, dispatch]
  );

  const isDownloadLoading = useCallback(
    (fileName: string) => {
      return !!downloadLoadings.find((dl) => dl === fileName);
    },
    [downloadLoadings]
  );

  const handleExport = useCallback(
    async (file: IpfsFile) => {
      setExportLoadings((prev) => [...prev, file.name]);
      try {
        await getExportApi(file, currentPath);
      } catch (e) {
      } finally {
        setExportLoadings((prev) => {
          return prev.filter((d) => d !== file.name);
        });
      }
    },
    [currentPath]
  );

  const isExportLoading = useCallback(
    (fileName: string) => {
      return !!exportLoadings.find((dl) => dl === fileName);
    },
    [exportLoadings]
  );

  const handleCopyToClipboard = useCallback(
    (file: IpfsFile) => {
      if (file.is_folder || file.public_cid === null) return;
      copyToClipboard(
        GET_IPFS_FILE_URL(file.public_cid, onStageEnv?.IpfsAddress),
        "Copied"
      );
    },
    [onStageEnv?.IpfsAddress]
  );

  return (
    <>
      <>
        <div className="pt-0 px-0 pb-4 grid grid-cols-5">
          {/* {!!files?.length && ( */}
          <>
            <div className="col-span-2 border-b dark:border-dark-2 text-slate-400 dark:text-slate-100 font-light overflow-hidden whitespace-nowrap p-2 md:px-4 text-ellipsis">
              Name
            </div>
            <div className="col-span-2 border-b dark:border-dark-2 text-slate-400 dark:text-slate-100 font-light overflow-hidden whitespace-nowrap p-2 md:px-4 text-ellipsis col-size">
              Size
            </div>
            {/* <div className="col-span-1 border-b dark:border-dark-2 text-slate-600 dark:text-slate-200 border-slate-300 dark:border-slate-700 font-bold text-sm p-2 md:px-4 text-ellipsis overflow-hidden whitespace-nowrap col-lastModified">
              Last Modified
            </div> */}
            <div className="col-span-1 border-b dark:border-dark-2 text-slate-400 dark:text-slate-100 font-light overflow-hidden whitespace-nowrap p-2 md:px-4 text-ellipsis col-action"></div>
          </>
          {/* )} */}

          {!filesLoading &&
            filteredFiles?.map((file, i) => (
              <File
                key={`file-${file.public_cid}-${i}`}
                file={file}
                name={file.name}
                size={file.size_byte || 0}
                downloadLoading={isDownloadLoading(file.name)}
                exportLoading={isExportLoading(file.name)}
                onOpenFile={handleFile}
                onDownload={handleDownload}
                onExport={handleExport}
                onCopy={handleCopyToClipboard}
              />
            ))}
        </div>
        {filesLoading ? (
          <Loading borderSize={2} style={{ minHeight: 170 }} />
        ) : (
          <>{!files.length && <EmptyFileList />}</>
        )}
      </>
      <Modal
        title={""}
        contentClassName="w-full max-w-2xl"
        // containerStyle={{ alignItems: "flex-start", overflowY: "auto" }}
        isOpen={!!showFile}
        onClose={() => setShowFile(undefined)}
      >
        <div className="w-full min-h-[100px]">
          {showFile && showFile.public_cid && (
            <img
              alt={showFile?.name}
              src={`${GET_IPFS_FILE_URL(
                showFile.public_cid,
                onStageEnv?.IpfsAddress
              )}`}
            />
          )}
        </div>
      </Modal>
    </>
  );
}

export default FileList;
