import { useRef } from "react";
import FileIcon, { extToType } from "./../../general/FileIcon";
import { humanizeSize } from "../../../utils/file";
import Highlighter from "react-highlight-words";
import {
  getBucketObjectsAsync,
  handleChangeCheckedObjects,
  handleClearCheckedObjects,
  updateBreadCrumb,
} from "../../../store/s3/buckets/bucketSlice";
import {
  handleHideObjectDrawer,
  handleShowObjectDrawer,
  selectSelectedObject,
} from "../../../store/s3/buckets/objectSlice";
import { dateConvertor } from "../../../utils/date";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useSearch } from "../../../providers/SearchProvider";
import { S3BucketDetails, S3BucketObject } from "../../../types/s3-bucket";
import {
  objectIsFolder,
  getFolderName,
  getFileNameAndExt,
} from "../../../utils/bucket";
import { FileTypes } from "../../../types";
import classNames from "classnames";

export type BucketObjectProps = {
  object: S3BucketObject;
  bucketDetails: S3BucketDetails | null;
  checked?: boolean;
  onOpenFile?: (object: S3BucketObject) => void;
  onDeleteFile?: (object: S3BucketObject) => void;
  onOpenError?: () => void;
};

function BucketObject({ object, checked, bucketDetails }: BucketObjectProps) {
  const ref = useRef(null);

  //for show details in the object-drawer component
  const selectedObject = useAppSelector(selectSelectedObject);
  const dispatch = useAppDispatch();
  const { value: searchValue } = useSearch();

  function handleFile(object: S3BucketObject, e: any) {
    dispatch(handleClearCheckedObjects());
    if (objectIsFolder(object.name)) {
      const folderName = getFolderName(object.name);
      if (folderName) {
        dispatch(updateBreadCrumb({ title: folderName }));
        dispatch(handleHideObjectDrawer());
        if (bucketDetails) {
          dispatch(getBucketObjectsAsync({ name: bucketDetails.name }));
        }
      }
    } else {
      dispatch(handleShowObjectDrawer({ object }));
    }
  }

  function handleChangeChecked(e: any) {
    e.stopPropagation();
    dispatch(handleHideObjectDrawer());
    dispatch(handleChangeCheckedObjects(object));
  }

  return (
    <div
      className={classNames(
        "grid grid-cols-4 cursor-pointer col-span-full text-[#475569] dark:text-slate-100",
        {
          "hover:bg-[#f8fafc] dark:hover:bg-dark-2":
            object.name !== selectedObject?.name,
          "bg-primary-50 dark:bg-dark-1": object.name === selectedObject?.name,
        }
      )}
      ref={ref}
      onClick={(e) => handleFile(object, e)}
    >
      <div className="border-b col-span-2 flex items-center border-slate-300/60 dark:border-dark-2 p-3 md:px-4 w-full text-ellipsis whitespace-nowrap overflow-hidden gap-1 col-name">
        <input
          id="default-checkbox"
          type="checkbox"
          checked={checked || false}
          onChange={() => {}}
          className="block w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 rounded ring-0 dark:bg-gray-700 dark:border-gray-600"
          onClick={handleChangeChecked}
        />
        <FileIcon
          type={
            objectIsFolder(object.name)
              ? "folder"
              : (extToType(
                  getFileNameAndExt(object.name)?.fileExt || ""
                ) as FileTypes)
          }
        />
        <Highlighter
          highlightClassName="bg-primary-100 dark:bg-primary-300 rounded-sm"
          searchWords={[searchValue]}
          autoEscape={true}
          textToHighlight={
            objectIsFolder(object.name)
              ? getFolderName(object.name) || ""
              : `${getFileNameAndExt(object.name)?.fileName || ""}.${
                  getFileNameAndExt(object.name)?.fileExt || ""
                }`
          }
        />
      </div>
      <div className="border-b col-span-1 flex items-center border-slate-300/60 dark:border-dark-2 p-3 md:px-4 w-full text-ellipsis whitespace-nowrap overflow-hidden col-size">
        {object.size !== 0
          ? humanizeSize(Number(object.size), { fractionDigits: 2 }).join("B")
          : ""}
      </div>
      <div className="border-b col-span-1 flex items-center border-slate-300/60 dark:border-dark-2 p-3 md:px-4 w-full text-ellipsis whitespace-nowrap overflow-hidden col-lastModified">
        {object.last_modified && dateConvertor(object.last_modified)}
      </div>
    </div>
  );
}

export default BucketObject;
