import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import Modal from "../../../../modals/Modal";
import Button from "../../../../buttons/Button";
import { useState } from "react";

import {
  getObjectInspectAsync,
  handleHideObjectInspectModal,
  selectObjectInspectLoading,
  selectObjectVersions,
  selectObjectVersionsLoading,
  selectShowObjectInspectModal,
} from "../../../../../store/s3/buckets/objectSlice";
import { getMainVersionOfObject } from "../../../../../utils/bucket";
import Switcher from "../../../../inputs/Switcher";
import { selectBucketDetails } from "../../../../../store/s3/buckets/bucketSlice";
import Text from "../../../../general/Text";

const S3ObjectInspectModal = () => {
  const isOpen = useAppSelector(selectShowObjectInspectModal);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const versions = useAppSelector(selectObjectVersions);
  const versionsLoading = useAppSelector(selectObjectVersionsLoading);
  const mainVersion = getMainVersionOfObject(versions);
  const inspectLoading = useAppSelector(selectObjectInspectLoading);
  const [enabledEncrypt, setEnabledEncrypt] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { handleSubmit, reset } = useForm();

  const handleClearComponent = () => {
    reset();
    setEnabledEncrypt(false);
  };

  const onSubmit = () => {
    if (mainVersion && mainVersion.version_id && bucketDetails) {
      dispatch(
        getObjectInspectAsync({
          bucketName: bucketDetails.name,
          prefix: mainVersion.name,
          encrypt: enabledEncrypt,
        })
      ).then((action) => {
        if (action.type === "object/inspect/fulfilled") {
          handleClearComponent();
          dispatch(handleHideObjectInspectModal());
        }
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleHideObjectInspectModal());
        handleClearComponent();
      }}
      contentClassName="max-w-lg"
      // containerClassName="!items-start"
      title={"Inspect Object"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col w-full gap-5">
          <div className="flex items-center justify-between w-full">
            <Text className="text-sm flex gap-0.5">
              Would you like to encrypt?{" "}
            </Text>
            <Switcher on={enabledEncrypt} onToggle={setEnabledEncrypt} />
          </div>
          <Text className="font-normal text-base">
            File: {mainVersion?.name}
          </Text>
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button
            buttonProps={{
              onClick: (e) => {
                e.preventDefault();
                dispatch(handleHideObjectInspectModal());
              },
            }}
            type="light"
            buttonClassName="w-[70px]"
          >
            Cancel
          </Button>
          <Button
            loading={versionsLoading || inspectLoading}
            type="primary"
            buttonProps={{
              disabled: versionsLoading || inspectLoading,
              type: "submit",
            }}
            buttonClassName="w-[70px]"
          >
            Inspect
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default S3ObjectInspectModal;
