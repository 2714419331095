import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import Modal from "../../../../modals/Modal";
import Button from "../../../../buttons/Button";
import { useEffect, useState } from "react";

import {
  getObjectVersionsAsync,
  handleHideObjectLegalHoldModal,
  selectObjectLegalHoldLoading,
  selectObjectVersions,
  selectObjectVersionsLoading,
  selectShowObjectLegalHoldModal,
  setObjectLegalHoldAsync,
} from "../../../../../store/s3/buckets/objectSlice";
import { getMainVersionOfObject } from "../../../../../utils/bucket";
import Switcher from "../../../../inputs/Switcher";
import { selectBucketDetails } from "../../../../../store/s3/buckets/bucketSlice";

const S3ObjectLegalHoldModal = () => {
  const isOpen = useAppSelector(selectShowObjectLegalHoldModal);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const versions = useAppSelector(selectObjectVersions);
  const versionsLoading = useAppSelector(selectObjectVersionsLoading);
  const mainVersion = getMainVersionOfObject(versions);
  const legalHoldLoading = useAppSelector(selectObjectLegalHoldLoading);
  const [enabledLegalHold, setEnabledLegalHold] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (mainVersion) {
      setEnabledLegalHold(mainVersion.legal_hold_status === "ON");
    }
  }, [mainVersion]);

  const { handleSubmit, reset } = useForm();

  const handleClearComponent = () => {
    reset();
    setEnabledLegalHold(false);
  };

  const onSubmit = (data: any) => {
    if (mainVersion && mainVersion.version_id && bucketDetails) {
      dispatch(
        setObjectLegalHoldAsync({
          bucketName: bucketDetails.name,
          prefix: mainVersion.name,
          versionId: mainVersion.version_id,
          status: enabledLegalHold ? "enabled" : "disabled",
        })
      ).then((action) => {
        if (action.type === "object/legal-hold/set/fulfilled") {
          handleClearComponent();
          dispatch(handleHideObjectLegalHoldModal());
          dispatch(
            getObjectVersionsAsync({
              bucketName: bucketDetails.name,
              prefix: mainVersion.name,
            })
          );
        }
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleHideObjectLegalHoldModal());
        handleClearComponent();
      }}
      contentClassName="max-w-lg"
      // containerClassName="!items-start"
      title={"Set Legal Hold"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col w-full gap-5">
          <div className="flex items-center justify-between w-full">
            <div className="text-sm text-slate-700 dark:text-slate-200">
              Legal Hold Status
            </div>
            <Switcher on={enabledLegalHold} onToggle={setEnabledLegalHold} />
          </div>
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button
            buttonProps={{
              onClick: (e) => {
                e.preventDefault();
                dispatch(handleHideObjectLegalHoldModal());
              },
            }}
            type="light"
            buttonClassName="w-[70px]"
          >
            Cancel
          </Button>
          <Button
            loading={versionsLoading || legalHoldLoading}
            type="primary"
            buttonProps={{
              disabled: versionsLoading || legalHoldLoading,
              type: "submit",
            }}
            buttonClassName="w-[70px]"
          >
            Set
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default S3ObjectLegalHoldModal;
