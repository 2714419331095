import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  InstanceVolume,
  InstanceVolumeBackup,
  InstancesVolumeSnapshot,
  InstancesVolumeState,
} from "../../types/instance";
import { IThunkRejectValue, RootState } from "../../types";
import {
  deleteInstanceBackupSnapshotApi,
  deleteInstanceVolumeBackupListApi,
  deleteInstanceVolumeSnapshotListApi,
  getInstanceVolumeBackupListApi,
  getInstanceVolumeSnapshotListApi,
  restoreInstanceVolumeBackupListApi,
} from "../../apis/instancesAPI";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import toast from "react-hot-toast";

const initialState: InstancesVolumeState = {
  volume: null,
  volumeLoading: false,
  volumeActionLoading: false,

  volumeBackups: [],
  volumeBackupsLoading: false,

  selectedVolumeBackup: null,
  selectedVolumeBackupLoading: false,

  volumeSnapshots: [],
  volumeSnapshotsLoading: false,

  selectedVolumeSnapshot: null,
  selectedVolumeSnapshotLoading: false,

  showVolumeBackupModal: false,
  showVolumeSnapshotModal: false,
};

export const getInstanceVolumeBackupListAsync = createAsyncThunk<
  { volumeBackups: InstanceVolumeBackup[] },
  { volumeId: string; regionName: string; withoutLoading?: boolean },
  IThunkRejectValue
>(
  "instances/volume/backups/list",
  async (
    { volumeId, regionName, withoutLoading },
    { rejectWithValue, fulfillWithValue, requestId, dispatch }
  ) => {
    try {
      dispatch(
        getInstanceVolumeBackupListAsync.pending(requestId, {
          volumeId,
          regionName,
          withoutLoading,
        })
      );
      const response = await getInstanceVolumeBackupListApi(
        volumeId,
        regionName
      );
      const volumeBackups = response.data.Result;
      return fulfillWithValue({ volumeBackups });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteInstancesVolumeBackupAsync = createAsyncThunk<
  boolean,
  { volumeId: string; regionName: string; volumeBackupId: string },
  IThunkRejectValue
>(
  "instances/volume/backup/delete",
  async ({ volumeId, regionName, volumeBackupId }, { rejectWithValue }) => {
    try {
      await deleteInstanceVolumeBackupListApi(
        volumeId,
        regionName,
        volumeBackupId
      );
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const restoreInstancesVolumeBackupAsync = createAsyncThunk<
  boolean,
  { volumeId: string; regionName: string; volumeBackupId: string; data: any },
  IThunkRejectValue
>(
  "instances/volume/backup/restore",
  async (
    { volumeId, regionName, volumeBackupId, data },
    { rejectWithValue }
  ) => {
    try {
      await restoreInstanceVolumeBackupListApi(
        volumeId,
        regionName,
        volumeBackupId,
        data
      );
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getInstanceVolumeSnapshotListAsync = createAsyncThunk<
  { volumeSnapshots: InstancesVolumeSnapshot[] },
  { volumeId: string; withoutLoading?: boolean },
  IThunkRejectValue
>(
  "instances/volume/snapshots/list",
  async (
    { volumeId, withoutLoading },
    { rejectWithValue, fulfillWithValue, requestId, dispatch }
  ) => {
    try {
      dispatch(
        getInstanceVolumeSnapshotListAsync.pending(requestId, {
          volumeId,
          withoutLoading,
        })
      );
      const response = await getInstanceVolumeSnapshotListApi(volumeId);
      const volumeSnapshots = response.data.Result;
      return fulfillWithValue({ volumeSnapshots });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteInstancesVolumeSnapshotAsync = createAsyncThunk<
  boolean,
  { volumeId: string; snapshotId: string },
  IThunkRejectValue
>(
  "instances/volume/snapshot/delete",
  async ({ volumeId, snapshotId }, { rejectWithValue }) => {
    try {
      await deleteInstanceVolumeSnapshotListApi(volumeId, snapshotId);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

const instancesVolumeSlice = createSlice({
  name: "instances-volume",
  initialState,
  reducers: {
    handleHideVolumeBackupModal: (state) => {
      state.showVolumeBackupModal = false;
    },
    handleShowVolumeBackupModal: (
      state,
      action: PayloadAction<{ volume: InstanceVolume }>
    ) => {
      state.showVolumeBackupModal = true;
      state.volume = action.payload.volume;
    },
    handleHideVolumeSnapshorModal: (state) => {
      state.showVolumeSnapshotModal = false;
    },
    handleShowVolumeSnapshotModal: (
      state,
      action: PayloadAction<{ volume: InstanceVolume }>
    ) => {
      state.showVolumeSnapshotModal = true;
      state.volume = action.payload.volume;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInstanceVolumeBackupListAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.volumeBackupsLoading = true;
      })
      .addCase(getInstanceVolumeBackupListAsync.fulfilled, (state, action) => {
        state.volumeBackupsLoading = false;
        state.volumeBackups = action.payload.volumeBackups;
      })
      .addCase(
        getInstanceVolumeBackupListAsync.rejected,
        (state, { payload }) => {
          state.volumeBackupsLoading = false;
          toast.error(() => CustomErrorToast(payload?.message));
        }
      );
    builder
      .addCase(deleteInstancesVolumeBackupAsync.pending, (state) => {
        state.volumeBackupsLoading = true;
      })
      .addCase(deleteInstancesVolumeBackupAsync.fulfilled, (state, action) => {
        state.volumeBackupsLoading = false;
        // state.selectedVolumeBackup = null;
      })
      .addCase(
        deleteInstancesVolumeBackupAsync.rejected,
        (state, { payload }) => {
          state.volumeBackupsLoading = false;
          toast.error(() => CustomErrorToast(payload?.message));
        }
      );
    builder
      .addCase(restoreInstancesVolumeBackupAsync.pending, (state) => {
        state.volumeBackupsLoading = true;
      })
      .addCase(restoreInstancesVolumeBackupAsync.fulfilled, (state) => {
        state.volumeBackupsLoading = false;
      })
      .addCase(
        restoreInstancesVolumeBackupAsync.rejected,
        (state, { payload }) => {
          state.volumeBackupsLoading = false;
          toast.error(() => CustomErrorToast(payload?.message));
        }
      );
    builder
      .addCase(getInstanceVolumeSnapshotListAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading)
          state.volumeSnapshotsLoading = true;
      })
      .addCase(
        getInstanceVolumeSnapshotListAsync.fulfilled,
        (state, action) => {
          state.volumeSnapshotsLoading = false;
          state.volumeSnapshots = action.payload.volumeSnapshots;
        }
      )
      .addCase(
        getInstanceVolumeSnapshotListAsync.rejected,
        (state, { payload }) => {
          state.volumeSnapshotsLoading = false;
          toast.error(() => CustomErrorToast(payload?.message));
        }
      );
    builder
      .addCase(deleteInstancesVolumeSnapshotAsync.pending, (state) => {
        state.volumeSnapshotsLoading = true;
      })
      .addCase(
        deleteInstancesVolumeSnapshotAsync.fulfilled,
        (state, action) => {
          state.volumeSnapshotsLoading = false;
          // state.selectedVolumeSnapshot = null;
        }
      )
      .addCase(
        deleteInstancesVolumeSnapshotAsync.rejected,
        (state, { payload }) => {
          state.volumeSnapshotsLoading = false;
          toast.error(() => CustomErrorToast(payload?.message));
        }
      );
  },
});

export const selectInstancesVolumeBackupsList = (state: RootState) =>
  state.instancesVolume.volumeBackups;
export const selectInstancesVolumeBackupsListLoading = (state: RootState) =>
  state.instancesVolume.volumeBackupsLoading;

export const selectInstancesVolumeSnapshotsList = (state: RootState) =>
  state.instancesVolume.volumeSnapshots;
export const selectInstancesVolumeSnapshotsListLoading = (state: RootState) =>
  state.instancesVolume.volumeSnapshotsLoading;
export const selectSelectedInstancesVolumeSnapshot = (state: RootState) =>
  state.instancesVolume.selectedVolumeSnapshot;
export const selectSelectedInstancesVolumeSnapshotLoading = (
  state: RootState
) => state.instancesVolume.selectedVolumeSnapshotLoading;

export const selectshowVolumeBackupModal = (state: RootState) =>
  state.instancesVolume.showVolumeBackupModal;
export const selectshowVolumeSnapshotModal = (state: RootState) =>
  state.instancesVolume.showVolumeSnapshotModal;

export const {
  // handleSetSelectedInstanceVolumeBackup,
  handleHideVolumeBackupModal,
  handleShowVolumeBackupModal,
  handleShowVolumeSnapshotModal,
  handleHideVolumeSnapshorModal,
} = instancesVolumeSlice.actions;
export default instancesVolumeSlice.reducer;
