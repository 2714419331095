import { useEffect, useState } from "react";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../../general/Table";
import { ReactComponent as MoreIcon } from "./../../../../assets/icons/more.svg";
import { ReactComponent as ArchiveIcon } from "./../../../../assets/icons/archive-box.svg";

import {
  selectBucketDetails,
  selectBucketDetailsLoading,
} from "../../../../store/s3/buckets/bucketSlice";

import {
  deleteBucketAnonymousAccessAsync,
  getBucketAnonymousesAsync,
  handleShowAnonymousModal,
  selectBucketAnonymouses,
  selectBucketAnonymousesActionLoading,
  selectBucketAnonymousesLoading,
} from "../../../../store/s3/buckets/setting/anonymousSlice";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import NotData, { S3NotActiveTab } from "../../../layouts/NotData";
import QuestionModal, { DeleteModal } from "../../../modals/QuestionModal";
import Dropdown from "../../../general/Dropdown";
import Button from "../../../buttons/Button";
import S3AnonymousAccessModal from "../modals/buckets/S3AnonymousAccessModal";
import Text from "../../../general/Text";
import { selectS3Status } from "../../../../store/s3/s3PublicSlice";
import { S3Anonymous, S3AnonymousDelete } from "../../../../types/s3-anonymous";
import { ReactComponent as EditIcon } from "./../../../../assets/icons/pencil-square.svg";

const S3BucketAnonymousTab = () => {
  const s3Status = useAppSelector(selectS3Status);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const bucketDetailsLoading = useAppSelector(selectBucketDetailsLoading);

  const anonymouses = useAppSelector(selectBucketAnonymouses);
  const loading = useAppSelector(selectBucketAnonymousesLoading);
  const actionLoading = useAppSelector(selectBucketAnonymousesActionLoading);

  const [deleteAnonymous, setDeleteAnonymous] = useState<S3Anonymous | null>(
    null
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (bucketDetails && !bucketDetailsLoading) {
      dispatch(getBucketAnonymousesAsync({ name: bucketDetails.name }));
    }
  }, [bucketDetails, dispatch, bucketDetailsLoading]);

  const handleDelete = () => {
    if (deleteAnonymous && bucketDetails) {
      const deleteData: S3AnonymousDelete = {
        prefix: deleteAnonymous.prefix || "/",
      };
      dispatch(
        deleteBucketAnonymousAccessAsync({
          bucketName: bucketDetails.name,
          data: deleteData,
        })
      ).then((action) => {
        if (action.type === "bucket/anonymous-access/delete/fulfilled") {
          dispatch(getBucketAnonymousesAsync({ name: bucketDetails.name }));
          setDeleteAnonymous(null);
        }
      });
    }
  };

  if (!s3Status) {
    return <S3NotActiveTab />;
  }
  return (
    <>
      <div className="flex transition-all duration-500 items-center justify-between gap-3">
        <Text className="whitespace-nowrap font-medium">Anonymous Access</Text>
        <div className="flex items-center justify-end gap-1 md:gap-2 ">
          {" "}
          <Button
            type="primary"
            buttonProps={{
              onClick: () => dispatch(handleShowAnonymousModal({})),
            }}
          >
            Add Access Rule
          </Button>
        </div>
      </div>
      <div className="mt-5 w-full">
        <Table loading={loading}>
          <TableHead>
            <TableRow>
              <TableTH lable="Prefix" />
              <TableTH lable="Access" />
              <TableTH lable="" />
            </TableRow>
          </TableHead>
          <TableBody>
            {anonymouses?.map((anonymouse, i) => (
              <TableRow key={i}>
                <TableTD>
                  <Text className="text-xs md:text-sm font-semibold whitespace-nowrap">
                    {anonymouse.prefix || "/"}
                  </Text>
                </TableTD>
                <TableTD>
                  <Text className="text-xs md:text-sm whitespace-nowrap">
                    {anonymouse.access}
                  </Text>
                </TableTD>
                <TableTD className="w-32 gap-1">
                  <div className="h-full w-full inline-flex items-center justify-end gap-1">
                    <div className="w-8 flex justify-center items-center">
                      <Dropdown
                        positionClassName="!w-40"
                        menu={[
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <EditIcon className="w-4" />
                                Edit
                              </div>
                            ),
                            onClick: () =>
                              dispatch(
                                handleShowAnonymousModal({ anonymouse })
                              ),
                          },
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: (_, close) => {
                              close();
                              setDeleteAnonymous(anonymouse);
                            },
                          },
                        ]}
                        type="simple"
                      >
                        <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                          <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                        </div>
                      </Dropdown>
                    </div>
                  </div>
                </TableTD>
              </TableRow>
            ))}

            {anonymouses?.length === 0 && (
              <TableRow withoutHoverStyle={true}>
                <TableTD colSpan={3} className="!border-0">
                  <NotData />
                </TableTD>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <S3AnonymousAccessModal />

      <DeleteModal
        title="Delete Anonymous Access"
        isOpen={deleteAnonymous !== null}
        onClose={() => setDeleteAnonymous(null)}
        onConfirm={handleDelete}
        confirmButtonType="danger"
        loading={actionLoading || bucketDetailsLoading}
        // confirmString={
        //   anonymouses.find((a) => a.prefix === deleteAnonymous?.prefix)?.prefix
        // }
      />
    </>
  );
};

export default S3BucketAnonymousTab;
