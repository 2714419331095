import { Link, useNavigate } from "react-router-dom";
import Button from "../buttons/Button";
import Input from "../inputs/Input";
import { SignInUrl } from "../../utils/urls";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ForgotPasswordSchema } from "../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  forgotPasswordAsync,
  selectLoginLoading,
} from "../../store/auth/authSlice";
import toast from "react-hot-toast";
import { ToastClasses } from "../modals/alerts";
import { ReactComponent as LogoTextIcon } from "./../../assets/icons/logo-text.svg";
import Text from "../general/Text";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectLoginLoading);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ForgotPasswordSchema),
  });
  const onSubmit = (data: any) => {
    dispatch(forgotPasswordAsync({ email: data.email })).then((action) => {
      if (action.type === "auth/forgot-password/fulfilled") {
        toast.success("Check your email inbox", {
          className: ToastClasses,
        });
        setTimeout(() => {
          navigate(SignInUrl);
        }, 4000);
      }
    });
  };

  return (
    <>
      <Text
        type="title"
        className="mb-7 text-center"
        value={`Password reset`}
      />
      <Text
        type="text"
        className="mb-7"
        value={`Enter your email address below, and we'll send you a secure link to
        reset your password.`}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="gap-6 flex flex-col">
          <Input
            inputProps={{ ...register("email") }}
            label="Email address"
            required
            placeholder="your@email.com"
            error={errors.email?.message}
          />
          <div className="flex flex-col">
            <Button
              type="primary"
              buttonProps={{ type: "submit", disabled: loading }}
              buttonClassName="!w-full"
              loading={loading}
            >
              Send reset link
            </Button>
            <div className="flex gap-1 mt-2 justify-center">
              <Link
                to={SignInUrl}
                className="text-sm text-sky-500 hover:text-sky-600"
              >
                Go back to sign in
              </Link>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
export default ForgotPassword;
