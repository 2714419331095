import NotData from "../../layouts/NotData";
import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useState } from "react";
import Button from "../../buttons/Button";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import { useNavigate, useParams } from "react-router-dom";
import { DatabaseServiceGeneralInformationUrl } from "../../../utils/urls";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import Text from "../../general/Text";
import { useSearch } from "../../../providers/SearchProvider";
import Highlighter from "react-highlight-words";
import {
  selectDatabaseServicesActionLoading,
  selectDatabaseServicesLoading,
} from "../../../store/database/servicesSlice";
import {
  getDBSQuerrStatisticsAsync,
  resetDBSQuerrStatisticsAsync,
  selectDBSQuerrStatistics,
  selectDBSQuerrStatisticsLoading,
  selectDatabaseService,
} from "../../../store/database/serviceSlice";
import { IDBSQueryStatisticts } from "../../../types/database";
import { generateServicePermissions } from "../../../pages/databases/ServicePage";

const QuerryStatisticsTab = () => {
  const [filteredQuerrStatistics, setFilteredQuerrStatisticss] = useState<
    IDBSQueryStatisticts[]
  >([]);
  const { id } = useParams();

  const service = useAppSelector(selectDatabaseService);
  const querryStatistics = useAppSelector(selectDBSQuerrStatistics);
  const querryStatisticsLoading = useAppSelector(
    selectDBSQuerrStatisticsLoading
  );
  const loading = useAppSelector(selectDatabaseServicesLoading);
  const actionLoading = useAppSelector(selectDatabaseServicesActionLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (service) {
      const permissions = generateServicePermissions().queryStatistics;
      if (!permissions.includes(service.engine)) {
        navigate(DatabaseServiceGeneralInformationUrl(service.id));
      } else {
        dispatch(
          getDBSQuerrStatisticsAsync({ id: service.id, engine: service.engine })
        );
      }
    }
  }, [dispatch, navigate, service]);

  useEffect(() => {
    const lookedUpFiles = querryStatistics.filter((query) =>
      query.query?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredQuerrStatisticss(lookedUpFiles);
  }, [dispatch, searchValue, querryStatistics]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Database</title>
        <meta name="description" content="" />
      </Helmet>

      {service && (
        <>
          <div className="flex flex-col gap-3">
            <Text className="font-medium text-lg ">Query Statistics</Text>
            <div className="items-center justify-between flex flex-1 transition duration-150">
              <Text className="text-sm ">
                Query statistics gather the statistics collected in performance
                schemas. They enable you to monitor server events (e.g.syncing,
                temporary tables, indexes, join, etc.)
              </Text>
            </div>
            <div className="flex justify-end">
              {service && querryStatistics && (
                <div className="">
                  <Button
                    type="primary"
                    buttonProps={{
                      onClick: () =>
                        dispatch(
                          resetDBSQuerrStatisticsAsync({
                            engine: service.engine,
                            id: service.id,
                            // data,
                          })
                        ),
                      // disabled: isAnyUserDeleting,
                    }}
                    buttonClassName="group"
                  >
                    <RefreshIcon className="w-4" />
                    Reset
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="mt-5 w-full">
            <Table loading={querryStatisticsLoading}>
              <TableHead>
                <TableRow>
                  <TableTH lable="Query" />
                  <TableTH lable="No.of rows" />
                  <TableTH lable="No.of calls" />
                  <TableTH lable="Min(ms)" />
                  <TableTH lable="Max(ms)" />
                  <TableTH lable="Average(ms)" />
                  <TableTH lable="No queries(ms)" />
                  <TableTH lable="Total(ms)" />
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredQuerrStatistics.map((query, i) => (
                  <TableRow key={i}>
                    <TableTD className="cursor-pointer">
                      <div className="flex items-center flex-row justify-between space-x-3">
                        <Highlighter
                          highlightClassName="bg-primary-100 dark:bg-primary-300 rounded-sm"
                          className="whitespace-nowrap max-w-[240px] text-ellipsis overflow-hidden text-sm font-medium"
                          searchWords={[searchValue]}
                          autoEscape={true}
                          textToHighlight={query.query}
                        />
                      </div>
                    </TableTD>

                    <TableTD className="cursor-pointer">
                      <Text className="text-xs md:text-sm">{query.rows}</Text>
                    </TableTD>

                    <TableTD className="cursor-pointer">
                      <Text className="text-xs md:text-sm">{query.calls}</Text>
                    </TableTD>
                    <TableTD className="cursor-pointer">
                      <Text>{query.minTime}</Text>
                    </TableTD>
                    <TableTD className="cursor-pointer">
                      <Text>{query.maxTime}</Text>
                    </TableTD>
                    <TableTD className="cursor-pointer">
                      <Text>{query.meanPlanTime}</Text>
                    </TableTD>
                    <TableTD className="cursor-pointer">
                      <Text>{query.stddevTime}</Text>
                    </TableTD>
                    <TableTD className="cursor-pointer">
                      <Text>{query.totalTime}</Text>
                    </TableTD>
                  </TableRow>
                ))}

                {querryStatistics.length === 0 && !querryStatisticsLoading && (
                  <TableRow withoutHoverStyle={true}>
                    <TableTD colSpan={10} className="!border-0">
                      <NotData text="No queris have been run" />
                    </TableTD>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </>
      )}
    </>
  );
};

export default QuerryStatisticsTab;
