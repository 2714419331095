import { useNavigate } from "react-router-dom";
import Button from "../../buttons/Button";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { InstancesUrl } from "../../../utils/urls";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InstanceAutomaticBackupSchema } from "../../../utils/validations";
import Text, { A, HighlighterText } from "../../general/Text";
import classNames from "classnames";
import { LoadingSpin } from "../../general/Loading";
import {
  getInstancesProductAvailabilityAsync,
  selectInstancesActionLoading,
  selectInstances,
  selectInstancesLoading,
  getInstancesAsync,
} from "../../../store/instances/instancesSlice";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import {
  InstanceAutomaticConfigurationInput,
  InstanceWorkflowInput,
} from "../create/components";
import { Autobackup, Instance } from "../../../types/instance";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import { createAutomaticBackupInstanceAsync } from "../../../store/instances/instanceSlice";
import { useSearch } from "../../../providers/SearchProvider";
import Input from "../../inputs/Input";

const createSteps = [
  "workflowSelection",
  "instanceSelection",
  "scheduleSelection",
  "createSchedule",
];

type CreateStep = (typeof createSteps)[number];

const InstancesAutomaticBackupTab = () => {
  const instances = useAppSelector(selectInstances);
  const [filteredInstances, setFilteredInstances] = useState<Instance[]>([]);
  const [selectedInstance, setSelectedInstance] = useState<Instance | null>(
    null
  );

  const loading = useAppSelector(selectInstancesLoading);
  const actionLoading = useAppSelector(selectInstancesActionLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { value: searchValue } = useSearch();

  // states
  const [expanded, setExpanded] = useState<CreateStep>("workflowSelection");

  const methods = useForm({
    resolver: yupResolver(InstanceAutomaticBackupSchema()),
    reValidateMode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      autobackup: null,
      scheduleName: "",
    },
  });
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
    watch,
  } = methods;

  // watch form values
  const autobackup = watch("autobackup");
  const scheduleName = watch("scheduleName");

  // console.log("autobackup", autobackup);
  // console.log("scheduleName", scheduleName);

  //get first data
  useEffect(() => {
    dispatch(getInstancesProductAvailabilityAsync());
  }, [dispatch, reset]);

  useEffect(() => {
    dispatch(getInstancesAsync({ withoutLoading: false }));
  }, [dispatch]);

  const handleEditWorkflowSelection = () => {
    setExpanded("workflowSelection");
    // setValue("autobackup", null);
    // setValue("scheduleName", "");
  };

  const handleEditRegionSelection = () => {
    setExpanded("instanceSelection");
    // setValue("autobackup", null);
    // setValue("scheduleName", "");
  };

  const handleEditImageSelection = () => {
    setExpanded("scheduleSelection");
    // setValue("autobackup", null);
    // setValue("scheduleName", "");
  };

  const handleEditConfigSelection = () => {
    setExpanded("createSchedule");
  };

  const handleSubmitForm = (data: any) => {
    // console.log("Form Data:", data);

    if (selectedInstance && autobackup) {
      let formData: Autobackup = {
        instanceId: selectedInstance.id,
        cron: autobackup.cron,
        name: scheduleName,
        rotation: autobackup.rotation,
      };

      if (autobackup.maxExecutionCount && autobackup.maxExecutionCount !== 0) {
        formData = {
          ...formData,
          maxExecutionCount: autobackup.maxExecutionCount,
        };
      }

      // console.log("Form Data with maxExecutionCount:", formData);

      dispatch(
        createAutomaticBackupInstanceAsync({
          instanceId: selectedInstance.id,
          regionName: selectedInstance?.region,
          data: formData,
        })
      ).then((action) => {
        if (action.type === "instance/automatic-backup/fulfilled") {
          navigate(InstancesUrl);
          reset();
        }
      });
    }
  };

  useEffect(() => {
    const lookedUpFiles = instances.filter((instance) =>
      instance.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredInstances(lookedUpFiles);
  }, [dispatch, searchValue, instances]);

  const handleRowClick = (instance: Instance) => {
    setSelectedInstance(instance);
  };

  return (
    <>
      <div className="flex items-center justify-between h-16 px-6 sticky top-0 z-20 bg-white dark:bg-dark-1 border-b dark:border-dark-2">
        <div className="items-center justify-between flex flex-1 transition duration-150">
          <div className="font-medium mr-2 text-standard text-md dark:text-slate-100 flex items-center gap-1">
            Create a schedule
          </div>
        </div>
        <div className="">
          <Button
            type="light"
            size="small"
            buttonProps={{
              onClick: () => navigate(InstancesUrl),
            }}
            buttonClassName="group"
          >
            <CloseIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>

      {loading && (
        <div className="h-full w-full flex items-center justify-center min-h-[calc(100vh-128px)]">
          <LoadingSpin borderSize={2} />
        </div>
      )}
      {!loading && (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <div className="mt-10 w-full px-6 flex flex-col lg:flex-row pb-24 gap-8 relative">
              <div className="w-full flex flex-col">
                {/* select workflow*/}
                <Accordion
                  expanded={expanded === "workflowSelection"}
                  sx={{ boxShadow: "none" }}
                  className="bg-white dark:bg-dark-3"
                >
                  <AccordionSummary
                    expandIcon={null}
                    aria-controls="panel1bh-content"
                    id="workflowSelection"
                    sx={{ my: 0 }}
                  >
                    <div className="flex w-full items-center justify-between">
                      {createSteps.indexOf(expanded) > 0 ? (
                        <>
                          <Text className="text-base font-medium flex items-center gap-1">
                            <CheckIcon className="w-5 h-5 text-primary-500" />
                            Select a workflow
                            {/* selected work flow */}
                          </Text>
                          <A
                            className="text-sm"
                            onClick={handleEditWorkflowSelection}
                          >
                            edit this step
                          </A>
                        </>
                      ) : (
                        <Text
                          className={classNames(
                            "text-base font-medium flex items-center gap-1",
                            {
                              "!text-primary-500":
                                expanded === "workflowSelection",
                            }
                          )}
                        >
                          1. Select a workflow
                        </Text>
                      )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="mt-3">
                      <Text className="text-xs mb-5">
                        A workflow describes one or more actions.
                      </Text>

                      <InstanceWorkflowInput
                        //TODO
                        title="Workflow Description"
                        description="This workflow generates instance backups. The backups can then be used to start new instances."
                        control={control}
                        initialSelected=""
                        errorMessage=""
                        handleEditModelSelection={() => {}}
                      />
                    </div>
                    <Button
                      buttonProps={{
                        onClick: (e: any) => {
                          e.preventDefault();
                          setExpanded("instanceSelection");
                        },
                      }}
                    >
                      Next
                    </Button>
                  </AccordionDetails>
                </Accordion>

                {/* select instance */}
                <Accordion
                  expanded={expanded === "instanceSelection"}
                  sx={{ boxShadow: "none" }}
                  className="bg-white dark:bg-dark-3"
                >
                  <AccordionSummary
                    expandIcon={null}
                    aria-controls="panel1bh-content"
                    id="instanceSelection"
                    sx={{ my: 0 }}
                  >
                    <div className="flex w-full items-center justify-between">
                      {createSteps.indexOf(expanded) > 1 ? (
                        <>
                          <Text className="text-base font-medium flex items-center gap-1">
                            <CheckIcon className="w-5 h-5 text-primary-500" />
                            Select a target resource
                          </Text>
                          {/* <Text> {selectedInstance?.name}</Text> */}
                          <A
                            className="text-sm"
                            onClick={handleEditRegionSelection}
                          >
                            edit this step
                          </A>
                        </>
                      ) : (
                        <Text
                          className={classNames(
                            "text-base font-medium flex items-center gap-1",
                            {
                              "!text-primary-500":
                                expanded === "instanceSelection",
                            }
                          )}
                        >
                          2. Select a target resource
                        </Text>
                      )}
                    </div>
                  </AccordionSummary>

                  <AccordionDetails>
                    <div className="mt-3 w-full">
                      <Table loading={loading}>
                        <TableHead>
                          <TableRow>
                            <TableTH lable="Name" />
                            <TableTH lable="Location" />
                            <TableTH lable="Model" />
                            <TableTH lable="Status" />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredInstances.map((instance, i) => (
                            <TableRow
                              key={i}
                              onClick={() => handleRowClick(instance)}
                              className={
                                selectedInstance === instance
                                  ? "bg-gray-200"
                                  : ""
                              }
                            >
                              <TableTD className="cursor-pointer">
                                <div className="flex flex-col gap-1">
                                  <HighlighterText
                                    searchValue={searchValue}
                                    textToHighlight={instance.name}
                                  />
                                </div>
                              </TableTD>
                              <TableTD className="cursor-pointer">
                                <Text className="text-xs md:text-sm">
                                  {instance.region}
                                </Text>
                              </TableTD>
                              <TableTD className="cursor-pointer">
                                <Text className="text-xs md:text-sm">
                                  {instance.planCode?.split(".")[0]}
                                </Text>
                              </TableTD>
                              <TableTD className="cursor-pointer">
                                <Text
                                  className="text-xs"
                                  type={
                                    instance.status === "ACTIVE"
                                      ? "success-alert"
                                      : "warning-alert"
                                  }
                                >
                                  {instance.status}
                                </Text>
                              </TableTD>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>

                    {/* Next Button */}
                    <Button
                      buttonProps={{
                        disabled: !selectedInstance,
                        onClick: (e) => {
                          e.preventDefault();
                          setExpanded("scheduleSelection");
                        },
                      }}
                    >
                      Next
                    </Button>
                  </AccordionDetails>
                </Accordion>

                {/* select schedule> */}
                <Accordion
                  expanded={expanded === "scheduleSelection"}
                  sx={{ boxShadow: "none" }}
                  className="bg-white dark:bg-dark-3"
                >
                  <AccordionSummary
                    expandIcon={null}
                    aria-controls="panel1bh-content"
                    id="scheduleSelection"
                    sx={{ my: 0 }}
                  >
                    <div className="w-full flex">
                      {createSteps.indexOf(expanded) > 2 ? (
                        <div className="flex flex-col gap-5 w-full">
                          <div className="flex w-full items-center justify-between">
                            <Text className="text-base font-medium flex items-center gap-1">
                              <CheckIcon className="w-5 h-5 text-primary-500" />
                              Define scheduling
                            </Text>
                            <A
                              className="text-sm"
                              onClick={handleEditImageSelection}
                            >
                              edit this step
                            </A>
                          </div>
                        </div>
                      ) : (
                        <Text
                          className={classNames(
                            "text-base font-medium flex items-center gap-1",
                            {
                              "!text-primary-500":
                                expanded === "scheduleSelection",
                            }
                          )}
                        >
                          3. Define scheduling
                        </Text>
                      )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="mt-3 mb-5">
                      <InstanceAutomaticConfigurationInput control={control} />
                    </div>
                    <Button
                      buttonProps={{
                        disabled: !autobackup,
                        onClick: (e: any) => {
                          e.preventDefault();
                          setExpanded("createSchedule");
                        },
                      }}
                    >
                      Next
                    </Button>
                  </AccordionDetails>
                </Accordion>

                {/* schedule name */}
                <Accordion
                  expanded={expanded === "createSchedule"}
                  sx={{ boxShadow: "none" }}
                  className="bg-white dark:bg-dark-3"
                >
                  <AccordionSummary
                    expandIcon={null}
                    aria-controls="panel1bh-content"
                    id="createSchedule"
                    sx={{ my: 0 }}
                  >
                    <div className="w-full flex">
                      {createSteps.indexOf(expanded) > 3 ? (
                        <div className="flex flex-col gap-5 w-full">
                          <div className="flex w-full items-center justify-between">
                            <Text className="text-base font-medium flex items-center gap-1">
                              <CheckIcon className="w-5 h-5 text-primary-500" />
                              Configure your instance
                            </Text>
                            <A
                              className="text-sm"
                              onClick={handleEditConfigSelection}
                            >
                              edit this step
                            </A>
                          </div>

                          <div>
                            {autobackup !== null && (
                              <div className="px-5 grid grid-cols-4">
                                <Text className="text-sm col-span-1">
                                  Auto backup:
                                </Text>
                                <CheckIcon className="w-5 h-5 text-primary-500" />
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <Text
                          className={classNames(
                            "text-base font-medium flex items-center gap-1",
                            {
                              "!text-primary-500":
                                expanded === "createSchedule",
                            }
                          )}
                        >
                          4. Name your schedule
                        </Text>
                      )}
                    </div>
                  </AccordionSummary>

                  <AccordionDetails>
                    <div className="mt-3 mb-5">
                      <div className="w-1/2">
                        <Text type="title" className="font-medium">
                          Schedule name
                        </Text>{" "}
                        <Input
                          inputProps={{
                            ...register("scheduleName"),
                            type: "text",
                          }}
                          error={errors.scheduleName?.message}
                        />
                        <Text className="text-xs">
                          May only contain numbers, letters, underscores, dashes
                          and full stops.
                        </Text>
                        <Text className="text-sm mt-5 ">
                          Each backup will be billed at €0.01 ex. VAT/month/GB
                        </Text>
                      </div>
                    </div>

                    <Button
                      buttonClassName="mr-2"
                      loading={loading}
                      type="primary"
                      buttonProps={{
                        disabled: !scheduleName,
                        type: "submit",
                      }}
                    >
                      Create
                    </Button>
                    <Button
                      buttonProps={{
                        onClick: (e: any) => {
                          e.preventDefault();
                          setExpanded("workflowSelection");
                        },
                      }}
                    >
                      Cancel
                    </Button>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </form>
        </FormProvider>
      )}
    </>
  );
};

export default InstancesAutomaticBackupTab;
