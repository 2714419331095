import { ReactComponent as InboxIcon } from "./../../assets/icons/inbox.svg";
// import { ReactComponent as EmptyState } from "./../../assets/icons/empty-state.svg";
import { ReactComponent as CubeIcon } from "./../../assets/icons/cube.svg";
import { ReactComponent as Square3StackIcon } from "./../../assets/icons/square-3stack.svg";
import { ReactComponent as SettingIcon } from "./../../assets/icons/setting.svg";
import { ReactComponent as NoData } from "./../../assets/icons/exclamation-triangle-icon.svg";
import { ReactComponent as WorkflowIcon } from "./../../assets/icons/workflow.svg";
import { ReactComponent as CubeTransparentIcon } from "./../../assets/icons/cube-transparent.svg";

import Text from "../general/Text";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import Button from "../buttons/Button";

interface NotExistProps {
  text?: string;
  buttonText?: string;
  url?: () => void;
}

const NotData = ({
  text,
  icon,
  className,
  iconClassName,
  textClassName,
}: {
  text?: string | React.ReactNode;
  icon?: React.ReactNode;
  className?: string;
  iconClassName?: string;
  textClassName?: string;
}) => {
  return (
    <div
      className={classNames(
        "w-full min-h-[150px] flex flex-col gap-1 justify-center items-center  text-slate-400 dark:text-gray-600",
        {
          [className || ""]: className,
        }
      )}
    >
      {icon || <InboxIcon className={classNames("w-14", iconClassName)} />}
      <span className={classNames("text-sm font-medium", textClassName)}>
        {text || "No data"}
      </span>
    </div>
  );
};

export const EmptyFileList = () => {
  return (
    <div className="border-0 w-full flex flex-col justify-center items-center p-16 text-center gap-2 text-slate-400 dark:text-gray-600">
      <div className="flex flex-col items-center">
        <InboxIcon className="w-14" />
        <p className="text-xl text-slate-500 dark:text-slate-300 font-medium">
          Drop files here
        </p>
      </div>
      <p className="text-sm text-slate-400">Or use “Upload file” button.</p>
    </div>
  );
};

export const EmptyList = ({ text }: { text?: string | React.ReactNode }) => {
  return (
    <div className="text-sm dark:text-slate-500 text-slate-600 px-4 py-6 w-full text-center font-semibold">
      {text}
    </div>
  );
};

export const NotActiveTab = ({
  text,
  icon,
}: {
  text?: string | React.ReactElement;
  icon?: React.ReactElement;
}) => {
  return (
    <div className="flex w-full flex-col items-center min-h-[300px] justify-center">
      {icon}
      <Text className="px-4 py-4 w-full text-center">{text}</Text>
    </div>
  );
};

export const IpfsNotActiveTab = () => {
  return (
    <NotActiveTab
      icon={<CubeIcon className="w-14 text-primary-400 animate-bounce" />}
      text="We are creating your IPFS. Please wait..."
    />
  );
};

export const S3NotActiveTab = () => {
  return (
    <NotActiveTab
      icon={
        <Square3StackIcon className="w-14 text-primary-400 animate-bounce" />
      }
      text="We are creating your S3. Please wait..."
    />
  );
};

export const WorkflowNotActiveTab = () => {
  return (
    <NotActiveTab
      icon={<WorkflowIcon className="w-14 text-primary-400 animate-bounce" />}
      text="We are creating your workflow. Please wait..."
    />
  );
};

export const RpcNotActiveTab = () => {
  return (
    <NotActiveTab
      icon={
        <CubeTransparentIcon className="w-14 text-primary-400 animate-bounce" />
      }
      text="We are creating your RPC. Please wait..."
    />
  );
};

export const NotExist: React.FC<NotExistProps> = ({
  text,
  buttonText,
  url,
}) => {
  return (
    <NotActiveTab
      icon={<NoData className="w-14 " />}
      text={
        <div className="flex flex-col gap-2 items-center justify-center">
          <Text className="font-semibold text-2xl">404</Text>
          {text || "This is not the web page you are looking for"}
          {url ? (
            <Button buttonProps={{ onClick: url }}>
              {buttonText || "Go Back"}
            </Button>
          ) : null}
        </div>
      }
    />
  );
};

export const WorkingPage: React.FunctionComponent<{ title?: string }> = ({
  title,
}) => {
  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} {title ? `| ${title}` : ""}
        </title>
        <meta name="description" content={title} />
      </Helmet>
      <div className="w-full h-[calc(100vh-4rem)] flex items-center justify-center">
        <div className="flex flex-col items-center gap-1">
          <SettingIcon className="w-11 aspect-square animate-spin-slow text-primary-400" />
          <Text type="subtext" className="!text-primary-400 font-medium">
            Under Construction
          </Text>
        </div>
      </div>
    </>
  );
};
export default NotData;
