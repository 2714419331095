import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { HomeUrl } from "../../utils/urls";
import Button from "../../components/buttons/Button";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  selectAcceptLoading,
  acceptTeamInvitationsync,
  checkInvitationAync,
} from "../../store/settings/teams/teamsSlice";
import Text, { ActionText } from "../../components/general/Text";
import { getEnvironmentsAsync } from "../../store/environments/environmentsSlice";
import { useCallback, useEffect, useState } from "react";
import Loading from "../../components/general/Loading";
import { AnimatePresence, motion } from "framer-motion";
import { ReactComponent as ErrorIcon } from "./../../assets/icons/error-warning.svg";
import { Environment } from "../../types/environment";
import { handleSelectEnv } from "../../store/auth/authSlice";

type checkTypes = "loading" | "valid" | "invalid";

const AcceptInvitedTeamPage = () => {
  const { env, token } = useParams();
  const [query] = useSearchParams();
  const [checkStatus, setCheckStatus] = useState<checkTypes>("loading");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const acceptLoading = useAppSelector(selectAcceptLoading);

  const envName = query.get("envName") || "";

  useEffect(() => {
    if (env && token) {
      dispatch(checkInvitationAync({ env, token })).then((action) => {
        if (action.type === "teams/invite/check/fulfilled") {
          setCheckStatus(action.payload ? "valid" : "invalid");
        } else {
          setCheckStatus("invalid");
        }
      });
    }
  }, [dispatch, env, token]);

  const handleAccept = useCallback(() => {
    if (env && token && checkStatus === "valid")
      dispatch(acceptTeamInvitationsync({ env, token })).then((action) => {
        if (action.type === "teams/invite/accept/fulfilled") {
          dispatch(getEnvironmentsAsync()).then((action2) => {
            if (action2.type === "environments/fulfilled") {
              const environments = (
                action2.payload as { environments: Environment[] }
              ).environments;

              const acceptedEnv = environments.find((en) => en.Id === +env);
              if (acceptedEnv) {
                dispatch(handleSelectEnv({ env: acceptedEnv }));
                navigate(HomeUrl);
              }
            }
          });
        }
      });
  }, [checkStatus, dispatch, env, navigate, token]);

  return (
    <>
      <div className="text-slate-900 dark:text-slate-100 font-semibold text-lg mb-7 text-center">
        Invitation details
      </div>
      <AnimatePresence>
        <div className="w-full flex flex-col items-center gap-5 my-10 min-h-[100px] justify-center">
          {checkStatus === "loading" && <Loading />}
          {checkStatus === "valid" && (
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0 }}
              className="flex flex-col justify-center items-center"
            >
              <Text className="flex gap-1 mb-4">
                You are invited to the <ActionText text={envName} /> workspace
              </Text>
              <Button
                loading={acceptLoading}
                buttonProps={{
                  onClick: handleAccept,
                  disabled: !!!env || !!!token || acceptLoading,
                }}
                buttonClassName="w-[90px]"
                type="light"
              >
                Accept
              </Button>
            </motion.div>
          )}
          {checkStatus === "invalid" && (
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0 }}
              className="flex flex-col justify-center items-center"
            >
              <ErrorIcon className="w-16 text-red-500" />
              <Text className="text-center mb-4">
                The invitation is not valid
              </Text>
              <Button
                type="light"
                buttonProps={{ onClick: () => navigate(HomeUrl) }}
              >
                Back to panel
              </Button>
            </motion.div>
          )}
        </div>
      </AnimatePresence>
    </>
  );
};
export default AcceptInvitedTeamPage;
