import classNames from "classnames";
import React, { PropsWithChildren } from "react";

export type StepperProps<T = string> = {
  steps: Array<StepperStep<T>>;
  step: T;
  className?: string;
};

export interface StepperStep<T = string> {
  label: string | React.ReactNode;
  value: T;
  callback?: (clickedValue: T) => void;
}

const Stepper = <T extends string>(
  props: PropsWithChildren<StepperProps<T>>
) => {
  const { steps, step, className } = props;
  const stepsKeys = steps.map((s) => s.value);
  const [activeLevels, checkedLevels] = handleSelectedSteps(step, stepsKeys);

  return (
    <ol
      className={classNames(
        "flex items-center w-full text-xs md:text-base font-medium text-center  text-gray-500 dark:text-gray-400 py-6",
        className
      )}
    >
      {steps.map((step, i) => (
        <li
          key={i}
          className={classNames(`flex items-center `, {
            "md:w-full md:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden lg:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700":
              steps.length !== i + 1,
            "!text-blue-600 dark:!text-blue-500": activeLevels?.includes(
              step.value
            ),
            "cursor-pointer":
              checkedLevels.includes(step.value) && step.callback,
          })}
          onClick={() =>
            step.callback && checkedLevels.includes(step.value)
              ? step.callback(step.value)
              : null
          }
        >
          <span
            className={classNames("flex items-center", {
              [` after:content-['/'] lg:after:hidden after:mx-1 after:text-gray-200 dark:after:text-gray-500`]:
                steps.length !== i + 1,
            })}
          >
            {checkedLevels?.includes(step.value) ? (
              <TickIcon />
            ) : (
              <span className="me-1 md:me-2">{i + 1}.</span>
            )}
            <div className="whitespace-nowrap">{step.label}</div>
          </span>
        </li>
      ))}
    </ol>
  );
};
const handleSelectedSteps = <T extends string>(
  step: T,
  stepsKey: T[]
): [T[], T[]] => {
  const index = stepsKey.indexOf(step);

  if (index !== -1) {
    return [stepsKey.slice(0, index + 1), stepsKey.slice(0, index)];
  } else {
    return [[], []];
  }
};

const TickIcon = () => {
  return (
    <svg
      className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
    </svg>
  );
};
export default Stepper;
