import { Tab } from "@headlessui/react";
import classNames from "classnames";
import { TabOption, TabOptions, TapsProp } from "../../types/tabs";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Tab as MuiTab, Box, styled } from "@mui/material";

const Tabs = (tabsPros: TapsProp) => {
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    tabsPros.selectedIndex || 0
  );
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (typeof tabsPros.useUrl !== "undefined" && tabsPros.useUrl) {
      const si = getOptionIndexFromUrlString(
        location.pathname,
        tabsPros.options
      );
      setSelectedIndex(si);
    }
  }, [location, tabsPros.options, tabsPros.useUrl]);

  useEffect(() => {
    if (typeof tabsPros.useUrl === "undefined")
      setSelectedIndex(tabsPros.selectedIndex);
  }, [tabsPros.selectedIndex, tabsPros.useUrl]);

  const onChangeTab = (i: number) => {
    if (typeof tabsPros.useUrl !== "undefined" && tabsPros.useUrl) {
      const selectedOption = getTabOptionFromIndex(i, tabsPros.options);
      if (selectedOption && typeof selectedOption.url !== "undefined") {
        navigate(selectedOption.url);
      }
    }

    if (typeof tabsPros.onChange !== "undefined") {
      tabsPros.onChange(i);
    }
    setSelectedIndex(i);
  };
  return (
    <Tab.Group selectedIndex={selectedIndex} onChange={onChangeTab}>
      <Tab.List
        className={classNames("flex overflow-x-auto", {
          [tabsPros.listClassName || ""]: tabsPros.listClassName,
          "w-full md:w-auto ":
            tabsPros.tabType === undefined || tabsPros.tabType === "default",
          "bg-slate-500/10 p-1 rounded-lg": tabsPros.tabType === "creamy",
        })}
      >
        {tabsPros.options.map((option, i) => (
          <Tab
            key={i}
            disabled={option.disabled}
            test-cy={option.testId}
            className={({ selected }) =>
              classNames(
                "hover:dark:text-slate-100 hover:text-gray-900 outline-none disabled:cursor-not-allowed",
                {
                  "font-semibold bg-primary-50 dark:bg-dark-2 text-blue-500 hover:!text-blue-600 hover:dark:text-blue-600 rounded-lg":
                    selected &&
                    (tabsPros.tabType === undefined ||
                      tabsPros.tabType === "default"),
                  "font-normal text-xs sm:text-sm whitespace-nowrap px-3 h-9 w-full":
                    tabsPros.tabType === "creamy",
                  "bg-white dark:bg-dark-3 dark:text-slate-300 rounded-lg":
                    tabsPros.tabType === "creamy" && selected,
                  "text-gray-400 dark:text-slate-400": !selected,
                }
              )
            }
          >
            {(tabsPros.tabType === undefined ||
              tabsPros.tabType === "default") && (
              <div className="rounded-md flex items-center transition-background duration-150 justify-center text-center sm:space-x-2 w-full px-3 py-1.5 ">
                <span className="text-xs sm:text-sm whitespace-nowrap">
                  {option.label}
                </span>
              </div>
            )}
            {tabsPros.tabType === "creamy" && <>{option.label}</>}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels>
        {tabsPros.options.map((option, i) => (
          <Tab.Panel
            key={i}
            className={classNames("bg-white dark:bg-dark-2 rounded-md", {
              "py-6":
                (tabsPros.tabType === undefined ||
                  tabsPros.tabType === "default") &&
                option.element,
              [tabsPros.panelClassName || ""]: tabsPros.panelClassName,
            })}
          >
            {option.element}
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};
export const getTabOptionFromIndex = (
  index: number,
  options: TabOptions
): TabOption | undefined => {
  return options[index];
};

export const getOptionIndexFromUrlString = (
  url: string,
  options: TabOptions
) => {
  let index = undefined;

  options.forEach((element, i) => {
    if (element.url && url.includes(element.url)) index = i;
  });

  return index;
};

interface StyledTabProps {
  label: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const MuiTabItem = styled((props: StyledTabProps) => (
  <MuiTab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(12),
  marginRight: theme.spacing(1),
  // color: "rgba(255, 255, 255, 0.7)",
  // "&.Mui-selected": {
  //   color: "#fff",
  // },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

export function MuiTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
export default Tabs;
