import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getWalletApiApiKeysAysnc,
  selectWalletApiApiKeys,
  selectWalletApiLoading,
  handleShowWalletApiEditorModal,
  deleteWalletApiApiKeyAsync,
  selectWalletApiActionLoading,
} from "../../../store/wallet-api/apiKeysSlice";
import Button from "../../buttons/Button";
import Dropdown from "../../general/Dropdown";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import NotData from "../../layouts/NotData";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil-square.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import ApiKeyEditorModal from "./ApiKeyEditorModal";
import { HiddenKey } from "../../general/HiddenKey";
import QuestionModal, { DeleteModal } from "../../modals/QuestionModal";
import Highlighter from "react-highlight-words";
import { useSearch } from "../../../providers/SearchProvider";
import { IpfsApiKey } from "../../../types/ipfs-api-key";
import Text from "../../general/Text";

const WalletApiApiKeysTab = () => {
  const [deleteApiKeyId, setDeleteApiKeyId] = useState<number | null>(null);
  const apiKeys = useAppSelector(selectWalletApiApiKeys);
  const [filteredApiKeys, setFilteredApiKeys] = useState<IpfsApiKey[]>([]);
  const loading = useAppSelector(selectWalletApiLoading);
  const actionLoading = useAppSelector(selectWalletApiActionLoading);
  const dispatch = useAppDispatch();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    // if (apiKeys.length === 0) {
    dispatch(getWalletApiApiKeysAysnc());
    // }
  }, [dispatch]);

  useEffect(() => {
    const lookedUpFiles = apiKeys.filter((apiKey) =>
      apiKey.Label?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredApiKeys(lookedUpFiles);
  }, [dispatch, searchValue, apiKeys]);

  return (
    <>
      <div className="flex transition-all duration-500">
        <Text>Access keys</Text>
        <div className="ml-auto flex items-center justify-end gap-1 md:gap-2">
          <Button
            type="primary"
            buttonProps={{
              onClick: () => dispatch(handleShowWalletApiEditorModal({})),
            }}
          >
            Create New
          </Button>
        </div>
      </div>
      <Table loading={loading} className="mt-6">
        <TableHead>
          <TableRow>
            <TableTH lable="Label" />
            <TableTH lable="Value" />
            <TableTH lable="" />
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredApiKeys.map((apiKey, index) => (
            <TableRow key={index}>
              <TableTD className="w-36">
                <Highlighter
                  highlightClassName="bg-primary-100 dark:bg-primary-300 rounded-sm"
                  className="whitespace-nowrap max-w-[110px] md:max-w-none text-ellipsis overflow-hidden text-sm font-medium"
                  searchWords={[searchValue]}
                  autoEscape={true}
                  textToHighlight={apiKey.Label}
                />
              </TableTD>
              <TableTD>
                <div className="text-sm dark:text-slate-200">
                  <HiddenKey text={apiKey.KeyValue} />
                </div>
              </TableTD>
              <TableTD className="w-10">
                <div className="w-8 flex justify-center items-center">
                  <Dropdown
                    positionClassName="!w-40"
                    menu={[
                      {
                        key: "1",
                        label: (
                          <div className="flex items-center gap-1">
                            <EditIcon className="w-4" />
                            Edit
                          </div>
                        ),
                        onClick: (_, close) => {
                          close();
                          dispatch(handleShowWalletApiEditorModal({ apiKey }));
                        },
                      },
                      {
                        type: "divider",
                      },
                      {
                        key: "end",
                        label: (
                          <div className="flex items-center gap-1">
                            <ArchiveIcon className="w-4" />
                            Delete
                          </div>
                        ),
                        danger: true,
                        onClick: (_, close) => {
                          close();
                          setDeleteApiKeyId(apiKey.Id);
                        },
                      },
                    ]}
                    type="simple"
                  >
                    <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                      <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                    </div>
                  </Dropdown>
                </div>
              </TableTD>
            </TableRow>
          ))}
          {apiKeys.length === 0 && (
            <TableRow withoutHoverStyle={true}>
              <TableTD colSpan={3} className="!border-0">
                <NotData text="You have not created any API key yet" />
              </TableTD>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <ApiKeyEditorModal />

      <DeleteModal
        title="Delete Access key"
        isOpen={deleteApiKeyId !== null}
        onClose={() => setDeleteApiKeyId(null)}
        description="To confirm, type 'DELETE' in the box below"
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        onConfirm={() => {
          deleteApiKeyId &&
            dispatch(deleteWalletApiApiKeyAsync({ id: deleteApiKeyId })).then(
              (action) => {
                if (action.type === "wallet-api/api-keys/delete/fulfilled") {
                  dispatch(getWalletApiApiKeysAysnc());
                }
                setDeleteApiKeyId(null);
              }
            );
        }}
        loading={actionLoading}
        // confirmString={apiKeys.find((api) => api.Id === deleteApiKeyId)?.Label}
      />
    </>
  );
};

export default WalletApiApiKeysTab;
