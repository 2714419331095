import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Modal from "../../modals/Modal";
import Button from "../../buttons/Button";
import Text from "../../general/Text";
import {
  getInstanceAsync,
  handleInstanceStopHideModal,
  selectInstance,
  selectInstanceLoading,
  selectShowStopModal,
  stopInstanceAsync,
} from "../../../store/instances/instanceSlice";
import { getInstancesAsync } from "../../../store/instances/instancesSlice";

const InstancesStopModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectShowStopModal);
  const loading = useAppSelector(selectInstanceLoading);
  const instance = useAppSelector(selectInstance);

  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({
    shouldUnregister: true,
    reValidateMode: "onChange",
  });

  const onSubmit = (data: any) => {
    if (instance) {
      dispatch(stopInstanceAsync({ instanceId: instance?.id, data })).then(
        (action) => {
          if (action.type === "instance/stop/fulfilled") {
            dispatch(getInstancesAsync({ withoutLoading: false }));
            dispatch(getInstanceAsync({ instanceId: instance.id }));
            dispatch(handleInstanceStopHideModal());
          }
        }
      );
    }
  };

  const handleClose = () => {
    reset();
    dispatch(handleInstanceStopHideModal());
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      contentClassName="max-w-lg"
      //containerClassName="!items-start"
      title="Stop your instance"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5">
          <Text className="text-sm mb-5">
            You are about to stop your captainProd-1 instance. The resources
            dedicated to your Public Cloud instance are still reserved
            (including the IP address). You can reboot your instance at any
            time. In the meantime, you will still be charged the same price for
            your instance.
          </Text>
        </div>

        <div className="mt-4 flex justify-end gap-2">
          <Button
            type="light"
            buttonProps={{
              onClick: handleClose,
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            buttonProps={{
              disabled: !isValid,
              type: "submit",
            }}
            loading={loading}
          >
            Confirm
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default InstancesStopModal;
