import { useAppDispatch, useAppSelector } from "../../../hooks";
import Modal from "../../modals/Modal";
import { useEffect } from "react";
import {
  selectShowCreatedData,
  selectCreatedData,
  handleClearCreatedAccessKey,
} from "../../../store/s3/accessKeysSlice";
import CopyableKey from "../../general/CopyableKey";
import Button from "../../buttons/Button";
import { textJsonDownloader } from "../../../utils/file";

const S3AccessKeyShowModal = () => {
  const isOpen = useAppSelector(selectShowCreatedData);
  const accessKey = useAppSelector(selectCreatedData);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isOpen) {
    }
  }, [isOpen, accessKey]);

  const handleDownload = () => {
    if (accessKey) {
      textJsonDownloader(
        accessKey,
        `${accessKey.accessKey}_${new Date()
          .toLocaleDateString("en-CA")
          .replaceAll("-", "")}`
      );
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleClearCreatedAccessKey());
      }}
      contentClassName="max-w-lg"
      containerClassName="!items-start"
      title={"Access key"}
    >
      <div className="mt-5 flex flex-col w-full gap-5">
        <div className="items-center flex w-full">
          <div className="text-slate-800 dark:text-slate-200 text-sm w-20">
            Access Key
          </div>
          <div className="col-auto">
            <CopyableKey text={accessKey?.accessKey || ""} />
          </div>
        </div>
        <div className="flex items-center">
          <div className="text-slate-800 dark:text-slate-200 text-sm w-20">
            Secret Key
          </div>
          <div>
            <CopyableKey text={accessKey?.secretKey || ""} />
          </div>
        </div>
      </div>
      <div className="mt-8 flex justify-end">
        <Button
          // loading={loading}
          type="primary"
          buttonProps={{
            onClick: handleDownload,
            // disabled: loading,
            // type: "submit",
          }}
          buttonClassName="w-[150px]"
        >
          Download JSON
        </Button>
      </div>
    </Modal>
  );
};

export default S3AccessKeyShowModal;
