import { useCallback, useEffect, useState } from "react";
import ThemeDropdown from "../dropdowns/ThemeDropdown";
import UserDropdown from "../dropdowns/UserDropdown";
import { EnvironmentButton } from "../environments/EnvironmentSelectModal";
import SearchInput from "../general/SearchInput";
import {
  SearchActionType,
  useSearchDispatch,
} from "../../providers/SearchProvider";
import { Link, useLocation } from "react-router-dom";
import useWindowOnClick from "../../hooks/useWindowOnClick";
import { ReactComponent as MenuIcon } from "./../../assets/icons/menu.svg";
import { ReactComponent as LogoIcon } from "./../../assets/icons/logo.svg";
import { ReactComponent as SearchIcon } from "./../../assets/icons/search.svg";
import classNames from "classnames";
import NotifDropdown from "../dropdowns/NotifDropdown";
import { HomeUrl } from "../../utils/urls";

const Header: React.FunctionComponent<{
  onHideNav?: () => void;
  onShowNav?: () => void;
}> = ({ onHideNav, onShowNav }) => {
  const location = useLocation();
  const dispatch = useSearchDispatch();

  const [openMobileSearch, setOpenMobileSearch] = useState(false);

  useEffect(() => {
    dispatch({ type: SearchActionType.CLEAR, payload: "" });
  }, [dispatch, location.pathname]);

  useWindowOnClick(
    () => {
      if (onHideNav) onHideNav();
    },
    [],
    { capture: true }
  );

  useWindowOnClick(() => {
    setOpenMobileSearch(false);
  }, []);

  const handleClickMobileSearchBtn: React.MouseEventHandler<HTMLDivElement> =
    useCallback((e) => {
      e.stopPropagation();
      setOpenMobileSearch(true);
    }, []);

  return (
    <div className="w-full h-16 px-6 flex items-center bg-white dark:bg-dark-3 top-0 z-30 sticky border-b-2 border-slate-200 dark:border-dark-3">
      <div className="w-full lg:max-w-7xl mx-auto flex items-center justify-between">
        <div className="lg:hidden flex items-center gap-3">
          <MenuIcon
            className="text-slate-800 dark:text-slate-200 w-7 h-7"
            onClick={onShowNav}
            test-cy="menu-icon"
          />
          <Link to={HomeUrl}>
            <LogoIcon className="w-8 h-8 aspect-square" />
          </Link>
        </div>

        <div
          className={classNames(
            "flex-1 w-full bg-white dark:bg-dark-3 items-center h-14 absolute lg:static flex  -top-14 bottom-0 left-0 right-0 z-50 transition-all lg:transition-none",
            {
              "!top-0": openMobileSearch,
            }
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="w-full lg:w-96">
            <SearchInput />
          </div>
        </div>
        <div className="flex items-center gap-2 md:gap-4 text-slate-500 dark:text-slate-300">
          <EnvironmentButton />
          <NotifDropdown />
          <ThemeDropdown />
          <div className="h-[35px] w-[2px] bg-slate-300 hidden md:block" />
          <div className="flex items-center">
            <UserDropdown />
          </div>
          <div onClick={handleClickMobileSearchBtn}>
            <SearchIcon className="w-6 aspect-square lg:hidden" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
