import classNames from "classnames";
import { PropsWithChildren, ReactNode, useState } from "react";

type AlertProps = {
  className?: string;
  type?: "info" | "warning" | "danger" | "success";
  showCloseButton?: boolean;
  icon?: ReactNode;
};

const Alert: React.FunctionComponent<PropsWithChildren<AlertProps>> = ({
  className,
  type,
  children,
  showCloseButton = false,
  icon,
}) => {
  const [showAlert, setShowAlert] = useState(true);

  const toggleAlert = () => {
    setShowAlert(!showAlert);
  };
  return (
    <div className="relative">
      {showAlert && (
        <div
          className={classNames("w-full rounded-xl border p-6 py-5", {
            "bg-transparent border-slate-200 dark:border-dark-2":
              type === undefined,
            "border-primary-200 bg-primary-50/40 dark:bg-primary-400/10 text-primary-400":
              type === "info",
            "border-orange-300 bg-orange-50/40 dark:bg-orange-400/10 text-orange-400":
              type === "warning",
            "border-red-500 bg-red-500/10 dark:bg-red-600/10 text-red-500":
              type === "danger",
            "border-green-300 bg-green-50/40 dark:bg-green-400/10 text-green-700":
              type === "success",
            [className || ""]: className,
          })}
        >
          {showCloseButton && (
            <button
              className="absolute top-0 right-0 mt-1 mr-1 px-2 py-1 text-sm rounded hover:bg-gray-200 dark:hover:bg-gray-800"
              onClick={toggleAlert}
            >
              &#x2715;
            </button>
          )}
          <div className="flex flex-row">
            {" "}
            {icon && <div className="mr-1">{icon}</div>}
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default Alert;
