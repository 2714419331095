import { PropsWithChildren, useState } from "react";
import { ReactComponent as EyeIcon } from "./../../assets/icons/eye.svg";
import { ReactComponent as EyeSlashIcon } from "./../../assets/icons/eye-slash.svg";
import { copyToClipboard } from "../../utils/copy";
import Text from "./Text";
import classNames from "classnames";

export const HiddenKey = (
  props: PropsWithChildren<{ text?: string; textClassName?: string }>
) => {
  const { text } = props;
  const [showText, setShowText] = useState(false);

  return (
    <div className="flex items-center gap-1">
      <div className="select-none">
        {showText && (
          <EyeIcon
            onClick={() => setShowText(false)}
            className="w-5 h-5 cursor-pointer  hover:scale-110 transition-all duration-300 text-slate-700 hover:text-slate-900 dark:text-slate-300 dark:hover:text-slate-200"
          />
        )}
        {!showText && (
          <EyeSlashIcon
            onClick={() => setShowText(true)}
            className="w-5 h-5 cursor-pointer  hover:scale-110 transition-all duration-300 text-slate-700 hover:text-slate-900 dark:text-slate-300 dark:hover:text-slate-200"
          />
        )}
      </div>
      <div
        className="relative overflow-hidden cursor-pointer text-sm h-7 dark:bg-dark-2 dark:hover:bg-white/10 bg-gray-200/70 hover:bg-gray-200 px-2 rounded-md select-none transition-all duration-500 flex flex-col items-center justify-center whitespace-nowrap"
        onClick={() => text && copyToClipboard(text)}
      >
        {!showText && (
          <div className="bg-white/10 dark:bg-black/10 backdrop-blur-[2.3px] absolute left-0 top-0 right-0 bottom-0 w-full h-full" />
        )}
        <Text
          className={classNames(
            "w-full overflow-hidden text-ellipsis truncate",
            props.textClassName
          )}
        >
          {text}
        </Text>
      </div>
    </div>
  );
};
