import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import Modal from "../../../../modals/Modal";
import Button from "../../../../buttons/Button";
import { useCallback, useEffect, useState } from "react";

import {
  getObjectVersionsAsync,
  handleHideObjectRetentionPolicyModal,
  selectObjectRetentionPolicyLoading,
  selectObjectVersions,
  selectObjectVersionsLoading,
  selectShowObjectRetentionPolicyModal,
  setObjectRetentionPolicyAsync,
} from "../../../../../store/s3/buckets/objectSlice";
import { getMainVersionOfObject } from "../../../../../utils/bucket";
import Switcher from "../../../../inputs/Switcher";
import { selectBucketDetails } from "../../../../../store/s3/buckets/bucketSlice";
import Text from "../../../../general/Text";
import RadioGrouper, { RadioGroupeItem } from "../../../../inputs/RadioGrouper";
import { S3BucketRetentionMode } from "../../../../../types/s3-bucket";
import Select, { SelectOption } from "../../../../inputs/Select";
import {
  createDateString,
  getDaysInMonth,
  monthOptions,
  yearsOptions,
} from "../../../../../utils/date";
import toast from "react-hot-toast";
import { ToastClasses } from "../../../../modals/alerts";
import { modeOptions } from "../buckets/S3RetentionEditorModal";
// import toast from "react-hot-toast";
// import { ToastClasses } from "../../../modals/alerts";

const S3ObjectRetentionPolicyModal = () => {
  const isOpen = useAppSelector(selectShowObjectRetentionPolicyModal);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const versions = useAppSelector(selectObjectVersions);
  const versionsLoading = useAppSelector(selectObjectVersionsLoading);
  const mainVersion = getMainVersionOfObject(versions);
  const retentionPolicyLoading = useAppSelector(
    selectObjectRetentionPolicyLoading
  );
  const [enabledRetentionPolicy, setEnabledRetentionPolicy] =
    useState<boolean>(false);
  const [selectedMode, setSelectedMode] = useState<
    RadioGroupeItem<S3BucketRetentionMode> | undefined
  >();

  const [selectedYear, setSelectedYear] = useState<SelectOption<number>>();
  const [selectedMonth, setSelectedMonth] = useState<SelectOption<number>>();
  const [selectedDay, setSelectedDay] = useState<SelectOption<number>>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (mainVersion && isOpen) {
      if ("retention_mode" in mainVersion) {
        setEnabledRetentionPolicy(true);
        setSelectedMode(
          mainVersion.retention_mode === "COMPLIANCE"
            ? modeOptions.find((o) => o.value === "compliance")
            : modeOptions.find((o) => o.value === "governance")
        );
      } else {
        setEnabledRetentionPolicy(false);
        setSelectedMode(undefined);
      }

      if (
        "retention_until_date" in mainVersion &&
        mainVersion.retention_until_date
      ) {
        const dateObject = new Date(mainVersion.retention_until_date);

        const day = dateObject.getDate();
        const month = dateObject.getMonth() + 1;
        const year = dateObject.getFullYear();

        const dayOption = getDaysInMonth(month, year).find(
          (d) => d.value === day
        );
        dayOption && setSelectedDay(dayOption);

        const monthOption = monthOptions.find((m) => m.value === month);
        monthOption && setSelectedMonth(monthOption);

        const yearOption = yearsOptions.find((y) => y.value === year);
        yearOption && setSelectedYear(yearOption);
      } else {
        setSelectedYear(undefined);
        setSelectedMonth(undefined);
        setSelectedDay(undefined);
      }
    }
  }, [mainVersion, isOpen]);

  const { handleSubmit, reset } = useForm();

  const handleChangeMode = (item: RadioGroupeItem<S3BucketRetentionMode>) => {
    if (mainVersion && !("retention_mode" in mainVersion)) {
      setSelectedMode(item);
    }
  };

  const onSubmit = () => {
    if (mainVersion && mainVersion.version_id && bucketDetails) {
      if ("retention_mode" in mainVersion && !enabledRetentionPolicy) {
        //TODO handle delete retention for this object
      } else {
        if (selectedMode) {
          if (selectedYear && selectedMonth && selectedDay) {
            dispatch(
              setObjectRetentionPolicyAsync({
                bucketName: bucketDetails.name,
                prefix: mainVersion.name,
                versionId: mainVersion.version_id,
                data: {
                  expires: createDateString(
                    selectedYear.value,
                    selectedMonth.value,
                    selectedDay.value
                  ),
                  mode: selectedMode.value,
                },
              })
            ).then((action) => {
              if (action.type === "object/retention-policy/set/fulfilled") {
                handleClearComponent();
                dispatch(handleHideObjectRetentionPolicyModal());
                dispatch(
                  getObjectVersionsAsync({
                    bucketName: bucketDetails.name,
                    prefix: mainVersion.name,
                  })
                );
              }
            });
          } else {
            toast.error(`Please select a valid date time`, {
              className: ToastClasses,
            });
          }
        } else {
          toast.error(`Please select a valid mode`, {
            className: ToastClasses,
          });
        }
      }
    }
  };

  const handleClearComponent = useCallback(() => {
    reset();
    setEnabledRetentionPolicy(false);
    setSelectedYear(undefined);
    setSelectedMonth(undefined);
    setSelectedDay(undefined);
  }, [reset]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        handleClearComponent();
        dispatch(handleHideObjectRetentionPolicyModal());
      }}
      contentClassName="max-w-lg"
      // containerClassName="!items-start"
      title={"Set Retention Policy"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col w-full gap-5">
          {mainVersion && "retention_mode" in mainVersion && (
            <div className="flex items-center justify-between w-full">
              <Text className="text-sm">Retention Policy Status</Text>
              <Switcher
                on={enabledRetentionPolicy}
                onToggle={setEnabledRetentionPolicy}
              />
            </div>
          )}

          <div className="flex gap-5 justify-between items-center">
            <Text className="text-sm">Retention Unit</Text>
            <div className="">
              <RadioGrouper
                items={modeOptions}
                selected={selectedMode}
                setSelected={handleChangeMode}
              />
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <Text className="text-sm">Date</Text>
            <div className="grid grid-cols-3 gap-3">
              <Select
                options={monthOptions}
                selected={selectedMonth}
                setSelected={setSelectedMonth}
                disabled={
                  mainVersion &&
                  "retention_mode" in mainVersion &&
                  !enabledRetentionPolicy
                }
              />
              <Select
                options={getDaysInMonth(
                  selectedMonth?.value,
                  selectedYear?.value
                )}
                selected={selectedDay}
                setSelected={setSelectedDay}
                disabled={
                  mainVersion &&
                  "retention_mode" in mainVersion &&
                  !enabledRetentionPolicy
                }
              />
              <Select
                options={yearsOptions}
                selected={selectedYear}
                setSelected={setSelectedYear}
                disabled={
                  mainVersion &&
                  "retention_mode" in mainVersion &&
                  !enabledRetentionPolicy
                }
              />
            </div>
          </div>
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button
            buttonProps={{
              onClick: (e) => {
                e.preventDefault();
                dispatch(handleHideObjectRetentionPolicyModal());
              },
            }}
            type="light"
            buttonClassName="w-[70px]"
          >
            Cancel
          </Button>
          <Button
            loading={versionsLoading || retentionPolicyLoading}
            type="primary"
            buttonProps={{
              disabled: versionsLoading || retentionPolicyLoading,
              type: "submit",
            }}
            buttonClassName="w-[70px]"
          >
            Set
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default S3ObjectRetentionPolicyModal;
