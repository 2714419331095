import { useForm } from "react-hook-form";
import Button from "../buttons/Button";
import Text from "../general/Text";
import { ReactComponent as CheckIcon } from "./../../assets/icons/check.svg";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getPlansAsync,
  getSubscriptionsAsync,
  selectPlanGroups,
  selectPlanGroupsLoading,
  selectSubscriptionActionLoading,
  selectSubscriptions,
  selectSubscriptionsLoading,
  updateSubscriptionsAsync,
} from "../../store/billing/billingSlice";
import { useEffect, useMemo } from "react";
import { LoadingSpin } from "../general/Loading";
import { PlanItem } from "./UniversalPlansBox";
import { selectOnStageEnv } from "../../store/auth/authSlice";

//Web Auth
export const Web3AuthPlansBox = () => {
  const env = useAppSelector(selectOnStageEnv);
  const subscriptions = useAppSelector(selectSubscriptions);
  const subscriptionsLoading = useAppSelector(selectSubscriptionsLoading);
  const subscriptionActionLoading = useAppSelector(
    selectSubscriptionActionLoading
  );

  const planGroups = useAppSelector(selectPlanGroups);
  const planGroupsLoading = useAppSelector(selectPlanGroupsLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (subscriptions.length === 0) {
      dispatch(getSubscriptionsAsync());
    }
  }, [dispatch, subscriptions.length]);

  useEffect(() => {
    // if (planGroups.length === 0) {
    dispatch(getPlansAsync());
    // }
  }, [dispatch, planGroups.length]);

  const plans = useMemo(() => {
    const planGroup = planGroups.find((pg) => pg.PlanGroupId === 3);
    if (planGroup) {
      return planGroup.PlanDetail;
    }
    return [];
  }, [planGroups]);

  const handleBuyPlan = (planId: number) => {
    dispatch(updateSubscriptionsAsync({ data: { PlanId: planId } })).then(
      (action) => {
        if (action.type === "billing/update-subscriptions/fulfilled") {
          dispatch(getSubscriptionsAsync());
        }
      }
    );
  };
  return (
    <>
      {(subscriptionsLoading || planGroupsLoading) && (
        <div className="h-full w-full flex items-center justify-center min-h-[300px]">
          <LoadingSpin borderSize={2} />
        </div>
      )}
      {!subscriptionsLoading && !planGroupsLoading && (
        <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 gap-5">
          {plans.map((plan, i) => (
            <PlanItem
              key={i}
              {...plan}
              handleBuy={env?.IsAccountOwner ? handleBuyPlan : undefined}
              selected={subscriptions.map((s) => s.PlanId).includes(plan.Id)}
            />
          ))}
          <PlanItem
            Id={0}
            Default={false}
            Name="Enterprise"
            PlanDescription={`{\n"Users included":"floating",\n"Apps included":"unlimeted",\n"additional Apps":"floating",\n"additional fee after reaching quota (USD/Stored user)":"floating"\n}`}
            selected={false}
          />
        </div>
      )}
    </>
  );
};

// const PlanItem: React.FC<{
//   title: string;
//   price: number;
//   selected?: boolean;
// }> = ({ title, price, selected }) => {
//   return (
//     <div
//       className={classNames(
//         "col-span-1 rounded-2xl border-2 dark:bg-dark-3 mx-auto flex flex-col gap-5 p-4 w-full hover:shadow-lg transition-shadow duration-200",
//         {
//           "border-slate-200 dark:border-gray-800": !selected,
//           "border-primary-400": selected,
//         }
//       )}
//     >
//       <div className="flex flex-col gap-5">
//         <div className="flex items-center gap-2">
//           <Text className="text-base font-medium">{title} </Text>
//           {selected && (
//             <Text type="primary-alert" className="text-xs font-medium">
//               Active plan
//             </Text>
//           )}
//         </div>
//         <div className="flex flex-col gap-2">
//           <div className="flex gap-1 items-center">
//             <Text className="text-2xl font-semibold">
//               {price === 0 ? "Free" : `$${price}`}
//             </Text>
//             {price > 0 && <Text className="text-xs font-medium">/ month</Text>}
//           </div>
//           <Text className="text-sm font-light">
//             $12 per month if paid annually
//           </Text>
//         </div>
//         <Button buttonProps={{}} buttonClassName="!w-full">
//           Buy plan
//         </Button>
//       </div>

//       <div className="flex flex-col gap-3">
//         <Text className="text-sm">Everything necessary to get started</Text>
//         <div className="flex flex-col gap-1">
//           <OptionRow text="5 products" />
//           <OptionRow text="Up to 1,000 subscribers" />
//           <OptionRow text="Basic analytics" />
//           <OptionRow text="B48-hour support response time" />
//         </div>
//       </div>
//     </div>
//   );
// };

// const OptionRow: React.FC<{ text: string | React.ReactNode }> = ({ text }) => {
//   return (
//     <div className="flex items-center gap-2">
//       <div className="w-[14px] h-[14px] rounded-full bg-primary-400 flex justify-center items-center">
//         <CheckIcon className="w-3 h-3 text-white" />
//       </div>
//       <Text className="text-sm font-light">{text}</Text>
//     </div>
//   );
// };
