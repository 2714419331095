import { useAppSelector } from "../../../../hooks";
import {
  selectObjectSize,
  selectObjectSizeLoading,
} from "../../../../store/s3/overviewSlice";
import { S3WidgetType } from "../../../../types/s3";
import BarWidget from "./freams/BarWidget";

const chartType: S3WidgetType = "objectSize";
const chartCode: number = 52;

const ObjectSizeWidget = () => {
  const data = useAppSelector(selectObjectSize);
  const loading = useAppSelector(selectObjectSizeLoading);

  return (
    <BarWidget
      title="Object size distribution"
      chartCode={chartCode}
      chartType={chartType}
      data={data}
      dataLoading={loading}
    />
  );
};

export default ObjectSizeWidget;
