import classNames from "classnames";
import { useMemo, useState } from "react";
import { ReactComponent as ArrowIcon } from "./../../assets/icons/arrow-right.svg";

export type PaginationProps = {
  limit: number;
  offset: number;
  total: number;
  siblingCount?: number;
  onPageChange: (offset: number, limit: number) => void;
  className?: string;
  loading?: boolean;
};

const Pagination = (props: PaginationProps) => {
  const {
    onPageChange,
    total,
    siblingCount = 1,
    offset,
    limit,
    className,
    loading = false,
  } = props;

  const [currentPage, setCurrentPage] = useState(
    Math.floor(offset / limit) + 1
  );

  const paginationRange = usePagination({
    currentPage,
    total,
    siblingCount,
    limit,
  });

  let lastPage = paginationRange && paginationRange[paginationRange.length - 1];

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || Number(paginationRange?.length) < 2) {
    return null;
  }

  const onNext = () => {
    if (!loading) {
      const prevPage = currentPage;
      if (prevPage < Number(lastPage)) {
        setCurrentPage(prevPage + 1);
        const newOffset = prevPage * limit;
        onPageChange(newOffset, limit);
      }
    }
  };

  const onPrevious = () => {
    if (!loading) {
      const prevPage = currentPage;
      if (prevPage - 1 > 0) {
        setCurrentPage(prevPage - 1);
        const newOffset = (prevPage - 2) * limit;
        onPageChange(newOffset, limit);
      }
    }
  };

  const onPage = (page: number) => {
    if (!loading) {
      setCurrentPage(page);
      const newOffset = (page - 1) * limit;
      onPageChange(newOffset, limit);
    }
  };

  return (
    <ul
      className={classNames("flex gap-0.5", { [className || ""]: className })}
    >
      {/* Left navigation arrow */}
      <li
        className={classNames(
          "w-7 h-7 flex justify-center items-center  rounded-full cursor-pointer transition-all duration-100 select-none",
          {
            "bg-slate-100 text-slate-500 hover:bg-primary-500 hover:text-white  dark:bg-dark-1 dark:hover:bg-dark-2 dark:text-slate-100":
              currentPage !== 1, //enable
            "bg-slate-100 text-slate-300 hover:bg-slate-100 hover:text-slate-300  dark:bg-dark-1 dark:hover:bg-dark-2 dark:text-slate-500 !cursor-not-allowed":
              currentPage === 1, //disable
          }
        )}
        onClick={onPrevious}
      >
        <ArrowIcon className="w-4 rotate-180" />
      </li>
      {paginationRange &&
        paginationRange.map((pageNumber, i) => {
          // If the pageItem is a DOT, render the DOTS unicode character
          if (pageNumber === DOTS) {
            return (
              <li
                key={`page-item-${pageNumber}-${i}`}
                className={classNames(
                  "w-7 h-7 flex justify-center items-center rounded-full cursor-default transition-all duration-100 bg-transparent text-slate-500 select-none",
                  { "!cursor-not-allowed": loading }
                )}
              >
                &#8230;
              </li>
            );
          }

          // Render our Page Pills
          return (
            <li
              key={`page-item-${pageNumber}`}
              className={classNames(
                "w-7 h-7 flex justify-center items-center rounded-full cursor-pointer transition-all duration-100 select-none text-sm",
                {
                  "bg-slate-100 text-slate-500 hover:bg-primary-500 hover:text-white dark:bg-dark-1 dark:hover:bg-dark-2 dark:text-slate-300":
                    pageNumber !== currentPage, //not-selected
                  "bg-primary-500 text-white hover:bg-primary-500 hover:text-white":
                    pageNumber === currentPage, //selected
                  "!cursor-not-allowed": loading,
                }
              )}
              onClick={() => onPage(Number(pageNumber))}
            >
              {pageNumber}
            </li>
          );
        })}
      {/*  Right Navigation arrow */}
      <li
        className={classNames(
          "w-7 h-7 flex justify-center items-center  rounded-full cursor-pointer transition-all duration-100 select-none",
          {
            "bg-slate-100 text-slate-500 hover:bg-primary-500 hover:text-white dark:bg-dark-1 dark:hover:bg-dark-2 dark:text-slate-100":
              currentPage !== lastPage, //enable
            "bg-slate-100 text-slate-300 hover:bg-slate-100 hover:text-slate-300 dark:bg-dark-1 dark:hover:bg-dark-2 dark:text-slate-500 !cursor-not-allowed":
              currentPage === lastPage, //disable
            "!cursor-not-allowed": loading,
          }
        )}
        onClick={onNext}
      >
        <ArrowIcon className="w-4" />
      </li>
    </ul>
  );
};

const range = (start: number, end: number) => {
  let length = end - start + 1;
  /*
          Create an array of certain length and set the elements within it from
        start value to end value.
      */
  return Array.from({ length }, (_, idx) => idx + start);
};

export const DOTS = "...";

export const usePagination = ({
  total,
  limit,
  siblingCount = 1,
  currentPage,
}: {
  total: number;
  limit: number;
  siblingCount: number;
  currentPage: number;
}) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(total / limit);

    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    /*
          Case 1:
          If the number of pages is less than the page numbers we want to show in our
          paginationComponent, we return the range [1..totalPageCount]
        */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    /*
            Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
        */
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    /*
          We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
        */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    /*
            Case 2: No left dots to show, but rights dots to be shown
        */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    /*
            Case 3: No right dots to show, but left dots to be shown
        */
    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    /*
            Case 4: Both left and right dots to be shown
        */
    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [total, limit, siblingCount, currentPage]);

  return paginationRange;
};

export default Pagination;
