import Button from "../../buttons/Button";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useMemo, useState } from "react";
import Text from "../../general/Text";
import Card from "../../general/Card";
import { useNavigate, useParams } from "react-router-dom";
import { InstancesUrl, InstancesVolumesUrl } from "../../../utils/urls";
import {
  getDBSCatalogAsync,
  selectDBSCatalog,
  selectDatabaseServicesActionLoading,
} from "../../../store/database/servicesSlice";
import QuestionModal from "../../modals/QuestionModal";
import Loading, { LoadingSpin } from "../../general/Loading";
import { NotExist } from "../../layouts/NotData";

import {
  getInstanceAsync,
  handleInstanceApplicationLoginShowModal,
  handleInstanceBootShowModal,
  handleInstanceColdShowModal,
  handleInstanceHotShowModal,
  handleInstanceNameShowModal,
  handleInstanceReactiveShowModal,
  handleInstanceRebootShowModal,
  handleInstanceReinstallShowModal,
  handleInstanceRescueShowModal,
  handleInstanceStopShowModal,
  handleInstanceSuspendShowModal,
  handleInstanceVolumeShowModal,
  selectInstance,
  selectInstanceLoading,
} from "../../../store/instances/instanceSlice";
import { ReactComponent as MoreIcon } from "../../../assets/icons/more.svg";
import InstancesBackupModal from "./InstancesBackupModal";
import InstancesStopModal from "./InstancesStopModal";
import InstancesRebootInRescueModeModal from "./InstancesRebootInRescueModeModal";
import InstancesHotRebootModal from "./InstancesHotRebootModal";
import InstancesColdRebootModal from "./InstancesColdRebootModal";
import InstancesReinstallModal from "./InstancesReinstallModal";
import {
  deleteInstanceAsync,
  getInstancesAsync,
} from "../../../store/instances/instancesSlice";
import { Instance } from "../../../types/instance";
import InstancesExitRescueMode from "./InstancesExitRescueMode";
import InstancesBootModal from "./InstancesBootModal";
import InstancesReactiveModal from "./InstancesReactiveModal";
import InstancesSuspendModal from "./InstancesSuspendModal";
import { getDBSPriceAmout } from "../../../pages/databases/ServiceCreatePage";
import Dropdown from "../../general/Dropdown";
import InstanceNameModal from "./InstanceNameModal";
import InstanceVolumeModal from "./InstanceVolumeModal";
import {
  getInstancesVolumesAsync,
  selectInstancesVolumes,
  selectInstancesVolumesLoading,
} from "../../../store/instances/instancesVolumesSlice";
import InstanceInformationLoginModal from "./InstanceInformationLoginModal";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil-square.svg";
import { ReactComponent as BootIcon } from "./../../../assets/icons/square-3stack.svg";
import { ReactComponent as StopIcon } from "./../../../assets/icons/stop.svg";
import { ReactComponent as RebootIcon } from "./../../../assets/icons/arrow-path-rounded-square.svg";
import { ReactComponent as PuseIcon } from "./../../../assets/icons/puse-circle.svg";
import { ReactComponent as ExitIcon } from "./../../../assets/icons/arrow-left-start-on-rectangle.svg";

const InstancesOverviewTab = () => {
  const { id } = useParams();

  const [deleteInstance, setDeleteInstance] = useState<Instance | null>(null);

  const instance = useAppSelector(selectInstance);
  const loading = useAppSelector(selectInstanceLoading);
  const actionLoading = useAppSelector(selectDatabaseServicesActionLoading);
  const catalog = useAppSelector(selectDBSCatalog);
  const volumes = useAppSelector(selectInstancesVolumes);
  const volumesLoading = useAppSelector(selectInstancesVolumesLoading);

  const addon =
    catalog && catalog.addons.find((c) => c.planCode === instance?.planCode);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useState<boolean>(false);

  useEffect(() => {
    if (id) {
      dispatch(getInstanceAsync({ instanceId: id }));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (!catalog) {
      dispatch(getDBSCatalogAsync());
    }
  }, [catalog, dispatch]);

  useEffect(() => {
    // if (!volumes) {
    dispatch(getInstancesVolumesAsync({}));
    // }
  }, [dispatch, instance]);

  const filterVolumes = useMemo(() => {
    if (!volumes || !instance) return [];
    return volumes.filter((volume) => volume.region === instance.region);
  }, [volumes, instance]);

  const attachedVolumes = useMemo(() => {
    if (!instance) return null;
    return filterVolumes.find((volume) =>
      volume.attachedTo.includes(instance.id)
    );
  }, [filterVolumes, instance]);

  return (
    <>
      {instance && !loading && (
        <>
          <div className="flex justify-between items-center gap-3 p-3 rounded-md dark:bg-dark-3 border border-slate-200 dark:border-dark-2 mb-5">
            <Text type="title" className="text-sm ">
              {instance.name}
            </Text>

            <div className=" flex  ">
              <div className="mr-2">
                <Button
                  size="medium"
                  type="light"
                  buttonProps={{
                    onClick: () =>
                      dispatch(
                        getInstanceAsync({
                          instanceId: instance.id,
                        })
                      ),
                  }}
                  buttonClassName="group"
                  tooltip="Refresh"
                >
                  <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
                </Button>
              </div>

              {instance && (
                <Dropdown
                  positionClassName="!w-50 max-h-48 overflow-y-auto"
                  menu={[
                    {
                      key: "1",
                      label: (
                        <div className="flex items-center gap-1">
                          <EditIcon className="w-4" />
                          Rename instance
                        </div>
                      ),
                      onClick: () =>
                        dispatch(handleInstanceNameShowModal(instance)),
                    },

                    {
                      key: "boot",
                      label: (
                        <div className="flex items-center gap-1">
                          <BootIcon className="w-4" />
                          Boot
                        </div>
                      ),
                      onClick: () =>
                        dispatch(handleInstanceBootShowModal(instance)),
                      disabled:
                        instance.status === "ACTIVE" ||
                        instance.status === "SHELVED_OFFLOADED" ||
                        instance.status === "UNSHELVING" ||
                        instance.status === "SHELVING",
                    },

                    {
                      key: "stop",
                      label: (
                        <div className="flex items-center gap-1">
                          {" "}
                          <StopIcon className="w-4" />
                          Stop
                        </div>
                      ),
                      onClick: () =>
                        dispatch(handleInstanceStopShowModal(instance)),
                      disabled:
                        instance.status === "SHUTOFF" ||
                        instance.status === "SHELVED_OFFLOADED" ||
                        instance.status === "UNSHELVING" ||
                        instance.status === "SHELVING",
                    },

                    ...(instance.status === "ACTIVE" ||
                    instance.status === "SHUTOFF"
                      ? [
                          {
                            key: "reboot rescue",
                            label: (
                              <div className="flex items-center gap-1">
                                <RebootIcon className="w-4" />
                                Reboot in rescue mode
                              </div>
                            ),
                            onClick: () =>
                              dispatch(handleInstanceRebootShowModal(instance)),
                          },
                        ]
                      : []),
                    ...(instance.status === "ACTIVE"
                      ? [
                          {
                            key: "hot reboot",
                            label: (
                              <div className="flex items-center gap-1">
                                <RebootIcon className="w-4" />
                                Hot reboot (soft)
                              </div>
                            ),
                            onClick: () =>
                              dispatch(handleInstanceHotShowModal(instance)),
                          },

                          {
                            key: "cold reboot",
                            label: (
                              <div className="flex items-center gap-1">
                                <RebootIcon className="w-4" />
                                Cold reboot (hard)
                              </div>
                            ),
                            onClick: () =>
                              dispatch(handleInstanceColdShowModal(instance)),
                          },

                          {
                            key: "reinstall",
                            label: (
                              <div className="flex items-center gap-1">
                                <RefreshIcon className="w-4" />
                                Reinstall
                              </div>
                            ),
                            onClick: () =>
                              dispatch(
                                handleInstanceReinstallShowModal(instance)
                              ),
                          },
                        ]
                      : []),
                    ...(instance.status === "RESCUE"
                      ? [
                          {
                            key: "exit rescue",
                            label: (
                              <div className="flex items-center gap-1">
                                <ExitIcon className="w-4" />
                                Exit rescue mode
                              </div>
                            ),
                            onClick: () =>
                              dispatch(handleInstanceRescueShowModal(instance)),
                          },
                        ]
                      : []),
                    {
                      key: "Suspend",
                      label: (
                        <div className="flex items-center gap-1">
                          {" "}
                          <PuseIcon className="w-4" />
                          Suspend
                        </div>
                      ),
                      onClick: () =>
                        dispatch(handleInstanceSuspendShowModal(instance)),
                      disabled:
                        instance.status === "SHELVED_OFFLOADED" ||
                        instance.status === "UNSHELVING" ||
                        instance.status === "SHELVING",
                    },

                    {
                      key: "Reactive",
                      label: (
                        <div className="flex items-center gap-1">
                          {" "}
                          <RefreshIcon className="w-4" />
                          Reactive
                        </div>
                      ),
                      onClick: () =>
                        dispatch(handleInstanceReactiveShowModal(instance)),
                      disabled:
                        instance.status === "ACTIVE" ||
                        instance.status === "UNSHELVING" ||
                        instance.status === "BUILD" ||
                        instance.status === "SHUTOFF" ||
                        instance.status === "RESCUING" ||
                        instance.status === "RESCUE" ||
                        instance.status === "UNRESCUING" ||
                        instance.status === "REBOOT" ||
                        instance.status === "HARD_REBOOT" ||
                        instance.status === "SHELVING" ||
                        instance.status === "REBUILD",
                    },
                    { type: "divider" as const },
                    {
                      key: "end",
                      label: (
                        <div className="flex items-center gap-1">
                          {" "}
                          <ArchiveIcon className="w-4" />
                          Delete
                        </div>
                      ),
                      danger: true,
                      onClick: (_, close) => {
                        close();
                        setDeleteInstance(instance);
                      },
                      disabled: loading,
                    },
                  ]}
                  type="simple"
                >
                  <Button>Actions</Button>
                </Dropdown>
              )}
            </div>
          </div>

          <div className="mt-5">
            <Card title="Information">
              <div className="mt-2 grid gap-4 md:grid-cols-2">
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Text type="title" className="text-sm">
                        Status
                      </Text>

                      <Text
                        className="text-xs inline-block"
                        type={
                          instance.status === "ACTIVE"
                            ? "success-alert"
                            : "warning-alert"
                        }
                      >
                        {instance.status}
                      </Text>
                    </div>
                  </div>
                </div>

                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Text type="title" className="text-sm">
                        region
                      </Text>
                      <Text className="text-sm">{instance?.region}</Text>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Text type="title" className="text-sm">
                        Model
                      </Text>
                      <Text className="text-sm">{instance?.flavor.name}</Text>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Text type="title" className="text-sm">
                        RAM
                      </Text>
                      <Text className="text-sm">
                        {instance?.flavor.ram / 1000} GB
                      </Text>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Text type="title" className="text-sm">
                        Processor
                      </Text>
                      <Text className="text-sm">
                        {instance?.flavor.vcpus} vCores
                      </Text>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Text type="title" className="text-sm">
                        Storage
                      </Text>
                      <Text className="text-sm inline-block">
                        {instance.flavor.disk} Gib
                      </Text>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Text type="title" className="text-sm">
                        Public bandwidth
                      </Text>
                      <Text className="text-sm inline-block">
                        {instance.flavor.inboundBandwidth} Mbps
                      </Text>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Text type="title" className="text-sm">
                        Price
                      </Text>
                      {addon && (
                        <Text className="text-sm inline-block">
                          €{addon && getDBSPriceAmout(addon.pricings[0].price)}{" "}
                          ex.VAT/{addon.pricings[0].intervalUnit}
                        </Text>
                      )}
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-row md:flex-row">
                    <div className="flex flex-col">
                      <Text type="title" className="text-sm">
                        Volumes
                      </Text>
                      {attachedVolumes?.status === "in-use" ? (
                        <Text>
                          {attachedVolumes?.name} {attachedVolumes?.size} GiB
                        </Text>
                      ) : (
                        <>
                          {volumesLoading ? (
                            <LoadingSpin borderSize={2} />
                          ) : (
                            <Text>-</Text>
                          )}
                        </>
                      )}
                    </div>

                    <div className="w-8 h-8 flex border border-gray-300  rounded-md">
                      <Dropdown
                        positionClassName="!w-48 "
                        menu={[
                          {
                            key: "1",
                            label: (
                              <div className="flex items-center gap-1 ">
                                Manage volumes
                              </div>
                            ),
                            onClick: () => navigate(InstancesVolumesUrl),
                            disabled: loading,
                          },

                          {
                            type: "divider",
                          },
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                Attach a volume
                              </div>
                            ),

                            onClick: () =>
                              dispatch(handleInstanceVolumeShowModal(instance)),
                            disabled: loading,
                          },
                        ]}
                        type="simple"
                      >
                        <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                          <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                        </div>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-row md:flex-row">
                    <div className="flex flex-col">
                      <Text type="title" className="text-sm">
                        Image
                      </Text>

                      <Text>
                        {instance && instance.image
                          ? instance.image.name
                          : "Undefined Image"}
                      </Text>
                    </div>

                    {instance.image && instance.image.tags.length > 0 && (
                      <div className="w-8 h-8 flex border border-gray-300  rounded-md">
                        <Dropdown
                          positionClassName="!w-48 "
                          menu={[
                            {
                              key: "1",
                              label: (
                                <div className="flex items-center gap-1 ">
                                  Login information
                                </div>
                              ),
                              onClick: (_, close) => {
                                close();
                                dispatch(
                                  handleInstanceApplicationLoginShowModal(
                                    instance
                                  )
                                );
                              },
                              disabled: loading,
                            },
                          ]}
                          type="simple"
                        >
                          <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                            <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                          </div>
                        </Dropdown>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Card>
          </div>

          <InstancesBackupModal />
          <InstancesColdRebootModal />
          <InstancesHotRebootModal />
          <InstancesRebootInRescueModeModal />
          <InstancesExitRescueMode />
          <InstancesReinstallModal />
          <InstancesStopModal />
          <InstancesBootModal />
          <InstancesSuspendModal />
          <InstancesReactiveModal />
          <InstanceNameModal />
          <InstanceVolumeModal />
          <InstanceInformationLoginModal />
          <QuestionModal
            title="Delete an instance"
            description={`Are you sure that you want to delete the ${instance.name} instance?`}
            isOpen={deleteInstance !== null}
            onClose={() => setDeleteInstance(null)}
            onConfirm={() => {
              deleteInstance &&
                dispatch(
                  deleteInstanceAsync({
                    id: deleteInstance.id,
                  })
                ).then((action) => {
                  if (action.type === "instances/delete/fulfilled") {
                    dispatch(getInstancesAsync({ withoutLoading: false }));
                    setDeleteInstance(null);
                    navigate(InstancesUrl);
                  }
                });
            }}
            loading={actionLoading}
            confirmButtonType="danger"
          />
        </>
      )}

      {/* empty service */}
      {instance === null && !loading && (
        <div className="h-full flex items-center justify-center px-4">
          <div className="flex flex-col items-center">
            <div className="text-center space-y-1">
              <NotExist
                url={() => navigate(InstancesUrl)}
                text="The cluster does not exist"
              />
            </div>
          </div>
        </div>
      )}

      {loading && <Loading borderSize={2} className="!min-h-[300px]" />}
    </>
  );
};

export default InstancesOverviewTab;
