import { useState, useEffect } from "react";
import PropTypes from "prop-types";

// material-ui
import {
  Box,
  FormControl,
  Stack,
  OutlinedInput,
  Popper,
  // TextField,
  // Typography,
  // IconButton,
  Switch,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { useTheme, styled } from "@mui/material/styles";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

// project imports
// import AnimateButton from "ui-component/extended/AnimateButton";
// import { StyledButton } from "ui-component/StyledButton";
import { DarkCodeEditor } from "./editor/DarkCodeEditor";
import { LightCodeEditor } from "./editor/LightCodeEditor";

// API
// import credentialApi from "api/credential";
// import oauth2Api from "api/oauth2";

// Hooks
// import useApi from "hooks/useApi";
import useScriptRef from "./../../../../hooks/useScriptRef";

// icons
// import { ReactComponent as IconCopy } from "./../../../../assets/icons/copy.svg";
import { ReactComponent as IconTrash } from "./../../../../assets/icons/archive-box.svg";
// import gLoginLogo from "assets/images/google-login-white.png";

//css
import "./InputParameters.css";

import useDarkMode from "../../../../hooks/useDarkMode";
import Text from "../../../general/Text";
import { InfoTooltip } from "../../../general/Tooltip";
import Button from "../../../buttons/Button";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  getNodeCredentialParamsAsync,
  getNodeCredentialsAsync,
  selectWorkflowNodeCredenialParams,
  selectWorkflowNodeCredenialParamsLoading,
  selectWorkflowNodeCredenials,
  selectWorkflowNodeCredenialsLoading,
} from "../../../../store/workflows/workflowSlice";
import {
  createNewCredentialApi,
  deleteCredentialApi,
  geOAuth2PopupUrlApi,
  geOAuth2RedirectUrlApi,
  getSpecificCredentialApi,
  updateCredentialApi,
} from "../../../../apis/workflowsAPI";
import Input from "../../../inputs/Input";
import CopyableKey from "../../../general/CopyableKey";

const StyledPopper = styled(Popper)({
  boxShadow:
    "0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)",
  borderRadius: "10px",
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 10,
      margin: 10,
    },
  },
});

const ADD_NEW_CREDENTIAL = "+ Add New Credential";

// ==============================|| CREDENTIAL INPUT ||============================== //

const CredentialInput = ({
  initialParams,
  paramsType,
  initialValues,
  initialValidation,
  valueChanged,
  paramsChanged,
  onSubmit,
  ...others
}) => {
  // console.log("initialParams", initialParams);
  // console.log("initialValues", initialValues);
  const dispatch = useAppDispatch();
  const scriptedRef = useScriptRef();
  const theme = useTheme();
  // const customization = useSelector((state) => state.customization)
  const { mode } = useDarkMode();

  const [credentialValidation, setCredentialValidation] = useState({});
  const [credentialValues, setCredentialValues] = useState({});
  const [nodeCredentialName, setNodeCredentialName] = useState("");
  const [credentialParams, setCredentialParams] = useState([]);
  const [credentialOptions, setCredentialOptions] = useState([]);
  const [oAuth2RedirectURL, setOAuth2RedirectURL] = useState("");

  // const getCredentialParamsApi = useApi(credentialApi.getCredentialParams);
  // const getRegisteredCredentialsApi = useApi(credentialApi.getCredentials);
  const nodeCredenials = useAppSelector(selectWorkflowNodeCredenials);
  const nodeCredenialsLoading = useAppSelector(
    selectWorkflowNodeCredenialsLoading
  );

  const nodeCredenialParams = useAppSelector(selectWorkflowNodeCredenialParams);
  const nodeCredenialParamsLoading = useAppSelector(
    selectWorkflowNodeCredenialParamsLoading
  );

  const onChanged = (values) => {
    const updateValues = values;
    updateValues.submit = null;
    valueChanged(updateValues, paramsType);
  };

  const getCredentialRequestBody = (values) => {
    if (credentialParams.length === 0)
      throw new Error("Credential params empty");

    const credentialData = {};
    for (let i = 0; i < credentialParams.length; i += 1) {
      const credParamName = credentialParams[i].name;
      if (credParamName in values)
        credentialData[credParamName] = values[credParamName];
    }
    delete credentialData.name;

    const credBody = {
      name: values.name,
      nodeCredentialName: values.credentialMethod,
      credentialData,
    };

    return credBody;
  };

  const updateYupValidation = (inputName, validationKey) => {
    const updateValidation = {
      ...credentialValidation,
      [inputName]: Yup.object({
        [validationKey]: Yup.string().required(`${inputName} is required`),
      }),
    };
    setCredentialValidation(updateValidation);
  };

  const clearCredentialParams = () => {
    const updateParams = initialParams.filter((item) =>
      credentialParams.every((paramItem) => item.name !== paramItem.name)
    );
    setCredentialParams([]);
    setOAuth2RedirectURL("");

    paramsChanged(updateParams, paramsType);
  };

  const clearCredentialParamsValues = (value) => {
    let updateValues = JSON.parse(JSON.stringify(credentialValues));

    for (let i = 0; i < credentialParams.length; i += 1) {
      const credParamName = credentialParams[i].name;
      if (credParamName in updateValues) delete updateValues[credParamName];
    }
    updateValues = {
      ...updateValues,
      registeredCredential: value,
    };
    valueChanged(updateValues, paramsType);
  };

  const onDeleteCredential = async (credentialId) => {
    const response = await deleteCredentialApi(credentialId);
    if (response.data) {
      clearCredentialParams();
      clearCredentialParamsValues("");
    }
  };

  const openOAuth2PopUpWindow = (oAuth2PopupURL) => {
    const windowWidth = 500;
    const windowHeight = 400;
    const left = window.screenX + (window.outerWidth - windowWidth) / 2;
    const top = window.screenY + (window.outerHeight - windowHeight) / 2.5;
    const title = `Connect Credential`;
    const url = oAuth2PopupURL;
    const popup = window.open(
      url,
      title,
      `width=${windowWidth},height=${windowHeight},left=${left},top=${top}`
    );
    return popup;
  };

  const findMatchingOptions = (options, value) =>
    options.find((option) => option.name === value);

  const getDefaultOptionValue = () => "";

  // getRegisteredCredentialsApi successful
  useEffect(() => {
    if (nodeCredenials) {
      const credentialOptions = [];
      if (nodeCredenials.length) {
        for (let i = 0; i < nodeCredenials.length; i += 1) {
          credentialOptions.push({
            _id: nodeCredenials[i]._id,
            name: nodeCredenials[i].name,
          });
        }
      }
      credentialOptions.push({
        name: ADD_NEW_CREDENTIAL,
      });
      setCredentialOptions(credentialOptions);
      if (initialParams.find((prm) => prm.name === "registeredCredential")) {
        updateYupValidation("registeredCredential", "name");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeCredenials]);

  // getCredentialParamsApi successful
  useEffect(() => {
    // console.log("nodeCredenialParams", nodeCredenialParams);
    if (nodeCredenialParams) {
      let newCredentialParams = nodeCredenialParams.credentials;

      const credentialNameParam = {
        label: "Credential Name",
        name: "name",
        type: "string",
        default: "",
      };

      newCredentialParams = [...newCredentialParams, credentialNameParam];

      setCredentialParams(newCredentialParams);

      const updateParams = initialParams;

      for (let i = 0; i < newCredentialParams.length; i += 1) {
        const credParamName = newCredentialParams[i].name;
        if (
          initialParams.find((prm) => prm.name === credParamName) === undefined
        ) {
          updateParams.push(newCredentialParams[i]);
        }
      }
      paramsChanged(updateParams, paramsType);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeCredenialParams]);

  // Initialize values
  useEffect(() => {
    setCredentialValues(initialValues);
    if (initialValues && initialValues.credentialMethod) {
      // getRegisteredCredentialsApi.request(initialValues.credentialMethod);
      dispatch(
        getNodeCredentialsAsync({ name: initialValues.credentialMethod })
      );
      setNodeCredentialName(initialValues.credentialMethod);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  // Initialize validation
  useEffect(() => {
    setCredentialValidation(initialValidation);
  }, [initialValidation]);

  return (
    <>
      <Box>
        <Formik
          enableReinitialize
          initialValues={credentialValues}
          validationSchema={Yup.object().shape(credentialValidation)}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              // if (scriptedRef.current) {
              const isAddNewCredential =
                values &&
                values.registeredCredential &&
                values.registeredCredential.name === ADD_NEW_CREDENTIAL;

              if (
                !isAddNewCredential &&
                (credentialParams.length === 0 || !values.credentialMethod)
              ) {
                onSubmit(
                  values.credentialMethod
                    ? { ...values, submit: true }
                    : { submit: true },
                  paramsType
                );
                setStatus({ success: true });
                setSubmitting(false);
              } else {
                const body = getCredentialRequestBody(values);
                let response;
                if (isAddNewCredential) {
                  response = await createNewCredentialApi(body);
                } else {
                  response = await updateCredentialApi(
                    values.registeredCredential._id,
                    body
                  );
                }
                if (response.data) {
                  const _credential = response.data.Result;
                  // Open oAuth2 window
                  if (
                    values.credentialMethod.toLowerCase().includes("oauth2")
                  ) {
                    const oAuth2PopupURL = await geOAuth2PopupUrlApi(
                      _credential._id
                    );
                    const popUpWindow = openOAuth2PopUpWindow(
                      oAuth2PopupURL.data.Result
                    );

                    const oAuth2Completed = async (event) => {
                      if (event.data === "success") {
                        window.removeEventListener(
                          "message",
                          oAuth2Completed,
                          false
                        );

                        const submitValues = {
                          credentialMethod: values.credentialMethod,
                          registeredCredential: {
                            _id: _credential._id,
                            name: _credential.name,
                          },
                          submit: true,
                        };
                        clearCredentialParams();
                        onSubmit(submitValues, paramsType);
                        setStatus({ success: true });
                        setSubmitting(false);

                        if (popUpWindow) {
                          popUpWindow.close();
                        }
                      }
                    };
                    window.addEventListener("message", oAuth2Completed, false);
                    return;
                  }

                  const submitValues = {
                    credentialMethod: values.credentialMethod,
                    registeredCredential: {
                      _id: _credential._id,
                      name: _credential.name,
                    },
                    submit: true,
                  };
                  clearCredentialParams();
                  onSubmit(submitValues, paramsType);
                  setStatus({ success: true });
                  setSubmitting(false);
                } else {
                  throw new Error(response);
                }
              }
              // }
            } catch (err) {
              console.error(err);
              // if (scriptedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
              // }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit} {...others}>
              {initialParams.map((input) => {
                if (input.type === "options") {
                  const inputName = input.name;
                  const availableOptions = input.options || [];

                  return (
                    <FormControl
                      key={inputName}
                      fullWidth
                      sx={{ mb: 1, mt: 1 }}
                    >
                      <div className="flex items-center gap-1">
                        <Text className="text-xs">{input.label}</Text>
                        {input.description && (
                          <InfoTooltip content={input.description} clickable />
                        )}
                      </div>
                      <Autocomplete
                        id={inputName}
                        freeSolo
                        options={availableOptions}
                        value={
                          findMatchingOptions(
                            availableOptions,
                            values[inputName]
                          ) || getDefaultOptionValue()
                        }
                        onChange={(e, selection) => {
                          const value = selection ? selection.name : "";
                          setFieldValue(inputName, value);
                          const overwriteValues = {
                            [inputName]: value,
                          };
                          onChanged(overwriteValues);
                          clearCredentialParams();
                          if (selection) {
                            dispatch(getNodeCredentialsAsync({ name: value }));
                            setNodeCredentialName(value);
                          } else {
                            setCredentialOptions([]);
                          }
                        }}
                        onBlur={handleBlur}
                        PopperComponent={StyledPopper}
                        renderInput={(params) => (
                          <div ref={params.InputProps.ref}>
                            <Input inputProps={params.inputProps} />
                          </div>
                        )}
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            <div className="flex flex-col">
                              <Text className="text-sm">{option.label}</Text>
                              {option.description && (
                                <Text className="text-xs p-0.5" type="subtext">
                                  {option.description}
                                </Text>
                              )}
                            </div>
                          </Box>
                        )}
                        sx={{
                          p: 0,
                          color:
                            mode === "dark" ? "rgb(75,85,99)" : "rgb(51,65,85)",
                        }}
                        size="small"
                        // className="bg-secondary-100 focus:ring-0 text-sm rounded-lg block w-full p-0 dark:bg-dark-2 outline-none disabled:cursor-not-allowed disabled:bg-secondary-200 dark:disabled:bg-gray-700 dark:disabled:text-secondary-400 disabled:text-secondary-500 disabled:border-secondary-300 disabled:dark:border-gray-600"
                      />
                      {errors[inputName] && (
                        <span
                          style={{
                            color: "red",
                            fontSize: "0.7rem",
                            fontStyle: "italic",
                          }}
                        >
                          {errors[inputName]}
                        </span>
                      )}
                    </FormControl>
                  );
                }
                return null;
              })}

              {initialParams.find(
                (prm) => prm.name === "registeredCredential"
              ) && (
                <FormControl fullWidth sx={{ mb: 1, mt: 1 }}>
                  <div className="flex items-center gap-1">
                    <Text className="text-xs">Registered Credential</Text>
                    <InfoTooltip content="Select previously registered credential OR add new credential" />
                  </div>
                  <Autocomplete
                    id="registered-credential"
                    freeSolo
                    options={credentialOptions}
                    value={
                      values.registeredCredential && values.credentialMethod
                        ? values.registeredCredential
                        : " "
                    }
                    getOptionLabel={(option) => option.name || " "}
                    onChange={async (e, selectedCredential) => {
                      setFieldValue(
                        "registeredCredential",
                        selectedCredential !== null
                          ? selectedCredential
                          : initialValues.registeredCredential
                      );
                      const overwriteValues = {
                        ...values,
                        registeredCredential: selectedCredential,
                      };
                      onChanged(overwriteValues);
                      if (selectedCredential) {
                        if (selectedCredential.name !== ADD_NEW_CREDENTIAL) {
                          const resp = await getSpecificCredentialApi(
                            selectedCredential._id
                          );
                          if (resp.data.Result) {
                            const _cre = resp.data.Result;

                            const updateValues = {
                              ...overwriteValues,
                              ..._cre.credentialData,
                              name: _cre.name,
                            };
                            valueChanged(updateValues, paramsType);
                          }
                        } else {
                          clearCredentialParamsValues(selectedCredential);
                        }
                        dispatch(
                          getNodeCredentialParamsAsync({
                            name: nodeCredentialName,
                          })
                        );
                        if (
                          values.credentialMethod
                            .toLowerCase()
                            .includes("oauth2")
                        ) {
                          const redirectURLResp =
                            await geOAuth2RedirectUrlApi();
                          if (redirectURLResp.data)
                            setOAuth2RedirectURL(redirectURLResp.data.Result);
                        }
                      }
                    }}
                    onInputChange={(e, value) => {
                      if (!value) {
                        clearCredentialParams();
                        clearCredentialParamsValues("");
                      }
                    }}
                    onBlur={handleBlur}
                    PopperComponent={Popper}
                    renderInput={(params) => (
                      <div ref={params.InputProps.ref}>
                        <Input inputProps={params.inputProps} />
                      </div>
                    )}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        <div className="flex flex-col">
                          <Text className="text-sm">{option.name}</Text>
                        </div>
                      </Box>
                    )}
                    sx={{
                      p: 0,
                      color:
                        mode === "dark" ? "rgb(75,85,99)" : "rgb(51,65,85)",
                    }}
                    size="small"
                    // className="bg-secondary-100 focus:ring-0 text-sm rounded-lg block w-full p-0 dark:bg-dark-2 outline-none disabled:cursor-not-allowed disabled:bg-secondary-200 dark:disabled:bg-gray-700 dark:disabled:text-secondary-400 disabled:text-secondary-500 disabled:border-secondary-300 disabled:dark:border-gray-600"
                  />
                  {errors.registeredCredential && (
                    <span
                      style={{
                        color: "red",
                        fontSize: "0.7rem",
                        fontStyle: "italic",
                      }}
                    >
                      Registered Credential is required
                    </span>
                  )}
                </FormControl>
              )}

              {values &&
                values.registeredCredential &&
                values.registeredCredential._id && (
                  <Button
                    buttonClassName="mb-1"
                    buttonProps={{
                      onClick: (e) => {
                        e.preventDefault();
                        onDeleteCredential(values.registeredCredential._id);
                      },
                    }}
                    size="small"
                  >
                    <IconTrash className="w-3 h-3" />
                    Delete Credential
                  </Button>
                )}

              {oAuth2RedirectURL && (
                <div>
                  <Text className="text-xs">OAuth2 Redirect URL</Text>

                  <Stack direction="row">
                    <CopyableKey text={oAuth2RedirectURL} />
                  </Stack>
                </div>
              )}

              {values.credentialMethod &&
                credentialParams.map((input) => {
                  if (input.type === "json") {
                    const inputName = input.name;

                    return (
                      <FormControl
                        key={inputName}
                        fullWidth
                        sx={{ mb: 1, mt: 1 }}
                        error={Boolean(errors[inputName])}
                      >
                        <div className="flex items-center gap-1">
                          <Text className="text-xs">{input.label}</Text>
                          {input.description && (
                            <InfoTooltip
                              content={input.description}
                              clickable
                            />
                          )}
                        </div>
                        <div className="border h-[200px] max-h-[200px] overflow-x-hidden overflow-y-auto">
                          {mode === "dark" ? (
                            <DarkCodeEditor
                              value={values[inputName] || ""}
                              onValueChange={(code) => {
                                setFieldValue(inputName, code);
                              }}
                              placeholder={input.placeholder}
                              type={input.type}
                              onBlur={(e) => {
                                const overwriteValues = {
                                  ...values,
                                  [inputName]: e.target.value,
                                };
                                onChanged(overwriteValues);
                              }}
                              style={{
                                fontSize: "0.875rem",
                                minHeight: "200px",
                                width: "100%",
                              }}
                            />
                          ) : (
                            <LightCodeEditor
                              value={values[inputName] || ""}
                              onValueChange={(code) => {
                                setFieldValue(inputName, code);
                              }}
                              placeholder={input.placeholder}
                              type="json"
                              onBlur={(e) => {
                                const overwriteValues = {
                                  ...values,
                                  [inputName]: e.target.value,
                                };
                                onChanged(overwriteValues);
                              }}
                              style={{
                                fontSize: "0.875rem",
                                minHeight: "200px",
                                width: "100%",
                              }}
                            />
                          )}
                        </div>
                        {errors[inputName] && (
                          <span
                            style={{
                              color: "red",
                              fontSize: "0.7rem",
                              fontStyle: "italic",
                            }}
                          >
                            {errors[inputName]}
                          </span>
                        )}
                      </FormControl>
                    );
                  }

                  if (
                    input.type === "string" ||
                    input.type === "password" ||
                    input.type === "number"
                  ) {
                    const inputName = input.name;

                    return (
                      <FormControl
                        key={inputName}
                        fullWidth
                        sx={{ mb: 1, mt: 1 }}
                        error={Boolean(errors[inputName])}
                      >
                        <div className="flex items-center gap-1">
                          <Text className="text-xs">{input.label}</Text>
                          {input.description && (
                            <InfoTooltip
                              clickable
                              content={input.description}
                            />
                          )}
                        </div>
                        <OutlinedInput
                          id={inputName}
                          type={
                            input.type === "string" || input.type === "number"
                              ? "text"
                              : input.type
                          }
                          value={values[inputName] || ""}
                          placeholder={input.placeholder}
                          name={inputName}
                          onBlur={(e) => {
                            handleBlur(e);
                            onChanged(values);
                          }}
                          onChange={handleChange}
                          sx={{
                            p: 0,
                            borderRadius: 2,
                            backgroundColor:
                              mode === "dark"
                                ? "rgb(32,36,37)"
                                : "rgb(241,245,249)",
                            border:
                              mode === "dark"
                                ? "solid 2px rgb(32,36,37)"
                                : `solid 2px rgb(241,245,249)`,
                            "&:focus": {
                              borderColor:
                                mode === "dark"
                                  ? "rgb(71,85,105)"
                                  : "rgb(226,232,240)",
                            },
                            "&:hover": { outline: "0" },
                            color: mode === "dark" ? "white" : "black",
                          }}
                          size="small"
                          // className="bg-secondary-100 focus:ring-0 text-sm rounded-lg block w-full p-0 dark:bg-dark-2 outline-none disabled:cursor-not-allowed disabled:bg-secondary-200 dark:disabled:bg-gray-700 dark:disabled:text-secondary-400 disabled:text-secondary-500 disabled:border-secondary-300 disabled:dark:border-gray-600"
                        />
                        {errors[inputName] && (
                          <span
                            style={{
                              color: "red",
                              fontSize: "0.7rem",
                              fontStyle: "italic",
                            }}
                          >
                            {errors[inputName]}
                          </span>
                        )}
                      </FormControl>
                    );
                  }

                  if (input.type === "boolean") {
                    const inputName = input.name;

                    return (
                      <FormControl
                        key={inputName}
                        fullWidth
                        sx={{ mb: 1, mt: 1 }}
                        error={Boolean(errors[inputName])}
                      >
                        <div className="flex items-center gap-1">
                          <Text className="text-xs">{input.label}</Text>
                          {input.description && (
                            <InfoTooltip
                              content={input.description}
                              clickable
                            />
                          )}
                        </div>
                        <Switch
                          checked={!!values[inputName]}
                          onChange={(event) => {
                            setFieldValue(inputName, event.target.checked);
                            const overwriteValues = {
                              ...values,
                              [inputName]: event.target.checked,
                            };
                            onChanged(overwriteValues);
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </FormControl>
                    );
                  }

                  if (input.type === "options") {
                    const inputName = input.name;
                    const availableOptions = input.options || [];

                    return (
                      <FormControl
                        key={inputName}
                        fullWidth
                        sx={{ mb: 1, mt: 1 }}
                      >
                        <div className="flex items-center gap-1">
                          <Text className="text-xs">{input.label}</Text>
                          {input.description && (
                            <InfoTooltip
                              content={input.description}
                              clickable
                            />
                          )}
                        </div>
                        <Autocomplete
                          id={inputName}
                          freeSolo
                          options={availableOptions}
                          value={
                            findMatchingOptions(
                              availableOptions,
                              values[inputName]
                            ) || getDefaultOptionValue()
                          }
                          onChange={(e, selection) => {
                            const value = selection ? selection.name : "";
                            setFieldValue(inputName, value);
                            const overwriteValues = {
                              ...values,
                              [inputName]: value,
                            };
                            onChanged(overwriteValues);
                          }}
                          onBlur={handleBlur}
                          PopperComponent={StyledPopper}
                          renderInput={(params) => (
                            <div ref={params.InputProps.ref}>
                              <Input inputProps={params.inputProps} />
                            </div>
                          )}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              <div className="flex flex-col">
                                <Text className="text-sm">{option.label}</Text>
                                {option.description && (
                                  <Text className="text-xs" type="subtext">
                                    {option.description}
                                  </Text>
                                )}
                              </div>
                            </Box>
                          )}
                          sx={{
                            p: 0,
                            color:
                              mode === "dark"
                                ? "rgb(75,85,99)"
                                : "rgb(51,65,85)",
                          }}
                          size="small"
                        />
                        {errors[inputName] && (
                          <span
                            style={{
                              color: "red",
                              fontSize: "0.7rem",
                              fontStyle: "italic",
                            }}
                          >
                            {errors[inputName]}
                          </span>
                        )}
                      </FormControl>
                    );
                  }
                  return null;
                })}

              <Box sx={{ mt: 2 }}>
                {!(values.credentialMethod || "")
                  .toLowerCase()
                  .includes("google") && (
                  <Button
                    buttonClassName="!w-full"
                    buttonProps={{
                      disabled: isSubmitting || Object.keys(errors).length > 0,
                      type: "submit",
                    }}
                  >
                    {values &&
                    values.registeredCredential &&
                    (values.registeredCredential.name === ADD_NEW_CREDENTIAL ||
                      credentialParams.length)
                      ? "Save and Continue"
                      : "Continue"}
                  </Button>
                )}
                {(values.credentialMethod || "")
                  .toLowerCase()
                  .includes("google") && (
                  <Button
                    buttonProps={{
                      disabled: isSubmitting || Object.keys(errors).length > 0,
                      type: "submit",
                    }}
                    buttonClassName="!w-full"
                  >
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        width: "100%",
                        height: 50,
                        background: "white",
                      }}
                    >
                      {/* <img
                        style={{
                          objectFit: "contain",
                          height: "100%",
                          width: "100%",
                          padding: 7,
                        }}
                        src={gLoginLogo}
                        alt="Google Login"
                      /> */}
                    </div>
                  </Button>
                )}
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </>
  );
};

CredentialInput.propTypes = {
  initialParams: PropTypes.array,
  paramsType: PropTypes.string,
  initialValues: PropTypes.object,
  initialValidation: PropTypes.object,
  valueChanged: PropTypes.func,
  paramsChanged: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default CredentialInput;
