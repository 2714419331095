import { useNavigate } from "react-router-dom";
import Button from "../../components/buttons/Button";
import { ReactComponent as BucketIcon } from "./../../assets/icons/bucket.svg";
import { ReactComponent as CloseIcon } from "./../../assets/icons/close.svg";
import { ReactComponent as ArrowRightIcon } from "./../../assets/icons/arrow-right.svg";
import { S3BucketsUrl } from "../../utils/urls";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  createBucketAsync,
  getBucketsAsync,
  selectActionLoading,
  selectBuckets,
  selectLoading,
} from "../../store/s3/buckets/bucketsSlice";
import { useEffect, useState } from "react";
import RadioGrouper, {
  RadioGroupeItem,
} from "../../components/inputs/RadioGrouper";
import Select, { SelectOption } from "../../components/inputs/Select";
import {
  modeOptions,
  validityOptions,
} from "../../components/s3/buckets/modals/buckets/S3RetentionEditorModal";
import S3QuotaForm, {
  capacityOptions,
} from "../../components/s3/buckets/forms/S3QuotaForm";
import Input from "../../components/inputs/Input";
import Switcher from "../../components/inputs/Switcher";
import { InfoTooltip } from "../../components/general/Tooltip";
import S3ExclodeForm from "../../components/s3/buckets/forms/S3ExclodeForm";
import S3BucketNameValidation from "../../components/s3/buckets/forms/S3BucketNameValidation";
import { S3bucketSchema } from "../../utils/validations";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { binarySize } from "../../utils/file";
import { Helmet } from "react-helmet";
import Text from "../../components/general/Text";

const S3BucketCreatePage = () => {
  const buckets = useAppSelector(selectBuckets);
  const bucketsLoading = useAppSelector(selectLoading);
  const bucketActionLoading = useAppSelector(selectActionLoading);

  const [versioning, setVersioning] = useState<boolean>(false);
  const [isExcludeFolders, setIsExcludeFolders] = useState<boolean>(false);
  const [objectLocking, setObjectLocking] = useState<boolean>(false);
  const [quota, setQuota] = useState<boolean>(false);
  const [retention, setRetention] = useState<boolean>(false);
  const [selectedCapacityOption, setSelectedCapacityOption] =
    useState<SelectOption>(capacityOptions[0]);
  const [selectedValidityOption, setSelectedValidityOption] =
    useState<SelectOption>(validityOptions[0]);
  const [selectedMode, setSelectedMode] = useState<RadioGroupeItem>(
    modeOptions[0]
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm({
    resolver: yupResolver(
      S3bucketSchema(versioning, objectLocking, quota, retention, [
        ...buckets.map((b) => b.name),
      ])
    ),
  });
  const bucketName = watch("name");

  useEffect(() => {
    if (buckets.length === 0) {
      dispatch(getBucketsAsync());
    }
  }, [buckets.length, dispatch]);

  useEffect(() => {
    setValue("name", "");
    setValue("capacity", 1);
    setValue("validity", 180);
    setSelectedCapacityOption(capacityOptions[0]);
    setSelectedMode(modeOptions[0]);
    setSelectedValidityOption(validityOptions[0]);
  }, [setValue]);

  const onSubmit = (data: any) => {
    // data:
    //capacity -> number or ''
    //validity -> number or '' -used
    //name -used
    //excludePrefixes -used
    //--- others:
    // versioning -used
    // objectLocking -used
    // quota -used
    // selectedCapacityOption -used
    // retention
    // selectedMode
    // selectedValidityOption

    let bucketData: any = {
      name: data.name,
      locking: objectLocking,
      versioning: {
        enabled: versioning,
        excludePrefixes: data.excludePrefixes?.map((e: any) => e.prefix),
        excludeFolders: isExcludeFolders,
      },
    };

    if (quota) {
      const quotaValue = {
        enabled: quota,
        type: "hard",
        amount:
          data.capacity !== ""
            ? binarySize(data.capacity, selectedCapacityOption.value.toString())
            : 0,
      };
      bucketData.quota = quotaValue;
    }

    if (retention) {
      const RetentionValue = {
        mode: selectedMode.value,
        unit: selectedValidityOption?.value,
        validity: data.validity,
      };
      bucketData.retention = RetentionValue;
    }

    dispatch(createBucketAsync(bucketData)).then((action) => {
      if (action.type === "buckets/create/fulfilled") {
        dispatch(getBucketsAsync()).then(() => {
          navigate(S3BucketsUrl);
        });
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | S3 (create new bucket)</title>
        <meta
          name="description"
          content="Simple-storage technology refers to a straightforward method of storing data or information in a manner that is easy to implement and access"
        />
      </Helmet>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center justify-between h-16 px-6 bg-white dark:bg-dark-1">
          <div className="items-center justify-between flex flex-1 transition duration-150">
            <Text className="font-medium flex items-center gap-1">
              <BucketIcon className="w-5 h-5" />
              Create Bucket
            </Text>
          </div>
          <div className="">
            <Button
              type="light"
              size="small"
              buttonProps={{
                onClick: () => navigate(S3BucketsUrl),
              }}
              buttonClassName="group"
            >
              <CloseIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>
          </div>
        </div>

        <div className="mt-5 w-full overflow-x-auto px-6 min-h-[calc(100%-6rem)]">
          <div className="grid grid-cols-1 md:grid-cols-5 pb-20 gap-5 mt-5">
            <div className="col-span-1 md:col-span-3 flex flex-col w-full gap-5 p-5 rounded-lg border bg-white dark:bg-dark-3 dark:border-dark-2">
              <div className="flex flex-col gap-1">
                <Input
                  label="Name"
                  inputProps={{ ...register("name") }}
                  error={errors.name?.message}
                  placeholder=""
                />
              </div>
              <div className="flex items-center justify-between w-full">
                <div className="flex gap-2 items-center">
                  <div className="text-sm text-slate-700 dark:text-slate-200">
                    Versioning
                  </div>
                  <InfoTooltip content="Versioning allows to keep multiple versions of the same object under the same key." />
                </div>
                <Switcher
                  disabled={objectLocking}
                  on={versioning}
                  onToggle={setVersioning}
                />
              </div>

              {versioning && !objectLocking && (
                <S3ExclodeForm
                  enabledExclodeFolders={isExcludeFolders}
                  setExcludeFoldersStatus={setIsExcludeFolders}
                  errors={errors}
                  register={register}
                  control={control}
                />
              )}
              <div className="flex items-center justify-between w-full">
                <div className="flex gap-2 items-center">
                  <div className="text-sm text-slate-700 dark:text-slate-200">
                    Object Locking
                  </div>
                  <InfoTooltip content="Object Locking prevents objects from being deleted. Required to support retention and legal hold. Can only be enabled at bucket creation." />
                </div>
                <Switcher
                  onToggle={(v) => {
                    setObjectLocking(v);
                    v && setVersioning(v);
                  }}
                  on={objectLocking}
                  disabled={retention}
                />
              </div>

              <S3QuotaForm
                enabledQuota={quota}
                setQuotaStatus={setQuota}
                capacityInputProps={{ ...register("capacity") }}
                capacityErrorMessage={errors.capacity?.message}
                selectedCapacityOption={selectedCapacityOption}
                setSelectedCapacityOption={setSelectedCapacityOption}
              />

              {versioning && (
                <div className="flex flex-col gap-2">
                  <div className="flex items-center justify-between w-full">
                    <div className="flex gap-2 items-center">
                      <div className="text-sm text-slate-700 dark:text-slate-200">
                        Retention
                      </div>
                      <InfoTooltip content="Retention imposes rules to prevent object deletion for a period of time. Versioning must be enabled in order to set bucket retention policies." />
                    </div>
                    <Switcher
                      on={retention}
                      onToggle={(v) => {
                        setRetention(v);
                        if (v) {
                          setObjectLocking(true);
                        }
                      }}
                    />
                  </div>
                  {retention && (
                    <div className="flex flex-col gap-3">
                      <div className="flex gap-5">
                        <div className="text-sm text-slate-500 dark:text-slate-200">
                          Mode
                        </div>
                        <div className="flex-1">
                          <RadioGrouper
                            items={modeOptions}
                            selected={selectedMode}
                            setSelected={setSelectedMode}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="text-sm text-slate-500 dark:text-slate-200">
                          Validity
                        </div>
                        <div className="flex gap-2">
                          <div className="flex-1">
                            <Input
                              inputProps={{
                                ...register("validity"),
                              }}
                              error={errors.validity?.message}
                            />
                          </div>
                          <div className="w-20">
                            <Select
                              options={validityOptions}
                              selected={selectedValidityOption}
                              setSelected={(o) =>
                                o && setSelectedValidityOption(o)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="col-span-1 md:col-span-2">
              <S3BucketNameValidation name={bucketName} />
            </div>
          </div>
        </div>
        <div className="fixed bottom-0 right-0 left-0 flex items-center justify-end w-full h-16 border-t bg-white dark:bg-dark-1 dark:border-dark-2 px-6  md:pl-[17.5rem]">
          <div>
            <Button
              type="primary"
              buttonProps={{
                disabled: bucketsLoading || bucketActionLoading,
                type: "submit",
              }}
              buttonClassName="group w-[110px]"
              loading={bucketsLoading || bucketActionLoading}
            >
              Create
              <ArrowRightIcon className="w-4 h-4 group-hover:scale-110 group-hover:translate-x-1 transition-all duration-300" />
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default S3BucketCreatePage;
