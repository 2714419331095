import { useAppDispatch, useAppSelector } from "../../../hooks";
import Modal from "../../modals/Modal";
import Text from "../../general/Text";
import {
  applicationAccessInstanceAsync,
  handleInstanceApplicationLoginHideModal,
  selectInstance,
  selectInstanceLoginInformation,
  selectInstanceLoginInformationLoading,
  selectShowApplicationLoginModal,
} from "../../../store/instances/instanceSlice";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { CopyableInput } from "../../general/CopyableKey";

const InstanceInformationLoginModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectShowApplicationLoginModal);
  const instance = useAppSelector(selectInstance);
  const loginInformation = useAppSelector(selectInstanceLoginInformation);
  const loading = useAppSelector(selectInstanceLoginInformationLoading);

  useEffect(() => {
    if (isOpen && instance) {
      dispatch(
        applicationAccessInstanceAsync({
          instanceId: instance.id,
          data: { instanceId: instance.id },
        })
      );
    }
  }, [isOpen, instance, dispatch]);

  const handleClose = () => {
    dispatch(handleInstanceApplicationLoginHideModal());
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      contentClassName="max-w-lg"
      // containerClassName="!items-start"
      title="Application login"
    >
      <div className="flex flex-col mt-9 mx-2 gap-4">
        <div className="flex flex-row gap-1">
          <Text className="text-sm font-medium">Type:</Text>
          <Text className="text-sm min-w-[150px]" loading={loading}>
            {loginInformation?.accesses[0].type}
          </Text>
        </div>
        <div className="flex flex-row gap-1">
          <Text className="text-sm font-medium">URL:</Text>
          {loginInformation && (
            <Text className="min-w-[150px]" loading={loading}>
              <Link
                to={loginInformation?.accesses[0].url}
                className="text-sm text-sky-500 hover:text-sky-600"
                target="_blank"
              >
                {loginInformation?.accesses[0].url}
              </Link>
            </Text>
          )}
        </div>
        <div className="flex flex-row gap-1">
          <Text className="text-sm font-medium">ID:</Text>
          <Text className="text-sm min-w-[150px]" loading={loading}>
            {loginInformation?.accesses[0].login}
          </Text>
        </div>

        <div className="flex flex-row gap-1 items-center">
          <Text className="text-sm font-medium">Password</Text>
          <CopyableInput
            title=""
            value={loginInformation?.accesses[0].password}
            loading={loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default InstanceInformationLoginModal;
