import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Modal from "../../modals/Modal";
import Button from "../../buttons/Button";
import Text from "../../general/Text";
import {
  getInstanceAsync,
  handleInstanceNameHideModal,
  selectInstance,
  selectInstanceLoading,
  selectShowNameModal,
  updateInstanceNameAsync,
} from "../../../store/instances/instanceSlice";
import { getInstancesAsync } from "../../../store/instances/instancesSlice";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { InstanceNameSchema } from "../../../utils/validations";
import Input from "../../inputs/Input";

const InstanceNameModal = () => {
  const isOpen = useAppSelector(selectShowNameModal);
  const [isInstanceNameChanged, setInstanceNameChanged] = useState(false);
  const instance = useAppSelector(selectInstance);
  const instanceLoading = useAppSelector(selectInstanceLoading);
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors: errorsName },
    setValue,
  } = useForm({
    resolver: yupResolver(InstanceNameSchema),
    shouldUnregister: true,
    reValidateMode: "onChange",
  });

  //Name
  useEffect(() => {
    if (instance) {
      setValue("instanceName", instance.name);
    }
  }, [instance, setValue]);

  const handleInstanceNameChange = () => {
    if (!isInstanceNameChanged) {
      setInstanceNameChanged(true);
    }
  };

  const onSubmit = (data: any) => {
    if (instance) {
      dispatch(
        updateInstanceNameAsync({
          instanceId: instance.id,
          data,
        })
      ).then((action) => {
        if (action.type === "instance/update/fulfilled") {
          reset();
          dispatch(handleInstanceNameHideModal());
          dispatch(getInstancesAsync({ withoutLoading: false }));
          dispatch(getInstanceAsync({ instanceId: instance.id }));
        }
      });
    }
  };
  const handleClose = () => {
    reset();
    dispatch(handleInstanceNameHideModal());
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      contentClassName="max-w-lg"
      //containerClassName="!items-start"
      title=" Instance name"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <div className="my-5">
          <Text className="font-medium">Location</Text>
          <Text className="text-xs">{instance?.region}</Text>
        </div>

        <div className="mb-5">
          <Text className=" font-medium">SSH key</Text>
          <Text className="text-xs">{instance?.sshKey?.name}</Text>
        </div>

        <div>
          <Text className="font-medium">Instance name</Text>
          <Input
            inputProps={{
              ...register("instanceName"),
              onChange: handleInstanceNameChange,
            }}
            error={errorsName.instanceName?.message}
          />
        </div>

        <div>
          <Text className="text-sm ">
            May contain numbers, letters, underscores, dashes, and full stops
            only.
          </Text>
        </div>
        <div className="mt-4 flex gap-5 justify-end">
          <Button
            type="primary"
            buttonProps={{
              type: "submit",
              disabled: !isInstanceNameChanged || instanceLoading,
            }}
          >
            Change the name
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default InstanceNameModal;
