import { useAppDispatch, useAppSelector } from "../../../hooks";
import Modal from "../../modals/Modal";
import Input from "../../inputs/Input";
import Button from "../../buttons/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DBSIPsSchema } from "../../../utils/validations";
import {
  getDatabaseServicesAsync,
  updateDBSAsync,
  selectDatabaseServicesActionLoading,
} from "../../../store/database/servicesSlice";

import { useEffect } from "react";
import Textarea from "../../inputs/Textarea";
import {
  getDatabaseServiceAsync,
  handleHideIPsEditorModal,
  selectDatabaseService,
  selectDatabaseServiceEditorIsOpen,
  selectDelectedDatabaseServiceIP,
} from "../../../store/database/serviceSlice";
import { DBSUpdateApiData } from "../../../types/database";

const IpsCreateModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectDatabaseServiceEditorIsOpen);
  const loading = useAppSelector(selectDatabaseServicesActionLoading);
  const selectedIP = useAppSelector(selectDelectedDatabaseServiceIP);
  const service = useAppSelector(selectDatabaseService);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(DBSIPsSchema),
    shouldUnregister: true,
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (isOpen) {
      if (selectedIP) {
        setValue("ip", selectedIP.ip);
        setValue("description", selectedIP.description);
      }
    } else {
      setValue("ip", "");
      setValue("description", "");
    }
  }, [isOpen, selectedIP, setValue]);

  const onSubmit = (data: any) => {
    if (data.ip && !data.ip.includes("/")) {
      data.ip = `${data.ip}/32`;
    }
    if (service && selectedIP) {
      // Updating an existing IPrestriction
      const updatedIpRestrictions = service.ipRestrictions.map(
        (restriction: any) => {
          if (restriction.ip === selectedIP.ip) {
            return { ip: data.ip, description: data.description };
          }
          return { ip: restriction.ip, description: restriction.description };
        }
      );

      const formData: DBSUpdateApiData = {
        ipRestrictions: updatedIpRestrictions,
      };

      dispatch(
        updateDBSAsync({
          id: service.id,
          engine: service.engine,
          data: formData,
        })
      ).then((action) => {
        if (action.type === "services/update/fulfilled") {
          dispatch(handleHideIPsEditorModal());
          dispatch(getDatabaseServicesAsync());
          dispatch(getDatabaseServiceAsync({ id: service.id }));
        }
      });
    } else if (service && selectedIP === null) {
      const newIpRestriction = { ip: data.ip, description: data.description };
      let updatedIpRestrictions: any[] = [];
      if (Array.isArray(service.ipRestrictions)) {
        updatedIpRestrictions = service.ipRestrictions.map(
          (restriction: any) => ({
            ip: restriction.ip,
            description: restriction.description,
          })
        );
      }
      updatedIpRestrictions.push(newIpRestriction);
      const formData: DBSUpdateApiData = {
        ipRestrictions: updatedIpRestrictions,
      };

      dispatch(
        updateDBSAsync({
          id: service.id,
          engine: service.engine,
          data: formData,
        })
      ).then((action) => {
        if (action.type === "services/update/fulfilled") {
          reset();
          dispatch(handleHideIPsEditorModal());
          dispatch(getDatabaseServicesAsync());
          dispatch(getDatabaseServiceAsync({ id: service.id }));
        }
      });
    }
  };

  const handleClose = () => {
    reset();
    dispatch(handleHideIPsEditorModal());
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      contentClassName="max-w-lg"
      // containerClassName="!items-start"
      title={
        <div className="text-base">
          {selectedIP === null ? "Create" : "Update"} an IP address or IP
          address block (CIDR)
        </div>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5">
          <Input
            inputProps={{ ...register("ip") }}
            error={errors.ip?.message}
            placeholder="Enter an IPv4 address or network range"
            label="IP address or IP/mask"
            required
          />
        </div>
        <div className="mt-5">
          {" "}
          <Textarea
            label="Description"
            textareaProps={{ ...register("description"), rows: 7 }}
            error={errors.description?.message}
            textareaClassName="text-xs"
          />
        </div>

        <div className="mt-4 flex justify-end gap-2">
          <Button
            type="light"
            buttonProps={{
              onClick: handleClose,
            }}
          >
            Cancel
          </Button>

          <Button
            type="primary"
            buttonProps={{
              // disabled: !isValid,
              type: "submit",
            }}
            loading={loading}
          >
            {selectedIP ? "Edit" : "Confirm"}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default IpsCreateModal;
