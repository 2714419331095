import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Button from "../../buttons/Button";
import Dropdown from "../../general/Dropdown";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import NotData from "../../layouts/NotData";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil-square.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import Web3ApiKeyEditorModal from "./Web3ApiKeyEditorModal";
import { HiddenKey } from "../../general/HiddenKey";
import QuestionModal, { DeleteModal } from "../../modals/QuestionModal";
import Highlighter from "react-highlight-words";
import { useSearch } from "../../../providers/SearchProvider";
import {
  deleteWeb3AuthEndpointApiKeyAsync,
  getWeb3AuthEndpointApiKeysAsync,
  handleShowApiKeyEditor,
  selectWeb3AuthEndpoint,
  selectWeb3AuthEndpointActionLoading,
  selectWeb3AuthEndpointApiKeys,
  selectWeb3AuthEndpointApiKeysLoading,
  selectWeb3AuthEndpointLoading,
} from "../../../store/web3-auth/web3AuthEndpointSlice";
import { Web3AuthApiKey } from "../../../types/web3-auth";

const Web3AuthEndpointApiKeysTab = () => {
  const endpoint = useAppSelector(selectWeb3AuthEndpoint);
  const endpointLoading = useAppSelector(selectWeb3AuthEndpointLoading);
  const [deleteApiKeyId, setDeleteApiKeyId] = useState<number | null>(null);
  const apiKeys = useAppSelector(selectWeb3AuthEndpointApiKeys);
  const [filteredApiKeys, setFilteredApiKeys] = useState<Web3AuthApiKey[]>([]);
  const loading = useAppSelector(selectWeb3AuthEndpointApiKeysLoading);
  const actionLoading = useAppSelector(selectWeb3AuthEndpointActionLoading);
  const dispatch = useAppDispatch();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (endpoint) {
      dispatch(getWeb3AuthEndpointApiKeysAsync(endpoint.Id.toString()));
    }
  }, [dispatch, endpoint]);

  useEffect(() => {
    const lookedUpFiles = apiKeys.filter((apiKey) =>
      apiKey.Label?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredApiKeys(lookedUpFiles);
  }, [dispatch, searchValue, apiKeys]);

  return (
    <>
      <div className="flex transition-all duration-500">
        <div className="text-slate-800 dark:text-slate-100">Access keys</div>
        <div className="ml-auto flex items-center justify-end gap-1 md:gap-2">
          <Button
            type="primary"
            buttonProps={{
              onClick: () => dispatch(handleShowApiKeyEditor({})),
            }}
          >
            Create New
          </Button>
        </div>
      </div>
      <Table loading={loading || endpointLoading} className="mt-6">
        <TableHead>
          <TableRow>
            <TableTH lable="Label" />
            <TableTH lable="Value" />
            <TableTH lable="" />
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredApiKeys.map((apiKey, index) => (
            <TableRow key={index}>
              <TableTD className="w-36">
                <Highlighter
                  highlightClassName="bg-primary-100 dark:bg-primary-300 rounded-sm"
                  className="whitespace-nowrap max-w-[110px] md:max-w-none text-ellipsis overflow-hidden text-sm font-medium"
                  searchWords={[searchValue]}
                  autoEscape={true}
                  textToHighlight={apiKey.Label}
                />
              </TableTD>
              <TableTD>
                <div className="text-sm dark:text-slate-200">
                  <HiddenKey text={apiKey.KeyValue} />
                </div>
              </TableTD>
              <TableTD className="w-10">
                <div className="w-8 flex justify-center items-center">
                  <Dropdown
                    positionClassName="!w-40"
                    menu={[
                      {
                        key: "1",
                        label: (
                          <div className="flex items-center gap-1">
                            <EditIcon className="w-4" />
                            Edit
                          </div>
                        ),
                        onClick: () =>
                          dispatch(handleShowApiKeyEditor({ apiKey })),
                      },
                      {
                        type: "divider",
                      },
                      {
                        key: "end",
                        label: (
                          <div className="flex items-center gap-1">
                            <ArchiveIcon className="w-4" />
                            Delete
                          </div>
                        ),
                        danger: true,
                        onClick: () => {
                          setDeleteApiKeyId(apiKey.Id);
                        },
                      },
                    ]}
                    type="simple"
                  >
                    <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                      <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                    </div>
                  </Dropdown>
                </div>
              </TableTD>
            </TableRow>
          ))}
          {apiKeys.length === 0 && (
            <TableRow withoutHoverStyle={true}>
              <TableTD colSpan={3} className="!border-0">
                <NotData />
              </TableTD>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Web3ApiKeyEditorModal />

      <DeleteModal
        title="Delete Access key"
        isOpen={deleteApiKeyId !== null}
        onClose={() => setDeleteApiKeyId(null)}
        description="To confirm, type 'DELETE' in the box below"
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        onConfirm={() => {
          deleteApiKeyId &&
            endpoint &&
            dispatch(
              deleteWeb3AuthEndpointApiKeyAsync({
                endpointId: endpoint.Id,
                id: deleteApiKeyId,
              })
            ).then((action) => {
              if (
                action.type === "web3-auth/endpoint/api-keys/delete/fulfilled"
              ) {
                dispatch(
                  getWeb3AuthEndpointApiKeysAsync(endpoint.Id.toString())
                );
              }
              setDeleteApiKeyId(null);
            });
        }}
        loading={actionLoading}
        // confirmString={apiKeys.find((api) => api.Id === deleteApiKeyId)?.Label}
      />
    </>
  );
};

export default Web3AuthEndpointApiKeysTab;
