import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { IThunkRejectValue, RootState } from "../../types";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import {
  // INode,
  INodeCredential,
  INodeCredentialParam,
  INodeResponse,
  IWorkflowResponse,
  WorkflowState,
} from "../../types/workflows";
import {
  deleteWorkflowExecutionApi,
  getNodeApi,
  getNodeCredentialParamsApi,
  getNodeRegisteredCredentialsApi,
  getWorkflowApi,
  testNodeApi,
  testWorkflowApi,
} from "../../apis/workflowsAPI";
import { isAxiosError } from "axios";
import { changeWorkflowActiveStatus } from "./workflowsSlice";

const initialState: WorkflowState = {
  workflow: null,
  workflowLoading: false,
  actionLoading: false,

  removeEdgeId: "",
  isDirty: false,

  node: null,
  nodeLoading: false,

  //used in credentialInput component
  credentials: [],
  credentialsLoading: false,

  //used in credentialInput component
  credentialParams: null,
  credentialParamsLoading: false,

  test: null,
  testLoading: false,

  executionDeleteLoading: false,

  testWorkflowLoading: false,
};

export const getWorkflowAsync = createAsyncThunk<
  { workflow: IWorkflowResponse },
  string,
  IThunkRejectValue
>("workflow", async (id, { rejectWithValue, fulfillWithValue, dispatch }) => {
  try {
    const response = await getWorkflowApi(id);
    const { NodeStatus, Result: workflow } = response.data;

    dispatch(changeWorkflowActiveStatus(NodeStatus === 2));

    return fulfillWithValue({ workflow });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getNodeAsync = createAsyncThunk<
  { node: INodeResponse },
  { name: string },
  IThunkRejectValue
>("workflow/node", async ({ name }, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getNodeApi(name);
    const node = response.data.Result;

    return fulfillWithValue({ node });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getNodeCredentialsAsync = createAsyncThunk<
  { credentials: Array<INodeCredential> },
  { name: string },
  IThunkRejectValue
>(
  "workflow/node/credentials",
  async ({ name }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getNodeRegisteredCredentialsApi(name);
      const credentials = response.data.Result;

      return fulfillWithValue({ credentials });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteWorkflowExecutionAsync = createAsyncThunk<
  boolean,
  string,
  IThunkRejectValue
>("workflow/executions/delete", async (executionId, { rejectWithValue }) => {
  try {
    await deleteWorkflowExecutionApi(executionId);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getNodeCredentialParamsAsync = createAsyncThunk<
  { credentialParams: INodeCredentialParam },
  { name: string },
  IThunkRejectValue
>(
  "workflow/node/credential-params",
  async ({ name }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getNodeCredentialParamsApi(name);
      const credentialParams = response.data.Result;

      return fulfillWithValue({ credentialParams });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const testNodeAsync = createAsyncThunk<
  any,
  { name: string; data: any },
  IThunkRejectValue
>(
  "workflow/node/test",
  async ({ name, data }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await testNodeApi(name, data);
      if (isAxiosError(response)) {
        return rejectWithValue({ message: "has error" });
      } else {
        const test = response.data.Result;
        return fulfillWithValue({ test });
      }
    } catch (e) {
      console.log("catch", e);
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const testWorkflowAsync = createAsyncThunk<
  boolean,
  { startingNodeId: string; data: any },
  IThunkRejectValue
>(
  "workflow/test",
  async ({ startingNodeId, data }, { rejectWithValue, fulfillWithValue }) => {
    try {
      await testWorkflowApi(startingNodeId, data);
      return true;
    } catch (e) {
      console.log("catch", e);
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const workflowSlice = createSlice({
  name: "workflow",
  initialState,
  reducers: {
    handleSetRemoveEdge: (state, action: PayloadAction<{ edgeId: string }>) => {
      state.removeEdgeId = action.payload.edgeId;
    },
    handleSetDirty: (state) => {
      state.isDirty = true;
    },
    handleRemoveDirty: (state) => {
      state.isDirty = false;
    },
    handleSetWorkflow: (
      state,
      action: PayloadAction<{ workflow: IWorkflowResponse | null }>
    ) => {
      state.workflow = action.payload.workflow;
    },
    handleClearWorkflow: (state) => {
      state.node = null;
      state.credentialParams = null;
      state.credentials = [];
    },
    handleChangeTestLoading: (state, action: PayloadAction<boolean>) => {
      state.testLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkflowAsync.pending, (state) => {
        state.workflowLoading = true;
      })
      .addCase(getWorkflowAsync.fulfilled, (state, action) => {
        const { workflow } = action.payload;
        state.workflow = workflow;
        state.workflowLoading = false;
      })
      .addCase(getWorkflowAsync.rejected, (state, { payload }) => {
        state.workflowLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getNodeAsync.pending, (state) => {
        state.nodeLoading = true;
      })
      .addCase(getNodeAsync.fulfilled, (state, { payload: { node } }) => {
        state.node = node;
        state.nodeLoading = false;
      })
      .addCase(getNodeAsync.rejected, (state, { payload }) => {
        state.nodeLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getNodeCredentialsAsync.pending, (state) => {
        state.credentialsLoading = true;
      })
      .addCase(
        getNodeCredentialsAsync.fulfilled,
        (state, { payload: { credentials } }) => {
          state.credentials = credentials;
          state.credentialsLoading = false;
        }
      )
      .addCase(getNodeCredentialsAsync.rejected, (state, { payload }) => {
        state.credentialsLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getNodeCredentialParamsAsync.pending, (state) => {
        state.credentialParamsLoading = true;
      })
      .addCase(
        getNodeCredentialParamsAsync.fulfilled,
        (state, { payload: { credentialParams } }) => {
          state.credentialParams = credentialParams;
          state.credentialParamsLoading = false;
        }
      )
      .addCase(getNodeCredentialParamsAsync.rejected, (state, { payload }) => {
        state.credentialParamsLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(testNodeAsync.pending, (state) => {
        state.testLoading = true;
      })
      .addCase(testNodeAsync.fulfilled, (state, { payload: { test } }) => {
        state.test = test;
        state.testLoading = false;
      })
      .addCase(testNodeAsync.rejected, (state, { payload }) => {
        state.testLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteWorkflowExecutionAsync.pending, (state) => {
        state.executionDeleteLoading = true;
      })
      .addCase(deleteWorkflowExecutionAsync.fulfilled, (state) => {
        state.executionDeleteLoading = false;
      })
      .addCase(deleteWorkflowExecutionAsync.rejected, (state, { payload }) => {
        state.executionDeleteLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(testWorkflowAsync.pending, (state) => {
        state.testWorkflowLoading = true;
      })
      .addCase(testWorkflowAsync.fulfilled, (state) => {
        state.testWorkflowLoading = false;
      })
      .addCase(testWorkflowAsync.rejected, (state, { payload }) => {
        state.testWorkflowLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectCanvas = (state: RootState) => state.workflow;

export const selectWorkflow = (state: RootState) => state.workflow.workflow;
export const selectWorkflowLoading = (state: RootState) =>
  state.workflow.workflowLoading;

export const selectWorkflowActionLoading = (state: RootState) =>
  state.workflow.actionLoading;

export const selectWorkflowNode = (state: RootState) => state.workflow.node;
export const selectWorkflowNodeLoading = (state: RootState) =>
  state.workflow.nodeLoading;

export const selectWorkflowNodeCredenials = (state: RootState) =>
  state.workflow.credentials;
export const selectWorkflowNodeCredenialsLoading = (state: RootState) =>
  state.workflow.credentialsLoading;

export const selectWorkflowNodeCredenialParams = (state: RootState) =>
  state.workflow.credentialParams;
export const selectWorkflowNodeCredenialParamsLoading = (state: RootState) =>
  state.workflow.credentialParamsLoading;

export const selectWorkflowNodeTest = (state: RootState) => state.workflow.test;
export const selectWorkflowNodeTestLoading = (state: RootState) =>
  state.workflow.testLoading;

export const selectWorkflowDeleteExecutionLoading = (state: RootState) =>
  state.workflow.executionDeleteLoading;

export const selectWorkflowTestLoading = (state: RootState) =>
  state.workflow.testWorkflowLoading;

export const {
  handleSetRemoveEdge,
  handleSetDirty,
  handleRemoveDirty,
  handleSetWorkflow,
  handleClearWorkflow,
  handleChangeTestLoading,
} = workflowSlice.actions;
export default workflowSlice.reducer;
