import { useEffect, useState } from "react";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../../general/Table";
import { ReactComponent as MoreIcon } from "./../../../../assets/icons/more.svg";
import { ReactComponent as ArchiveIcon } from "./../../../../assets/icons/archive-box.svg";

import {
  selectBucketDetails,
  selectBucketDetailsLoading,
} from "../../../../store/s3/buckets/bucketSlice";
import {
  getBucketLifecyclesAsync,
  selectBucketLifecycleActionLoading,
  selectBucketLifecycles,
  selectBucketLifecyclesLoading,
  deleteBucketLifecycleAsync,
  handleShowLifecycleEditor,
} from "../../../../store/s3/buckets/setting/lifecycleSlice";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import NotData, { S3NotActiveTab } from "../../../layouts/NotData";
import QuestionModal, { DeleteModal } from "../../../modals/QuestionModal";
import Dropdown from "../../../general/Dropdown";
import Button from "../../../buttons/Button";
import S3LifecycleEditorModal from "../modals/buckets/S3LifecycleEditorModal";
import Text from "../../../general/Text";
import { selectS3Status } from "../../../../store/s3/s3PublicSlice";
import { ReactComponent as EditIcon } from "./../../../../assets/icons/pencil-square.svg";

const S3BucketLifecycleTab = () => {
  const s3Status = useAppSelector(selectS3Status);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const bucketDetailsLoading = useAppSelector(selectBucketDetailsLoading);

  const lifecycles = useAppSelector(selectBucketLifecycles);
  const loading = useAppSelector(selectBucketLifecyclesLoading);
  const actionLoading = useAppSelector(selectBucketLifecycleActionLoading);

  const [deleteLifecycle, setDeleteLifecycle] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (bucketDetails && !bucketDetailsLoading) {
      dispatch(getBucketLifecyclesAsync({ name: bucketDetails.name }));
    }
  }, [bucketDetails, bucketDetailsLoading, dispatch]);

  const handleDelete = () => {
    if (deleteLifecycle && bucketDetails)
      dispatch(
        deleteBucketLifecycleAsync({
          bucketName: bucketDetails.name,
          id: deleteLifecycle,
        })
      ).then((action) => {
        if (action.type === "bucket/lifecycle/delete/fulfilled") {
          dispatch(getBucketLifecyclesAsync({ name: bucketDetails.name }));
          setDeleteLifecycle(null);
        }
      });
  };

  if (!s3Status) {
    return <S3NotActiveTab />;
  }
  return (
    <>
      <div className="flex transition-all duration-500 items-center justify-between gap-3">
        <Text className="whitespace-nowrap font-medium">Lifecycle Rules</Text>
        <div className="flex items-center justify-end gap-1 md:gap-2 ">
          {" "}
          <Button
            type="primary"
            buttonProps={{
              onClick: () => dispatch(handleShowLifecycleEditor({})),
            }}
          >
            Add Lifecycle Rule
          </Button>
        </div>
      </div>
      <div className="mt-5 w-full">
        <Table loading={loading}>
          <TableHead>
            <TableRow>
              <TableTH lable="Type" />
              <TableTH lable="Version" />
              <TableTH lable="Tier" />
              <TableTH lable="Prefix" />
              <TableTH lable="After" />
              <TableTH lable="Status" />
              <TableTH lable="" />
            </TableRow>
          </TableHead>
          <TableBody>
            {lifecycles?.map((lifecycle, i) => (
              <TableRow key={i}>
                <TableTD>
                  <Text className="text-xs md:text-sm font-semibold whitespace-nowrap">
                    {lifecycle.transition.storage_class ||
                    lifecycle.transition.noncurrent_storage_class
                      ? "Transition"
                      : "Expiry"}
                  </Text>
                </TableTD>
                <TableTD>
                  <Text className="text-xs md:text-sm whitespace-nowrap">
                    {"noncurrent_expiration_days" in lifecycle.expiration ||
                    "noncurrent_transition_days" in lifecycle.transition
                      ? "Non-Current"
                      : "Current"}
                  </Text>
                </TableTD>
                <TableTD>
                  <Text className="text-xs md:text-sm whitespace-nowrap">
                    {lifecycle.transition.storage_class ||
                      lifecycle.transition.noncurrent_storage_class}
                  </Text>
                </TableTD>
                <TableTD>
                  <Text className="text-xs md:text-sm whitespace-nowrap">
                    {lifecycle.prefix}
                  </Text>
                </TableTD>
                <TableTD>
                  <Text className="text-xs md:text-sm whitespace-nowrap">
                    {lifecycle.transition.days ||
                      lifecycle.transition.noncurrent_transition_days ||
                      lifecycle.expiration.days ||
                      lifecycle.expiration.noncurrent_expiration_days}{" "}
                    days
                  </Text>
                </TableTD>
                <TableTD>
                  <Text className="text-xs md:text-sm whitespace-nowrap">
                    {lifecycle.status}
                  </Text>
                </TableTD>
                <TableTD className="w-32 gap-1">
                  <div className="h-full w-full inline-flex items-center justify-end gap-1">
                    <div className="w-8 flex justify-center items-center">
                      <Dropdown
                        positionClassName="!w-40"
                        menu={[
                          {
                            key: "edit",
                            label: (
                              <div className="flex itmes-center gap-1">
                                <EditIcon className="w-4"></EditIcon>
                                Edit
                              </div>
                            ),
                            onClick: () =>
                              dispatch(
                                handleShowLifecycleEditor({ lifecycle })
                              ),
                          },
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: (_, close) => {
                              close();
                              setDeleteLifecycle(lifecycle.id);
                            },
                          },
                        ]}
                        type="simple"
                      >
                        <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                          <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                        </div>
                      </Dropdown>
                    </div>
                  </div>
                </TableTD>
              </TableRow>
            ))}

            {lifecycles?.length === 0 && (
              <TableRow withoutHoverStyle={true}>
                <TableTD colSpan={7} className="!border-0">
                  <NotData text="You have not added any lifecycle yet" />
                </TableTD>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <S3LifecycleEditorModal />
      <DeleteModal
        isOpen={deleteLifecycle !== null}
        onClose={() => setDeleteLifecycle(null)}
        onConfirm={handleDelete}
        loading={actionLoading || bucketDetailsLoading}
        title="Delete Lifecycle Rule"
        // description={`Are you sure you want to delete the ${deleteLifecycle} rule?`}
        confirmButtonType="danger"
        // confirmString={lifecycles.find((l) => l.id === deleteLifecycle)?.id}
      />
    </>
  );
};

export default S3BucketLifecycleTab;
