import { useAppDispatch, useAppSelector } from "../../../hooks";
import Modal from "../../modals/Modal";
import Button from "../../buttons/Button";
import {
  handleDBSPoolInfo,
  selectDBSDatabases,
  selectDBSDatabasesLoading,
  selectDBSPoolsActionLoading,
  selectDBSPoolsShowInfo,
  selectDBSSelectedPool,
  selectDatabaseService,
} from "../../../store/database/serviceSlice";
import { SelectOption } from "../../inputs/Select";
import Text from "../../general/Text";
import CopyableKey, { CopyableInput } from "../../general/CopyableKey";
import Dropdown from "../../general/Dropdown";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import DBSCertificate from "../general-information/DBSCertificate";

export const modeOptions: SelectOption[] = [
  { label: "session", value: "session" },
  { label: "statement", value: "statement" },
  { label: "transaction", value: "transaction" },
];

const DBSPoolInfoModal = () => {
  const dispatch = useAppDispatch();

  const selectedPool = useAppSelector(selectDBSSelectedPool);

  const isOpen = useAppSelector(selectDBSPoolsShowInfo);
  const actionLoading = useAppSelector(selectDBSPoolsActionLoading);

  // const service = useAppSelector(selectDatabaseService);
  // const serviceLoading = useAppSelector(selectDatabaseServiceLoading);

  // const users = useAppSelector(selectDatabaseServiceUser);
  // const usersLoading = useAppSelector(selectDatabaseServiceUserLoading);

  const databases = useAppSelector(selectDBSDatabases);
  const databasesLoading = useAppSelector(selectDBSDatabasesLoading);

  const service = useAppSelector(selectDatabaseService);

  // useEffect(() => {
  //   if (isOpen && service && databases.length === 0) {
  //     dispatch(
  //       getDBSDatabasesAsync({ engine: service.engine, clusterId: service.id })
  //     );
  //   }
  // }, [databases.length, dispatch, isOpen, service]);

  // useEffect(() => {
  //   if (isOpen && service && users.length === 0) {
  //     dispatch(getDBSUsersAsync({ engine: service.engine, id: service.id }));
  //   }
  // }, [dispatch, isOpen, service, users.length]);

  const handleClose = () => {
    dispatch(handleDBSPoolInfo({ status: false, pool: null }));
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      contentClassName="max-w-xl"
      title={`Pool informations`}
    >
      <div className="mt-5 flex flex-col gap-3">
        <div className="grid grid-cols-8 items-center gap-10">
          <Text className="text-sm font-medium col-span-2">Database:</Text>
          <Text className="text-sm  col-span-3" loading={databasesLoading}>
            {databases.find((d) => d.id === selectedPool?.databaseId)?.name}
          </Text>
        </div>
        <div className="grid grid-cols-8 items-center gap-10">
          <Text className="text-sm font-medium col-span-2">Port:</Text>
          <Text className="text-sm ">{selectedPool?.port}</Text>
        </div>
        <div className="grid grid-cols-8 items-center gap-10">
          <Text className="text-sm font-medium col-span-2">SSL mode:</Text>
          <Text className="text-sm ">{selectedPool?.sslMode}</Text>
        </div>

        <div className="grid grid-cols-8 items-center gap-5 w-full">
          <Text className="text-sm font-medium col-span-2">CA certificate</Text>
          <div className="col-span-6">
            {service && (
              <DBSCertificate
                service={service}
                validEngines={["mysql", "cassandra", "postgresql", "kafka"]}
              />
            )}
          </div>
        </div>

        <div className="grid grid-cols-8 items-center gap-10">
          <Text className="text-sm font-medium col-span-2">URI service:</Text>
          <div className="col-span-6">
            <CopyableKey text={selectedPool?.uri} />
          </div>
        </div>
        <div className="grid grid-cols-8 items-center gap-10">
          <Text className="text-sm font-medium col-span-2">Mode:</Text>
          <Text className="text-sm ">{selectedPool?.mode}</Text>
        </div>
        <div className="grid grid-cols-8 items-center gap-10">
          <Text className="text-sm font-medium col-span-2">Size:</Text>
          <Text className="text-sm ">{selectedPool?.size}</Text>
        </div>
      </div>

      <div className="mt-4 flex justify-end gap-2">
        <Button
          type="light"
          buttonProps={{
            onClick: handleClose,
          }}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default DBSPoolInfoModal;
