import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ReactComponent as SunIcon } from "./../../assets/icons/sun.svg";
import { ReactComponent as MoonIcon } from "./../../assets/icons/moon.svg";
import { ReactComponent as SystemIcon } from "./../../assets/icons/computer-desktop.svg";
import useDarkMode from "../../hooks/useDarkMode";
import classNames from "classnames";

const ThemeDropdown = ({ itemsClassName }: { itemsClassName?: string }) => {
  const { mode, modeRefrence, changeMode } = useDarkMode();
  return (
    <Menu as="div" className="relative text-left flex justify-center">
      <Menu.Button className="inline-flex w-full justify-center items-center text-sm font-medium focus:outline-none focus-visible:ring-0">
        {mode === "light" && <SunIcon className="w-6 h-6 hover:scale-110" />}
        {mode === "dark" && <MoonIcon className="w-6 h-6 hover:scale-110" />}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={classNames(
            "absolute bg-white dark:bg-zinc-900 ring-slate-900/10 dark:ring-black z-50 top-full rounded-lg ring-1 shadow-lg overflow-hidden w-36 py-1 text-sm font-semibold dark:highlight-white/5 mt-1",
            {
              [itemsClassName || ""]: itemsClassName,
              "right-0": !itemsClassName,
            }
          )}
        >
          <Menu.Item>
            {({ active }) => (
              <div
                onClick={() => changeMode("light")}
                className={classNames(
                  "group flex w-full items-center rounded-md px-2 py-1 text-sm cursor-pointer ",
                  {
                    "bg-slate-50 dark:bg-zinc-800": active,
                    "text-gray-900 dark:text-slate-300":
                      modeRefrence !== "manual" ||
                      (modeRefrence === "manual" && mode === "dark"),
                    "text-sky-500 dark:text-sky-500":
                      mode === "light" && modeRefrence === "manual",
                  }
                )}
              >
                <SunIcon className="w-5 h-5 mr-2" />
                Light
              </div>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <div
                onClick={() => changeMode("dark")}
                className={classNames(
                  "group flex w-full items-center rounded-md px-2 py-1 text-sm cursor-pointer",
                  {
                    "bg-slate-50 dark:bg-zinc-800": active,
                    "text-gray-900 dark:text-slate-300":
                      modeRefrence !== "manual",
                    "dark:text-sky-500":
                      mode === "dark" && modeRefrence === "manual",
                  }
                )}
              >
                <MoonIcon className="w-5 h-5 mr-2" />
                Dark
              </div>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <div
                onClick={() => changeMode("system")}
                className={classNames(
                  "group flex w-full items-center rounded-md px-2 py-1 text-sm cursor-pointer",
                  {
                    "bg-slate-50 dark:bg-zinc-800": active,
                    "text-gray-900 dark:text-slate-300":
                      modeRefrence !== "system",
                    "text-sky-500 dark:text-sky-500": modeRefrence === "system",
                  }
                )}
              >
                <SystemIcon className="w-5 h-5 mr-2" />
                System
              </div>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ThemeDropdown;
