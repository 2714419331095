import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Popper,
  IconButton,
} from "@mui/material";

// third-party
import ReactJson from "react-json-view";

// project imports
// import MainCard from "ui-component/cards/MainCard";
import Transitions from "./../extended/Transitions";
import ExpandDataDialog from "./../dialog/ExpandDataDialog";

// icons
import { ReactComponent as IconX } from "./../../../../assets/icons/close.svg";
import { ReactComponent as IconArrowsMaximize } from "./../../../../assets/icons/arrows-pointing-out.svg";
import { ReactComponent as ExpandMoreIcon } from "./../../../../assets/icons/chevron-down.svg";

import useDarkMode from "../../../../hooks/useDarkMode";
import Text from "../../../general/Text";
import { IconBtnWrapper } from "../../../general/Wrappers";

// ==============================|| VARIABLE SELECTOR ||============================== //

const isPositiveNumeric = (value) => /^\d+$/.test(value);

const VariableSelector = ({
  nodes,
  isVariableSelectorOpen,
  anchorEl,
  onVariableSelected,
  handleClose,
}) => {
  const theme = useTheme();
  const { mode } = useDarkMode();

  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const [showExpandDialog, setShowExpandDialog] = useState(false);
  const [expandDialogProps, setExpandDialogProps] = useState({});

  const varPrevOpen = useRef(open);

  const handleAccordionChange = (nodeLabel) => (event, isExpanded) => {
    setExpanded(isExpanded ? nodeLabel : false);
  };

  const onClipboardCopy = (e, node) => {
    const namespaces = e.namespace;
    let returnVariablePath = `${node.id}`;
    for (let i = 0; i < namespaces.length; i += 1) {
      const namespace = namespaces[i];
      if (namespace !== "root") {
        if (isPositiveNumeric(namespace)) {
          if (returnVariablePath.endsWith(".")) {
            returnVariablePath = returnVariablePath.substring(
              0,
              returnVariablePath.length - 1
            );
          }
          returnVariablePath += `[${namespace}]`;
        } else {
          returnVariablePath += namespace;
        }
        if (i !== namespaces.length - 1) {
          returnVariablePath += ".";
        }
      }
    }
    onVariableSelected(returnVariablePath);
  };

  const onExpandDialogClicked = (data, node) => {
    const dialogProp = {
      title: `Variable Data: ${node.data.label}`,
      data,
      node,
    };
    setExpandDialogProps(dialogProp);
    setShowExpandDialog(true);
  };

  // Handle Accordian
  useEffect(() => {
    varPrevOpen.current = open;
  }, [open]);

  useEffect(() => {
    setOpen(isVariableSelectorOpen);
  }, [isVariableSelectorOpen]);

  return (
    <>
      <Popper
        placement="right-start"
        open={open}
        role={undefined}
        transition
        anchorEl={anchorEl}
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [37, 370],
              },
            },
          ],
        }}
        sx={{ zIndex: 900, width: 350 }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper className="!border-0 dark:!bg-dark-3 dark:!border-2 dark:!border-dark-2 !shadow-xl !transition-all !rounded-2xl">
              <div className="shadow">
                <div className="flex items-center justify-between p-1 px-2">
                  <Text className="text-sm">Variable Selector</Text>
                  <IconBtnWrapper onClick={handleClose} className="">
                    <IconX className="w-4 h-4 text-slate-700 dark:text-slate-300" />
                  </IconBtnWrapper>
                </div>
                <div className="h-full max-h-[calc(100vh-250px)] overflow-x-hidden overflow-y-auto">
                  {nodes && nodes.length === 0 && (
                    <Text className="p-2 text-sm">No variables</Text>
                  )}

                  {nodes && nodes.length > 0 && (
                    <Box sx={{ p: 2 }}>
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: 330,
                          py: 0,
                          borderRadius: "10px",
                          [theme.breakpoints.down("md")]: {
                            maxWidth: 300,
                          },
                          "& .MuiListItemSecondaryAction-root": {
                            top: 22,
                          },
                          "& .MuiDivider-root": {
                            my: 0,
                          },
                          "& .list-container": {
                            pl: 7,
                          },
                        }}
                      >
                        {nodes.map((node, index) => (
                          <Box key={index}>
                            <Accordion
                              expanded={expanded === node.data.label}
                              onChange={handleAccordionChange(node.data.label)}
                              sx={{ boxShadow: "none" }}
                              className="bg-white dark:bg-dark-3"
                            >
                              <AccordionSummary
                                expandIcon={
                                  <ExpandMoreIcon className="w-4 h-4 text-slate-700 dark:text-slate-300" />
                                }
                                aria-controls={`${node.data.label}-content`}
                                id={`${node.data.label}-header`}
                              >
                                <Text className="text-sm font-semibold">
                                  {node.data.label}
                                </Text>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div style={{ position: "relative" }}>
                                  <ReactJson
                                    theme={
                                      mode === "dark" ? "ocean" : "rjv-default"
                                    }
                                    style={{ padding: 10, borderRadius: 10 }}
                                    collapsed
                                    src={
                                      node.data.outputResponses &&
                                      node.data.outputResponses.output
                                        ? node.data.outputResponses.output
                                        : {}
                                    }
                                    enableClipboard={(e) =>
                                      onClipboardCopy(e, node)
                                    }
                                  />
                                  <IconButton
                                    size="small"
                                    sx={{
                                      height: 25,
                                      width: 25,
                                      position: "absolute",
                                      top: 5,
                                      right: 5,
                                    }}
                                    title="Expand Variable"
                                    color="primary"
                                    onClick={() =>
                                      onExpandDialogClicked(
                                        node.data.outputResponses &&
                                          node.data.outputResponses.output
                                          ? node.data.outputResponses.output
                                          : {},
                                        node
                                      )
                                    }
                                  >
                                    <IconArrowsMaximize />
                                  </IconButton>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </Box>
                        ))}
                      </List>
                    </Box>
                  )}
                </div>
              </div>
            </Paper>
          </Transitions>
        )}
      </Popper>
      <ExpandDataDialog
        enableClipboard
        show={showExpandDialog}
        dialogProps={expandDialogProps}
        onCancel={() => setShowExpandDialog(false)}
        onCopyClick={(e, node) => {
          onClipboardCopy(e, node);
          setShowExpandDialog(false);
        }}
      ></ExpandDataDialog>
    </>
  );
};

VariableSelector.propTypes = {
  nodes: PropTypes.array,
  isVariableSelectorOpen: PropTypes.bool,
  anchorEl: PropTypes.any,
  onVariableSelected: PropTypes.func,
  handleClose: PropTypes.func,
};

export default VariableSelector;
