import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Modal from "../../modals/Modal";
import Input from "../../inputs/Input";
import Button from "../../buttons/Button";
import Text from "../../general/Text";
import {
  createBackupInstanceAsync,
  getInstanceAsync,
  handleInstanceHideModal,
  selectInstance,
  selectInstanceLoading,
  selectShowModal,
} from "../../../store/instances/instanceSlice";
import { getInstancesAsync } from "../../../store/instances/instancesSlice";
import { ReactComponent as ErrorIcon } from "./../../../assets/icons/logs/error.svg";
import Alert from "../../general/Alert";
import { selectSelectedInstancesVolumeSnapshot } from "../../../store/instances/instancesVolumeSlice";

const formatDateTimeInstanceBackUp = (date: any) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

const InstancesBackupModal = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [combinedValue, setCombinedValue] = useState("");
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectShowModal);
  const loading = useAppSelector(selectInstanceLoading);
  const instance = useAppSelector(selectInstance);
  const snapshot = useAppSelector(selectSelectedInstancesVolumeSnapshot);

  const { handleSubmit, reset, watch, setValue } = useForm({
    shouldUnregister: true,
    reValidateMode: "onChange",
  });

  const instanceName = watch("instanceName");

  useEffect(() => {
    if (isOpen && instance) {
      setValue("instanceName", instance.name);
    }
  }, [isOpen, instance, setValue]);

  const onSubmit = (data: any) => {
    if (instance) {
      const formData = {
        snapshotName: combinedValue,
      };
      dispatch(
        createBackupInstanceAsync({ instanceId: instance?.id, data: formData })
      ).then((action) => {
        if (action.type === "instance/backup/fulfilled") {
          dispatch(getInstancesAsync({ withoutLoading: false }));
          dispatch(getInstanceAsync({ instanceId: instance.id }));
          dispatch(handleInstanceHideModal());
        }
      });
    }
  };

  const handleClose = () => {
    reset();
    dispatch(handleInstanceHideModal());
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setCombinedValue(
      `${instanceName || ""} ${formatDateTimeInstanceBackUp(currentTime)}`
    );
  }, [instanceName, currentTime]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      contentClassName="max-w-lg"
      //containerClassName="!items-start"
      title="Creating a backup"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5">
          {/* <Input
            inputProps={{
              value: combinedValue,
              readOnly: true,
            }}
            label="Name and Current Date and Time"
          /> */}
          <Text className="text-sm mb-5 mt-1">
            Price: €0.01 ex. VAT/month/GB
          </Text>

          <Alert
            type="warning"
            className="text-xs mb-3"
            icon={<ErrorIcon className="w-5 mr-1 aspect-square" />}
          >
            Your instance will be suspended for a few seconds, and the disk’s
            read/write performance may be impacted until the backup is complet.
          </Alert>
        </div>

        <div className="mt-4 flex justify-end gap-2">
          <Button
            type="light"
            buttonProps={{
              onClick: handleClose,
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            buttonProps={{
              type: "submit",
            }}
            loading={loading}
          >
            Confirm
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default InstancesBackupModal;
