import { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";

import {
  selectWebApp,
  selectWebAppLoading,
} from "../../../store/web-app/webAppSlice";
import Button from "../../buttons/Button";
import { ReactComponent as ArrowsPointingInIcon } from "./../../../assets/icons/arrows-pointing-in.svg";
import { ReactComponent as ArrowsPointingOutIcon } from "./../../../assets/icons/arrows-pointing-out.svg";
import classNames from "classnames";
import { LoadingSpin } from "../../general/Loading";

import { Terminal } from "@xterm/xterm";
import { FitAddon } from "@xterm/addon-fit";
import "@xterm/xterm/css/xterm.css";

const WebAppShellTab = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);

  // const logs = useAppSelector(selectWebAppLogs);
  // const logsLoading = useAppSelector(selectWebAppLogsLoading);

  const terminalRef = useRef<HTMLDivElement>(null);
  const [basePrompt, setBasePrompt] = useState("$ansar# ");

  const dispatch = useAppDispatch();

  //states
  const [maximize, setMaximize] = useState<boolean>(false);

  useEffect(() => {
    const terminal = new Terminal({
      theme: {
        background: "#21232e",
      },
    });
    const fitAddon = new FitAddon();
    terminal.loadAddon(fitAddon);

    if (terminalRef.current) {
      terminal.open(terminalRef.current);
      fitAddon.fit();
    }

    const handleKey = (e: { key: string; domEvent: KeyboardEvent }) => {
      if (!terminal) return;

      const printable =
        !e.domEvent.altKey && !e.domEvent.ctrlKey && !e.domEvent.metaKey;

      if (e.domEvent.key === "Enter") {
        terminal.write(`\r\n${basePrompt}`);
      } else if (e.domEvent.key === "Backspace") {
        const cursorPosition = terminal.buffer.active.cursorX;
        if (cursorPosition > basePrompt.length) {
          terminal.write("\b \b");
        }
      } else if (printable) {
        terminal.write(e.key);
      }
    };

    terminal.write(`\r\n${basePrompt}`);
    terminal.onKey(handleKey);

    return () => {
      if (terminal) {
        terminal.dispose();
      }
    };
  }, [basePrompt]);

  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-end">
        {!maximize && (
          <div className="max-lg:order-4 ml-2">
            <Button buttonProps={{ onClick: () => setMaximize(true) }}>
              <ArrowsPointingOutIcon className="w-4 aspect-square" />
              Maximize
            </Button>
          </div>
        )}
      </div>
      <div
        className={classNames(
          "flex flex-col w-full bg-gray-100 dark:bg-dark-3",
          {
            "relative rounded-tl-xl rounded-tr-xl rounded-xl pb-1": !maximize,
            "z-[2147483004] fixed top-0 left-0 h-screen overflow-y-auto bg-[#21232e]":
              maximize,
          }
        )}
      >
        {/* header */}
        {maximize && (
          <header className="py-6 px-10">
            <div className="flex items-center justify-end">
              <Button buttonProps={{ onClick: () => setMaximize(false) }}>
                <ArrowsPointingInIcon className="w-4 aspect-square" />
                Minimize
              </Button>
            </div>
          </header>
        )}
        <div className="flex-1 flex flex-col">
          {/* <XTerm ref={xtermRef} /> */}
          <div ref={terminalRef} className="overflow-y-scroll" />
          {/* loading */}
          {false && (
            <div className="h-full flex items-center justify-center px-4">
              <LoadingSpin borderSize={2} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WebAppShellTab;
