import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./../../../../hooks";

import {
  getWebAppEnvironmentsAsync,
  getWebAppSecretFilesAsync,
  saveWebAppEnvironmentsAsync,
  saveWebAppSecretFilesAsync,
  selectWebApp,
  selectWebAppEnvironments,
  selectWebAppEnvironmentsActionLoading,
  selectWebAppEnvironmentsLoading,
  selectWebAppLoading,
  selectWebAppSecretFiles,
  selectWebAppSecretFilesActionLoading,
  selectWebAppSecretFilesLoading,
} from "../../../../store/web-app/webAppSlice";
import { useFieldArray, useForm } from "react-hook-form";
import Input from "../../../inputs/Input";
import Button from "../../../buttons/Button";
import { AnimatePresence, motion } from "framer-motion";
import { uuid } from "../../../../utils/uuid";
import { ReactComponent as ArchiveIcon } from "./../../../../assets/icons/archive-box.svg";
import { ReactComponent as PlusIcon } from "./../../../../assets/icons/plus.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  WebAppEnvironmentsSchema,
  WebAppSecretFilesSchema,
} from "../../../../utils/validations";
import Card from "../../../general/Card";
import ImportFromEnvFileModal from "../../../settings/env-variables/ImportFromEnvFileModal";
import { handleShowImportFromEnvFileModal } from "../../../../store/settings/env-variables/envVariablesSlice";
import Text from "../../../general/Text";
import { SecretFileModal } from "../../create-page-steps/CreateFormStep";
import { WebAppSecretFile } from "../../../../types/web-app";
import { Select2 } from "../../../inputs/Select";
import Loading from "../../../general/Loading";

const WebAppEnvironmentTab = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);

  const environments = useAppSelector(selectWebAppEnvironments);
  const environmentsLoading = useAppSelector(selectWebAppEnvironmentsLoading);
  const environmentsActionLoading = useAppSelector(
    selectWebAppEnvironmentsActionLoading
  );

  const dispatch = useAppDispatch();

  const {
    register,
    formState: { errors },
    control,
    setValue,
    getValues,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(WebAppEnvironmentsSchema()),
  });

  const {
    fields: ENVsFields,
    append: ENVappend,
    remove: ENVremove,
    update: ENVupdate,
  } = useFieldArray({
    control,
    name: "ENVs",
  });

  useEffect(() => {
    if (webApp) {
      dispatch(getWebAppEnvironmentsAsync({ webAppId: webApp.Id.toString() }));
    }
  }, [dispatch, webApp]);

  useEffect(() => {
    if (environments) {
      setValue("ENVs", environments);
    }
  }, [environments, setValue]);

  const handleGenerateEnvVal = (index: number) => {
    const randomId = uuid(20);
    setValue(`ENVs.${index}.Value`, randomId, {
      shouldDirty: true,
      shouldTouch: true,
    });
    ENVupdate(index, { Value: randomId });
  };

  const handleSubmitForm = (data: any) => {
    if (webApp)
      dispatch(
        saveWebAppEnvironmentsAsync({
          webAppId: webApp.Id.toString(),
          envs: data.ENVs,
        })
      );
  };

  return (
    <div className="flex flex-col gap-6">
      <Card
        title="Environment Variables"
        description="Set environment-specific config and secrets (such as API keys), then read those values from your code"
      >
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="flex flex-col w-full mt-3 mb-1">
            {environmentsLoading && (
              <Loading borderSize={2} className="min-h-[200px]" />
            )}
            {!environmentsLoading && (
              <>
                {ENVsFields.map((_, index) => (
                  <div key={index} className="grid gap-3 grid-cols-12 pb-2">
                    <div className="col-span-4">
                      <Input
                        label=""
                        inputProps={{
                          ...register(`ENVs.${index}.Key` as const),
                        }}
                        error={
                          errors.ENVs &&
                          errors.ENVs[index] &&
                          errors.ENVs[index]?.Key &&
                          errors.ENVs[index]?.Key?.message
                        }
                        placeholder="Key"
                      />
                    </div>
                    <div className="col-span-8">
                      <div className="flex items-center gap-2">
                        <div className="flex-1">
                          <Input
                            label=""
                            inputProps={{
                              ...register(`ENVs.${index}.Value` as const),
                            }}
                            error={
                              errors.ENVs &&
                              errors.ENVs[index] &&
                              errors.ENVs[index]?.Value &&
                              errors.ENVs[index]?.Value?.message
                            }
                            placeholder="Value"
                            className="flex-1"
                          />
                        </div>
                        {!getValues(`ENVs.${index}.Value`) && (
                          <Button
                            buttonProps={{
                              onClick: (e) => {
                                e.preventDefault();
                                handleGenerateEnvVal(index);
                              },
                            }}
                          >
                            generate
                          </Button>
                        )}
                        <Button
                          type="icon"
                          buttonProps={{
                            onClick: (e) => {
                              e.preventDefault();
                              ENVremove(index);
                            },
                            disabled: false,
                          }}
                          // buttonClassName="mt-1.5"
                        >
                          <ArchiveIcon className="w-5 text-slate-700 dark:text-slate-300 hover:text-red-500 hover:dark:text-red-400" />
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
                <AnimatePresence>
                  {errors.ENVs &&
                    typeof errors.ENVs.root?.message === "string" && (
                      <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                      >
                        <p className="mt-2 text-xs text-red-600 dark:text-red-500">
                          {errors.ENVs.root.message}
                        </p>
                      </motion.div>
                    )}
                </AnimatePresence>
              </>
            )}

            <div className="flex items-center justify-between mt-3">
              <Button buttonProps={{ disabled: true }}>
                Create Environment Group
              </Button>
              <div className="flex justify-start gap-2">
                <Button
                  type="light"
                  buttonProps={{
                    onClick: (e) => {
                      e.preventDefault();
                      ENVappend({
                        Key: "",
                        Value: "",
                      });
                    },
                  }}
                >
                  <PlusIcon className="w-4" />
                  Add Environment Variable
                </Button>
                <Button
                  type="light"
                  buttonProps={{
                    onClick: (e) => {
                      e.preventDefault();
                      dispatch(handleShowImportFromEnvFileModal());
                    },
                  }}
                >
                  Add from .env
                </Button>
                {ENVsFields.length > 0 && (
                  <Button
                    type="primary"
                    buttonProps={{
                      type: "submit",
                      disabled: environmentsLoading,
                    }}
                    loading={environmentsActionLoading}
                  >
                    Save Changes
                  </Button>
                )}
              </div>
            </div>
            <ImportFromEnvFileModal callback={ENVappend} />
          </div>
        </form>
      </Card>
      <SecretFiles />
      <LinkedEnvironmentGroups />
    </div>
  );
};

const SecretFiles = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);

  const secretFiles = useAppSelector(selectWebAppSecretFiles);
  const secretFilesLoading = useAppSelector(selectWebAppSecretFilesLoading);
  const secretFilesActionLoading = useAppSelector(
    selectWebAppSecretFilesActionLoading
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (webApp) {
      dispatch(getWebAppSecretFilesAsync({ webAppId: webApp.Id.toString() }));
    }
  }, [dispatch, webApp]);

  const [secretFileModal, setSecretFileModal] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<
    (WebAppSecretFile & { i: number }) | undefined
  >(undefined);

  const {
    register,
    formState: { errors },
    control,
    setValue,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(WebAppSecretFilesSchema()),
  });

  const {
    fields: secretFileFields,
    append,
    update,
    remove,
  } = useFieldArray({
    control,
    name: "SecretFiles",
  });

  useEffect(() => {
    if (secretFiles) {
      setValue("SecretFiles", secretFiles);
    }
  }, [secretFiles, setValue]);

  const handleSubmitForm = (data: any) => {
    console.log(data);
    if (webApp)
      dispatch(
        saveWebAppSecretFilesAsync({
          webAppId: webApp.Id.toString(),
          secretFiles: data.SecretFiles,
        })
      );
  };

  return (
    <Card
      title="Secret Files"
      description={
        <div className="col-span-1 text-sm font-normal font-sans mt-2">
          <Text>
            Store plaintext files containing secret data (such as a
            <span className="text-primary-300 ml-1">.env</span> file or a
            private key)
          </Text>
          <Text className="mt-2">
            Read these files during builds by absolute path at{" "}
            <span className="text-primary-300 ml-1">{`/etc/secrets/<filename>`}</span>
            .
          </Text>
        </div>
      }
    >
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        {secretFilesLoading && (
          <Loading borderSize={2} className="min-h-[200px]" />
        )}
        {!secretFilesLoading &&
          secretFileFields.map((secretFile, index) => (
            <div key={index} className="grid gap-3 grid-cols-12 pb-2">
              <div className="col-span-4">
                <Input
                  label=""
                  inputProps={{
                    ...register(`SecretFiles.${index}.Filename` as const),
                    readOnly: true,
                    onClick: () => {
                      setSelectedFile({ ...secretFile, i: index });
                      setSecretFileModal(true);
                    },
                  }}
                  error={
                    errors.SecretFiles &&
                    errors.SecretFiles[index] &&
                    errors.SecretFiles[index]?.Filename &&
                    errors.SecretFiles[index]?.Filename?.message
                  }
                  placeholder="file name"
                />
              </div>
              <div className="col-span-8">
                <div className="flex items-center gap-2">
                  <div className="flex-1">
                    <Input
                      label=""
                      inputProps={{
                        ...register(
                          `SecretFiles.${index}.FileContents` as const
                        ),
                        readOnly: true,
                        type: "password",
                        onClick: () => {
                          setSelectedFile({ ...secretFile, i: index });
                          setSecretFileModal(true);
                        },
                      }}
                      error={
                        errors.SecretFiles &&
                        errors.SecretFiles[index] &&
                        errors.SecretFiles[index]?.FileContents &&
                        errors.SecretFiles[index]?.FileContents?.message
                      }
                      placeholder="file contents"
                      className="flex-1"
                    />
                  </div>
                  <Button
                    type="icon"
                    buttonProps={{
                      onClick: (e) => {
                        e.preventDefault();
                        remove(index);
                      },
                      disabled: false,
                    }}
                  >
                    <ArchiveIcon className="w-5 text-slate-700 dark:text-slate-300 hover:text-red-500 hover:dark:text-red-400" />
                  </Button>
                </div>
              </div>
            </div>
          ))}
        <div className="flex items-center justify-between gap-2 mt-3">
          <Button
            type="light"
            buttonProps={{
              onClick: (e) => {
                e.preventDefault();
                setSecretFileModal(true);
              },
            }}
          >
            <PlusIcon className="w-4" />
            Add Secret File
          </Button>
          {secretFileFields.length > 0 && (
            <Button
              type="primary"
              buttonProps={{
                type: "submit",
                disabled: secretFilesLoading,
              }}
              loading={secretFilesActionLoading}
            >
              Save Changes
            </Button>
          )}
        </div>
      </form>
      <SecretFileModal
        isOpen={secretFileModal}
        onClose={() => {
          setSecretFileModal(false);
          setSelectedFile(undefined);
        }}
        callback={(data: any) => {
          if (selectedFile) {
            setValue(`SecretFiles.${selectedFile.i}`, data, {
              shouldDirty: true,
              shouldTouch: true,
              shouldValidate: true,
            });
            update(selectedFile.i, data);
          } else {
            append(data);
          }
        }}
        selectedFile={selectedFile}
      />
    </Card>
  );
};

const LinkedEnvironmentGroups = () => {
  const [selectedGroup, setSelectedGroup] = useState(undefined);
  return (
    <Card title="Linked Environment Groups">
      <div className="grid grid-cols-5 gap-x-10 my-6">
        <div className="col-span-2 text-sm font-normal font-sans">
          <Text className="font-medium">Link Environment Group</Text>
          <Text className="mt-2">
            Environment groups are collections of environment variables and
            secret files that you can share across multiple services.
          </Text>
        </div>
        <div className="col-span-3">
          <div className="flex items-center gap-4">
            <Select2
              emptyString="Select an environment group..."
              options={[]}
              value={selectedGroup}
              className="flex-1"
            />
            <Button buttonProps={{ disabled: true }}>Link</Button>
          </div>
        </div>
      </div>
    </Card>
  );
};
export default WebAppEnvironmentTab;
