import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import React, { useEffect, useMemo, useState } from "react";
import Button from "../../../components/buttons/Button";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";

import { useNavigate } from "react-router-dom";
import {
  getDBSRolesAsync,
  selectDBSLoadsLoading,
  selectDBSRoles,
} from "../../../store/database/servicesSlice";
import {
  createDBSUserAsync,
  getDBSUsersAsync,
  selectDatabaseService,
  selectDatabaseServiceUserLoading,
  selectDatabaseServiceUsers,
  selectDatabaseServiceUsersLoading,
} from "../../../store/database/serviceSlice";
import Card from "../../general/Card";
import Text from "../../../components/general/Text";
import Input from "../../inputs/Input";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DBSUserSchema } from "../../../utils/validations";
import { Select2 } from "../../inputs/Select";
import { DatabaseUsersUrl } from "../../../utils/urls";
import { ReactComponent as RightArrow } from "./../../../assets/icons/arrow-up.svg";

//for redis user inputs
interface Inputs {
  keys?: string[];
  categories?: string[];
  commands?: string[];
  channels?: string[];
}

const UserCreateTab = () => {
  const [rows, setRows] = useState<
    { id: number; selectedRoles: string | null }[]
  >([{ id: 1, selectedRoles: null }]);
  const [rowsMongo, setRowsMongo] = useState<
    { id: number; selectedRoles: string | null; dbInputValue: string }[]
  >([{ id: 1, selectedRoles: null, dbInputValue: "" }]);

  const service = useAppSelector(selectDatabaseService);
  const roles = useAppSelector(selectDBSRoles);
  const rolesLoading = useAppSelector(selectDBSLoadsLoading);
  const users = useAppSelector(selectDatabaseServiceUsers);
  const usersLoading = useAppSelector(selectDatabaseServiceUsersLoading);
  const userLoading = useAppSelector(selectDatabaseServiceUserLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userRoles = useMemo(() => {
    if (!users || users.length === 0) return [];

    // Extract initial roles, ensuring user.roles exists
    const initialRoles = users
      .map((user) => user.roles || []) // Default to an empty array if user.roles is undefined or null
      .flat()
      .map((role) => role.split("@")[0].trim());

    // Extract roles from rowsMongo
    const mongoRoles = rowsMongo
      .filter((row) => row.selectedRoles !== null)
      .map(
        (row) =>
          (row.selectedRoles && row.selectedRoles.split("@")[0].trim()) || null
      )
      .filter(Boolean); // Remove null values

    // Combine and remove duplicates
    const combinedRoles = [...initialRoles, ...mongoRoles];
    return Array.from(new Set(combinedRoles));
  }, [users, rowsMongo]);

  useEffect(() => {
    if (service && users[0]?.roles) {
      dispatch(getDBSRolesAsync({ id: service.id, engine: service.engine }));
    }
  }, [dispatch, service, users]);

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(DBSUserSchema),
  });

  const onSubmit = (data: any) => {
    // console.log("userRoles:", userRoles);

    //postgressql service
    const selectedRolesArray: string[] = rows
      .map((row) => row.selectedRoles)
      .filter(
        (role): role is string =>
          typeof role === "string" && userRoles.includes(role)
      );
    // Map and filter roles for mongodb service
    const selectedRolesArrayMongo =
      users[0]?.roles &&
      rowsMongo
        .map((row) => {
          if (row.selectedRoles) {
            if (row.selectedRoles.includes("@admin")) {
              // Keep roles with "@admin" as is
              return row.selectedRoles;
            } else if (row.selectedRoles.includes("@(defined db)")) {
              // Replace @(defined db) with the user input value
              const rolePrefix = row.selectedRoles.split("@(defined db)")[0];
              const updatedRole = `${rolePrefix}@${row.dbInputValue}`;
              return updatedRole;
            }
          }
          return null;
        })
        .filter((role): role is string => {
          if (role === null) return false;
          // Normalize the role for comparison
          const normalizedRole = role.split("@")[0].trim(); // Remove any suffix after "@"
          const isIncluded = userRoles.includes(normalizedRole);
          // console.log(
          //   `Checking if role "${normalizedRole}" is included: ${isIncluded}`
          // );
          return isIncluded;
        });

    // Debugging: Check the resulting roles array

    const formdataWithRoles = {
      name: data.name,
      roles:
        service?.engine === "mongodb"
          ? selectedRolesArrayMongo
          : selectedRolesArray,
    };

    const formdataWithGroup = { name: data.name, group: data.group };
    const formData = { name: data.name };

    const formDataForRedis = {
      name: data.name,
      categories: inputs.categories,
      channels: inputs.channels,
      commands: inputs.commands,
      keys: inputs.keys,
    };
    const payload =
      service?.engine === "redis"
        ? formDataForRedis //TODO
        : users[0]?.roles
        ? formdataWithRoles
        : data.group
        ? formdataWithGroup
        : formData;

    if (service) {
      dispatch(
        createDBSUserAsync({
          engine: service.engine,
          id: service.id,
          data: payload,
        })
      ).then((action) => {
        if (action.type === "service/user/create/fulfilled") {
          reset();
          dispatch(
            getDBSUsersAsync({ id: service.id, engine: service.engine })
          );
          navigate(DatabaseUsersUrl(service.id));
        }
      });
    }
  };

  const getAvailableRoles = (currentIndex: number) => {
    const selectedRoles = rows
      .map((r, index) => (index === currentIndex ? null : r.selectedRoles))
      .filter((r): r is string => r !== null);
    return roles.filter((role) => !selectedRoles.includes(role));
  };

  //Mongodb user
  const handleAddRowMongo = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const lastRow = rowsMongo[rowsMongo.length - 1];
    if (lastRow.selectedRoles !== null) {
      const newRow = {
        id: rowsMongo.length + 1,
        selectedRoles: null,
        dbInputValue: "",
      };
      setRowsMongo([...rowsMongo, newRow]);
    }
  };

  const handleDeleteRowMongo = (
    e: React.MouseEvent<HTMLButtonElement>,
    indexToRemove: number
  ) => {
    e.stopPropagation();
    e.preventDefault();
    if (indexToRemove === 0) {
      const updatedRows = [...rowsMongo];
      updatedRows[indexToRemove].selectedRoles = null;
      setRowsMongo(updatedRows);
    } else {
      const updatedRows = rowsMongo.filter(
        (_, index) => index !== indexToRemove
      );
      setRowsMongo(updatedRows);
    }
  };
  const handleRoleChange = (v: string | null | undefined, index: number) => {
    const updatedRows = [...rowsMongo];
    updatedRows[index].selectedRoles = v || null;

    // Automatically set input value to "admin" if role includes "@admin"
    if (v && v.includes("@admin")) {
      updatedRows[index].dbInputValue = "admin";
    } else {
      updatedRows[index].dbInputValue = ""; // Reset to allow user input
    }

    setRowsMongo(updatedRows);
  };

  const handleDbInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const updatedRows = [...rowsMongo];
    updatedRows[index].dbInputValue = e.target.value;
    setRowsMongo(updatedRows);
  };

  //postgre user
  const handleAddRow = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const lastRow = rows[rows.length - 1];
    if (lastRow.selectedRoles !== null) {
      const newRow = { id: rows.length + 1, selectedRoles: null };
      setRows([...rows, newRow]);
    }
  };

  const handleDeleteRow = (
    e: React.MouseEvent<HTMLButtonElement>,
    indexToRemove: number
  ) => {
    e.stopPropagation();
    e.preventDefault();
    if (indexToRemove === 0) {
      const updatedRows = [...rows];
      updatedRows[indexToRemove].selectedRoles = null;
      setRows(updatedRows);
    } else {
      const updatedRows = rows.filter((_, index) => index !== indexToRemove);
      setRows(updatedRows);
    }
  };

  //Redis user TODO
  const [inputs, setInputs] = useState<Inputs>({
    keys: [],
    categories: [],
    commands: [],
    channels: [],
  });

  const [newInput, setNewInput] = useState({
    keys: "",
    categories: "",
    commands: "",
    channels: "",
  });
  const addInput = (
    type: keyof Omit<Inputs, "name">,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    if (newInput[type]) {
      setInputs((prev) => ({
        ...prev,
        [type]: [...(prev[type] as string[]), newInput[type]],
      }));
      setNewInput((prev) => ({
        ...prev,
        [type]: "",
      }));
    }
  };

  const removeInput = (
    type: keyof Omit<Inputs, "name">,
    index: number,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setInputs((prev) => ({
      ...prev,
      [type]: (prev[type] as string[]).filter((_, i) => i !== index),
    }));
  };

  const handleNewInputChange = (
    type: keyof Omit<Inputs, "name">,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.stopPropagation();
    setNewInput((prev) => ({
      ...prev,
      [type]: e.target.value,
    }));
  };

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Database</title>
        <meta name="description" content="" />
      </Helmet>

      <div>
        <div className="flex items-center justify-between">
          <div className="w-full flex flex-col gap-5">
            {service && (
              <div
                className="group px-1 items-center flex flex-1 transition-all duration-150 cursor-pointer gap-x-0.5"
                onClick={() => navigate(DatabaseUsersUrl(service.id))}
              >
                <RightArrow className="-rotate-90 w-4 h-4 transition-all duration-500 text-primary-500 group-hover:translate-x-[-4px]" />
                <Text type="title" className="text-sm !text-primary-500">
                  Back to “Users & Roles”
                </Text>
              </div>
            )}

            <Card title="Add user">
              <div className="flex flex-col gap-4">
                <form onSubmit={handleSubmit(onSubmit)} className="md:w-6/12">
                  <div className="w-32">
                    <Text className="text-sm font-medium">Username</Text>
                  </div>
                  <Input
                    inputProps={{ ...register("name") }}
                    error={errors.name?.message}
                  />

                  {/* Service user inclode roles*/}
                  {users[0]?.roles && (
                    <>
                      <div className="w-32 mt-8 mb-5">
                        <Text className="font-medium">Edit roles</Text>
                      </div>
                      {service?.engine === "postgresql" &&
                        rows.map((row, index) => {
                          const availableRoles = getAvailableRoles(index);

                          return (
                            <div
                              key={row.id}
                              className="grid gap-3 grid-cols-12 pb-2"
                            >
                              <div className="col-span-9 mr-1">
                                <Select2
                                  loading={rolesLoading}
                                  label="role"
                                  value={row.selectedRoles ?? undefined}
                                  onChange={(v) => {
                                    const updatedRows = [...rows];
                                    updatedRows[index].selectedRoles =
                                      v || null;
                                    setRows(updatedRows);
                                  }}
                                  options={availableRoles.map((role) => ({
                                    label: role.split("@")[0],
                                    value: role,
                                  }))}
                                  className=""
                                />
                              </div>
                              <div className="col-span-3 flex items-start justify-center mt-5">
                                <Button
                                  type="icon"
                                  buttonProps={{
                                    onClick: (e) => handleDeleteRow(e, index),
                                    disabled: false,
                                  }}
                                  buttonClassName="mt-1.5"
                                >
                                  <ArchiveIcon className="w-5 text-slate-700 dark:text-slate-300 hover:text-red-500 hover:dark:text-red-400" />
                                </Button>
                                <Button
                                  type="light"
                                  buttonProps={{
                                    onClick: handleAddRow,
                                    disabled: availableRoles.length === 1,
                                  }}
                                  buttonClassName="mt-1.5"
                                  loading={usersLoading}
                                >
                                  <PlusIcon className="w-4" />
                                </Button>
                              </div>
                            </div>
                          );
                        })}

                      {service?.engine === "mongodb" &&
                        rowsMongo.map((rowMongo, index) => {
                          const availableRoles = getAvailableRoles(index);

                          return (
                            <div
                              key={rowMongo.id}
                              className="grid gap-3 grid-cols-12 pb-2"
                            >
                              <div className="col-span-5 mr-1">
                                <Select2
                                  loading={rolesLoading}
                                  label="role"
                                  value={rowMongo.selectedRoles ?? undefined}
                                  onChange={(v) => handleRoleChange(v, index)}
                                  options={availableRoles.map((role) => ({
                                    label: role.split("@")[0],
                                    value: role,
                                  }))}
                                  className=""
                                />
                              </div>
                              <div className="col-span-5">
                                <Input
                                  label="Database"
                                  inputProps={{
                                    type: "text",
                                    value: rowMongo.dbInputValue,
                                    onChange: (e) =>
                                      handleDbInputChange(
                                        e as React.ChangeEvent<HTMLInputElement>,
                                        index
                                      ),
                                    disabled:
                                      rowMongo.selectedRoles?.includes(
                                        "@admin"
                                      ) || false, // Disable input if role includes "@admin"
                                  }}
                                  className=""
                                />
                              </div>
                              <div className="col-span-2 flex items-start justify-center mt-5">
                                <Button
                                  type="icon"
                                  buttonProps={{
                                    onClick: (e) =>
                                      handleDeleteRowMongo(e, index),
                                    disabled: false,
                                  }}
                                  buttonClassName="mt-1.5"
                                >
                                  <ArchiveIcon className="w-5 text-slate-700 dark:text-slate-300 hover:text-red-500 hover:dark:text-red-400" />
                                </Button>
                                <Button
                                  type="light"
                                  buttonProps={{
                                    onClick: handleAddRowMongo,
                                    disabled: availableRoles.length === 1,
                                  }}
                                  buttonClassName="mt-1.5"
                                  loading={usersLoading}
                                >
                                  <PlusIcon className="w-4" />
                                </Button>
                              </div>
                            </div>
                          );
                        })}
                      <div className="mt-1"></div>
                    </>
                  )}
                  {/* M3db service */}
                  {service?.engine === "m3db" && (
                    <div className="mt-5">
                      <div className="col-span-10 mt-5">
                        <Text className="text-sm font-medium">Group</Text>
                      </div>
                      <Input
                        inputProps={{ ...register("group") }}
                        error={errors.group?.message}
                      />
                    </div>
                  )}

                  {/* Redis service */}
                  {service?.engine === "redis" && (
                    <>
                      {(
                        [
                          "keys",
                          "categories",
                          "commands",
                          "channels",
                        ] as (keyof Omit<Inputs, "name">)[]
                      ).map((type) => (
                        <div key={type} className="mt-10">
                          <div className="col-span-10 mt-5">
                            <Text className="text-sm font-medium">
                              {type.charAt(0).toUpperCase() + type.slice(1)}
                            </Text>
                          </div>
                          <div className="flex relative oui-input-group ">
                            <Input
                              inputProps={{
                                value: newInput[type],
                                placeholder: `Add a ${type.slice(0, -1)}`,
                                onChange: (e) =>
                                  handleNewInputChange(
                                    type,
                                    e as React.ChangeEvent<HTMLInputElement>
                                  ),
                              }}
                              error={errors[type]?.message}
                            />
                            <Button
                              buttonClassName="ml-3"
                              buttonProps={{
                                onClick: (e) => {
                                  e.preventDefault();
                                  addInput(type, e);
                                },
                                className:
                                  "absolute top-1 right-1 text-xs h-5 px-1", // Adjust as needed
                              }}
                              type="default"
                            >
                              Add{" "}
                              {/* {type.charAt(0).toUpperCase() +
                                type.slice(1).slice(0, -1)} */}
                            </Button>
                          </div>
                          <div className="flex  gap-2 mt-2">
                            {(inputs[type] as string[]).map((value, index) => (
                              <div
                                key={index}
                                className="relative flex items-center gap-2 bg-gray-100 p-2 rounded-lg fit-content"
                              >
                                <div className="flex-1">{value}</div>

                                <Button
                                  buttonProps={{
                                    onClick: (e) => {
                                      e.stopPropagation();
                                      removeInput(type, index, e);
                                    },
                                  }}
                                  type="default"
                                >
                                  X
                                </Button>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                  <div className="mt-4 flex gap-5">
                    <Button type="primary" loading={userLoading}>
                      Create User
                    </Button>
                  </div>
                </form>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserCreateTab;
