import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Modal from "../../modals/Modal";
import Button from "../../buttons/Button";
import Text from "../../general/Text";
import {
  getInstanceAsync,
  handleInstanceHotHideModal,
  rebootInstanceAsync,
  selectInstance,
  selectInstanceLoading,
  selectShowHotModal,
} from "../../../store/instances/instanceSlice";
import { getInstancesAsync } from "../../../store/instances/instancesSlice";

const InstancesHotRebootModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectShowHotModal);
  const loading = useAppSelector(selectInstanceLoading);
  const instance = useAppSelector(selectInstance);

  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({
    shouldUnregister: true,
    reValidateMode: "onChange",
  });

  const onSubmit = (data: any) => {
    if (instance) {
      const formData = {
        type: "soft",
      };
      dispatch(
        rebootInstanceAsync({ instanceId: instance?.id, data: formData })
      ).then((action) => {
        if (action.type === "instance/reboot/fulfilled") {
          dispatch(getInstancesAsync({ withoutLoading: false }));
          dispatch(getInstanceAsync({ instanceId: instance.id }));
          dispatch(handleInstanceHotHideModal());
        }
      });
    }
  };

  const handleClose = () => {
    reset();
    dispatch(handleInstanceHotHideModal());
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      contentClassName="max-w-lg"
     // containerClassName="!items-start"
      title="Hot reboot (soft)"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5">
          <Text className="text-sm mb-5">
            You are about to reboot your {instance?.name} instance.
          </Text>
        </div>

        <div className="mt-4 flex justify-end gap-2">
          <Button
            type="light"
            buttonProps={{
              onClick: handleClose,
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            buttonProps={{
              disabled: !isValid,
              type: "submit",
            }}
            loading={loading}
          >
            Confirm
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default InstancesHotRebootModal;
