import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getInstanceAsync,
  selectInstance,
  selectInstanceLoading,
} from "../../../store/instances/instanceSlice";
import Text from "../../general/Text";
import { getInstancesAsync } from "../../../store/instances/instancesSlice";
import { CopyableInput } from "../../general/CopyableKey";
import { InstancesUrl } from "../../../utils/urls";
import { useNavigate, useParams } from "react-router-dom";

const InstanceNetworksTab = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const instance = useAppSelector(selectInstance);
  const loading = useAppSelector(selectInstanceLoading);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getInstanceAsync({ instanceId: id })).then((action) => {
        if (action && action.type === "instance/rejected") {
          navigate(InstancesUrl);
        }
      });
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (!instance) {
      dispatch(getInstancesAsync({ withoutLoading: false }));
    }
  }, [dispatch, instance]);

  return (
    <div>
      {instance && (
        <div className="mt-5">
          <div className="mt-2 grid gap-4 md:grid-cols-2">
            {instance.ipAddresses.map((ip, index) => (
              <div key={index}>
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <CopyableInput
                        title={`IPv${ip.version}`}
                        value={ip.ip}
                        loading={loading}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <CopyableInput
                        title="Gateway"
                        value={ip.gatewayIp || "undefined"}
                        loading={loading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {/* <div className="p-2"> //TODO IF HAVE PRIVATE NETWORK
        <div className="flex justify-between flex-col md:flex-row">
          <div className="mb-4 md:mb-0 w-full">
            <Text type="title" className="text-sm mb-2">
              Private networks
            </Text>

            <div className=" flex gap-5 flex-row items-center">
              <Text className="text-sm">-</Text>

              <Button>
                <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
              </Button>
            </div>
          </div>
        </div>
      </div> */}
            <div className="p-2">
              <div className="flex justify-between flex-col md:flex-row">
                <div className="mb-4 md:mb-0 w-full">
                  <CopyableInput
                    title="Login information"
                    value={`ssh ${instance?.image?.user}@${instance.ipAddresses[0]?.ip}`}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
            <div className="p-2">
              <div className="flex justify-between flex-col md:flex-row">
                <div className="mb-4 md:mb-0 w-full">
                  <Text type="title" className="text-sm mb-2">
                    SSH Key
                  </Text>

                  <div className=" flex gap-5 flex-row items-center">
                    <Text className="text-sm">{instance.sshKey?.name}</Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InstanceNetworksTab;
