import { useAppSelector } from "./../../../hooks";
import Text from "./../../general/Text";
import { binarySize, humanizeSize } from "./../../../utils/file";
import {
  selectIpfsUsage,
  selectIpfsMetricsLoading,
} from "../../../store/ipfs/ipfsPublicSlice";
import { useMemo } from "react";

const IpfsReportedUsageWidget = () => {
  const usage = useAppSelector(selectIpfsUsage);
  const infoLoading = useAppSelector(selectIpfsMetricsLoading);

  const usageSpace = useMemo(() => {
    if (usage) {
      return humanizeSize(binarySize(usage, "K", 1024), {
        fractionDigits: 1,
      }).join("B");
    }
    return "0";
  }, [usage]);

  return (
    <div className="w-full col-span-1 rounded-lg border dark:border-dark-2 bg-white dark:bg-dark-3 p-5">
      <div className="flex flex-col w-full gap-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <Text className="text-sm">Reported Usage</Text>
          </div>
          <div className="flex flex-col gap-1">
            <div className="flex">
              <Text type="title" className="text-base" loading={infoLoading}>
                {usageSpace}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default IpfsReportedUsageWidget;
