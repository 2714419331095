import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createS3EventAsync,
  getS3EventsAsync,
  selectActionLoading,
  selectLoading,
  selectSelectedS3Event,
} from "../../../../store/s3/eventsSlice";
import Button from "../../../buttons/Button";
import { S3KafkaEventSchema } from "../../../../utils/validations";
import Input from "../../../inputs/Input";
import { useEffect, useState } from "react";
import Textarea from "../../../inputs/Textarea";
import Text from "../../../general/Text";
import Switcher from "../../../inputs/Switcher";
import { InfoTooltip } from "../../../general/Tooltip";
import { S3EventsUrl } from "../../../../utils/urls";
import { useNavigate } from "react-router-dom";
import useGetS3Events from "../../../../hooks/useGetS3Events";

const KafkaForm = () => {
  const event = useAppSelector(selectSelectedS3Event);
  const loading = useAppSelector(selectActionLoading);
  const eventsLoading = useAppSelector(selectLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const getS3Events = useGetS3Events();

  const [enableSASL, setEnableSASL] = useState<boolean>(false);
  const [enableTLS, setEnableTLS] = useState<boolean>(false);
  const [enableTlsSkip, setEnableTlsSkip] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(S3KafkaEventSchema),
  });

  useEffect(() => {
    if (event) {
    } else {
    }
  }, [event, setValue]);

  const onSubmit = (data: any) => {
    let keyValues = [
      {
        key: "brokers",
        value: data.brokers,
      },
      {
        key: "topic",
        value: data.topic,
      },
      {
        key: "sasl_username",
        value: data.sasl_username,
      },
      {
        key: "sasl_password",
        value: data.sasl_password,
      },
      {
        key: "sasl_mechanism",
        value: data.sasl_mechanism,
      },
      {
        key: "tls_client_auth",
        value: data.tls_client_auth,
      },
      {
        key: "sasl",
        value: enableSASL ? "on" : "off",
      },
      {
        key: "tls",
        value: enableTLS ? "on" : "off",
      },
      {
        key: "tls_skip_verify",
        value: enableTlsSkip ? "on" : "off",
      },
      {
        key: "client_tls_cert",
        value: data.client_tls_cert,
      },
      {
        key: "client_tls_key",
        value: data.client_tls_key,
      },
      {
        key: "version",
        value: data.version,
      },
      {
        key: "queue_dir",
        value: data.queue_dir,
      },
      {
        key: "comment",
        value: data.comment,
      },
    ];
    if (data.queue_limit !== null) {
      keyValues = [
        ...keyValues,
        {
          key: "queue_limit",
          value: data.queue_limit.toString(),
        },
      ];
    }
    const formData = {
      key_values: keyValues,
    };
    // if (event) {
    // dispatch(
    //   updateS3EventAsync({
    //     id: event.Id,
    //     data: {
    //       label: data.label,
    //     },
    //   })
    // ).then((action) => {
    //   if (action.type === "s3-events/update/fulfilled") {
    //     // handleClearComponent();
    //     dispatch(handleHideEditor());
    //     dispatch(getS3EventsAsync());
    //     reset();
    //   }
    // });
    // } else {
    dispatch(
      createS3EventAsync({
        event: "kafka",
        identifier: data.identifier,
        data: formData,
      })
    ).then((action) => {
      if (action.type === "s3-events/create/fulfilled") {
        getS3Events(() => {
          reset();
          navigate(S3EventsUrl);
        });
      }
    });
    // }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col w-full gap-5">
        <Input
          label="Identifier"
          tooltip="Unique descriptive string for this destination"
          placeholder="Enter Destination Identifier"
          inputProps={{ ...register("identifier") }}
          error={errors.identifier?.message}
          required
        />

        <Input
          label="Brokers"
          tooltip="Comma separated list of Kafka broker addresses"
          placeholder="Enter Brokers"
          inputProps={{ ...register("brokers") }}
          error={errors.brokers?.message}
        />

        <Input
          label="Topic"
          tooltip="Kafka topic used for bucket notifications"
          placeholder="Enter Topic"
          inputProps={{ ...register("topic") }}
          error={errors.topic?.message}
        />

        <Input
          label="SASL Username"
          tooltip="Username for SASL/PLAIN or SASL/SCRAM authentication"
          placeholder="Enter SASL Username"
          inputProps={{ ...register("sasl_username") }}
          error={errors.sasl_username?.message}
        />

        <Input
          label="SASL Password"
          tooltip="Password for SASL/PLAIN or SASL/SCRAM authentication"
          placeholder="Enter SASL Password"
          inputProps={{ ...register("sasl_password") }}
          error={errors.sasl_password?.message}
        />

        <Input
          label="SASL Mechanism"
          tooltip="SASL authentication mechanism, default 'PLAIN'"
          inputProps={{ ...register("sasl_mechanism") }}
          error={errors.sasl_mechanism?.message}
        />

        <Input
          label="TLS Client Auth"
          tooltip="Client Auth determines the Kafka server's policy for TLS client authorization"
          placeholder="Enter TLS Client Auth"
          inputProps={{ ...register("tls_client_auth") }}
          error={errors.tls_client_auth?.message}
        />

        <div className="flex items-center justify-between w-full">
          <div className="flex gap-1 items-center">
            <Text type="title" className="text-sm !font-medium">
              SASL
            </Text>
            <InfoTooltip content="Enable SASL Authentication" />
          </div>
          <Switcher on={enableSASL} onToggle={setEnableSASL} />
        </div>

        <div className="flex items-center justify-between w-full">
          <div className="flex gap-1 items-center">
            <Text type="title" className="text-sm !font-medium">
              TLS
            </Text>
            <InfoTooltip content="Enable TLS" />
          </div>
          <Switcher on={enableTLS} onToggle={setEnableTLS} />
        </div>

        <div className="flex items-center justify-between w-full">
          <div className="flex gap-1 items-center">
            <Text type="title" className="text-sm !font-medium">
              TLS skip verify
            </Text>
            <InfoTooltip content="Trust server TLS without verification" />
          </div>
          <Switcher on={enableTlsSkip} onToggle={setEnableTlsSkip} />
        </div>

        <Input
          label="client TLS cert"
          tooltip="Path to client certificate for mTLS authorization"
          placeholder="ِEnter TLS Client Cert"
          inputProps={{ ...register("client_tls_cert") }}
          error={errors.client_tls_cert?.message}
        />

        <Input
          label="client TLS key"
          tooltip="Path to client key for mTLS authorization"
          placeholder="ِEnter TLS Client Key"
          inputProps={{ ...register("client_tls_key") }}
          error={errors.client_tls_key?.message}
        />

        <Input
          label="Version"
          tooltip="Specify the version of the Kafka cluster e.g '2.2.0'"
          placeholder="ِEnter Kafka Version"
          inputProps={{ ...register("version") }}
          error={errors.version?.message}
        />

        <Input
          label="Queue Directory"
          tooltip="Staging directory for undelivered messages e.g. '/home/events'"
          placeholder="ِEnter Queue Directory"
          inputProps={{ ...register("queue_dir") }}
          error={errors.queue_dir?.message}
        />

        <Input
          label="Queue Limit"
          tooltip="Maximum limit for undelivered messages, defaults to '10000'"
          placeholder="ِEnter Queue Limit"
          inputProps={{ ...register("queue_limit"), type: "number" }}
          error={errors.queue_limit?.message}
        />

        <Textarea
          label="Comment"
          placeholder="Enter custom notes if any"
          textareaProps={{ ...register("comment") }}
          error={errors.comment?.message}
        />
      </div>
      <div className="mt-4 flex justify-end">
        <Button
          loading={loading || eventsLoading}
          type="primary"
          buttonProps={{
            disabled: loading || eventsLoading,
            type: "submit",
          }}
          buttonClassName="w-[160px]"
        >
          {event ? "Update Evet Target" : "Save Evet Target"}
        </Button>
      </div>
    </form>
  );
};
export default KafkaForm;
