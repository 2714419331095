import classNames from "classnames";
import { motion, AnimatePresence } from "framer-motion";

const Textarea = ({
  id,
  textareaProps,
  placeholder,
  textareaClassName,
  label,
  error,
  required,
  hint,
}: {
  id?: string;
  textareaProps?: React.HTMLProps<HTMLTextAreaElement>;
  textareaClassName?: string;
  placeholder?: string;
  label?: string;
  required?: boolean;
  error?: string;
  hint?: string;
}) => {
  return (
    <div className="flex flex-col">
      <div
        className={classNames("flex mb-1 items-center", {
          "justify-between": label,
          "justify-end": !label,
        })}
      >
        {label && (
          <label
            htmlFor={id}
            className={classNames(
              "block text-sm text-slate-800 dark:text-slate-50 whitespace-nowrap",
              {
                "text-red-700 dark:text-red-500": error,
              }
            )}
          >
            {label}
            {required && <span className="text-red-500 mx-1">*</span>}
          </label>
        )}
        {hint && <span className="text-xs text-slate-500">{hint}</span>}
      </div>
      <textarea
        id={id}
        {...textareaProps}
        className={classNames(
          textareaClassName,
          " text-sm rounded-lg block w-full p-2.5 bg-secondary-100 focus:ring-2 focus:ring-slate-200 focus:bg-slate-50  dark:bg-dark-2 dark:focus:ring-slate-600 outline-none",
          {
            " border-red-500 text-red-900 placeholder-red-700  focus:ring-red-500 focus:border-red-500 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500":
              error,
            "dark:border-zinc-600 dark:text-slate-50 dark:placeholder-gray-500 focus:ring-secondary-200 focus:border-blue-500  dark:focus:border-slate-600 ":
              !error,
          }
        )}
        placeholder={placeholder}
      />
      <AnimatePresence>
        {error && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
          >
            <p className="mt-2 text-xs text-red-600 dark:text-red-500">
              {error}
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Textarea;
