import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isAxiosError } from "axios";
import { IThunkRejectValue, RootState } from "../../../types";
import type { PayloadAction } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { ToastClasses } from "../../../components/modals/alerts";
import {
  CreateEnvVariableData,
  EnvVariablesStateType,
  EnvironmentVariable,
} from "../../../types/env-variables";
import {
  getEnvVariablesApi,
  deleteEnvVariableApi,
  createEnvVariableApi,
  updateEnvVariableApi,
  createMultipleEnvVariableApi,
} from "../../../apis/envVariablesAPI";
import { getExtractErrors } from "../../../apis";
import { CustomErrorToast } from "../../../components/general/Toast";

const dummyEnvVariable: EnvironmentVariable[] = [
  {
    Id: 1,
    Key: "key-1",
    Value: "test variable",
  },
];

const initialState: EnvVariablesStateType = {
  envVariables: [...dummyEnvVariable],
  loading: false,
  selectedEnvVariable: null,
  showEditorModal: false,
  actionLoading: false,
  showImportFromEnvFileModal: false,
};

export const getEnvVariablesAysnc = createAsyncThunk<
  { envVariables: Array<EnvironmentVariable> },
  undefined,
  IThunkRejectValue
>("envVariables", async (_, { fulfillWithValue, rejectWithValue }) => {
  try {
    const response = await getEnvVariablesApi();

    const { Result } = response.data;
    const envVariables = Result || [];

    return fulfillWithValue({ envVariables });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const deleteEnvVariableAsync = createAsyncThunk<
  boolean,
  { id: number },
  IThunkRejectValue
>("envVariables/delete", async ({ id }, { rejectWithValue }) => {
  try {
    await deleteEnvVariableApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createEnvVariableAsync = createAsyncThunk<
  boolean,
  CreateEnvVariableData,
  IThunkRejectValue
>("envVariables/create", async (formData, { rejectWithValue }) => {
  try {
    await createEnvVariableApi(formData);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createMultipleEnvVariableAsync = createAsyncThunk<
  boolean,
  any,
  IThunkRejectValue
>("envVariables/create/multiple", async (data, { rejectWithValue }) => {
  try {
    await createMultipleEnvVariableApi(data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const updateEnvVariableAsync = createAsyncThunk<
  boolean,
  { id: number; formData: CreateEnvVariableData },
  IThunkRejectValue
>("envVariables/update", async ({ id, formData }, { rejectWithValue }) => {
  try {
    await updateEnvVariableApi(id, formData);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

const envVariablesSlice = createSlice({
  name: "envVariables",
  initialState: initialState,
  reducers: {
    clearApiKeys(state) {
      state.envVariables = initialState.envVariables;
      state.loading = initialState.loading;
    },
    handleShowEnvVariableEditorModal: (
      state,
      action: PayloadAction<{ envVariable?: EnvironmentVariable }>
    ) => {
      state.showEditorModal = true;
      state.selectedEnvVariable = action.payload.envVariable || null;
    },
    handleHideEnvVariableEditorModal: (state) => {
      state.showEditorModal = false;
      state.selectedEnvVariable = null;
    },
    handleShowImportFromEnvFileModal: (state) => {
      state.showImportFromEnvFileModal = true;
    },
    handleHideImportFromEnvFileModal: (state) => {
      state.showImportFromEnvFileModal = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEnvVariablesAysnc.fulfilled, (state, action) => {
        state.envVariables = action.payload.envVariables;
        state.loading = false;
      })
      .addCase(getEnvVariablesAysnc.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEnvVariablesAysnc.rejected, (state, { payload }) => {
        state.loading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteEnvVariableAsync.fulfilled, (state, action) => {
        state.actionLoading = false;
      })
      .addCase(deleteEnvVariableAsync.pending, (state, action) => {
        state.actionLoading = true;
      })
      .addCase(deleteEnvVariableAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createEnvVariableAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(createEnvVariableAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(createEnvVariableAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createMultipleEnvVariableAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(createMultipleEnvVariableAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(
        createMultipleEnvVariableAsync.rejected,
        (state, { payload }) => {
          state.actionLoading = false;
          toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(updateEnvVariableAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(updateEnvVariableAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(updateEnvVariableAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectEnvVariablesLoading = (state: RootState) =>
  state.envVariables.loading;
export const selectEnvVariablesActionLoading = (state: RootState) =>
  state.envVariables.actionLoading;

export const selectEnvVariables = (state: RootState) =>
  state.envVariables.envVariables;

export const selectSelectedEnvVariable = (state: RootState) =>
  state.envVariables.selectedEnvVariable;

export const selectShowEnvVariableEditorModal = (state: RootState) =>
  state.envVariables.showEditorModal;

export const selectShowImportFromEnvFileModal = (state: RootState) =>
  state.envVariables.showImportFromEnvFileModal;

export const {
  clearApiKeys,
  handleHideEnvVariableEditorModal,
  handleShowEnvVariableEditorModal,
  handleHideImportFromEnvFileModal,
  handleShowImportFromEnvFileModal,
} = envVariablesSlice.actions;

export default envVariablesSlice.reducer;
