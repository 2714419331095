import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import Text, { HighlighterText } from "../../general/Text";
import NotData, { WorkflowNotActiveTab } from "../../layouts/NotData";
import {
  getWorkflowWalletsAsync,
  selectWorkflowWallets,
  selectWorkflowWalletsLoading,
} from "../../../store/workflows/walletsSlice";
import { IWorkflowWallet } from "../../../types/workflows";
import { useSearch } from "../../../providers/SearchProvider";
import CopyableKey from "../../general/CopyableKey";
import { timeAgo } from "../../../utils/date";
import { Helmet } from "react-helmet";
import Button from "../../buttons/Button";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil.svg";

import { networks } from "./constant";
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import { IconBtnWrapper } from "../../general/Wrappers";
import Dropdown from "../../general/Dropdown";
import WalletDialog from "./WalletDialog";
import { selectWorkflowStatus } from "../../../store/workflows/workflowsSlice";

const WorkflowsWalletsTab = () => {
  const workflowStatus = useAppSelector(selectWorkflowStatus);

  const wallets = useAppSelector(selectWorkflowWallets);
  const walletsLoading = useAppSelector(selectWorkflowWalletsLoading);
  const [filteredWallets, setFilteredWallets] = useState<IWorkflowWallet[]>([]);

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogProps, setDialogProps] = useState({});

  const dispatch = useAppDispatch();
  const { value: searchValue } = useSearch();

  useEffect(() => {
    dispatch(getWorkflowWalletsAsync());
  }, [dispatch]);

  useEffect(() => {
    const lookedUp = wallets.filter((wallet) =>
      wallet.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredWallets(lookedUp);
  }, [dispatch, searchValue, wallets]);

  const getNetworkItem = useCallback((network: string) => {
    return networks.find((ntw) => ntw.name === network);
  }, []);

  const addNew = useCallback(() => {
    const dialogProp = {
      title: "Add New Wallet",
      type: "ADD",
      cancelButtonName: "Cancel",
      confirmButtonName: "Add",
    };
    setDialogProps(dialogProp);
    setShowDialog(true);
  }, []);

  const importNew = useCallback(() => {
    const dialogProp = {
      title: "Import Wallet",
      type: "IMPORT",
      cancelButtonName: "Cancel",
      confirmButtonName: "IMPORT",
    };
    setDialogProps(dialogProp);
    setShowDialog(true);
  }, []);

  const edit = useCallback((id: string) => {
    const dialogProp = {
      title: "Edit Wallet",
      type: "EDIT",
      cancelButtonName: "Cancel",
      confirmButtonName: "Save",
      id,
    };
    setDialogProps(dialogProp);
    setShowDialog(true);
  }, []);

  const onConfirm = useCallback(() => {
    setShowDialog(false);
    dispatch(getWorkflowWalletsAsync());
  }, [dispatch]);

  if (!workflowStatus) {
    return <WorkflowNotActiveTab />;
  }

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Workflow Wallets </title>
        <meta name="description" content="" />
      </Helmet>
      <div className="flex transition-all duration-500 items-center justify-between gap-3">
        <Text className="whitespace-nowrap font-medium"></Text>
        <div className="flex items-center justify-end gap-1 md:gap-2 ">
          <Button
            type="primary"
            buttonProps={{ onClick: addNew }}
            buttonClassName="group"
          >
            Add New
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
          <Button
            type="light"
            buttonProps={{ onClick: importNew }}
            buttonClassName="group"
          >
            Import Wallet
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>
      <div className="mt-5 w-full">
        <Table loading={walletsLoading} className="table-auto">
          <TableHead>
            <TableRow>
              <TableTH lable="Name" />
              <TableTH lable="Address" />
              <TableTH lable="Network" />
              <TableTH lable="Created" />
              <TableTH lable="" />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredWallets.map((wallet, index) => (
              <TableRow
                key={index}
                onClick={() => edit(wallet._id)}
                className="cursor-pointer"
              >
                <TableTD>
                  <div className="flex items-center">
                    {wallet.address && (
                      <div className="w-[35px] h-[35px] rounded-full bg-white dark:bg-dark-1 mr-3">
                        <Jazzicon
                          diameter={35}
                          seed={jsNumberForAddress(wallet.address)}
                        />
                      </div>
                    )}
                    <HighlighterText
                      searchValue={searchValue}
                      textToHighlight={wallet.name}
                    />
                  </div>
                </TableTD>
                <TableTD>
                  <CopyableKey
                    className="text-xs md:text-sm"
                    text={wallet.address}
                    label={`${wallet.address.slice(
                      0,
                      8
                    )}...${wallet.address.slice(-4)}`}
                  />
                </TableTD>
                <TableTD>
                  <Text
                    className="text-xs md:text-sm !text-slate-50 px-1 py-0.5 rounded-md"
                    style={{
                      backgroundColor:
                        getNetworkItem(wallet.network)?.color || "#666666",
                    }}
                  >
                    {getNetworkItem(wallet.network)?.label}
                  </Text>
                </TableTD>
                <TableTD>
                  <Text className="text-xs md:text-sm whitespace-nowrap">
                    {timeAgo(wallet.createdDate)}
                  </Text>
                </TableTD>
                <TableTD>
                  <div className="h-full w-full inline-flex items-center justify-center gap-1">
                    <div className="w-8 flex justify-center items-center">
                      <Dropdown
                        positionClassName="!w-40"
                        menu={[
                          {
                            key: "1",
                            label: (
                              <div className="flex items-center gap-1">
                                <EditIcon className="w-4" />
                                Edit
                              </div>
                            ),
                            onClick: () => edit(wallet._id),
                          },
                          {
                            type: "divider",
                          },
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: (_, close) => {
                              close();
                              // setDeleteWorkflow(flow);
                            },
                          },
                        ]}
                        type="simple"
                      >
                        <IconBtnWrapper>
                          <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                        </IconBtnWrapper>
                      </Dropdown>
                    </div>
                  </div>
                </TableTD>
              </TableRow>
            ))}
            {wallets.length === 0 && (
              <TableRow withoutHoverStyle={true}>
                <TableTD colSpan={5} className="!border-none">
                  <NotData text="No connections created yet" />
                </TableTD>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <WalletDialog
          show={showDialog}
          dialogProps={dialogProps}
          onCancel={() => setShowDialog(false)}
          onConfirm={onConfirm}
        />
      </div>
    </>
  );
};

export default WorkflowsWalletsTab;
