import { useEffect, useState } from "react";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../../general/Table";
import { ReactComponent as MoreIcon } from "./../../../../assets/icons/more.svg";
import { ReactComponent as ArchiveIcon } from "./../../../../assets/icons/archive-box.svg";

import {
  deleteBucketEventsAsync,
  getBucketEventsAsync,
  handleShowEventEditor,
  selectBucketDetails,
  selectBucketDetailsLoading,
  selectBucketEvents,
  selectBucketEventsActionLoading,
  selectBucketEventsLoading,
} from "../../../../store/s3/buckets/bucketSlice";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import NotData, { S3NotActiveTab } from "../../../layouts/NotData";
import QuestionModal, { DeleteModal } from "../../../modals/QuestionModal";
import Dropdown from "../../../general/Dropdown";
import Button from "../../../buttons/Button";
import { S3BucketEvent } from "../../../../types/s3-bucket";
import S3SubscribeEventsModal from "../modals/buckets/S3SubscribeEventsModal";
import Text from "../../../general/Text";
import { selectS3Status } from "../../../../store/s3/s3PublicSlice";

const S3BucketEventsTab = () => {
  const s3Status = useAppSelector(selectS3Status);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const bucketDetailsLoading = useAppSelector(selectBucketDetailsLoading);

  const bucketEvents = useAppSelector(selectBucketEvents);
  const bucketEventsLoading = useAppSelector(selectBucketEventsLoading);
  const bucketEventsActionLoading = useAppSelector(
    selectBucketEventsActionLoading
  );

  const [deleteEvent, setDeleteEvent] = useState<S3BucketEvent | null>(null);
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();

  useEffect(() => {
    if (bucketDetails && !bucketDetailsLoading) {
      dispatch(getBucketEventsAsync({ name: bucketDetails.name }));
    }
  }, [bucketDetails, dispatch, bucketDetailsLoading]);

  const handleDelete = () => {
    if (deleteEvent && bucketDetails) {
      const deleteData: any = {
        events: deleteEvent.events,
        prefix: deleteEvent.prefix,
        suffix: deleteEvent.suffix,
      };
      dispatch(
        deleteBucketEventsAsync({
          name: bucketDetails.name,
          arn: deleteEvent.arn,
          data: deleteData,
        })
      ).then((action) => {
        if (action.type === "bucket/events/delete/fulfilled") {
          dispatch(getBucketEventsAsync({ name: bucketDetails.name }));
          setDeleteEvent(null);
        }
      });
    }
  };

  if (!s3Status) {
    return <S3NotActiveTab />;
  }
  return (
    <>
      <div className="flex transition-all duration-500 items-center justify-between gap-3">
        <Text className="whitespace-nowrap font-medium">Bucket Events</Text>
        <div className="flex items-center justify-end gap-1 md:gap-2 ">
          <Button
            type="primary"
            buttonProps={{
              onClick: () => dispatch(handleShowEventEditor({})),
              // disabled: true,
            }}
          >
            Subscribe to Event
          </Button>
        </div>
      </div>
      <div className="mt-5 w-full">
        <Table loading={bucketEventsLoading}>
          <TableHead>
            <TableRow>
              <TableTH lable="SQS" />
              <TableTH lable="Events" />
              <TableTH lable="Prefix" />
              <TableTH lable="Suffix" />
              <TableTH lable="" />
            </TableRow>
          </TableHead>
          <TableBody>
            {bucketEvents?.map((event, i) => (
              <TableRow key={i}>
                <TableTD>
                  <Text className="text-xs md:text-sm font-semibold whitespace-nowrap">
                    {event.arn}
                  </Text>
                </TableTD>
                <TableTD>
                  <Text className="text-xs md:text-sm whitespace-nowrap">
                    {event.events.join(", ")}
                  </Text>
                </TableTD>

                <TableTD>
                  <Text className="text-xs md:text-sm whitespace-nowrap">
                    {event.prefix}
                  </Text>
                </TableTD>

                <TableTD>
                  <Text className="text-xs md:text-sm whitespace-nowrap">
                    {event.suffix}
                  </Text>
                </TableTD>

                <TableTD className="w-32 gap-1">
                  <div className="h-full w-full inline-flex items-center justify-end gap-1">
                    <div className="w-8 flex justify-center items-center">
                      <Dropdown
                        positionClassName="!w-40"
                        menu={[
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: () => setDeleteEvent(event),
                          },
                        ]}
                        type="simple"
                      >
                        <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                          <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                        </div>
                      </Dropdown>
                    </div>
                  </div>
                </TableTD>
              </TableRow>
            ))}

            {bucketEvents?.length === 0 && (
              <TableRow withoutHoverStyle={true}>
                <TableTD colSpan={5} className="!border-0">
                  <NotData text="You have not subscribed any event yet" />
                </TableTD>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <S3SubscribeEventsModal />
      <QuestionModal
        isOpen={deleteEvent !== null}
        onClose={() => setDeleteEvent(null)}
        onConfirm={handleDelete}
        loading={bucketEventsActionLoading}
        confirmButtonType="danger"
      />
      <DeleteModal
        title="Delete Event"
        isOpen={deleteEvent !== null}
        onClose={() => setDeleteEvent(null)}
        onConfirm={handleDelete}
        loading={bucketEventsActionLoading}
        // confirmString={
        //   bucketEvents.find((event) => event.arn === deleteEvent?.arn)
        //     ?.arn
        // }
        confirmButtonType="danger"
      />
    </>
  );
};

export default S3BucketEventsTab;
