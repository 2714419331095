import { Node, Edge } from "reactflow";
import {
  ICommonObject,
  IFlowData,
  INodeData,
  INodeParams,
  INodeParamsType,
} from "../types/workflows";
import lodash from "lodash";
import moment from "moment";
import { getLocalStorage } from "./localStorage";
import { Environment } from "prismjs";

export const numberOrExpressionRegex = /^(\d+\.?\d*|{{.*}})$/; //return true if string consists only numbers OR expression {{}}

export const getWorkflowWebhookUrl = (webhookEndpoint: string) => {
  const selectedEnvId = getLocalStorage<Environment | null>("env", null)?.Id;
  return (
    process.env.REACT_APP_FRONT_END_URL +
    "v1/wf-actions/" +
    selectedEnvId +
    "/" +
    webhookEndpoint
  );
};

export const getWorkflowSocketIOBaseUrl = () => {
  const selectedEnvId = getLocalStorage<Environment | null>("env", null)?.Id;
  return "/v1/environment/" + selectedEnvId + "/wf-socket";
};

export const getNodeIconUrl = (baseIconUrl?: string) => {
  if (baseIconUrl) {
    const arr = baseIconUrl.split("/").filter(Boolean);
    return `https://cdn.djuno.cloud/node-icon/${arr[arr.length - 2]}/${
      arr[arr.length - 1]
    }`;
  } else {
    return "";
  }
};

export const constructNodeDirectedGraph = (
  nodes: Node[],
  edges: Edge[],
  reverse: boolean = false
) => {
  const graph: Record<string, string[]> = {};
  const nodeDependencies: Record<string, number> = {};

  // Initialize node dependencies and graph
  for (let i = 0; i < nodes.length; i += 1) {
    const nodeId = nodes[i].id;
    nodeDependencies[nodeId] = 0;
    graph[nodeId] = [];
  }

  for (let i = 0; i < edges.length; i += 1) {
    const source = edges[i].source;
    const target = edges[i].target;

    if (Object.prototype.hasOwnProperty.call(graph, source)) {
      graph[source].push(target);
    } else {
      graph[source] = [target];
    }

    if (reverse) {
      if (Object.prototype.hasOwnProperty.call(graph, target)) {
        graph[target].push(source);
      } else {
        graph[target] = [source];
      }
    }

    nodeDependencies[target] += 1;
  }

  return { graph, nodeDependencies };
};

// Breadth First Search to get all connected parent nodes from target
export const getAllConnectedNodesFromTarget = (
  targetNodeId: string,
  edges: Edge[],
  graph: Record<string, string[]>
) => {
  const nodeQueue: string[] = [];
  const exploredNodes: string[] = [];

  nodeQueue.push(targetNodeId);
  exploredNodes.push(targetNodeId);

  while (nodeQueue.length) {
    const nodeId = nodeQueue.shift() || "";
    const parentNodeIds: string[] = [];

    const inputEdges = edges.filter(
      (edg) => edg.target === nodeId && edg.targetHandle?.includes("-input-")
    );
    if (inputEdges && inputEdges.length) {
      for (let j = 0; j < inputEdges.length; j += 1) {
        parentNodeIds.push(inputEdges[j].source);
      }
    }

    const neighbourNodeIds = graph[nodeId];

    for (let i = 0; i < neighbourNodeIds.length; i += 1) {
      const neighNodeId = neighbourNodeIds[i];
      if (parentNodeIds.includes(neighNodeId)) {
        if (!exploredNodes.includes(neighNodeId)) {
          exploredNodes.push(neighNodeId);
          nodeQueue.push(neighNodeId);
        }
      }
    }
  }
  return exploredNodes;
};

export const getAvailableNodeIdsForVariable = (
  nodes: Node[],
  edges: Edge[],
  targetNodeId: string
) => {
  const { graph } = constructNodeDirectedGraph(nodes, edges, true);
  const exploreNodes = getAllConnectedNodesFromTarget(
    targetNodeId,
    edges,
    graph
  );
  const setPath = new Set(exploreNodes);
  setPath.delete(targetNodeId);
  return Array.from(setPath);
};

export const generateWebhookEndpoint = () => {
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  const webhookEndpoint = Array.from({ length: 15 })
    .map(() => {
      return characters.charAt(Math.floor(Math.random() * characters.length));
    })
    .join("");
  return webhookEndpoint;
};

export const getUniqueNodeId = (nodeData: INodeData, nodes: Node[]) => {
  // Get amount of same nodes
  let totalSameNodes = 0;
  for (let i = 0; i < nodes.length; i += 1) {
    const node = nodes[i];
    if (node.data.name === nodeData.name) {
      totalSameNodes += 1;
    }
  }

  // Get unique id
  let nodeId = `${nodeData.name}_${totalSameNodes}`;
  for (let i = 0; i < nodes.length; i += 1) {
    const node = nodes[i];
    if (node.id === nodeId) {
      totalSameNodes += 1;
      nodeId = `${nodeData.name}_${totalSameNodes}`;
    }
  }
  return nodeId;
};

const getUniqueNodeLabel = (nodeData: INodeData, nodes: Node[]) => {
  // Get amount of same nodes
  let totalSameNodes = 0;
  for (let i = 0; i < nodes.length; i += 1) {
    const node = nodes[i];
    if (node.data.name === nodeData.name) {
      totalSameNodes += 1;
    }
  }

  // Get unique label
  let nodeLabel = `${nodeData.label}_${totalSameNodes}`;
  for (let i = 0; i < nodes.length; i += 1) {
    const node = nodes[i];
    if (node.data.label === nodeLabel) {
      totalSameNodes += 1;
      nodeLabel = `${nodeData.label}_${totalSameNodes}`;
    }
  }
  return totalSameNodes === 0 ? nodeData.label : nodeLabel;
};

export const checkIfNodeLabelUnique = (nodeLabel: string, nodes: Node[]) => {
  for (let i = 0; i < nodes.length; i += 1) {
    const node = nodes[i];
    if (node.data.label === nodeLabel) {
      return false;
    }
  }
  return true;
};

export const initializeNodeData = (nodeParams: ICommonObject) => {
  let initialValues: { [key: string]: any } = {};
  const params = nodeParams as unknown as INodeParams[];
  for (let i = 0; i < params.length; i += 1) {
    const input = params[i];

    initialValues[input.name] = input.default || "";

    if (input.type === "array" && input.array) {
      // Add a check for input.array
      const newObj: { [key: string]: any } = {};
      for (let j = 0; j < input.array.length; j += 1) {
        newObj[input.array[j].name] = input.array[j].default || "";
      }
      initialValues[input.name] = [newObj];
    }
  }

  initialValues.submit = null;

  return initialValues;
};

export const addAnchors = (
  nodeData: INodeData,
  nodes: Node<INodeData>[],
  newNodeId: string
) => {
  const incoming = nodeData.incoming || 0;
  const outgoing = nodeData.outgoing || 0;

  const inputAnchors = [];
  for (let i = 0; i < incoming; i += 1) {
    const newInput = {
      id: `${newNodeId}-input-${i}`,
    };
    inputAnchors.push(newInput);
  }

  const outputAnchors = [];
  for (let i = 0; i < outgoing; i += 1) {
    const newOutput = {
      id: `${newNodeId}-output-${i}`,
    };
    outputAnchors.push(newOutput);
  }

  nodeData.inputAnchors = inputAnchors;
  nodeData.outputAnchors = outputAnchors;
  nodeData.label = getUniqueNodeLabel(nodeData, nodes);

  if (nodeData.actions) nodeData.actions = initializeNodeData(nodeData.actions);
  if (nodeData.credentials)
    nodeData.credentials = initializeNodeData(nodeData.credentials);
  if (nodeData.networks)
    nodeData.networks = initializeNodeData(nodeData.networks);
  if (nodeData.inputParameters)
    nodeData.inputParameters = initializeNodeData(nodeData.inputParameters);

  return nodeData;
};

export const getEdgeLabelName = (source: string | null) => {
  if (source === null) return "";
  const sourceSplit = source.split("-");
  if (sourceSplit.length && sourceSplit[0].includes("ifElse")) {
    const outputAnchorsIndex = sourceSplit[sourceSplit.length - 1];
    return outputAnchorsIndex === "0" ? "true" : "false";
  }
  return "";
};

export const checkMultipleTriggers = (nodes: Node[]) => {
  for (let i = 0; i < nodes.length; i += 1) {
    const node = nodes[i];
    if (node.data.type === "webhook" || node.data.type === "trigger") {
      return true;
    }
  }
  return false;
};

export const convertDateStringToDateObject = (dateString: string) => {
  if (dateString === undefined || !dateString) return undefined;

  const date = moment(dateString);
  if (!date.isValid) return undefined;

  // Sat Sep 24 2022 07:30:14
  return new Date(
    date.year(),
    date.month(),
    date.date(),
    date.hours(),
    date.minutes()
  );
};

export const getFileName = (fileBase64: string) => {
  const splitDataURI = fileBase64.split(",");
  const filename = splitDataURI[splitDataURI.length - 1].split(":")[1];
  return filename;
};

export const getFolderName = (base64ArrayStr: string) => {
  try {
    const base64Array = JSON.parse(base64ArrayStr);
    const filenames = [];
    for (let i = 0; i < base64Array.length; i += 1) {
      const fileBase64 = base64Array[i];
      const splitDataURI = fileBase64.split(",");
      const filename = splitDataURI[splitDataURI.length - 1].split(":")[1];
      filenames.push(filename);
    }
    return filenames.length ? filenames.join(",") : "";
  } catch (e) {
    return "";
  }
};

export const generateExportFlowData = (flowData: IFlowData) => {
  const nodes = flowData.nodes;
  const edges = flowData.edges;

  console.log(flowData);
  for (let i = 0; i < nodes.length; i += 1) {
    nodes[i].selected = false;
    const node = nodes[i];
    const newNodeData: any = {
      label: node.data.label,
      name: node.data.name,
      type: node.data.type,
      inputAnchors: node.data.inputAnchors,
      outputAnchors: node.data.outputAnchors,
      selected: false,
      icon: node.data.icon,
    };
    if (node.data.inputParameters) {
      newNodeData.inputParameters = {
        ...node.data.inputParameters,
        submit: null,
      };
      if (node.data.inputParameters.wallet)
        delete newNodeData.inputParameters.wallet;
    }
    if (node.data.actions) {
      newNodeData.actions = { ...node.data.actions, submit: null };
      if (node.data.actions.wallet) delete newNodeData.actions.wallet;
    }
    if (node.data.networks) {
      newNodeData.networks = { ...node.data.networks, submit: null };
      if (node.data.networks.wallet) delete newNodeData.networks.wallet;
    }
    if (node.data.credentials && node.data.credentials.credentialMethod) {
      newNodeData.credentials = {
        credentialMethod: node.data.credentials.credentialMethod,
        submit: null,
      };
      if (node.data.credentials.wallet) delete newNodeData.credentials.wallet;
    }

    nodes[i].data = newNodeData;
  }
  const exportJson = {
    nodes,
    edges,
  };
  return exportJson;
};

const isHideRegisteredCredential = (
  params: INodeParams[],
  paramsType: INodeParamsType,
  nodeFlowData: INodeData
) => {
  const nodeParams = nodeFlowData[paramsType];
  if (!nodeParams || !("credentialMethod" in nodeParams)) {
    return undefined;
  }

  let clonedParams = params;

  for (let i = 0; i < clonedParams.length; i += 1) {
    const input = clonedParams[i];
    if (input.type === "options" && input.options) {
      const selectedCredentialMethodOption = input.options.find(
        (opt) => opt.name === nodeParams["credentialMethod"]
      );
      if (
        selectedCredentialMethodOption &&
        selectedCredentialMethodOption !== undefined &&
        selectedCredentialMethodOption.hideRegisteredCredential
      )
        return true;
    }
  }
  return false;
};

export const handleCredentialParams = (
  nodeParams: INodeParams[],
  paramsType: INodeParamsType,
  reorganizedParams: INodeParams[],
  nodeFlowData: INodeData
) => {
  if (
    paramsType === "credentials" &&
    nodeParams.find((nPrm) => nPrm.name === "registeredCredential") ===
      undefined &&
    nodeParams.find((nPrm) => nPrm.name === "credentialMethod") !== undefined &&
    !isHideRegisteredCredential(
      lodash.cloneDeep(reorganizedParams),
      paramsType,
      nodeFlowData
    )
  ) {
    // Add hard-coded registeredCredential params
    nodeParams.push({
      label: "Registered Credential", //TODO
      type: "string", //TODO
      name: "registeredCredential",
    });
  } else if (
    paramsType === "credentials" &&
    nodeParams.find((nPrm) => nPrm.name === "registeredCredential") !==
      undefined &&
    nodeParams.find((nPrm) => nPrm.name === "credentialMethod") !== undefined &&
    isHideRegisteredCredential(
      lodash.cloneDeep(reorganizedParams),
      paramsType,
      nodeFlowData
    )
  ) {
    // Delete registeredCredential params
    nodeParams = nodeParams.filter(
      (prm) => prm.name !== "registeredCredential"
    );
  } else if (
    paramsType === "credentials" &&
    nodeParams.find((nPrm) => nPrm.name === "credentialMethod") === undefined
  ) {
    // Delete registeredCredential params
    nodeParams = nodeParams.filter(
      (prm) => prm.name !== "registeredCredential"
    );
  }
  return nodeParams;
};

export const copyToClipboard = (e: any) => {
  const src = e.src;
  if (Array.isArray(src) || typeof src === "object") {
    navigator.clipboard.writeText(JSON.stringify(src, null, "  "));
  } else {
    navigator.clipboard.writeText(src);
  }
};
