import {
  useEffect,
  useRef,
  useState,
  useCallback,
  DragEventHandler,
  useMemo,
} from "react";
import ReactFlow, {
  addEdge,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  ReactFlowInstance,
  Connection,
  NodeMouseHandler,
  Node,
  ReactFlowProvider,
} from "reactflow";
import "reactflow/dist/style.css";
import "./index.css";

import { useNavigate } from "react-router-dom";
import { unstable_usePrompt as usePrompt } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../hooks";

// project imports
import CanvasNode from "./CanvasNode";
import ButtonEdge from "./ButtonEdge";
import CanvasHeader from "./CanvasHeader";

// third party
import socketIOClient from "socket.io-client";
import {
  getWorkflowAsync,
  handleRemoveDirty,
  handleSetDirty,
  handleSetRemoveEdge,
  handleSetWorkflow,
  selectCanvas,
  selectWorkflow,
  selectWorkflowLoading,
  testWorkflowAsync,
} from "../../../../store/workflows/workflowSlice";
import { INodeData, IWorkflowResponse } from "../../../../types/workflows";

// utils
import {
  generateWebhookEndpoint,
  getUniqueNodeId,
  checkIfNodeLabelUnique,
  addAnchors,
  getEdgeLabelName,
  checkMultipleTriggers,
} from "./../../../../utils/wfHelper";
import {
  createWorkflowAsync,
  deleteWorkflowAsync,
  deployWorkflowAsync,
  getNodesAsync,
  selectNodes,
  selectNodesLoading,
  selectWorkflowStatus,
  selectWorkflowsActionLoading,
  updateWorkflowAsync,
} from "../../../../store/workflows/workflowsSlice";
import toast from "react-hot-toast";
import QuestionModal from "../../../modals/QuestionModal";
import AddNodes from "./AddNodes";
import EditNodes from "./EditNodes";
import Button from "../../../buttons/Button";
import { ReactComponent as IconBolt } from "./../../../../assets/icons/bolt.svg";
import TestWorkflowDialog from "../dialog/TestWorkflowDialog";
import {
  deleteAllTestWebhooksApi,
  removeTestTriggersApi,
} from "../../../../apis/workflowsAPI";
import { ToastClasses } from "../../../modals/alerts";
import useDarkMode from "../../../../hooks/useDarkMode";
import { NotExist, WorkflowNotActiveTab } from "../../../layouts/NotData";
import { WorkflowsUrl } from "../../../../utils/urls";
import { getLocalStorage } from "../../../../utils/localStorage";
import { Environment } from "../../../../types/environment";
import { LoginUser } from "../../../../types/auth";

const Canvas = () => {
  const workflowStatus = useAppSelector(selectWorkflowStatus);

  const selectedWorkflow = useAppSelector(selectWorkflow);
  const selectedWorkflowLoading = useAppSelector(selectWorkflowLoading);

  const nodesData = useAppSelector(selectNodes);
  const nodesDataLoading = useAppSelector(selectNodesLoading);

  const actionLoading = useAppSelector(selectWorkflowsActionLoading);

  const { mode } = useDarkMode();

  const URLpath = document.location.pathname.toString().split("/");
  const workflowShortId =
    URLpath[URLpath.length - 1] && URLpath[URLpath.length - 1].startsWith("W")
      ? URLpath[URLpath.length - 1]
      : "";

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const canvas = useAppSelector(selectCanvas);
  console.log("canvas", canvas);
  const [canvasDataStore, setCanvasDataStore] = useState(canvas);
  const [workflow, setWorkflow] = useState<IWorkflowResponse | null>(null);
  const [deleteWorkflow, setDeleteWorkflow] = useState<boolean>(false);
  const [isTestWorkflowDialogOpen, setTestWorkflowDialogOpen] =
    useState<boolean>(false);
  const [testWorkflowDialogProps, setTestWorkflowDialogProps] = useState({});
  const [isTestingWorkflow, setIsTestingWorkflow] = useState<boolean>(false);
  // const [menu, setMenu] = useState<IContextMenuData | null>(null);
  // ==============================|| ReactFlow ||============================== //

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const nodeTypes = useMemo(() => ({ customNode: CanvasNode }), []);
  const edgeTypes = useMemo(() => ({ buttonedge: ButtonEdge }), []);

  const [rfInstance, setRfInstance] = useState<ReactFlowInstance | null>(null);
  const [selectedNode, setSelectedNode] = useState<Node | null>(null);

  const [isExist, setIsExist] = useState<boolean>(true);

  const reactFlowWrapper = useRef<HTMLDivElement>(null);

  // ==============================|| Events & Actions ||============================== //

  const setDirty = useCallback(() => {
    dispatch(handleSetDirty());
  }, [dispatch]);

  const onConnect = useCallback(
    (params: Connection) => {
      const newEdge = {
        ...params,
        type: "buttonedge",
        id: `${params.source}-${params.sourceHandle}-${params.target}-${params.targetHandle}`,
        data: { label: getEdgeLabelName(params.sourceHandle) },
      };
      setEdges((eds) => addEdge(newEdge, eds));
      setDirty();
    },
    [setDirty, setEdges]
  );

  const handleTestWorkflow = () => {
    try {
      if (workflow && workflow.deployed) {
        alert(
          "Testing workflow requires stopping deployed workflow. Please stop deployed workflow first"
        );
        return;
      }
      if (rfInstance) {
        const rfInstanceObject = rfInstance.toObject();
        const nodes = rfInstanceObject.nodes || [];
        setTestWorkflowDialogOpen(true);
        setTestWorkflowDialogProps({
          title: "Test Workflow",
          nodes: nodes.filter((nd) => !nd.id.includes("ifElse")),
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onStartingPointClick = (startingNodeId: string) => {
    try {
      if (rfInstance) {
        const proxySocket = socketIOClient("", {
          path: "/wf-socket",
        });
        const rfInstanceObject = rfInstance.toObject();
        const nodes = rfInstanceObject.nodes || [];
        const edges = rfInstanceObject.edges || [];
        setTestWorkflowDialogOpen(false);

        let user = getLocalStorage<LoginUser | null>("login", null);
        const selectedEnvId = getLocalStorage<Environment | null>(
          "env",
          null
        )?.Id;

        proxySocket.on("connect", () => {
          proxySocket.emit("selectEnvironment", selectedEnvId, user?.JwtToken);
        });

        proxySocket.on("proxyConnected", async (serverSocketId) => {
          const clientId = serverSocketId.id;
          const node = nodes.find((nd) => nd.id === startingNodeId);
          if (node) {
            const nodeData = node.data;
            const body = {
              nodes,
              edges,
              clientId,
              nodeData,
            };
            // testWorkflowApi.request(startingNodeId, body);
            dispatch(testWorkflowAsync({ startingNodeId, data: body }));
            setNodes((nds) =>
              nds.map((node) => {
                node.data = {
                  ...node.data,
                  outputResponses: {
                    ...node.data.outputResponses,
                    submit: null,
                    needRetest: null,
                  },
                  selected: false,
                };
                return node;
              })
            );
            setIsTestingWorkflow(true);
          } else {
            setIsTestingWorkflow(false);
          }
        });

        proxySocket.on("testWorkflowNodeResponse", (value) => {
          const { nodeId, data, status } = value;

          const node = nodes.find((nd) => nd.id === nodeId);
          if (node) {
            const outputValues = {
              submit: status === "FINISHED" ? true : null,
              needRetest: status === "FINISHED" ? null : true,
              output: data,
            };
            const nodeData = node.data;
            nodeData["outputResponses"] = outputValues;
            setNodes((nds) =>
              nds.map((node) => {
                if (node.id === nodeId) {
                  node.data = {
                    ...nodeData,
                    selected: false,
                  };
                }
                return node;
              })
            );
          }
        });

        proxySocket.on("testWorkflowNodeFinish", () => {
          setIsTestingWorkflow(false);
          proxySocket.disconnect();
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  //load workflow with pasted text of selected file
  const handleLoadWorkflow = useCallback(
    (file: any) => {
      try {
        const flowData = JSON.parse(file);
        const nodes = flowData.nodes || [];

        for (let i = 0; i < nodes.length; i += 1) {
          const nodeData = nodes[i].data;
          if (nodeData.type === "webhook")
            nodeData.webhookEndpoint = generateWebhookEndpoint();
        }

        setNodes(nodes);
        setEdges(flowData.edges || []);
        setDirty();
      } catch (e) {
        console.error(e);
      }
    },
    [setDirty, setEdges, setNodes]
  );

  const handleDeployWorkflow = async () => {
    if (rfInstance && workflow) {
      const rfInstanceObject = rfInstance.toObject();
      const flowData = JSON.stringify(rfInstanceObject);

      // Always save workflow first
      let savedWorkflowResponse: IWorkflowResponse | undefined;
      if (!workflow.shortId) {
        const newWorkflowBody = {
          name: workflow.name,
          deployed: false,
          flowData,
        };
        await dispatch(createWorkflowAsync({ data: newWorkflowBody })).then(
          (action) => {
            if (
              action.type === "workflows/create/fulfilled" &&
              action.payload &&
              "workflow" in action.payload
            ) {
              savedWorkflowResponse = action.payload.workflow;
            }
          }
        );
      } else {
        const updateBody = {
          flowData,
        };
        await dispatch(
          updateWorkflowAsync({ id: workflow.shortId, data: updateBody })
        ).then((action) => {
          if (
            action.type === "workflows/update/fulfilled" &&
            action.payload &&
            "workflow" in action.payload
          ) {
            savedWorkflowResponse = action.payload.workflow;
          }
        });
      }

      dispatch(handleRemoveDirty());

      // Then deploy
      if (savedWorkflowResponse) {
        dispatch(
          deployWorkflowAsync({
            id: savedWorkflowResponse.shortId,
            data: {},
          })
        ).then((action) => {
          if (
            action.type === "workflows/deploy/fulfilled" &&
            action.payload &&
            "workflow" in action.payload
          ) {
            const deployedWorkflowResponse = action.payload.workflow;
            dispatch(handleSetWorkflow({ workflow: deployedWorkflowResponse }));
            toast.success("Workflow deployed!", { className: ToastClasses });
          }
        });
      }
    }
  };

  const handleStopWorkflow = () => {
    if (workflow) {
      dispatch(
        deployWorkflowAsync({
          id: workflow.shortId,
          data: {
            halt: true,
          },
        })
      ).then((action) => {
        if (
          action.type === "workflows/deploy/fulfilled" &&
          action.payload &&
          "workflow" in action.payload
        ) {
          const stoppedWorkflowResponse = action.payload.workflow;
          dispatch(handleSetWorkflow({ workflow: stoppedWorkflowResponse }));
          toast.success("Workflow stopped", { className: ToastClasses });
        }
      });
    }
  };

  const handleDeleteWorkflow = () => {
    if (workflow) {
      dispatch(deleteWorkflowAsync({ id: workflow.shortId })).then((action) => {
        if (action.type === "workflows/delete/fulfilled") {
          navigate(-1);
        }
      });
    }
  };

  const handleSaveFlow = (workflowName: string) => {
    if (rfInstance) {
      setNodes((nds) =>
        nds.map((node) => {
          node.data = {
            ...node.data,
            selected: false,
          };
          return node;
        })
      );

      const rfInstanceObject = rfInstance.toObject();
      const flowData = JSON.stringify(rfInstanceObject);

      if (!workflow) {
        const newWorkflowBody = {
          name: workflowName,
          deployed: false,
          flowData,
        };
        dispatch(createWorkflowAsync({ data: newWorkflowBody })).then(
          (action) => {
            if (
              action.type === "workflows/create/fulfilled" &&
              action.payload &&
              "workflow" in action.payload
            ) {
              const wf = action.payload.workflow;
              dispatch(handleSetWorkflow({ workflow: wf }));
              saveWorkflowSuccess();
              window.history.replaceState(null, "", `/canvas/${wf.shortId}`);
            }
          }
        );
      } else {
        const updateBody = {
          name: workflowName,
          flowData,
        };
        dispatch(
          updateWorkflowAsync({ id: workflow.shortId, data: updateBody })
        ).then((action) => {
          if (
            action.type === "workflows/update/fulfilled" &&
            action.payload &&
            "workflow" in action.payload
          ) {
            const wf = action.payload.workflow;
            dispatch(handleSetWorkflow({ workflow: wf }));
            saveWorkflowSuccess();
          }
        });
      }
    }
  };

  const onNodeDoubleClick = useCallback<NodeMouseHandler>(
    (_, clickedNode) => {
      setSelectedNode(clickedNode);
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === clickedNode.id) {
            node.data = {
              ...node.data,
              selected: true,
            };
          } else {
            node.data = {
              ...node.data,
              selected: false,
            };
          }

          return node;
        })
      );
    },
    [setNodes]
  );

  const onNodeContextMenu = useCallback<NodeMouseHandler>(
    (event, clickedNode) => {
      event.preventDefault();
      setSelectedNode(clickedNode);
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === clickedNode.id) {
            node.data = {
              ...node.data,
              selected: true,
            };
          } else {
            node.data = {
              ...node.data,
              selected: false,
            };
          }
          return node;
        })
      );

      // Calculate position of the context menu. We want to make sure it
      // doesn't get positioned off-screen.
      // const pane = reactFlowWrapper.current?.getBoundingClientRect();

      // if (pane) {
      //   const positionsData = {
      //     nodeId: clickedNode.id,
      //     nodeLabel: clickedNode.data.label,
      //     top: event.clientY < pane.height - 200 && event.clientY - 100,
      //     left: event.clientX < pane.width - 200 && event.clientX,
      //     right:
      //       event.clientX >= pane.width - 200 && pane.width - event.clientX,
      //     bottom:
      //       event.clientY >= pane.height - 200 &&
      //       pane.height - event.clientY + 50,
      //   };
      //   console.log(positionsData);
      //   setMenu(positionsData);
      // }
    },
    [setNodes]
  );

  const onNodeLabelUpdate = useCallback(
    (nodeLabel: string) => {
      if (selectedNode && rfInstance) {
        setNodes((nds) =>
          nds.map((node) => {
            if (node.id === selectedNode.id) {
              if (!checkIfNodeLabelUnique(nodeLabel, rfInstance.getNodes())) {
                toast.error("Duplicated node label", {
                  className: ToastClasses,
                });
              } else {
                if (node.data.label !== nodeLabel) {
                  setTimeout(() => setDirty(), 0);
                }
                node.data = {
                  ...node.data,
                  label: nodeLabel,
                };
              }
            }
            return node;
          })
        );
      }
    },
    [rfInstance, selectedNode, setDirty, setNodes]
  );

  const onNodeValuesUpdate = useCallback(
    (nodeFlowData: INodeData) => {
      if (selectedNode) {
        setNodes((nds) =>
          nds.map((node) => {
            if (node.id === selectedNode.id) {
              setTimeout(() => setDirty(), 0);
              node.data = {
                ...node.data,
                ...nodeFlowData,
                selected: true,
              };
            }
            return node;
          })
        );
      }
    },
    [selectedNode, setDirty, setNodes]
  );

  const onDragOver = useCallback<DragEventHandler<HTMLDivElement>>((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback<DragEventHandler<HTMLDivElement>>(
    (event) => {
      event.preventDefault();
      const reactFlowBounds = reactFlowWrapper.current?.getBoundingClientRect();
      let nodeData = event.dataTransfer.getData("application/reactflow");

      if (reactFlowBounds && rfInstance) {
        // check if the dropped element is valid
        if (typeof nodeData === "undefined" || !nodeData) {
          return;
        }

        const _node = JSON.parse(nodeData) as INodeData;
        // check if workflow contains multiple triggers/webhooks
        if (
          (_node.type === "webhook" || _node.type === "trigger") &&
          checkMultipleTriggers(rfInstance.getNodes())
        ) {
          toast.error("Workflow can only contains 1 trigger or webhook node", {
            className: ToastClasses,
          });
          return;
        }

        if (_node.type === "webhook")
          _node.webhookEndpoint = generateWebhookEndpoint();

        const position = rfInstance.project({
          x: event.clientX - reactFlowBounds.left - 100,
          y: event.clientY - reactFlowBounds.top - 50,
        });

        const newNodeId = getUniqueNodeId(_node, rfInstance.getNodes());

        const newNode = {
          id: newNodeId,
          position,
          type: "customNode",
          data: addAnchors(_node, rfInstance.getNodes(), newNodeId),
        };

        setSelectedNode(newNode);
        setNodes((nds) =>
          nds.concat(newNode).map((node) => {
            if (node.id === newNode.id) {
              node.data = {
                ...node.data,
                selected: true,
              };
            } else {
              node.data = {
                ...node.data,
                selected: false,
              };
            }

            return node;
          })
        );
        setTimeout(() => setDirty(), 0);
      }
    },

    [rfInstance, setDirty, setNodes]
  );

  const saveWorkflowSuccess = useCallback(() => {
    dispatch(handleRemoveDirty());
    toast.success("Workflow saved", { className: ToastClasses });
  }, [dispatch]);

  const deleteNode = useCallback(
    (nodeId: string) => {
      setNodes((nodes) => nodes.filter((node) => node.id !== nodeId));
      setEdges((edges) => edges.filter((edge) => edge.source !== nodeId));
      setDirty();
      setSelectedNode(null);
    },
    [setNodes, setEdges, setDirty]
  );

  // ==============================|| useEffect ||============================== //

  // Get specific workflow successful
  useEffect(() => {
    if (selectedWorkflow) {
      const initialFlow = selectedWorkflow.flowData
        ? JSON.parse(selectedWorkflow.flowData)
        : [];
      // console.log(initialFlow);
      setNodes(initialFlow.nodes || []);
      setEdges(initialFlow.edges || []);
      // dispatch(handleSetWorkflow({ workflow: selectedWorkflow }));
    }
  }, [dispatch, selectedWorkflow, setEdges, setNodes]);

  // Listen to edge button click remove redux event
  useEffect(() => {
    if (rfInstance && canvasDataStore.removeEdgeId !== "") {
      const edges = rfInstance.getEdges();
      const toRemoveEdgeId = canvasDataStore.removeEdgeId.split(":")[0];
      setEdges(edges.filter((edge) => edge.id !== toRemoveEdgeId));
      setDirty();
    }
  }, [canvasDataStore.removeEdgeId, rfInstance, setDirty, setEdges]);

  useEffect(
    () => setWorkflow(canvasDataStore.workflow),
    [canvasDataStore.workflow]
  );

  useEffect(() => {
    if (workflow && rfInstance) {
      setTimeout(() => rfInstance.fitView(), 100);
    }
  }, [rfInstance, workflow]);

  // Initialization
  useEffect(() => {
    removeTestTriggersApi();
    deleteAllTestWebhooksApi();

    if (workflowShortId) {
      dispatch(getWorkflowAsync(workflowShortId)).then((action) => {
        if (action.type === "workflow/rejected") {
          setIsExist(false);
        }
      });
    } else {
      setNodes([]);
      setEdges([]);
    }

    dispatch(getNodesAsync());

    // Clear dirty state before leaving and remove any ongoing test triggers and webhooks
    return () => {
      removeTestTriggersApi();
      deleteAllTestWebhooksApi();

      setTimeout(() => {
        dispatch(handleRemoveDirty());
        dispatch(handleSetRemoveEdge({ edgeId: "" }));
        dispatch(handleSetWorkflow({ workflow: null }));
      }, 0);
    };
  }, [dispatch, setEdges, setNodes, workflowShortId]);

  useEffect(() => {
    setCanvasDataStore(canvas);
  }, [canvas]);

  useEffect(() => {
    function handlePaste(e: any) {
      const pasteData = e.clipboardData.getData("text");
      //TODO: prevent paste event when input focused, temporary fix: catch workflow syntax
      if (
        pasteData.includes('{"nodes":[') &&
        pasteData.includes('],"edges":[')
      ) {
        handleLoadWorkflow(pasteData);
      }
    }

    window.addEventListener("paste", handlePaste);

    return () => {
      window.removeEventListener("paste", handlePaste);
    };
  }, [handleLoadWorkflow]);

  usePrompt({
    message: "You have unsaved changes! Do you want to navigate away?",
    when: canvasDataStore.isDirty,
  });

  if (!workflowStatus) {
    return <WorkflowNotActiveTab />;
  }

  return (
    <div className="flex flex-col h-[calc(100vh-4rem)]">
      {isExist ? (
        <>
          <div className="h-16 w-full">
            <CanvasHeader
              workflow={workflow}
              handleSaveFlow={handleSaveFlow}
              handleDeployWorkflow={handleDeployWorkflow}
              handleStopWorkflow={handleStopWorkflow}
              handleDeleteWorkflow={() => setDeleteWorkflow(true)}
              handleLoadWorkflow={handleLoadWorkflow}
              loading={selectedWorkflowLoading}
            />
          </div>
          <div className="flex-1 text-gray-400">
            <div className="reactflow-parent-wrapper">
              {!selectedWorkflowLoading && (
                <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                  <ReactFlowProvider>
                    <ReactFlow
                      nodes={nodes}
                      edges={edges}
                      onNodesChange={onNodesChange}
                      onNodeDoubleClick={onNodeDoubleClick}
                      onNodeContextMenu={onNodeContextMenu}
                      onEdgesChange={onEdgesChange}
                      onDrop={onDrop}
                      onDragOver={onDragOver}
                      onNodeDragStop={setDirty}
                      nodeTypes={nodeTypes}
                      edgeTypes={edgeTypes}
                      onConnect={onConnect}
                      onInit={setRfInstance}
                      fitView={true}
                      dir=""
                    >
                      <MiniMap
                        nodeColor={"#0074E5"}
                        nodeBorderRadius={4}
                        maskColor={
                          mode === "dark"
                            ? "rgba(156,163,175,0.15)"
                            : "rgba(156,163,175,0.25)"
                        }
                        className="dark:!bg-dark-1 hidden md:block"
                      />
                      <Controls
                        className="!flex !left-1/2 dark:!bg-dark-1"
                        style={{
                          transform: "translate(-50%, -50%)",
                        }}
                      />
                      <Background color={"#aaa"} gap={16} />
                      <AddNodes
                        nodesData={nodesData}
                        nodesDataLoading={nodesDataLoading}
                        node={selectedNode}
                      />
                      <EditNodes
                        nodes={nodes}
                        edges={edges}
                        node={selectedNode}
                        workflow={workflow}
                        onNodeLabelUpdate={onNodeLabelUpdate}
                        onNodeValuesUpdate={onNodeValuesUpdate}
                        onDeleteNode={deleteNode}
                      />
                      <div className="absolute z-[1050] top-5 right-5 inline-flex">
                        <Button
                          buttonClassName="!px-1.5 !bg-yellow-300 hover:!bg-yellow-500"
                          buttonProps={{
                            disabled: isTestingWorkflow,
                            onClick: handleTestWorkflow,
                          }}
                          loading={isTestingWorkflow}
                        >
                          <IconBolt className="w-5 aspect-square text-slate-800 p-0.5" />
                        </Button>
                      </div>
                    </ReactFlow>
                  </ReactFlowProvider>
                </div>
              )}
            </div>
          </div>

          <QuestionModal
            title={`Delete`}
            description={`Delete workflow ${workflow?.name}?`}
            isOpen={deleteWorkflow}
            onClose={() => setDeleteWorkflow(false)}
            confirmButtonType="danger"
            confirmButtonText="Delete"
            onConfirm={handleDeleteWorkflow}
            loading={actionLoading}
          />
          <TestWorkflowDialog
            show={isTestWorkflowDialogOpen}
            dialogProps={testWorkflowDialogProps}
            onCancel={() => setTestWorkflowDialogOpen(false)}
            onItemClick={onStartingPointClick}
          />
        </>
      ) : (
        <NotExist url={() => navigate(WorkflowsUrl)} />
      )}
    </div>
  );
};
export default Canvas;
