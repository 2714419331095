import { Outlet } from "react-router-dom";
import Tabs from "../../components/general/Tabs";
import { TabOptions } from "../../types/tabs";
import {
  NftApiApiKeysUrl,
  NftApiOverviewUrl,
  NftApiPlansUrl,
} from "../../utils/urls";
import Text from "../../components/general/Text";
import { Helmet } from "react-helmet";
import NftApiApiKeysTab from "../../components/nft-api/api_keys/NftApiApiKeysTab";
import NftApiOverviewTab from "../../components/nft-api/oweview/MarketApiOverviewTab";
import NftApiPlansTab from "../../components/nft-api/plans/NftApiPlansTab";
import { ReactComponent as ComputerIcon } from "./../../assets/icons/computer-desktop.svg";
import { ReactComponent as KeyIcon } from "./../../assets/icons/key.svg";
import { ReactComponent as ListBulletIcon } from "./../../assets/icons/list-bullet.svg";

export const NftApiPageMenus: TabOptions = [
  {
    label: (
      <div className="flex items-center gap-1">
        <ComputerIcon className="w-3" />
        Overview
      </div>
    ),
    url: NftApiOverviewUrl,
    element: <NftApiOverviewTab />,
    testId: "1th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <KeyIcon className="w-3" />
        Access Keys
      </div>
    ),
    url: NftApiApiKeysUrl,
    element: <NftApiApiKeysTab />,
    testId: "2th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <ListBulletIcon className="w-3" />
        Plans
      </div>
    ),
    url: NftApiPlansUrl,
    element: <NftApiPlansTab />,
    testId: "3th-tab",
  },
];

const NftApiPage = () => {
  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | NFTs API</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="flex items-center justify-between h-20">
        <div className="px-6 items-center justify-between flex flex-1 transition duration-150">
          <Text className="font-medium text-lg">NFT API</Text>
        </div>
        {/* <div className="mr-6 w-96"></div> */}
      </div>
      <div className="mx-6">
        <Tabs
          useUrl
          options={NftApiPageMenus.map((o) => ({
            label: o.label,
            url: o.url,
            testId: o.testId,
          }))}
        />
        <div className="py-6">
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default NftApiPage;
