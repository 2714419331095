import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../../components/buttons/Button";
import { ReactComponent as RefreshIcon } from "./../../../../assets/icons/arrow-path.svg";
import { ReactComponent as ArchiveIcon } from "./../../../../assets/icons/archive-box.svg";
import { ReactComponent as EditIcon } from "./../../../../assets/icons/pencil-square.svg";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InstanceVolumeEditSchema } from "../../../../utils/validations";
import { DBSAddon } from "../../../../types/database";
import {
  getInstancesProductAvailabilityAsync,
  getRegionsAsync,
} from "../../../../store/instances/instancesSlice";
import {
  getDBSCatalogAsync,
  selectDBSCatalog,
  selectDBSCatalogLoading,
} from "../../../../store/database/servicesSlice";
import { InstanceVolume } from "../../../../types/instance";
import {
  InstanceVolumeCapacityInput,
  InstanceVolumeNameInput,
} from "../../../../components/instances/volumes/CreateComponents";
import {
  deleteInstancesVolumesAsync,
  getInstancesVolumeAsync,
  getInstancesVolumesAsync,
  handleSetSelectedInstanceVolume,
  selectInstancesSelectedVolume,
  selectInstancesSelectedVolumeLoading,
  selectInstancesVolumesActionLoading,
} from "../../../../store/instances/instancesVolumesSlice";
import Text from "../../../../components/general/Text";
import Dropdown from "../../../general/Dropdown";
import QuestionModal, { DeleteModal } from "../../../modals/QuestionModal";
import {
  InstancesEditVolumeUrl,
  InstancesVolumesUrl,
} from "../../../../utils/urls";
import Card from "../../../general/Card";
import Loading from "../../../general/Loading";
import { getDBSPriceAmout } from "../../../../pages/databases/ServiceCreatePage";

const VolumeOverviewTab = () => {
  const [deleteInstanceVolume, setDeleteInstanceVolume] = useState<
    string | null
  >(null);
  const [deleteVolume, setDeleteVolume] = useState<string | null>(null);

  const { id } = useParams();

  const navigate = useNavigate();

  const selectedVolume = useAppSelector(selectInstancesSelectedVolume);
  const selectedVolumeLoading = useAppSelector(
    selectInstancesSelectedVolumeLoading
  );
  const selectedVolumeActionLoading = useAppSelector(
    selectInstancesVolumesActionLoading
  );

  const catalog = useAppSelector(selectDBSCatalog);
  const catalogLoading = useAppSelector(selectDBSCatalogLoading);

  const actionLoading = useAppSelector(selectInstancesVolumesActionLoading);

  // states
  const [addons, setAddons] = useState<Array<DBSAddon>>([]);

  const dispatch = useAppDispatch();

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(InstanceVolumeEditSchema),
    defaultValues: {
      capacity: 10,
      volumeName: "",
    },
  });

  const {
    setValue,
    reset,
    control,
    formState: { errors },
    watch,
  } = methods;

  // watch form values
  const capacity = watch("capacity");
  // const volumeName = watch("volumeName");

  //get selected volume
  useEffect(() => {
    if (selectedVolume === null && id !== undefined) {
      dispatch(getInstancesVolumeAsync({ id }));
    }
  }, [dispatch, id, selectedVolume]);

  useEffect(() => {
    if (id) {
      dispatch(getInstancesVolumeAsync({ id: id })).then((action) => {
        if (action && action.type === "instances/volume/rejected") {
          navigate(InstancesVolumesUrl);
        }
      });
    }
  }, [dispatch, id]);

  //get first data
  useEffect(() => {
    if (!catalog) {
      dispatch(getDBSCatalogAsync());
    }
    // dispatch(getRegionsAsync());
  }, [dispatch, reset]);

  useEffect(() => {
    if (catalog) {
      setAddons(catalog.addons.filter((a) => a.planCode.startsWith("volume.")));
    }
  }, [catalog]);

  useEffect(() => {
    if (selectedVolume) {
      setValue("capacity", selectedVolume.size);
      setValue("volumeName", selectedVolume.name);
    }
  }, [selectedVolume, setValue]);

  const selectedAddon = useMemo(() => {
    if (selectedVolume) {
      return addons.find(
        (a) => a.planCode === `volume.${selectedVolume.type}.consumption`
      );
    }
  }, [addons, selectedVolume]);

  const estimatedPrice = useMemo(() => {
    if (selectedAddon) {
      const unitPrice = selectedAddon.pricings[0].price;
      return unitPrice * 24 * 30 * capacity;
    }
    return 0;
  }, [capacity, selectedAddon]);

  return (
    <>
      {selectedVolume && !selectedVolumeLoading && (
        <>
          <div className="flex justify-between items-center gap-3 p-3 border rounded-md dark:bg-dark-3 border border-slate-200 dark:border-dark-2 mb-5">
            <Text type="title" className="text-sm">
              {selectedVolume.name}
            </Text>

            <div className=" flex  items-center ">
              <div className="mr-2">
                {" "}
                <Button
                  size="medium"
                  type="light"
                  buttonProps={{
                    onClick: () =>
                      dispatch(
                        getInstancesVolumeAsync({ id: selectedVolume.id })
                      ),
                  }}
                  buttonClassName="group"
                  tooltip="Refresh"
                >
                  <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
                </Button>
              </div>

              {selectedVolume && (
                <Dropdown
                  positionClassName="!w-50 max-h-48 overflow-y-auto"
                  menu={[
                    {
                      key: "1",
                      label: (
                        <div className="flex items-center gap-1">
                          <EditIcon className="w-4" />
                          Edit volume
                        </div>
                      ),
                      onClick: (_, close) => {
                        close();
                        dispatch(
                          handleSetSelectedInstanceVolume(selectedVolume)
                        );
                        navigate(InstancesEditVolumeUrl(selectedVolume.id));
                      },
                    },

                    { type: "divider" as const },
                    {
                      key: "end",
                      label: (
                        <div className="flex items-center gap-1">
                          {" "}
                          <ArchiveIcon className="w-4" />
                          Delete
                        </div>
                      ),
                      danger: true,
                      onClick: (_, close) => {
                        close();
                        setDeleteVolume(selectedVolume.id);
                      },
                      disabled: selectedVolumeLoading,
                    },
                  ]}
                  type="simple"
                >
                  <Button>Actions</Button>
                </Dropdown>
              )}
            </div>
          </div>

          <div className="mt-5">
            <Card title="Information">
              <div className="mt-2 grid gap-4 md:grid-cols-2">
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Text type="title" className="text-sm">
                        Status
                      </Text>

                      <Text
                        className="text-xs inline-block"
                        type={
                          selectedVolume.status === "available"
                            ? "success-alert"
                            : "warning-alert"
                        }
                      >
                        {selectedVolume.status}
                      </Text>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Text type="title" className="text-sm">
                        region
                      </Text>
                      <Text className="text-sm">{selectedVolume.region}</Text>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Text type="title" className="text-sm">
                        Volume size
                      </Text>
                      <Text className="text-sm">{selectedVolume.size}</Text>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Text type="title" className="text-sm">
                        Estimated amount
                      </Text>
                      <Text className="text-sm">{`€${getDBSPriceAmout(
                        estimatedPrice
                      )} ex. VAT/month`}</Text>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mb-4 md:mb-0 w-full">
                      <Text type="title" className="text-sm">
                        Volume type
                      </Text>
                      <Text className="text-sm">{selectedVolume.type}</Text>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>

          <DeleteModal
            title="Delete a Volume"
            isOpen={deleteVolume !== null}
            onClose={() => setDeleteVolume(null)}
            description="To confirm, type 'DELETE' in the box below"
            confirmButtonText="Confirm"
            confirmButtonType="danger"
            confirmButtonClassName="w-full"
            loading={actionLoading}
            onConfirm={() => {
              deleteVolume &&
                dispatch(
                  deleteInstancesVolumesAsync({ id: deleteVolume })
                ).then((action) => {
                  if (action.type === "instances/volumes/delete/fulfilled") {
                    dispatch(getInstancesVolumesAsync({}));
                    navigate(InstancesVolumesUrl);
                  }
                  setDeleteVolume(null);
                });
            }}
            confirmString={selectedVolume.name}
          />
          {(selectedVolumeLoading || catalogLoading) && (
            <Loading borderSize={2} className="!min-h-[300px]" />
          )}
        </>
      )}
    </>
  );
};

export default VolumeOverviewTab;
