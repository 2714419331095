import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Button from "../buttons/Button";
import Input from "../inputs/Input";
import { ForgotPasswordUrl, HomeUrl, SignUpUrl } from "../../utils/urls";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SignInSchema } from "../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getMeAsync,
  loginAsync,
  selectLoginLoading,
  selectUserLoading,
  selectValidate2FAloading,
  validateTwoFactorAsync,
} from "../../store/auth/authSlice";
import Loading from "../general/Loading";
import Text from "../general/Text";
import { ChangeEvent, useCallback, useState } from "react";
import { LoginUser } from "../../types/auth";
import { AnimatePresence, motion } from "framer-motion";

const SignIn = () => {
  const dispatch = useAppDispatch();
  const loginLoading = useAppSelector(selectLoginLoading);
  const validateLoading = useAppSelector(selectValidate2FAloading);
  const userLoading = useAppSelector(selectUserLoading);
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const returnUrl = query.get("return-url");

  const [needOTP, setNeedOTP] = useState(false);
  const [codeError, setCodeError] = useState<boolean>(false);
  const [code, setCode] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(SignInSchema),
  });

  const onSubmit = (data: any) => {
    dispatch(loginAsync(data)).then((action) => {
      if (action.type === "auth/login/fulfilled") {
        const { login } = action.payload as { login: LoginUser };
        if (login.TwoFactorEnabled) {
          setNeedOTP(true);
        } else {
          callGetMe();
        }
      }
    });
  };

  const callGetMe = useCallback(() => {
    dispatch(getMeAsync({})).then((action) => {
      if (action.type === "auth/me/fulfilled") {
        navigate(returnUrl || HomeUrl);
      }
    });
  }, [dispatch, navigate, returnUrl]);

  const callValidateCode = useCallback(() => {
    if (typeof code === "undefined" || code === "") {
      setCodeError(true);
    } else {
      const loginData = getValues();
      dispatch(
        validateTwoFactorAsync({ AuthenticationCode: code, ...loginData })
      ).then((action) => {
        if (action.type === "auth/validate-2fa/fulfilled") {
          callGetMe();
        }
      });
    }
  }, [callGetMe, code, dispatch, getValues]);

  return (
    <>
      <Text
        type="title"
        className="mb-7 text-center"
        value={`Sign in to your account`}
      />
      <AnimatePresence>
        {!userLoading && !needOTP && (
          <motion.form
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="gap-1 flex flex-col">
              <div className="gap-5 flex flex-col">
                <Input
                  inputProps={{
                    ...register("Email"),
                    className: "mb-4",
                  }}
                  label="Email address"
                  required
                  placeholder="your@email.com"
                  error={errors.Email?.message}
                />
                <Input
                  label="Password"
                  inputProps={{
                    type: "password",
                    ...register("Password"),
                  }}
                  required
                  placeholder="●●●●●●"
                  error={errors.Password?.message}
                />
              </div>
              <span>
                <Link
                  to={ForgotPasswordUrl}
                  className="text-sm text-primary-300 hover:text-primary-400"
                >
                  Forgot your password?
                </Link>
              </span>
              <div className="flex flex-col mt-4">
                <Button
                  type="primary"
                  buttonProps={{ type: "submit", disabled: loginLoading }}
                  buttonClassName="!w-full"
                  loading={loginLoading}
                >
                  Sign in to your account
                </Button>
                <div className="flex gap-1 mt-2 justify-center">
                  <span className="text-sm text-slate-800 dark:text-slate-50">
                    Don't have an account?
                  </span>
                  <Link
                    to={SignUpUrl}
                    className="text-sm text-sky-500 hover:text-sky-600"
                  >
                    Sign up
                  </Link>
                </div>
              </div>
            </div>
          </motion.form>
        )}
        {!userLoading && needOTP && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="gap-1 flex flex-col"
          >
            <div className="gap-5 flex flex-col">
              <Text className="text-sm">
                Please enter the code from your authenticator app. You can also
                enter one of your backup codes.
              </Text>
              <Input
                inputProps={{
                  className: "mb-4",
                  onChange: (e: ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value;
                    setCodeError(value === "");
                    setCode(value);
                  },
                }}
                label="Code"
                required
                placeholder="000000"
                error={codeError ? "Code is a required" : undefined}
              />
            </div>
            <div className="flex flex-col mt-4">
              <Button
                type="primary"
                buttonProps={{
                  disabled: loginLoading,
                  onClick: callValidateCode,
                }}
                buttonClassName="!w-full"
                loading={validateLoading}
              >
                Sign in
              </Button>
            </div>
          </motion.div>
        )}
        {userLoading && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Loading borderSize={2} style={{ minHeight: 150 }} />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
export default SignIn;
