import { PropsWithChildren } from "react";
import Text from "./Text";
import classNames from "classnames";
import { uuid } from "../../utils/uuid";

type CardProps = {
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  className?: string;
  setting?: React.ReactNode;
};

const Card: React.FunctionComponent<PropsWithChildren<CardProps>> = ({
  children,
  title,
  description,
  className,
  setting,
}) => {
  return (
    <div
      id={title?.toString() || uuid()}
      className={classNames(
        "flex flex-col w-full rounded-xl dark:bg-dark-3 border border-slate-200 dark:border-dark-2 p-6 pt-4",
        { [className || ""]: className }
      )}
    >
      {(title || description || setting) && (
        <div className="w-full pb-3 border-b border-slate-200 dark:border-dark-2 mb-3">
          <div className="w-full flex items-start justify-between">
            {title ? (
              <Text className="font-medium">{title}</Text>
            ) : (
              <Text type="subtext" className="text-sm">
                {description}
              </Text>
            )}
            {setting}
          </div>
          {title && description && (
            <Text type="subtext" className="text-sm">
              {description}
            </Text>
          )}
        </div>
      )}
      {children}
    </div>
  );
};

export default Card;
