import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Dropdown from "../../general/Dropdown";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right.svg";
import { ReactComponent as DetailsIcon } from "./../../../assets/icons/bars-3-bottom-left.svg";
import QuestionModal, { DeleteModal } from "../../modals/QuestionModal";
import { BlockEvent } from "../../../types/block-events";
import {
  getBlockEventsAsync,
  deleteBlockEventAsync,
  selectBlockEventsActionLoading,
  selectBlockEventsLoading,
  selectBlockEvents,
  setBlockEvent,
} from "../../../store/block-event/blockEventsSlice";
import Text from "../../general/Text";
import Button from "../../buttons/Button";
import { BlockEventCreateUrl, BlockEventUrl } from "../../../utils/urls";
import { useNavigate } from "react-router-dom";
import Highlighter from "react-highlight-words";
import { useSearch } from "../../../providers/SearchProvider";
import NotData from "../../layouts/NotData";
import { Helmet } from "react-helmet";
import { timeAgo } from "../../../utils/date";
import { capitalizeFirstLetter } from "../../../utils";

const BlockEventNotificationsTab = () => {
  const blockEvents = useAppSelector(selectBlockEvents);
  const loading = useAppSelector(selectBlockEventsLoading);
  const [filteredEvents, setFilteredEvents] = useState<BlockEvent[]>([]);
  const actionLoading = useAppSelector(selectBlockEventsActionLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { value: searchValue } = useSearch();
  const [deleteBlockEvent, setDeleteBlockEvent] = useState<BlockEvent | null>(
    null
  );
  useEffect(() => {
    // if (blockEvents.length === 0) {
    dispatch(getBlockEventsAsync());
    // }
  }, [dispatch]);

  useEffect(() => {
    const lookedUp = blockEvents.filter((event) =>
      event.Name?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredEvents(lookedUp);
  }, [dispatch, searchValue, blockEvents]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Block Events</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="flex transition-all duration-500 items-center justify-between gap-3">
        <Text className="whitespace-nowrap font-medium"></Text>
        <div className="flex items-center justify-end gap-1 md:gap-2 ">
          <Button
            type="primary"
            buttonProps={{
              onClick: () => navigate(BlockEventCreateUrl),
            }}
            buttonClassName="group"
          >
            Create Event
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>
      <div className="mt-5 w-full">
        <Table loading={loading}>
          <TableHead>
            <TableRow>
              <TableTH lable="Name" />
              <TableTH lable="Network" />
              <TableTH lable="Destinations" />
              <TableTH lable="Last delivery" />
              <TableTH lable="Status" />
              <TableTH lable="" />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredEvents.map((event, index) => {
              const network = event.Network.split("-");
              return (
                <TableRow
                  key={index}
                  onClick={() => {
                    dispatch(setBlockEvent(event));
                    navigate(BlockEventUrl(event.BlockEventId));
                  }}
                  className="cursor-pointer"
                >
                  <TableTD className="w-36">
                    <Highlighter
                      highlightClassName="bg-primary-100 dark:bg-primary-300 rounded-sm"
                      className="whitespace-nowrap max-w-[110px] md:max-w-none text-ellipsis overflow-hidden text-sm font-medium"
                      searchWords={[searchValue]}
                      autoEscape={true}
                      textToHighlight={event.Name}
                    />
                  </TableTD>
                  <TableTD>
                    <Text className="text-sm">{`${capitalizeFirstLetter(
                      network[0]
                    )} ${capitalizeFirstLetter(network[1])}`}</Text>
                  </TableTD>
                  <TableTD>
                    <Text className="text-sm">{event.Destinations.length}</Text>
                  </TableTD>
                  <TableTD>
                    <Text className="text-sm">{timeAgo(event.Updated_at)}</Text>
                  </TableTD>
                  <TableTD>
                    {event.Enabled ? (
                      <Text type="success-alert" className="text-sm">
                        Enable
                      </Text>
                    ) : (
                      <Text type="warning-alert" className="text-sm">
                        Disable
                      </Text>
                    )}
                  </TableTD>
                  <TableTD className="w-10">
                    <div className="h-full w-full inline-flex items-center justify-end gap-1 px-4">
                      <div className="w-8 flex justify-center items-center">
                        <Dropdown
                          positionClassName="!w-40"
                          menu={[
                            {
                              key: "details",
                              label: (
                                <div className="flex items-center gap-1">
                                  <DetailsIcon className="w-4" />
                                  Get details
                                </div>
                              ),
                              onClick: () => {
                                dispatch(setBlockEvent(event));
                                navigate(BlockEventUrl(event.BlockEventId));
                              },

                              disabled: loading,
                            },
                            {
                              type: "divider",
                            },
                            {
                              key: "end",
                              label: (
                                <div className="flex items-center gap-1">
                                  <ArchiveIcon className="w-4" />
                                  Delete
                                </div>
                              ),
                              danger: true,
                              onClick: () => {
                                setDeleteBlockEvent(event);
                              },
                            },
                          ]}
                          type="simple"
                        >
                          <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                            <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                          </div>
                        </Dropdown>
                      </div>
                      <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" />
                    </div>
                  </TableTD>
                </TableRow>
              );
            })}
            {blockEvents.length === 0 && (
              <TableRow withoutHoverStyle={true}>
                <TableTD colSpan={6} className="!border-0">
                  <NotData text="You have not created any block event yet" />
                </TableTD>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      <DeleteModal
        title="Delete Block Event"
        isOpen={deleteBlockEvent !== null}
        onClose={() => setDeleteBlockEvent(null)}
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        onConfirm={() => {
          deleteBlockEvent &&
            dispatch(
              deleteBlockEventAsync({
                blockEventId: deleteBlockEvent.BlockEventId,
              })
            ).then((action) => {
              if (action.type === "block-events/delete/fulfilled") {
                dispatch(getBlockEventsAsync());
              }
              setDeleteBlockEvent(null);
            });
        }}
        loading={actionLoading}
        confirmString={deleteBlockEvent?.Name}
      />
    </>
  );
};

export default BlockEventNotificationsTab;
