import JsonView from "@uiw/react-json-view";
import { lightTheme } from "@uiw/react-json-view/light";
import { darkTheme } from "@uiw/react-json-view/dark";
import useDarkMode from "../../hooks/useDarkMode";

const JsonViewer: React.FunctionComponent<{
  value?: object | null;
  collapsed?: number | boolean;
}> = ({ value, collapsed }) => {
  const { mode } = useDarkMode();
  return (
    <JsonView
      style={mode === "dark" ? darkTheme : lightTheme}
      value={value || {}}
      enableClipboard={false}
      displayDataTypes={false}
      shortenTextAfterLength={20}
      collapsed={collapsed !== undefined ? collapsed : 2}
    />
  );
};

export default JsonViewer;
