import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import useDarkMode from "./hooks/useDarkMode";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import { useAppDispatch } from "./hooks";
import { syncState } from "./store/auth/authSlice";
import { ToastClasses } from "./components/modals/alerts";
// import {
//   Experimental_CssVarsProvider as CssVarsProvider,
//   experimental_extendTheme as extendTheme,
// } from "@mui/material/styles";
// const theme = extendTheme({
//   components: {
//     MuiTextField: {
//       styleOverrides: {
//         root: () => ({ borderRadius: 12 }),
//       },
//     },
//   },
// });
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK as string);

function App() {
  const dispatch = useAppDispatch();
  useDarkMode();
  // const options = {
  //   clientSecret:
  //     "seti_1OBZP2H1htd4Va0UuyfzBb17_secret_OzYWnpZtwebHyUXR26QKenKfzfyQYek",
  // };

  useEffect(() => {
    dispatch(syncState());
  }, [dispatch]);

  return (
    <>
      {/* <CssVarsProvider theme={theme}> */}
      {/* <Elements stripe={stripePromise}> */}
      <RouterProvider router={router} />
      {/* </Elements> */}
      {/* </CssVarsProvider> */}
      <Toaster
        toastOptions={{
          className: ToastClasses,
        }}
      />
    </>
  );
}

export default App;
