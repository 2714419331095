import { createPortal } from "react-dom";
import PropTypes from "prop-types";

import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import ReactJson from "react-json-view";

// utils
import { copyToClipboard } from "./../../../../utils/wfHelper";
import useDarkMode from "../../../../hooks/useDarkMode";
import Text from "../../../general/Text";

const ExpandDataDialog = ({
  show,
  dialogProps,
  onCancel,
  onCopyClick,
  enableClipboard,
}) => {
  const portalElement = document.getElementById("portal");
  const mode = useDarkMode();

  const component = show ? (
    <Dialog
      open={show}
      fullWidth
      maxWidth="md"
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        className:
          "!border-0 dark:!bg-dark-3 dark:!border-2 dark:!border-dark-2 !shadow-xl !transition-all !rounded-2xl",
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {dialogProps.title}
        <Text>{dialogProps.title}</Text>
      </DialogTitle>
      <DialogContent>
        {!enableClipboard && (
          <ReactJson
            theme={mode === "dark" ? "ocean" : "rjv-default"}
            style={{ padding: 10, borderRadius: 10 }}
            src={dialogProps.data}
            enableClipboard={(e) => copyToClipboard(e)}
          />
        )}
        {enableClipboard && (
          <ReactJson
            theme={mode === "dark" ? "ocean" : "rjv-default"}
            style={{ padding: 10, borderRadius: 10 }}
            src={dialogProps.data}
            enableClipboard={(e) => onCopyClick(e, dialogProps.node)}
          />
        )}
      </DialogContent>
    </Dialog>
  ) : null;

  return createPortal(component, portalElement);
};

ExpandDataDialog.propTypes = {
  show: PropTypes.bool,
  dialogProps: PropTypes.object,
  onCancel: PropTypes.func,
  onCopyClick: PropTypes.func,
  enableClipboard: PropTypes.bool,
};

export default ExpandDataDialog;
