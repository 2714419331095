import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getIpfsApiKeysAysnc,
  selectIpfsApiKeys,
  selectIPFSLoading,
  handleShowEditorModal,
  deleteIpfsApiKeyAsync,
  selectIPFSApiKeysActionLoading,
} from "../../../store/ipfs/apiKeysSlice";
import Button from "../../buttons/Button";
import Dropdown from "../../general/Dropdown";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import NotData, { EmptyList, IpfsNotActiveTab } from "../../layouts/NotData";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil-square.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { HiddenKey } from "../../general/HiddenKey";
import QuestionModal, { DeleteModal } from "../../modals/QuestionModal";
import Highlighter from "react-highlight-words";
import { useSearch } from "../../../providers/SearchProvider";
import { IpfsApiKey } from "../../../types/ipfs-api-key";
import { selectIpfsStatus } from "../../../store/ipfs/ipfsPublicSlice";
import Text from "../../general/Text";
import ApiKeyEditorModal from "../api_keys/ApiKeyEditorModal";
import {
  selectIpfsGateways,
  getIpfsGatewaysAysnc,
  deleteIpfsGatewayAsync,
  createIpfsGatewayAsync,
  selectIPFSGatewayActionLoading,
} from "../../../store/ipfs/gatewaysSlice";
import CopyableKey from "../../general/CopyableKey";
import { selectOnStageEnv } from "../../../store/auth/authSlice";
import Card from "../../general/Card";
import { AddressItem } from "../../rpc-endpoints/security/DataRows";
import AddressInput from "../../inputs/AddressInput";
import { domainNameRegex, ipv4Regex } from "../../../utils/regex";

const IpfsSettingsTab = () => {
  const ipfsStatus = useAppSelector(selectIpfsStatus);

  //api keys
  const [deleteApiKeyId, setDeleteApiKeyId] = useState<number | null>(null);
  const apiKeys = useAppSelector(selectIpfsApiKeys);
  const [filteredApiKeys, setFilteredApiKeys] = useState<IpfsApiKey[]>([]);
  const loading = useAppSelector(selectIPFSLoading);
  const actionLoading = useAppSelector(selectIPFSApiKeysActionLoading);

  //Gateways -> whitelist
  const gateways = useAppSelector(selectIpfsGateways);
  // const [filteredGateways, setFilteredGateways] = useState<IpfsGateway[]>([]);
  const whitelistActionLoading = useAppSelector(selectIPFSGatewayActionLoading);
  const [deleteWhitelist, setDeleteWhitelist] = useState<null | number>(null);

  const ipfsReferrers = useMemo(() => {
    return gateways.filter((gw) => gw.WhiteListType === 0);
  }, [gateways]);

  const ipfsSources = useMemo(() => {
    return gateways.filter((gw) => gw.WhiteListType === 1);
  }, [gateways]);

  const env = useAppSelector(selectOnStageEnv);

  const dispatch = useAppDispatch();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    dispatch(getIpfsApiKeysAysnc());
    dispatch(getIpfsGatewaysAysnc());
  }, [dispatch]);

  useEffect(() => {
    const lookedUpFiles = apiKeys.filter((apiKey) =>
      apiKey.Label?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredApiKeys(lookedUpFiles);
  }, [dispatch, searchValue, apiKeys]);

  // useEffect(() => {
  //   const lookedUpFiles = gateways.filter((gateway) =>
  //     gateway.WhiteList?.toLowerCase().includes(searchValue.toLowerCase())
  //   );
  //   setFilteredGateways(lookedUpFiles);
  // }, [dispatch, searchValue, gateways]);

  const handleCreateWhitelist = (value: string, type: number) => {
    dispatch(
      createIpfsGatewayAsync({
        WhiteList: value,
        WhiteListType: type,
      })
    ).then((action) => {
      if (action.type === "ipfs/gateways/create/fulfilled") {
        dispatch(getIpfsGatewaysAysnc());
      }
    });
  };

  const handleDeleteGateway = () => {
    deleteWhitelist &&
      dispatch(deleteIpfsGatewayAsync({ id: deleteWhitelist })).then(
        (action) => {
          if (action.type === "ipfs/gateways/delete/fulfilled") {
            dispatch(getIpfsGatewaysAysnc());
          }
          setDeleteWhitelist(null);
        }
      );
  };

  if (!ipfsStatus) {
    return <IpfsNotActiveTab />;
  }

  return (
    <>
      <div className="mb-10">
        <div className="flex transition-all duration-500 items-center justify-between">
          <Text className="whitespace-nowrap font-medium">Access keys</Text>
          <Button
            type="primary"
            // size="small"
            buttonProps={{
              onClick: () => dispatch(handleShowEditorModal({})),
            }}
            // startIcon={<PlusIcon className="w-4 h-4" />}
            contentClassName="group"
          >
            Create New
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>

        <Card
          title="API keys"
          description="Use api-keys to retrieve and serve your content with speed and
          reliability"
          className="mt-4"
          // setting={}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center text-sm gap-2 flex-1 mb-3">
              <Text>API:</Text>
              <CopyableKey text={env?.IpfsAddress} />
            </div>
          </div>
          <Table loading={loading} className="mt-6">
            <TableHead>
              <TableRow>
                <TableTH lable="Label" />
                <TableTH lable="Value" />
                <TableTH lable="" />
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredApiKeys.map((apiKey, index) => (
                <TableRow key={index}>
                  <TableTD className="w-36">
                    <Highlighter
                      highlightClassName="bg-primary-100 dark:bg-primary-300 rounded-sm"
                      className="whitespace-nowrap max-w-[110px] md:max-w-none text-ellipsis overflow-hidden text-sm font-medium"
                      searchWords={[searchValue]}
                      autoEscape={true}
                      textToHighlight={apiKey.Label}
                    />
                  </TableTD>
                  <TableTD>
                    <div className="text-sm dark:text-slate-200">
                      <HiddenKey text={apiKey.KeyValue} />
                    </div>
                  </TableTD>
                  <TableTD className="w-10">
                    <div className="w-8 flex justify-center items-center">
                      <Dropdown
                        positionClassName="!w-40"
                        menu={[
                          {
                            key: "1",
                            label: (
                              <div className="flex items-center gap-1">
                                <EditIcon className="w-4" />
                                Edit
                              </div>
                            ),
                            onClick: (_, close) => {
                              close();
                              dispatch(handleShowEditorModal({ apiKey }));
                            },
                          },
                          {
                            type: "divider",
                          },
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: (_, close) => {
                              close();
                              setDeleteApiKeyId(apiKey.Id);
                            },
                          },
                        ]}
                        type="simple"
                      >
                        <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                          <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                        </div>
                      </Dropdown>
                    </div>
                  </TableTD>
                </TableRow>
              ))}
              {apiKeys.length === 0 && (
                <TableRow withoutHoverStyle={true}>
                  <TableTD colSpan={3} className="!border-0">
                    <NotData text="You have not created any API key yet" />
                  </TableTD>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Card>
        <ApiKeyEditorModal />
        <DeleteModal
          title="Delete API"
          confirmButtonText="Confirm"
          confirmButtonType="danger"
          confirmButtonClassName="w-full"
          isOpen={deleteApiKeyId !== null}
          onClose={() => setDeleteApiKeyId(null)}
          onConfirm={() => {
            deleteApiKeyId &&
              dispatch(deleteIpfsApiKeyAsync({ id: deleteApiKeyId })).then(
                (action) => {
                  if (action.type === "ipfs/api-keys/delete/fulfilled") {
                    dispatch(getIpfsApiKeysAysnc());
                  }
                  setDeleteApiKeyId(null);
                }
              );
          }}
          loading={actionLoading}
          // confirmString={
          //   apiKeys.find((api) => api.Id === deleteApiKeyId)?.Label
          // }
        />
      </div>

      {/* Gate ways */}
      {/* <div className="flex transition-all duration-500 mt-10">
        <Text>Gateways</Text>
        <div className="ml-auto flex items-center justify-end gap-1 md:gap-2">
          <Button
            type="primary"
            buttonProps={{
              onClick: () => dispatch(handleShowEditorModalGateway({})),
            }}
          >
            Create New
          </Button>
        </div>
      </div> */}

      <div>
        <Text className="whitespace-nowrap font-medium">Whitelists</Text>
        <Card
          className="mb-6 mt-4"
          title="Referrer"
          description="You can set up the host names which you wish to whitelist for
        access to your endpoint. If a host name is not on the whitelist,
        it will be denied access. Using a wildcard character (*) is only
        supported at the beginning of the domain."
        >
          <div className="mt-2">
            <div className="border rounded-lg dark:border-dark-2 dark:bg-dark-3 bg-white divide-y dark:divide-dark-2">
              {gateways.length === 0 && (
                <EmptyList text="No referrers added yet" />
              )}
              {ipfsReferrers.map((gateway, i) => (
                <AddressItem
                  key={i}
                  value={
                    <div className="flex items-center gap-2">
                      <Text type="subtext" className="w-[120px]">
                        Domain Name
                      </Text>
                      <CopyableKey text={gateway.WhiteList} />
                    </div>
                  }
                  onDelete={() => setDeleteWhitelist(gateway.Id)}
                />
              ))}
            </div>
          </div>
          <div className="flex items-start gap-2">
            <AddressInput
              placeholder="Example: djuno.io"
              loading={whitelistActionLoading}
              onAdd={(value: string) => handleCreateWhitelist(value, 0)}
              regex={domainNameRegex}
              errorMessage={`please enter a valid domain name
            `}
            />
          </div>
        </Card>

        <Card
          className="mb-6 mt-8"
          title="Source IP"
          description="You can add  IP addresses that you wish to whitelist for access to your endpoint. If an IP address is not on the whitelist, it will be denied access."
        >
          <div className="mt-2">
            <div className="border rounded-lg dark:border-dark-2 dark:bg-dark-3 bg-white divide-y dark:divide-dark-2">
              {gateways.length === 0 && (
                <EmptyList text="No referrers added yet" />
              )}
              {ipfsSources.map((gateway, i) => (
                <AddressItem
                  key={i}
                  value={
                    <div className="flex items-center gap-2">
                      <Text type="subtext" className="w-[120px]">
                        IP Address
                      </Text>
                      <CopyableKey text={gateway.WhiteList} />
                    </div>
                  }
                  onDelete={() => setDeleteWhitelist(gateway.Id)}
                />
              ))}
            </div>
          </div>
          <div className="flex items-start gap-2">
            <AddressInput
              placeholder="Example: 123.123.123.123"
              loading={whitelistActionLoading}
              onAdd={(value: string) => handleCreateWhitelist(value, 1)}
              regex={ipv4Regex}
              errorMessage={`please enter a valid IP address`}
            />
          </div>
        </Card>

        {/* <GatewayEditorModal /> */}
        <DeleteModal
          title="Delete Item"
          confirmButtonText="Confirm"
          confirmButtonType="danger"
          confirmButtonClassName="w-full"
          isOpen={deleteWhitelist !== null}
          onClose={() => setDeleteWhitelist(null)}
          onConfirm={handleDeleteGateway}
          loading={whitelistActionLoading}
          confirmString={
            gateways.find((gateway) => gateway.Id === deleteWhitelist)
              ?.WhiteList
          }
        />
      </div>
    </>
  );
};

export default IpfsSettingsTab;
