import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Modal from "../../modals/Modal";
import Button from "../../buttons/Button";
import { getInstanceAsync } from "../../../store/instances/instanceSlice";
import {
  getInstancesAsync,
  selectInstances,
  selectInstancesActionLoading,
} from "../../../store/instances/instancesSlice";
import {
  getInstancesVolumesAsync,
  handleInstanceDetachHideModal,
  instancesDetachVolumeAsync,
  selectInstancesSelectedVolume,
  selectInstancesVolumesActionLoading,
  selectInstancesVolumesLoading,
  selectShowInstanceDetachModal,
} from "../../../store/instances/instancesVolumesSlice";
import { useMemo } from "react";
import Text from "../../general/Text";

const InstanceDetachModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectShowInstanceDetachModal);
  const instances = useAppSelector(selectInstances);
  const volume = useAppSelector(selectInstancesSelectedVolume);
  const actionLoading = useAppSelector(selectInstancesVolumesActionLoading);

  const { handleSubmit, reset } = useForm({
    shouldUnregister: true,
    reValidateMode: "onChange",
  });

  // useEffect(() => {
  //   if (isOpen) {
  //     dispatch(getInstancesVolumesSnapshotAsync());
  //   }
  // }, [isOpen, dispatch]);

  const attachedInstance = useMemo(() => {
    return instances.find((instance) => instance.id === volume?.attachedTo[0]);
  }, [instances, volume?.attachedTo]);

  const onSubmit = (data: any) => {
    if (attachedInstance && volume) {
      const formData = { instanceId: attachedInstance.id };
      dispatch(
        instancesDetachVolumeAsync({
          volumeId: volume?.id,
          data: formData,
        })
      ).then((action) => {
        if (action.type === "instances/volume/detach/fulfilled") {
          dispatch(getInstancesAsync({ withoutLoading: false }));
          dispatch(getInstanceAsync({ instanceId: attachedInstance.id }));
          dispatch(getInstancesVolumesAsync({}));
          dispatch(handleInstanceDetachHideModal());
        }
      });
    }
  };

  const handleClose = () => {
    reset();
    dispatch(handleInstanceDetachHideModal());
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      contentClassName="max-w-lg"
      // containerClassName="!items-start"
      title="Detach volume from instance"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5">
          {" "}
          {instances.length > 0 && volume && (
            <Text className="text-sm pt-3 flex flex-row mt-5">
              Detach the {volume.name} volume from the {instances[0].name}{" "}
              instance
            </Text>
          )}
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button
            type="light"
            buttonProps={{
              onClick: handleClose,
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            buttonProps={{
              type: "submit",
            }}
            loading={actionLoading}
          >
            Confirm
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default InstanceDetachModal;
