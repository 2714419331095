import { useCallback, useEffect, useMemo, useState } from "react";
import RevolutCheckout, { Mode } from "@revolut/checkout";
import Button from "../../buttons/Button";
import { LoadingSpin } from "../../general/Loading";
import Text, { A } from "../../general/Text";
import { ReactComponent as RevolutIcon } from "./../../../assets/icons/revolut.svg";

const CardDetailsBox: React.FC<{
  beforInitFn: () => Promise<string | undefined>;
  afterInitFn: (orderToken: string) => Promise<void>;
  initLoading?: boolean;
  savingLoading?: boolean;
  username?: string;
}> = ({ beforInitFn, afterInitFn, initLoading, savingLoading, username }) => {
  console.log("username:", username);
  const [revolutSetupLoading, setRevolutSetupLoading] = useState(true);
  const [revolutPayLoading, setRevolutPayLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const name = useMemo(() => {
    if (!username) return "unknown user";
    const wordsCount = username.split(" ").length;
    if (wordsCount < 2) {
      return username + " *[djuno-cloud]";
    }
    return username;
  }, [username]);
  const handleInitiateRevolut = useCallback(() => {
    const cardFieldTarget = document.getElementById("card-field");
    const buttonTarget = document.getElementById("button-submit");

    //show card details inputs
    if (cardFieldTarget && buttonTarget) {
      try {
        setRevolutSetupLoading(true);
        beforInitFn().then((orderToken) => {
          console.log({ orderToken });
          if (orderToken) {
            const mode =
              (process.env.REACT_APP_REVOLUT_TYPE as Mode) || "sandbox";
            console.log(
              "REACT_APP_REVOLUT_TYPE:",
              process.env.REACT_APP_REVOLUT_TYPE
            );
            RevolutCheckout(orderToken, mode).then(function (instance) {
              setRevolutSetupLoading(false);
              const cardField = instance.createCardField({
                target: cardFieldTarget,
                onSuccess() {
                  console.log("onSuccess");
                  setRevolutPayLoading(false);
                  afterInitFn(orderToken);
                },
                onError(error) {
                  console.error("error");
                  console.log({ error });
                  setRevolutPayLoading(false);
                  setErrorMessage(`Something went wrong. ${error}`);
                },
                styles: {
                  default: {
                    color: "#858585",
                  },
                  autofilled: {
                    color: "#858585",
                  },
                },
              });

              buttonTarget.addEventListener("click", () => {
                setRevolutPayLoading(true);
                cardField.submit({
                  savePaymentMethodFor: "merchant",
                  name,
                });
              });
            });
          }
        });
      } catch (e) {
        console.log({ e });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleInitiateRevolut();
  }, [handleInitiateRevolut]);

  return (
    <>
      <div className="flex flex-col justify-center w-full min-h-[100px]">
        {initLoading && (
          <div className="w-full flex flex-col justify-center items-center h-full gap-2">
            <LoadingSpin borderSize={2} />
            <Text className="text-sm">Please wait...</Text>
          </div>
        )}

        {/* {!savingLoading && ( */}
        <div className="flex items-center w-full text-slate-800 dark:text-slate-200">
          <div className="w-full " id="card-field"></div>
        </div>
        {/* )} */}

        {errorMessage && (
          <div className="text-red-500 mt-5 text-sm">{errorMessage}</div>
        )}
      </div>
      <div className="mt-4 flex justify-between items-center">
        <div className="flex items-center gap-1">
          <Text className="text-xs" type="subtext">
            Powered by
          </Text>
          <A href="https://revolut.com" target="_blank">
            <RevolutIcon className="h-2.5 text-slate-600 dark:text-slate-400" />
          </A>
        </div>
        <Button
          loading={initLoading || revolutSetupLoading || revolutPayLoading}
          contentClassName="w-[40px]"
          type="primary"
          buttonProps={{
            id: "button-submit",
            disabled: savingLoading,
          }}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default CardDetailsBox;
