import classNames from "classnames";
import React, {
  AnchorHTMLAttributes,
  CSSProperties,
  PropsWithChildren,
} from "react";
import Highlighter from "react-highlight-words";

type TextProps = {
  value?: string | React.ReactNode;
  className?: string;
  style?: CSSProperties;
  type?:
    | "text"
    | "title"
    | "subtext"
    | "primary-alert"
    | "success-alert"
    | "warning-alert"
    | "neutral-alert"
    | "error-alert"
    | "transparent";
  loading?: boolean;
  disabled?: boolean;
};
const Text = (props: PropsWithChildren<TextProps>) => {
  const { children, value, className, type, loading, disabled, style } = props;
  return (
    <div
      style={style}
      className={classNames(className, {
        "text-slate-800 dark:text-slate-300":
          typeof type === "undefined" || type === "text",
        "text-slate-500 dark:text-gray-500": type === "subtext",
        "text-slate-900 dark:text-slate-200 font-semibold": type === "title",
        "text-slate-600 dark:text-slate-400 bg-slate-700/10 dark:bg-slate-600/30 rounded-md px-2 py-0.5 text-sm":
          type === "neutral-alert",
        "text-primary-500 bg-primary-50 dark:bg-primary-600/10 rounded-md px-2 py-0.5 text-sm":
          type === "primary-alert",
        "text-success bg-success/10 rounded-md px-2 py-0.5  text-sm":
          type === "success-alert",
        "text-yellow-600 bg-yellow-600/10 rounded-md px-2 py-0.5  text-sm":
          type === "warning-alert",
        "text-red-500 bg-red-600/10 rounded-md px-2 py-0.5  text-sm":
          type === "error-alert",
        "!text-slate-400 dark:!text-slate-500": disabled,
        "bg-slate-200 dark:bg-slate-50/10 animate-pulse rounded-md": loading,
      })}
    >
      {children || value}
    </div>
  );
};

export const ActionText = ({
  text,
  icon,
  onClick,
  type,
  className,
  loading,
}: {
  text?: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  type?: "default" | "simple";
  className?: string;
  loading?: boolean;
}) => {
  return (
    <div
      onClick={() => (onClick ? onClick() : {})}
      className={classNames(
        className,
        "text-xs font-semibold p-1 px-1.5 rounded-md flex items-center gap-1 justify-between transition-all duration-300",
        {
          "hover:bg-primary-100/50 hover:text-primary-500 dark:hover:bg-primary-400/20 transition-all duration-150 cursor-pointer":
            onClick,
          "bg-primary-100/30 text-primary-400 dark:bg-primary-100/10 ":
            type === undefined || type === "default",
          "bg-gray-100 text-gray-600 dark:bg-gray-400/10 dark:text-gray-300":
            type === "simple",
          "min-w-[90px] animate-pulse": loading,
        }
      )}
    >
      {text}
      {icon}
    </div>
  );
};

const A: React.FunctionComponent<AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <a
      {...props}
      className={classNames(
        "cursor-pointer text-sky-500 hover:text-sky-600",
        className
      )}
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

const HighlighterText: React.FC<{
  searchValue: string;
  textToHighlight: string;
  className?: string;
}> = ({ searchValue, textToHighlight, className }) => {
  return (
    <Highlighter
      highlightClassName="bg-primary-100 dark:bg-primary-300 rounded-sm"
      className={classNames(
        "whitespace-nowrap max-w-[110px] md:max-w-none text-ellipsis overflow-hidden text-sm font-medium",
        className
      )}
      searchWords={[searchValue]}
      autoEscape={true}
      textToHighlight={textToHighlight}
    />
  );
};
export { A, HighlighterText };
export default Text;
