import { Outlet } from "react-router-dom";
import Tabs from "../../components/general/Tabs";
import { TabOptions } from "../../types/tabs";
import {
  S3AccessKeysUrl,
  S3BucketsUrl,
  S3EventsUrl,
  S3LogsUrl,
  S3OverviewUrl,
  S3PlansUrl,
  S3TiersUrl,
} from "../../utils/urls";
import S3OverviewTab from "../../components/s3/overview/S3OverviewTab";
import S3bucketsTab from "../../components/s3/buckets/S3bucketsTab";
import S3AccessKeysTab from "../../components/s3/access-keys/S3AccessKeysTab";
import S3EventsTab from "../../components/s3/events/S3EventsTab";
import S3LogsTab from "../../components/s3/logs/S3LogsTab";
// import S3SettingsTab from "../../components/s3/settings/S3SettingsTab";

import { ReactComponent as ComputerIcon } from "./../../assets/icons/computer-desktop.svg";
import { ReactComponent as BucketIcon } from "./../../assets/icons/bucket.svg";
import { ReactComponent as KeyIcon } from "./../../assets/icons/key.svg";
import { ReactComponent as BellIcon } from "./../../assets/icons/bell.svg";
import { ReactComponent as BeatIcon } from "./../../assets/icons/beat.svg";
import { ReactComponent as TiersIcon } from "./../../assets/icons/square-3stack.svg";
import { ReactComponent as ListBulletIcon } from "./../../assets/icons/list-bullet.svg";
import S3TiersTab from "../../components/s3/tiers/S3TiersTab";
import Text from "../../components/general/Text";
import { Helmet } from "react-helmet";
import S3PlanesTab from "../../components/s3/plans/S3PlansTab";

export const S3PageMenus: TabOptions = [
  {
    label: (
      <div className="flex items-center gap-1">
        <ComputerIcon className="w-3" />
        Overview
      </div>
    ),
    url: S3OverviewUrl,
    element: <S3OverviewTab />,
    testId: "1th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <BucketIcon className="w-3" />
        Buckets
      </div>
    ),
    url: S3BucketsUrl,
    element: <S3bucketsTab />,
    testId: "2th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <KeyIcon className="w-3" />
        Access Keys
      </div>
    ),
    url: S3AccessKeysUrl,
    element: <S3AccessKeysTab />,
    testId: "3th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <BellIcon className="w-3" />
        Events
      </div>
    ),
    url: S3EventsUrl,
    element: <S3EventsTab />,
    testId: "4th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <BeatIcon className="w-3" />
        Logs
      </div>
    ),
    url: S3LogsUrl,
    element: <S3LogsTab />,
    testId: "5th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <TiersIcon className="w-3" />
        Tier
      </div>
    ),
    url: S3TiersUrl,

    element: <S3TiersTab />,
    testId: "6th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <ListBulletIcon className="w-3" />
        Plans
      </div>
    ),
    url: S3PlansUrl,
    element: <S3PlanesTab />,
    testId: "7th-tab",
  },
];

const S3Page = () => {
  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | S3</title>
        <meta
          name="description"
          content="Simple-storage technology refers to a straightforward method of storing data or information in a manner that is easy to implement and access"
        />
      </Helmet>
      <div className="flex items-center justify-between h-20">
        <div className="px-6 items-center justify-between flex flex-1 transition duration-150">
          <Text className="font-medium text-lg">S3</Text>
        </div>
        <div className="mr-6 w-96"></div>
      </div>
      <div className="mx-6">
        <Tabs
          useUrl
          options={S3PageMenus.map((o) => ({
            label: o.label,
            url: o.url,
            testId: o.testId,
          }))}
        />
        <div className="py-6">
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default S3Page;
