import { useAppSelector } from "../../../../hooks";
import {
  selectNodeSyscalls,
  selectNodeSyscallsLoading,
} from "../../../../store/s3/overviewSlice";
import GraphWidget from "./freams/GraphWidget";
import { S3WidgetType } from "../../../../types/s3";

const chartType: S3WidgetType = "nodeSyscalls";
const chartCode: number = 11;

const NodeSyscallsWidget = () => {
  const data = useAppSelector(selectNodeSyscalls);
  const loading = useAppSelector(selectNodeSyscallsLoading);

  return (
    <GraphWidget
      title="Node Syscalls"
      chartCode={chartCode}
      chartType={chartType}
      data={data}
      dataLoading={loading}
    />
  );
};

export default NodeSyscallsWidget;
