import Text from "../../../general/Text";
import { ReactComponent as BucketIcon } from "./../../../../assets/icons/bucket.svg";

const BucketsCountWidget: React.FunctionComponent<{
  bucketCount?: number;
  loading?: boolean;
}> = ({ bucketCount, loading }) => {
  return (
    <div className="w-full col-span-2 lg:col-span-1 rounded-lg border dark:border-dark-2 bg-white dark:bg-dark-3 p-5">
      <div className="flex flex-col w-full gap-3">
        <div className="flex justify-between items-center">
          <Text>Buckets</Text>
          <BucketIcon className="w-5 dark:text-slate-50" />
        </div>
        <div className="h-12">
          <Text type="title" className="text-5xl !h-12" loading={loading}>
            {bucketCount}
          </Text>
        </div>
      </div>
    </div>
  );
};
export default BucketsCountWidget;
