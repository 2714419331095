import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Button from "../../buttons/Button";
import Dropdown from "../../general/Dropdown";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import NotData from "../../layouts/NotData";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil-square.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as BackupIcon } from "./../../../assets/icons/arrow-up-on-square.svg";
import { ReactComponent as LinkIcon } from "./../../../assets/icons/link.svg";
import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import QuestionModal, { DeleteModal } from "../../modals/QuestionModal";
import { useSearch } from "../../../providers/SearchProvider";
import Text, { A, HighlighterText } from "../../general/Text";

import {
  deleteInstancesVolumesAsync,
  getInstancesVolumesAsync,
  handleInstanceAttachShowModal,
  handleInstanceDetachShowModal,
  handleSetSelectedInstanceVolume,
  selectInstancesVolumes,
  selectInstancesVolumesActionLoading,
  selectInstancesVolumesLoading,
} from "../../../store/instances/instancesVolumesSlice";
import { InstanceVolume } from "../../../types/instance";
import { InstancesSskKeyEditorModal } from "../instances/InstancesSskKeyEditorModal";
import { Link, useNavigate } from "react-router-dom";
import {
  InstanceOverviewUrl,
  InstancesCreateVolumeUrl,
  InstancesEditVolumeUrl,
  InstancesVolumeBackupUrl,
  InstancesVolumeOverviewUrl,
} from "../../../utils/urls";
import {
  selectInstances,
  selectInstancesLoading,
} from "../../../store/instances/instancesSlice";
import InstanceAttachModal from "./InstanceAttachModal";
import InstanceDetachModal from "./InstanceDetachModal";

const InstancesVolumesTab = () => {
  const navigate = useNavigate();

  const [deleteVolume, setDeleteVolume] = useState<string | null>(null);

  const volumes = useAppSelector(selectInstancesVolumes);
  const [filteredVolumes, setFilteredVolumes] = useState<InstanceVolume[]>([]);
  const loading = useAppSelector(selectInstancesVolumesLoading);
  const actionLoading = useAppSelector(selectInstancesVolumesActionLoading);

  const instances = useAppSelector(selectInstances);
  const instancesLoading = useAppSelector(selectInstancesLoading);

  const dispatch = useAppDispatch();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (volumes.length === 0) {
      dispatch(getInstancesVolumesAsync({}));
    }
  }, [dispatch]);

  useEffect(() => {
    const lookedUpApiKeys = volumes.filter((key) =>
      key.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredVolumes(lookedUpApiKeys);
  }, [dispatch, searchValue, volumes]);

  const getInstanceFromId = useCallback(
    (id: string) => {
      return instances.find((i) => i.id === id);
    },
    [instances]
  );

  return (
    <>
      <div className="flex transition-all duration-500">
        {/* <Text>Volumes</Text> */}
        <div className="ml-auto flex items-center justify-end gap-1 md:gap-2">
          <Button
            size="medium"
            type="light"
            buttonProps={{
              onClick: () => dispatch(getInstancesVolumesAsync({})),
            }}
            buttonClassName="group"
            tooltip="Refresh"
          >
            <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
          <Button
            type="primary"
            buttonProps={{
              onClick: () => navigate(InstancesCreateVolumeUrl),
            }}
            buttonClassName="group"
          >
            Create New
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>
      <div className="mt-6">
        <Table loading={loading}>
          <TableHead>
            <TableRow>
              <TableTH lable="Name/ID" />
              <TableTH lable="Region" />
              <TableTH lable="Type" />
              <TableTH lable="Capacity" />
              <TableTH lable="Instance attached" />
              <TableTH lable="Status" />
              <TableTH lable="" />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredVolumes.map((volume, index) => (
              <TableRow
                key={index}
                onClick={() => navigate(InstancesVolumeOverviewUrl(volume.id))}
              >
                <TableTD className="w-36">
                  <div className="flex flex-col gap-1">
                    <HighlighterText
                      searchValue={searchValue}
                      textToHighlight={volume.name}
                    />
                    <HighlighterText
                      searchValue={searchValue}
                      textToHighlight={volume.id}
                    />
                  </div>
                </TableTD>
                <TableTD>
                  <Text className="text-sm">{volume.region}</Text>
                </TableTD>
                <TableTD>
                  <Text className="text-sm whitespace-nowrap">
                    {volume.type}
                  </Text>
                </TableTD>
                <TableTD>
                  <Text className="text-sm">{`${volume.size.toString()} GB`}</Text>
                </TableTD>
                <TableTD>
                  <Text className="text-sm">
                    {volume.attachedTo.map((attached, idx) => {
                      return (
                        <A>
                          <Link key={idx} to={InstanceOverviewUrl(attached)}>
                            {getInstanceFromId(attached)?.name}
                          </Link>
                        </A>
                      );
                    })}
                  </Text>
                </TableTD>
                <TableTD>
                  <Text
                    className="text-sm whitespace-nowrap"
                    type={
                      volume.status === "available" ||
                      volume.status === "in-use"
                        ? "success-alert"
                        : volume.status === "error" ||
                          volume.status === "error_backing-up" ||
                          volume.status === "error_deleting" ||
                          volume.status === "error_extending" ||
                          volume.status === "error_restoring"
                        ? "error-alert"
                        : "primary-alert"
                    }
                  >
                    {volume.status}
                  </Text>
                </TableTD>
                <TableTD className="w-32 gap-1">
                  <div className="h-full w-full inline-flex items-center justify-center gap-1">
                    <div className="w-8 flex justify-center items-center">
                      <Dropdown
                        positionClassName="!w-49"
                        menu={[
                          {
                            key: "edit",
                            label: (
                              <div className="flex items-center gap-1">
                                <EditIcon className="w-4" />
                                Edit
                              </div>
                            ),
                            onClick: (_, close) => {
                              close();
                              dispatch(handleSetSelectedInstanceVolume(volume));
                              navigate(InstancesEditVolumeUrl(volume.id));
                            },
                          },
                          ...(volume.status === "available"
                            ? [
                                {
                                  key: "attach",
                                  label: (
                                    <div className="flex items-center gap-1">
                                      <LinkIcon className="w-4" />
                                      Attach to instance
                                    </div>
                                  ),
                                  onClick: () => {
                                    dispatch(
                                      handleInstanceAttachShowModal({
                                        selectedVolume: volume,
                                      })
                                    );
                                  },
                                },
                              ]
                            : []),

                          ...(volume.status === "in-use" ||
                          volume.attachedTo.length > 0
                            ? [
                                {
                                  key: "detach",
                                  label: (
                                    <div className="flex items-center gap-1">
                                      <ArchiveIcon className="w-4" />
                                      Detach from instance
                                    </div>
                                  ),
                                  onClick: () => {
                                    dispatch(
                                      handleInstanceDetachShowModal({
                                        selectedVolume: volume,
                                      })
                                    );
                                  },
                                },
                              ]
                            : []),
                          // {
                          //   key: "backup",
                          //   label: (
                          //     <div className="flex items-center gap-1">
                          //       <BackupIcon className="w-3" />
                          //       Create a backup
                          //     </div>
                          //   ),
                          //   onClick: (_, close) => {
                          //     close();
                          //     dispatch(handleSetSelectedInstanceVolume(volume));
                          //     navigate(InstancesVolumeBackupUrl(volume.id));
                          //   },
                          // },
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: (_, close) => {
                              close();
                              setDeleteVolume(volume.id);
                            },
                          },
                        ]}
                        type="simple"
                      >
                        <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                          <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                        </div>
                      </Dropdown>
                    </div>
                    <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" />
                  </div>
                </TableTD>
              </TableRow>
            ))}
            {volumes.length === 0 && (
              <TableRow withoutHoverStyle={true}>
                <TableTD colSpan={7} className="!border-0">
                  <NotData text="You have not created any Volume yet" />
                </TableTD>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <InstancesSskKeyEditorModal />
      <InstanceAttachModal />
      <InstanceDetachModal />
      <DeleteModal
        title="Delete a Volume"
        isOpen={deleteVolume !== null}
        onClose={() => setDeleteVolume(null)}
        description="To confirm, type 'DELETE' in the box below"
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        loading={actionLoading}
        onConfirm={() => {
          deleteVolume &&
            dispatch(deleteInstancesVolumesAsync({ id: deleteVolume })).then(
              (action) => {
                if (action.type === "instances/volumes/delete/fulfilled") {
                  dispatch(getInstancesVolumesAsync({}));
                }
                setDeleteVolume(null);
              }
            );
        }}
        confirmString={volumes.find((v) => v.id === deleteVolume)?.name}
      />
    </>
  );
};

export default InstancesVolumesTab;
