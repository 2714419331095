import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../../../buttons/Button";
import { S3TierGoogleCloudSchema } from "../../../../utils/validations";
import Input from "../../../inputs/Input";
import { useNavigate } from "react-router-dom";
import { S3TiersUrl } from "../../../../utils/urls";
import {
  createS3TierAsync,
  getS3TiersAsync,
  selectS3TierActionLoading,
} from "../../../../store/s3/tiersSlice";
import { convertFileToBase64 } from "../../../../utils/file";
import { useEffect } from "react";

const GoogleCloudForm = () => {
  const actionLoading = useAppSelector(selectS3TierActionLoading);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(S3TierGoogleCloudSchema),
  });

  useEffect(() => {
    setValue("endpoint", "https://storage.googleapis.com");
  });
  const onSubmit = async (data: any) => {
    const file = data.creds[0];
    const base64Data = await convertFileToBase64(file);
    const apiData = { ...data, creds: base64Data };
    dispatch(
      createS3TierAsync({
        type: "gcs",
        data: apiData,
      })
    ).then((action) => {
      if (action.type === "s3-tiers/create/fulfilled") {
        dispatch(getS3TiersAsync()).then((action) => {
          if (action.type === "s3-tiers/fulfilled") {
            reset();
            navigate(S3TiersUrl);
          }
        });
      }
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
      <div className="grid grid-cols-2 w-full gap-5">
        <Input
          label="Name"
          placeholder="Enter Name"
          inputProps={{
            ...register("name", {
              onChange: (e) => {
                const { value } = e.target;
                e.target.value = value.toUpperCase();
                return value.toUpperCase();
              },
            }),
          }}
          error={errors.name?.message}
          required
        />

        <Input
          label="Endpoint"
          placeholder="Endpoint"
          inputProps={{
            ...register("endpoint"),
          }}
          error={errors.endpoint?.message}
          required
        />

        <Input
          label="Credentials"
          placeholder=""
          inputProps={{
            ...register("creds"),
            type: "file",
            accept: ".json",
          }}
          error={errors.creds?.message}
          required
          hint="json file"
        />

        <Input
          label="Bucket"
          placeholder="ِEnter Bucket"
          inputProps={{ ...register("bucket") }}
          error={errors.bucket?.message}
          required
        />
        <Input
          label="Prefix"
          placeholder="ِEnter Prefix"
          inputProps={{ ...register("prefix") }}
          error={errors.prefix?.message}
          required
        />
        <Input
          label="Region"
          inputProps={{ ...register("region") }}
          error={errors.region?.message}
        />
      </div>
      <div className="mt-4 flex justify-end">
        <Button
          loading={actionLoading}
          type="primary"
          buttonProps={{
            disabled: actionLoading,
            type: "submit",
          }}
          buttonClassName="w-[200px]"
        >
          Save Tier Configuration
        </Button>
      </div>
    </form>
  );
};
export default GoogleCloudForm;
