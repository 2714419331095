import { MouseEvent, useMemo, useRef } from "react";
import { ReactComponent as CopyToClipboardIcon } from "./../../../assets/icons/copy.svg";
import { ReactComponent as ArrowDownCircleIcon } from "./../../../assets/icons/arrow-down-circle.svg";
import { ReactComponent as ArrowTopRightOnSquareIcon } from "./../../../assets/icons/arrow-top-right-on-square.svg";
import FileIcon, { extToType } from "./../../general/FileIcon";
import { humanizeSize } from "../../../utils/file";
import { IpfsFile } from "../../../types/ipfs-file";
import Highlighter from "react-highlight-words";
import Button from "../../buttons/Button";
import { useSearch } from "../../../providers/SearchProvider";
import { FileTypes } from "../../../types";
import Text from "../../general/Text";

export type FileProps = {
  name: string;
  size: number;
  file: IpfsFile;
  downloadLoading?: boolean;
  exportLoading?: boolean;
  onOpenFile?: (
    file: IpfsFile,
    e: MouseEvent<HTMLDivElement>,
    type: FileTypes | undefined
  ) => void;
  onDownload?: (file: IpfsFile) => void;
  onExport?: (file: IpfsFile) => void;
  onCopy?: (file: IpfsFile) => void;
};

function File({
  name,
  size,
  file,
  downloadLoading,
  exportLoading,
  onOpenFile,
  onDownload,
  onExport,
  onCopy,
}: FileProps) {
  const ref = useRef(null);

  const { value: searchValue } = useSearch();

  const file_ext = useMemo(() => {
    return (file.extension?.split(".")[1] ||
      file.name.split(".")[1] ||
      file.content_type?.split("/")[1] ||
      "folder") as FileTypes;
  }, [file.content_type, file.extension, file.name]);

  const fileType = useMemo(() => {
    return extToType(file_ext);
  }, [file_ext]);

  const fileSize = useMemo(() => {
    if (size !== 0) {
      return humanizeSize(Number(size), { fractionDigits: 2 }).join("B");
    }
    return "";
  }, [size]);

  return (
    <div
      className="grid grid-cols-5 hover:bg-[#f8fafc] dark:hover:bg-dark-2 text-[#475569] dark:text-slate-100 cursor-pointer col-span-full"
      ref={ref}
      onClick={(e) => (onOpenFile ? onOpenFile(file, e, fileType) : null)}
    >
      <div className="col-span-2 border-b flex items-center dark:border-dark-2 py-3 px-2 md:px-4 w-full text-ellipsis whitespace-nowrap overflow-hidden gap-1 col-name">
        <FileIcon type={fileType} />
        <Highlighter
          highlightClassName="bg-primary-100 dark:bg-primary-300 rounded-sm"
          className="whitespace-nowrap max-w-[110px] md:max-w-none text-ellipsis overflow-hidden text-sm font-medium"
          searchWords={[searchValue]}
          autoEscape={true}
          textToHighlight={name}
        />
      </div>
      <div className="col-span-2 border-b flex items-center dark:border-dark-2 py-3 px-2 md:px-4 w-full text-ellipsis whitespace-nowrap overflow-hidden col-size">
        <Text className="text-xs md:text-sm">{fileSize}</Text>
      </div>
      {/* <div className="border-b col-span-1 flex items-center dark:border-dark-2 py-3 px-2 md:px-4 w-full text-ellipsis whitespace-nowrap overflow-hidden col-lastModified">
        <Text className="text-xs md:text-sm">
          {createdAt && dateConvertor(createdAt)}
        </Text>
      </div> */}
      <div className=" col-span-1 border-b flex items-center justify-end dark:border-dark-2 py-3 px-2 md:px-4 w-full text-ellipsis whitespace-nowrap overflow-hidden col-action">
        <div className="flex gap-2">
          <Button
            type="icon"
            size="small"
            startIcon={
              <CopyToClipboardIcon className="w-4 h-4 md:w-5 md:h-5" />
            }
            buttonProps={{
              onClick: (e: any) => {
                e.stopPropagation();
                if (onCopy) {
                  onCopy(file);
                }
              },
              disabled: file.is_folder || file.public_cid === null,
            }}
            tooltip="copy link"
          />

          <Button
            type="icon"
            size="small"
            startIcon={
              <ArrowTopRightOnSquareIcon className="w-4 h-4 md:w-5 md:h-5" />
            }
            buttonProps={{
              onClick: (e: any) => {
                e.stopPropagation();
                if (onExport && !exportLoading) {
                  onExport(file);
                }
              },
            }}
            tooltip="Export"
            loading={exportLoading}
          />

          <Button
            type="icon"
            size="small"
            startIcon={
              <ArrowDownCircleIcon className="w-4 h-4 md:w-5 md:h-5" />
            }
            buttonProps={{
              onClick: (e: any) => {
                e.stopPropagation();
                if (onDownload && !downloadLoading) {
                  onDownload(file);
                }
              },
            }}
            tooltip="Download"
            loading={downloadLoading}
          />
        </div>
      </div>
    </div>
  );
}

export default File;
