import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
  AreaChart,
} from "recharts";
import {
  GraphChartCustomTooltipProps,
  S3MetricsWidget,
} from "../../../types/s3";
import { useMemo } from "react";
import {
  combineGraphLineData,
  getGraphLineColor,
  replaceRegendFormat,
} from "../../../utils/bucket";
import Text from "../Text";
import { formatTimestamp } from "../../../utils/date";
import { humanizeSize } from "../../../utils/file";

const GraphChart: React.FunctionComponent<{
  data: S3MetricsWidget | null;
  dateFormat?: string;
  type?: "line" | "area";
}> = ({ data, dateFormat, type = "line" }) => {
  const combineLineDataMemo = useMemo(() => {
    return () => combineGraphLineData(data, dateFormat);
  }, [data, dateFormat]);
  // console.log(combineLineDataMemo());

  const renderXAxis = () => {
    return (
      <XAxis
        dataKey="name"
        tick={{ fontSize: 10 }}
        interval={Math.ceil(combineLineDataMemo().length / 5)}
        // domain={[-1, 1]}
      />
    );
  };

  const renderYAxis = () => (
    <YAxis
      tick={{ fontSize: 10 }}
      tickFormatter={(value) =>
        humanizeSize(value, { fractionDigits: 1 }).join("B")
      }
    />
  );

  const renderChart = () => {
    if (type === "area") {
      return (
        <AreaChart
          data={combineLineDataMemo()}
          margin={{ top: 2, right: 15, bottom: 0, left: 0 }}
        >
          <defs>
            {data &&
              data.targets?.map((target, i) => {
                return target.result?.map((res, j) => {
                  const _key = i * (target.result?.length || 0) + j;
                  return (
                    <linearGradient
                      key={_key}
                      id={`area-color-${_key}`}
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="5%" stopColor="#70B8FF" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#70B8FF" stopOpacity={0} />
                    </linearGradient>
                  );
                });
              })}
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          {renderXAxis()}
          {renderYAxis()}
          <Tooltip content={<GraphCustomTooltip />} />
          {data &&
            data.targets?.map((target, i) => {
              return target.result?.map((res, j) => {
                const _key = i * (target.result?.length || 0) + j;
                return (
                  <Area
                    key={`area-${_key}`}
                    dataKey={replaceRegendFormat(
                      target.legendFormat,
                      res.metric
                    )}
                    type="monotone"
                    fill={`url(#area-color-${_key})`}
                    strokeWidth={5}
                    stroke="#1F8FFF"
                  />
                );
              });
            })}
        </AreaChart>
      );
    } else {
      return (
        <LineChart
          data={combineLineDataMemo()}
          margin={{ top: 2, right: 15, bottom: 0, left: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          {renderXAxis()}
          {renderYAxis()}
          <Tooltip content={<GraphCustomTooltip />} />
          {data &&
            data.targets?.map((target, i) => {
              return target.result?.map((res, j) => {
                const _key = i * (target.result?.length || 0) + j;
                return (
                  <Line
                    key={`line-${_key}`}
                    dataKey={replaceRegendFormat(
                      target.legendFormat,
                      res.metric
                    )}
                    type="monotone"
                    stroke={getGraphLineColor(_key)}
                    dot={false}
                    strokeWidth={2}
                  />
                );
              });
            })}
        </LineChart>
      );
    }
  };
  return <ResponsiveContainer>{renderChart()}</ResponsiveContainer>;
};

const GraphCustomTooltip = ({
  active,
  payload,
}: GraphChartCustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white/70 dark:bg-dark-3/80 text-xs p-2 border dark:border-dark-2 rounded-md max-h-40 overflow-y-auto">
        <div className="flex flex-col">
          <Text className="text-center " type="subtext">
            {formatTimestamp(payload[0].payload.timestamp).datetime}
          </Text>
          {payload.map((item, i) => (
            <div className="flex items-center gap-1" key={i}>
              <span
                className="w-2 h-2 rounded-full"
                style={{ backgroundColor: getGraphLineColor(i) }}
              />
              <Text className="label">{`${item.dataKey}: ${humanizeSize(
                item.payload[item.dataKey]
              ).join("B")}`}</Text>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

export const GraphChartMonitor: React.FunctionComponent<{
  data: S3MetricsWidget | null;
}> = ({ data }) => {
  return (
    <>
      {data?.targets?.map((target, i) =>
        target.result?.map((res, j) => (
          <div
            className="flex items-center gap-1"
            key={i * (target.result || []).length + j}
          >
            <span
              className="w-[8px] h-[8px]"
              style={{
                backgroundColor: getGraphLineColor(
                  i * (target.result || []).length + j
                ),
              }}
            />
            <Text className="text-[70%] overflow-hidden text-ellipsis">
              {replaceRegendFormat(target.legendFormat, res.metric)}
            </Text>
          </div>
        ))
      )}
    </>
  );
};
export default GraphChart;
