import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddCredentialSchema } from "../../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Modal from "../../modals/Modal";
import Input from "../../inputs/Input";
import Button from "../../buttons/Button";
import {
  addCredentialAsync,
  closeAddCredentialModal,
  getCredentialsAsync,
  getRegisteriesTypesAsync,
  selectAddCredentialModalIsOpen,
  selectCredentialAddLoading,
  selectRegisteriesTypes,
  selectRegisteriesTypesLoading,
} from "../../../store/settings/registeries/registeriesSlice";
import Select, { SelectOption } from "../../inputs/Select";
import Text, { A } from "../../general/Text";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getRegisteryData } from "./RegisteriesTab";

const AddCredentialModal = () => {
  const isOpen = useAppSelector(selectAddCredentialModalIsOpen);
  const loading = useAppSelector(selectCredentialAddLoading);
  const dispatch = useAppDispatch();

  const registeryTypes = useAppSelector(selectRegisteriesTypes);
  const registeryTypesLoading = useAppSelector(selectRegisteriesTypesLoading);

  const [selectedRegistry, setSelectedRegistry] = useState<SelectOption>();

  useEffect(() => {
    if (isOpen && registeryTypes.length === 0) {
      dispatch(getRegisteriesTypesAsync());
    }
  }, [dispatch, isOpen, registeryTypes.length]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(AddCredentialSchema),
  });

  const onSubmit = (data: any) => {
    dispatch(addCredentialAsync(data)).then((action) => {
      if (action.type === "registeries/credentials/add/fulfilled") {
        clearModal();
        dispatch(closeAddCredentialModal());
        dispatch(getCredentialsAsync());
      }
    });
  };

  const clearModal = useCallback(() => {
    setSelectedRegistry(undefined);
    reset();
  }, [reset]);

  const registeryOptions: SelectOption<number>[] = useMemo(() => {
    return [
      ...registeryTypes.map((type) => {
        let data = getRegisteryData(type.Value);
        return {
          label: (
            <div className="flex items-center gap-2">
              {data.Icon}
              <Text>{type.Text}</Text>
            </div>
          ),
          value: type.Value,
          extraElement: data.Description,
        };
      }),
    ];
  }, [registeryTypes]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        clearModal();
        dispatch(closeAddCredentialModal());
      }}
      contentClassName="max-w-xl"
      //   containerClassName="!items-start"
      title="Add Credential"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col w-full gap-5">
          <Input
            label="Name"
            inputProps={{ ...register("Name") }}
            error={errors.Name?.message}
            placeholder=""
          />
          <div>
            <Select
              label="Registery"
              options={registeryOptions}
              selected={selectedRegistry}
              setSelected={(o) => {
                setValue("RegisteryType", o ? +o.value : -1, {
                  shouldValidate: true,
                });
                setSelectedRegistry(o);
              }}
              error={errors.RegisteryType?.message}
              loading={registeryTypesLoading}
              clearable
            />
            {selectedRegistry?.extraElement && (
              <div className="mt-3">{selectedRegistry?.extraElement}</div>
            )}
          </div>
          <Input
            label="Username"
            inputProps={{ ...register("UserName") }}
            error={errors.UserName?.message}
            placeholder=""
          />
          <Input
            label="Personal Access Token or Password"
            inputProps={{ ...register("Sec_Key"), type: "password" }}
            error={errors.Sec_Key?.message}
            placeholder=""
          />
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            loading={loading}
            type="primary"
            buttonProps={{
              disabled: loading,
              type: "submit",
            }}
            buttonClassName="w-[160px]"
          >
            Add Credential
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddCredentialModal;
