import toast from "react-hot-toast";
import { ToastClasses } from "../components/modals/alerts";

export const copyToClipboard = (
  value: string | number,
  successMessage?: string,
  faildMessage?: string,
  callback?: () => void
) => {
  if (navigator.clipboard && window.isSecureContext) {
    window.navigator.clipboard
      .writeText(value.toString())
      .then(() => {
        toast.success(successMessage || "Data copied to clipboard!", {
          className: ToastClasses,
        });
        callback && callback();
      })
      .catch(() => {
        toast.error(faildMessage || "Failed to copy data to clipboard.", {
          className: ToastClasses,
        });
      });
  } else {
    // Use the 'out of viewport hidden text area' trick
    const textArea = document.createElement("textarea");
    textArea.value = value.toString();

    // Move textarea out of the viewport so it's not visible
    textArea.style.position = "absolute";
    textArea.style.left = "-999999px";

    document.body.prepend(textArea);
    textArea.select();

    try {
      document.execCommand("copy");
      toast.success(successMessage || "Data copied to clipboard!", {
        className: ToastClasses,
      });
      callback && callback();
    } catch (error) {
      toast.error(faildMessage || "Failed to copy data to clipboard.", {
        className: ToastClasses,
      });
    } finally {
      textArea.remove();
    }
  }
};
