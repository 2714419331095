import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useState } from "react";
import Button from "../../buttons/Button";
import { useNavigate } from "react-router-dom";
import {
  DatabaseServiceGeneralInformationUrl,
  DatabaseServiceNamespacesUrl,
} from "../../../utils/urls";

import Text from "../../general/Text";
import {
  createDBSNamespaceAsync,
  getDBSNamespacesAsync,
  handleHideNamespaceEditorModal,
  selectDatabaseService,
  selectNamespaces,
  selectNamespacesLoading,
  selectSelectedNamespace,
  selectShowNamespaceEditor,
  updateDBSNamespaceAsync,
} from "../../../store/database/serviceSlice";
import { generateServicePermissions } from "../../../pages/databases/ServicePage";
import { ReactComponent as RightArrow } from "../../../assets/icons/arrow-up.svg";
import Input from "../../inputs/Input";
import { InfoTooltip } from "../../general/Tooltip";
import Accordion from "../../general/Accordion";
import RadioGrouper, { RadioGroupeItem } from "../../inputs/RadioGrouper";
import Select from "../../inputs/Select";
import { useForm } from "react-hook-form";
import { NamespacesSchema } from "../../../utils/validations";
import { yupResolver } from "@hookform/resolvers/yup";

export const typeOptions: RadioGroupeItem<"aggregated" | "unaggregated">[] = [
  { label: "Aggregated", value: "aggregated" },
  { label: "Unaggregated  ", value: "unaggregated" },
];
export const typeOptionsWritesToCommitLogEnabled = [
  { label: "Default", value: "default" },
  { label: "True", value: "true" },
  { label: "False", value: "false" },
];
export const typeOptionssnapshotEnabled = [
  { label: "Default", value: "default" },
  { label: "True", value: "true" },
  { label: "False", value: "false" },
];

const convertToISO8601Duration = (duration: any) => {
  if (duration === null || duration === undefined) return null; // Handle null or undefined input

  const regex = /([0-9]+[smhd])/g;
  const matches = duration.match(regex);
  if (!matches) return null; // Handle invalid format

  let isoDuration = "P";
  let timePart = "T";

  matches.forEach((match: any) => {
    const value = match.slice(0, -1);
    const unit = match.slice(-1);
    switch (unit) {
      case "d":
        isoDuration += `${value}D`;
        break;
      case "h":
        timePart += `${value}H`;
        break;
      case "m":
        timePart += `${value}M`;
        break;
      case "s":
        timePart += `${value}S`;
        break;
      default:
        break;
    }
  });

  if (timePart === "T") timePart = ""; // Remove time part if empty
  return isoDuration + timePart;
};

export const convertFromISO8601Duration = (
  isoDuration: string | null | undefined
): string => {
  if (!isoDuration) return ""; // Return empty string if isoDuration is null or undefined

  const regex =
    /P(?:([0-9]+)D)?(?:T(?:([0-9]+)H)?(?:([0-9]+)M)?(?:([0-9]+)S)?)?/;
  const matches = isoDuration.match(regex);
  if (!matches) return "";

  const days = matches[1] ? `${matches[1]}d` : "";
  const hours = matches[2] ? `${matches[2]}h` : "";
  const minutes = matches[3] ? `${matches[3]}m` : "";
  const seconds = matches[4] ? `${matches[4]}s` : "";

  return `${days}${hours}${minutes}${seconds}`;
};

const DBSNamespaceCreateTab = () => {
  const [selectedType, setSelectedType] = useState<RadioGroupeItem>(
    typeOptions[0]
  );
  const [
    selectedWritesToCommitLogEnabled,
    setSelectedWritesToCommitLogEnabled,
  ] = useState(typeOptionsWritesToCommitLogEnabled[0]);
  const [selectedSnapshotEnabled, setSelectedSnapshotEnabled] = useState(
    typeOptionssnapshotEnabled[0]
  );

  const handleSelectChangeWritesToCommitLogEnabled = (option: any) => {
    setSelectedWritesToCommitLogEnabled(option);
  };
  const handleSelectChangeSnapshotEnabled = (option: any) => {
    setSelectedSnapshotEnabled(option);
  };
  const isOpen = useAppSelector(selectShowNamespaceEditor);
  const service = useAppSelector(selectDatabaseService);
  const loading = useAppSelector(selectNamespacesLoading);
  const selectedNamespace = useAppSelector(selectSelectedNamespace);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
    reset,
  } = useForm({
    resolver: yupResolver(NamespacesSchema),
    mode: "onChange", // This will trigger validation on every change
  });

  useEffect(() => {
    if (isOpen) {
      if (selectedNamespace) {
        setValue("name", selectedNamespace.name);
        setValue(
          "resolution",
          convertFromISO8601Duration(selectedNamespace.resolution) || ""
        );
        setValue(
          "periodDuration",
          convertFromISO8601Duration(
            selectedNamespace.retention.periodDuration
          ) || ""
        );
        setValue(
          "blockSizeDuration",
          convertFromISO8601Duration(
            selectedNamespace.retention.blockSizeDuration
          ) || null
        );
        setValue(
          "bufferFutureDuration",
          convertFromISO8601Duration(
            selectedNamespace.retention.bufferFutureDuration
          ) || null
        );
        setValue(
          "bufferPastDuration",
          convertFromISO8601Duration(
            selectedNamespace.retention.bufferPastDuration
          ) || null
        );
        setValue(
          "blockDataExpirationDuration",
          convertFromISO8601Duration(
            selectedNamespace.retention.blockDataExpirationDuration
          ) || null
        );
        const selectedTypeOption =
          typeOptions.find(
            (option) => option.value === selectedNamespace.type
          ) || typeOptions[0];

        setSelectedType(selectedTypeOption);
        const writesToCommitLogEnabledOption =
          typeOptionsWritesToCommitLogEnabled.find(
            (option) =>
              option.value ===
              selectedNamespace.writesToCommitLogEnabled.toString()
          ) || typeOptionsWritesToCommitLogEnabled[0];
        setSelectedWritesToCommitLogEnabled(writesToCommitLogEnabledOption);

        const snapshotEnabledOption =
          typeOptionssnapshotEnabled.find(
            (option) =>
              option.value === selectedNamespace.snapshotEnabled.toString()
          ) || typeOptionssnapshotEnabled[0];
        setSelectedSnapshotEnabled(snapshotEnabledOption);
      }
    } else {
      setValue("name", "");
      setValue("resolution", "");
      setValue("periodDuration", "");
      setValue("blockSizeDuration", null);
      setValue("bufferFutureDuration", null);
      setValue("bufferPastDuration", null);
      setValue("blockDataExpirationDuration", null);
      setSelectedType(typeOptions[0]);
      setSelectedSnapshotEnabled(typeOptionssnapshotEnabled[0]);
      setSelectedWritesToCommitLogEnabled(
        typeOptionsWritesToCommitLogEnabled[0]
      );
    }
  }, [isOpen, selectNamespaces, setValue]);

  useEffect(() => {
    if (service) {
      const permissions = generateServicePermissions().users;
      if (!permissions.includes(service.engine)) {
        navigate(DatabaseServiceGeneralInformationUrl(service.id));
      } else {
        dispatch(
          getDBSNamespacesAsync({ id: service.id, engine: service.engine })
        );
      }
    }
  }, [dispatch, navigate, service]);

  const onSubmit = (data: any) => {
    if (service) {
      if (selectedNamespace) {
        const updateFormData = {
          resolution: convertToISO8601Duration(data.resolution),
          retention: {
            blockDataExpirationDuration:
              convertToISO8601Duration(data.blockDataExpirationDuration) ||
              null,
            blockSizeDuration:
              convertToISO8601Duration(data.blockSizeDuration) || null,
            bufferFutureDuration:
              convertToISO8601Duration(data.bufferFutureDuration) || null,
            bufferPastDuration:
              convertToISO8601Duration(data.bufferPastDuration) || null,
            periodDuration: convertToISO8601Duration(data.periodDuration),
          },

          ...(selectedWritesToCommitLogEnabled.value !== "default"
            ? {
                snapshotEnabled:
                  selectedWritesToCommitLogEnabled.value === "true"
                    ? true
                    : false,
              }
            : {}),
          ...(selectedSnapshotEnabled.value !== "default"
            ? {
                writesToCommitLogEnabled:
                  selectedSnapshotEnabled.value === "true" ? true : false,
              }
            : {}),
        };

        dispatch(
          updateDBSNamespaceAsync({
            engine: service.engine,
            id: service.id,
            data: updateFormData,
            namespaceId: selectedNamespace.id,
          })
        ).then((action) => {
          if (action.type === "service/namespace/update/fulfilled") {
            reset();
            dispatch(
              getDBSNamespacesAsync({ id: service.id, engine: service.engine })
            );
            dispatch(handleHideNamespaceEditorModal());
          }
        });
      } else {
        const formData = {
          name: data.name,
          resolution: convertToISO8601Duration(data.resolution),
          retention: {
            blockDataExpirationDuration: convertToISO8601Duration(
              data.blockDataExpirationDuration
            ),
            blockSizeDuration: convertToISO8601Duration(data.blockSizeDuration),
            bufferFutureDuration: convertToISO8601Duration(
              data.bufferFutureDuration
            ),
            bufferPastDuration: convertToISO8601Duration(
              data.bufferPastDuration
            ),
            periodDuration: convertToISO8601Duration(data.periodDuration),
          },
          ...(selectedWritesToCommitLogEnabled.value !== "default"
            ? {
                snapshotEnabled:
                  selectedWritesToCommitLogEnabled.value === "true"
                    ? true
                    : false,
              }
            : {}),
          ...(selectedSnapshotEnabled.value !== "default"
            ? {
                writesToCommitLogEnabled:
                  selectedSnapshotEnabled.value === "true" ? true : false,
              }
            : {}),
          type: "aggregated",
        };

        dispatch(
          createDBSNamespaceAsync({
            engine: service.engine,
            id: service.id,
            data: formData,
          })
        ).then((action) => {
          if (action.type === "service/namespace/create/fulfilled") {
            reset();
            dispatch(
              getDBSNamespacesAsync({ id: service.id, engine: service.engine })
            );
            navigate(DatabaseServiceNamespacesUrl(service.id));
            reset();
          }
        });
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Database</title>
        <meta name="description" content="" />
      </Helmet>

      {service && (
        <>
          <div className="flex items-center justify-between">
            <div className="w-full flex flex-col gap-5">
              {!isOpen && service && (
                <div
                  className="group px-1 items-center  flex flex-1 transition-all duration-150 cursor-pointer gap-x-0.5"
                  onClick={() =>
                    navigate(DatabaseServiceNamespacesUrl(service.id))
                  }
                >
                  <RightArrow className="-rotate-90 w-4 h-4 transition-all duration-500 text-primary-500 group-hover:translate-x-[-4px]" />

                  <Text type="title" className="text-sm !text-primary-500 ">
                    Back to “Namespaces”
                  </Text>
                </div>
              )}
              <div className="w-full">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mt-5 flex flex-col w-full gap-5">
                    <Input
                      label="Name"
                      inputProps={{
                        ...register("name"),
                        type: "string",
                        disabled: !!selectedNamespace,
                      }}
                      error={errors.name?.message}
                      placeholder="Namespace name"
                      required
                    />
                  </div>

                  <div className="mt-5 flex flex-col gap-5  ">
                    <div className="flex flex-row items-center space-x-2">
                      <Text className="text-sm">Type</Text>
                      <InfoTooltip content="There can only be one non-aggregated namespace" />
                    </div>

                    <div className="flex flex-col ">
                      <RadioGrouper
                        items={typeOptions}
                        selected={selectedType}
                        setSelected={setSelectedType}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="flex flex-row justify-between mb-5 gap-5 ">
                    <div className="mt-5 flex flex-col w-full gap-5">
                      <Input
                        label="Retention period"
                        inputProps={{
                          ...register("periodDuration"),
                          type: "string",
                        }}
                        error={errors.periodDuration?.message}
                        placeholder="E.g. 48h"
                        tooltip="The data retention duration for the namespace in short time format. Examples: 20s, 10m, 24h, 1d"
                        required
                      />
                    </div>

                    <div className="mt-5 flex flex-col w-full gap-5">
                      <Input
                        label="Resolution"
                        inputProps={{
                          ...register("resolution"),
                          type: "string",
                          disabled:
                            isOpen && selectedNamespace?.resolution === null,
                        }}
                        error={errors.resolution?.message}
                        placeholder="E.g. 1d"
                        tooltip="The resolution for an aggregated namespace."
                        required
                      />
                    </div>
                  </div>

                  <Accordion
                    items={[
                      {
                        label: "Advanced configuration ",
                        panel: (
                          <div className="flex gap-5 flex-col  w-full">
                            <div className="flex flex-row w-full gap-5 justify-between">
                              <div className="mt-5 flex flex-col w-full gap-5">
                                <Select
                                  className="disabled  "
                                  label="writesToCommitLogEnabled"
                                  options={typeOptionsWritesToCommitLogEnabled}
                                  selected={selectedWritesToCommitLogEnabled}
                                  setSelected={
                                    handleSelectChangeWritesToCommitLogEnabled
                                  }
                                  tooltip={`Defines whether ${service.engine} should include writes to this namespace in the commitlog.`}
                                />
                              </div>
                              <div className="mt-5 flex flex-col w-full gap-5">
                                <Select
                                  className="disabled  "
                                  label="snapshotEnabled"
                                  options={typeOptionssnapshotEnabled}
                                  selected={selectedSnapshotEnabled}
                                  setSelected={
                                    handleSelectChangeSnapshotEnabled
                                  }
                                  tooltip={`Defines whether ${service.engine} should create snapshot files for this namespace.`}
                                />
                              </div>

                              <div className="mt-5 flex flex-col w-full gap-5">
                                <Input
                                  className="w-full "
                                  label="retention.blockSizeDuration"
                                  inputProps={{
                                    ...register("blockSizeDuration"),
                                    type: "string",
                                  }}
                                  error={errors.blockSizeDuration?.message}
                                  placeholder="E.g. 2h"
                                  tooltip="The amount of time a block is held in memory before it is written to disk, in short time format. Examples: 20s, 10m, 24h, 1d"
                                />
                              </div>
                            </div>
                            <div className="flex flex-row w-full gap-5 justify-between">
                              <div className="mt-5  w-full gap-5  ">
                                <Input
                                  className="w-full flex-grow"
                                  label="retention.bufferPastDuration"
                                  inputProps={{
                                    ...register("bufferPastDuration"),
                                    type: "string",
                                  }}
                                  error={errors.bufferPastDuration?.message}
                                  placeholder="E.g. 10m"
                                  tooltip="A past duration for which writes are still accepted on the name space, in short time format. Examples: 20s, 10m, 24h, 1d"
                                />
                              </div>
                              <div className="mt-5  w-full gap-5">
                                <Input
                                  className="w-full flex-grow"
                                  label="retention.bufferFutureDuration"
                                  inputProps={{
                                    ...register("bufferFutureDuration"),
                                    type: "string",
                                  }}
                                  error={errors.bufferFutureDuration?.message}
                                  placeholder="E.g. 10m"
                                  tooltip="A future duration for which writes on the namespace are already accepted, in short time format. Examples: 20s, 10m, 24h, 1d"
                                />
                              </div>
                              <div className="mt-5  w-full gap-5">
                                <Input
                                  className="w-full flex-grow"
                                  label="retention.blockDataExpirationDuration"
                                  inputProps={{
                                    ...register("blockDataExpirationDuration"),
                                    type: "string",
                                  }}
                                  error={
                                    errors.blockDataExpirationDuration?.message
                                  }
                                  placeholder="E.g. 10m"
                                  tooltip="Block memory expiry duration"
                                />
                              </div>
                            </div>
                          </div>
                        ),
                      },
                    ]}
                  />
                  <div className="mt-4 flex justify-end">
                    <Button
                      loading={loading}
                      type="primary"
                      buttonProps={{
                        type: "submit",
                      }}
                      buttonClassName="w-[100px]"
                    >
                      {selectedNamespace
                        ? "Modify a namespace"
                        : " Add a name space"}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DBSNamespaceCreateTab;
