import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  getS3WidgetAsync,
  selectLastScanActivity,
  selectLastScanActivityLoading,
  selectS3EndTime,
  selectS3StartTime,
} from "../../../../store/s3/overviewSlice";
import { S3WidgetType } from "../../../../types/s3";
import StatWidget from "./freams/StatWidget";
import { getLastValueOfWidgetTarget } from "../../../../utils/bucket";
import { ReactComponent as ScanIcon } from "./../../../../assets/icons/scan-time.svg";

const chartType: S3WidgetType = "lastScanActivity";
const chartCode: number = 81;

const LastScanActivityWidget = () => {
  const data = useAppSelector(selectLastScanActivity);
  const loading = useAppSelector(selectLastScanActivityLoading);
  const startTime = useAppSelector(selectS3StartTime);
  const endTime = useAppSelector(selectS3EndTime);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      getS3WidgetAsync({ key: chartType, n: chartCode, startTime, endTime })
    );
  }, [dispatch, endTime, startTime]);

  const getLastScan = useCallback(() => {
    if (data?.targets && data.targets.length > 0) {
      return Math.floor(
        Number(getLastValueOfWidgetTarget(data?.targets)[1]) / 1000000000
      );
    } else {
      return 0;
    }
  }, [data?.targets]);

  return (
    <StatWidget
      title="Time since last Scan Activity"
      value={`${getLastScan()} seconds`}
      dataLoading={loading}
      icon={<ScanIcon className="w-4 text-slate-800 dark:text-slate-300" />}
    />
  );
};

export default LastScanActivityWidget;
