import Button from "../../buttons/Button";
import Card from "../../general/Card";
import Text from "../../general/Text";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  handleInstanceDeleteHideModal,
  handleInstanceDeleteShowModal,
  selectInstance,
  selectInstanceBackupsLoading,
  selectShowDeleteModal,
} from "../../../store/instances/instanceSlice";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  deleteInstanceAsync,
  getInstancesAsync,
} from "../../../store/instances/instancesSlice";
import { InstancesUrl } from "../../../utils/urls";
import { DeleteModal } from "../../modals/QuestionModal";

const InstanceDeleteTab = () => {
  const instance = useAppSelector(selectInstance);
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectShowDeleteModal);
  const instanceBackupsLoading = useAppSelector(selectInstanceBackupsLoading);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleDeleteInstance = async () => {
    if (instance) {
      setLoading(true);
      const action = await dispatch(deleteInstanceAsync({ id: instance.id }));
      if (action.type === "instances/delete/fulfilled") {
        dispatch(getInstancesAsync({ withoutLoading: false }));
        navigate(InstancesUrl);
        handleClose();
      }
      setLoading(false);
    }
  };

  const handleClose = () => {
    dispatch(handleInstanceDeleteHideModal());
  };

  return (
    <div>
      {" "}
      <Card title="Delete">
        <div className="mt-2 grid gap-4 w-full">
          <Text className="text-sm">
            Deleting your server will stop any existing processes and destroy
            the disk and backups.
          </Text>
          <Text className="text-sm">
            The data won't be retrievable_snapshots of the server remain intact.
          </Text>

          <>
            <div className="p-2">
              {instance && (
                <Button
                  buttonProps={{
                    onClick: () => {
                      dispatch(handleInstanceDeleteShowModal(instance));
                    },
                  }}
                  type="danger"
                >
                  Delete instance
                </Button>
              )}
            </div>
          </>
        </div>
      </Card>
      <DeleteModal
        isOpen={isOpen}
        title="Delete Instance"
        onClose={handleClose}
        description="To confirm, type 'DELETE' in the box below"
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        loading={loading || instanceBackupsLoading}
        onConfirm={handleDeleteInstance}
        confirmString={instance?.name}
      />
    </div>
  );
};

export default InstanceDeleteTab;
