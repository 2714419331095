import { useState } from "react";
import { getLocalStorage, setLocalStorage } from "../../utils/localStorage";
import Button from "../buttons/Button";
import Text from "../general/Text";
import { ReactComponent as CloseIcon } from "./../../assets/icons/close.svg";

const Web3Banner = () => {
  const showWeb3Banner = getLocalStorage<boolean>("web3-banner", true);
  const [show, setShow] = useState<boolean>(showWeb3Banner);
  const closeBanner = () => {
    setLocalStorage<boolean>("web3-banner", false);
    setShow(false);
  };

  if (!show) return null;
  return (
    <div className="relative w-full mt-5 bg-gray-50 dark:bg-dark-3 border border-slate-300 dark:border-dark-2 p-6 rounded-lg flex flex-col">
      <CloseIcon
        onClick={closeBanner}
        className="absolute right-3 top-3 text-slate-500 dark:text-slate-300 w-5 hover:scale-110 duration-300 cursor-pointer"
      />
      <div className="flex items-center w-full justify-between">
        <Text className="text-sm">Get started with Web3.0 API</Text>
      </div>
      <div className="flex items-center flex-wrap gap-2 mt-5">
        <a href="https://docs.djuno.cloud" target="_blank" rel="noreferrer">
          <Button>Documentation</Button>
        </a>
        <a href="https://docs.djuno.cloud" target="_blank" rel="noreferrer">
          <Button>Sign in with Metamask</Button>
        </a>
        <a href="https://docs.djuno.cloud" target="_blank" rel="noreferrer">
          <Button>Aggregate user data</Button>
        </a>
      </div>
    </div>
  );
};

export default Web3Banner;
