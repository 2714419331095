import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import type { PayloadAction } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { ToastClasses } from "../../components/modals/alerts";
import {
  CreateNftApiApiKeyData,
  NftApiApiKey,
  NftApiApiKeyStateType,
} from "../../types/nft-api";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import {
  getNftApiApiKeysApi,
  createNftApiApiKeyApi,
  updateNftApiApiKeyApi,
  deleteNftApiApiKeyApi,
} from "../../apis/nftApiAPI";

const initialState: NftApiApiKeyStateType = {
  loading: false,
  apiKeys: [],
  selectedApiKey: null,
  showEditorModal: false,
  actionLoading: false,
};

export const getNftApiApiKeysAysnc = createAsyncThunk<
  {
    apiKeys: Array<NftApiApiKey>;
  },
  undefined,
  { rejectValue: { message: string } }
>("nft-api/api-keys", async (_, { fulfillWithValue, rejectWithValue }) => {
  try {
    const response = await getNftApiApiKeysApi();
    const apiKeys = response.data.Result;
    return fulfillWithValue({ apiKeys });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const deleteNftApiApiKeyAsync = createAsyncThunk<
  boolean,
  { id: number },
  IThunkRejectValue
>("nft-api/api-keys/delete", async ({ id }, { rejectWithValue }) => {
  try {
    await deleteNftApiApiKeyApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createNftApiApiKeyAsync = createAsyncThunk<
  boolean,
  CreateNftApiApiKeyData,
  IThunkRejectValue
>("nft-api/api-keys/create", async (formData, { rejectWithValue }) => {
  try {
    await createNftApiApiKeyApi(formData);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const updateNftApiApiKeyAsync = createAsyncThunk<
  boolean,
  { id: number; formData: CreateNftApiApiKeyData },
  IThunkRejectValue
>("nft-api/api-keys/update", async ({ id, formData }, { rejectWithValue }) => {
  try {
    await updateNftApiApiKeyApi(id, formData);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

const nftApiApiKeysSlice = createSlice({
  name: "nftApiApiKeys",
  initialState: initialState,
  reducers: {
    clearNftApiApiKeys(state) {
      state.apiKeys = initialState.apiKeys;
      state.loading = initialState.loading;
    },
    handleShowNftApiEditorModal: (
      state,
      action: PayloadAction<{ apiKey?: NftApiApiKey }>
    ) => {
      state.showEditorModal = true;
      state.selectedApiKey = action.payload.apiKey || null;
    },
    handleHideNftApiEditorModal: (state) => {
      state.showEditorModal = false;
      state.selectedApiKey = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNftApiApiKeysAysnc.fulfilled, (state, action) => {
        state.apiKeys = action.payload.apiKeys;
        state.loading = false;
      })
      .addCase(getNftApiApiKeysAysnc.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNftApiApiKeysAysnc.rejected, (state, { payload }) => {
        state.loading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteNftApiApiKeyAsync.fulfilled, (state, action) => {
        state.actionLoading = false;
      })
      .addCase(deleteNftApiApiKeyAsync.pending, (state, action) => {
        state.actionLoading = true;
      })
      .addCase(deleteNftApiApiKeyAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createNftApiApiKeyAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(createNftApiApiKeyAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(createNftApiApiKeyAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateNftApiApiKeyAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(updateNftApiApiKeyAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(updateNftApiApiKeyAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        toast.error((payload as { message: string }).message, {
          className: ToastClasses,
        });
      });
  },
});

export const selectNftApiLoading = (state: RootState) =>
  state.nftApiApiKeys.loading;
export const selectNftApiActionLoading = (state: RootState) =>
  state.nftApiApiKeys.actionLoading;

export const selectNftApiApiKeys = (state: RootState) =>
  state.nftApiApiKeys.apiKeys;

export const selectSelectedNftApiApiKey = (state: RootState) =>
  state.nftApiApiKeys.selectedApiKey;

export const selectShowNftApiEditorModal = (state: RootState) =>
  state.nftApiApiKeys.showEditorModal;

export const {
  clearNftApiApiKeys,
  handleShowNftApiEditorModal,
  handleHideNftApiEditorModal,
} = nftApiApiKeysSlice.actions;

export default nftApiApiKeysSlice.reducer;
