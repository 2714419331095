import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../buttons/Button";
import { ReactComponent as CloseIcon } from "./../../../../assets/icons/close.svg";

import {
  InstancesVolumeBackupListUrl,
  InstancesVolumeSnapshotListUrl,
  InstancesVolumesUrl,
} from "../../../../utils/urls";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InstanceVolumeBackupSchema } from "../../../../utils/validations";
import { DBSAddon } from "../../../../types/database";
import { LoadingSpin } from "../../../general/Loading";
import {
  getDBSCatalogAsync,
  selectDBSCatalog,
  selectDBSCatalogLoading,
} from "../../../../store/database/servicesSlice";
import {
  createInstancesVolumeBackupAsync,
  createInstancesVolumeSnapshotAsync,
  getInstancesVolumeAsync,
  getInstancesVolumesAsync,
  selectInstancesSelectedVolume,
  selectInstancesSelectedVolumeLoading,
  selectInstancesVolumesActionLoading,
} from "../../../../store/instances/instancesVolumesSlice";
import Text from "../../../general/Text";
import { Box } from "@mui/material";
import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import { capitalizeFirstLetter } from "../../../../utils";

import Input from "../../../inputs/Input";
import { getDBSPriceAmout } from "../../../../pages/databases/ServiceCreatePage";
import Modal from "../../../modals/Modal";
import {
  getInstanceVolumeBackupListAsync,
  getInstanceVolumeSnapshotListAsync,
  handleHideVolumeBackupModal,
  handleHideVolumeSnapshorModal,
  selectshowVolumeBackupModal,
  selectshowVolumeSnapshotModal,
} from "../../../../store/instances/instancesVolumeSlice";

const backupTypes = [
  {
    id: "snapshot",
    code: "volume.snapshot.monthly.postpaid",
    title: "Volume Snapshot",
    description: `With snapshot backups, you can quickly revert your system back to a previous state.
  The snapshot is stored on the same storage cluster as your volume.`,
  },
];

const InstanceVolumeSnapshostsModal = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const isOpen = useAppSelector(selectshowVolumeSnapshotModal);
  const selectedVolume = useAppSelector(selectInstancesSelectedVolume);
  const selectedVolumeLoading = useAppSelector(
    selectInstancesSelectedVolumeLoading
  );
  const catalog = useAppSelector(selectDBSCatalog);
  const catalogLoading = useAppSelector(selectDBSCatalogLoading);
  const actionLoading = useAppSelector(selectInstancesVolumesActionLoading);

  // states
  const [addons, setAddons] = useState<Array<DBSAddon>>([]);

  const dispatch = useAppDispatch();

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(InstanceVolumeBackupSchema),
    defaultValues: {
      selectedType: "volume.snapshot.monthly.postpaid",
      name: "",
    },
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
    register,
    watch,
  } = methods;

  // watch form values
  const selectedType = watch("selectedType");

  //get selected volume
  useEffect(() => {
    if (selectedVolume === null && id !== undefined) {
      dispatch(getInstancesVolumeAsync({ id }));
    }
  }, [dispatch, id, selectedVolume]);

  //get first data
  useEffect(() => {
    dispatch(getDBSCatalogAsync());
  }, [dispatch, reset]);

  useEffect(() => {
    if (catalog) {
      setAddons(
        catalog.addons.filter(
          (a) =>
            a.planCode.startsWith("volume.") ||
            a.planCode.startsWith("snapshot.")
        )
      );
    }
  }, [catalog]);

  const handleSubmitForm = (data: any) => {
    if (selectedVolume) {
      if (selectedType === "volume.snapshot.monthly.postpaid") {
        dispatch(
          createInstancesVolumeSnapshotAsync({
            volumeId: selectedVolume.id,
            data: {
              description: "",
              name: data.name,
            },
          })
        ).then((action) => {
          if (action.type === "instances/volumes/snapshot/create/fulfilled") {
            navigate(InstancesVolumeSnapshotListUrl(selectedVolume.id));
            handleClose();
            dispatch(
              getInstancesVolumeAsync({
                id: selectedVolume.id,
                withoutLoading: true,
              })
            ).then((action) => {
              if (
                action.type === "instances/volume/fulfilled" &&
                selectedVolume.status === "available"
              ) {
                dispatch(getInstancesVolumesAsync({ withoutLoading: true }));
                dispatch(
                  getInstanceVolumeSnapshotListAsync({
                    volumeId: selectedVolume?.id,
                    withoutLoading: true,
                  })
                );
              }
            });
          }
        });
      }
    }
  };

  const disabledForMakingBackup = useMemo(() => {
    if (!selectedVolume) return true;
    // console.log(selectedType);
    if (
      selectedVolume.attachedTo.length > 0 &&
      selectedType === "snapshot.monthly.postpaid"
    ) {
      return true;
    } else {
      return false;
    }
  }, [selectedType, selectedVolume]);

  const handleClose = () => {
    dispatch(handleHideVolumeSnapshorModal());
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      contentClassName="max-w-lg"
      //containerClassName="!items-start"
      title={`Create a snapshot from ${selectedVolume?.name}`}
    >
      {(catalogLoading || selectedVolumeLoading) && (
        <div className="h-full w-full flex items-center justify-center ">
          <LoadingSpin borderSize={2} />
        </div>
      )}
      {!catalogLoading && !selectedVolumeLoading && (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="mt-3 w-full px-6 flex flex-col lg:flex-row  relative">
            <div className="w-full flex flex-col">
              <Controller
                name="selectedType"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <Box sx={{ width: "100%", typography: "body1" }}>
                      <RadioGroup value={value || null} onChange={onChange}>
                        <div className="flex flex-col w-full mt-3">
                          <div className="w-full mb-5">
                            {backupTypes.map((type, i) => {
                              const addon = addons.find(
                                (a) => a.planCode === type.code
                              );

                              const exVAT = addon
                                ? getDBSPriceAmout(addon.pricings[0].price, 3)
                                : null;
                              return (
                                <RadioGroup.Option
                                  key={type.title + i}
                                  value={type.code}
                                >
                                  {({ checked, disabled }) => {
                                    return (
                                      <div
                                        className={classNames(
                                          "col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300 cursor-pointer h-full",
                                          {
                                            "border-primary-400 dark:border-primary-400":
                                              checked,
                                            "!cursor-not-allowed": disabled,
                                          }
                                        )}
                                      >
                                        <div className="flex items-center justify-between mb-2">
                                          <Text className="text-sm">
                                            {capitalizeFirstLetter(type.title)}
                                          </Text>
                                        </div>
                                        <div className="flex items-center justify-between ">
                                          <Text className="text-xs">
                                            {type.description}
                                          </Text>
                                        </div>
                                        <div className="flex flex-col border-t  gap-0.5">
                                          {exVAT && (
                                            <div
                                              key={i}
                                              className="flex items-center "
                                            >
                                              <Text className="text-xs font-medium">
                                                Price: €{exVAT} ex. VAT/GB/month
                                              </Text>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  }}
                                </RadioGroup.Option>
                              );
                            })}
                          </div>
                        </div>
                      </RadioGroup>
                    </Box>
                  );
                }}
              />

              {/* select name */}
              <Box sx={{ width: "100%", typography: "body1" }}>
                <div className="flex flex-col gap-1 mb-9">
                  <Input
                    label="Backup name"
                    inputProps={{ ...register("name") }}
                    error={errors.name?.message}
                  />
                </div>
              </Box>
              <div className="mt-4 flex justify-end gap-2">
                <Button
                  type="light"
                  buttonProps={{
                    onClick: handleClose,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  buttonProps={{
                    type: "submit",
                    disabled: !isValid || disabledForMakingBackup,
                  }}
                  loading={actionLoading}
                >
                  Confirm
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
    </Modal>
  );
};

export default InstanceVolumeSnapshostsModal;
