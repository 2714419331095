import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import Modal from "../../../../modals/Modal";
import Button from "../../../../buttons/Button";
import { useEffect, useState } from "react";
import Switcher from "../../../../inputs/Switcher";
import {
  getBucketVersioningAsync,
  handleHideVerioningEditor,
  selectBucketDetails,
  selectBucketVersioning,
  selectBucketVersioningLoading,
  selectShowVersioningEditor,
  setBucketVersioningAsync,
} from "../../../../../store/s3/buckets/bucketSlice";

import { yupResolver } from "@hookform/resolvers/yup";
import { S3VersioningSchema } from "../../../../../utils/validations";
import S3ExclodeForm from "../../forms/S3ExclodeForm";

const S3VersioningEditorModal = () => {
  const isOpen = useAppSelector(selectShowVersioningEditor);
  const loading = useAppSelector(selectBucketVersioningLoading);
  const versioning = useAppSelector(selectBucketVersioning);
  const bucketDetails = useAppSelector(selectBucketDetails);

  const [isVersioning, setIsVersioning] = useState<boolean>(false);
  const [isExcludeFolders, setIsExcludeFolders] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(S3VersioningSchema(isVersioning)),
  });

  useEffect(() => {
    if (isOpen && bucketDetails && versioning) {
      setIsVersioning(versioning.status === "Enabled");
      setIsExcludeFolders(versioning.excludeFolders || false);
      setValue("excludePrefixes", versioning.excludedPrefixes);
    }
  }, [isOpen, bucketDetails, setValue, versioning]);

  useEffect(() => {
    if (isOpen && bucketDetails && versioning === null) {
      // dispatch(getBucketVersioningAsync({ name: bucketDetails.name }));
    }
  }, [isOpen, bucketDetails, versioning, dispatch]);

  const handleClearComponent = () => {
    setIsVersioning(false);
    setIsExcludeFolders(false);
    reset();
  };

  const onSubmit = (data: any) => {
    //data
    // excludePrefixes
    //--- others:
    //isVersioning
    //isExcludeFolders
    if (bucketDetails) {
      dispatch(
        setBucketVersioningAsync({
          name: bucketDetails.name,
          formData: {
            enabled: isVersioning,
            excludeFolders: isExcludeFolders,
            excludePrefixes: data.excludePrefixes?.map((e: any) => e.prefix),
          },
        })
      ).then((action) => {
        if (action.type === "bucket/versioning/set/fulfilled") {
          handleClearComponent();
          dispatch(handleHideVerioningEditor());
          dispatch(getBucketVersioningAsync({ name: bucketDetails.name }));
        }
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleHideVerioningEditor());
        handleClearComponent();
      }}
      contentClassName="max-w-lg"
      // containerClassName="!items-start"
      title={"Versioning on Bucket"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col w-full gap-5">
          <div className="flex items-center justify-between w-full">
            <div className="text-sm text-slate-700 dark:text-slate-200">
              Versioning Status
            </div>
            <Switcher on={isVersioning} onToggle={setIsVersioning} />
          </div>

          {isVersioning && (
            <S3ExclodeForm
              enabledExclodeFolders={isExcludeFolders}
              setExcludeFoldersStatus={setIsExcludeFolders}
              control={control}
              errors={errors}
              register={register}
            />
          )}
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button
            buttonProps={{
              onClick: (e) => {
                e.preventDefault();
                handleClearComponent();
                dispatch(handleHideVerioningEditor());
              },
            }}
            type="light"
            buttonClassName="w-[70px]"
          >
            Cancel
          </Button>
          <Button
            loading={loading}
            type="primary"
            buttonProps={{
              disabled: loading,
              type: "submit",
            }}
            buttonClassName="w-[70px]"
          >
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default S3VersioningEditorModal;
