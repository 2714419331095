import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createS3EventAsync,
  getS3EventsAsync,
  selectActionLoading,
  selectLoading,
  selectSelectedS3Event,
} from "../../../../store/s3/eventsSlice";
import Button from "../../../buttons/Button";
import { S3MqttEventSchema } from "../../../../utils/validations";
import Input from "../../../inputs/Input";
import { useEffect } from "react";
import Textarea from "../../../inputs/Textarea";
import { S3EventsUrl } from "../../../../utils/urls";
import { useNavigate } from "react-router-dom";
import useGetS3Events from "../../../../hooks/useGetS3Events";

const MqttForm = () => {
  const event = useAppSelector(selectSelectedS3Event);
  const loading = useAppSelector(selectActionLoading);
  const eventsLoading = useAppSelector(selectLoading);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const getS3Events = useGetS3Events();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(S3MqttEventSchema),
  });

  useEffect(() => {
    if (event) {
    } else {
    }
  }, [event, setValue]);

  const onSubmit = (data: any) => {
    let keyValues = [
      {
        key: "broker",
        value: data.broker,
      },
      {
        key: "topic",
        value: data.topic,
      },
      {
        key: "username",
        value: data.username,
      },
      {
        key: "password",
        value: data.password,
      },
      {
        key: "qos",
        value: data.qos,
      },
      {
        key: "keep_alive_interval",
        value: data.keep_alive_interval,
      },
      {
        key: "reconnect_interval",
        value: data.reconnect_interval,
      },
      {
        key: "queue_dir",
        value: data.queue_dir,
      },
      {
        key: "comment",
        value: data.comment,
      },
    ];
    if (data.queue_limit !== null) {
      keyValues = [
        ...keyValues,
        {
          key: "queue_limit",
          value: data.queue_limit.toString(),
        },
      ];
    }
    const formData = {
      key_values: keyValues,
    };
    // if (event) {
    // dispatch(
    //   updateS3EventAsync({
    //     id: event.Id,
    //     data: {
    //       label: data.label,
    //     },
    //   })
    // ).then((action) => {
    //   if (action.type === "s3-events/update/fulfilled") {
    //     // handleClearComponent();
    //     dispatch(handleHideEditor());
    //     dispatch(getS3EventsAsync());
    //     reset();
    //   }
    // });
    // } else {
    dispatch(
      createS3EventAsync({
        event: "mqtt",
        identifier: data.identifier,
        data: formData,
      })
    ).then((action) => {
      if (action.type === "s3-events/create/fulfilled") {
        getS3Events(() => {
          reset();
          navigate(S3EventsUrl);
        });
      }
    });
    // }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col w-full gap-5">
        <Input
          label="Identifier"
          tooltip="Unique descriptive string for this destination"
          placeholder="Enter Destination Identifier"
          inputProps={{ ...register("identifier") }}
          error={errors.identifier?.message}
          required
        />

        <Input
          label="Broker"
          tooltip="MQTT server endpoint e.g. `tcp://localhost:1883`"
          placeholder="Enter Broker"
          inputProps={{ ...register("broker") }}
          error={errors.broker?.message}
        />

        <Input
          label="Topic"
          tooltip="Name of the MQTT topic to publish"
          placeholder="Enter Topic"
          inputProps={{ ...register("topic") }}
          error={errors.topic?.message}
        />

        <Input
          label="Username"
          tooltip="MQTT Username"
          placeholder="Enter Username"
          inputProps={{ ...register("username") }}
          error={errors.username?.message}
        />

        <Input
          label="Password"
          tooltip="MQTT Password"
          placeholder="Enter Password"
          inputProps={{ ...register("password") }}
          error={errors.password?.message}
        />

        <Input
          label="QOS"
          tooltip="Set the quality of service priority, defaults to '0'"
          placeholder="Enter QOS"
          inputProps={{ ...register("qos") }}
          error={errors.qos?.message}
        />

        <Input
          label="Keep Alive Interval"
          tooltip="Keep-alive interval for MQTT connections in s,m,h,d"
          placeholder="Enter Keep Alive Interval"
          inputProps={{ ...register("keep_alive_interval") }}
          error={errors.keep_alive_interval?.message}
        />

        <Input
          label="Reconnect Interval"
          tooltip="Reconnect interval for MQTT connections in s,m,h,d"
          placeholder="ِEnter Reconnect Interval"
          inputProps={{ ...register("reconnect_interval") }}
          error={errors.reconnect_interval?.message}
        />

        <Input
          label="Queue Directory"
          tooltip="Staging directory for undelivered messages e.g. '/home/events'"
          placeholder="ِEnter Queue Directory"
          inputProps={{ ...register("queue_dir") }}
          error={errors.queue_dir?.message}
        />

        <Input
          label="Queue Limit"
          tooltip="Maximum limit for undelivered messages, defaults to '10000'"
          placeholder="ِEnter Queue Limit"
          inputProps={{ ...register("queue_limit"), type: "number" }}
          error={errors.queue_limit?.message}
        />

        <Textarea
          label="Comment"
          placeholder="Enter custom notes if any"
          textareaProps={{ ...register("comment") }}
          error={errors.comment?.message}
        />
      </div>
      <div className="mt-4 flex justify-end">
        <Button
          loading={loading || eventsLoading}
          type="primary"
          buttonProps={{
            disabled: loading || eventsLoading,
            type: "submit",
          }}
          buttonClassName="w-[160px]"
        >
          {event ? "Update Evet Target" : "Save Evet Target"}
        </Button>
      </div>
    </form>
  );
};
export default MqttForm;
