import { useState, useEffect } from "react";
import PropTypes from "prop-types";

// material-ui
import { Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// project imports
import OptionParamsResponse from "./OptionParamsResponse";

// third party
import lodash from "lodash";
import AsyncSelect from "react-select/async";

// icons
import { ReactComponent as IconX } from "./../../../../assets/icons/close.svg";

// Constant
import { InfoTooltip } from "../../../general/Tooltip";
import { getNodeLoadMethodApi } from "../../../../apis/workflowsAPI";
import useDarkMode from "../../../../hooks/useDarkMode";
import Text from "../../../general/Text";

// ==============================|| ASYNC SELECT WRAPPER ||============================== //

const AsyncSelectWrapper = ({
  title,
  description,
  value,
  loadMethod,
  loadFromDbCollections,
  nodeFlowData,
  error,
  onChange,
  onMenuOpen,
  onSetError,
}) => {
  const theme = useTheme();
  const { mode } = useDarkMode();

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      paddingTop: 7,
      paddingBottom: 7,
      paddingLeft: 15,
      paddingRight: 15,
      cursor: "pointer",
      fontWeight: "400",
      fontSize: 14,
      backgroundColor:
        mode === "dark"
          ? state.isSelected
            ? "rgb(32,36,37)"
            : "rgb(63,63,70)"
          : state.isSelected
          ? "rgb(235,245,255)"
          : "#fff",
      color: mode === "dark" ? "white" : "black",
      "&:hover": {
        backgroundColor:
          mode === "dark" ? "#233345" : theme.palette.grey["200"],
      },
    }),
    control: (provided) => ({
      ...provided,
      cursor: "text",
      backgroundColor: mode === "dark" ? "rgb(32,36,37)" : "rgb(241,245,249)",
      paddingTop: 1,
      paddingBottom: 1,
      paddingRight: 4,
      paddingLeft: 4,
      borderRadius: "0.5rem",
      border:
        mode === "dark"
          ? "solid 2px rgb(32,36,37)"
          : `solid 2px rgb(241,245,249)`,
      "&:hover": { outline: "0" },
      "&:focus": {
        borderColor: mode === "dark" ? "rgb(71,85,105)" : "rgb(226,232,240)",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: mode === "dark" ? "white" : "black",
      fontWeight: "400",
      fontSize: 14,
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: mode === "dark" ? "rgb(63,63,70)" : "",
      boxShadow:
        "0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)",
    }),
  };

  const [asyncOptions, setAsyncOptions] = useState([]);

  const getSelectedValue = (value) =>
    asyncOptions.find((option) => option.name === value);

  const getDefaultOptionValue = () => "";

  const formatErrorMessage = (error) => {
    if (error) return `*${error.replace(/["]/g, "")}`;
    return "";
  };

  const showHideOptions = (options) => {
    let returnOptions = options;
    const toBeDeleteOptions = [];
    const displayTypes = ["show", "hide"];

    for (let x = 0; x < displayTypes.length; x += 1) {
      const displayType = displayTypes[x];

      for (let i = 0; i < returnOptions.length; i += 1) {
        const option = returnOptions[i];
        const displayOptions = option[displayType];

        if (displayOptions) {
          Object.keys(displayOptions).forEach((path) => {
            const comparisonValue = displayOptions[path];
            const groundValue = lodash.get(nodeFlowData, path, "");

            if (Array.isArray(comparisonValue)) {
              if (
                displayType === "show" &&
                !comparisonValue.includes(groundValue)
              ) {
                toBeDeleteOptions.push(option);
              }
              if (
                displayType === "hide" &&
                comparisonValue.includes(groundValue)
              ) {
                toBeDeleteOptions.push(option);
              }
            } else if (typeof comparisonValue === "string") {
              if (
                displayType === "show" &&
                !(
                  comparisonValue === groundValue ||
                  new RegExp(comparisonValue).test(groundValue)
                )
              ) {
                toBeDeleteOptions.push(option);
              }
              if (
                displayType === "hide" &&
                (comparisonValue === groundValue ||
                  new RegExp(comparisonValue).test(groundValue))
              ) {
                toBeDeleteOptions.push(option);
              }
            }
          });
        }
      }
    }

    for (let i = 0; i < toBeDeleteOptions.length; i += 1) {
      returnOptions = returnOptions.filter(
        (opt) => JSON.stringify(opt) !== JSON.stringify(toBeDeleteOptions[i])
      );
    }

    return returnOptions;
  };

  const loadOptions = (inputValue, callback) => {
    getNodeLoadMethodApi(nodeFlowData.name, {
      ...nodeFlowData,
      loadMethod,
      loadFromDbCollections,
    }).then((response) => {
      const data = response.data.Result;
      const filteredOption = (data || []).filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      const options = showHideOptions(filteredOption);
      setAsyncOptions(options);
      callback(options);
    });
  };

  const formatOptionLabel = ({ label, description }, { context }) => (
    <>
      {context === "menu" && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text className="text-sm">{label}</Text>
          {description && (
            <Text className="text-xs mt-1" type="subtext">
              {description}
            </Text>
          )}
        </div>
      )}
      {context === "value" && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text className="text-sm">{label}</Text>
        </div>
      )}
    </>
  );

  useEffect(() => () => setAsyncOptions([]), []);

  useEffect(() => {
    if (value !== undefined) {
      const selectedOption = asyncOptions.find(
        (option) => option.name === value
      );
      if (!selectedOption) {
        onSetError();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asyncOptions]);

  return (
    <>
      <Stack direction="row">
        <Text className="text-xs">{title}</Text>
        {description && <InfoTooltip content={description} />}
      </Stack>
      <div style={{ position: "relative" }}>
        <AsyncSelect
          key={JSON.stringify(nodeFlowData)} // to reload async select whenever flowdata changed
          styles={customStyles}
          value={getSelectedValue(value) || getDefaultOptionValue()}
          formatOptionLabel={formatOptionLabel}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.name}
          loadOptions={loadOptions}
          defaultOptions
          onChange={onChange}
          onMenuOpen={onMenuOpen}
        />
        <button
          style={{
            backgroundColor:
              mode === "dark" ? "rgb(32,36,37)" : "rgb(241,245,249)",
            minHeight: 10,
            height: 27,
            width: 30,
            position: "absolute",
            right: 3,
            top: 0,
            bottom: 0,
            margin: "auto",
            border: "none",
            cursor: "pointer",
          }}
          title="Clear Selection"
          type="button"
          onClick={() => onChange(null)}
        >
          <IconX className="w-[18px] h-[18px] text-slate-700 dark:text-slate-200" />
        </button>
      </div>
      {error && (
        <span style={{ color: "red", fontSize: "0.7rem", fontStyle: "italic" }}>
          {formatErrorMessage(error)}
        </span>
      )}

      <OptionParamsResponse value={value} options={asyncOptions} />
    </>
  );
};

AsyncSelectWrapper.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.string,
  loadMethod: PropTypes.string,
  loadFromDbCollections: PropTypes.array,
  nodeFlowData: PropTypes.object,
  error: PropTypes.string,
  onChange: PropTypes.func,
  onMenuOpen: PropTypes.func,
  onSetError: PropTypes.func,
};

export default AsyncSelectWrapper;
