import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  selectEmailVerificationLoading,
  selectIsAuthenticate,
  verificationEmailAsync,
} from "../../store/auth/authSlice";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../general/Loading";
import { useCallback, useEffect, useState } from "react";
import { HomeUrl, SignInUrl } from "../../utils/urls";
import { ReactComponent as VerifyIcon } from "./../../assets/icons/verify.svg";
import { ReactComponent as ErrorIcon } from "./../../assets/icons/error-warning.svg";
import Button from "../buttons/Button";
import { AnimatePresence, motion } from "framer-motion";
import Text from "../general/Text";

const EmailVerification = () => {
  const dispatch = useAppDispatch();
  const isAuthenticate = useAppSelector(selectIsAuthenticate);
  const navigate = useNavigate();
  const loading = useAppSelector(selectEmailVerificationLoading);
  // const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState<boolean | undefined>(undefined);
  const { token } = useParams();

  const handleVerify = useCallback(() => {
    if (token) {
      // setLoading(true);
      setStatus(undefined);
      dispatch(verificationEmailAsync({ token })).then((action) => {
        // setLoading(false);
        if (action.type === "auth/verification-email/fulfilled") {
          setStatus(true);
        } else {
          setStatus(false);
        }
      });

      // setTimeout(() => {
      //   setLoading(false);
      //   setStatus(true);
      // }, 4000);
    }
  }, [dispatch, token]);

  useEffect(() => {
    handleVerify();
  }, [handleVerify]);

  const handleContinue = () => {
    if (isAuthenticate) {
      navigate(HomeUrl);
    } else {
      navigate(SignInUrl);
    }
  };

  const handleTryAgain = () => handleVerify();

  return (
    <>
      <Text
        type="title"
        className="mb-7 text-center"
        value={`Email verification`}
      />
      <AnimatePresence>
        <div className="w-full flex justify-center items-center min-h-[130px]">
          {loading && <Loading />}
          {!loading && status && (
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0 }}
              className="flex flex-col justify-center items-center"
            >
              <VerifyIcon className="w-24 text-green-600" />
              <Text
                type="text"
                className="mb-4 text-center"
                value={`Your email address has been successfully verified`}
              />
              <Button type="light" buttonProps={{ onClick: handleContinue }}>
                {isAuthenticate ? "Continue" : "Login"}
              </Button>
            </motion.div>
          )}
          {!loading && !status && (
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0 }}
              className="flex flex-col justify-center items-center"
            >
              <ErrorIcon className="w-24 text-red-500" />
              <Text
                type="text"
                className="mb-4 text-center"
                value={`There was a problem verifying your email address`}
              />
              <Button type="light" buttonProps={{ onClick: handleTryAgain }}>
                Try again
              </Button>
            </motion.div>
          )}
        </div>
      </AnimatePresence>
    </>
  );
};
export default EmailVerification;
