import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getWeb3AuthEndpointAsync,
  refreshWeb3AuthEncryptionKeyAsync,
  selectWeb3AuthEndpoint,
  selectWeb3AuthEndpointActionLoading,
  selectWeb3AuthEndpointLoading,
  selectWeb3AuthEndpointUpdateLoading,
  updateWeb3AuthEndpointAsync,
} from "../../../store/web3-auth/web3AuthEndpointSlice";
import Text from "../../general/Text";
import Input from "../../inputs/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { Web3EndpointUpdateSchema } from "../../../utils/validations";
import { useEffect, useState } from "react";
import Checkbox from "../../inputs/Checkbox";
import {
  getWeb3NetworksAsync,
  selectWeb3Networks,
  selectWeb3NetworksLoading,
} from "../../../store/web3-auth/web3PublicSlice";
import { MultiSelect, SelectOption } from "../../inputs/Select";
import Card from "../../general/Card";
import UserDataCard from "./UserDataCard";
import Button from "../../buttons/Button";
import { getWeb3AuthEndpointsAsync } from "../../../store/web3-auth/web3AuthEndpointsSlice";
import { HiddenKey } from "../../general/HiddenKey";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import QuestionModal from "../../modals/QuestionModal";

const Web3AuthEndpointSettingTab = () => {
  const endpoint = useAppSelector(selectWeb3AuthEndpoint);
  const endpointLoading = useAppSelector(selectWeb3AuthEndpointLoading);

  const web3Networks = useAppSelector(selectWeb3Networks);
  const web3NetworksLoading = useAppSelector(selectWeb3NetworksLoading);

  const updateLoading = useAppSelector(selectWeb3AuthEndpointUpdateLoading);

  const dispatch = useAppDispatch();

  const [selectedNetworksOptions, setSelectedNetworksOptions] = useState<
    Array<SelectOption<number>>
  >([]);

  const [updateEncryptionKey, setUpdateEncryptionKey] = useState(false);
  const actionLoading = useAppSelector(selectWeb3AuthEndpointActionLoading);

  const {
    setValue,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Web3EndpointUpdateSchema),
  });

  const handleUpdateApp = (data: any) => {
    // console.log(data);
    if (endpoint)
      dispatch(
        updateWeb3AuthEndpointAsync({
          id: endpoint.Id,
          data: {
            ...data,
            Networks: [...selectedNetworksOptions.map((o) => o.value)],
          },
        })
      ).then((action) => {
        if (action.type === "web3-auth/endpoint/update/fulfilled") {
          dispatch(getWeb3AuthEndpointAsync(endpoint.Id.toString())).then(
            () => {
              dispatch(getWeb3AuthEndpointsAsync());
            }
          );
        }
      });
  };

  useEffect(() => {
    if (endpoint) {
      setValue("Name", endpoint.Name);
      setValue("TokenLifeTime", endpoint.TokenLifeTime);
      // setValue("JWTEncryptionKey", endpoint.EncyptionKey);
      setValue("EnabledAvatar", endpoint.EnabledAvatar);
      setSelectedNetworksOptions([
        ...endpoint.NetworkItems.map((no) => ({
          label: no.NetworkName,
          value: no.Id,
        })),
      ]);
    }
  }, [endpoint, setValue]);

  useEffect(() => {
    if (web3Networks.length === 0) {
      dispatch(getWeb3NetworksAsync());
    }
  }, [dispatch, web3Networks.length]);

  return (
    <div className="w-full flex flex-col gap-8">
      <Card title="App setting">
        <div className="w-full md:w-3/4 lg:w-1/2">
          <form onSubmit={handleSubmit(handleUpdateApp)}>
            <div className="flex flex-col w-full  gap-4">
              <div className="flex flex-col md:flex-row md:items-center gap-1">
                <div className="w-36">
                  <Text className="text-sm whitespace-nowrap">App Name:</Text>
                </div>
                <div className="flex-1">
                  <Input
                    inputProps={{ ...register("Name") }}
                    error={errors.Name?.message}
                  />
                </div>
              </div>

              <div className="flex flex-col md:flex-row md:items-center gap-1">
                <div className="w-36">
                  <Text className="text-sm">JWT life time: </Text>
                </div>
                <div className="flex-1">
                  <Input
                    inputProps={{ ...register("TokenLifeTime") }}
                    error={errors.TokenLifeTime?.message}
                  />
                </div>
              </div>

              <div className="flex flex-col md:flex-row md:items-center gap-1">
                <div className="w-36">
                  <Text className="text-sm">JWT Encryption key: </Text>
                </div>
                <div className="flex flex-1 items-center">
                  <HiddenKey
                    text={endpoint?.EncyptionKey}
                    textClassName="!w-56 md:!w-auto"
                  />
                  <Button
                    type="icon"
                    tooltip="Refresh Encryption key"
                    buttonProps={{
                      onClick: (e) => {
                        e.preventDefault();
                        setUpdateEncryptionKey(true);
                      },
                    }}
                  >
                    <RefreshIcon className="w-5 text-slate-800 dark:text-slate-400" />
                  </Button>
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:items-center gap-1">
                <Checkbox
                  inputProps={{ ...register("EnabledAvatar") }}
                  id="TermsOfService"
                  label="enable avatar"
                  error={errors.EnabledAvatar?.message}
                />
              </div>
              <div className="flex flex-col md:flex-row md:items-center gap-1">
                <div className="w-36">
                  <Text className="text-sm">Chains</Text>
                </div>
                <div className="flex-1">
                  <MultiSelect<number>
                    label={
                      <Text className="text-xs" type="subtext">
                        allow user to sign on multiple network and wallets
                      </Text>
                    }
                    options={web3Networks.map((n) => ({
                      label: n.NetworkName,
                      value: n.Id,
                    }))}
                    value={selectedNetworksOptions}
                    onChange={setSelectedNetworksOptions}
                    clearable
                    loading={web3NetworksLoading}
                    emptyString="Select chains"
                    error={
                      selectedNetworksOptions.length === 0
                        ? "Select atleast one network!"
                        : undefined
                    }
                  />
                </div>
              </div>
              <div className="flex gap-1 items-center justify-end md:justify-start">
                <div className="w-36"></div>
                <Button
                  buttonProps={{
                    type: "submit",
                    disabled:
                      endpointLoading || selectedNetworksOptions.length === 0,
                  }}
                  loading={updateLoading}
                  type="primary"
                >
                  Save changes
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Card>
      <UserDataCard />
      <QuestionModal
        isOpen={updateEncryptionKey}
        onClose={() => setUpdateEncryptionKey(false)}
        title="Refresh Encryption key"
        description="Are you sure you want to refresh the token?"
        onConfirm={() => {
          endpoint &&
            dispatch(
              refreshWeb3AuthEncryptionKeyAsync({ id: endpoint.Id })
            ).then((action) => {
              if (
                action.type ===
                "web3-auth/endpoint/encryption-key/update/fulfilled"
              ) {
                dispatch(getWeb3AuthEndpointAsync(endpoint.Id.toString()));
              }
              setUpdateEncryptionKey(false);
            });
        }}
        loading={actionLoading}
      />
    </div>
  );
};

export default Web3AuthEndpointSettingTab;
