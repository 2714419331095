import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  getS3WidgetAsync,
  selectLastHealActivity,
  selectLastHealActivityLoading,
  selectS3EndTime,
  selectS3StartTime,
} from "../../../../store/s3/overviewSlice";
import { S3WidgetType } from "../../../../types/s3";
import StatWidget from "./freams/StatWidget";
import { ReactComponent as HealIcon } from "./../../../../assets/icons/heal-svgrepo-com.svg";

const chartType: S3WidgetType = "lastHealActivity";
const chartCode: number = 80;

const LastHealActivityWidget = () => {
  const data = useAppSelector(selectLastHealActivity);
  const loading = useAppSelector(selectLastHealActivityLoading);
  const startTime = useAppSelector(selectS3StartTime);
  const endTime = useAppSelector(selectS3EndTime);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      getS3WidgetAsync({ key: chartType, n: chartCode, startTime, endTime })
    );
  }, [dispatch, endTime, startTime]);

  return (
    <StatWidget
      title="Time since last Heal Activity"
      value={""}
      dataLoading={loading}
      icon={<HealIcon className="w-3 text-slate-800 dark:text-slate-300" />}
    />
  );
};

export default LastHealActivityWidget;
