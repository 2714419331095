import NotData from "../../layouts/NotData";
import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useState } from "react";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";

import { useNavigate } from "react-router-dom";
import { DatabaseServiceGeneralInformationUrl } from "../../../utils/urls";
import Dropdown from "../../general/Dropdown";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import Text from "../../general/Text";
import { useSearch } from "../../../providers/SearchProvider";
import {
  getDBSCurrentQuerriesAsync,
  selectDBSCurrentQueries,
  selectDBSCurrentQueriesLoading,
  selectDatabaseService,
} from "../../../store/database/serviceSlice";
import { DBSCurrentQuery } from "../../../types/database";
import { generateServicePermissions } from "../../../pages/databases/ServicePage";
import Switcher from "../../inputs/Switcher";
import Highlighter from "react-highlight-words";

const DBSCurrentQueriesTab = () => {
  const service = useAppSelector(selectDatabaseService);

  const currentQueries = useAppSelector(selectDBSCurrentQueries);
  const currentQueriesLoading = useAppSelector(selectDBSCurrentQueriesLoading);
  const [filteredQueries, setFilteredQueries] = useState<
    Array<DBSCurrentQuery>
  >([]);

  const [showIdlConnections, setShowIdlConnections] = useState(true);
  const [showActiveConnections, setShowActiveConnections] = useState(true);
  const [autoRefresh, setAutoRefresh] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (service) {
      const permissions = generateServicePermissions().currentQueries;
      if (!permissions.includes(service.engine)) {
        navigate(DatabaseServiceGeneralInformationUrl(service.id));
      } else {
        dispatch(
          getDBSCurrentQuerriesAsync({ id: service.id, engine: service.engine })
        );
      }
    }
  }, [dispatch, navigate, service]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (service && autoRefresh) {
      interval = setInterval(() => {
        dispatch(
          getDBSCurrentQuerriesAsync({
            id: service.id,
            engine: service.engine,
            withoutLoading: true,
          })
        );
      }, 10000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, service, autoRefresh]);

  useEffect(() => {
    const lookedUp = currentQueries.filter((q) =>
      q.query?.toLowerCase().includes(searchValue.toLowerCase())
    );

    if (showIdlConnections) {
      lookedUp.filter(
        (q) =>
          q.state === "IDLE" ||
          q.state === "IDLE_IN_TRANSACTION" ||
          q.state === "IDLE_IN_TRANSACTION_ABORTED"
      );
    }

    if (showActiveConnections) {
      lookedUp.filter((q) => q.state === "ACTIVE");
    }
    setFilteredQueries(lookedUp);
  }, [
    currentQueries,
    dispatch,
    searchValue,
    showActiveConnections,
    showIdlConnections,
  ]);

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | Database - queries in progress
        </title>
        <meta name="description" content="" />
      </Helmet>

      {service && (
        <>
          <div className="flex items-center justify-between">
            {/* <div className="items-center justify-between flex flex-1 transition duration-150">
              <Text className="font-medium text-lg">Queries in progress</Text>
            </div> */}
            {/* <div className="">
              {service && users && (
                <Button
                  type="primary"
                  buttonProps={{
                    onClick: () =>
                      navigate(DatabaseUsersCreateUrl(service?.id)),
                    disabled: isAnyUserDeleting,
                  }}
                  buttonClassName="group"
                >
                  Add user
                  <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
                </Button>
              )}
            </div> */}
          </div>
          <div className="flex items-center gap-7">
            <div className="flex flex-col gap-1">
              <Text className="text-xs font-medium">Show idle connections</Text>
              <Switcher
                on={showIdlConnections}
                onToggle={setShowIdlConnections}
              />
            </div>
            <div className="flex flex-col gap-1">
              <Text className="text-xs font-medium">
                Show active connections
              </Text>
              <Switcher
                on={showActiveConnections}
                onToggle={setShowActiveConnections}
              />
            </div>
            <div className="flex flex-col gap-1">
              <Text className="text-xs font-medium">
                Authomatically refresh page
              </Text>
              <Switcher on={autoRefresh} onToggle={setAutoRefresh} />
            </div>
          </div>
          <div className="mt-5 w-full">
            <Table loading={currentQueriesLoading}>
              <TableHead>
                <TableRow>
                  <TableTH lable="Query" />
                  <TableTH lable="PID" />
                  <TableTH lable="Duration" />
                  <TableTH lable="Database" />
                  <TableTH lable="Client address" />
                  <TableTH lable="Application name" />
                  <TableTH lable="" />
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredQueries.map((query, i) => (
                  <TableRow key={i}>
                    <TableTD className="cursor-pointer">
                      <div className="flex items-center flex-row justify-between space-x-3">
                        <Highlighter
                          highlightClassName="bg-primary-100 dark:bg-primary-300 rounded-sm"
                          className="whitespace-nowrap max-w-[240px] text-ellipsis overflow-hidden text-sm font-medium"
                          searchWords={[searchValue]}
                          autoEscape={true}
                          textToHighlight={query.query}
                        />
                      </div>
                    </TableTD>

                    <TableTD className="cursor-pointer">
                      <Text className="text-xs md:text-sm">{query.pid}</Text>
                    </TableTD>

                    <TableTD className="cursor-pointer">
                      <Text className="text-xs md:text-sm">
                        {query.queryDuration}
                      </Text>
                    </TableTD>
                    <TableTD className="cursor-pointer">
                      <Text className="text-xs md:text-sm">
                        {query.databaseName}
                      </Text>
                    </TableTD>

                    <TableTD className="cursor-pointer">
                      <Text className="text-xs md:text-sm">
                        {query.clientHostname}
                      </Text>
                    </TableTD>
                    <TableTD className="cursor-pointer">
                      <Text className="text-xs md:text-sm">
                        {query.applicationName}
                      </Text>
                    </TableTD>

                    <TableTD className="w-32 gap-1">
                      <div className="h-full w-full inline-flex items-center justify-center gap-1">
                        <div className="w-8 flex justify-center items-center ">
                          <Dropdown
                            positionClassName="!w-48"
                            menu={
                              [
                                // {
                                //   key: "1",
                                //   label: (
                                //     <div className="flex items-center gap-1">
                                //       <RefreshIcon className="w-4" />
                                //       Reset the password
                                //     </div>
                                //   ),
                                //   onClick: (_, close) => {
                                //     close();
                                //     setDBSUserId(user.id);
                                //   },
                                //   disabled:
                                //     loading ||
                                //     isAnyUserDeleting ||
                                //     user.status === "PENDING",
                                // },
                                // {
                                //   type: "divider",
                                // },
                                // {
                                //   key: "end",
                                //   label: (
                                //     <div className="flex items-center gap-1">
                                //       <ArchiveIcon className="w-4" />
                                //       Delete
                                //     </div>
                                //   ),
                                //   danger: true,
                                //   onClick: (_, close) => {
                                //     close();
                                //     setDeleteUser(user.id);
                                //   },
                                //   disabled:
                                //     loading ||
                                //     isAnyUserDeleting ||
                                //     user.status === "PENDING",
                                // },
                              ]
                            }
                            type="simple"
                          >
                            <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                              <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                            </div>
                          </Dropdown>
                        </div>
                        <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" />
                      </div>
                    </TableTD>
                  </TableRow>
                ))}

                {currentQueries.length === 0 && !currentQueriesLoading && (
                  <TableRow withoutHoverStyle={true}>
                    <TableTD colSpan={10} className="!border-0">
                      <NotData text="No queries have been run" />
                    </TableTD>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          {/* <QuestionModal
            title="Reset password"
            description="Are you sure you want to reset the password for this user?"
            isOpen={DBSUserId !== null}
            onClose={() => setDBSUserId(null)}
            onConfirm={() => {
              DBSUserId &&
                service &&
                dispatch(
                  resetDBSUsersAsync({
                    id: service.id,
                    engine: service.engine,
                    userId: DBSUserId,
                    data: users,
                  })
                ).then((action) => {
                  if (action.type === "service/users/reset/fulfilled") {
                    dispatch(getDatabaseServicesAsync());
                    dispatch(getDatabaseServiceAsync({ id: service.id }));
                  } else {
                  }
                  setDBSUserId(null);
                });
            }}
            loading={actionLoading}
            confirmButtonType="danger"
          />
          <QuestionModal
            isOpen={deleteUser !== null}
            onClose={() => setDeleteUser(null)}
            onConfirm={() => {
              deleteUser &&
                service &&
                dispatch(
                  deleteDBSUserAsync({
                    id: service.id,
                    engine: service.engine,
                    userId: deleteUser,
                  })
                ).then((action) => {
                  if (action.type === "service/user/delete/fulfilled") {
                    dispatch(getDatabaseServicesAsync());
                    dispatch(getDatabaseServiceAsync({ id: service.id }));
                    dispatch(
                      getDBSUsersAsync({
                        id: service.id,
                        engine: service.engine,
                      })
                    );
                  } else {
                  }
                  setDeleteUser(null);
                });
            }}
            loading={actionLoading}
            confirmButtonType="danger"
          /> */}
        </>
      )}
    </>
  );
};

export default DBSCurrentQueriesTab;
