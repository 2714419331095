import classNames from "classnames";
import { ButtonHTMLAttributes } from "react";
import Loading from "../general/Loading";
import Tooltip, { TooltipPlaces } from "../general/Tooltip";

export type ButtonTypes =
  | "default"
  | "primary"
  | "light"
  | "icon"
  | "danger"
  | "danger-light";

const Button = ({
  children,
  buttonProps,
  type,
  size,
  startIcon,
  endIcon,
  buttonClassName,
  contentClassName,
  loading,
  loadingSize,
  tooltip,
  tooltipPlace,
}: {
  children?: React.ReactNode;
  buttonProps?: ButtonHTMLAttributes<any>;
  type?: ButtonTypes;
  size?: "small" | "medium" | "large" | "full";
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  buttonClassName?: string;
  contentClassName?: string;
  loading?: boolean;
  loadingSize?: number;
  tooltip?: string;
  tooltipPlace?: TooltipPlaces;
}) => {
  return (
    <Tooltip content={tooltip || ""} place={tooltipPlace}>
      <button
        {...buttonProps}
        className={classNames(
          buttonClassName,
          " focus:ring-2 focus:ring-primary-50 rounded-xl focus:outline-none transition-all disabled:cursor-not-allowed dark:focus:ring-secondary-600  w-max ",
          {
            "text-primary-400 bg-transparent hover:bg-primary-50 hover:text-primary-500 hover:border-primary-100 border-2 border-slate-200 dark:bg-zinc-800 dark:hover:bg-zinc-700 dark:text-white dark:border-secondary-700 disabled:border-secondary-200 disabled:hover:bg-transparent disabled:dark:hover:bg-transparent disabled:text-secondary-400 disabled:dark:text-secondary-400":
              type === "default" || type === undefined,
            "text-white bg-primary-500 hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-200 dark:hover:shadow-lg dark:hover:shadow-secondary-700 disabled:bg-secondary-300 border-2 border-primary-500 disabled:border-secondary-300  disabled:text-secondary-400 disabled:dark:bg-secondary-500 disabled:dark:border-secondary-500  disabled:dark:text-secondary-300 disabled:hover:shadow-none":
              type === "primary",
            "text-primary-500 bg-primary-100/30 hover:bg-primary-100/50 border-2 border-primary-100 hover:border-primary-200 dark:bg-dark-2 dark:hover:bg-zinc-700 dark:text-white dark:border-secondary-600  disabled:dark:bg-secondary-600 disabled:bg-secondary-200 disabled:text-secondary-400":
              type === "light",
            "aspect-square focus:!ring-0 hover:scale-110 !rounded-full bg-transparent !p-0":
              type === "icon",
            "text-red-500 bg-transparent hover:bg-red-500/10 border-2 border-red-500 disabled:border-secondary-400 disabled:hover:bg-transparent disabled:text-secondary-400 disabled:dark:text-secondary-400":
              type === "danger",
            "text-red-500 bg-transparent hover:bg-red-500/10 border-2 border-transparent disabled:hover:bg-transparent disabled:text-secondary-400 disabled:dark:text-secondary-400":
              type === "danger-light",
            "font-medium text-sm px-4 h-9":
              size === "medium" || size === undefined,
            "font-normal text-xs px-3 h-7": size === "small",
            "font-semibold text-base px-5 h-11": size === "large",
          }
        )}
      >
        <div
          className={classNames(
            contentClassName,
            "flex items-center justify-center gap-1"
          )}
        >
          {!loading && (
            <>
              {startIcon}
              {children}
              {endIcon}
            </>
          )}
          {loading && (
            <Loading
              borderSize={2}
              size={loadingSize ? loadingSize : type === "icon" ? 16 : 20}
              color={type === "danger" ? "#D52B1E" : undefined}
              borderColor={type === "danger" ? "#fbb4b485" : undefined}
            />
          )}
        </div>
      </button>
    </Tooltip>
  );
};
export default Button;
