import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

// material-ui
import { Box, IconButton } from "@mui/material";

// third party
import ReactJson from "react-json-view";
import socketIOClient from "socket.io-client";

// project imports
import Button from "../../../buttons/Button";
import Text from "../../../general/Text";
import ExpandDataDialog from "../dialog/ExpandDataDialog";
import CopyableKey from "../../../general/CopyableKey";
import HTMLDialog from "../dialog/HTMLDialog";
import AttachmentDialog from "../dialog/AttachmentDialog";

import { ReactComponent as IconExclamationMark } from "./../../../../assets/icons/exclamation-circle.svg";
import { ReactComponent as IconX } from "./../../../../assets/icons/close.svg";
import { ReactComponent as IconArrowsMaximize } from "./../../../../assets/icons/arrows-pointing-out.svg";
import { ReactComponent as IconArrowUpRightCircle } from "./../../../../assets/icons/arrow-top-right-on-square.svg";

// utils
import {
  copyToClipboard,
  getWorkflowSocketIOBaseUrl,
  getWorkflowWebhookUrl,
} from "./../../../../utils/wfHelper";
import useDarkMode from "../../../../hooks/useDarkMode";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  selectWorkflowNodeTest,
  selectWorkflowNodeTestLoading,
  testNodeAsync,
} from "../../../../store/workflows/workflowSlice";
import { getTunnelURLApi } from "../../../../apis/workflowsAPI";
import { BASE_URL } from "../../../../apis";
import { getLocalStorage } from "../../../../utils/localStorage";
// ==============================|| OUTPUT RESPONSES ||============================== //

const OutputResponses = ({
  nodeId,
  nodeParamsType,
  nodeFlowData,
  nodes,
  edges,
  workflow,
  onSubmit,
}) => {
  const { mode } = useDarkMode();
  const dispatch = useAppDispatch();

  const [outputResponse, setOutputResponse] = useState([]);
  const [errorResponse, setErrorResponse] = useState(null);
  const [nodeName, setNodeName] = useState(null);
  const [nodeType, setNodeType] = useState(null);
  const [nodeLabel, setNodeLabel] = useState(null);
  const [isTestNodeBtnDisabled, disableTestNodeBtn] = useState(true);
  const [testNodeLoading, setTestNodeLoading] = useState(null);
  const [showHTMLDialog, setShowHTMLDialog] = useState(false);
  const [HTMLDialogProps, setHTMLDialogProps] = useState({});
  const [showAttachmentDialog, setShowAttachmentDialog] = useState(false);
  const [attachmentDialogProps, setAttachmentDialogProps] = useState({});
  const [showExpandDialog, setShowExpandDialog] = useState(false);
  const [expandDialogProps, setExpandDialogProps] = useState(null);
  const [tunnelURL, setTunnelURL] = useState("");

  const testRes = useAppSelector(selectWorkflowNodeTest);
  const testResLoading = useAppSelector(selectWorkflowNodeTestLoading);

  const onTestNodeClick = (nodeType) => {
    /* If workflow is already deployed, stop it first to be safe.
     *  Because it could cause throttled calls
     */
    if (workflow?.deployed) {
      setTestNodeLoading(false);
      alert(
        "Testing trigger requires stopping workflow. Please stop workflow first"
      );
      return;
    }

    const testNodeBody = {
      nodes,
      edges,
      nodeId,
    };

    try {
      if (nodeType === "webhook") {
        setTestNodeLoading(true);
        // const baseURL = getWorkflowSocketIOBaseUrl();
        // let user = getLocalStorage("login", null);
        // const socket = socketIOClient("", {
        //   path: baseURL,
        //   extraHeaders: { Authorization: `Bearer ${user?.JwtToken}` },
        // });
        // socket.on("connect", async () => {
        //   testNodeBody.clientId = socket.id;
        //   //   testNodeApi.request(nodeFlowData.name, testNodeBody);
        //   dispatch(
        //     testNodeAsync({ name: nodeFlowData.name, data: testNodeBody })
        //   ).then((action) => {
        //     if (action.type === "workflow/node/test/rejected") {
        //       console.log("testNodeAsync webhook:", action);
        //       handleErrorTest(""); //TODO
        //     }
        //   });
        // });
        // socket.on("testWebhookNodeResponse", (data) => {
        //   setOutputResponse(data);
        //   setTestNodeLoading(false);
        //   const formValues = {
        //     submit: true,
        //     needRetest: null,
        //     output: data,
        //   };
        //   onSubmit(formValues, "outputResponses");
        //   socket.io.disconnect();
        // });

        //updated
        let user = getLocalStorage("login", null);
        const selectedEnvId = getLocalStorage("env", null)?.Id;

        const proxySocket = socketIOClient("", {
          path: "/wf-socket",
        });

        // const proxySocket = socketIOClient("", {
        //   path: "/wf-socket",
        // });

        proxySocket.on("connect", () => {
          proxySocket.emit("selectEnvironment", selectedEnvId, user?.JwtToken);
        });

        proxySocket.on("proxyConnected", async (serverSocketId) => {
          testNodeBody.clientId = serverSocketId;
          dispatch(
            testNodeAsync({ name: nodeFlowData.name, data: testNodeBody })
          ).then((action) => {
            if (action.type === "workflow/node/test/rejected") {
              handleErrorTest(""); //TODO
            }
          });
        });

        proxySocket.on("testWebhookNodeResponse", (data) => {
          setOutputResponse(data);
          setTestNodeLoading(false);
          const formValues = {
            submit: true,
            needRetest: null,
            output: data,
          };
          onSubmit(formValues, "outputResponses");
          proxySocket.disconnect();
        });
      } else {
        dispatch(
          testNodeAsync({ name: nodeFlowData.name, data: testNodeBody })
        ).then((action) => {
          if (action.type === "workflow/node/test/rejected") {
            console.log("testNodeAsync:", action);
            handleErrorTest(""); //TODO
          }
        });
      }
    } catch (error) {
      setTestNodeLoading(false);
      setOutputResponse([]);
      setErrorResponse(error);
      console.error(error);
    }
  };

  const checkIfTestNodeValid = useCallback(() => {
    const paramsTypes = nodeParamsType.filter(
      (type) => type !== "outputResponses"
    );
    for (let i = 0; i < paramsTypes.length; i += 1) {
      const paramType = paramsTypes[i];

      if (!nodeFlowData[paramType] || !nodeFlowData[paramType].submit) {
        return true;
      }
    }
    return false;
  }, [nodeFlowData, nodeParamsType]);

  const openAttachmentDialog = (outputResponse) => {
    const dialogProp = {
      title: "Attachments",
      executionData: outputResponse,
    };
    setAttachmentDialogProps(dialogProp);
    setShowAttachmentDialog(true);
  };

  const openHTMLDialog = (executionData) => {
    const dialogProp = {
      title: "HTML",
      executionData,
    };
    setHTMLDialogProps(dialogProp);
    setShowHTMLDialog(true);
  };

  const onExpandDialogClicked = (executionData) => {
    const dialogProp = {
      title: `Output Responses: ${nodeLabel} `,
      data: executionData,
    };
    setExpandDialogProps(dialogProp);
    setShowExpandDialog(true);
  };

  useEffect(() => {
    if (
      nodeFlowData &&
      nodeFlowData.outputResponses &&
      nodeFlowData.outputResponses.output
    ) {
      setOutputResponse(nodeFlowData.outputResponses.output);
    } else {
      setOutputResponse([]);
    }

    disableTestNodeBtn(checkIfTestNodeValid());
  }, [checkIfTestNodeValid, nodeFlowData, nodeParamsType]);

  useEffect(() => {
    if (nodes && nodeId) {
      const selectedNode = nodes.find((nd) => nd.id === nodeId);
      if (selectedNode) {
        setNodeName(selectedNode.data.name);
        setNodeType(selectedNode.data.type);
        setNodeLabel(selectedNode.data.label);
      }
    }
  }, [nodes, nodeId]);

  // Test node successful
  useEffect(() => {
    if (testRes && nodeType && nodeType !== "webhook") {
      setOutputResponse(testRes);
      setErrorResponse(null);
      const formValues = {
        submit: true,
        needRetest: null,
        output: testRes,
      };
      onSubmit(formValues, "outputResponses");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testRes]);

  useEffect(() => {
    try {
      getTunnelURLApi().then((res) => {
        if (res.response.status === 200) setTunnelURL(res.data.Result);
      });
    } catch (e) {
      // console.error("getTunnelURLApi Error:", e);
    }
  }, []);

  // Test node error
  const handleErrorTest = useCallback(
    (error) => {
      if (nodeType && nodeType !== "webhook") {
        let errorMessage = "Unexpected Error.";

        //   if (testNodeApi.error.response && testNodeApi.error.response.data) {
        //     errorMessage = testNodeApi.error.response.data;
        //   } else if (testNodeApi.error.message) {
        //     errorMessage = testNodeApi.error.message;
        //   }

        setErrorResponse(errorMessage);
        setOutputResponse([]);
        const formValues = {
          submit: null,
          needRetest: null,
          output: [],
        };
        onSubmit(formValues, "outputResponses");
      }
    },
    [nodeType, onSubmit]
  );

  // Test node loading
  useEffect(() => {
    if (nodeType && nodeType !== "webhook") setTestNodeLoading(testResLoading);
  }, [nodeType, testResLoading]);

  return (
    <>
      <div className="w-full">
        {nodeFlowData &&
          nodeFlowData.outputResponses &&
          nodeFlowData.outputResponses.needRetest && (
            <Text
              className="flex items-center text-sm py-1"
              type="warning-alert"
            >
              <IconExclamationMark className="w-5 h-5" />
              Retest the node for updated parameters
            </Text>
          )}
        {nodeName &&
          (nodeName === "webhook" ||
            nodeName === "chainLinkFunctionWebhook") && (
            <Box sx={{ mb: 3 }}>
              <Text className="text-sm">Local URL:</Text>
              <CopyableKey
                text={getWorkflowWebhookUrl(nodeFlowData.webhookEndpoint)}
              />
              <Button
                size="small"
                buttonClassName="mt-2"
                buttonProps={{
                  onClick: () =>
                    window.open(
                      getWorkflowWebhookUrl(nodeFlowData.webhookEndpoint),
                      "_blank"
                    ),
                }}
              >
                <IconArrowUpRightCircle className="w-4 h-4" />
                Open in New Tab
              </Button>

              {tunnelURL && (
                <div>
                  <Text className="text-sm">Tunnel URL:</Text>
                  <CopyableKey
                    text={`${tunnelURL}api/v1/webhook/${nodeFlowData.webhookEndpoint}`}
                  />
                  <Button
                    size="small"
                    buttonClassName="mt-2"
                    buttonProps={{
                      onClick: () =>
                        window.open(
                          `${tunnelURL}/api/v1/webhook/${nodeFlowData.webhookEndpoint}`,
                          "_blank"
                        ),
                    }}
                  >
                    <IconArrowUpRightCircle className="w-4 h-4" />
                    Open in New Tab
                  </Button>
                </div>
              )}
            </Box>
          )}
        {errorResponse && (
          <Box sx={{ mb: 2 }}>
            {/* <Chip sx={{ mb: 1 }} icon={<IconX />} label="Error" color="error" /> */}
            <div>
              <Text type="error-alert" className="flex items-center">
                <IconX className="w-5 h-5" /> Error
              </Text>
            </div>
            <Text className="!text-error text-sm">{errorResponse}</Text>
          </Box>
        )}
        <Box sx={{ position: "relative" }}>
          <ReactJson
            theme={mode === "dark" ? "ocean" : "rjv-default"}
            collapsed
            style={{ padding: 10, borderRadius: 10 }}
            src={outputResponse}
            enableClipboard={(e) => copyToClipboard(e)}
          />
          <IconButton
            size="small"
            sx={{
              height: 25,
              width: 25,
              position: "absolute",
              top: 5,
              right: 5,
            }}
            title="Expand Data"
            color="primary"
            onClick={() => onExpandDialogClicked(outputResponse)}
          >
            <IconArrowsMaximize />
          </IconButton>
          <div>
            {outputResponse.map((respObj, respObjIndex) => (
              <div key={respObjIndex}>
                {respObj.html && <Text className="text-sm">HTML</Text>}
                {respObj.html && (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      maxHeight: "100%",
                      overflow: "auto",
                      backgroundColor: "white",
                      borderRadius: 5,
                    }}
                    dangerouslySetInnerHTML={{ __html: respObj.html }}
                  />
                )}
                {respObj.html && (
                  <Button
                    size="small"
                    className="mt-2"
                    buttonProps={{
                      onClick: () => openHTMLDialog(outputResponse),
                    }}
                  >
                    View HTML
                  </Button>
                )}

                {respObj.attachments && (
                  <Text className="text-sm">Attachments</Text>
                )}
                {respObj.attachments &&
                  respObj.attachments.map((attachment, attchIndex) => (
                    <div key={attchIndex}>
                      <Text className="text-sm">
                        Item {respObjIndex} |{" "}
                        {attachment.filename
                          ? attachment.filename
                          : `Attachment ${attchIndex}`}
                      </Text>
                      <embed
                        src={attachment.content}
                        width="100%"
                        height="100%"
                        style={{ borderStyle: "solid" }}
                        type={attachment.contentType}
                      />
                      <Button
                        size="small"
                        buttonClassName="mt-2"
                        buttonProps={{
                          onClick: () => openAttachmentDialog(outputResponse),
                        }}
                      >
                        View Attachment
                      </Button>
                    </div>
                  ))}
              </div>
            ))}
          </div>
        </Box>
        <div className="mt-4">
          <Button
            buttonClassName="!w-full"
            buttonProps={{
              onClick: () => onTestNodeClick(nodeType),
              type: "submit",
              disabled: isTestNodeBtnDisabled || testNodeLoading,
            }}
            loading={testNodeLoading}
            type="primary"
          >
            Test Node
          </Button>
        </div>
      </div>

      <AttachmentDialog
        show={showAttachmentDialog}
        dialogProps={attachmentDialogProps}
        onCancel={() => setShowAttachmentDialog(false)}
      />

      <HTMLDialog
        show={showHTMLDialog}
        dialogProps={HTMLDialogProps}
        onCancel={() => setShowHTMLDialog(false)}
      />

      <ExpandDataDialog
        show={showExpandDialog}
        dialogProps={expandDialogProps}
        onCancel={() => setShowExpandDialog(false)}
      />
    </>
  );
};

OutputResponses.propTypes = {
  nodeId: PropTypes.string,
  nodeParamsType: PropTypes.array,
  nodeFlowData: PropTypes.object,
  nodes: PropTypes.array,
  edges: PropTypes.array,
  workflow: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default OutputResponses;
