import Text, { A } from "../../general/Text";
import Input from "../../inputs/Input";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useRef } from "react";
import {
  handleSetWebAppImageURL,
  handleSetWebAppCredentialId,
  selectWebAppImageCredentialId,
  selectWebAppImageURL,
  selectWebAppIsValidateImage,
  selectWebAppSelectedDeployType,
  selectWebAppValidationLoading,
  selectWebAppValidationResponse,
  validateWebAppImageAsync,
} from "../../../store/web-app/webAppCreateSlice";
import Card from "../../general/Card";
import { Link } from "react-router-dom";
import { SettingsRegisteriesUrl } from "../../../utils/urls";
import {
  getCredentialsAsync,
  selectCredentials,
  selectCredentialsLoading,
} from "../../../store/settings/registeries/registeriesSlice";
import { Select2, SelectOption } from "../../inputs/Select";
import { Credential } from "../../../types/registeries";
import { getRegisteryData } from "../../settings/registeries/RegisteriesTab";
import { ImageValidationResponse } from "../../../types/web-app";
import classNames from "classnames";

const SelectDeployingSourceStep = () => {
  const selectedDeployType = useAppSelector(selectWebAppSelectedDeployType);
  return (
    <div className="w-full min-h-[calc(100%-6rem)]">
      {selectedDeployType === "git" && <>Connect a repository</>}
      {selectedDeployType === "image" && <DeployingImageCard />}
    </div>
  );
};

const DeployingImageCard = () => {
  const validationResponse = useAppSelector(selectWebAppValidationResponse);
  return (
    <div className="flex my-5 ">
      <Card title="Deploy an image">
        <DeployingImageFrom />
        {validationResponse && (
          <div className="mt-4 lg:hidden text-sm">
            <WebAppImageValidationMessage {...validationResponse} />
          </div>
        )}
      </Card>
      <div className="hidden lg:block max-w-[300px] py-8 ml-10 text-sm">
        <Text className="text-base font-medium">Registry Credentials</Text>
        <Text type="subtext" className="mt-2 leading-5">
          We currently support <A>private images</A> from GitHub, GitLab, and
          Docker Hub registries. We don’t restrict public image registry types.
        </Text>
        {validationResponse && (
          <div className="mt-4">
            <WebAppImageValidationMessage {...validationResponse} />
          </div>
        )}
      </div>
    </div>
  );
};

export const DeployingImageFrom = () => {
  const imageURL = useAppSelector(selectWebAppImageURL);
  const selectedCredentialId = useAppSelector(selectWebAppImageCredentialId);

  const timeout = useRef<NodeJS.Timeout | null>(null);
  const validationLoading = useAppSelector(selectWebAppValidationLoading);

  const credentials = useAppSelector(selectCredentials);
  const credentialsLoading = useAppSelector(selectCredentialsLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (credentials.length === 0) dispatch(getCredentialsAsync());
  }, [credentials.length, dispatch]);

  const handleChangeImageURL = (event: React.ChangeEvent<HTMLInputElement>) => {
    const url = event.target.value;
    dispatch(handleSetWebAppImageURL(url));
    timeout.current && clearTimeout(timeout.current);
    if (url !== "") {
      timeout.current = setTimeout(
        () => dispatch(validateWebAppImageAsync()),
        2000
      );
    }
  };

  const handleChangeCredential = (option: number | undefined) => {
    dispatch(handleSetWebAppCredentialId(option || null));
    if (imageURL !== "") dispatch(validateWebAppImageAsync());
  };

  return (
    <div className="flex flex-col mt-10 space-y-12 md:space-y-16">
      <div className="flex flex-col md:flex-row lg:flex-col xl:flex-row justify-between space-x-0 md:space-x-4 lg:space-x-0 xl:space-x-4">
        <div className="text-xs md:text-sm mb-2 mr-4">
          <Text className="font-medium">Image URL</Text>
          <Text type="subtext">The image URL for your external image.</Text>
        </div>
        <div className="w-full md:max-w-md lg:w-full xl:max-w-md">
          <Input
            inputProps={{ value: imageURL, onChange: handleChangeImageURL }}
            placeholder="docker.io/library/nginx:latest"
            loading={validationLoading}
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row lg:flex-col xl:flex-row justify-between space-x-0 md:space-x-4 lg:space-x-0 xl:space-x-4">
        <div className="text-xs md:text-sm mb-2 mr-4">
          <Text className="font-medium">Credential (Optional)</Text>
          <Text type="subtext">
            Use a credential to access private images. Manage credentials in{" "}
            <A>
              <Link to={SettingsRegisteriesUrl}>Settings</Link>
            </A>
            .
          </Text>
        </div>
        <div className="w-full md:max-w-md lg:w-full xl:max-w-md">
          <Select2<number>
            emptyString="No credential"
            clearable
            options={[...credentials.map(credentialSelectOption)]}
            value={selectedCredentialId || undefined}
            onChange={handleChangeCredential}
            loading={credentialsLoading}
            disabled={validationLoading}
          />
        </div>
      </div>
    </div>
  );
};

const credentialSelectOption = (
  credential: Credential
): SelectOption<number, Credential> => {
  const { Icon } = getRegisteryData(credential.RegisteryType);
  return {
    label: (
      <div className="flex items-center gap-1">
        {Icon}
        {credential.Name}
      </div>
    ),
    value: credential.Id,
    extraData: credential,
  };
};

export const WebAppImageValidationMessage = ({
  message,
  status,
}: ImageValidationResponse) => {
  return (
    <Text
      className={classNames(
        "font-light leading-5 inline-block px-4 py-3 rounded-md ",
        {
          "bg-red-100 dark:bg-red-500/30": status === "invalid",
          "bg-green-100 dark:bg-green-500/30": status === "valid",
        }
      )}
    >
      {message}
    </Text>
  );
};
export default SelectDeployingSourceStep;
