import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import Text from "../../components/general/Text";
import { TabOptions } from "../../types/tabs";
import RpcEndpointsTab from "../../components/rpc-endpoints/endpoints/RpcEndpointsTab";
import RpcEndpointsPlansTab from "../../components/rpc-endpoints/plans/RpcEndpointsPlansTab";
import Tabs from "../../components/general/Tabs";
import { ReactComponent as ListBulletIcon } from "./../../assets/icons/list-bullet.svg";
import { ReactComponent as CubeIcon } from "./../../assets/icons/cube-transparent.svg";
import { RpcEndpointsPlansUrl, RpcEndpointsUrl } from "../../utils/urls";

export const RpcEndpontsPageMenus: TabOptions = [
  {
    label: (
      <div className="flex items-center gap-1">
        <CubeIcon className="w-3" />
        Endpoints
      </div>
    ),
    url: RpcEndpointsUrl,
    element: <RpcEndpointsTab />,
    testId: "1th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <ListBulletIcon className="w-3" />
        Plans
      </div>
    ),
    url: RpcEndpointsPlansUrl,
    element: <RpcEndpointsPlansTab />,
    testId: "2th-tab",
  },
];

const RpcEndpointsPage = () => {
  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | RPC Endpoints</title>
        <meta
          name="description"
          content="RPC-endpoints, or Remote Procedure Call endpoints, are a fundamental aspect of distributed computing systems, enabling communication between different components or services across a network"
        />
      </Helmet>
      <div className="flex items-center justify-between h-20">
        <div className="px-6 items-center justify-between flex flex-1 transition duration-150">
          <Text className="font-medium text-lg">RPC Endpoints</Text>
        </div>
        {/* <div className="mr-6 w-96"></div> */}
      </div>
      <div className="mx-6">
        <Tabs
          useUrl
          options={RpcEndpontsPageMenus.map((o) => ({
            label: o.label,
            url: o.url,
            testId: o.testId,
          }))}
        />
        <div className="py-6">
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default RpcEndpointsPage;
