import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Button from "../../buttons/Button";
import Dropdown from "../../general/Dropdown";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import NotData from "../../layouts/NotData";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
// import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil-square.svg";
import { ReactComponent as InfoIcon } from "./../../../assets/icons/eye.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { HiddenKey } from "../../general/HiddenKey";
import QuestionModal, { DeleteModal } from "../../modals/QuestionModal";
import { useSearch } from "../../../providers/SearchProvider";
import { HighlighterText } from "../../general/Text";

import {
  deleteInstancesSshKeysAsync,
  getInstancesSshKeysAsync,
  handleInstanceDeleteSshKeyHideModal,
  handleInstanceDeleteSshKeyShowModal,
  handleInstancesSshKeyEditor,
  selectInstancesSelectedSshKey,
  selectInstancesSshKeys,
  selectInstancesSshKeysActionLoading,
  selectInstancesSshKeysLoading,
  selectShowInstancesSshKeysModal,
} from "../../../store/instances/instancesSSHkeysSlice";
import { InstanceSshKey } from "../../../types/instance";
import { InstancesSskKeyEditorModal } from "./InstancesSskKeyEditorModal";

const InstancesSshKeysTab = () => {
  const sshKeys = useAppSelector(selectInstancesSshKeys);
  const sshKey = useAppSelector(selectInstancesSelectedSshKey);
  const [filteredSshKeys, setFilteredSshKeys] = useState<InstanceSshKey[]>([]);
  const loading = useAppSelector(selectInstancesSshKeysLoading);
  const actionLoading = useAppSelector(selectInstancesSshKeysActionLoading);
  const isOpen = useAppSelector(selectShowInstancesSshKeysModal);

  const dispatch = useAppDispatch();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    dispatch(getInstancesSshKeysAsync());
  }, [dispatch]);

  useEffect(() => {
    const lookedUpApiKeys = sshKeys.filter((key) =>
      key.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredSshKeys(lookedUpApiKeys);
  }, [dispatch, searchValue, sshKeys]);

  const handleClose = () => {
    dispatch(handleInstanceDeleteSshKeyHideModal());
  };

  return (
    <>
      <div className="flex transition-all duration-500">
        {/* <Text>SSH Keys</Text> */}
        <div className="ml-auto flex items-center justify-end gap-1 md:gap-2">
          <Button
            type="primary"
            buttonProps={{
              onClick: () =>
                dispatch(handleInstancesSshKeyEditor({ status: true })),
            }}
            buttonClassName="group"
          >
            Create New
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>
      <div className="mt-6">
        <Table loading={loading}>
          <TableHead>
            <TableRow>
              <TableTH lable="Label" />
              <TableTH lable="Value" />
              <TableTH lable="" />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSshKeys.map((sshKey, index) => (
              <TableRow key={index}>
                <TableTD className="w-36">
                  <HighlighterText
                    searchValue={searchValue}
                    textToHighlight={sshKey.name}
                  />
                </TableTD>
                <TableTD>
                  <div className="text-sm dark:text-slate-200">
                    <HiddenKey
                      text={sshKey.publicKey}
                      textClassName="max-w-[300px] md:max-w-[300px]"
                    />
                  </div>
                </TableTD>
                <TableTD className="w-10">
                  <div className="w-8 flex justify-center items-center">
                    <Dropdown
                      positionClassName="!w-40"
                      menu={[
                        // {
                        //   key: "1",
                        //   label: (
                        //     <div className="flex items-center gap-1">
                        //       <EditIcon className="w-4" />
                        //       Edit
                        //     </div>
                        //   ),
                        //   onClick: (_, close) => {
                        //     close();
                        //     dispatch(
                        //       handleInstancesSshKeyEditor({
                        //         status: true,
                        //         sshKey,
                        //       })
                        //     );
                        //   },
                        // },
                        // {
                        //   key: "info",
                        //   label: (
                        //     <div className="flex items-center gap-1">
                        //       <InfoIcon className="w-4" />
                        //       Info
                        //     </div>
                        //   ),
                        //   onClick: (_, close) => {
                        //     close();
                        //     dispatch(
                        //       handleInstancesSshKeyEditor({
                        //         status: true,
                        //         sshKey,
                        //       })
                        //     );
                        //   },
                        // },
                        // {
                        //   type: "divider",
                        // },
                        {
                          key: "end",
                          label: (
                            <div className="flex items-center gap-1">
                              <ArchiveIcon className="w-4" />
                              Delete
                            </div>
                          ),
                          danger: true,
                          onClick: (_, close) => {
                            close();
                            dispatch(
                              handleInstanceDeleteSshKeyShowModal(sshKey)
                            );
                          },
                        },
                      ]}
                      type="simple"
                    >
                      <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                        <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                      </div>
                    </Dropdown>
                  </div>
                </TableTD>
              </TableRow>
            ))}
            {sshKeys.length === 0 && (
              <TableRow withoutHoverStyle={true}>
                <TableTD colSpan={3} className="!border-0">
                  <NotData text="You have not created any SSH key yet" />
                </TableTD>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <InstancesSskKeyEditorModal />
      <DeleteModal
        isOpen={isOpen}
        title="Delete SSH Key"
        onClose={handleClose}
        description="To confirm, type 'DELETE' in the box below"
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        loading={actionLoading}
        onConfirm={() => {
          sshKey &&
            dispatch(deleteInstancesSshKeysAsync({ id: sshKey.id })).then(
              (action) => {
                if (action.type === "instances/ssh-keys/delete/fulfilled") {
                  dispatch(getInstancesSshKeysAsync());
                  dispatch(handleInstanceDeleteSshKeyHideModal());
                  handleClose();
                }
              }
            );
        }}
        confirmString={sshKey?.name}
      />
    </>
  );
};

export default InstancesSshKeysTab;
