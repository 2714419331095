import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IpfsApiKeySchema } from "../../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Modal from "../../modals/Modal";
import Input from "../../inputs/Input";
import Button from "../../buttons/Button";
import { useEffect } from "react";
import {
  selectSelectedWeb3ApiKey,
  selectShowWeb3ApiKeyEditor,
  selectWeb3AuthEndpointActionLoading,
  handleHideApiKeyEditor,
  selectWeb3AuthEndpoint,
  selectWeb3AuthEndpointLoading,
  getWeb3AuthEndpointApiKeysAsync,
  updateWeb3AuthEndpointApiKeyAsync,
  createWeb3AuthEndpointApiKeyAsync,
} from "../../../store/web3-auth/web3AuthEndpointSlice";

const Web3ApiKeyEditorModal = () => {
  const endpoint = useAppSelector(selectWeb3AuthEndpoint);
  const endpointLoading = useAppSelector(selectWeb3AuthEndpointLoading);
  const isOpen = useAppSelector(selectShowWeb3ApiKeyEditor);
  const loading = useAppSelector(selectWeb3AuthEndpointActionLoading);
  const apiKey = useAppSelector(selectSelectedWeb3ApiKey);
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(IpfsApiKeySchema),
  });

  useEffect(() => {
    if (isOpen && apiKey) {
      setValue("label", apiKey.Label);
    }
  }, [isOpen, apiKey, setValue]);

  const onSubmit = (data: any) => {
    if (endpoint)
      if (apiKey) {
        dispatch(
          updateWeb3AuthEndpointApiKeyAsync({
            endpointId: endpoint.Id,
            id: apiKey.Id,
            data: {
              label: data.label,
            },
          })
        ).then((action) => {
          if (action.type === "web3-auth/endpoint/api-keys/update/fulfilled") {
            reset();
            dispatch(handleHideApiKeyEditor());
            dispatch(getWeb3AuthEndpointApiKeysAsync(endpoint.Id.toString()));
          }
        });
      } else {
        dispatch(
          createWeb3AuthEndpointApiKeyAsync({
            endpointId: endpoint.Id,
            data: {
              label: data.label,
            },
          })
        ).then((action) => {
          if (action.type === "web3-auth/endpoint/api-keys/create/fulfilled") {
            reset();
            dispatch(handleHideApiKeyEditor());
            dispatch(getWeb3AuthEndpointApiKeysAsync(endpoint.Id.toString()));
          }
        });
      }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleHideApiKeyEditor());
        reset();
      }}
      contentClassName="max-w-lg"
      //   containerClassName="!items-start"
      title={apiKey ? "Update api key" : "Create new api key"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col w-full gap-5">
          <Input
            label="Api key label"
            inputProps={{ ...register("label") }}
            error={errors.label?.message}
            placeholder=""
          />
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            loading={loading}
            type="primary"
            buttonProps={{
              disabled: loading || endpointLoading,
              type: "submit",
            }}
            buttonClassName="w-[100px]"
          >
            {apiKey ? "Update" : "Create"}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default Web3ApiKeyEditorModal;
