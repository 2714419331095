import classNames from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import { InfoTooltip } from "../general/Tooltip";
import { ReactComponent as CopyIcon } from "./../../assets/icons/copy.svg";
import { copyToClipboard } from "../../utils/copy";
import { LoadingSpin } from "../general/Loading";

export type InputTypes = "default" | "simple";

const Input = ({
  id,
  inputProps,
  type,
  placeholder,
  label,
  error,
  required,
  hint,
  tooltip,
  copyableFn,
  loading,
  className,
  labelClassName,
  AfterComponent,
}: {
  id?: string;
  inputProps?: React.HTMLProps<HTMLInputElement>;
  type?: InputTypes;
  placeholder?: string;
  label?: string;
  required?: boolean;
  error?: string | boolean;
  hint?: string | React.ReactNode;
  tooltip?: string;
  copyableFn?: () => string | number | null | undefined;
  loading?: boolean;
  className?: string;
  labelClassName?: string;
  AfterComponent?: React.ReactNode;
}) => {
  const handleCopyToClipboard = () => {
    if (copyableFn) {
      const value = copyableFn();
      if (typeof value === "string" || typeof value === "number") {
        copyToClipboard(value);
      }
    }
  };
  return (
    <div className="flex flex-col">
      <div
        className={classNames("flex items-center", {
          [labelClassName || ""]: labelClassName,
          "justify-between": label,
          "justify-end": !label,
          "mb-1": label || required || tooltip || hint,
        })}
      >
        <label
          htmlFor={id}
          className={classNames(
            "flex items-center gap-1 text-sm text-slate-800 dark:text-slate-50 whitespace-nowrap",
            {
              "text-red-700 dark:text-red-500": error,
            }
          )}
        >
          {label}
          {required && <span className="text-red-500">*</span>}
          {tooltip && <InfoTooltip content={tooltip} />}
        </label>
        {hint && <span className="text-[11px] text-slate-500">{hint}</span>}
      </div>
      <div className="w-full relative block z-0">
        {copyableFn && (
          <div className="absolute z-30 inset-y-0 end-0 flex items-center pe-3">
            <CopyIcon
              onClick={handleCopyToClipboard}
              className="w-5 cursor-pointer hover:scale-110 text-slate-800 hover:text-primary-300 dark:text-slate-300 dark:hover:text-primary-300"
            />
          </div>
        )}
        <input
          // type="text"
          id={id}
          {...inputProps}
          className={classNames(
            className,
            "bg-secondary-100 focus:ring-0 text-sm rounded-lg block w-full p-2.5  dark:bg-dark-2 outline-none disabled:cursor-not-allowed disabled:bg-secondary-200 dark:disabled:bg-gray-700 dark:disabled:text-secondary-400 disabled:text-secondary-500 disabled:border-secondary-300 disabled:dark:border-gray-600",
            {
              "text-secondary-600 !bg-transparent": type === "simple",
              "border-2": type === "default" || type === undefined,
              "border  border-red-500 text-red-900 placeholder-red-700  focus:border-red-500 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500":
                error,
              "dark:border-dark-2 dark:focus:border-slate-600 dark:text-slate-50 dark:placeholder-gray-500 border-secondary-100 focus:bg-secondary-50  focus:border-secondary-200 ":
                !error,
              "pr-10": copyableFn,
            }
          )}
          placeholder={placeholder}
        />
        {loading && (
          <div className="absolute z-40 inset-y-0 end-0 flex items-center pe-2.5">
            <LoadingSpin borderSize={2} />
          </div>
        )}
        <div className="absolute inset-y-0 end-0 flex">{AfterComponent}</div>
      </div>
      <AnimatePresence>
        {error && typeof error === "string" && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
          >
            <p className="mt-2 text-xs text-red-600 dark:text-red-500">
              {error}
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Input;
