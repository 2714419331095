import { useAppSelector } from "../../../hooks";
import {
  selectWalletApiRequestLogs,
  selectWalletApiRequestLogsLoading,
} from "../../../store/wallet-api/overviewSlice";
import Card from "../../general/Card";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import Text from "../../general/Text";
import NotData from "../../layouts/NotData";
import WalletApiBanner from "./WalletApiBanner";
import PieChart, { PieChartData } from "../../general/charts/PieChart";

const WalletApiOverviewTab = () => {
  const logs = useAppSelector(selectWalletApiRequestLogs);
  const logsLoading = useAppSelector(selectWalletApiRequestLogsLoading);

  const creditsTodayData: PieChartData[] = [
    {
      name: "Free",
      value: 100,
      className: "fill-[#eee] dark:fill-white/10",
    },
  ];

  const creditsYesterdayData: PieChartData[] = [
    {
      name: "Free",
      value: 100,
      className: "fill-[#eee] dark:fill-white/10",
    },
  ];

  const creditsThisMonthData: PieChartData[] = [
    {
      name: "Free",
      value: 100,
      className: "fill-[#eee] dark:fill-white/10",
    },
  ];

  const creditsLastMonthData: PieChartData[] = [
    {
      name: "Free",
      value: 100,
      className: "fill-[#eee] dark:fill-white/10",
    },
  ];

  return (
    <div className="flex flex-col gap-8">
      <WalletApiBanner />
      <Card title="API Key Usage">
        <div className="flex flex-col md:flex-row gap-5 md:divide-x dark:divide-white/10">
          <div className="flex items-center gap-5">
            <div className="flex flex-col items-center">
              <PieChart data={creditsTodayData} centerLabel="0" />
              <Text className="text-xs">Credits Today</Text>
            </div>
            <div className="flex flex-col items-center">
              <PieChart data={creditsYesterdayData} centerLabel="0" />
              <Text className="text-xs">Credits Yesterday</Text>
            </div>
          </div>

          <div className="flex items-center gap-5 md:pl-5">
            <div className="flex flex-col items-center">
              <PieChart data={creditsThisMonthData} centerLabel="0/10,000" />
              <Text className="text-xs">Credits This Month</Text>
            </div>
            <div className="flex flex-col items-center">
              <PieChart data={creditsLastMonthData} centerLabel="0/10,000" />
              <Text className="text-xs">Credits Last Month</Text>
            </div>
          </div>
        </div>
      </Card>
      <Card title="API Request Log">
        <Table loading={logsLoading} className="table-auto">
          <TableHead>
            <TableRow>
              <TableTH lable="Last 100 API Requests" />
              <TableTH lable="Timestamp" />
              <TableTH lable="Credit Count" />
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.map((log, index) => (
              <TableRow key={index}>
                <TableTD>
                  <Text className="text-xs md:text-sm" type="primary-alert">
                    --
                  </Text>
                </TableTD>
                <TableTD>
                  <Text className="text-xs md:text-sm" type="primary-alert">
                    --
                  </Text>
                </TableTD>
                <TableTD>
                  <Text className="text-xs md:text-sm" type="primary-alert">
                    --
                  </Text>
                </TableTD>
              </TableRow>
            ))}
            {logs.length === 0 && (
              <TableRow withoutHoverStyle={true}>
                <TableTD colSpan={3} className="!border-none">
                  <NotData />
                </TableTD>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Card>
    </div>
  );
};

export default WalletApiOverviewTab;
