import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isAxiosError } from "axios";
import toast from "react-hot-toast";
import { ToastClasses } from "../../../components/modals/alerts";
import { Enum, IThunkRejectValue, RootState } from "../../../types";
import { Credential, RegisteriesState } from "../../../types/registeries";
import {
  addCredentailApi,
  getCredentialsApi,
  removeCredentialApi,
  getRegisteriesTypesApi,
} from "../../../apis/registeriesAPI";
import { getExtractErrors } from "../../../apis";
import { CustomErrorToast } from "../../../components/general/Toast";

const initialState: RegisteriesState = {
  credentials: [],
  credentialsLoading: false,
  addCredentialModalIsOpen: false,
  credentialAddLoading: false,
  credentialRemoveLoading: false,
  registeryTypes: [],
  registeryTypesLoading: false,
};

export const getCredentialsAsync = createAsyncThunk<
  { credentials: Credential[] },
  undefined,
  IThunkRejectValue
>(
  "registeries/credentials",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getCredentialsApi();
      const credentials = response.data.Result;

      return fulfillWithValue({ credentials });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getRegisteriesTypesAsync = createAsyncThunk<
  { types: Enum[] },
  undefined,
  IThunkRejectValue
>("registeries/types", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getRegisteriesTypesApi();
    const types = response.data.Result;

    return fulfillWithValue({ types });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const addCredentialAsync = createAsyncThunk<any, any, IThunkRejectValue>(
  "registeries/credentials/add",
  async (data, { rejectWithValue }) => {
    try {
      const res = await addCredentailApi(data);
      return res.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const removeCredentialAsync = createAsyncThunk<
  any,
  { id: number },
  IThunkRejectValue
>("registeries/credentials/remove", async ({ id }, { rejectWithValue }) => {
  try {
    const res = await removeCredentialApi(id);
    return res.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const registeriesSlice = createSlice({
  name: "registeries",
  initialState,
  reducers: {
    openAddCredentialModal: (state) => {
      state.addCredentialModalIsOpen = true;
    },
    closeAddCredentialModal: (state) => {
      state.addCredentialModalIsOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCredentialsAsync.pending, (state) => {
        state.credentialsLoading = true;
      })
      .addCase(getCredentialsAsync.fulfilled, (state, action) => {
        const { credentials } = action.payload;
        state.credentials = credentials;
        state.credentialsLoading = false;
      })
      .addCase(getCredentialsAsync.rejected, (state, { payload }) => {
        state.credentialsLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getRegisteriesTypesAsync.pending, (state) => {
        state.registeryTypesLoading = true;
      })
      .addCase(getRegisteriesTypesAsync.fulfilled, (state, action) => {
        const { types } = action.payload;
        state.registeryTypes = types;
        state.registeryTypesLoading = false;
      })
      .addCase(getRegisteriesTypesAsync.rejected, (state, { payload }) => {
        state.registeryTypesLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(addCredentialAsync.pending, (state) => {
        state.credentialAddLoading = true;
      })
      .addCase(addCredentialAsync.fulfilled, (state, action) => {
        state.credentialAddLoading = false;
      })
      .addCase(addCredentialAsync.rejected, (state, { payload }) => {
        state.credentialAddLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(removeCredentialAsync.pending, (state) => {
        state.credentialRemoveLoading = true;
      })
      .addCase(removeCredentialAsync.fulfilled, (state, action) => {
        state.credentialRemoveLoading = false;
      })
      .addCase(removeCredentialAsync.rejected, (state, { payload }) => {
        state.credentialRemoveLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectCredentials = (state: RootState) =>
  state.registeries.credentials;
export const selectCredentialsLoading = (state: RootState) =>
  state.registeries.credentialsLoading;

export const selectRegisteriesTypes = (state: RootState) =>
  state.registeries.registeryTypes;
export const selectRegisteriesTypesLoading = (state: RootState) =>
  state.registeries.registeryTypesLoading;

export const selectAddCredentialModalIsOpen = (state: RootState) =>
  state.registeries.addCredentialModalIsOpen;
export const selectCredentialAddLoading = (state: RootState) =>
  state.registeries.credentialAddLoading;
export const selectCredentialRemoveLoading = (state: RootState) =>
  state.registeries.credentialRemoveLoading;

export const { openAddCredentialModal, closeAddCredentialModal } =
  registeriesSlice.actions;
export default registeriesSlice.reducer;
