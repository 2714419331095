import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  deleteWebAppSharingAsync,
  getWebAppSharedAsync,
  createWebAppSharingAsync,
  selectWebApp,
  selectWebAppDeleteId,
  selectWebAppLoading,
  selectWebAppShared,
  selectWebAppSharedLoading,
  selectWebAppSharingLoading,
} from "../../../store/web-app/webAppSlice";
import Text, { A } from "../../general/Text";
import Loading from "../../general/Loading";
import Card from "../../general/Card";
import Input from "../../inputs/Input";
import Button from "../../buttons/Button";
import { ReactComponent as UserTeamIcon } from "./../../../assets/icons/user-team.svg";
import UserAvatar from "./../../../assets/images/blank-avatar.png";
import { selectUser } from "../../../store/auth/authSlice";
import { BASE_URL } from "../../../apis";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { WebAppSaringSchema } from "../../../utils/validations";

const WebAppSharingTab = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);

  const authUser = useAppSelector(selectUser);

  const shared = useAppSelector(selectWebAppShared);
  const sharedLoading = useAppSelector(selectWebAppSharedLoading);
  const sharingLoading = useAppSelector(selectWebAppSharingLoading);
  const deleteId = useAppSelector(selectWebAppDeleteId);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (webApp) {
      dispatch(getWebAppSharedAsync({ webAppId: webApp.Id.toString() }));
    }
  }, [dispatch, webApp]);

  const {
    handleSubmit,
    register,
    formState: { isValid },
    reset,
  } = useForm({
    resolver: yupResolver(WebAppSaringSchema),
  });

  const handleAddUser = (data: any) => {
    if (webApp) {
      dispatch(
        createWebAppSharingAsync({
          webAppId: webApp.Id.toString(),
          Email: data.Email,
        })
      ).then((action) => {
        if (action.type === "web-app/sharing/fulfilled") {
          dispatch(getWebAppSharedAsync({ webAppId: webApp.Id.toString() }));
          reset();
        }
      });
    }
  };

  return (
    <Card title="Share Web Service">
      <div className="col-span-1 text-sm font-normal font-sans mt-2">
        <Text>
          Sharing a Web Service with other Render users allows them to view,
          modify, and delete it.
        </Text>
      </div>
      <form onSubmit={handleSubmit(handleAddUser)}>
        <div className="flex items-center gap-3 w-full my-4">
          <div className="!flex-1">
            <Input
              inputProps={{ ...register("Email") }}
              placeholder="Email address"
            />
          </div>
          <Button
            type="primary"
            buttonProps={{ type: "submit", disabled: !isValid }}
            loading={sharingLoading}
          >
            Share
          </Button>
        </div>
      </form>
      <ul className="mt-2 border dark:border-dark-2 rounded-lg bg-white dark:bg-dark-3">
        <li className="p-4 border-b last:border-b-0 dark:border-dark-2">
          <div className="flex justify-between items-center">
            <div className="flex items-center flex-1 gap-4">
              <div className="-mr- w-8 h-8 bg-gray-200 bg-gray-300/30 dark:bg-gray-500/20 rounded-full ring-1 ring-gray-400 dark:ring-gray-600 flex items-center text-center justify-center overflow-hidden">
                <img
                  src={
                    authUser?.AvatarLink !== null
                      ? BASE_URL + authUser?.AvatarLink
                      : UserAvatar
                  }
                  alt={authUser?.FullName}
                  className="w-full h-full"
                />
              </div>
              <div className="flex items-center gap-3">
                <Text className="text-sm flex items-center gap-1">
                  {authUser?.Email}
                </Text>
                <Text type={"primary-alert"}>You</Text>
              </div>
            </div>
            <div className="flex items-center"></div>
          </div>
        </li>
        {sharedLoading && <Loading borderSize={2} className="min-h-[300px]" />}

        {!sharedLoading &&
          shared.length > 0 &&
          shared.map((item, index) => (
            <li
              key={index}
              className="p-4 border-b last:border-b-0 dark:border-dark-2"
            >
              <div className="flex justify-between items-center">
                <div className="flex items-center flex-1 gap-4">
                  <div className="-mr- w-8 h-8 bg-gray-200 bg-gray-300/30 dark:bg-gray-500/20 rounded-full ring-1 ring-gray-400 dark:ring-gray-600 flex items-center text-center justify-center overflow-hidden">
                    <UserTeamIcon className="w-full h-full text-slate-400 dark:text-slate-400" />
                  </div>
                  <div className="flex items-center gap-3">
                    <Text className="text-sm flex items-center gap-1">
                      {item.Email}
                    </Text>
                    <Text
                      type={
                        item.Status === 0 ? "warning-alert" : "success-alert"
                      }
                    >
                      {item.Status === 0 ? "Pending" : "Accepted"}
                    </Text>
                  </div>
                </div>
                <div className="flex items-center">
                  <Button
                    type="danger"
                    loading={deleteId !== null && deleteId === item.Id}
                    buttonProps={{
                      onClick: () => {
                        if (webApp) {
                          dispatch(
                            deleteWebAppSharingAsync({
                              webAppId: webApp.Id.toString(),
                              Id: item.Id,
                            })
                          );
                        }
                      },
                    }}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            </li>
          ))}
      </ul>
    </Card>
  );
};

export default WebAppSharingTab;
