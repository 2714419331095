import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  deleteWorkflowAsync,
  getWorkflowsAsync,
  handleChangeFlowDir,
  selectWorkflowDirection,
  selectWorkflowStatus,
  selectWorkflows,
  selectWorkflowsActionLoading,
  selectWorkflowsLoading,
} from "../../../store/workflows/workflowsSlice";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import Text, { HighlighterText } from "../../general/Text";
import NotData, { WorkflowNotActiveTab } from "../../layouts/NotData";
import { useSearch } from "../../../providers/SearchProvider";
import { IWorkflowResponse } from "../../../types/workflows";
import Dropdown from "../../general/Dropdown";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as DetailsIcon } from "./../../../assets/icons/bars-3-bottom-left.svg";
import { ReactComponent as DirectionIcon } from "./../../../assets/icons/arrows-right-left.svg";
import QuestionModal, { DeleteModal } from "../../modals/QuestionModal";
import { Helmet } from "react-helmet";
import Button from "../../buttons/Button";
import { useNavigate } from "react-router-dom";
import { WorkflowUrl } from "../../../utils/urls";
import { IconBtnWrapper } from "../../general/Wrappers";
import { getNodeIconUrl } from "../../../utils/wfHelper";
import Tooltip from "../../general/Tooltip";
import classNames from "classnames";
import { selectWorkflow } from "../../../store/workflows/workflowSlice";

const WorkflowsTab = () => {
  const workflowStatus = useAppSelector(selectWorkflowStatus);
  const workflows = useAppSelector(selectWorkflows);
  const workflow = useAppSelector(selectWorkflow);
  const workflowsLoading = useAppSelector(selectWorkflowsLoading);
  const workflowsActionLoading = useAppSelector(selectWorkflowsActionLoading);
  const flowDirection = useAppSelector(selectWorkflowDirection);
  const [filteredWorkflows, setFilteredWorkflows] = useState<
    IWorkflowResponse[]
  >([]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [deleteWorkflow, setDeleteWorkflow] =
    useState<IWorkflowResponse | null>(null);
  const [images, setImages] = useState<Record<string, string[]>>({});
  const { value: searchValue } = useSearch();

  useEffect(() => {
    dispatch(getWorkflowsAsync());
  }, [dispatch]);

  useEffect(() => {
    const lookedUpWorkflows = workflows.filter((workflow) =>
      workflow.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredWorkflows(lookedUpWorkflows);
  }, [dispatch, searchValue, workflows]);

  useEffect(() => {
    const getNodesImages = async () => {
      if (workflows) {
        try {
          const images: Record<string, string[]> = {};
          for (let i = 0; i < workflows.length; i += 1) {
            const flowDataStr = workflows[i].flowData;
            const flowData = JSON.parse(flowDataStr);
            const nodes = flowData.nodes || [];
            images[workflows[i].shortId] = [];

            for (let j = 0; j < nodes.length; j += 1) {
              const imageSrc = getNodeIconUrl(nodes[j].data.icon);
              if (!images[workflows[i].shortId].includes(imageSrc)) {
                images[workflows[i].shortId].push(imageSrc);
              }
            }
          }
          setImages(images);
        } catch (e) {
          console.error(e);
        }
      }
    };
    getNodesImages();
  }, [workflows]);

  const changeFlowDirection = useCallback(() => {
    dispatch(handleChangeFlowDir());
  }, [dispatch]);

  if (!workflowStatus) {
    return <WorkflowNotActiveTab />;
  }

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Workflows</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="flex transition-all duration-500 items-center justify-between gap-3">
        <div></div>
        <div className="flex items-center justify-end gap-7">
          <div className="flex items-center gap-0.5">
            <Text className="whitespace-nowrap font-medium text-sm">
              flow direction
            </Text>
            <Tooltip
              content={
                flowDirection === "ltr" ? `left to right` : "top to bottom"
              }
            >
              <DirectionIcon
                onClick={changeFlowDirection}
                className={classNames(
                  "w-5 h-5 text-blue-500 cursor-pointer hover:text-blue-600 hover:scale-110 duration-200",
                  { "rotate-90": flowDirection === "ttb" }
                )}
              />
            </Tooltip>
          </div>
          <Button
            type="primary"
            buttonProps={{
              onClick: () => navigate(WorkflowUrl()),
            }}
            buttonClassName="group"
          >
            Add New
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>
      <div className="mt-5 w-full">
        <Table loading={workflowsLoading} className="table-auto">
          <TableHead>
            <TableRow>
              <TableTH lable="Name" />
              <TableTH lable="Execution" />
              <TableTH lable="Steps" />
              {/* <TableTH lable="Created" /> */}
              <TableTH lable="Status" />
              <TableTH lable="" />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredWorkflows.map((flow, index) => (
              <TableRow
                key={index}
                onClick={() => navigate(WorkflowUrl(flow.shortId))}
                className="cursor-pointer"
              >
                <TableTD>
                  <HighlighterText
                    searchValue={searchValue}
                    textToHighlight={flow.name}
                  />
                </TableTD>
                <TableTD>
                  <Text className="text-xs md:text-sm">
                    {flow.executionCount}
                  </Text>
                </TableTD>
                <TableTD>
                  <div className="flex flex-row min-w-[210px] max-w-[210px] overflow-x-hidden">
                    {images[flow.shortId]?.map((img) => (
                      <div
                        key={img}
                        className="w-[32px] h-[32px] aspect-square mr-1 rounded-full bg-white dark:bg-white/10"
                      >
                        <img
                          className="w-full h-full p-1 object-contain"
                          alt=""
                          src={img}
                        />
                      </div>
                    ))}
                  </div>
                </TableTD>
                {/* <TableTD>
                <Text className="text-xs md:text-sm" type="primary-alert">
                  --
                </Text>
              </TableTD> */}
                <TableTD>
                  {flow.deployed && (
                    <Text className="text-xs" type="success-alert">
                      Deployed
                    </Text>
                  )}
                </TableTD>
                <TableTD className="w-32 gap-1">
                  <div className="h-full w-full inline-flex items-center justify-center gap-1">
                    <div className="w-8 flex justify-center items-center">
                      <Dropdown
                        positionClassName="!w-40"
                        menu={[
                          {
                            key: "1",
                            label: (
                              <div className="flex items-center gap-1">
                                <DetailsIcon className="w-4" />
                                Get details
                              </div>
                            ),
                            onClick: () => navigate(WorkflowUrl(flow.shortId)),
                            disabled: workflowsLoading,
                          },
                          {
                            type: "divider",
                          },
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: (_, close) => {
                              close();
                              setDeleteWorkflow(flow);
                            },
                            disabled: workflowsLoading,
                          },
                        ]}
                        type="simple"
                      >
                        <IconBtnWrapper>
                          <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                        </IconBtnWrapper>
                      </Dropdown>
                    </div>
                    <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" />
                  </div>
                </TableTD>
              </TableRow>
            ))}
            {workflows.length === 0 && (
              <TableRow withoutHoverStyle={true}>
                <TableTD colSpan={4} className="!border-none">
                  <NotData />
                </TableTD>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <QuestionModal
        isOpen={deleteWorkflow !== null}
        onClose={() => setDeleteWorkflow(null)}
        onConfirm={() => {
          deleteWorkflow &&
            dispatch(deleteWorkflowAsync({ id: deleteWorkflow.shortId })).then(
              (action) => {
                if (action.type === "workflows/delete/fulfilled") {
                  dispatch(getWorkflowsAsync());
                } else {
                }
                setDeleteWorkflow(null);
              }
            );
        }}
        loading={workflowsActionLoading}
        confirmButtonType="danger"
      />
      <DeleteModal
        title="Delete Workflow"
        isOpen={deleteWorkflow !== null}
        onClose={() => setDeleteWorkflow(null)}
        description="To confirm, type 'DELETE' in the box below"
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        onConfirm={() => {
          deleteWorkflow &&
            dispatch(deleteWorkflowAsync({ id: deleteWorkflow.shortId })).then(
              (action) => {
                if (action.type === "workflows/delete/fulfilled") {
                  dispatch(getWorkflowsAsync());
                } else {
                }
                setDeleteWorkflow(null);
              }
            );
        }}
        loading={workflowsActionLoading}
        confirmString={deleteWorkflow?.name}
      />
    </>
  );
};

export default WorkflowsTab;
