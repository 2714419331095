import { Popover as Popper, Transition } from "@headlessui/react";
import { Fragment, PropsWithChildren } from "react";
import { useFloating, shift, flip } from "@floating-ui/react-dom";

const Popover: React.FC<PropsWithChildren<{ buttonEl: React.ReactNode }>> = ({
  buttonEl,
  children,
}) => {
  const { refs, floatingStyles } = useFloating({
    placement: "bottom-start",
    middleware: [flip(), shift()],
  });

  return (
    <Popper className="relative">
      {({ open }) => (
        <>
          <Popper.Button
            className={`
            ${open ? "text-white" : "text-white/90"}
            `}
            ref={refs.setReference}
          >
            {buttonEl}
          </Popper.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popper.Panel
              ref={refs.setFloating}
              style={floatingStyles}
              className="absolute left-0 z-10 mt-3 transform px-4 sm:px-0"
            >
              {children}
            </Popper.Panel>
          </Transition>
        </>
      )}
    </Popper>
  );
};

export default Popover;
