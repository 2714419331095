import React, { HTMLProps, PropsWithChildren } from "react";
import classNames from "classnames";
// import useDarkMode from "../../hooks/useDarkMode";
// import { tagLoading } from "../../utils/theme";
import { LoadingSpin } from "./Loading";
import { AnimatePresence, motion } from "framer-motion";

interface TableRowProps extends HTMLProps<HTMLTableRowElement> {
  className?: string;
  withoutHoverStyle?: boolean;
}

interface TableTDProps extends HTMLProps<HTMLTableCellElement> {
  className?: string;
}

const testLoading = false;

const Table = (
  props: PropsWithChildren<{
    loading?: boolean;
    className?: string;
    containerClassName?: string;
  }>
) => {
  // const { mode } = useDarkMode();
  // const tagLoadingClass = tagLoading(mode);

  return (
    <div
      className={classNames("relative min-h-[200px]", props.containerClassName)}
    >
      <div className="overflow-x-auto overflow-y-hidden">
        <table
          className={classNames(
            "rounded-md border border-transparent border-separate border-spacing-0 w-full space-y-4",
            {
              // [tagLoadingClass]: props.loading,
              [props.className || ""]: props.className,
            }
          )}
        >
          {props.children}
        </table>
      </div>
      <AnimatePresence>
        {(props.loading || testLoading) && (
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute w-full h-full top-0 left-0 bg-gray-400/10 dark:bg-gray-800/10 backdrop-blur-[1.1px] flex justify-center items-center"
          >
            <LoadingSpin borderSize={2.3} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const TableRow = (props: PropsWithChildren<TableRowProps>) => {
  const { className, withoutHoverStyle, selected, disabled, onClick, ...rest } =
    props;
  return (
    <tr
      {...rest}
      onClick={(e: any) => (!disabled && onClick ? onClick(e) : null)}
      className={classNames(
        className,
        "group duration-200 transition-colors bg-white dark:bg-dark-3",
        {
          "dark:hover:bg-dark-2 hover:bg-[#f8fafc] ":
            (withoutHoverStyle === undefined || withoutHoverStyle === false) &&
            !selected,
          "dark:!bg-white/10 !bg-[#eff5fe] ": selected,
          "cursor-not-allowed": disabled,
          "cursor-pointer": !disabled && onClick,
        }
      )}
    >
      {props.children}
    </tr>
  );
};

const TableHead = (props: PropsWithChildren) => <thead>{props.children}</thead>;

const TableTH = (
  props: PropsWithChildren<{ lable?: string | React.ReactNode }>
) => {
  return (
    <th className="text-left bg-white dark:bg-dark-3 border-b dark:border-dark-2 p-2">
      <div className="text-slate-400 dark:text-slate-100 font-light overflow-hidden whitespace-nowrap">
        {props.children || props.lable}
      </div>
    </th>
  );
};

const TableBody = (props: PropsWithChildren) => <tbody>{props.children}</tbody>;

const TableTD = (props: PropsWithChildren<TableTDProps>) => {
  const { className, children, ...rest } = props;
  return (
    <td
      {...rest}
      className={classNames(
        "text-md py-3 px-2 text-[#475569] dark:text-slate-100 border-b dark:border-dark-2",
        props.className
      )}
    >
      <div className="h-full w-full flex items-center">{props.children}</div>
    </td>
  );
};
export { TableRow, TableHead, TableBody, TableTH, TableTD };
export default Table;
