import NotData from "../../layouts/NotData";
import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useState } from "react";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArrowIcon } from "./../../../assets/icons/arrow-top-right-on-square.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";

import { Link, useNavigate } from "react-router-dom";
import {
  DatabaseServiceGeneralInformationUrl,
  DatabaseServiceIntegrationsUrl,
} from "../../../utils/urls";
import Dropdown from "../../general/Dropdown";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import QuestionModal, { DeleteModal } from "../../modals/QuestionModal";
import Text from "../../general/Text";
import { useSearch } from "../../../providers/SearchProvider";
import {
  deleteDBSServiceIntegrationAsync,
  getDBSServiceIntegrationsAsync,
  handleDBSIntegrationEditor,
  selectDBSIntegration,
  selectDBSIntegrations,
  selectDBSIntegrationsActionLoading,
  selectDBSIntegrationsLoading,
  selectDatabaseService,
} from "../../../store/database/serviceSlice";
import { generateServicePermissions } from "../../../pages/databases/ServicePage";
import Button from "../../buttons/Button";
import DBSIntegrationCreateModal from "./DBSIntegrationCreateModal";
import {
  getDatabaseServicesAsync,
  selectDatabaseServices,
  selectDatabaseServicesLoading,
} from "../../../store/database/servicesSlice";

const DBSServiceIntegrationTab = () => {
  const service = useAppSelector(selectDatabaseService);
  const integration = useAppSelector(selectDBSIntegration);
  const integrations = useAppSelector(selectDBSIntegrations);
  const integrationsLoading = useAppSelector(selectDBSIntegrationsLoading);
  const integrationsActionLoading = useAppSelector(
    selectDBSIntegrationsActionLoading
  );
  const [deleteIntegration, setDeleteIntegrations] = useState<string | null>(
    null
  );

  const services = useAppSelector(selectDatabaseServices);
  const servicesLoading = useAppSelector(selectDatabaseServicesLoading);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (service) {
      const permissions = generateServicePermissions().integrations;
      if (!permissions.includes(service.engine)) {
        navigate(DatabaseServiceGeneralInformationUrl(service.id));
      } else {
        dispatch(
          getDBSServiceIntegrationsAsync({
            clusterId: service.id,
            engine: service.engine,
          })
        );
      }
    }
  }, [dispatch, navigate, service]);

  useEffect(() => {
    dispatch(getDatabaseServicesAsync());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | Database - service integrations
        </title>
        <meta name="description" content="" />
      </Helmet>

      {service && (
        <>
          <div className="flex items-center justify-between">
            <div className="flex flex-col gap-1">
              <Text className="font-medium text-lg">Service integration</Text>
              <Text className="font-light text-xs">
                With service integration, you ca connect different solutions
              </Text>
            </div>
            <div className="flex items-center gap-2">
              <Button
                size="medium"
                type="light"
                buttonProps={{
                  onClick: () => {
                    if (service) {
                      dispatch(
                        getDBSServiceIntegrationsAsync({
                          clusterId: service.id,
                          engine: service.engine,
                        })
                      );
                    }
                  },
                  disabled: !service,
                }}
                buttonClassName="group"
                tooltip="Refresh"
              >
                <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
              </Button>
              <Button
                type="primary"
                buttonProps={{
                  onClick: () => dispatch(handleDBSIntegrationEditor(true)),
                  // disabled: isAnyUserDeleting,
                }}
                buttonClassName="group"
              >
                Add an integrations
                <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
              </Button>
            </div>
          </div>

          <div className="mt-5 w-full">
            <Table loading={integrationsLoading}>
              <TableHead>
                <TableRow>
                  <TableTH lable="Source" />
                  <TableTH lable="Target" />
                  <TableTH lable="Type" />
                  <TableTH lable="Status" />
                  <TableTH lable="" />
                </TableRow>
              </TableHead>

              <TableBody>
                {integrations.map((integration, i) => {
                  const sourceService = services.find(
                    (s) => s.id === integration.sourceServiceId
                  );
                  const destinationService = services.find(
                    (s) => s.id === integration.destinationServiceId
                  );
                  return (
                    <TableRow key={i}>
                      <TableTD>
                        <Text
                          className="text-xs md:text-sm whitespace-nowrap"
                          loading={servicesLoading}
                        >
                          {service.id === integration.sourceServiceId ? (
                            sourceService?.description
                          ) : (
                            <Link
                              to={DatabaseServiceGeneralInformationUrl(
                                integration.sourceServiceId
                              )}
                              className="group/link flex items-center gap-1"
                            >
                              {sourceService?.description}
                              <ArrowIcon className="group-hover/link:text-primary-500 w-4 h-4" />
                            </Link>
                          )}
                        </Text>
                      </TableTD>

                      <TableTD>
                        <Text
                          className="text-xs md:text-sm whitespace-nowrap"
                          loading={servicesLoading}
                        >
                          {service.id === integration.destinationServiceId ? (
                            destinationService?.description
                          ) : (
                            <Link
                              to={DatabaseServiceGeneralInformationUrl(
                                integration.destinationServiceId
                              )}
                              className="group/link flex items-center gap-1"
                            >
                              {destinationService?.description}
                              <ArrowIcon className="group-hover/link:text-primary-500 w-4 h-4" />
                            </Link>
                          )}
                        </Text>
                      </TableTD>

                      <TableTD>
                        <Text className="text-xs md:text-sm">
                          {integration.type}
                        </Text>
                      </TableTD>
                      <TableTD>
                        <Text
                          className="text-xs md:text-sm"
                          type={
                            integration.status === "READY"
                              ? "success-alert"
                              : "warning-alert"
                          }
                        >
                          {integration.status}
                        </Text>
                      </TableTD>

                      <TableTD className="w-32 gap-1">
                        <div className="h-full w-full inline-flex items-center justify-end gap-1">
                          <div className="w-8 flex justify-center items-center ">
                            <Dropdown
                              positionClassName="!w-48"
                              menu={[
                                {
                                  key: "end",
                                  label: (
                                    <div className="flex items-center gap-1">
                                      <ArchiveIcon className="w-4" />
                                      Delete
                                    </div>
                                  ),
                                  danger: true,
                                  onClick: (_, close) => {
                                    close();
                                    setDeleteIntegrations(integration.id);
                                  },
                                },
                              ]}
                              type="simple"
                            >
                              <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                                <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                              </div>
                            </Dropdown>
                          </div>
                        </div>
                      </TableTD>
                    </TableRow>
                  );
                })}

                {integrations.length === 0 && !integrationsLoading && (
                  <TableRow withoutHoverStyle={true}>
                    <TableTD colSpan={10} className="!border-0">
                      <NotData text="No result" />
                    </TableTD>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <DBSIntegrationCreateModal />
          <DeleteModal
            title="Delete a Service Integration"
            isOpen={deleteIntegration !== null}
            onClose={() => setDeleteIntegrations(null)}
            description="To confirm, type 'DELETE' in the box below"
            confirmButtonText="Confirm"
            confirmButtonType="danger"
            confirmButtonClassName="w-full"
            onConfirm={() => {
              deleteIntegration &&
                service &&
                dispatch(
                  deleteDBSServiceIntegrationAsync({
                    clusterId: service.id,
                    engine: service.engine,
                    integrationId: deleteIntegration,
                  })
                ).then((action) => {
                  if (
                    action.type ===
                    "service/service-integrations/delete/fulfilled"
                  ) {
                    dispatch(
                      getDBSServiceIntegrationsAsync({
                        engine: service.engine,
                        clusterId: service.id,
                      })
                    );
                  } else {
                  }
                  setDeleteIntegrations(null);
                });
            }}
            loading={integrationsActionLoading}
            confirmString={integration?.type}
          />
        </>
      )}
    </>
  );
};

export default DBSServiceIntegrationTab;
