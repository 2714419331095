import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { S3AccessKeySchema } from "../../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Modal from "../../modals/Modal";
import Input from "../../inputs/Input";
import Button from "../../buttons/Button";
import { useEffect, useState } from "react";
import {
  selectActionLoading,
  selectShowEditor,
  selectSelectedS3AccessKey,
  handleHideEditor,
  polictText,
  handleShowCreatedAccessKey,
  updateS3AccessKeyAsync,
  createS3AccessKeyAsync,
  getS3AccessKeysAsync,
  selectSelectedS3AccessKeyName,
  selectS3AccessKeySelectionLoading,
  getS3AccessKeyAsync,
} from "../../../store/s3/accessKeysSlice";
import Switcher from "../../inputs/Switcher";
import Textarea from "../../inputs/Textarea";
import { uuid } from "../../../utils/uuid";
import { S3CreatedAccessKey } from "../../../types/s3-access-key";
import { formatTimestamp } from "../../../utils/date";

const S3AccessKeyEditorModal = () => {
  const isOpen = useAppSelector(selectShowEditor);
  const loading = useAppSelector(selectActionLoading);

  const selectedAccessKeyName = useAppSelector(selectSelectedS3AccessKeyName);
  const selectedAccessKey = useAppSelector(selectSelectedS3AccessKey);
  const selectionLoading = useAppSelector(selectS3AccessKeySelectionLoading);

  const [isPolicy, setIsPolicy] = useState<boolean>(false);
  const [isEnabled, setIsEnabled] = useState<boolean>(true);

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(S3AccessKeySchema(selectedAccessKeyName !== null)),
  });

  useEffect(() => {
    if (isOpen) {
      if (selectedAccessKeyName) {
        setIsPolicy(true);
        setValue("accessKey", "");
        setValue("secretKey", "");
        dispatch(getS3AccessKeyAsync({ accessKey: selectedAccessKeyName }));
      } else {
        setValue("accessKey", uuid(16));
        setValue("secretKey", uuid(32));
        setValue("policy", polictText);
      }
    }
  }, [dispatch, isOpen, selectedAccessKeyName, setValue]);

  // useEffect(() => {
  //   if (isOpen && selectedAccessKey) {
  //     const expiry = selectedAccessKey.expiration?.split("T");
  //     console.log("expiry", expiry);
  //     console.log(
  //       " selectedAccessKey.expiration",
  //       selectedAccessKey.expiration
  //     );

  //     setValue("policy", selectedAccessKey.policy);
  //     if (expiry)
  //       setValue("expiration", expiry[0] + "T" + expiry[1].substring(0, 8));
  //     setValue("name", selectedAccessKey.name);
  //     setValue("description", selectedAccessKey.description);
  //     setValue("comments", "");
  //     setIsEnabled(selectedAccessKey.accountStatus === "on");
  //   }
  // }, [isOpen, selectedAccessKey, setValue]);

  useEffect(() => {
    if (isOpen && selectedAccessKey) {
      setValue("policy", selectedAccessKey.policy);
      setValue("name", selectedAccessKey.name);
      setValue("description", selectedAccessKey.description);
      setValue("comments", "");
      setIsEnabled(selectedAccessKey.accountStatus === "on");
      if (selectedAccessKey.expiration) {
        const formattedTimestamp = formatTimestamp(
          selectedAccessKey.expiration,
          "YYYY-MM-DDTHH:mm",
          { isUTC: false }
        );
        setValue("expiration", formattedTimestamp.datetime);
      }
    }
  }, [isOpen, selectedAccessKey, setValue]);

  const handleClearComponent = () => {
    setIsPolicy(false);
    setIsEnabled(true);
    reset();
    setValue("accessKey", "");
    setValue("secretKey", "");
    setValue("policy", "");
    setValue("name", "");
    setValue("description", "");
    setValue("comments", "");
    setValue("expiration", "");
  };

  const onSubmit = (data: any) => {
    let formattedDate = null;

    if (data.expiration) {
      const inputDate = new Date(data.expiration);
      console.log("inputDate", inputDate);
      // Extract components
      const year = inputDate.getFullYear();
      const month = String(inputDate.getMonth() + 1).padStart(2, "0");
      const day = String(inputDate.getDate()).padStart(2, "0");
      const hours = String(inputDate.getHours()).padStart(2, "0");
      const minutes = String(inputDate.getMinutes()).padStart(2, "0");
      const seconds = String(inputDate.getSeconds()).padStart(2, "0");
      const milliseconds = String(inputDate.getMilliseconds()).padStart(3, "0");

      // Get the time zone offset in hours and minutes
      const timeZoneOffsetHours = Math.floor(
        inputDate.getTimezoneOffset() / 60
      );
      const timeZoneOffsetMinutes = Math.abs(
        inputDate.getTimezoneOffset() % 60
      );
      const timeZoneOffsetSign = inputDate.getTimezoneOffset() > 0 ? "-" : "+";

      formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${timeZoneOffsetSign}${String(
        Math.abs(timeZoneOffsetHours)
      ).padStart(2, "0")}:${String(timeZoneOffsetMinutes).padStart(2, "0")}`;

      console.log("formattedDate", formattedDate);
    }

    if (selectedAccessKey) {
      const updatedData: S3CreatedAccessKey = {
        policy: data.policy || "",
        expiry: formattedDate || null,
        name: data.name,
        description: data.description || "",
        comments: data.comments || "",
        status: isEnabled ? "on" : "off",
      };

      if (selectedAccessKeyName) {
        dispatch(
          updateS3AccessKeyAsync({
            accessKey: selectedAccessKeyName,
            data: updatedData,
          })
        ).then((action) => {
          if (action.type === "s3-access-keys/update/fulfilled") {
            handleClearComponent();
            dispatch(handleHideEditor());
            dispatch(getS3AccessKeysAsync());
          }
        });
      }
    } else {
      const createdData: S3CreatedAccessKey = {
        accessKey: data.accessKey,
        secretKey: data.secretKey,
        policy: data.policy,
        expiry: formattedDate,
        name: data.name,
        description: data.description,
        comments: data.comments,
      };
      dispatch(createS3AccessKeyAsync(createdData)).then((action) => {
        if (action.type === "s3-access-keys/create/fulfilled") {
          handleClearComponent();
          dispatch(handleHideEditor());
          dispatch(getS3AccessKeysAsync());
          dispatch(handleShowCreatedAccessKey(createdData));
        }
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleHideEditor());
        handleClearComponent();
      }}
      contentClassName="max-w-lg"
      containerClassName="!items-start"
      title={
        selectedAccessKeyName ? "Update access key" : "Create new access key"
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col w-full gap-5">
          {!selectedAccessKeyName && (
            <>
              {" "}
              <Input
                label="Access key"
                inputProps={{ ...register("accessKey") }}
                error={errors.accessKey?.message}
                placeholder=""
              />
              <Input
                label="Secret key"
                inputProps={{ ...register("secretKey") }}
                error={errors.secretKey?.message}
                placeholder=""
              />
            </>
          )}

          <div className="flex flex-col gap-2">
            <div className="flex items-center justify-between w-full">
              <div className="text-sm text-slate-700 dark:text-slate-200">
                Restrict beyond user policy ?
              </div>
              <Switcher
                on={isPolicy}
                onToggle={setIsPolicy}
                disabled={!!selectedAccessKeyName}
              />
            </div>

            {isPolicy && (
              <div className="">
                <div className="text-xs text-slate-500 dark:text-slate-200">
                  Current User Policy - edit the JSON to remove permissions for
                  this service account
                </div>
                <Textarea
                  label=""
                  textareaClassName="w-full"
                  textareaProps={{
                    ...register("policy"),
                    rows: 10,
                  }}
                  error={errors.policy?.message}
                />
              </div>
            )}
          </div>

          <Input
            label="Expiry"
            inputProps={{ ...register("expiration"), type: "datetime-local" }}
            error={errors.expiration?.message}
          />

          <Input
            label="Name"
            inputProps={{ ...register("name") }}
            error={errors.name?.message}
            placeholder=""
          />
          <Input
            label="Description"
            inputProps={{ ...register("description") }}
            error={errors.description?.message}
            placeholder=""
          />
          <Input
            label="Comments"
            inputProps={{ ...register("comments") }}
            error={errors.comments?.message}
            placeholder=""
          />
          {selectedAccessKeyName && (
            <div className="flex items-center w-full gap-2">
              <div className="text-sm text-slate-700 dark:text-slate-200">
                Status
              </div>
              <Switcher on={isEnabled} onToggle={setIsEnabled} />
            </div>
          )}
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            loading={loading}
            type="primary"
            buttonProps={{
              disabled: loading,
              type: "submit",
            }}
            buttonClassName="w-[100px]"
          >
            {selectedAccessKeyName ? "Update" : "Create"}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default S3AccessKeyEditorModal;
