import classNames from "classnames";
import { useAppSelector } from "../../../../hooks";
import {
  selectS3Metrics,
  selectS3MetricsLoading,
} from "../../../../store/s3/overviewSlice";
import { S3MetricsServer } from "../../../../types/s3";
import Accordion from "../../../general/Accordion";
import Text from "../../../general/Text";
import PieChart, { PieChartData } from "../../../general/charts/PieChart";
import { humanizeSize } from "../../../../utils/file";
// import { ReactComponent as CircleStackIcon } from "./../../../../assets/icons/circle-stack.svg";

const ServersWidget = () => {
  const info = useAppSelector(selectS3Metrics);
  const infoLoading = useAppSelector(selectS3MetricsLoading);
  return (
    <div className="w-full col-span-1 flex flex-col gap-5">
      {info?.servers.map((server, i) => (
        <Accordion
          key={i}
          items={[
            {
              label: <ServerLabel server={server} />,
              panel: <ServerPanel server={server} />,
            },
          ]}
          panelClassNames="p-4 md:p-6 lg:p-10"
          loading={infoLoading}
        />
      ))}
    </div>
  );
};

const ServerLabel = ({ server }: { server: S3MetricsServer }) => {
  return (
    <div className="w-full flex items-center justify-between pr-2 md:pr-5">
      <div className="flex items-center gap-1.5">
        <Text className="text-xs md:text-sm">{server.endpoint}</Text>
        <span
          className={classNames("w-3 h-3 rounded-full", {
            "bg-green-500": server.state === "online",
            "bg-red-500": server.state !== "online",
          })}
        />
      </div>
      <div className="flex gap-6 md:gap-9">
        <div className="flex flex-col">
          <Text className="text-xs md:text-sm">
            {server.drives.filter((d) => d.state === "ok").length}/
            {server.drives.length}
          </Text>
          <Text type="subtext" className="text-xs md:text-sm font-semibold">
            Drives
          </Text>
        </div>

        <div className="flex flex-col">
          <Text className="text-xs md:text-sm">
            {Object.values(server.network).filter((n) => n === "online").length}
            /{Object.keys(server.network).length}
          </Text>
          <Text type="subtext" className="text-xs md:text-sm font-semibold">
            Network
          </Text>
        </div>

        <div className="flex flex-col">
          <Text className="text-xs md:text-sm">
            {Math.floor(server.uptime / 100000)} days
          </Text>
          <Text type="subtext" className="text-xs md:text-sm font-semibold">
            Up time
          </Text>
        </div>
      </div>
      <div className="items-center gap-1.5 hidden md:flex">
        Version:{server.version}
      </div>
    </div>
  );
};

const ServerPanel = ({ server }: { server: S3MetricsServer }) => {
  return (
    <div className="flex flex-col gap-4">
      {server.drives.map((drive, i) => {
        const usedPer = Math.round((drive.usedSpace / drive.totalSpace) * 100);
        const pieData: PieChartData[] = [
          {
            name: "Free",
            value: 100 - usedPer,
            className: "fill-[#eee] dark:fill-white/10",
          },
          {
            name: "Usage",
            value: usedPer,
            className: "fill-primary-500",
          },
        ];
        return (
          <div
            key={i}
            className="flex flex-col md:flex-row items-center justify-between gap-3 border dark:border-dark-2 rounded-lg p-5"
          >
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-1.5">
                <Text className="text-xs md:text-sm">{drive.endpoint}</Text>
                <span
                  className={classNames("w-3 h-3 rounded-full", {
                    "bg-green-500": drive.state === "ok",
                    "bg-red-500": drive.state !== "ok",
                  })}
                />
              </div>
              <PieChart
                data={pieData}
                centerLabel={humanizeSize(drive.usedSpace, {
                  fractionDigits: 1,
                }).join("B")}
              />
            </div>
            <div className="flex gap-7 lg:gap-9">
              <div className="flex flex-col">
                <Text className="text-xs md:text-sm">
                  {humanizeSize(drive.totalSpace, { fractionDigits: 1 }).join(
                    "B"
                  )}
                </Text>
                <Text
                  type="subtext"
                  className="text-xs md:text-sm font-semibold"
                >
                  Capacity
                </Text>
              </div>

              <div className="flex flex-col">
                <Text className="text-xs md:text-sm">
                  {humanizeSize(drive.usedSpace, { fractionDigits: 1 }).join(
                    "B"
                  )}
                </Text>
                <Text
                  type="subtext"
                  className="text-xs md:text-sm font-semibold"
                >
                  Used
                </Text>
              </div>

              <div className="flex flex-col">
                <Text className="text-xs md:text-sm">
                  {humanizeSize(drive.availableSpace, {
                    fractionDigits: 1,
                  }).join("B")}
                </Text>
                <Text
                  type="subtext"
                  className="text-xs md:text-sm font-semibold"
                >
                  Available
                </Text>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ServersWidget;
