import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  selectInviteLoading,
  selectInviteModalIsOpen,
  inviteUserAsync,
  closeInviteModal,
  getTeamsAsync,
} from "../../../store/settings/teams/teamsSlice";
import { InviteUserSchema } from "../../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Modal from "../../modals/Modal";
import Input from "../../inputs/Input";
import Button from "../../buttons/Button";

const InviteUserModal = () => {
  const isOpen = useAppSelector(selectInviteModalIsOpen);
  const loading = useAppSelector(selectInviteLoading);
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(InviteUserSchema),
  });

  const onSubmit = (data: any) => {
    dispatch(
      inviteUserAsync({
        // Name: data.name,
        Email: data.email,
      })
    ).then((action) => {
      if (action.type === "teams/invite/fulfilled") {
        reset();
        dispatch(closeInviteModal());
        dispatch(getTeamsAsync());
      }
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        reset();
        dispatch(closeInviteModal());
      }}
      contentClassName="max-w-lg"
      //   containerClassName="!items-start"
      title="Invite new user"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col w-full gap-5">
          {/* <Input
            label="What's their name?"
            inputProps={{ ...register("name") }}
            error={errors.name?.message}
            placeholder=""
          /> */}
          <Input
            label="What's their email address?"
            inputProps={{ ...register("email") }}
            error={errors.email?.message}
            placeholder=""
          />
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            loading={loading}
            type="primary"
            buttonProps={{
              disabled: loading,
              type: "submit",
            }}
            buttonClassName="w-[160px]"
          >
            Add team member
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default InviteUserModal;
