import { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  getS3WidgetAsync,
  selectS3EndTime,
  selectS3StartTime,
  selectUptime,
  selectUptimeLoading,
} from "../../../../store/s3/overviewSlice";
import { S3WidgetType } from "../../../../types/s3";
import StatWidget from "./freams/StatWidget";
import { getLastValueOfWidgetTarget } from "../../../../utils/bucket";
import { ReactComponent as ClockIcon } from "./../../../../assets/icons/clock.svg";

const chartType: S3WidgetType = "uptime";
const chartCode: number = 1;

const UptimeWidget = () => {
  const data = useAppSelector(selectUptime);
  const loading = useAppSelector(selectUptimeLoading);
  const dispatch = useAppDispatch();
  const startTime = useAppSelector(selectS3StartTime);
  const endTime = useAppSelector(selectS3EndTime);

  useEffect(() => {
    dispatch(
      getS3WidgetAsync({ key: chartType, n: chartCode, startTime, endTime })
    );
  }, [dispatch, endTime, startTime]);

  const getUptime = useMemo(() => {
    if (data?.targets && data.targets.length > 0) {
      return Math.floor(
        Number(getLastValueOfWidgetTarget(data?.targets)[1]) / 100000
      );
    } else {
      return 0;
    }
  }, [data?.targets]);

  return (
    <StatWidget
      title="Uptime"
      value={`${getUptime} days`}
      dataLoading={loading}
      icon={<ClockIcon className="w-4 text-slate-800 dark:text-slate-300" />}
    />
  );
};

export default UptimeWidget;
