import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Tabs from "../../components/general/Tabs";
import { TabOptions } from "../../types/tabs";
import {
  SettingsBillingUrl,
  SettingsEnvVariablesUrl,
  SettingsProfileUrl,
  SettingsRegisteriesUrl,
  SettingsTeamsUrl,
} from "../../utils/urls";
import Text from "../../components/general/Text";
import SettingProfileTab from "../../components/settings/profile/SettingProfileTab";
import SettingsTeamsTab from "../../components/settings/teams/SettingsTeamsTab";
import SettingBillingTab from "../../components/settings/billing/SettingBillingTab";
import SettingsRegisteriesTab from "../../components/settings/registeries/RegisteriesTab";
import EnvironmentVariablesTab from "../../components/settings/env-variables/EnvironmentVariablesTab";
import { useAppSelector } from "../../hooks";
import { selectOnStageEnv } from "../../store/auth/authSlice";
import { useEffect } from "react";
import { ReactComponent as ProfileIcon } from "./../../assets/icons/user-team.svg";
import { ReactComponent as TeamsIcon } from "./../../assets/icons/teams.svg";
import { ReactComponent as BanknotesIcon } from "./../../assets/icons/bank-notes.svg";
import { ReactComponent as RegisteriesIcon } from "./../../assets/icons/shield-check.svg";

export const SettingsPageMenus: TabOptions<{ showOnlyOwner: boolean }> = [
  {
    label: (
      <div className="flex items-center gap-1">
        <ProfileIcon className="w-3" />
        Profile
      </div>
    ),
    url: SettingsProfileUrl,
    element: <SettingProfileTab />,
    setting: { showOnlyOwner: false },
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <TeamsIcon className="w-3" />
        Teams
      </div>
    ),
    url: SettingsTeamsUrl,
    element: <SettingsTeamsTab />,
    setting: { showOnlyOwner: true },
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <BanknotesIcon className="w-3" />
        Billing
      </div>
    ),
    url: SettingsBillingUrl,
    element: <SettingBillingTab />,
    setting: { showOnlyOwner: true },
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <RegisteriesIcon className="w-3" />
        Registeries
      </div>
    ),
    url: SettingsRegisteriesUrl,
    element: <SettingsRegisteriesTab />,
    setting: { showOnlyOwner: false },
  },
  // {
  //   label: "Environment Variables",
  //   url: SettingsEnvVariablesUrl,
  //   element: <EnvironmentVariablesTab />,
  //   setting: { showOnlyOwner: true },
  // },
];

const SettingsPage = () => {
  const onStageEnv = useAppSelector(selectOnStageEnv);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (onStageEnv && !onStageEnv.IsAccountOwner) {
      const tabOption = SettingsPageMenus.find(
        (m) => m.url === location.pathname
      );
      if (tabOption && tabOption.setting?.showOnlyOwner) {
        navigate(SettingsProfileUrl);
      }
    }
  }, [onStageEnv, location.pathname, navigate]);
  return (
    <>
      <div className="flex items-center justify-between h-16">
        <div className="px-6 items-center justify-between flex flex-1 transition duration-150">
          <Text className="font-medium mr-2 text-standard text-xl">
            Settings
          </Text>
        </div>
      </div>
      <div className="px-6">
        <Tabs
          useUrl
          options={SettingsPageMenus.filter((o) =>
            o.setting?.showOnlyOwner ? onStageEnv?.IsAccountOwner : true
          ).map((o) => ({
            label: o.label,
            url: o.url,
          }))}
        />
        <div className="py-6">
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default SettingsPage;
