import { useAppDispatch, useAppSelector } from "../../../hooks";
import Modal from "../../modals/Modal";
import Input from "../../inputs/Input";
import Button from "../../buttons/Button";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DBSIntegrationSchema } from "../../../utils/validations";

import { useEffect, useMemo, useState } from "react";
import {
  addDBSServiceIntegrationAsync,
  getDBSServiceIntegrationCapabilitiesAsync,
  getDBSServiceIntegrationsAsync,
  handleDBSIntegrationEditor,
  selectDBSIntegrationCapabilities,
  selectDBSIntegrationCapabilitiesLoading,
  selectDBSIntegrations,
  selectDBSIntegrationsActionLoading,
  selectDBSIntegrationsShowEditor,
  selectDatabaseService,
} from "../../../store/database/serviceSlice";
import { Select2, SelectOption } from "../../inputs/Select";
import { DBSIntegrationCapability } from "../../../types/database";
import Alert from "../../general/Alert";
import Text from "../../general/Text";
import {
  getDatabaseServicesAsync,
  selectDatabaseServices,
  selectDatabaseServicesLoading,
} from "../../../store/database/servicesSlice";

const DBSIntegrationCreateModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectDBSIntegrationsShowEditor);

  const integrations = useAppSelector(selectDBSIntegrations);
  const actionLoading = useAppSelector(selectDBSIntegrationsActionLoading);

  const integrationCapabilities = useAppSelector(
    selectDBSIntegrationCapabilities
  );
  const integrationCapabilitiesLoading = useAppSelector(
    selectDBSIntegrationCapabilitiesLoading
  );

  const service = useAppSelector(selectDatabaseService);
  const services = useAppSelector(selectDatabaseServices);
  const servicesLoading = useAppSelector(selectDatabaseServicesLoading);

  //form init
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm({
    resolver: yupResolver(DBSIntegrationSchema),
    mode: "all",
  });

  const selectedType = watch("type");
  const sourceServiceId = watch("sourceServiceId");
  const destinationServiceId = watch("destinationServiceId");

  //states
  const [selectedIntegration, setSelectedIntegration] =
    useState<DBSIntegrationCapability>();
  const [sourceServiceTypes, setSourceServiceTypes] = useState<Array<string>>(
    []
  );
  const [targetServiceTypes, setTargetServiceTypes] = useState<Array<string>>(
    []
  );

  //recieve all created services
  useEffect(() => {
    if (isOpen && services.length === 0) {
      dispatch(getDatabaseServicesAsync());
    }
  }, [dispatch, isOpen, services]);

  //get service-integration capabilities
  useEffect(() => {
    if (service && isOpen) {
      dispatch(
        getDBSServiceIntegrationCapabilitiesAsync({
          engine: service.engine,
          clusterId: service.id,
        })
      );
    }
  }, [dispatch, isOpen, service]);

  //create new integration
  const onSubmit = (data: any) => {
    if (service) {
      dispatch(
        addDBSServiceIntegrationAsync({
          clusterId: service.id,
          engine: service.engine,
          data: data,
        })
      ).then((action) => {
        if (action.type === "service/service-integrations/create/fulfilled") {
          dispatch(handleDBSIntegrationEditor(false));
          dispatch(
            getDBSServiceIntegrationsAsync({
              engine: service.engine,
              clusterId: service.id,
            })
          );
        }
      });
    }
  };

  const handleClose = () => {
    reset();
    dispatch(handleDBSIntegrationEditor(false));
  };

  const integrationOptions: SelectOption<string, DBSIntegrationCapability>[] =
    useMemo(() => {
      return Array.from(
        integrationCapabilities
          .reduce((map, obj) => map.set(obj.type, obj), new Map())
          .values()
      ).map((ic) => {
        const option: SelectOption<string, DBSIntegrationCapability> = {
          label: ic.type,
          value: ic.type,
          extraData: ic,
        };
        return option;
      });
    }, [integrationCapabilities]);

  //calculate selected integration and source types and destination types
  useEffect(() => {
    const selectedIntegration = integrationOptions.find(
      (integrationOption) => integrationOption.value === selectedType
    )?.extraData;
    setSelectedIntegration(selectedIntegration);

    let sources: string[] = [];
    let destinations: string[] = [];

    integrationCapabilities
      .filter((ic) => ic.type === selectedType)
      .forEach((ic) => {
        sources.push(ic.sourceEngine);
        destinations.push(ic.destinationEngine);
      });

    sources = Array.from(new Set(sources).values());
    destinations = Array.from(new Set(destinations).values());

    setSourceServiceTypes(sources);
    setTargetServiceTypes(destinations);
  }, [selectedType, integrationOptions, integrationCapabilities]);

  //source services
  const sourceServices = useMemo(() => {
    return services
      .filter((s) => s.status === "READY")
      .filter((s) => sourceServiceTypes.includes(s.engine));
  }, [services, sourceServiceTypes]);

  //create select options from sourceServices
  const sourceServicesOptions: SelectOption[] = useMemo(() => {
    return sourceServices.map((s) => {
      const option: SelectOption<string> = {
        label: s.description,
        value: s.id,
      };
      return option;
    });
  }, [sourceServices]);

  //destination services
  const destinationServices = useMemo(() => {
    return services
      .filter((s) => s.status === "READY")
      .filter((s) => targetServiceTypes.includes(s.engine));
  }, [services, targetServiceTypes]);

  //create select options from destinationServices
  const destinationServicesOptions: SelectOption[] = useMemo(() => {
    return destinationServices.map((s) => {
      const option: SelectOption<string> = {
        label: s.description,
        value: s.id,
      };
      return option;
    });
  }, [destinationServices]);

  const isCreatedBefore = useMemo(() => {
    return !!integrations.find(
      (i) =>
        i.sourceServiceId === sourceServiceId &&
        i.destinationServiceId === destinationServiceId
    );
  }, [destinationServiceId, integrations, sourceServiceId]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      contentClassName="max-w-lg"
      title={`Add an integration`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5">
          {selectedType &&
            (destinationServicesOptions.length === 0 ||
              sourceServicesOptions.length === 0) && (
              <Alert type="warning" className="mb-5">
                <div className="flex flex-col">
                  <Text className="text-sm" type="transparent">
                    You do not have a service to add this type of integration.
                    Please add a service powered by one of the engines listed
                    below, and a type of public network:
                  </Text>
                  <div className="flex flex-col mt-2">
                    {sourceServicesOptions.length === 0 &&
                      sourceServiceTypes.map((type, i) => (
                        <Text
                          key={i}
                          className="text-sm font-medium"
                          type="transparent"
                        >
                          - {type}
                        </Text>
                      ))}
                    {destinationServicesOptions.length === 0 &&
                      targetServiceTypes.map((type, i) => (
                        <Text
                          key={i}
                          className="text-sm font-medium"
                          type="transparent"
                        >
                          - {type}
                        </Text>
                      ))}
                  </div>
                </div>
              </Alert>
            )}
          {isCreatedBefore && (
            <Alert type="warning" className="mb-5">
              <Text className="text-sm" type="transparent">
                A similar integration already exists.
              </Text>
            </Alert>
          )}
          <Controller
            control={control}
            name="type"
            render={({ field: { value, onChange } }) => (
              <Select2
                value={value}
                onChange={(v) => {
                  setValue("sourceServiceId", "");
                  setValue("destinationServiceId", "");
                  onChange(v);
                }}
                options={[...integrationOptions]}
                error={errors.type?.message}
                label="Integration type"
                required
                emptyString="Select an integration type"
                loading={integrationCapabilitiesLoading}
              />
            )}
          />
        </div>

        <div className="flex items-start w-full gap-5 mt-5">
          <Controller
            control={control}
            name="sourceServiceId"
            render={({ field: { value, onChange } }) => (
              <Select2
                disabled={sourceServicesOptions.length === 0}
                value={value || undefined}
                onChange={onChange}
                options={sourceServicesOptions}
                error={errors.sourceServiceId?.message}
                label="Source service"
                required
                emptyString=""
                className="flex-1"
                loading={servicesLoading}
              />
            )}
          />
          <Controller
            control={control}
            name="destinationServiceId"
            render={({ field: { value, onChange } }) => (
              <Select2
                disabled={destinationServicesOptions.length === 0}
                value={value || undefined}
                onChange={onChange}
                options={destinationServicesOptions}
                error={errors.destinationServiceId?.message}
                label="Target service"
                required
                emptyString=""
                className="flex-1"
                loading={servicesLoading}
              />
            )}
          />
        </div>

        <div className="grid grid-cols-2 w-full gap-5 mt-5">
          {selectedIntegration?.parameters?.map((parameter, i) => {
            return (
              <div className="col-span-1" key={i}>
                <Input
                  label={parameter.name}
                  inputProps={{
                    ...register(
                      parameter.name as "topic" | "indexPrefix" | "indexDaysMax"
                    ),
                    type:
                      parameter.type === "integer" ? "number" : parameter.type,
                  }}
                  error={errors.topic?.message}
                  className=""
                />
              </div>
            );
          })}
        </div>

        <div className="mt-4 flex justify-end gap-2">
          <Button
            type="light"
            buttonProps={{
              onClick: (e) => {
                e.preventDefault();
                handleClose();
              },
            }}
          >
            Cancel
          </Button>

          <Button
            type="primary"
            buttonProps={{
              type: "submit",
              disabled: isCreatedBefore,
            }}
            loading={actionLoading}
          >
            Confirm
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default DBSIntegrationCreateModal;
