import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { EnvAddFromFileSchema } from "../../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Modal from "../../modals/Modal";
import Button from "../../buttons/Button";
import {
  createEnvVariableAsync,
  createMultipleEnvVariableAsync,
  getEnvVariablesAysnc,
  handleHideImportFromEnvFileModal,
  selectEnvVariablesActionLoading,
  selectShowImportFromEnvFileModal,
} from "../../../store/settings/env-variables/envVariablesSlice";
import Textarea from "../../inputs/Textarea";
import Text from "../../general/Text";

const ImportFromEnvFileModal: React.FC<{ callback?: any }> = ({ callback }) => {
  const isOpen = useAppSelector(selectShowImportFromEnvFileModal);
  const loading = useAppSelector(selectEnvVariablesActionLoading);

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(EnvAddFromFileSchema),
  });

  const onSubmit = (data: any) => {
    const envs = data.Value as string;
    const envsArr = envs
      .split("\n")
      .filter(Boolean)
      .filter((d) => d.includes("="))
      .map((item) => {
        const [Key, Value] = item.split("=");
        return { Key, Value };
      });

    if (callback) {
      callback(envsArr);
      dispatch(handleHideImportFromEnvFileModal());
    } else {
      dispatch(createMultipleEnvVariableAsync(data)).then((action) => {
        if (action.type === "envVariables/create/multiple/fulfilled") {
          reset();
          dispatch(handleHideImportFromEnvFileModal());
          dispatch(getEnvVariablesAysnc());
        }
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        reset();
        dispatch(handleHideImportFromEnvFileModal());
      }}
      contentClassName="max-w-lg"
      title="Add from .env"
    >
      <form>
        <div className="mt-5 flex flex-col w-full gap-5">
          <Text type="subtext" className="text-sm">
            Paste your .env contents to add multiple environment variables at
            onece
          </Text>
          <Textarea
            textareaProps={{ rows: 7, ...register("Value") }}
            error={errors.Value?.message}
            placeholder={`KEY_1=VALUE_1 \nKEY_2=VALUE_2 \nKEY_3=VALUE_3`}
          />
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            loading={loading}
            type="primary"
            buttonProps={{
              type: "submit",
              onClick: (e) => {
                e.preventDefault();
                handleSubmit(onSubmit)();
              },
            }}
            buttonClassName="w-[160px]"
          >
            Add Variables
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ImportFromEnvFileModal;
