import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ReactComponent as DockerColorIcon } from "./../../../assets/icons/socials/docker-color.svg";
import {
  getWebAppPreviewsAsync,
  selectWebApp,
  selectWebAppLoading,
  selectWebAppPreviews,
  selectWebAppPreviewsLoading,
} from "../../../store/web-app/webAppSlice";
import Text, { A } from "../../general/Text";
import NotData from "../../layouts/NotData";
import Loading from "../../general/Loading";
import { timeAgo } from "../../../utils/date";
import Card from "../../general/Card";

const WebAppPreviewTab = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);

  const previews = useAppSelector(selectWebAppPreviews);
  const previewsLoading = useAppSelector(selectWebAppPreviewsLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (webApp) {
      dispatch(getWebAppPreviewsAsync({ webAppId: webApp.Id.toString() }));
    }
  }, [dispatch, webApp]);

  return (
    <Card
      title="Service Previews"
      description={
        <div className="col-span-1 text-sm font-normal font-sans mt-2">
          <Text>
            Inspect and review functional and visual changes for the code in
            your pull requests before merging them. Use Render’s API to create
            previews of your service. <A>Read the docs</A>
          </Text>
        </div>
      }
    >
      <ul className="mt-2 border dark:border-dark-2 rounded-lg bg-white dark:bg-dark-3">
        {previewsLoading && (
          <Loading borderSize={2} className="min-h-[300px]" />
        )}
        {!previewsLoading && previews.length === 0 && (
          <NotData text="Previews created via the API will be listed here." />
        )}
        {!previewsLoading &&
          previews.length > 0 &&
          previews.map((preview, index) => (
            <li
              key={index}
              className="p-4 border-b last:border-b-0 dark:border-dark-2"
            >
              <div className="flex justify-between items-center">
                <div className="flex items-center flex-1 gap-4">
                  <span>
                    {preview.Type === "image" && (
                      <DockerColorIcon className="w-4 aspect-square" />
                    )}
                  </span>
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-3">
                      <Text className="text-sm flex items-center gap-1">
                        {preview.CommitId}
                      </Text>
                    </div>

                    <div className="flex items-center gap-3">
                      <Text className="text-sm flex items-center gap-1 font-light">
                        {webApp?.Name}
                        {" Preview "}
                        {preview.CommitId.substring(0, 7)}
                        {" created "}
                        {timeAgo(preview.CreatedAt)}
                        {" by "}
                        {preview.By}
                      </Text>
                    </div>

                    <A className="text-xs">{preview.Link}</A>
                  </div>
                </div>
                <div className="flex items-center"></div>
              </div>
            </li>
          ))}
      </ul>
    </Card>
  );
};

export default WebAppPreviewTab;
