import NotData from "../../../components/layouts/NotData";
import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useMemo, useState } from "react";
import Button from "../../../components/buttons/Button";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import { ReactComponent as InfoIcon } from "./../../../assets/icons/eye.svg";

import { useNavigate } from "react-router-dom";
import {
  DatabaseServiceGeneralInformationUrl,
  DatabaseUsersCreateUrl,
} from "../../../utils/urls";
import Dropdown from "../../../components/general/Dropdown";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../../components/general/Table";
import QuestionModal, {
  DeleteModal,
} from "../../../components/modals/QuestionModal";
import Text from "../../../components/general/Text";
import { useSearch } from "../../../providers/SearchProvider";
import Highlighter from "react-highlight-words";
import {
  getDatabaseServicesAsync,
  selectDatabaseServicesLoading,
} from "../../../store/database/servicesSlice";
import { formatTimestamp } from "../../../utils/date";
import {
  deleteDBSUserAsync,
  getDBSUsersAsync,
  getDatabaseServiceAsync,
  handleDBSShowUserDetailsModal,
  resetDBSUsersAsync,
  selectDatabaseService,
  selectDatabaseServiceUser,
  selectDatabaseServiceUserLoading,
  selectDatabaseServiceUsers,
  selectDatabaseServiceUsersLoading,
} from "../../../store/database/serviceSlice";
import { IDBSUser } from "../../../types/database";
import { generateServicePermissions } from "../../../pages/databases/ServicePage";
import UserDetailsModal from "./UserDetailsModal";

const UserTab = () => {
  const [DBSUserId, setDBSUserId] = useState<string | null>(null);
  const [deleteUser, setDeleteUser] = useState<string | null>(null);
  const [filteredUsers, setFilteredUsers] = useState<IDBSUser[]>([]);

  const service = useAppSelector(selectDatabaseService);
  const user = useAppSelector(selectDatabaseServiceUser);
  const users = useAppSelector(selectDatabaseServiceUsers);
  const usersLoading = useAppSelector(selectDatabaseServiceUsersLoading);
  const userLoading = useAppSelector(selectDatabaseServiceUserLoading);
  const loading = useAppSelector(selectDatabaseServicesLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (service) {
      const permissions = generateServicePermissions().users;
      if (!permissions.includes(service.engine)) {
        navigate(DatabaseServiceGeneralInformationUrl(service.id));
      } else {
        dispatch(getDBSUsersAsync({ id: service.id, engine: service.engine }));
      }
    }
  }, [dispatch, navigate, service]);

  useEffect(() => {
    const lookedUpFiles = users.filter((user) =>
      user.username?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredUsers(lookedUpFiles);
  }, [dispatch, searchValue, users]);

  const isAnyUserDeleting = useMemo(() => {
    return users.some((user) => user.status === "DELETING");
  }, [users]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Database</title>
        <meta name="description" content="" />
      </Helmet>

      {service && (
        <>
          <div className="flex items-center justify-between">
            <div className="items-center justify-between flex flex-1 transition duration-150">
              <Text className="font-medium text-lg">Users & Roles</Text>
            </div>
            <div className="flex items-center gap-2">
              <Button
                size="medium"
                type="light"
                buttonProps={{
                  onClick: () => {
                    if (service)
                      dispatch(
                        getDBSUsersAsync({
                          id: service.id,
                          engine: service.engine,
                        })
                      );
                  },
                  disabled: !service,
                }}
                buttonClassName="group"
                tooltip="Refresh"
              >
                <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
              </Button>
              <Button
                type="primary"
                buttonProps={{
                  onClick: () => navigate(DatabaseUsersCreateUrl(service.id)),
                  disabled: isAnyUserDeleting,
                }}
                buttonClassName="group"
              >
                Add user
                <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
              </Button>
            </div>
          </div>
          <div className="mt-5 w-full">
            <Table loading={usersLoading}>
              <TableHead>
                <TableRow>
                  <TableTH lable="Username" />
                  {service.engine !== "mysql" &&
                    service.engine !== "m3db" &&
                    service.engine !== "redis" &&
                    service.engine !== "cassandra" && <TableTH lable="Roles" />}

                  {service.engine === "m3db" && <TableTH lable="Group" />}
                  <TableTH lable="Creation date" />
                  <TableTH lable="Status" />
                  <TableTH lable="" />
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredUsers.map((user, i) => (
                  <TableRow key={i}>
                    <TableTD>
                      <div className="flex items-center flex-row justify-between space-x-3">
                        <Highlighter
                          highlightClassName="bg-primary-100 dark:bg-primary-300 rounded-sm"
                          className="whitespace-nowrap max-w-[240px] text-ellipsis overflow-hidden text-sm font-medium"
                          searchWords={[searchValue]}
                          autoEscape={true}
                          textToHighlight={user.username}
                        />
                      </div>
                    </TableTD>

                    {user.roles && (
                      <TableTD>
                        <Text className="text-xs md:text-sm">
                          {user.roles.map((r) => (
                            <div>{r}</div>
                          ))}
                        </Text>
                      </TableTD>
                    )}

                    {service.engine === "m3db" && (
                      <TableTD>
                        <Text className="text-xs md:text-sm">{user.group}</Text>
                      </TableTD>
                    )}
                    <TableTD>
                      <Text className="text-xs md:text-sm">
                        {
                          formatTimestamp(user.createdAt, "MMM DD hh:mm:ss ")
                            .datetime
                        }
                      </Text>
                    </TableTD>

                    <TableTD>
                      <Text
                        className="text-xs"
                        type={
                          user.status === "READY"
                            ? "success-alert"
                            : "warning-alert"
                        }
                      >
                        {user.status}
                      </Text>
                    </TableTD>

                    <TableTD className="w-32 gap-1">
                      <div className="h-full w-full inline-flex items-center justify-center gap-1">
                        <div className="w-8 flex justify-center items-center ">
                          <Dropdown
                            positionClassName="!w-48"
                            menu={[
                              ...(service.engine === "redis"
                                ? [
                                    {
                                      key: "details",
                                      label: (
                                        <div className="flex items-center gap-1">
                                          <InfoIcon className="w-4" />
                                          View Details
                                        </div>
                                      ),
                                      onClick: (_: any, close: any) => {
                                        close();
                                        dispatch(
                                          handleDBSShowUserDetailsModal(user)
                                        );
                                      },
                                      disabled:
                                        loading ||
                                        isAnyUserDeleting ||
                                        user.status === "PENDING",
                                    },
                                  ]
                                : []),
                              {
                                key: "reset",
                                label: (
                                  <div className="flex items-center gap-1">
                                    <RefreshIcon className="w-4" />
                                    Reset the password
                                  </div>
                                ),
                                onClick: (_, close) => {
                                  close();
                                  setDBSUserId(user.id);
                                },
                                disabled:
                                  loading ||
                                  isAnyUserDeleting ||
                                  user.status === "PENDING",
                              },
                              {
                                type: "divider",
                              },
                              {
                                key: "end",
                                label: (
                                  <div className="flex items-center gap-1">
                                    <ArchiveIcon className="w-4" />
                                    Delete
                                  </div>
                                ),
                                danger: true,
                                onClick: (_, close) => {
                                  close();
                                  setDeleteUser(user.id);
                                },
                                disabled:
                                  loading ||
                                  isAnyUserDeleting ||
                                  user.status === "PENDING",
                              },
                            ]}
                            type="simple"
                          >
                            <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                              <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                            </div>
                          </Dropdown>
                        </div>

                        {/* <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" /> */}
                      </div>
                    </TableTD>
                  </TableRow>
                ))}

                {users.length === 0 && !usersLoading && (
                  <TableRow withoutHoverStyle={true}>
                    <TableTD colSpan={10} className="!border-0">
                      <NotData text="You have not created any user yet" />
                    </TableTD>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <UserDetailsModal />
          <QuestionModal
            title="Reset password"
            description="Are you sure you want to reset the password for this user?"
            isOpen={DBSUserId !== null}
            onClose={() => setDBSUserId(null)}
            onConfirm={() => {
              DBSUserId &&
                service &&
                dispatch(
                  resetDBSUsersAsync({
                    id: service.id,
                    engine: service.engine,
                    userId: DBSUserId,
                    // data: DBSUserId,
                  })
                ).then((action) => {
                  if (action.type === "service/users/reset/fulfilled") {
                    dispatch(getDatabaseServicesAsync());
                    dispatch(getDatabaseServiceAsync({ id: service.id }));
                  } else {
                  }
                  setDBSUserId(null);
                });
            }}
            loading={userLoading}
            confirmButtonType="danger"
          />
          <DeleteModal
            title="Delete a User"
            isOpen={deleteUser !== null}
            onClose={() => setDeleteUser(null)}
            description="To confirm, type 'DELETE' in the box below"
            confirmButtonText="Confirm"
            confirmButtonType="danger"
            confirmButtonClassName="w-full"
            onConfirm={() => {
              deleteUser &&
                service &&
                dispatch(
                  deleteDBSUserAsync({
                    id: service.id,
                    engine: service.engine,
                    userId: deleteUser,
                  })
                ).then((action) => {
                  if (action.type === "service/user/delete/fulfilled") {
                    dispatch(getDatabaseServicesAsync());
                    dispatch(getDatabaseServiceAsync({ id: service.id }));
                    dispatch(
                      getDBSUsersAsync({
                        id: service.id,
                        engine: service.engine,
                      })
                    );
                  } else {
                  }
                  setDeleteUser(null);
                });
            }}
            loading={userLoading}
            confirmString={user?.username}
          />
        </>
      )}
    </>
  );
};

export default UserTab;
