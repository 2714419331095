import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Modal from "../../modals/Modal";
import Button from "../../buttons/Button";
import {
  getInstanceAsync,
  instancesAttachVolumeAsync,
  selectInstanceActionLoading,
} from "../../../store/instances/instanceSlice";
import {
  getInstancesAsync,
  selectInstances,
  selectInstancesLoading,
} from "../../../store/instances/instancesSlice";
import {
  getInstancesVolumesAsync,
  handleInstanceAttachHideModal,
  selectInstancesSelectedVolume,
  selectShowInstanceAttachModal,
} from "../../../store/instances/instancesVolumesSlice";
import { Select2 } from "../../inputs/Select";
import { useEffect, useMemo, useState } from "react";
import { Instance } from "../../../types/instance";
import Text from "../../general/Text";
import { ReactComponent as ErrorIcon } from "./../../../assets/icons/logs/error.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { InstanceAttachSchema } from "../../../utils/validations";
import Alert from "../../general/Alert";

const InstanceAttachModal = () => {
  const [selectedInstance, setSelectedInstance] = useState<Instance>();
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectShowInstanceAttachModal);
  const instances = useAppSelector(selectInstances);
  const volume = useAppSelector(selectInstancesSelectedVolume);
  const instancesLoading = useAppSelector(selectInstancesLoading);
  const actionLoading = useAppSelector(selectInstanceActionLoading);

  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(InstanceAttachSchema),
    shouldUnregister: true,
    reValidateMode: "onChange",
    mode: "all",
  });

  useEffect(() => {
    if (!instances) {
      dispatch(getInstancesAsync({ withoutLoading: false }));
    }
  }, [instances, dispatch]);

  const filterInstances = useMemo(() => {
    return instances.filter((instance) => instance.region === volume?.region);
  }, [instances, volume]);

  const onSubmit = (data: any) => {
    if (selectedInstance && volume) {
      const formData = { instanceId: selectedInstance.id };
      dispatch(
        instancesAttachVolumeAsync({
          volumeId: volume?.id,
          data: formData,
        })
      ).then((action) => {
        if (action.type === "instance/volume/attach/fulfilled") {
          dispatch(getInstancesAsync({ withoutLoading: false }));
          dispatch(getInstanceAsync({ instanceId: selectedInstance.id }));
          dispatch(getInstancesVolumesAsync({}));
          dispatch(handleInstanceAttachHideModal());
        }
      });
    }
  };

  const handleClose = () => {
    reset();
    dispatch(handleInstanceAttachHideModal());
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      contentClassName="max-w-lg"
      title="Attach volume to an instance"
    >
      {filterInstances.length > 0 ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-5">
            {instances && (
              <Controller
                control={control}
                name="instanceId"
                render={({ field: { value, onChange } }) => (
                  <Select2
                    value={value}
                    label="Instance"
                    options={
                      filterInstances.map((i) => ({
                        label: i.name,
                        value: i.id,
                      })) || []
                    }
                    error={errors.instanceId?.message}
                    required
                    onChange={(value) => {
                      onChange(value);
                      setSelectedInstance(
                        filterInstances.find((i) => i.id === value)
                      );
                    }}
                    loading={instancesLoading}
                  />
                )}
              />
            )}
          </div>

          <div className="mt-4 flex justify-end gap-2">
            <Button
              type="light"
              buttonProps={{
                onClick: handleClose,
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              buttonProps={{
                disabled: actionLoading,
                type: "submit",
              }}
              loading={actionLoading}
            >
              Attach
            </Button>
          </div>
        </form>
      ) : (
        <Alert
          type="warning"
          className="text-xs mb-3"
          icon={<ErrorIcon className="w-5 mr-1 aspect-square" />}
        >
          No instances available in your volume’s region.
        </Alert>
      )}
    </Modal>
  );
};

export default InstanceAttachModal;
