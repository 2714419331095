import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import Text, { HighlighterText } from "../../general/Text";
import NotData, { WorkflowNotActiveTab } from "../../layouts/NotData";
import {
  getWorkflowContractsAsync,
  selectWorkflowContracts,
  selectWorkflowContractsLoading,
} from "../../../store/workflows/contractsSlice";
import { IWorkflowContract } from "../../../types/workflows";
import { useSearch } from "../../../providers/SearchProvider";
import { Helmet } from "react-helmet";
import Button from "../../buttons/Button";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import CopyableKey from "../../general/CopyableKey";
import { networks } from "../wallets/constant";
import { timeAgo } from "../../../utils/date";
import Dropdown from "../../general/Dropdown";
import { IconBtnWrapper } from "../../general/Wrappers";
import ContractDialog from "./ContractDialog";
import { selectWorkflowStatus } from "../../../store/workflows/workflowsSlice";

const WorkflowsRunsTab = () => {
  // const isOpen =  useAppSelector(selectContractShowDeleteModal);
  const workflowStatus = useAppSelector(selectWorkflowStatus);

  const contracts = useAppSelector(selectWorkflowContracts);
  const contractsLoading = useAppSelector(selectWorkflowContractsLoading);
  const [filteredContracts, setFilteredContracts] = useState<
    IWorkflowContract[]
  >([]);

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogProps, setDialogProps] = useState({});

  const dispatch = useAppDispatch();
  const { value: searchValue } = useSearch();

  useEffect(() => {
    dispatch(getWorkflowContractsAsync());
  }, [dispatch]);

  useEffect(() => {
    const lookedUp = contracts.filter((contract) =>
      contract.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredContracts(lookedUp);
  }, [dispatch, searchValue, contracts]);

  const getNetworkItem = useCallback((network: string) => {
    return networks.find((ntw) => ntw.name === network);
  }, []);

  const addNew = useCallback(() => {
    const dialogProp = {
      title: "Add New Contract",
      type: "ADD",
      cancelButtonName: "Cancel",
      confirmButtonName: "Add",
    };
    console.log(dialogProp);
    setDialogProps(dialogProp);
    setShowDialog(true);
  }, []);

  const edit = useCallback((id: string) => {
    const dialogProp = {
      title: "Edit Contract",
      type: "EDIT",
      cancelButtonName: "Cancel",
      confirmButtonName: "Save",
      id,
    };
    setDialogProps(dialogProp);
    setShowDialog(true);
  }, []);

  const onConfirm = useCallback(() => {
    setShowDialog(false);
    dispatch(getWorkflowContractsAsync());
  }, [dispatch]);

  if (!workflowStatus) {
    return <WorkflowNotActiveTab />;
  }

  // const handleClose = () => {
  //   dispatch(handleHideContractDeleteModal());
  // };

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Workflow Contracts </title>
        <meta name="description" content="" />
      </Helmet>
      <div className="flex transition-all duration-500 items-center justify-between gap-3">
        <Text className="whitespace-nowrap font-medium"></Text>
        <div className="flex items-center justify-end gap-1 md:gap-2 ">
          <Button
            type="primary"
            buttonProps={{ onClick: addNew }}
            buttonClassName="group"
          >
            Add New
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>
      <div className="mt-5 w-full">
        <Table loading={contractsLoading} className="table-auto">
          <TableHead>
            <TableRow>
              <TableTH lable="Name" />
              <TableTH lable="Address" />
              <TableTH lable="Network" />
              <TableTH lable="Created" />
              <TableTH lable="" />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredContracts.map((contract, index) => (
              <TableRow
                key={index}
                onClick={() => edit(contract._id)}
                className="cursor-pointer"
              >
                <TableTD>
                  <div className="flex items-center">
                    {contract.address && (
                      <div className="w-[35px] h-[35px] rounded-full bg-white dark:bg-dark-1 mr-3">
                        <Jazzicon
                          diameter={35}
                          seed={jsNumberForAddress(contract.address)}
                        />
                      </div>
                    )}
                    <HighlighterText
                      searchValue={searchValue}
                      textToHighlight={contract.name}
                    />
                  </div>
                </TableTD>
                <TableTD>
                  <CopyableKey
                    className="text-xs md:text-sm"
                    text={contract.address}
                    label={`${contract.address.slice(
                      0,
                      8
                    )}...${contract.address.slice(-4)}`}
                  />
                </TableTD>
                <TableTD>
                  <Text
                    className="text-xs md:text-sm !text-slate-50 px-1 py-0.5 rounded-md"
                    style={{
                      backgroundColor:
                        getNetworkItem(contract.network)?.color || "#666666",
                    }}
                  >
                    {getNetworkItem(contract.network)?.label}
                  </Text>
                </TableTD>
                <TableTD>
                  <Text className="text-xs md:text-sm whitespace-nowrap">
                    {timeAgo(contract.createdDate)}
                  </Text>
                </TableTD>
                <TableTD>
                  <div className="h-full w-full inline-flex items-center justify-center gap-1">
                    <div className="w-8 flex justify-center items-center">
                      <Dropdown
                        positionClassName="!w-40"
                        menu={[
                          {
                            key: "1",
                            label: (
                              <div className="flex items-center gap-1">
                                <EditIcon className="w-4" />
                                Edit
                              </div>
                            ),
                            onClick: () => edit(contract._id),
                          },
                          {
                            type: "divider",
                          },
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: (_, close) => {
                              close();
                              // setDeleteWorkflow(flow);
                            },
                          },
                        ]}
                        type="simple"
                      >
                        <IconBtnWrapper>
                          <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                        </IconBtnWrapper>
                      </Dropdown>
                    </div>
                  </div>
                </TableTD>
              </TableRow>
            ))}
            {contracts.length === 0 && (
              <TableRow withoutHoverStyle={true}>
                <TableTD colSpan={5} className="!border-none">
                  <NotData text="No connections created yet" />
                </TableTD>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <ContractDialog
          show={showDialog}
          dialogProps={dialogProps}
          onCancel={() => setShowDialog(false)}
          onConfirm={onConfirm}
        />
      </div>
    </>
  );
};

export default WorkflowsRunsTab;
