import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { S3TagSchema } from "../../../../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import Modal from "../../../../modals/Modal";
import Button from "../../../../buttons/Button";
import { selectBucketDetails } from "../../../../../store/s3/buckets/bucketSlice";
import Input from "../../../../inputs/Input";
import {
  getObjectVersionsAsync,
  handleHideObjectTagsModal,
  selectObjectTagsLoading,
  selectObjectVersions,
  selectObjectVersionsLoading,
  selectShowObjectTagsModal,
  setObjectTagsAsync,
} from "../../../../../store/s3/buckets/objectSlice";
import { getMainVersionOfObject } from "../../../../../utils/bucket";
import Text, { ActionText } from "../../../../general/Text";
import { ReactComponent as CloseIcon } from "./../../../../../assets/icons/close.svg";
import { useState } from "react";
import QuestionModal from "../../../../modals/QuestionModal";

const S3ObjectTagsModal = () => {
  const isOpen = useAppSelector(selectShowObjectTagsModal);
  const tagsLoading = useAppSelector(selectObjectTagsLoading);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const versions = useAppSelector(selectObjectVersions);
  const versionsLoading = useAppSelector(selectObjectVersionsLoading);
  const mainVersion = getMainVersionOfObject(versions);

  const dispatch = useAppDispatch();

  const [deleteTag, setDeleteTag] = useState<{
    [key: string]: string;
  } | null>(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(S3TagSchema),
  });

  const handleClearComponent = () => {
    reset();
  };

  const onSubmit = (data: any) => {
    // data:
    //key -> string
    //value -> string
    if (bucketDetails && mainVersion && mainVersion.version_id) {
      //tags
      const tags = "tags" in mainVersion ? mainVersion.tags : {};

      dispatch(
        setObjectTagsAsync({
          bucketName: bucketDetails.name,
          prefix: mainVersion.name,
          versionId: mainVersion.version_id,
          data: { tags: { ...tags, [data.key]: data.value } },
        })
      ).then((action) => {
        if (action.type === "object/tags/set/fulfilled") {
          handleClearComponent();
          dispatch(handleHideObjectTagsModal());
          dispatch(
            getObjectVersionsAsync({
              bucketName: bucketDetails.name,
              prefix: mainVersion.name,
            })
          );
        }
      });
    }
  };

  const handleDelete = () => {
    if (deleteTag && bucketDetails && mainVersion && mainVersion.version_id) {
      let allTags: { [key: string]: string } = {};
      if ("tags" in mainVersion && mainVersion.tags) {
        allTags = mainVersion.tags;
      }
      const updatedTags = Object.fromEntries(
        Object.entries(allTags).filter(
          ([key]) => key !== Object.keys(deleteTag)[0]
        )
      );
      dispatch(
        setObjectTagsAsync({
          bucketName: bucketDetails.name,
          prefix: mainVersion.name,
          versionId: mainVersion.version_id,
          data: { tags: updatedTags },
        })
      ).then((action) => {
        if (action.type === "object/tags/set/fulfilled") {
          setDeleteTag(null);
          dispatch(
            getObjectVersionsAsync({
              bucketName: bucketDetails.name,
              prefix: mainVersion.name,
            })
          );
        }
      });
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleHideObjectTagsModal());
        handleClearComponent();
      }}
      contentClassName="max-w-lg"
      containerClassName="!items-start"
      title={<Text type="title">Edit Tags</Text>}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex gap-1">
          <Text type="text">Tags for:</Text>
          <Text type="title">{mainVersion?.name}</Text>
        </div>
        <div className="mt-3 flex gap-1">
          <Text type="text">current tags:</Text>
          {mainVersion &&
            mainVersion.tags &&
            Object.entries(mainVersion.tags).map(([key, value], i) => (
              <ActionText
                key={i}
                type="simple"
                text={`${key} : ${value}`}
                icon={
                  <CloseIcon
                    onClick={() => setDeleteTag({ [key]: value })}
                    className="w-3 hover:scale-110 hover:text-slate-900 dark:hover:text-slate-50 hover:cursor-pointer"
                  />
                }
                loading={versionsLoading}
              />
            ))}
        </div>

        <div className="mt-5 w-full border-b dark:border-dark-2" />
        <div className="mt-2 flex flex-col w-full gap-3">
          <Text type="title">Add New Tag</Text>
          <Input
            label="New Tag Key"
            inputProps={{ ...register("key") }}
            error={errors.key?.message}
          />
          <Input
            label="New Tag Label"
            inputProps={{ ...register("value") }}
            error={errors.value?.message}
          />
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button
            buttonProps={{
              onClick: (e) => {
                e.preventDefault();
                dispatch(handleHideObjectTagsModal());
              },
            }}
            type="light"
            buttonClassName="w-[70px]"
          >
            Cancel
          </Button>
          <Button
            loading={tagsLoading || versionsLoading}
            type="primary"
            buttonProps={{
              disabled: tagsLoading || versionsLoading,
              type: "submit",
            }}
            buttonClassName="w-[70px]"
          >
            Set
          </Button>
        </div>
      </form>
      <QuestionModal
        title="Delete Tag"
        description={
          <>
            Are you sure you want to delete the tag{" "}
            <span className="font-semibold">
              {deleteTag
                ? `${Object.keys(deleteTag)[0]}:${Object.values(deleteTag)[0]}`
                : ""}
            </span>
            ?
          </>
        }
        isOpen={deleteTag !== null}
        onClose={() => setDeleteTag(null)}
        onConfirm={handleDelete}
        confirmButtonType="danger"
        loading={tagsLoading}
      />
    </Modal>
  );
};

export default S3ObjectTagsModal;
