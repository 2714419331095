import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/buttons/Button";
import { ReactComponent as CloseIcon } from "./../../../assets/icons/close.svg";

import { InstancesVolumesUrl } from "../../../utils/urls";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InstanceVolumeBackupSchema } from "../../../utils/validations";
import { DBSAddon } from "../../../types/database";
import { LoadingSpin } from "../../../components/general/Loading";
import {
  getDBSCatalogAsync,
  selectDBSCatalog,
  selectDBSCatalogLoading,
} from "../../../store/database/servicesSlice";
import {
  createInstancesVolumeBackupAsync,
  createInstancesVolumeSnapshotAsync,
  getInstancesVolumeAsync,
  selectInstancesSelectedVolume,
  selectInstancesSelectedVolumeLoading,
  selectInstancesVolumesActionLoading,
} from "../../../store/instances/instancesVolumesSlice";
import Text from "../../../components/general/Text";
import { Box } from "@mui/material";
import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import { capitalizeFirstLetter } from "../../../utils";
import { getDBSPriceAmout } from "../../databases/ServiceCreatePage";
import Input from "../../../components/inputs/Input";

const backupTypes = [
  {
    id: "snapshot",
    code: "volume.snapshot.monthly.postpaid",
    title: "Volume Snapshot",
    description: `With snapshot backups, you can quickly revert your system back to a previous state.
The snapshot is stored on the same storage cluster as your volume.`,
  },
  {
    id: "backup",
    code: "snapshot.monthly.postpaid",
    title: "Volume Backup",
    description: `Limit the impact of unexpected data loss on your application data.
The backup is stored on a different cluster (Object Storage), but in the same location as your volume.`,
  },
];

const InstanceVolumeBackupPage = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const selectedVolume = useAppSelector(selectInstancesSelectedVolume);
  const selectedVolumeLoading = useAppSelector(
    selectInstancesSelectedVolumeLoading
  );
  const catalog = useAppSelector(selectDBSCatalog);
  const catalogLoading = useAppSelector(selectDBSCatalogLoading);
  const actionLoading = useAppSelector(selectInstancesVolumesActionLoading);

  // states
  const [addons, setAddons] = useState<Array<DBSAddon>>([]);

  const dispatch = useAppDispatch();

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(InstanceVolumeBackupSchema),
    defaultValues: {
      selectedType: "",
      name: "",
    },
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
    register,
    watch,
  } = methods;

  // watch form values
  const selectedType = watch("selectedType");

  //get selected volume
  useEffect(() => {
    if (selectedVolume === null && id !== undefined) {
      dispatch(getInstancesVolumeAsync({ id }));
    }
  }, [dispatch, id, selectedVolume]);

  //get first data
  useEffect(() => {
    dispatch(getDBSCatalogAsync());
  }, [dispatch, reset]);

  useEffect(() => {
    if (catalog) {
      setAddons(
        catalog.addons.filter(
          (a) =>
            a.planCode.startsWith("volume.") ||
            a.planCode.startsWith("snapshot.")
        )
      );
    }
  }, [catalog]);

  const handleSubmitForm = (data: any) => {
    if (selectedVolume) {
      if (selectedType === "snapshot.monthly.postpaid") {
        dispatch(
          createInstancesVolumeBackupAsync({
            region: selectedVolume.region,
            data: {
              name: data.name,
              volumeId: selectedVolume.id,
            },
          })
        ).then((action) => {
          if (action.type === "instances/volumes/backup/create/fulfilled") {
            navigate(InstancesVolumesUrl);
          }
        });
      } else {
        dispatch(
          createInstancesVolumeSnapshotAsync({
            volumeId: selectedVolume.id,
            data: {
              description: "",
              name: data.name,
            },
          })
        ).then((action) => {
          if (action.type === "instances/volumes/snapshot/create/fulfilled") {
            navigate(InstancesVolumesUrl);
          }
        });
      }
    }
  };

  const disabledForMakingBackup = useMemo(() => {
    if (!selectedVolume) return true;
    // console.log(selectedType);
    if (
      selectedVolume.attachedTo.length > 0 &&
      selectedType === "snapshot.monthly.postpaid"
    ) {
      return true;
    } else {
      return false;
    }
  }, [selectedType, selectedVolume]);

  return (
    <>
      <div className="flex items-center justify-between h-16 px-6 sticky top-0 z-20 bg-white dark:bg-dark-1 border-b dark:border-dark-2">
        <div className="items-center justify-between flex flex-1 transition duration-150">
          <div className="font-medium mr-2 text-standard text-md dark:text-slate-100 flex items-center gap-1">
            Create a backup from {selectedVolume?.name}
          </div>
        </div>
        <div className="">
          <Button
            type="light"
            size="small"
            buttonProps={{
              onClick: () => navigate(InstancesVolumesUrl),
            }}
            buttonClassName="group"
          >
            <CloseIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>

      {(catalogLoading || selectedVolumeLoading) && (
        <div className="h-full w-full flex items-center justify-center min-h-[calc(100vh-128px)]">
          <LoadingSpin borderSize={2} />
        </div>
      )}
      {!catalogLoading && !selectedVolumeLoading && (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="mt-10 w-full px-6 flex flex-col lg:flex-row pb-24 gap-8 relative">
            <div className="w-full flex flex-col">
              <Controller
                name="selectedType"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <Box sx={{ width: "100%", typography: "body1" }}>
                      <RadioGroup value={value || null} onChange={onChange}>
                        <div className="flex flex-col w-full mt-3">
                          <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-5">
                            {backupTypes.map((type, i) => {
                              const addon = addons.find(
                                (a) => a.planCode === type.code
                              );

                              const exVAT = addon
                                ? getDBSPriceAmout(addon.pricings[0].price, 3)
                                : null;
                              return (
                                <RadioGroup.Option
                                  key={type.title + i}
                                  value={type.code}
                                >
                                  {({ checked, disabled }) => {
                                    return (
                                      <div
                                        className={classNames(
                                          "col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300 cursor-pointer h-full",
                                          {
                                            "border-primary-400 dark:border-primary-400":
                                              checked,
                                            "!cursor-not-allowed": disabled,
                                          }
                                        )}
                                      >
                                        <div className="flex items-center justify-between mb-2">
                                          <Text className="text-sm">
                                            {capitalizeFirstLetter(type.title)}
                                          </Text>
                                        </div>
                                        <div className="flex items-center justify-between mb-2">
                                          <Text className="text-xs">
                                            {type.description}
                                          </Text>
                                        </div>
                                        <div className="flex flex-col border-t mt-2 pt-2 gap-0.5">
                                          {exVAT && (
                                            <div
                                              key={i}
                                              className="flex items-center "
                                            >
                                              <Text className="text-xs font-medium">
                                                Price: €{exVAT} ex. VAT/GB/month
                                              </Text>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  }}
                                </RadioGroup.Option>
                              );
                            })}
                          </div>
                        </div>
                      </RadioGroup>
                    </Box>
                  );
                }}
              />

              {/* select name */}
              <Box sx={{ width: "100%", typography: "body1" }}>
                <div className="flex flex-col gap-1 mb-9">
                  <Input
                    label="Backup name"
                    inputProps={{ ...register("name") }}
                    error={errors.name?.message}
                  />
                </div>
              </Box>

              <div className="flex items-center gap-2">
                <Button
                  type="primary"
                  loading={actionLoading}
                  buttonProps={{
                    type: "submit",
                    disabled: !isValid || disabledForMakingBackup,
                  }}
                >
                  Create backup
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default InstanceVolumeBackupPage;
