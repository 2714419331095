import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isAxiosError } from "axios";
import toast from "react-hot-toast";

import { Rpc, RpcApiData, RpcsState } from "../../types/rpc";
import { IThunkRejectValue, RootState } from "../../types";
import { ToastClasses } from "../../components/modals/alerts";
import { createRpcApi, deleteRpcApi, getAllRpcApi } from "../../apis/rpcAPI";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";

const initialState: RpcsState = {
  allRpc: [],
  loading: false,
  actionLoading: false,
  selectedRpc: null,
};

export const getAllRpcAsync = createAsyncThunk<
  { rpcList: Rpc[] },
  undefined,
  IThunkRejectValue
>("rpcs", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getAllRpcApi();
    const rpcList = response.data.Result;

    return fulfillWithValue({ rpcList });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createRpcAsync = createAsyncThunk<
  boolean,
  RpcApiData,
  IThunkRejectValue
>("rpc/create", async (data, { rejectWithValue }) => {
  try {
    await createRpcApi(data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const deleteRpcAsync = createAsyncThunk<
  boolean,
  { id: number },
  IThunkRejectValue
>("rpc/delete", async ({ id }, { rejectWithValue }) => {
  try {
    await deleteRpcApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const rpcsSlice = createSlice({
  name: "rpcs",
  initialState,
  reducers: {
    // openCreateModal: (state) => {
    //   state.inviteModalIsOpen = true;
    // },
    // closeCreateModal: (state) => {
    //   state.inviteModalIsOpen = false;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRpcAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllRpcAsync.fulfilled, (state, action) => {
        const { rpcList } = action.payload;
        state.allRpc = rpcList;
        state.loading = false;
      })
      .addCase(getAllRpcAsync.rejected, (state, { payload }) => {
        state.loading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createRpcAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(createRpcAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(createRpcAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteRpcAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(deleteRpcAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(deleteRpcAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectAllRpc = (state: RootState) => state.rpcs.allRpc;
export const selectLoading = (state: RootState) => state.rpcs.loading;

export const selectActionLoading = (state: RootState) =>
  state.rpcs.actionLoading;

export const selectselectedRpc = (state: RootState) => state.rpcs.selectedRpc;

// export const { } =
//   rpcsSlice.actions;
export default rpcsSlice.reducer;
