import { jwtEnvAxios } from ".";
import { WebAppEnvironment, WebAppSecretFile } from "../types/web-app";

//apps-urls
const GET_WEB_APPS_URL = `/web-apps`;
// const CREATE_WEB3_AUTH_API_URL = `/web3-auth`;
// const UPDATE_WEB3_AUTH_API_URL = (id: number) => `/web3-auth/${id}`;
const DELETE_WEB_APP_API_URL = (id: number) => `/web-apps/${id}`;
const VALIDATE_WEB_APP_IMAGE_URL = (url: string, credentialId?: number) =>
  `/web-apps/validate?url=${url}${
    credentialId ? `&credentialId=${credentialId}` : ""
  }`;

//app-urls
const GET_WEB_APP_API_URL = (id: string) => `/web-apps/${id}`;
const GET_WEB_APP_EVENTS_API_URL = (id: string) => `/web-apps/${id}/events`;
const GET_WEB_APP_LOGS_API_URL = (id: string) => `/web-apps/${id}/logs`;
const GET_WEB_APP_DISK_API_URL = (id: string) => `/web-apps/${id}/disk`;
const SAVE_WEB_APP_DISK_API_URL = (id: string) => `/web-apps/${id}/disk`;
const DELETE_WEB_APP_DISK_API_URL = (id: string) => `/web-apps/${id}/disk`;
const GET_WEB_APP_ENVS_API_URL = (id: string) => `/web-apps/${id}/env`;
const SAVE_WEB_APP_ENVS_API_URL = (id: string) => `/web-apps/${id}/env`;
const GET_WEB_APP_SECRET_FILES_API_URL = (id: string) =>
  `/web-apps/${id}/secret-files`;
const SAVE_WEB_APP_SECRET_FILES_API_URL = (id: string) =>
  `/web-apps/${id}/secret-files`;
const GET_WEB_APP_PREVIEWS_API_URL = (id: string) => `/web-apps/${id}/previews`;
const GET_WEB_APP_SHARED_API_URL = (id: string) => `/web-apps/${id}/shared`;
const CREATE_WEB_APP_SHARING_API_URL = (id: string) =>
  `/web-apps/${id}/sharing`;
const DELETE_WEB_APP_SHARING_API_URL = (id: string, sharedId: number) =>
  `/web-apps/${id}/sharing/${sharedId}`;
const GET_WEB_APP_MANUAL_SCALING_API_URL = (id: string) =>
  `/web-apps/${id}/scaling`;
const UPDATE_WEB_APP_MANUAL_SCALING_API_URL = (id: string) =>
  `/web-apps/${id}/scaling`;
const UPDATE_WEB_APP_NAME_API_URL = (id: string) =>
  `/web-apps/${id}/update-name`;
const UPDATE_WEB_APP_DOCKER_COMMAND_API_URL = (id: string) =>
  `/web-apps/${id}/update-docker-command`;
const UPDATE_WEB_APP_PREDEPLOY_COMMAND_API_URL = (id: string) =>
  `/web-apps/${id}/update-pre-deploy-command`;
const UPDATE_WEB_APP_HEALTH_CHECK_PATH_API_URL = (id: string) =>
  `/web-apps/${id}/update-health-check-path`;
const UPDATE_WEB_APP_PLAN_API_URL = (id: string) => `/web-apps/${id}/plan`;

//web-apps
export function getWebAppsApi() {
  return jwtEnvAxios().get(GET_WEB_APPS_URL);
}

// export function createWeb3AuthEndpointApi(data: Web3AuthEndpointApiData) {
//   return jwtEnvAxios().post(CREATE_WEB3_AUTH_API_URL, data);
// }

// export function updateWeb3AuthEndpointApi(
//   id: number,
//   data: Web3AuthEndpointApiData
// ) {
//   return jwtEnvAxios().put(UPDATE_WEB3_AUTH_API_URL(id), data);
// }

export function deleteWebAppApi(id: number) {
  return jwtEnvAxios().delete(DELETE_WEB_APP_API_URL(id));
}

export function getValidateWebAppApi(url: string, credentialId?: number) {
  return jwtEnvAxios().get(VALIDATE_WEB_APP_IMAGE_URL(url, credentialId));
}

//web-app
export function getWebAppApi(id: string) {
  return jwtEnvAxios().get(GET_WEB_APP_API_URL(id));
}

export function getWebAppEventsApi(id: string) {
  return jwtEnvAxios().get(GET_WEB_APP_EVENTS_API_URL(id));
}

export function getWebAppLogsApi(id: string) {
  return jwtEnvAxios().get(GET_WEB_APP_LOGS_API_URL(id));
}

export function getWebAppDiskApi(id: string) {
  return jwtEnvAxios().get(GET_WEB_APP_DISK_API_URL(id));
}

export function saveWebAppDiskApi(id: string, data: any) {
  return jwtEnvAxios().post(SAVE_WEB_APP_DISK_API_URL(id), data);
}

export function deleteWebAppDiskApi(id: string) {
  return jwtEnvAxios().delete(DELETE_WEB_APP_DISK_API_URL(id));
}

export function getWebAppEnvironmentsApi(id: string) {
  return jwtEnvAxios().get(GET_WEB_APP_ENVS_API_URL(id));
}

export function saveWebAppEnvironmentsApi(
  id: string,
  envs: WebAppEnvironment[]
) {
  return jwtEnvAxios().post(SAVE_WEB_APP_ENVS_API_URL(id), { envs });
}

export function getWebAppSecretFilesApi(id: string) {
  return jwtEnvAxios().get(GET_WEB_APP_SECRET_FILES_API_URL(id));
}

export function saveWebAppSecretFilesApi(
  id: string,
  secretFiles: WebAppSecretFile[]
) {
  return jwtEnvAxios().post(SAVE_WEB_APP_SECRET_FILES_API_URL(id), {
    secretFiles,
  });
}

export function getWebAppPreviewsApi(id: string) {
  return jwtEnvAxios().get(GET_WEB_APP_PREVIEWS_API_URL(id));
}

export function getWebAppSharedApi(id: string) {
  return jwtEnvAxios().get(GET_WEB_APP_SHARED_API_URL(id));
}

export function createWebAppSharingApi(id: string, data: { Email: string }) {
  return jwtEnvAxios().post(CREATE_WEB_APP_SHARING_API_URL(id), {
    data,
  });
}

export function deleteWebAppSharingApi(id: string, sharedId: number) {
  return jwtEnvAxios().delete(DELETE_WEB_APP_SHARING_API_URL(id, sharedId));
}

export function getWebAppManualScalingApi(id: string) {
  return jwtEnvAxios().get(GET_WEB_APP_MANUAL_SCALING_API_URL(id));
}

export function updateWebAppManualScalingApi(
  id: string,
  data: { value: number }
) {
  return jwtEnvAxios().post(UPDATE_WEB_APP_MANUAL_SCALING_API_URL(id), data);
}

export function updateWebAppNameApi(id: string, data: { Name: string }) {
  return jwtEnvAxios().post(UPDATE_WEB_APP_NAME_API_URL(id), data);
}

export function updateWebAppDockerCommandApi(
  id: string,
  data: { DockerCommand: string }
) {
  return jwtEnvAxios().post(UPDATE_WEB_APP_DOCKER_COMMAND_API_URL(id), data);
}

export function updateWebAppPreDeployCommandApi(
  id: string,
  data: { PreDeployCommand: string }
) {
  return jwtEnvAxios().post(UPDATE_WEB_APP_PREDEPLOY_COMMAND_API_URL(id), data);
}

export function updateWebAppHealthCheckPathApi(
  id: string,
  data: { Path: string }
) {
  return jwtEnvAxios().post(UPDATE_WEB_APP_HEALTH_CHECK_PATH_API_URL(id), data);
}

export function updateWebAppPlanApi(id: string, data: { InstanceId: number }) {
  return jwtEnvAxios().post(UPDATE_WEB_APP_PLAN_API_URL(id), data);
}
