import { ReactComponent as TooltipIcon } from "./../../assets/icons/question-mark-circle.svg";
import { ReactComponent as ExclamationIcon } from "./../../assets/icons/exclamation-circle.svg";
import { PlacesType, Tooltip as ReactTooltip } from "react-tooltip";
import { uuid } from "../../utils/uuid";
import { PropsWithChildren, useMemo } from "react";
import classNames from "classnames";
import parser from "html-react-parser";

export type ToolTipTypes = "default" | "error";
export type TooltipPlaces = PlacesType;

type TooltipProps = {
  content: string | React.ReactNode;
  className?: string;
  clickable?: boolean;
  place?: TooltipPlaces;
};
const Tooltip = (props: PropsWithChildren<TooltipProps>) => {
  const randomKey = uuid(10);

  const _content = useMemo(() => {
    const parsedContent =
      typeof props.content === "string" ? parser(props.content) : props.content;

    if (
      parsedContent === null ||
      parsedContent === undefined ||
      (Array.isArray(parsedContent) && parsedContent.length === 0)
    ) {
      return null;
    }
    return parsedContent;
  }, [props.content]);

  return (
    <>
      <div data-tooltip-id={randomKey} className="inline">
        {props.children}
      </div>
      <ReactTooltip
        id={randomKey}
        clickable={props.clickable || false}
        className={classNames(
          "!bg-primary-500 text-white dark:!bg-primary-400 max-w-[250px] !px-2 !py-1 !whitespace-normal z-40",
          props.className
        )}
        place={props.place}
      >
        {_content}
      </ReactTooltip>
    </>
  );
};

const InfoTooltip = (props: PropsWithChildren<TooltipProps>) => {
  return (
    <Tooltip {...props}>
      <TooltipIcon className="w-4 text-slate-500 dark:text-slate-300 dark:hover:text-slate-100" />
    </Tooltip>
  );
};

const ErrorTooltip = (props: PropsWithChildren<TooltipProps>) => {
  const { className, ..._props } = props;
  return (
    <Tooltip
      {..._props}
      className={classNames("dark:!bg-red-500 !bg-red-500", className)}
    >
      <ExclamationIcon className="w-6 text-red-500 ml-1 mb-3" />
    </Tooltip>
  );
};

export { InfoTooltip, ErrorTooltip };
export default Tooltip;
