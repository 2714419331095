import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import React, { Fragment, PropsWithChildren, useEffect } from "react";
import { ReactComponent as CloseIcon } from "./../../assets/icons/close.svg";

const Modal = ({
  title,
  isOpen,
  onClose,
  children,
  contentClassName,
  containerClassName,
  closable = true,
  rendered,
}: PropsWithChildren<{
  title?: string | React.ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
  contentClassName?: string;
  containerClassName?: string;
  closable?: boolean;
  rendered?: () => void;
}>) => {
  useEffect(() => {
    if (rendered && isOpen) {
      setTimeout(rendered, 300);
    }
  }, [isOpen, rendered]);
  return (
    <Transition appear show={isOpen || false} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={onClose && closable ? onClose : () => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/10 backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div
            className={classNames(
              "flex min-h-full items-center justify-center p-4 text-center",
              {
                [containerClassName || ""]: containerClassName,
              }
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                as="div"
                className={classNames(
                  "w-full transform rounded-2xl bg-white border-0 dark:bg-dark-3 dark:border-2 dark:border-dark-2 p-3 md:p-6 text-left align-middle shadow-xl transition-all",
                  {
                    [contentClassName || ""]: contentClassName,
                  }
                )}
              >
                <Dialog.Title
                  as="h3"
                  className="text-md font-medium leading-6 text-gray-900 dark:text-slate-200 flex items-center justify-between"
                >
                  {title}
                  {closable && (
                    <CloseIcon
                      onClick={onClose ? onClose : () => {}}
                      className="w-5 h-5 cursor-pointer hover:scale-110"
                    />
                  )}
                </Dialog.Title>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
