import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  handleShowObjectPreview,
  handleShowObjectShareModal,
  selectObjectDeleteLoading,
  getObjectDownloadMultipleAsync,
  deleteMultipleObjectAsync,
} from "../../../store/s3/buckets/objectSlice";
import { handleShowAnonymousModal } from "../../../store/s3/buckets/setting/anonymousSlice";
import { useState } from "react";
import Text from "../../general/Text";
import { ReactComponent as CloseIcon } from "./../../../assets/icons/close.svg";
import { ReactComponent as DownloadIcon } from "./../../../assets/icons/arrow-down-tray.svg";
import { ReactComponent as ShareIcon } from "./../../../assets/icons/share.svg";
import { ReactComponent as EyeIcon } from "./../../../assets/icons/eye.svg";
import { ReactComponent as KeyIcon } from "./../../../assets/icons/key.svg";
import { ReactComponent as DeleteIcon } from "./../../../assets/icons/archive-box.svg";
import {
  getBucketObjectsAsync,
  handleClearCheckedObjects,
  selectBucketDetails,
  selectCheckedObjects,
} from "../../../store/s3/buckets/bucketSlice";
import S3ObjectPreview from "./modals/objects/S3ObjectPreview";
import S3ObjectShareModal from "./modals/objects/S3ObjectShareModal";
import QuestionModal from "../../modals/QuestionModal";
import Switcher from "../../inputs/Switcher";
import { ActionOption } from "./S3ObjectDrawer";
import { objectIsFolder } from "../../../utils/bucket";
import S3ObjectAnonymousAccessModal from "./modals/buckets/S3AnonymousAccessModal";

const S3SelectedObjectsDrawer = () => {
  const checkedObjects = useAppSelector(selectCheckedObjects);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const dispatch = useAppDispatch();

  const [deleteCheckedObjects, setDeleteCheckedObjects] = useState(false);
  const [enableDeleteAllVersions, setEnableDeleteAllVersions] = useState(false);
  const [enableBypass, setEnableBypass] = useState(false);
  const deleteLoading = useAppSelector(selectObjectDeleteLoading);

  const handleCloseDrawer = () => {
    dispatch(handleClearCheckedObjects());
  };

  const handleDeleteVersion = () => {
    if (bucketDetails) {
      dispatch(
        deleteMultipleObjectAsync({
          bucketName: bucketDetails.name,
          objects: checkedObjects,
          all_versions: enableDeleteAllVersions,
          bypass: enableBypass,
        })
      ).then((action) => {
        if (action.type === "object/delete-multiple/fulfilled") {
          dispatch(handleClearCheckedObjects());
          dispatch(getBucketObjectsAsync({ name: bucketDetails.name }));
          setDeleteCheckedObjects(false);
          setEnableDeleteAllVersions(false);
          setEnableBypass(false);
        }
      });
    }
  };
  return (
    <>
      {checkedObjects.length > 0 && (
        <div className="lg:border-l lg:pl-4 mt-4 lg:mt-0 lg:w-1/4 dark:border-dark-2">
          <div className="">
            <div className="flex items-center justify-between">
              <Text type="title">Selected Objects:</Text>
              <CloseIcon
                className="w-5 text-slate-800 dark:text-slate-200 hover:scale-110 cursor-pointer"
                onClick={handleCloseDrawer}
              />
            </div>
            <div className="flex flex-col w-full rounded-lg bg-gray-100/80 dark:bg-dark-1 mt-2">
              <Text className="p-2 border-b dark:border-b-dark-2" type="title">
                Actions:
              </Text>
              <ActionOption
                title="Download"
                icon={<DownloadIcon className="w-4" />}
                onClick={() =>
                  bucketDetails &&
                  dispatch(
                    getObjectDownloadMultipleAsync({
                      bucketName: bucketDetails.name,
                      objects: checkedObjects,
                    })
                  )
                }
                disabled={false}
              />
              <ActionOption
                title="Share"
                icon={<ShareIcon className="w-4" />}
                onClick={() =>
                  dispatch(
                    handleShowObjectShareModal({ object: checkedObjects[0] })
                  )
                }
                disabled={
                  checkedObjects.length > 1 ||
                  objectIsFolder(checkedObjects[0].name)
                }
              />
              <ActionOption
                title="Preview"
                icon={<EyeIcon className="w-4" />}
                onClick={() =>
                  dispatch(
                    handleShowObjectPreview({ object: checkedObjects[0] })
                  )
                }
                disabled={checkedObjects.length > 1}
              />
              <ActionOption
                title="Anonymous Access"
                icon={<KeyIcon className="w-4" />}
                onClick={() => {
                  // dispatch(
                  //   handleShowObjectAnonymousAccessModal({
                  //     object: checkedObjects[0],
                  //   })
                  // );
                  dispatch(handleShowAnonymousModal({}));
                }}
                disabled={
                  checkedObjects.length > 1
                    ? true
                    : !(
                        checkedObjects.length === 1 &&
                        objectIsFolder(checkedObjects[0].name)
                      )
                }
              />
              <ActionOption
                title="Delete"
                icon={<DeleteIcon className="w-4" />}
                onClick={() => setDeleteCheckedObjects(true)}
                disabled={false}
              />
            </div>
          </div>
          <S3ObjectPreview />
          <S3ObjectShareModal />
          <S3ObjectAnonymousAccessModal />
          <QuestionModal
            isOpen={deleteCheckedObjects}
            onClose={() => {
              setDeleteCheckedObjects(false);
              setEnableDeleteAllVersions(false);
            }}
            title="Delete Objects"
            confirmButtonType="danger"
            onConfirm={handleDeleteVersion}
            loading={deleteLoading}
          >
            <div className="flex flex-col gap-2">
              <div>
                <Text>
                  Are you sure you want to delete the selected{" "}
                  {checkedObjects.length} objects?:
                </Text>
              </div>
              <div className="flex items-center justify-between">
                <Text>Delete All Versions</Text>
                <Switcher
                  on={enableDeleteAllVersions}
                  onToggle={(value) => {
                    if (!value) {
                      setEnableBypass(false);
                    }
                    setEnableDeleteAllVersions(value);
                  }}
                />
              </div>
              {enableDeleteAllVersions && (
                <div className="flex items-center justify-between">
                  <Text>Bypass Governance Mode</Text>
                  <Switcher on={enableBypass} onToggle={setEnableBypass} />
                </div>
              )}
            </div>
          </QuestionModal>
        </div>
      )}
    </>
  );
};

export default S3SelectedObjectsDrawer;
