import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import NotData from "../../layouts/NotData";
import CopyableKey from "../../general/CopyableKey";
import { useSearch } from "../../../providers/SearchProvider";
import Highlighter from "react-highlight-words";
import {
  getWeb3AuthEndpointUsersAsync,
  handleUserDataInfoModal,
  selectWeb3AuthEndpoint,
  selectWeb3AuthEndpointLoading,
  selectWeb3AuthEndpointUsers,
  selectWeb3AuthEndpointUsersLoading,
} from "../../../store/web3-auth/web3AuthEndpointSlice";
import { Web3AuthEndpointUser } from "../../../types/web3-auth";
import { timeAgo } from "../../../utils/date";
import Text, { HighlighterText } from "../../general/Text";
import { ReactComponent as EyeIcon } from "./../../../assets/icons/eye.svg";
import Button from "../../buttons/Button";
import Web3UserDataInfoModal from "./Web3UserDataInfoModal";
import BlankAvatarImg from "./../../../assets/images/blank-avatar.png";

const Web3AuthEndpointUsersTab = () => {
  const endpoint = useAppSelector(selectWeb3AuthEndpoint);
  const endpointLoading = useAppSelector(selectWeb3AuthEndpointLoading);
  const users = useAppSelector(selectWeb3AuthEndpointUsers);
  const usersLoading = useAppSelector(selectWeb3AuthEndpointUsersLoading);
  const [filteredUsers, setFilteredUsers] = useState<Web3AuthEndpointUser[]>(
    []
  );

  const dispatch = useAppDispatch();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (endpoint) {
      dispatch(getWeb3AuthEndpointUsersAsync(endpoint.Id.toString()));
    }
  }, [dispatch, endpoint]);

  useEffect(() => {
    const lookedUpFiles = users?.filter((user) =>
      user.Id?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredUsers(lookedUpFiles);
  }, [dispatch, searchValue, users]);

  return (
    <>
      <Table loading={usersLoading || endpointLoading} className="table-auto">
        <TableHead>
          <TableRow>
            <TableTH lable="Profile ID" />
            <TableTH lable="Address" />
            <TableTH lable="Chain" />
            <TableTH lable="Last Loging" />
            <TableTH lable="User data" />
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredUsers.map((user, index) => (
            <TableRow key={index}>
              <TableTD>
                <div className="flex items-center gap-2">
                  <img
                    className="object-cover w-10 h-10 rounded-full"
                    src={user.Avatar || BlankAvatarImg}
                    alt="Avatar"
                  />
                  <HighlighterText
                    searchValue={searchValue}
                    textToHighlight={user.Id}
                  />
                </div>
              </TableTD>
              <TableTD>
                <CopyableKey text={user.WalletAddress} />
              </TableTD>
              <TableTD>
                <Text className="text-xs md:text-sm">{user.Chain}</Text>
              </TableTD>
              <TableTD>
                <Text className="text-xs md:text-sm">
                  {timeAgo(user.LastLogin)}
                </Text>
              </TableTD>
              <TableTD>
                <Button
                  type="icon"
                  buttonProps={{
                    onClick: () =>
                      dispatch(handleUserDataInfoModal({ status: true, user })),
                  }}
                >
                  <EyeIcon className="w-5 h-5" />
                </Button>
              </TableTD>
            </TableRow>
          ))}
          {filteredUsers.length === 0 && (
            <TableRow withoutHoverStyle={true}>
              <TableTD colSpan={4} className="!border-0">
                <NotData text="No users" />
              </TableTD>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Web3UserDataInfoModal />
    </>
  );
};

export default Web3AuthEndpointUsersTab;
