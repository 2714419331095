import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import type { PayloadAction } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { ToastClasses } from "../../components/modals/alerts";
import {
  CreateMarketApiApiKeyData,
  MarketApiApiKey,
  MarketApiApiKeyStateType,
} from "../../types/market-api";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import {
  getMarketApiApiKeysApi,
  createMarketApiApiKeyApi,
  updateMarketApiApiKeyApi,
  deleteMarketApiApiKeyApi,
} from "../../apis/marketApiAPI";

const initialState: MarketApiApiKeyStateType = {
  loading: false,
  apiKeys: [],
  selectedApiKey: null,
  showEditorModal: false,
  actionLoading: false,
};

export const getMarketApiApiKeysAysnc = createAsyncThunk<
  {
    apiKeys: Array<MarketApiApiKey>;
  },
  undefined,
  { rejectValue: { message: string } }
>("market-api/api-keys", async (_, { fulfillWithValue, rejectWithValue }) => {
  try {
    const response = await getMarketApiApiKeysApi();
    const apiKeys = response.data.Result;
    return fulfillWithValue({ apiKeys });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const deleteMarketApiApiKeyAsync = createAsyncThunk<
  boolean,
  { id: number },
  IThunkRejectValue
>("market-api/api-keys/delete", async ({ id }, { rejectWithValue }) => {
  try {
    await deleteMarketApiApiKeyApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createMarketApiApiKeyAsync = createAsyncThunk<
  boolean,
  CreateMarketApiApiKeyData,
  IThunkRejectValue
>("market-api/api-keys/create", async (formData, { rejectWithValue }) => {
  try {
    await createMarketApiApiKeyApi(formData);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const updateMarketApiApiKeyAsync = createAsyncThunk<
  boolean,
  { id: number; formData: CreateMarketApiApiKeyData },
  IThunkRejectValue
>(
  "market-api/api-keys/update",
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      await updateMarketApiApiKeyApi(id, formData);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

const marketApiApiKeysSlice = createSlice({
  name: "marketApiApiKeys",
  initialState: initialState,
  reducers: {
    clearMarketApiApiKeys(state) {
      state.apiKeys = initialState.apiKeys;
      state.loading = initialState.loading;
    },
    handleShowMarketApiEditorModal: (
      state,
      action: PayloadAction<{ apiKey?: MarketApiApiKey }>
    ) => {
      state.showEditorModal = true;
      state.selectedApiKey = action.payload.apiKey || null;
    },
    handleHideMarketApiEditorModal: (state) => {
      state.showEditorModal = false;
      state.selectedApiKey = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMarketApiApiKeysAysnc.fulfilled, (state, action) => {
        state.apiKeys = action.payload.apiKeys;
        state.loading = false;
      })
      .addCase(getMarketApiApiKeysAysnc.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMarketApiApiKeysAysnc.rejected, (state, { payload }) => {
        state.loading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteMarketApiApiKeyAsync.fulfilled, (state, action) => {
        state.actionLoading = false;
      })
      .addCase(deleteMarketApiApiKeyAsync.pending, (state, action) => {
        state.actionLoading = true;
      })
      .addCase(deleteMarketApiApiKeyAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createMarketApiApiKeyAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(createMarketApiApiKeyAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(createMarketApiApiKeyAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateMarketApiApiKeyAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(updateMarketApiApiKeyAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(updateMarketApiApiKeyAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        toast.error((payload as { message: string }).message, {
          className: ToastClasses,
        });
      });
  },
});

export const selectMarketApiLoading = (state: RootState) =>
  state.marketApiApiKeys.loading;
export const selectMarketApiActionLoading = (state: RootState) =>
  state.marketApiApiKeys.actionLoading;

export const selectMarketApiApiKeys = (state: RootState) =>
  state.marketApiApiKeys.apiKeys;

export const selectSelectedMarketApiApiKey = (state: RootState) =>
  state.marketApiApiKeys.selectedApiKey;

export const selectShowMarketApiEditorModal = (state: RootState) =>
  state.marketApiApiKeys.showEditorModal;

export const {
  clearMarketApiApiKeys,
  handleShowMarketApiEditorModal,
  handleHideMarketApiEditorModal,
} = marketApiApiKeysSlice.actions;

export default marketApiApiKeysSlice.reducer;
