import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import Modal from "../../../../modals/Modal";
import { useEffect } from "react";

import {
  getObjectPreviewAsync,
  getObjectVersionsAsync,
  handleHideObjectPreview,
  s3ImageTypes,
  s3TextTypes,
  selectObjectPreview,
  selectObjectPreviewLoading,
  selectObjectVersionsLoading,
  selectSelectedObject,
  selectSelectedVersionOfObject,
  selectShowObjectPreview,
} from "../../../../../store/s3/buckets/objectSlice";
import { getFileNameAndExt } from "../../../../../utils/bucket";
import { selectBucketDetails } from "../../../../../store/s3/buckets/bucketSlice";
import Loading from "../../../../general/Loading";
import Text from "../../../../general/Text";
import classNames from "classnames";

const S3ObjectPreview = () => {
  const isOpen = useAppSelector(selectShowObjectPreview);
  const previewLoading = useAppSelector(selectObjectPreviewLoading);
  const preview = useAppSelector(selectObjectPreview);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const versionsLoading = useAppSelector(selectObjectVersionsLoading);
  const selectedVersion = useAppSelector(selectSelectedVersionOfObject);
  const selectedObject = useAppSelector(selectSelectedObject);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      isOpen &&
      bucketDetails !== null &&
      selectedVersion === null &&
      selectedObject !== null
    ) {
      dispatch(
        getObjectVersionsAsync({
          bucketName: bucketDetails.name,
          prefix: selectedObject.name,
        })
      );
    }
  }, [bucketDetails, dispatch, isOpen, selectedObject, selectedVersion]);

  useEffect(() => {
    if (
      isOpen &&
      bucketDetails &&
      selectedVersion &&
      selectedVersion.version_id
    ) {
      dispatch(
        getObjectPreviewAsync({
          bucketName: bucketDetails.name,
          prefix: selectedVersion.name,
          verionId: selectedVersion.version_id,
        })
      );
    }
  }, [bucketDetails, dispatch, selectedVersion, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleHideObjectPreview());
      }}
      contentClassName="max-w-xl"
      containerClassName="!items-start"
      title={"Object Preview"}
    >
      <div className="mt-5 flex flex-col w-full gap-5">
        {(previewLoading || versionsLoading) && (
          <Loading style={{ minHeight: 90 }} />
        )}
        {!previewLoading && !versionsLoading && selectedVersion && (
          <>
            {preview && (
              <>
                {s3ImageTypes.includes(
                  getFileNameAndExt(selectedVersion.name)?.fileExt || ""
                ) && (
                  <img
                    className="w-full"
                    src={preview}
                    alt={selectedVersion?.name}
                  />
                )}
                {s3TextTypes.includes(
                  getFileNameAndExt(selectedVersion.name)?.fileExt || ""
                ) && (
                  <div
                    className={classNames(" min-h-[100px] p-2 rounded-md", {
                      "bg-gray-50 dark:bg-dark-1 dark:text-slate-200":
                        getFileNameAndExt(selectedVersion.name)?.fileExt ===
                        "txt",
                    })}
                    dangerouslySetInnerHTML={{ __html: preview }}
                  />
                )}
              </>
            )}
            {!(
              s3ImageTypes.includes(
                getFileNameAndExt(selectedVersion.name)?.fileExt || ""
              ) ||
              s3TextTypes.includes(
                getFileNameAndExt(selectedVersion.name)?.fileExt || ""
              )
            ) && (
              <div className="bg-red-200 dark:bg-red-500/60 dark:text-slate-200 min-h-[100px] p-2 rounded-md flex flex-col items-center">
                <Text>Preview unavailable</Text>
                <Text className="text-sm text-center mt-2">
                  File couldn't be previewed using file extension or mime type.
                  Please try Download instead
                </Text>
              </div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default S3ObjectPreview;
