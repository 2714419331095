import { Menu, Transition } from "@headlessui/react";
import { Fragment, PropsWithChildren } from "react";
import { ReactComponent as ArrowDownIcon } from "./../../assets/icons/arrow-down.svg";
import classNames from "classnames";
import {
  DropdownDivider,
  DropdownElement,
  DropdownItem,
  DropdownProps,
} from "../../types/dropdown";
import { useFloating, shift, flip } from "@floating-ui/react-dom";

const Dropdown = (dropdownProps: PropsWithChildren<DropdownProps>) => {
  const itemGroups = groupArrayByDivider(dropdownProps.menu || []);
  const { refs, floatingStyles } = useFloating({
    placement: "bottom-end",
    middleware: [flip(), shift()],
  });
  return (
    <Menu
      as="div"
      className="text-left w-full h-full inline-block justify-center items-center"
    >
      <div className="flex items-center justify-center">
        <Menu.Button
          onClick={(e) => e.stopPropagation()}
          ref={refs.setReference}
          className={classNames(
            dropdownProps.buttonClassName,
            "inline-flex justify-center w-full rounded-md select-none cursor-pointer transition duration-150 space-x-2 items-center text-sm font-semibold  text-slate-500 hover:text-slate-900 dark:text-slate-100 focus:outline-none focus-visible:ring-1 focus-visible:ring-white/75",
            {
              "px-2 bg-white dark:bg-zinc-700 dark:hover:bg-zinc-600 drop-shadow-md ring-1 ring-gray-200 dark:ring-0":
                dropdownProps.type === "default" ||
                typeof dropdownProps.type === "undefined",
              "": dropdownProps.type === "simple",
            }
          )}
          as="div"
        >
          {dropdownProps.children}
          {dropdownProps.type !== "simple" && (
            <div className="h-6 flex items-center ml-auto">
              <ArrowDownIcon className="h-4 w-4" />
            </div>
          )}
        </Menu.Button>
      </div>
      {itemGroups.length > 0 && (
        <Transition
          as={Fragment}
          enter="transition-opacity duration-75"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            ref={refs.setFloating}
            style={floatingStyles}
            // style={{ clip: "rect(auto, auto, auto, auto)" }}
            className={classNames(
              "absolute z-50 w-56 origin-top-right divide-y divide-gray-100 dark:divide-white/20 rounded-md bg-white dark:bg-dark-1 shadow-lg ring-1 ring-black/5 dark:ring-white/20 focus:outline-none",
              {
                [dropdownProps.positionClassName || ""]:
                  dropdownProps.positionClassName,
                [dropdownProps.itemsClassName || ""]:
                  dropdownProps.itemsClassName,
              }
            )}
          >
            {dropdownProps.title && (
              <div className="w-full px-3 text-xs py-2 text-slate-600 dark:text-slate-300">
                {dropdownProps.title}
              </div>
            )}

            {itemGroups.map((items, groupIdx) => {
              return (
                <div key={groupIdx} className="px-1 py-1 ">
                  {items.map((item, itemIdx) => (
                    <Menu.Item key={itemIdx}>
                      {({ active, close }) => (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (item.onClick && !item.disabled) {
                              item.onClick(item, close);
                            }
                          }}
                          className={classNames(
                            "group flex w-full items-center rounded-md px-2 py-2 text-sm",
                            {
                              "bg-primary-50 dark:bg-dark-2":
                                active && !item.danger,
                              "bg-red-100 dark:bg-red-600/10":
                                active && item.danger,
                              "cursor-not-allowed text-gray-400 dark:text-slate-500":
                                item.disabled,
                              "text-gray-900 dark:text-slate-100":
                                !item.disabled && !item.danger,
                              "text-red-900 dark:text-red-600":
                                !item.disabled && item.danger,
                              "animate-bounce": item.loading,
                            }
                          )}
                        >
                          {item.label}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              );
            })}
          </Menu.Items>
        </Transition>
      )}
    </Menu>
  );
};

function groupArrayByDivider(inputArray: DropdownElement[]): DropdownItem[][] {
  let groupedArrays: DropdownItem[][] = [];
  let currentGroup: DropdownItem[] = [];

  for (const item of inputArray) {
    if (isDivider(item)) {
      if (currentGroup.length > 0) {
        groupedArrays.push(currentGroup);
        currentGroup = [];
      }
    } else {
      currentGroup.push(item);
    }
  }

  if (currentGroup.length > 0) {
    groupedArrays.push(currentGroup);
  }

  return groupedArrays;
}

function isDivider(element: DropdownElement): element is DropdownDivider {
  return (element as DropdownDivider).type === "divider";
}

export default Dropdown;
