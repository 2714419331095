import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  deleteBucketAsync,
  getBucketsAsync,
  handleChangeCheckedBuckets,
  handleClearCheckedBuckets,
  selectActionLoading,
  selectBuckets,
  selectCheckedBuckets,
  selectLoading,
} from "../../../store/s3/buckets/bucketsSlice";
import Button from "../../buttons/Button";
import S3bucketEditorModal from "./modals/buckets/S3bucketEditorModal";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import Dropdown from "../../general/Dropdown";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as SettingIcon } from "./../../../assets/icons/setting.svg";
import { ReactComponent as BrowseIcon } from "./../../../assets/icons/arrow-right-circle.svg";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import { ReactComponent as BucketIcon } from "./../../../assets/icons/bucket.svg";
import NotData, { S3NotActiveTab } from "../../layouts/NotData";
import QuestionModal, { DeleteModal } from "../../modals/QuestionModal";
import { humanizeSize } from "../../../utils/file";
import { useNavigate } from "react-router-dom";
import {
  S3BucketCreateUrl,
  S3BucketSettingUrl,
  S3BucketUrl,
} from "../../../utils/urls";
import { dateConvertor } from "../../../utils/date";
import { useSearch } from "../../../providers/SearchProvider";
import Highlighter from "react-highlight-words";
import { S3Bucket } from "../../../types/s3-bucket";
import S3MultipleBucketEditorModal from "./modals/buckets/S3MultipleBucketEditorModal";
import { handleShowReplicationEditor } from "../../../store/s3/buckets/setting/replicationsSlice";
import { getBucketName } from "../../../utils/bucket";
import Text from "../../general/Text";
import S3MultipleLifecycleEditorModal from "./modals/buckets/S3MultipleLifecycleEditorModal";
import { handleShowLifecycleEditor } from "../../../store/s3/buckets/setting/lifecycleSlice";
import { selectS3Status } from "../../../store/s3/s3PublicSlice";

const S3bucketsTab = () => {
  const s3Status = useAppSelector(selectS3Status);
  const buckets = useAppSelector(selectBuckets);
  const [filteredBuckets, setFilteredBuckets] = useState<S3Bucket[]>([]);

  const loading = useAppSelector(selectLoading);
  const actionLoading = useAppSelector(selectActionLoading);
  const [deleteBucketName, setDeleteBucketName] = useState<string | null>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { value: searchValue } = useSearch();

  const checkedBuckets = useAppSelector(selectCheckedBuckets);

  useEffect(() => {
    // if (buckets.length === 0) {
    dispatch(getBucketsAsync());
    // }
  }, [dispatch]);

  useEffect(() => {
    const lookedUpBuckets = buckets.filter((bucket) =>
      bucket.name?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredBuckets(lookedUpBuckets);
  }, [dispatch, searchValue, buckets]);

  const handleDelete = () => {
    deleteBucketName &&
      dispatch(deleteBucketAsync({ name: deleteBucketName })).then((action) => {
        if (action.type === "buckets/delete/fulfilled") {
          dispatch(getBucketsAsync());
        } else {
        }
        setDeleteBucketName(null);
      });
  };

  function handleBucket(bucket: S3Bucket, e: any) {
    // dispatch(handleClearCheckedBuckets());
    getBucketName(bucket.name);
  }

  const handleChangeChecked = (
    e: React.MouseEvent<HTMLDivElement>,
    bucket: S3Bucket
  ) => {
    e.stopPropagation();
    dispatch(handleChangeCheckedBuckets(bucket));
    handleBucket(bucket, e);
  };

  if (!s3Status) {
    return <S3NotActiveTab />;
  }
  return (
    <>
      <div className="flex transition-all duration-500 items-center justify-between gap-3">
        <Text className="whitespace-nowrap font-medium">Object Storage</Text>
        <div className="flex items-center justify-end gap-1 md:gap-2 ">
          <Button
            size="medium"
            type="light"
            buttonProps={{
              onClick: () => dispatch(getBucketsAsync()),
            }}
            buttonClassName="group"
            tooltip="Refresh"
          >
            <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
          <Button
            type="light"
            size="medium"
            buttonProps={{
              onClick: () => dispatch(handleShowLifecycleEditor({})),
              disabled: checkedBuckets.length < 1,
            }}
            buttonClassName="group"
          >
            Lifecycle
            {/* <BucketIcon className="w-3" /> */}
          </Button>

          <Button
            type="light"
            size="medium"
            buttonProps={{
              onClick: () => dispatch(handleShowReplicationEditor({})),
              disabled: checkedBuckets.length < 1,
            }}
            buttonClassName="group"
          >
            {/* Create Multiple Bucket */}
            <BucketIcon className="w-3" />
          </Button>

          <Button
            type="primary"
            size="medium"
            buttonProps={{
              onClick: () => navigate(S3BucketCreateUrl),
            }}
            buttonClassName="group"
          >
            Create Bucket
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>
      <div className="mt-5 w-full">
        <Table loading={loading}>
          <TableHead>
            <TableRow>
              <TableTH lable="Name" />
              <TableTH lable="Created" />
              <TableTH lable="Size" />
              <TableTH lable="Objects" />
              <TableTH lable="" />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredBuckets.map((bucket, i) => (
              <TableRow
                key={i}
                onClick={() => {
                  navigate(S3BucketUrl(bucket.name));
                }}
              >
                <TableTD className="cursor-pointer">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    checked={checkedBuckets.some((o) => o.name === bucket.name)}
                    className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 rounded ring-0 dark:bg-gray-700 dark:border-gray-600"
                    onClick={(e) => handleChangeChecked(e, bucket)}
                  />
                  <div className="flex items-center flex-row justify-between space-x-4 ml-2">
                    <Highlighter
                      highlightClassName="bg-primary-100 dark:bg-primary-300 rounded-sm"
                      className="whitespace-nowrap max-w-[110px] md:max-w-none text-ellipsis overflow-hidden text-sm font-medium"
                      searchWords={[searchValue]}
                      autoEscape={true}
                      textToHighlight={bucket.name}
                    />
                  </div>
                </TableTD>
                <TableTD className="cursor-pointer">
                  <Text className="text-xs md:text-sm whitespace-nowrap">
                    {dateConvertor(bucket.creation_date)}
                  </Text>
                </TableTD>
                <TableTD className="cursor-pointer">
                  <Text className="text-xs md:text-sm whitespace-nowrap">
                    {bucket.size !== 0
                      ? humanizeSize(Number(bucket.size), {
                          fractionDigits: 2,
                        }).join("B")
                      : ""}
                  </Text>
                </TableTD>
                <TableTD className="cursor-pointer">
                  <Text className="text-xs md:text-sm">{bucket.objects}</Text>
                </TableTD>
                <TableTD className="w-32 gap-1">
                  <div className="h-full w-full inline-flex items-center justify-center gap-1">
                    <div className="w-8 flex justify-center items-center">
                      <Dropdown
                        positionClassName="!w-40"
                        menu={[
                          {
                            key: "1",
                            label: (
                              <div className="flex items-center gap-1">
                                <BrowseIcon className="w-4" />
                                Browse
                              </div>
                            ),
                            onClick: () => navigate(S3BucketUrl(bucket.name)),
                          },
                          {
                            key: "2",
                            label: (
                              <div className="flex items-center gap-1">
                                <SettingIcon className="w-4" />
                                Setting
                              </div>
                            ),
                            onClick: () =>
                              navigate(S3BucketSettingUrl(bucket.name)),
                          },
                          {
                            type: "divider",
                          },
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: () => setDeleteBucketName(bucket.name),
                          },
                        ]}
                        type="simple"
                      >
                        <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                          <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                        </div>
                      </Dropdown>
                    </div>
                    <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" />
                  </div>
                </TableTD>
              </TableRow>
            ))}

            {buckets.length === 0 && (
              <TableRow withoutHoverStyle={true}>
                <TableTD colSpan={5} className="!border-0">
                  <NotData text="You have not created any Bucket yet" />
                </TableTD>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <S3bucketEditorModal />
      <S3MultipleBucketEditorModal />
      <S3MultipleLifecycleEditorModal />
      <DeleteModal
        title="Delete Bucket"
        isOpen={deleteBucketName !== null}
        onClose={() => setDeleteBucketName(null)}
        onConfirm={handleDelete}
        loading={actionLoading}
        confirmString={
          buckets.find((bucket) => bucket.name === deleteBucketName)?.name
        }
        confirmButtonType="danger"
      />
    </>
  );
};

export default S3bucketsTab;
