import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import type { PayloadAction } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { ToastClasses } from "../../components/modals/alerts";
import {
  CreateWalletApiApiKeyData,
  WalletApiApiKey,
  WalletApiApiKeyStateType,
} from "../../types/wallet-api";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import {
  getWalletApiApiKeysApi,
  createWalletApiApiKeyApi,
  updateWalletApiApiKeyApi,
  deleteWalletApiApiKeyApi,
} from "../../apis/walletApiAPI";

const initialState: WalletApiApiKeyStateType = {
  loading: false,
  apiKeys: [],
  selectedApiKey: null,
  showEditorModal: false,
  actionLoading: false,
};

export const getWalletApiApiKeysAysnc = createAsyncThunk<
  {
    apiKeys: Array<WalletApiApiKey>;
  },
  undefined,
  { rejectValue: { message: string } }
>("wallet-api/api-keys", async (_, { fulfillWithValue, rejectWithValue }) => {
  try {
    const response = await getWalletApiApiKeysApi();
    const apiKeys = response.data.Result;
    return fulfillWithValue({ apiKeys });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const deleteWalletApiApiKeyAsync = createAsyncThunk<
  boolean,
  { id: number },
  IThunkRejectValue
>("wallet-api/api-keys/delete", async ({ id }, { rejectWithValue }) => {
  try {
    await deleteWalletApiApiKeyApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createWalletApiApiKeyAsync = createAsyncThunk<
  boolean,
  CreateWalletApiApiKeyData,
  IThunkRejectValue
>("wallet-api/api-keys/create", async (formData, { rejectWithValue }) => {
  try {
    await createWalletApiApiKeyApi(formData);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const updateWalletApiApiKeyAsync = createAsyncThunk<
  boolean,
  { id: number; formData: CreateWalletApiApiKeyData },
  IThunkRejectValue
>(
  "wallet-api/api-keys/update",
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      await updateWalletApiApiKeyApi(id, formData);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

const walletApiApiKeysSlice = createSlice({
  name: "walletApiApiKeys",
  initialState: initialState,
  reducers: {
    clearWalletApiApiKeys(state) {
      state.apiKeys = initialState.apiKeys;
      state.loading = initialState.loading;
    },
    handleShowWalletApiEditorModal: (
      state,
      action: PayloadAction<{ apiKey?: WalletApiApiKey }>
    ) => {
      state.showEditorModal = true;
      state.selectedApiKey = action.payload.apiKey || null;
    },
    handleHideWalletApiEditorModal: (state) => {
      state.showEditorModal = false;
      state.selectedApiKey = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWalletApiApiKeysAysnc.fulfilled, (state, action) => {
        state.apiKeys = action.payload.apiKeys;
        state.loading = false;
      })
      .addCase(getWalletApiApiKeysAysnc.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWalletApiApiKeysAysnc.rejected, (state, { payload }) => {
        state.loading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteWalletApiApiKeyAsync.fulfilled, (state, action) => {
        state.actionLoading = false;
      })
      .addCase(deleteWalletApiApiKeyAsync.pending, (state, action) => {
        state.actionLoading = true;
      })
      .addCase(deleteWalletApiApiKeyAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createWalletApiApiKeyAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(createWalletApiApiKeyAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(createWalletApiApiKeyAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateWalletApiApiKeyAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(updateWalletApiApiKeyAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(updateWalletApiApiKeyAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        toast.error((payload as { message: string }).message, {
          className: ToastClasses,
        });
      });
  },
});

export const selectWalletApiLoading = (state: RootState) =>
  state.walletApiApiKeys.loading;
export const selectWalletApiActionLoading = (state: RootState) =>
  state.walletApiApiKeys.actionLoading;

export const selectWalletApiApiKeys = (state: RootState) =>
  state.walletApiApiKeys.apiKeys;

export const selectSelectedWalletApiApiKey = (state: RootState) =>
  state.walletApiApiKeys.selectedApiKey;

export const selectShowWalletApiEditorModal = (state: RootState) =>
  state.walletApiApiKeys.showEditorModal;

export const {
  clearWalletApiApiKeys,
  handleShowWalletApiEditorModal,
  handleHideWalletApiEditorModal,
} = walletApiApiKeysSlice.actions;

export default walletApiApiKeysSlice.reducer;
