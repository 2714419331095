import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getWebAppJobsAsync,
  selectWebApp,
  selectWebAppJobs,
  selectWebAppJobsLoading,
  selectWebAppLoading,
} from "../../../store/web-app/webAppSlice";
import Text, { A } from "../../general/Text";
import NotData from "../../layouts/NotData";
import Loading from "../../general/Loading";
import { timeAgo } from "../../../utils/date";
import Card from "../../general/Card";

const WebAppJobsTab = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);

  const jobs = useAppSelector(selectWebAppJobs);
  const jobsLoading = useAppSelector(selectWebAppJobsLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (webApp) {
      dispatch(getWebAppJobsAsync({ webAppId: webApp.Id.toString() }));
    }
  }, [dispatch, webApp]);

  return (
    <>
      {jobsLoading && (
        <Card>
          <Loading borderSize={2} className="min-h-[300px]" />
        </Card>
      )}
      {!jobsLoading && jobs.length === 0 && (
        <Card>
          <NotData
            text={
              <div className="text-sm text-center mt-5">
                <Text>There are no jobs for this web service.</Text>
                <Text type="subtext" className="mt-2">
                  See our <A>docs</A> for information on how to trigger jobs.
                </Text>
              </div>
            }
          />
        </Card>
      )}

      {!jobsLoading && jobs.length > 0 && (
        <ul className="mt-2 border dark:border-dark-2 rounded-lg bg-white dark:bg-dark-3">
          {jobs.map((job, index) => (
            <li
              key={index}
              className="p-4 border-b last:border-b-0 dark:border-dark-2"
            >
              <div className="flex justify-between items-center">
                <div className="flex items-center flex-1 gap-4">
                  <div className="flex flex-col gap-2 text-sm ">
                    <div className="flex items-center gap-3">
                      <Text className="font-medium flex items-center gap-1">
                        {job.Name}
                      </Text>
                    </div>
                    <div>
                      <Text>Start Command: {job.StartCommand}</Text>
                      <Text>Plan: {job.Plan}</Text>
                      <Text>Created {timeAgo(job.CreatedAt)}</Text>
                    </div>
                  </div>
                </div>
                <div className="flex items-center text-sm">
                  <Text>{job.Status}</Text>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default WebAppJobsTab;
