import {
  selectInstance,
  selectInstanceBackups,
  selectInstanceBackupsLoading,
} from "../../../store/instances/instanceSlice";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { InstancesCreateUrl } from "../../../utils/urls";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import Text, { HighlighterText } from "../../general/Text";
import { DeleteModal } from "../../modals/QuestionModal";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import Dropdown from "../../general/Dropdown";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import NotData from "../../layouts/NotData";
import { useSearch } from "../../../providers/SearchProvider";
import {
  getInstancesAsync,
  getInstancesBackupsAsync,
  selectInstances,
  selectInstancesLoading,
} from "../../../store/instances/instancesSlice";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { InstanceBackup, InstanceSnapshot } from "../../../types/instance";
import Alert from "../../general/Alert";
import { ReactComponent as ErrorIcon } from "./../../../assets/icons/logs/error.svg";

const InstancesBackupsTab = () => {
  const [deleteBackup, setDeleteBackup] = useState<InstanceBackup | null>(null);

  const instance = useAppSelector(selectInstance);
  const instances = useAppSelector(selectInstances);
  const loading = useAppSelector(selectInstancesLoading);

  const backups = useAppSelector(selectInstanceBackups);
  const backupsLoading = useAppSelector(selectInstanceBackupsLoading);

  const [filteredBackups, setFilteredSBackups] = useState<InstanceBackup[]>([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (!instances) {
      dispatch(getInstancesAsync({ withoutLoading: false }));
    }
  }, [dispatch, instances]);

  // useEffect(() => {
  //   if (instance) {
  //     dispatch(getInstancesBackupsAsync({}));
  //   }
  // }, [dispatch, instance]);

  useEffect(() => {
    if (backups) {
      const filtered = backups.filter((backup) =>
        backup.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredSBackups(filtered);
    }
  }, [searchValue, backups]);

  return (
    <div>
      {instances && (
        <div className="mt-2 flex flex-col gap-4 w-full">
          {/* {filteredBackups.length > 0 && ( */}
          <div className="mt-5 w-full">
            <Table loading={backupsLoading}>
              <TableHead>
                <TableRow>
                  <TableTH lable="Name" />
                  <TableTH lable="ID" />
                  <TableTH lable="Region" />
                  <TableTH lable="Size" />
                  <TableTH lable="Creation date" />
                  <TableTH lable="Status" />
                  <TableTH lable="" />
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredBackups.map((backup, i) => (
                  <TableRow key={i}>
                    <TableTD className="cursor-pointer">
                      <div className="flex flex-col gap-1">
                        <HighlighterText
                          searchValue={searchValue}
                          textToHighlight={backup.name}
                        />
                      </div>
                    </TableTD>
                    <TableTD className="cursor-pointer">
                      <Text className="text-xs md:text-sm whitespace-nowrap">
                        {backup.id}
                      </Text>
                    </TableTD>

                    <TableTD className="cursor-pointer">
                      <Text className="text-xs md:text-sm whitespace-nowrap">
                        {/* {backup.region} */}
                      </Text>
                    </TableTD>

                    <TableTD className="cursor-pointer">
                      <Text className="text-xs md:text-sm whitespace-nowrap">
                        {/* {backup.size.toFixed(2)} GiB */}
                      </Text>
                    </TableTD>

                    <TableTD className="cursor-pointer">
                      <Text className="text-xs md:text-sm whitespace-nowrap">
                        {backup.createdAt}
                      </Text>
                    </TableTD>

                    <TableTD className="cursor-pointer">
                      <Text
                        className="text-xs whitespace-nowrap"
                        // type={
                        //   backup.status === "active"
                        //     ? "success-alert"
                        //     : "warning-alert"
                        // }
                      >
                        {/* {backup.status} */}
                      </Text>
                    </TableTD>

                    <TableTD className="w-32 gap-1">
                      <div className="h-full w-full inline-flex items-center justify-center gap-1">
                        <div className="w-8 flex justify-center items-center">
                          <Dropdown
                            positionClassName="!w-48"
                            menu={[
                              {
                                key: "1",
                                label: (
                                  <div className="flex items-center gap-1">
                                    {/* <DetailsIcon className="w-4" /> */}
                                    <PlusIcon className="w-4" />
                                    Create instance
                                  </div>
                                ),
                                onClick: () => navigate(InstancesCreateUrl),
                                disabled: loading,
                              },

                              {
                                type: "divider",
                              },
                              {
                                key: "end",
                                label: (
                                  <div className="flex items-center gap-1">
                                    <ArchiveIcon className="w-4" />
                                    Delete
                                  </div>
                                ),
                                danger: true,
                                onClick: (_, close) => {
                                  close();
                                  setDeleteBackup(backup);
                                },
                                // disabled:
                                //   loading || backup.status !== "active",
                              },
                            ]}
                            type="simple"
                          >
                            <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                              <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                            </div>
                          </Dropdown>
                        </div>
                        {/* <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" /> */}
                      </div>
                    </TableTD>
                  </TableRow>
                ))}

                {filteredBackups.length === 0 && (
                  <TableRow withoutHoverStyle={true}>
                    <TableTD colSpan={9} className="!border-0">
                      <NotData text="You have not created any backup yet" />
                    </TableTD>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          {/* )} */}
        </div>
      )}

      {/* <DeleteModal
        title="Delete a Backup"
        description={
          <div className="flex flex-col">
            <Text className="mb-5">
              Permanently delete
              {deleteBackup?.name} {deleteBackup?.createdAt}
            </Text>
            <Alert
              type="warning"
              className="text-xs mb-3"
              icon={<ErrorIcon className="w-5 mr-1 aspect-square" />}
            >
              All of the data on this instance backup will be lost.
            </Alert>
          </div>
        }
        isOpen={deleteBackup !== null}
        onClose={() => setDeleteBackup(null)}
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        onConfirm={() => {
          deleteBackup &&
            instance &&
            dispatch(
              deleteInstanceBackupSnapshotAsync({
                instanceId: instance.id,
                imageId: deleteBackup.id,
              })
            ).then((action) => {
              if (action.type === "instance/snapshot/delete/fulfilled") {
                // getInstanceBackupsAsync({  });
                dispatch(getInstancesAsync({ withoutLoading: false }));
                setDeleteBackup(null);
              }
              setDeleteBackup(null);
            });
        }}
        loading={backupsLoading}
        confirmString={deleteBackup?.name}
      /> */}
    </div>
  );
};

export default InstancesBackupsTab;
