import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ICountry,
  IServiceType,
  IThunkRejectValue,
  PublicStoreState,
  RootState,
} from "../types";
import { getExtractErrors } from "../apis";
import { CustomErrorToast } from "../components/general/Toast";
import toast from "react-hot-toast";
import { getCountriesApi, getServiceTypesApi } from "../apis/publicAPI";

const initialState: PublicStoreState = {
  counties: [],
  counriesLoading: false,

  serviceTypes: [],
  serviceTypesLoading: false,
};

export const getCountrieAsync = createAsyncThunk<
  { countries: ICountry[] },
  undefined,
  IThunkRejectValue
>("public/countries", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getCountriesApi();
    const countries = response.data.Result;
    return fulfillWithValue({ countries });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getServiceTypesAsync = createAsyncThunk<
  { serviceTypes: IServiceType[] },
  undefined,
  IThunkRejectValue
>("public/service-types", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getServiceTypesApi();
    const serviceTypes = response.data.Result;
    return fulfillWithValue({ serviceTypes });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const publiSlice = createSlice({
  name: "main-public",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCountrieAsync.pending, (state) => {
        state.counriesLoading = true;
      })
      .addCase(getCountrieAsync.fulfilled, (state, action) => {
        state.counties = action.payload.countries;
        state.counriesLoading = false;
      })
      .addCase(getCountrieAsync.rejected, (state, { payload }) => {
        state.counriesLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getServiceTypesAsync.pending, (state) => {
        state.serviceTypesLoading = true;
      })
      .addCase(getServiceTypesAsync.fulfilled, (state, action) => {
        state.serviceTypes = action.payload.serviceTypes;
        state.serviceTypesLoading = false;
      })
      .addCase(getServiceTypesAsync.rejected, (state, { payload }) => {
        state.serviceTypesLoading = false;
        toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectCountries = (state: RootState) => state.public.counties;
export const selectCountriesLoading = (state: RootState) =>
  state.public.counriesLoading;

export const selectServiceTypes = (state: RootState) =>
  state.public.serviceTypes;
export const selectServiceTypesLoading = (state: RootState) =>
  state.public.serviceTypesLoading;

export default publiSlice.reducer;
