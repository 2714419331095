import { Web3AuthPlansBox } from "../../plans/Web3AuthPlansBox";

const Web3AuthPlansTab = () => {
  return (
    <>
      <Web3AuthPlansBox />
    </>
  );
};

export default Web3AuthPlansTab;
